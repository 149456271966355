import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { formatDateRange } from '../../utils/common';
import { FormattedMessage } from 'react-intl';
import { SelectMyTaskStart } from '../../core/actions/tasks';
import ModalViewSubmitedWork from '../../components/Tasks/modalViewSubmitedWork';
import './task.scss';
import history from '../../utils/routes/history';

const Task = props => {
    const [modalVisible, setModalVisible] = useState(false);
    const dispatch = useDispatch();

    const [{ opacity }, drag, preview] = useDrag({
        item: { id: props.task?.id, type: 'task' },
        collect: monitor => ({
            opacity: monitor.isDragging() ? '0.4' : '1.0'
        })
    });

    const renderModal = (user, task) => {
        if (modalVisible) {
            return (
                <ModalViewSubmitedWork visible={modalVisible} user={user} task={task} onClose={() => setModalVisible(false)} />
            )
        }
    }

    const renderActions = t => {
        switch (t.status) {
            case "IN_PROGRESS":
            case "ACTIVE":
                return (
                    <div>
                        <div className="action-avatar-wrapper">
                            {t.active_freelancer?.profile_image
                                ? <Image src={t.active_freelancer?.profile_image} className="action-avatar" />
                                : <Image src="/assets/img/default-avatar.png" className="action-avatar" />
                            }
                            <div className="name-is-working">{t.active_freelancer?.name}</div>
                        </div>
                        <div className="action-desc">
                            <small>
                                <FormattedMessage id="my_projects.is_working" defaultMessage="Is working on the task" />
                            </small>
                        </div>
                        <div className="t-item-actions">
                            <button className="btn btn-default btn-generic btn-smallpadding btn-fullWidth" onClick={() => dispatch(SelectMyTaskStart(t.id))}>
                                <FormattedMessage id="my_projects.view_task" defaultMessage="View Task" />
                            </button>
                            <button className="btn btn-default btn-generic btn-smallpadding btn-fullWidth mt-2" onClick={() => process.env.NODE_ENV !== "production" &&  console.log('CancelTask')}>
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </button>
                        </div>
                    </div>
                )
            case "SUBMITTED":
                return (
                    <div>
                        <div className="action-avatar-wrapper">
                            {t.active_freelancer?.profile_image
                                ? <Image src={t.active_freelancer?.profile_image} className="action-avatar" />
                                : <Image src="/assets/img/default-avatar.png" className="action-avatar" />
                            }
                            <div className="name-is-working">{t.active_freelancer?.name}</div>
                        </div>
                        <div className="action-desc">
                            <small>
                                <FormattedMessage id="my_projects.submited_work" defaultMessage="Submited work" />
                            </small>
                        </div>
                        <div className="t-item-actions">
                            {/* <button className="btn btn-default btn-generic btn-smallpadding btn-fullWidth" onClick={() => setModalVisible(true)}>View work</button> */}
                            <button className="btn btn-default btn-generic btn-smallpadding btn-fullWidth mt-2" onClick={() => dispatch(SelectMyTaskStart(t.id))}>
                                <FormattedMessage id="my_projects.review_work" defaultMessage="Review work" />
                            </button>
                        </div>
                        {renderModal(t.active_freelancer, t)}
                    </div>
                )
            case 'COMPLETED':
                return (
                    <div>
                        <div className="action-avatar-wrapper">
                            {t.active_freelancer?.profile_image
                                ? <Image src={t.active_freelancer?.profile_image} className="action-avatar" />
                                : <Image src="/assets/img/default-avatar.png" className="action-avatar" />
                            }
                            <div className="name-is-working">{t.active_freelancer?.name}</div>
                        </div>
                        <div className="action-desc">
                            <small>
                                <FormattedMessage id="my_projects.finished_work" defaultMessage="Finished work on task" />
                            </small>
                        </div>
                        <div className="t-item-actions">
                            <button className="btn btn-default btn-generic btn-smallpadding btn-fullWidth mt-2" onClick={() => dispatch(SelectMyTaskStart(t.id))}>
                                <FormattedMessage id="my_projects.view_task" defaultMessage="View Task" />
                            </button>
                        </div>
                    </div>
                )

            default:
                return (
                    <div className="t-item-actions">
                        {t.status !== "COMPLETED" && <div className="t-item-noOfproposals">
                            <FormattedMessage id="plural.task.proposals" values={{ count: t.number_of_proposals }}
                                defaultMessage={`${t.number_of_proposals} Task Poposals`} />
                        </div>}
                        <button className="btn btn-default btn-generic btn-smallpadding btn-fullWidth" onClick={() => dispatch(SelectMyTaskStart(t.id))}>
                            <FormattedMessage id="my_projects.view_task" defaultMessage="View Task" />
                        </button>
                    </div>
                )
        }
    }

    const { task, readOnly } = props;

    if (!task)
        return <p>Loading...</p>

    return (
        <div
            ref={preview}
            className="tasklist-item"
            style={{ backgroundColor: 'rgb(255, 255, 255)', paddingLeft: '5px' }}
            key={task.id}
        >
            {/* This div is the black drag element */}
            {!readOnly &&
                <div ref={drag} className="grip-wrap">
                    <span className="grippy"></span>
                </div>}

            <div className={`t-item-body ${readOnly && "p-3"}`}>
                <div className="t-item-body-head">
                    <div className="t-item-hr1">
                        <span className="form-labels">{task.name}</span>
                        <span>{task.price ? `$` + parseInt(task.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : task.price}</span>
                    </div>

                    <div className="t-item-hr2">
                        <div>
                            <span className={"status-circle status-color-" + task.status.toLowerCase()}>● </span>
                            <FormattedMessage id={`common.${task.status.toLowerCase()}`} defaultMessage={task.status} />
                        </div>
                        <span>{formatDateRange(new Date(task.start_date), new Date(task.end_date))}</span>
                    </div>
                </div>

                <div className="t-item-body-content">
                    <div className="t-item-about">{task.short_description}</div>
                    <div className="card-tags">
                        {task.competences.map(c =>
                            <span onClick={() => history.push('/competency/'+c.id)} key={c.id}>{c.name} L{c.level}</span>
                        )}
                    </div>
                </div>
            </div>

            <div className="v-divider"></div>

            {renderActions(task)}
        </div>
    );
}

export default Task;