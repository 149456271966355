import React from 'react'
import './index.scss'
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ChangeRoleStart } from '../../core/actions/role';


class ProfileSwitcherControl extends React.Component {

    componentDidMount() { }

    getInitials(str) {
        try {
            let name = str.split(" ");
            if (name.length >= 2) {
                return name[0].charAt(0) + name[1].charAt(0);
            }
        } catch (err) { }
        return str.charAt(0);
    }

    changeRole(id) {
        this.props.ChangeRoleStart(id);
    }

    render() {

        return (
            <>
                {this.props.roles && this.props.roles.length > 1 ?
                    <>
                        <div className="switch-header">
                            <span><FormattedMessage id='profile.change.role' defaultMessage='Quick switch between my profiles' /></span>
                        </div>
                        <div className="switch-container">
                            {this.props.roles.map((role, i) =>
                                <div className={`switch-item item-${role.type.toLowerCase()} ${role.id === this.props.active_role_id ? "item-" + role.type.toLowerCase() + "-active" : ""}`}
                                    onClick={() => this.changeRole(role.id)} key={i}>
                                    <span className={role.type.toLowerCase() + "-usercon"}> {this.getInitials(role.name)}</span>
                                    <div className="item-content">
                                        <span>{role.name}</span>
                                        <span><FormattedMessage id={"roles.name." + role.type} defaultMessage={role.type} /></span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                    : ''
                }
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        active_role_type: state.user.info.settings.active_role_obj?.type,
        active_role_id: state.user.info.settings.active_role,
        active_role: state.user.info.settings.active_role_obj,
        roles: state.user.info.roles,
    }
}

export default injectIntl(connect(mapStateToProps, { ChangeRoleStart })(ProfileSwitcherControl));