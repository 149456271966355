//Competence Descriptions
export const GET_COMPETENCE_DESCRIPTIONS_REQUEST = "GET_COMPETENCE_DESCRIPTIONS_REQUEST";
export const GET_COMPETENCE_DESCRIPTIONS_SUCCESS = "GET_COMPETENCE_DESCRIPTIONS_SUCCESS";
export const GET_COMPETENCE_DESCRIPTIONS_FAILURE = "GET_COMPETENCE_DESCRIPTIONS_FAILURE";

export const CREATE_COMPETENCE_DESCRIPTION_REQUEST = "CREATE_COMPETENCE_DESCRIPTION_REQUEST";
export const CREATE_COMPETENCE_DESCRIPTION_SUCCESS = "CREATE_COMPETENCE_DESCRIPTION_SUCCESS";
export const CREATE_COMPETENCE_DESCRIPTION_FAILURE = "CREATE_COMPETENCE_DESCRIPTION_FAILURE";

export const VOTE_FOR_COMPETENCE_DESCRIPTION_REQUEST = "VOTE_FOR_COMPETENCE_DESCRIPTION_REQUEST";
export const VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS = "VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS";
export const VOTE_FOR_COMPETENCE_DESCRIPTION_FAILURE = "VOTE_FOR_COMPETENCE_DESCRIPTION_FAILURE";

export const DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_REQUEST = "DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_REQUEST";
export const DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS = "DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS";
export const DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_FAILURE = "DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_FAILURE";