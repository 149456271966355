import React from 'react';
import '../browse-styles.scss';
import './taskDetails.scss';
import history from '../../../utils/routes/history';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { SelectTaskStart, DeselectMyTask } from './../../../core/actions/tasks';
import { GetMyTaskProposalsStart } from './../../../core/actions/taskProposals';
import { SelectProjectStart } from './../../../core/actions/projects';
import { formatDateRange, contains, getFileName, getFileExtension } from '../../../utils/common';
import ModalSubmitTaskProposal from '../../../components/Tasks/modalSubmitTaskProposal';
import ModalFileViewer from '../../../components/Common/ModalFileViewer/modalFileViewer';
import { langService } from '../../../utils/languageService';


class TaskDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectId: null,
            modalProposalVisible: false,
            modalFileViewerVisible: false,
            selectedDocument: null
        };
    }

    componentDidMount() {
        if (!this.props.isEmailVerified) {
            history.push("/dashboard");
            return;
        } else {
            const param = this.props.match.params;
            if (!this.props.task?.id) {
                if (param) {
                    this.setState({ projectId: param.projectId });
                    this.props.SelectProjectStart(param.projectId, false);
                    this.props.SelectTaskStart(param.uid);
                    if (this.props.role?.type === 'DEV' || this.props.role?.type === 'INV') {
                        this.props.GetMyTaskProposalsStart(param.uid);
                    }
                } else {
                    // no data + no id...redirect
                    history.push("/browse/projects");
                }
            } else {
                if (param) {
                    this.setState({ projectId: param.projectId });
                }
            }
        }
    }

    hideModalProposal = () => {
        this.setState({ modalProposalVisible: false });
    }

    backToTasks() {
        const { role } = this.props;
        this.props.DeselectMyTask();

        if (role?.type !== 'DEV')
            history.push("/projects/" + this.state.projectId + "/2");
        else
            history.push("/browse/tasks");
    }

    // Modal File Viewer
    openFileViewer(file) {
        this.setState({ selectedDocument: file, modalFileViewerVisible: true });
    }
    renderModalFileViewer() {
        if (this.state.modalFileViewerVisible) {
            return (
                <ModalFileViewer visible={this.state.modalFileViewerVisible} file={this.state.selectedDocument} onClose={() => this.hideModalFileViewer()} />
            )
        }
    }
    hideModalFileViewer() {
        this.setState({ modalFileViewerVisible: false });
    }

    renderModalSubmitTaskProposal() {
        if (!this.state.modalProposalVisible) return;

        const { role, task } = this.props;

        if (task.id) {

            var invite = role.type === "INV" ? true : false;
            var freelancerId = role.type === "DEV" ? role.id : null;

            return (
                <ModalSubmitTaskProposal
                    visible={this.state.modalProposalVisible}
                    taskId={task.id}
                    clientId={task.project.client.id}
                    start={task.start_date}
                    end={task.end_date}
                    price={task.price}
                    invite={invite}
                    action="new"
                    freelancerId={freelancerId}
                    competences={task.competences}
                    onClose={this.hideModalProposal}
                />);
        } else {
            return null;
        }
    }

    renderTaskAction() {
        const { role, project, task, myTaskProposals } = this.props;
        if (role.type === "DEV") {
            if (myTaskProposals.length > 0) {
                if (myTaskProposals.some(x => x.status === "REJECTED")) {
                    return (
                        <>
                            <div className="you-like text-center">
                                <FormattedMessage id="browse.task.negotiations_completed" defaultMessage="Negotiations completed" />
                            </div>
                            <button className="btn btn-default btn-developers-inv btn-fullWidth">
                                <FormattedMessage id="browse.task.proposal_rejected" defaultMessage="Proposal rejected" />
                            </button>
                        </>
                    )
                } else {
                    return (
                        <>
                            <div className="you-like text-center">
                                <FormattedMessage id="browse.task.negotiation" defaultMessage="Negotiations in progress" />
                            </div>
                            <button className="btn btn-default btn-developers-inv btn-fullWidth" onClick={() => history.push("/my-work/" + this.props.task.id)}>
                                <FormattedMessage id="browse.task.view_my_work" defaultMessage="View in My Work" />
                            </button>
                        </>
                    )
                }

            } else if (task.visibility === "PRIVATE") {
                return (<div className="you-like text-center">
                    <FormattedMessage id="browse.task.invite_only" defaultMessage="Task is invite only" />
                </div>)
            } else if (task.status === 'PUBLISHED' || task.status === 'IN_NEGOTIATION') {
                return (
                    <>
                        <div className="you-like text-center">
                            <FormattedMessage id="browse.task.like_task" defaultMessage="You like this task?" />
                        </div>
                        <button className="btn btn-default btn-developers-inv btn-fullWidth" onClick={() => this.setState({ modalProposalVisible: true })}>
                            <FormattedMessage id="browse.task.submit_task_proposal" defaultMessage="Submit task proposal" />
                        </button>
                    </>
                )
            }
            else {
                return (
                    <>
                        <div className="you-like text-center">
                            <FormattedMessage id="browse.task.post_negotiation" defaultMessage="Someone is already working on this task." />
                        </div>
                    </>
                )
            }
        } else if (role.type === "INV" && project.client.id === role.id) {
            return (
                <>
                    <div className="you-like text-center">
                        <FormattedMessage id="browse.task.invite_free_long" defaultMessage="Invite freelancers to this task." />
                    </div>
                    <button className="btn btn-default btn-developers-inv btn-fullWidth" onClick={() => history.push("/my-projects/" + project.id + '/' + task.id)}>
                        <FormattedMessage id="browse.task.goto_my_projects" defaultMessage="Go to My Projects" />
                    </button>
                </>
            )
        } else {
            return (
                <>
                    <div className="you-like text-center">
                        <FormattedMessage id="browse.task.switch_to_participate" defaultMessage="Switch role to participate" />
                    </div>
                </>
            );
        }
    }

    renderFooterTaskAction() {
        const { role, project, task, myTaskProposals } = this.props;

        if (role.type === "DEV") {
            if (myTaskProposals.length > 0) {
                if (myTaskProposals.some(x => x.status === "REJECTED")) {
                    return (
                        <div className="p-content-footer">
                            <span>
                                <FormattedMessage id="'browse.task.negotiations_completed': 'Proposal rejected'," defaultMessage="Negotiations completed" />
                            </span>
                            <div className="btn btn-p-explore">
                                <FormattedMessage id="'browse.task.proposal_rejected': 'Proposal rejected'," defaultMessage="Proposal rejected" />
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="p-content-footer">
                            <span>
                                <FormattedMessage id="browse.task.negotiation" defaultMessage="Negotiations in progress" />
                            </span>
                            <div className="btn btn-p-explore" onClick={() => history.push("/my-work/" + this.props.task.id)}>
                                <FormattedMessage id="browse.task.view_my_work" defaultMessage="View in My Work" />
                            </div>
                        </div>
                    )
                }
            } else if (task.visibility === "PRIVATE") {
                return (
                    <div className="p-content-footer">
                        <span>
                            <FormattedMessage id="browse.task.invite_only" defaultMessage="Task is invite only" />
                        </span>
                    </div>
                )
            } else if (task.status === 'PUBLISHED' || task.status === 'IN_NEGOTIATION') {
                return (
                    <div className="p-content-footer">
                        <span>
                            <FormattedMessage id="browse.task.like_task" defaultMessage="You like this task?" />
                        </span>
                        <div className="btn btn-p-explore" onClick={() => this.setState({ modalProposalVisible: true })} >
                            <FormattedMessage id="browse.task.submit_task_proposal" defaultMessage="Submit task proposal" />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="p-content-footer">
                        <span>
                            <FormattedMessage id="browse.task.post_negotiation" defaultMessage="Someone is already working on this task." />
                        </span>
                        <div className="btn btn-p-explore" onClick={() => this.backToTasks()} >
                            <FormattedMessage id="browse.task.back" defaultMessage="Back to all Tasks" />
                        </div>
                    </div>
                )
            }
        } else if (role.type === "INV" && project.client.id === role.id) {
            return (
                <div className="p-content-footer">
                    <span>
                        <FormattedMessage id="browse.task.private_invite_only" defaultMessage="Your task is private. Invite freelancers to this task." />
                    </span>
                    <div className="btn btn-p-explore" onClick={() => history.push("/my-projects/" + project.id)} >
                        <FormattedMessage id="browse.task.goto_my_projects" defaultMessage="Go to My Projects" />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="p-content-footer">
                    <span>
                        <FormattedMessage id="browse.task.switch_to_participate" defaultMessage="Switch role to participate" />
                    </span>
                </div>
            );
        }
    }

    render() {
        const { task } = this.props;

        // TODO: Add loading spinner
        if (!task)
            return <p>Loading...</p>

        return (
            <>
                <Container>

                    <div className="header-back p-0 pt-4">
                        <div style={{'display': 'flex', 'alignItems': 'center'}}>
                            <span className="icon-back_arrow" onClick={() => this.backToTasks()}></span>
                            <span onClick={() => this.backToTasks()}>
                                <FormattedMessage id="browse.task.back" defaultMessage="Back to All Tasks" />
                            </span>
                        </div>
                    </div>

                    <div className="taskname">{task.name}</div>

                    <Row className="mt-3">

                        <Col md={9}>
                            <Tabs defaultActiveKey={1} className="">

                                <Tab eventKey={1} title={langService.messages['browse.task.details']}>
                                    <div className="p-3">

                                        <div className="content-title">
                                            <FormattedMessage id="browse.task.about" defaultMessage="About" />
                                        </div>
                                        <div className="pinfo mb-2">Project:&nbsp;<span className="name-link" onClick={() => history.push("/projects/" + this.state.projectId)}>{task.project?.name}</span></div>
                                        <div className="task-text">{task.short_description}</div>
                                        <div className="content-title">
                                            <FormattedMessage id="forms.labels.category" defaultMessage="Category" />
                                        </div>
                                        <div className="task-text">{task.category?.name}</div>
                                        <div className="content-title">
                                            <FormattedMessage id="browse.task.required_competences" defaultMessage="Required Competences" />
                                        </div>
                                        <div className="card-tags">
                                            {task.competences.map((c, i) =>
                                                <span onClick={() => history.push('/competency/' + c.id)} key={i}>{c.name} L{c.level}</span>
                                            )}
                                        </div>
                                        <div className="content-title">
                                            <FormattedMessage id="browse.task.description" defaultMessage="Long description" />
                                        </div>
                                        <div className="task-text">{task.description}</div>
                                        <div className="content-title">
                                            <FormattedMessage id="plural.files.attached" values={{ count: task?.documents?.length }}
                                                defaultMessage={`${task?.documents?.length} Files Attached`} />
                                        </div>
                                        {task?.documents?.map((doc, i) =>
                                            <div className="attachment-box" key={i}>
                                                <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? getFileExtension(doc.document) : 'generic'}`}></div>
                                                {/* <div className="download-link" onClick={() => this.downloadFile(doc.document)} alt="project attachment">{getFileName(doc.document)}</div> */}
                                                <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                                            </div>
                                        )}
                                        {this.renderModalFileViewer()}
                                        <br /><br />
                                        {this.renderFooterTaskAction()}

                                    </div>
                                </Tab>

                                {/* <Tab eventKey={2} title={langService.messages['browse.task.milestone']}>
                                    <div className="p-3">
                                        <div className="content-title">
                                            <FormattedMessage id="browse.task.milestone" defaultMessage="Milestones" />
                                        </div>
                                        <div className="task-text">Coming soon...</div>
                                    </div>
                                </Tab> */}

                            </Tabs>
                        </Col>

                        <Col md={3} className="pt-3">
                            <div className="trenum-title">
                                <FormattedMessage id="common.conditions" defaultMessage="Conditions" />
                            </div>

                            <div className="trenum-body">

                                <div className="trenum-body-col">
                                    <div className="trenum-title">
                                        <FormattedMessage id="browse.project.budget" defaultMessage="Budget" />
                                    </div>
                                    <div className="trenum-val">${task.price ? parseInt(task.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : task.price}</div>
                                </div>

                                <div className={!task.recurring ? "trenum-body-col last-trenum" : "trenum-body-col"}>
                                    <div className="trenum-title">
                                        <FormattedMessage id="browse.task.timeframe" defaultMessage="Timeframe" />
                                    </div>
                                    <div className="trenum-val2">{task.visibility && task.visibility === "PUBLIC" ? formatDateRange(new Date(task.start_date), new Date(task.end_date)) : 'N/A'}</div>
                                </div>

                                {task.recurring &&
                                    <div className={task.recurring ? "trenum-body-col last-trenum" : "trenum-body-col"}>
                                        <div className="trenum-title">
                                            <FormattedMessage id="common.reccurrance" defaultMessage="Task recurrance" />
                                        </div>
                                        <div className="trenum-val2">
                                            {task.recurring_type === 0 && langService.messages['common.interval']}
                                            {task.recurring_type === 1 && langService.messages['common.weekly'] + " X " + task.recurring_amount}
                                            {task.recurring_type === 2 && langService.messages['common.monthly'] + " X " + task.recurring_amount}
                                        </div>
                                    </div>
                                }

                                {this.renderTaskAction()}

                            </div>
                        </Col>

                    </Row>

                </Container>

                {this.renderModalSubmitTaskProposal()}

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isEmailVerified: state.user.info.isEmailVerified,
        role: state.user.info.settings.active_role_obj,
        project: state.browse.projects.selected,
        task: state.browse.tasks.selected,
        myTaskProposals: state.myWork.myTaskProposals.list.results
    }
}

export default injectIntl(connect(mapStateToProps, {
    SelectProjectStart,
    SelectTaskStart,
    GetMyTaskProposalsStart,
    DeselectMyTask,
})(TaskDetails));