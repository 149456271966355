import React from 'react';
import './competencesComponent.scss';
import { injectIntl, FormattedMessage } from 'react-intl';
import { WithOutContext as ReactTags } from 'react-tag-input';
import { langService } from '../../utils/languageService/index';
import { apiCon } from '../../API';
import ModalCreateANewCompetence from './Create/modalCreateANewCompetence';
import { getBloomVerb, getBloomName } from '../../utils/common/misc';

// include_task_issuer_id: tu daš noter client id pri insert,edit tasku ali proposlu

const KeyCodes = { comma: 188, enter: 13, };
const delimiters = [KeyCodes.comma, KeyCodes.enter];

class CompetencesComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            obj_competences: this.props.obj_competences?.map(x => {
                return {
                    id: x.id.toString(),
                    text: this.getText(x.name, x.level)
                }
            }) ?? [],
            competences: [],
            inputValue: '',
            issuer: this.props.issuer,
            modalCreateCompetenceVisible: false,
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        //this.handleDrag = this.handleDrag.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.issuer !== this.props.issuer) {
            this.loadData();
        }
    }

    getText = (label, level) => {
        return (
            `${label} (${getBloomName(level)} - ${getBloomVerb(1, level, langService.locale)})`)
    }

    loadData = () => {
        const { inputValue, obj_competences, issuer } = this.state;
        const url = '/competences/'
        const params = {
            'limit': 10,
            ...(issuer ? { 'include_task_issuer_id': issuer } : {}),
            ...(inputValue ? { 'search': inputValue } : {}),
        }
        apiCon.get(url, { params })
            .then(response => response.data.results.filter(x => !obj_competences.some(y => y.id === x.id)).map(x => {
                return {
                    id: x.id.toString(),
                    text: this.getText(x.name, x.level)
                }
            }))
            .catch(err => {
                process.env.NODE_ENV !== "production" && console.log('Bad Luck', err);
            }).then(x => this.setState({ competences: x }))
    };

    handleDelete(i) {
        const { obj_competences, competences } = this.state;
        if (obj_competences.length !== 0) {
            const deleted = obj_competences.find((_, index) => index === i);
            if (deleted) {
                const t = obj_competences.filter((c) => c.id !== deleted.id);
                const all = competences.concat(deleted)
                this.setState({ obj_competences: t, competences: all }, this.props.onCompetencesChange(t));
            }
        }
    }

    handleAddition(tag) {
        const { obj_competences, competences } = this.state;
        if (obj_competences.length >= 5) { return; };
        const found = competences.find(el => el.id === tag.id);
        const exists = obj_competences.find(el => el.id === tag.id);
        if (found && !exists) {
            const t = obj_competences.concat(tag);
            const all = competences.filter(x => x.id !== tag.id)
            this.setState({ obj_competences: t, competences: all }, this.props.onCompetencesChange(t));
        }
    }

    handleInputChange(input) {
        if (this.state.inputValue !== input) {
            this.setState({ inputValue: input },
                () => this.loadData())
        }
    }

    openModalCreateCompetence = () => {
        this.setState({ modalCreateCompetenceVisible: true });
    }

    hideModalCreateCompetence = (competence) => {
        this.setState({ modalCreateCompetenceVisible: false });
        if (competence) {
            competence = {
                id: competence.id,
                text: this.getText(competence.name, competence.level)
            }
            const { obj_competences } = this.state;
            const t = obj_competences.concat(competence)
            this.setState({
                obj_competences: t
            }, this.props.onCompetencesChange(t));
        }
    }

    // renderSuggestion(obj, query) {
    //     return (
    //         <div>{obj.name} {obj.level}</div>
    //     )
    // }

    render() {
        const { obj_competences, competences, modalCreateCompetenceVisible } = this.state;
        return (
            <>
                <ReactTags
                    name="tagsInput"
                    inputFieldPosition="bottom"
                    tags={obj_competences}
                    suggestions={competences}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    allowDragDrop={false}
                    // handleDrag={this.handleDrag}
                    delimiters={delimiters}
                    autofocus={false}
                    minQueryLength={0}
                    handleInputChange={this.handleInputChange}
                    // renderSuggestion={this.renderSuggestion}
                    placeholder={langService.messages['competences.search'] + "..."}
                    classNames={{
                        tags: 'cctags',
                        tagInput: 'cctagInput',
                        tagInputField: 'cctagInputField',
                        selected: 'ccselected',
                        tag: 'cctag',
                        remove: 'ccremove',
                        suggestions: 'ccsuggestions',
                        activeSuggestion: 'ccactiveSuggestion'
                    }}
                />
                <div className="form-sublabel create-competence" onClick={() => this.openModalCreateCompetence()} >
                    + <FormattedMessage id="competences.create" defaultMessage="Create new Competence" />
                </div>
                {modalCreateCompetenceVisible && <ModalCreateANewCompetence visible={modalCreateCompetenceVisible} onClose={(x) => this.hideModalCreateCompetence(x)} />}
            </>
        )
    }

}

export default injectIntl(CompetencesComponent);