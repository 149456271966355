import * as types from './types';
import { apiCon } from '../../../API';
import { GetMyTaskHistoryStart } from '../tasks';

//----------------------
// Actions Task Dispute Cases
//----------------------

//----------------------
// Actions Select My Task Dispute Case
//----------------------
export const SelectMyTaskDisputeCase = (id, get_history = false) => async dispatch => {
    dispatch({ type: types.SELECT_MY_TASK_DISPUTE_CASE_REQUEST });
    await apiCon.get('/me/project/task/disputecase/' + id)
        .then(response => {
            dispatch(SelectMyTaskDisputeCaseSuccess(response.data, get_history));
        }
        )
        .catch(err => {
            dispatch(SelectMyTaskDisputeCaseFail(err.response));
        })
}
export const SelectMyTaskDisputeCaseSuccess = (data, get_history) => async dispatch => {
    const payload = { succ_msg: "Successfully received task dispute case info.", selected: data }
    if (get_history) {
        dispatch(GetMyTaskHistoryStart(data.task.id));
    }
    dispatch({ type: types.SELECT_MY_TASK_DISPUTE_CASE_SUCCESS, payload: payload });
}
export const SelectMyTaskDisputeCaseFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Task Dispute Case failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get Task Dispute Case failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_MY_TASK_DISPUTE_CASE_FAILURE, payload: payload }
}

export const DeselectMyTaskDisputeCase = () => {
    const payload = { succ_msg: "Successfully deselected task dispute case info." }
    return { type: types.DESELECT_MY_TASK_DISPUTE_CASE, payload: payload };
}

//----------------------
// Actions Get My Task Dispute Case List
//----------------------
export const GetMyTaskDisputeCasesStart = (taskId, taskName, freelancerName, clientName, limit = 100, search) => async dispatch => {
    dispatch({ type: types.GET_MY_TASK_DISPUTE_CASES_REQUEST });
    var url = '/me/project/task/disputecases/'
    const params = {
        'limit': limit,
        ...(search ? { 'search': search } : {}),
        ...(taskId ? { 'taskId': taskId } : {}),
        ...(taskName ? { 'task': taskName } : {}),
        ...(freelancerName ? { 'freelancer': freelancerName } : {}),
        ...(clientName ? { 'client': clientName } : {}),
    }
    await apiCon.get(url, { params: params })
        .then(response => {
            dispatch(GetMyTaskDisputeCasesSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyTaskDisputeCasesFail(err.response));
        })
}
export const GetMyTaskDisputeCasesChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_MY_TASK_DISPUTE_CASES_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyTaskDisputeCasesSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyTaskDisputeCasesFail(err.response));
        })
}
export const GetMyTaskDisputeCasesSuccess = (data) => {
    const payload = { succ_msg: "Successfully received tasks dispute cases.", list: data }
    return { type: types.GET_MY_TASK_DISPUTE_CASES_SUCCESS, payload: payload };
}
export const GetMyTaskDisputeCasesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Task Dispute Cases failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get My Task Dispute Cases failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_TASK_DISPUTE_CASES_FAILURE, payload: payload }
}

//----------------------
// Actions Update Task Dispute Case
//----------------------
export const UpdateTaskDisputeCaseStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_TASK_DISPUTE_CASES_REQUEST });
    const address = `/me/project/task/disputecase/` + values.id;
    delete values['id'];
    const json = JSON.stringify(values);
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateTaskDisputeCaseSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateTaskDisputeCaseFail(err.response));
        });
}
export const UpdateTaskDisputeCaseSuccess = (data, showToast = true) => async dispatch => {
    const succ_msg = "Task Dispute Case updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: showToast ? succ_msg : null, selected: data };
    dispatch({ type: types.UPDATE_TASK_DISPUTE_CASES_SUCCESS, payload: payload });
}
export const UpdateTaskDisputeCaseFail = (err, showToast = true) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Update Task Dispute Case failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Update Task Dispute Case failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: showToast ? toast_msg : null, status: status };
    return { type: types.UPDATE_TASK_DISPUTE_CASES_FAILURE, payload: payload };
}