import * as types from './types';
import { apiCon } from '../../../API';

//----------------------
// Actions Task Category
//----------------------

//----------------------
// Actions Get Task Categories List
//----------------------
export const GetTaskCategoriesStart = (limit = 100, search) => async dispatch => {
    dispatch({ type: types.GET_TASK_CATEGORIES_REQUEST });
    var url = '/project/task/categories/'
    const params = {
        'limit' : limit,
        ...(search ? { 'search': search } : {}),
    }
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetTaskCategoriesSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetTaskCategoriesFail(err.response));
        })
}
export const GetTaskCategoriesChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_TASK_CATEGORIES_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetTaskCategoriesSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetTaskCategoriesFail(err.response));
        })
}
export const GetTaskCategoriesSuccess = (data) => {
    const payload = { succ_msg: "Successfully received Task categories list.", list: data }
    return { type: types.GET_TASK_CATEGORIES_SUCCESS, payload: payload };
}
export const GetTaskCategoriesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Task Categories failed. Please try again."
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_TASK_CATEGORIES_FAILURE, payload: payload }
}