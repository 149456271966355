import * as types from './types';
import { apiCon } from '../../../API';

//-------------------------------
// Actions Competence Skill
//-------------------------------

//-------------------------------
// Actions Get Competence Skills
//-------------------------------
export const GetCompetenceSkillsStart = (limit = 100, competence_id, search) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCE_SKILLS_REQUEST });
    var url = '/competence/skills/'
    const params = {
        'limit': limit,
        ...(competence_id ? { 'competence': competence_id } : {}),
        ...(search ? { 'search': search } : {}),
    }
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetCompetenceSkillsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetCompetenceSkillsFail(err.response));
        })
}
export const GetCompetenceSkillsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCE_SKILLS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetCompetenceSkillsSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetCompetenceSkillsFail(err.response));
        })
}
export const GetCompetenceSkillsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received Competence Skills info.", list: data, add: add }
    return { type: types.GET_COMPETENCE_SKILLS_SUCCESS, payload: payload };
}
export const GetCompetenceSkillsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Competence Skills failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_COMPETENCE_SKILLS_FAILURE, payload: payload }
}

//---------------------------------
// Actions Create Competence Skill
//---------------------------------
export const CreateCompetenceSkillStart = (competenceId, skillId) => async dispatch => {
    dispatch({ type: types.CREATE_COMPETENCE_SKILL_REQUEST });
    const json = JSON.stringify({
        'competence': competenceId,
        'skill': skillId,
    });
    const address = `/competence/skills/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(CreateCompetenceSkillSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateCompetenceSkillFail(err.response));
        });

}
export const CreateCompetenceSkillSuccess = (data) => {
    const succ_msg = "Competence Skill created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.CREATE_COMPETENCE_SKILL_SUCCESS, payload: payload };
}
export const CreateCompetenceSkillFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Create Competence Skill failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_COMPETENCE_SKILL_FAILURE, payload: payload };
}

//----------------------------------------
// Actions Vote for Competence Skill
//----------------------------------------
export const VoteForCompetenceSkillStart = (competenceSkillId, vote_option) => async dispatch => {
    dispatch({ type: types.VOTE_FOR_COMPETENCE_SKILL_REQUEST });
    const json = JSON.stringify({
        'action': vote_option ?? 'up',
    });
    const address = `/competence/skill/${competenceSkillId}/votes/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(VoteForCompetenceSkillSuccess(response.data));
        })
        .catch(err => {
            dispatch(VoteForCompetenceSkillFail(err.response));
        });

}
export const VoteForCompetenceSkillSuccess = (data) => {
    const succ_msg = "Successfully voted for Competence Skill!";
    const payload = { succ_msg: succ_msg, selected: data };
    return { type: types.VOTE_FOR_COMPETENCE_SKILL_SUCCESS, payload: payload };
}
export const VoteForCompetenceSkillFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Vote for Competence Skill failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.VOTE_FOR_COMPETENCE_SKILL_SUCCESS, payload: payload };
}

//------------------------------------------
// Actions Delete Vote for Competence Skill
//------------------------------------------
export const DeleteVoteForCompetenceSkillStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_VOTE_FOR_COMPETENCE_SKILL_REQUEST });
    const address = `/competence/skill/${id}/votes/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.delete(address, settings)
        .then(response => {
            dispatch(DeleteForCompetenceSkillSuccess(response.data));
        })
        .catch(err => {
            dispatch(DeleteForCompetenceSkillFail(err.response));
        });

}
export const DeleteForCompetenceSkillSuccess = (data) => {
    const succ_msg = "Successfully deleted vote for Competence Skill!";
    const payload = { succ_msg: succ_msg, deleted: data };
    return { type: types.DELETE_VOTE_FOR_COMPETENCE_SKILL_SUCCESS, payload: payload };
}
export const DeleteForCompetenceSkillFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Delete Vote for Competence Skill failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_VOTE_FOR_COMPETENCE_SKILL_FAILURE, payload: payload };
}