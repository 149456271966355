import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import history from '../../utils/routes/history';
import ModalFileViewer from '../../components/Common/ModalFileViewer/modalFileViewer';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getFileExtension, getFileName, contains } from '../../utils/common';
import { SelectProjectStart } from '../../core/actions/projects';


class TabProjectOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDocument: null,
            modalFileViewerVisible: false
        };
    }

    componentDidMount() {
        if (this.props.projectId) {
            this.props.SelectProjectStart(this.props.projectId, false, false)
        }
    }

    // Modal File Viewer
    openFileViewer(file) {
        this.setState({ selectedDocument: file, modalFileViewerVisible: true });
    }
    renderModalFileViewer() {
        if (this.state.modalFileViewerVisible) {
            return (
                <ModalFileViewer visible={this.state.modalFileViewerVisible} file={this.state.selectedDocument} onClose={() => this.setState({ modalFileViewerVisible: false })} />
            )
        }
    }

    render() {
        const { selectedProject } = this.props;
        if (!selectedProject.id) { return null };
        return (
            <div className="task-overview-body p-3">

                <div className="form-labels">
                    <FormattedMessage id="browse.task.about" defaultMessage="About" />
                </div>

                <div className="mt-2 mb-2">
                    <FormattedMessage id="roles.name.INV" defaultMessage="Client" />:&nbsp;
                    <div className="name-link" onClick={() => history.push("/user/" + selectedProject.client?.id)}>{selectedProject.client?.name}</div>
                </div>

                <div className="mt-2 mb-3">
                    <FormattedMessage id="forms.labels.public_link" defaultMessage="Public link" />:&nbsp;
                    <div className="name-link" onClick={() => history.push("/projects/" + selectedProject?.id)}>{selectedProject?.name}</div>
                </div>

                <div className="form-labels">
                    <FormattedMessage id="forms.labels.short_description" defaultMessage="Short description" />
                </div>
                <p>{selectedProject.short_description}</p>

                <div className="form-labels">
                    <FormattedMessage id="forms.labels.category" defaultMessage="Category" />
                </div>
                <div className="card-tags mb-2"><span className="no-hover">{selectedProject.category?.name}</span></div>

                <div className="form-labels">
                    <FormattedMessage id="forms.labels.description" defaultMessage="Description" />
                </div>
                <p>{selectedProject.description}</p>

                <div className="form-labels">
                    <FormattedMessage id="plural.files.attached" values={{ count: selectedProject?.documents?.length }}
                        defaultMessage={`${selectedProject?.documents?.length} Files Attached`} />
                </div>
                {selectedProject?.documents?.map((doc, i) => {
                    return (
                        <div key={i}>
                        <div className="attachment-box">
                            <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? getFileExtension(doc.document) : 'generic'}`}></div>
                            <div className="download-link" onClick={() => this.setState({ selectedDocument: doc.document, modalFileViewerVisible: true })} alt="project attachment">{getFileName(doc.document)}</div>
                        </div>
                        {this.renderModalFileViewer()}
                        </div>
                    )
                })}
                {selectedProject?.documents?.length === 0 && <div className="mt-3 mb-3"></div>}

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        selectedProject: state.browse.projects.selected,
    }
}

export default injectIntl(connect(mapStateToProps, {
    SelectProjectStart
})(TabProjectOverview));