import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalCreateNewCompetence.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CreateCompetenceSkillStart } from '../../core/actions/competenceSkill';
import { createErrorMessageSelector, createLoadingSelector } from './../../API/selector';
import { apiCon } from '../../API';
import { getBloomName, getBloomVerb } from '../../utils/common/misc';
import { langService } from '../../utils/languageService';
import ModalCreateANewCompetence from '../Competence/Create/modalCreateANewCompetence';
import Select from 'react-select';
import { Form } from 'react-bootstrap';

class ModalAddCompetenceSkills extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            obj_competence: null,
            modalCreateCompetenceVisible: false,
            invalid: false,
            options: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "") {
            this.props.onClose();
        }
    }

    openModalCreateCompetence = () => {
        this.setState({ modalCreateCompetenceVisible: true });
    }

    hideModalCreateCompetence = (competence) => {
        this.setState({ modalCreateCompetenceVisible: false });
        if (competence) {
            competence = {
                value: competence.id,
                label: competence.name,
                level: competence.level
            }
            this.setState({
                obj_competence: competence,
                invalid: false
            });
            this.loadData()
        }
    }

    loadData = inputValue => {
        const url = '/competences/'
        const params = {
            'limit': 10,
            ...(inputValue ? { 'search': inputValue } : {}),
        }
        apiCon.get(url, { params })
            .then(response => response.data.results.filter(x => x.id !== this.state.id && x.id !== this.state.obj_competence?.value).map(x => {
                return {
                    value: x.id.toString(),
                    label: x.name,
                    level: x.level
                }
            })).then(x => {
                if (this._isMounted) {
                    this.setState({ options: x })
                }
            })
    };

    getOptionLabel = option => {
        return (
            <div><strong>{option.label}</strong> <small>({getBloomName(option.level)} - {getBloomVerb(1, option.level, langService.locale)})</small></div>
        );
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.loadData(inputValue)
        return inputValue
    };

    onParentChanged = (value) => {
        this.setState({ obj_competence: value, invalid: value ? false : true })
        this.loadData();
    }

    handleSubmit = event => {
        const { obj_competence, id } = this.state;
        const form = event.currentTarget;

        var selectInput = event.target.parent;
        if (!selectInput.value) {
            selectInput.setCustomValidity('required');
            this.setState({ invalid: true });
        } else {
            selectInput.setCustomValidity('');
            this.setState({ invalid: false });
        }

        if (form.checkValidity() === false || !selectInput.value) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.props.CreateCompetenceSkillStart(id, obj_competence.value);
        }
    }

    render() {
        const { modalCreateCompetenceVisible, invalid, obj_competence, options } = this.state;
        const { visible, onClose } = this.props;
        return (
            <Rodal className="new-competence-rodal"
                visible={visible}
                width={500}
                // height={500}
                customStyles={{ height: 'auto', bottom: 'auto', top: 'auto' }}
                onClose={onClose}
                closeMaskOnClick={false}>
                <div>

                    <div className="modal-title">
                        <FormattedMessage id="competences.add_skill_title" defaultMessage="Suggest competence skill" />
                    </div>

                    <div className="modal-body">
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="competences.parent" defaultMessage="Parent competence" />
                                </Form.Label>
                                <Select
                                    blurinputonselect="true"
                                    cacheOptions={true}
                                    placeholder={<FormattedMessage id={'competences.search'} />}
                                    options={options}
                                    defaultOptions={true}
                                    value={obj_competence}
                                    name="parent"
                                    styles={{ control: (provided, state) => invalid ? { ...provided, border: "1px solid #dc3545" } : provided }}
                                    onInputChange={this.handleInputChange}
                                    getOptionLabel={this.getOptionLabel}
                                    onChange={this.onParentChanged}
                                />
                                {invalid && <FormattedMessage id="required" defaultMessage="Required" />}
                            </Form.Group>
                            <div className="form-sublabel create-competence" onClick={() => this.openModalCreateCompetence()} >
                                + <FormattedMessage id="competences.create" defaultMessage="Create new Competence" />
                            </div>
                            {modalCreateCompetenceVisible && <ModalCreateANewCompetence visible={modalCreateCompetenceVisible} onClose={(x) => this.hideModalCreateCompetence(x)} />}
                            <br />
                            <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2" >
                                <FormattedMessage id="common.save" defaultMessage={`Save`} />
                            </button>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => onClose()}>
                                <FormattedMessage id="common.cancel" defaultMessage={`Cancel`} />
                            </div>
                        </Form>

                    </div>
                </div>
            </Rodal >
        )
    }

}
const loadingSelector = createLoadingSelector(['CREATE_COMPETENCE_SKILL']);
const errorSelector = createErrorMessageSelector(['CREATE_COMPETENCE_SKILL']);
const mapStateToProps = state => {
    return {
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state),
    }
}

export default injectIntl(connect(mapStateToProps, {
    CreateCompetenceSkillStart,
})(ModalAddCompetenceSkills));