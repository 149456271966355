import { CHANGE_LANG } from '../../actions/lang/types';
import { changeLanguageHeader } from '../../../API';

const INITIAL_STATE = 'en'

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_LANG:
            changeLanguageHeader(action.payload);
            return action.payload;
        default:
            return state;
    }
}