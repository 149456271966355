import React, { useEffect } from 'react';
import '../../browse-styles.scss';
import '../index.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { Select } from '../../../../utils/forms/formElements';
import { ChangeProjectFiltersStart } from '../../../../core/actions/projects';
import { GetProjectCategoriesStart } from '../../../../core/actions/projectCategories';

const ProjectsFiltersComp = props => {
    const { formatMessage } = props.intl;

    useEffect(() => {
        if (props.projectCategories.length === 0)
            props.GetProjectCategoriesStart(100);

    }, []);

    const initializeProjectCategories = () => {
        const { projectCategories } = props;
        const categories = projectCategories?.map(c => ({ label: c.name, value: c.id }));
        categories.unshift({ label: 'All categories', value: '' });
        return categories;
    }

    const handleBudgetChange = (type, value) => {
        if (value === '') {
            props.ChangeProjectFiltersStart({ [`budget_${type}`]: '' });
            return;
        }

        const isNumbersOnly = /^\d+$/.test(value);

        if (!isNumbersOnly)
            return;

        props.ChangeProjectFiltersStart({ [`budget_${type}`]: value });
    }

    return (
        <div className="filters-wrapper">
            <div>
                <h6><FormattedMessage id="forms.labels.category" defaultMessage="Category" /></h6>
                <Select
                    blurinputonselect="true"
                    name="project-category-select"
                    style={{ marginTop: '-10px' }}
                    options={initializeProjectCategories()}
                    value={props.projectsFilters.category}
                    onChange={e => props.ChangeProjectFiltersStart({ category: e.target.value })}
                />
            </div>
            <div>
                <h6><FormattedMessage id="browse.project.budget" defaultMessage="Budget" /></h6>
                <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>Min</span>
                <Form.Control style={{ marginBottom: '9px' }} value={props.projectsFilters.budget_min} onChange={e => handleBudgetChange('min', e.target.value)} />
                <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>Max</span>
                <Form.Control style={{ marginBottom: '13px' }} value={props.projectsFilters.budget_max} onChange={e => handleBudgetChange('max', e.target.value)} />
            </div>
            <div>
                <h6><FormattedMessage id="projects.employmentduration" defaultMessage="Employment duration" /></h6>
                <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>
                    <FormattedMessage id="common.from" defaultMessage="from" />
                </span>
                <Form.Control
                    style={{ marginBottom: '13px' }}
                    type="date"
                    value={props.projectsFilters.date_from}
                    onChange={e => props.ChangeProjectFiltersStart({ date_from: e.target.value })}
                />
                <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>
                    <FormattedMessage id="common.to" defaultMessage="To" />
                </span>
                <Form.Control
                    style={{ marginBottom: '13px' }}
                    type="date"
                    value={props.projectsFilters.date_to}
                    onChange={e => props.ChangeProjectFiltersStart({ date_to: e.target.value })}
                />
            </div>
            <div>
                <h6><FormattedMessage id="common.misc" defaultMessage="Misc" /></h6>
                {props.isAuthenticated &&
                    <Form.Group controlId="project-my-projects-filter">
                        <Form.Check type="checkbox" checked={props.projectsFilters.my_projects} onChange={() => props.ChangeProjectFiltersStart({ my_projects: !props.projectsFilters.my_projects})} label={formatMessage({ id: 'nav.labels.myProjects', defaultMessage: 'My projects' })} />
                    </Form.Group>
                }
            </div>
            <div>
                <button style={{ width: '100%' }} onClick={() => props.ChangeProjectFiltersStart()} className="btn btn-default btn-projects">
                    <FormattedMessage id="forms.reset_filters" defaultMessage="Reset filters" />
                </button>
            </div>
        </div>
    );
}

const ProjectsSearchSortComp = props => {
    const { formatMessage } = props.intl;

    const SORT_OPTIONS = [
        { label: formatMessage({ id: 'browse.common.sortby', defaultMessage: 'Sort by' }), value: '' },
        { label: formatMessage({ id: 'browse.order.startingsoon', defaultMessage: 'Starting soon' }), value: 'estimated_start' },
        { label: formatMessage({ id: 'browse.order.highestbudget', defaultMessage: 'Highest budget' }), value: '-estimated_budget' },
        { label: formatMessage({ id: 'browse.order.lowestbudget', defaultMessage: 'Lowest budget' }), value: 'estimated_budget' },
        { label: formatMessage({ id: 'browse.order.byclient', defaultMessage: 'By client' }), value: 'client__id' }
    ]

    return (
        <div className="browse-search">
            <Form>
                <Form.Row>
                    <Col md={12} lg={9}>
                        <Form.Control
                            placeholder={formatMessage({ id: 'browse.common.search', defaultMessage: 'Search' })}
                            onChange={e => props.ChangeProjectFiltersStart({ search: e.target.value })}
                            value={props.projectsFilters.search}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <Select
                            blurinputonselect="true"
                            name="tasks-sort-select"
                            style={{ display: 'inline' }}
                            options={SORT_OPTIONS}
                            value={props.projectsFilters.ordering}
                            onChange={e => props.ChangeProjectFiltersStart({ ordering: e.target.value })}
                        />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    projectsFilters: state.browse.projects.list.filters,
    projectCategories: state.projectCategory.list.results,
    isAuthenticated: state.user.auth.isAuthenticated
})

const ProjectsFilters = injectIntl(connect(mapStateToProps, {
    GetProjectCategoriesStart,
    ChangeProjectFiltersStart
})(ProjectsFiltersComp))

const ProjectsSearchSort = injectIntl(connect(mapStateToProps, {
    ChangeProjectFiltersStart
})(ProjectsSearchSortComp));

export { ProjectsFilters, ProjectsSearchSort };