import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './index.scss';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { langService } from './../../utils/languageService/index';
import { CreateSubRoleStart } from '../../core/actions/subrole';

const customStyles = {
    width: 'inherit',
    height: '400px', //'inherit',
    maxWidth: '450px',
    maxHeight: '85%',
    bottom: 'auto',
    top: '15%'
};


class ModalCreateSubRole extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            type: 'EAS',
            emailValid: true,
            validated: false
        };
    }

    handleChange = input => event => {
        if (input === 'email') {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(event.target.value)) {
                this.setState({[input]: event.target.value, emailValid: false});
            } else {
                this.setState({ [input]: event.target.value, emailValid: true });
            }
        } else {
            this.setState({ [input]: event.target.value });
        }
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        // form validation
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.createSubRole();
            return;
        }

        this.setState({ validated: true });
    };

    createSubRole() {
        this.props.CreateSubRoleStart({
            name: this.state.name,
            email: this.state.email,
            type: this.state.type,
        }).then(
            this.setState({ name: '', email: '', validated: false }),
            this.props.onConfirm(),
            this.props.onClose()
        );
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.props.onClose()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        <FormattedMessage id="roles.add_admin" defaultMessage="Add administrator" />
                    </div>

                    <div className="modal-body p-2 pt-3">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formType">
                                    <Form.Label><FormattedMessage id="roles.role" defaultMessage="Role" /></Form.Label>
                                    <Form.Check type="radio" value="EAS" onChange={this.handleChange('type')} label={langService.messages['roles.name.EAS']} name="formSubrolesRadios" id="formSubroleEAS" defaultChecked />
                                    <Form.Check type="radio" value="EAP" onChange={this.handleChange('type')} label={langService.messages['roles.name.EAP']} name="formSubrolesRadios" id="formSubroleEAP" />
                                    <Form.Check type="radio" value="EAC" onChange={this.handleChange('type')} label={langService.messages['roles.name.EAC']} name="formSubrolesRadios" id="formSubroleEAC" />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formName">
                                    <Form.Label><FormattedMessage id="forms.labels.name" defaultMessage="Name" /></Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('name')} value={this.state.name || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formEmail">
                                    <Form.Label><FormattedMessage id="forms.labels.email" defaultMessage="Email" /></Form.Label>
                                    <Form.Control type="email" onChange={this.handleChange('email')} value={this.state.email || ''} required />
                                    { !this.state.emailValid && <small className="text-danger">Please provide a valid email.</small> }
                                </Form.Group>
                            </Form.Row>

                            <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2">
                                <FormattedMessage id="common.save" defaultMessage="Save" />
                            </button>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.props.onClose()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>

                        </Form>

                    </div>

                </div>
            </Rodal >
        )
    }

}

export default injectIntl(connect(null, {
    CreateSubRoleStart
})(ModalCreateSubRole));