import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import MilestoneColorPicker from '../../components/Common/MilestoneColorPicker';
import ModalMilestones from '../../components/Milestones/modalMilestones';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Col } from 'react-bootstrap';
import { DateRangePicker } from 'react-dates';
import { CreateTaskStart } from '../../core/actions/tasks/index';
import { SelectMyProjectStart } from '../../core/actions/projects';
import { GetTaskCategoriesStart } from '../../core/actions/taskCategories';
import { UploadDocuments } from '../../components/Projects/uploadDocuments';
import { langService } from './../../utils/languageService/index';
import CompetencesComponent from '../Competence/competencesComponent';
import ModalValidationWarnings from '../Common/ModalValidationWarnings';
import { apiCon } from '../../API';
import { createLoadingSelector, createErrorMessageSelector } from '../../API/selector';



class TaskCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            short_description: '',
            description: '',
            start_date: null,
            end_date: null,
            price: 0,
            visibility: 'PUBLIC',
            category: 0,
            categories: [],
            status: 'PUBLISHED',
            focusedInput: null,
            startDate: null,
            endDate: null,
            tags: [],
            validated: false,
            categoryInvalid: false,
            dateInvalid: false,
            tagsInvalid: false,
            taskFiles: null,
            milestoneId: null,
            milestones: null,
            recurring: false,
            recurring_type: 0,
            recurring_amount: 1,
            recurring_repeats_visible: false,
            warnings: {
                messages: [],
            },
            modalMilestonesVisible: false,
            modalWarningsVisible: false,
        };
        this.selectRef = React.createRef();
        this.selectMilestoneRef = React.createRef();
    }

    componentDidMount() {
        this.props.GetTaskCategoriesStart();
        this.setState({
            startDate: moment(this.props.selectedProject.estimated_start),
            endDate: moment(this.props.selectedProject.estimated_end)
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.categories) {
            this.generateCategoryOptions(nextProps.categories);
        }

        if (nextProps.selectedProject) {
            this.setState({
                visibility: nextProps.selectedProject.visibility,
                milestones: nextProps.selectedProject.milestones
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === '') {
            this.props.onTaskAdded()
        }
    }

    generateCategoryOptions(opts) {
        if (opts.length === 0) { return; };
        var options = [];
        opts.forEach(e => {
            var item = { label: e.name, options: [] };
            e.child_categories.forEach(o => {
                item.options.push({ label: o.name, value: o.id });
            });
            options.push(item);
        });
        this.setState({ categories: options });
    }

    handleChange = input => event => {
        if (input === 'category') {
            this.setState({ [input]: event.value, categoryInvalid: false });
        } else if (input === "recurring_type") {
            this.setState({ recurring_type: event.target.value, recurring_repeats_visible: event.target.value !== '0' ? true : false });
        } else {
            if (input === 'short_description' && event.target.value.length > 500)
                return;

            if (input === 'description' && event.target.value.length > 5000)
                return;

            this.setState({ [input]: event.target.value });
        }
    }

    openModalMilestones() {
        this.setState({ modalMilestonesVisible: true });
    }

    hideModalMilestones = () => {
        this.setState({ modalMilestonesVisible: false });
    }

    onColorChange = (c) => {
        // SET TASK MILESTONE ID
        this.setState({ milestoneId: c.value !== 0 ? c.value : null });
    }

    onFilesChanged = (f) => {
        this.setState({ taskFiles: f })
    }

    onCompetenceChange = (c) => {
        this.setState({ tags: c, tagsInvalid: c.length === 0 })
    }

    checkBoxChange = (e) => {
        e.target.checked ? this.setState({ recurring: e.target.checked }) : this.setState({ recurring: e.target.checked, recurring_repeats_visible: false, recurring_type: 0 });
    }

    getParams() {
        const competences = [];
        this.state.tags.forEach(t => {
            competences.push(t.id);
        });

        var params = {
            name: this.state.name,
            short_description: this.state.short_description,
            description: this.state.description,
            start_date: moment(this.state.startDate).startOf('day').add(23, 'hours').toISOString(),
            end_date: moment(this.state.endDate).startOf('day').toISOString(),
            price: this.state.price,
            visibility: this.state.visibility,
            competences: competences,
            category: this.state.category,
            status: 'PUBLISHED',
            project: this.props.selectedProject.id,
            milestone: this.state.milestoneId,
        };

        if (this.state.recurring) {
            params.recurring = true;
            params.recurring_type = this.state.recurring_type;
            params.recurring_amount = this.state.recurring_type !== 0 ? this.state.recurring_amount : null;
        }
        return params;
    }

    verify() {
        const params = this.getParams()
        const settings = { headers: { 'Content-Type': 'application/json' } };
        apiCon.post('/me/project/tasks/validate/', JSON.stringify(params), settings).then(response => {
            this.save(params)
        }).catch(error => {
            if (error.response.data) {
                this.setState({
                    modalWarningsVisible: true, warnings: error.response.data
                });
            }
        })
    }

    save(params) {
        if (!params)
            params = this.getParams()

        this.props.CreateTaskStart(params, this.state.taskFiles, true);
    }

    onValidationWarningsConfirm() {
        this.setState({ modalWarningsVisible: false }, () => this.save());
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        // check category dropdown
        var selectInput = event.target.selCategory;
        var categorySelected = false;
        if (!selectInput.value) {
            selectInput.setCustomValidity('required');
            this.setState({ categoryInvalid: true });
        } else {
            categorySelected = true;
            selectInput.setCustomValidity('');
            this.setState({ categoryInvalid: false });
        }

        // check date range
        if (!this.state.startDate || !this.state.endDate) {
            selectInput.setCustomValidity('required');
            selectInput.setCustomValidity('');
            this.setState({ dateInvalid: true });
        }

        // Competences
        var tagsInput = event.target.tagsInput;
        if (this.state.tags.length === 0) {
            tagsInput.setCustomValidity('required');
            this.setState({ tagsInvalid: true });
        } else {
            tagsInput.setCustomValidity('');
            this.setState({ tagsInvalid: false });
        }

        // form validation
        if (form.checkValidity() === false || !categorySelected) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.verify();
            return;
        }

        this.setState({ validated: true });
    };

    render() {
        const { tags, categoryInvalid, milestones } = this.state;
        const { selectedProject } = this.props;
        return (
            <div className="task-create-container">
                <div className="task-create-header">
                    <div>
                        <FormattedMessage id="browse.task.details" defaultMessage="Task Details" />
                    </div>
                </div>
                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit} autoComplete="off">
                    <Form.Row>
                        <Form.Group as={Col} controlId="formTaskName">
                            <Form.Label>
                                <FormattedMessage id="forms.labels.task_name" defaultMessage="Task Name" />
                            </Form.Label>
                            <Form.Control type="text" onChange={this.handleChange('name')} required />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formTaskCategory">
                            <Form.Label>
                                <FormattedMessage id="forms.labels.category" defaultMessage="Category" />
                            </Form.Label>
                            {/* <Select options={this.state.categories} onChange={this.handleChange('category')} /> */}
                            <Select
                                required
                                blurinputonselect="true"
                                ref={this.selectRef}
                                className="basic-single"
                                classNamePrefix="select"
                                name="selCategory"
                                options={this.state.categories}
                                onChange={this.handleChange('category')}
                                styles={{ control: (provided, state) => categoryInvalid ? { ...provided, border: "1px solid #dc3545" } : provided }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formTaskShortDescription">
                        <Form.Label>
                            <FormattedMessage id="forms.labels.short_description" defaultMessage="Short Description" /> ({500 - this.state.short_description.length})
                        </Form.Label>
                        <Form.Control as="textarea" rows="2" value={this.state.short_description} onChange={this.handleChange('short_description')} required />
                    </Form.Group>

                    <Form.Group controlId="formTaskDescription">
                        <Form.Label>
                            <FormattedMessage id="forms.labels.description" defaultMessage="Description" /> ({5000 - this.state.description.length})
                        </Form.Label>
                        <Form.Control as="textarea" rows="5" value={this.state.description} onChange={this.handleChange('description')} required />
                    </Form.Group>

                    <Form.Group style={{ width: '100%' }}>
                        <Form.Label>
                            <FormattedMessage id="forms.labels.documents" defaultMessage="Documents" />
                        </Form.Label>
                        <UploadDocuments onFilesChanged={this.onFilesChanged} />
                    </Form.Group>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formProjectDuration">
                            <Form.Label>
                                <FormattedMessage id="forms.labels.estimated_start_end" defaultMessage="Estimated start/end date" />
                            </Form.Label><br />
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="projectStartDate"
                                endDate={this.state.endDate}
                                endDateId="projectEndDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                endDatePlaceholderText={"End date"}
                                startDatePlaceholderText={"Start date"}
                                displayFormat={"DD-MM-YYYY"}
                                required
                            />
                            {this.state.dateInvalid && <div><small className="text-danger">Please select the date range for this task.</small></div>}
                        </Form.Group>

                        <Form.Group as={Col} controlId="formProjectBudget">
                            <Form.Label>
                                <FormattedMessage id="forms.labels.price" defaultMessage="Price" /> ($)
                            </Form.Label>
                            <Form.Control type="number" min="1" onChange={this.handleChange('price')} required />
                        </Form.Group>

                    </Form.Row>

                    <Form.Row>
                        <div className="" style={{ "marginBottom": "5px", "fontWeight": "bold", "marginLeft": "5px" }}>
                            <Form.Check inline label={langService.messages['common.reccurring_task']} name="recurring" type="checkbox" checked={this.state.recurring} onChange={this.checkBoxChange} />
                        </div>
                    </Form.Row>

                    <Form.Row className={!this.state.recurring ? "hide-section" : ""}>
                        <Form.Group as={Col} controlId="recurring_type">
                            <Form.Label>
                                <FormattedMessage id="common.recurring_type" defaultMessage="Recurring Type" />
                            </Form.Label>
                            <Form.Control as="select" onChange={this.handleChange('recurring_type')} value={this.state.recurring_type} style={{ "maxWidth": "238px" }}  >
                                <option value={0}>{langService.messages['common.interval']}</option>
                                <option value={1}>{langService.messages['common.weekly']}</option>
                                <option value={2}>{langService.messages['common.monthly']}</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row className={!this.state.recurring_repeats_visible ? "hide-section" : ""}>
                        <Form.Group as={Col} controlId="recurring_params">
                            <Form.Label>
                                <FormattedMessage id="common.reccurring_times" defaultMessage="Repeat X" />
                            </Form.Label>
                            <Form.Control type="number" min="1" value={this.state.recurring_amount} onChange={this.handleChange('recurring_amount')} style={{ "maxWidth": "238px" }} />
                        </Form.Group>
                        <Form.Group as={Col}>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formTaskCompetences">
                            <Form.Label>
                                <FormattedMessage id="forms.labels.required_competences" defaultMessage="Required Competences" />
                            </Form.Label>

                            <CompetencesComponent onCompetencesChange={this.onCompetenceChange} obj_competences={tags} issuer={selectedProject?.client?.id} />

                            <span className={this.state.tagsInvalid ? 'tags-invalid' : 'tags-valid'}>
                                <FormattedMessage id="competences.least_one" defaultMessage="Please choose at least one competence" />
                            </span>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formTaskMilestones">
                            <Form.Label>
                                <FormattedMessage id="common.milestone" defaultMessage="Milestone" />
                            </Form.Label>
                            {milestones?.list && <MilestoneColorPicker colorPicker={false} milestones={milestones} selected={milestones.selected} onColorChange={(c) => this.onColorChange(c)} />}
                            <div className="form-sublabel create-competence" style={{ "marginTop": "13px" }} onClick={() => this.openModalMilestones()} >
                                + <FormattedMessage id="common.milestones.create" defaultMessage="Create a new Milestone" />
                            </div>
                        </Form.Group>

                    </Form.Row>

                    {
                        selectedProject.visibility && (selectedProject.visibility === "PUBLIC")
                            ?
                            <Form.Group>
                                <Form.Label as="legend">
                                    <FormattedMessage id="forms.labels.visibility" defaultMessage="Visibility" />
                                </Form.Label>
                                <div className="visibility-wrapper">
                                    <Form.Check inline type="radio" value="PUBLIC" onChange={this.handleChange('visibility')} label={langService.messages['common.public']} name="formProjectRadios" id="formProjectRadio1" defaultChecked />
                                    <Form.Check inline type="radio" value="PRIVATE" onChange={this.handleChange('visibility')} label={langService.messages['common.private']} name="formProjectRadios" id="formProjectRadio2" />
                                </div>
                            </Form.Group>
                            : ''
                    }

                    <br />
                    <button type="submit" className="btn btn-default btn-generic float-right">
                        <FormattedMessage id="common.save" defaultMessage="Save" />
                    </button>
                    <br />

                </Form>

                <ModalMilestones projectId={selectedProject.id} milestones={milestones} visible={this.state.modalMilestonesVisible} onClose={this.hideModalMilestones} />
                <ModalValidationWarnings visible={this.state.modalWarningsVisible} warnings={this.state.warnings} onConfirm={() => this.onValidationWarningsConfirm()} onClose={() => this.setState({ modalWarningsVisible: false })} />

            </div>
        )
    }

}

const loadingSelector = createLoadingSelector(['CREATE_TASK']);
const errorSelector = createErrorMessageSelector(['CREATE_TASK']);
const mapStateToProps = state => {
    return {
        selectedProject: state.myWork.myProjects.selected,
        task: state.browse.projects.selected_task,
        categories: state.taskCategory.list.results,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps, {
    CreateTaskStart,
    SelectMyProjectStart,
    GetTaskCategoriesStart
})(TaskCreate));