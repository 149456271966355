import React from 'react';
import '../../../assets/styles/partials/_auth.scss';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Input } from '../../../utils/forms/formElements';
import { Form as BSForm, Container } from 'react-bootstrap';
import { strongPassword, passwordConfirmMatch } from '../../../utils/forms/validators';
import { composeRecordLevelValidators } from '../../../utils/forms/validatorUtilities';
import { ResetPasswordStart } from '../../../core/actions/auth';
import { FormattedMessage, injectIntl } from 'react-intl';


class ResetPasswordForm extends React.Component {
    FORM_LABEL = 'forms.labels';
    FORM_PLC = 'forms.placeholders';

    onSubmit = values => {
        const data = {
            new_password1: values.password1,
            new_password2: values.password2,
            uid: this.props.match.params.uid,
            token: this.props.match.params.token
        }
        this.props.ResetPasswordStart(data);
    }

    render() {
        const { formatMessage } = this.props.intl;
        // const prod = process.env.NODE_ENV === "production" ? true : false;
        return (
            <>
            <div id="overlay">
                <img src="/assets/img/icon/lightbulb.svg" alt="Idea"></img>
            </div>
            <Container className="flex-center pt-65">
                <Form
                    onSubmit={this.onSubmit}
                    validate={composeRecordLevelValidators(passwordConfirmMatch)}
                    render={props => (
                        <div className="auth">
                            <p><span className="underline"><FormattedMessage id="forms.resetPasswordForm" defaultMessage="Set new password" /></span></p>
                            <BSForm validated={props.valid} onSubmit={props.handleSubmit}>
                                <Field
                                    name="password1"
                                    label={<FormattedMessage id={`${this.FORM_LABEL}.newPassword`} defaultMessage="newPassword" />}
                                    placeholder={formatMessage({ id: `${this.FORM_PLC}.newPassword`, defaultMessage: "newPassword" })}
                                    type="password"
                                    component={Input}
                                    validate={strongPassword}
                                />
                                <Field
                                    name="password2"
                                    label={<FormattedMessage id={`${this.FORM_LABEL}.confirmPassword`} defaultMessage="confirmPassword" />}
                                    placeholder={formatMessage({ id: `${this.FORM_PLC}.confirmPassword`, defaultMessage: "confirmPassword" })}
                                    type="password"
                                    component={Input}
                                    //validate={required}
                                />
                                <button type="submit" className="btn btn-default btn-generic btn-block"><FormattedMessage id={`${this.FORM_LABEL}.resetPassword`} defaultMessage="resetPassword" /></button>
                                {/* { !prod ?  <pre><p ><code><small>{JSON.stringify(props.values, 0, 2)}</small></code></p></pre> : '' } */}
                            </BSForm>
                        </div>
                    )}
                />
            </Container>
            </>
        )
    }
}

export default injectIntl(connect(null, { ResetPasswordStart })(ResetPasswordForm));
