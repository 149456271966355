import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './index.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

const customStyles = {
    width: 'inherit',
    height: '375px', //'inherit',
    maxWidth: '45%',
    maxHeight: '85%',
    bottom: 'auto',
    top: '15%'
};

class ModalValidationWarnings extends React.Component {

    renderChanges(warnings) {
        return (
            <ul style={{ "textAlign": "left" }}>
                {/* TASK EDIT */}
                {
                    warnings.hasOwnProperty('task_price_exceeds') && warnings.hasOwnProperty('project_estimated_budget') &&
                    <li>
                        <FormattedMessage id="projects.estimated_budget" defaultMessage={`Estimated budget`} />:&nbsp;
                        {(parseFloat(warnings.project_estimated_budget) + parseFloat(warnings.task_price_exceeds)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </li>
                }
                {
                    warnings.hasOwnProperty('task_start_date') &&
                    <li><FormattedMessage id="projects.estimated_start" defaultMessage={`Estimated start date`} />: {moment(warnings.task_start_date).format('DD.MM.YYYY')}</li>
                }
                {
                    warnings.hasOwnProperty('task_end_date') &&
                    <li><FormattedMessage id="projects.estimated_end" defaultMessage={`Estimated end date`} />: {moment(warnings.task_end_date).format('DD.MM.YYYY')} </li>
                }
                {/* EDIT TASK PROPOSAL */}
                {
                    warnings.hasOwnProperty('task_proposal_price_exceeds') && warnings.hasOwnProperty('project_estimated_budget') &&
                    <li>
                        <FormattedMessage id="projects.estimated_budget" defaultMessage={`Estimated budget`} />:&nbsp;
                        {(parseFloat(warnings.project_estimated_budget) + parseFloat(warnings.task_proposal_price_exceeds)).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    </li>
                }
                {
                    warnings.hasOwnProperty('task_proposal_start_date') &&
                    <li><FormattedMessage id="projects.estimated_start" defaultMessage={`Estimated start date`} />: {moment(warnings.task_proposal_start_date).format('DD.MM.YYYY')}</li>
                }
                {
                    warnings.hasOwnProperty('task_proposal_end_date') &&
                    <li><FormattedMessage id="projects.estimated_end" defaultMessage={`Estimated end date`} />: {moment(warnings.task_proposal_end_date).format('DD.MM.YYYY')} </li>
                }
            </ul>
        )
    }

    renderWarnings(warnings) {
        return (
                <ul style={{ "textAlign": "left" }}>
                    {/* EDIT TASK */}
                    {
                        warnings.hasOwnProperty('task_price_exceeds') &&
                        <li className="form-sublabel">
                            <FormattedMessage id="my_projects.task_price_exceeds" values={{
                                price: parseFloat(warnings.task_price_exceeds).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                                b: chunks => (<strong>{chunks}</strong>)
                            }} defaultMessage={`Task price exceeds the project estimated budget by ${<strong>{parseFloat(warnings.task_price_exceeds).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</strong>}.`} />
                        </li>
                    }
                    {
                        warnings.hasOwnProperty('task_start_date') && warnings.hasOwnProperty('project_estimated_start') &&
                        <li className="form-sublabel">
                            <FormattedMessage id="my_projects.task_start_date_before" values={{
                                task_date: moment(warnings.task_start_date).format('DD.MM.YYYY'), project_date: moment(warnings.project_estimated_start).format('DD.MM.YYYY'),
                                b: chunks => (<strong>{chunks}</strong>)
                            }} defaultMessage={`Task start date(${<strong>{moment(warnings.task_start_date).format('DD.MM.YYYY')}</strong>}) is before the project start date (${<strong>{moment(warnings.project_estimated_start).format('DD.MM.YYYY')}</strong>}).`} />
                        </li>
                    }
                    {
                        warnings.hasOwnProperty('task_end_date') && warnings.hasOwnProperty('project_estimated_end') &&
                        <li className="form-sublabel">
                            <FormattedMessage id="my_projects.task_end_date_after" values={{
                                task_date: moment(warnings.task_end_date).format('DD.MM.YYYY'), project_date: moment(warnings.project_estimated_end).format('DD.MM.YYYY'),
                                b: chunks => (<strong>{chunks}</strong>)
                            }} defaultMessage={`Task end date(${<strong>{moment(warnings.task_end_date).format('DD.MM.YYYY')}</strong>}) is after the project end date (${<strong>{moment(warnings.project_estimated_end).format('DD.MM.YYYY')}</strong>}).`} />
                        </li>
                    }
                    {/* EDIT TASK PROPOSAL */}
                    {
                        warnings.hasOwnProperty('task_proposal_price_exceeds') &&
                        <li className="form-sublabel">
                            <FormattedMessage id="my_projects.task_price_exceeds" values={{
                                price: parseFloat(warnings.task_proposal_price_exceeds).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
                                b: chunks => (<strong>{chunks}</strong>)
                            }} defaultMessage={`Task price exceeds the project estimated budget by ${<strong>{parseFloat(warnings.task_proposal_price_exceeds).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</strong>}.`} />
                        </li>
                    }
                    {
                        warnings.hasOwnProperty('task_proposal_start_date') && warnings.hasOwnProperty('project_estimated_start') &&
                        <li className="form-sublabel">
                            <FormattedMessage id="my_projects.task_start_date_before" values={{
                                task_date: moment(warnings.task_proposal_start_date).format('DD.MM.YYYY'), project_date: moment(warnings.project_estimated_start).format('DD.MM.YYYY'),
                                b: chunks => (<strong>{chunks}</strong>)
                            }} defaultMessage={`Task start date(${<strong>{moment(warnings.task_proposal_start_date).format('DD.MM.YYYY')}</strong>}) is before the project start date (${<strong>{moment(warnings.project_estimated_start).format('DD.MM.YYYY')}</strong>}).`} />
                        </li>
                    }
                    {
                        warnings.hasOwnProperty('task_proposal_end_date') && warnings.hasOwnProperty('project_estimated_end') &&
                        <li className="form-sublabel">
                            <FormattedMessage id="my_projects.task_end_date_after" values={{
                                task_date: moment(warnings.task_proposal_end_date).format('DD.MM.YYYY'), project_date: moment(warnings.project_estimated_end).format('DD.MM.YYYY'),
                                b: chunks => (<strong>{chunks}</strong>)
                            }} defaultMessage={`Task end date(${<strong>{moment(warnings.task_proposal_end_date).format('DD.MM.YYYY')}</strong>}) is after the project end date (${<strong>{moment(warnings.project_estimated_end).format('DD.MM.YYYY')}</strong>}).`} />
                        </li>
                    }
                </ul>
        )
    }

    render() {
        const { visible, warnings } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.props.onClose()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        <FormattedMessage id="common.warning" defaultMessage="Warning" />
                    </div>

                    <div className="modal-body p-2 pt-4" style={{ "textAlign": "center", "height": "85%" }}>

                        <p className="text-left">
                            <strong><FormattedMessage id="my_projects.warning_info" defaultMessage="Some parameters of the task your are editing are out of scope of the parameters of the project" />:</strong>
                        </p>

                        {
                            warnings.hasOwnProperty('detail_values') ?
                                this.renderWarnings(warnings.detail_values) :
                                warnings.detail?.map(x => x.messages).map((x, w) =>
                                    <p className="form-sublabel" key={x}>{w}</p>
                                )
                        }
                        
                        <p className="mt-4 text-left">
                            <strong><FormattedMessage id="my_projects.save_will_update" defaultMessage="You can confirm to continue and the project will be updated with the following values" />:</strong>
                        </p>
                        <strong>{warnings.hasOwnProperty('detail_values') && this.renderChanges(warnings.detail_values)}</strong>

                        <div className="modal-submit-buttons modal-abs-bottom">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.props.onClose()} >
                                <FormattedMessage id="common.close" defaultMessage="Close" />
                            </div>
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.props.onConfirm()}>
                                <FormattedMessage id="common.confirm_and_save" defaultMessage="Confirm and Save" />
                            </button>
                        </div>

                    </div>

                </div>
            </Rodal >
        )
    }

}

export default injectIntl(connect(null, {})(ModalValidationWarnings));