import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { injectIntl, FormattedMessage } from 'react-intl';
import { apiCon } from '../../API';
import { getUrlParameterObject } from '../../utils/common/index';

// https://react-select.com/creatable
class SelectEduProgramWithCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected ? this.props.selected : []
        };
    }

    loadData = (inputValue) => {
        if (!this.props.edu_id) { return Promise.resolve([{
            value: inputValue,
            label: inputValue,
        }])};
        const url = '/eduinstitutions/programs/'
        const params = {
            'limit': 10,
            'edu_id': this.props.edu_id,
            ...(inputValue ? { 'search': inputValue } : {}),
        }
        const filters = getUrlParameterObject(params);
        return apiCon.get(url, { params: filters }).then(response =>
            response.data.results.map(x => {
                return {
                    value: x.id.toString(),
                    label: x.name,
                }
            }))
            .catch(err => {
                // process.env.NODE_ENV !== "production" && console.log('Bad Luck', err);
            })
    };

    getOptionLabel = option => {
        return (
            <div>{option.label}</div>
        );
    }

    handleInputChange = (newValue) => {
        return newValue;
    };

    handleCreate = (inputValue) => {
        this.setState({ selected: { value: null, label: inputValue}});
        this.props.onCreate(inputValue);
    };

    handleChange = (values) => {
        this.setState({ selected: values });
        this.props.onSelectedChange(values);
    }

    render() {
        const { getOptionLabel, loadData, placeholder, required } = this.props;
        return (
            <div>
                <AsyncCreatableSelect
                    key={JSON.stringify(this.props.edu_id)}
                    value={this.state.selected}
                    cacheOptions={false}
                    placeholder={<FormattedMessage id={placeholder ?? 'competences.search'} defaultMessage={`${placeholder} message not defined`} />}
                    loadOptions={loadData ?? this.loadData}
                    defaultOptions={true}
                    onInputChange={this.handleInputChange}
                    onCreateOption={this.handleCreate}
                    getOptionLabel={getOptionLabel ?? this.getOptionLabel}
                    onChange={this.handleChange}
                    required={required}
                />
            </div>
        );
    }
}

export default injectIntl(SelectEduProgramWithCreate);