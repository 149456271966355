import * as types from './types';
import { apiCon } from '../../../API'
import { langService } from '../../../utils/languageService';


// GET /v1/api/edu/degrees/ (Pregled stopenj izobrazb, samo za izobraževalne institucije in programskega admina)
// POST /v1/api/edu/degrees/ (Dodajanje stopenj izobrazb, samo za izobraževalne institucije in programskega admina)
// GET /v1/api/edu/degree/ (Pregled posamezne stopnje izobrazbe, samo za izobraževalne institucije in programskega admina)
// PATCH /v1/api/edu/degree/ (Urejanje stopnje izobrazbe, samo za izobraževalne institucije in programskega admina)
// DELETE /v1/api/edu/degree/ (Brisanje oz. deaktiviranje stopnje izobrazbe, samo za izobraževalne institucije in programskega admina)


//-------------------
//  Get Degrees 
//-------------------
export const GetEduDegreesStart = () => async dispatch => {
    dispatch({ type: types.GET_DEGREES_REQUEST });
    await apiCon.get(`edu/degrees/`)
        .then(response => {
            dispatch(GetEduDegreesSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetEduDegreesFail(err.response));
        })
}

export const GetEduDegreesSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved degrees.";
    const payload = { succ_msg: succ_msg, list: data };
    dispatch({ type: types.GET_DEGREES_SUCCESS, payload: payload });
}

export const GetEduDegreesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_DEGREES_FAILURE, payload: payload };
}

//----------------------
// Create Degree
//----------------------
export const CreateDegreeStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_DEGREE_REQUEST });
    await apiCon.post(`edu/degrees/`, values)
        .then(response => {
            dispatch(CreateDegreeSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateDegreeFail(err.response));
        })
}

export const CreateDegreeSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully created a new degree.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, role: data };
    dispatch({ type: types.CREATE_DEGREE_SUCCESS, payload: payload });
}

export const CreateDegreeFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = langService.messages["degree.create.error"];
    if (err.data.error_first_message) { toast_msg += err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.CREATE_DEGREE_FAILURE, payload: payload };
}

//----------------------
// Get Degree details
//----------------------
export const GetDegreeDetailsStart = () => async dispatch => {
    dispatch({ type: types.GET_DEGREE_REQUEST });
    await apiCon.get(`edu/degree/`)
        .then(response => {
            dispatch(GetDegreeDetailsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetDegreeDetailsFail(err.response));
        })
}

export const GetDegreeDetailsSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved degree details.";
    const payload = { succ_msg: succ_msg, role: data };
    dispatch({ type: types.GET_DEGREE_SUCCESS, payload: payload });
}

export const GetDegreeDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_DEGREE_FAILURE, payload: payload };
}

//----------------------
// Update Degree details
//----------------------
export const UpdateDegreeDetailsStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_DEGREE_REQUEST });
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `edu/degree/` + values.id;
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateDegreeDetailsSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateDegreeDetailsFail(err.response));
        })
}

export const UpdateDegreeDetailsSuccess = (data) => {
    const succ_msg = "Successfully updated degree details.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, details: data };
    return { type: types.UPDATE_DEGREE_SUCCESS, payload: payload };
}

export const UpdateDegreeDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = langService.messages["degree.update.error"];
    if (err.data.error_first_message) { toast_msg += err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_DEGREE_FAILURE, payload: payload };
}

//----------------------
// Delete SubRole
//----------------------
export const DeleteDegreeStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_DEGREE_REQUEST });
    const address = 'edu/degree/' + id;
    await apiCon.delete(address)
        .then(response => {
            dispatch(DeleteDegreeSuccess(id));
        })
        .catch(err => {
            dispatch(DeleteDegreeFail(err.response));
        });
}

export const DeleteDegreeSuccess = (id) => {
    const succ_msg = "Degree deleted successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_DEGREE_SUCCESS, payload: payload };
}

export const DeleteDegreeFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = langService.messages["degree.delete.error"];
    if (err.data.error_first_message) { toast_msg += err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_DEGREE_FAILURE, payload: payload };
}