import React from 'react';
import history from '../../../utils/routes/history';
import './formCompanyInformation.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { langService } from '../../../utils/languageService';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { UpdateRoleStart, GetRoleStart } from '../../../core/actions/role';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

class FormCompanyInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.role.id,
            company_name: this.props.role.company_name,
            vat: this.props.role.vat,
            address_line_1: this.props.role.address_line_1,
            address_line_2: this.props.role.address_line_2,
            post_code: this.props.role.post_code,
            state: this.props.role.state,
            company_city: this.props.role.company_city,
            company_country: this.props.role.company_country,
            company_reg_no: this.props.role.company_reg_no,
            is_company: this.props.role.is_company ? this.props.role.is_company : false,
            validated: false
        };
        this.checkBoxChange = this.checkBoxChange.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        this.props.GetRoleStart();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            this.setState({
                id: nextProps.role.id,
                company_name: nextProps.role.company_name,
                vat: nextProps.role.vat,
                address_line_1: nextProps.role.address_line_1,
                address_line_2: nextProps.role.address_line_2,
                post_code: nextProps.role.post_code,
                state: nextProps.role.state,
                company_city: nextProps.role.company_city,
                company_country: nextProps.role.company_country,
                company_reg_no: nextProps.role.company_reg_no,
                is_company: nextProps.role.is_company ? nextProps.role.is_company : false
            });
        }
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    checkBoxChange = (e) => {
        const target = e.target;
        this.setState({ is_company: target.checked });
    }

    onSelectFlag(countryCode) {
        this.setState({ company_country: countryCode });
    }

    handleSubmit = (event) => {
        const form = event.currentTarget.form;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.next();
        }
        this.setState({ validated: true });
    };

    next() {
        const step = this.props.role.type === "DEV" ? 3 : 5;
        if (this.state.is_company) {
            this.props.UpdateRoleStart({
                is_company: true,
                company_name: this.state.company_name,
                vat: this.state.vat,
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                post_code: this.state.post_code,
                state: this.state.state,
                company_city: this.state.company_city,
                company_country: this.state.company_country,
                company_reg_no: this.state.company_reg_no
            }, step);
        } else {
            this.props.UpdateRoleStart({
                is_company: false,
            }, step);
        }
    }

    render() {
        const { role } = this.props;
        return (
            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                <Row>
                    <Col>
                        <div className="card p-0">
                            <div className="card-head">
                                <FormattedMessage id="setup_profile.company_info" defaultMessage="Company information" />
                            </div>
                            <div className="card-body sp-cardbody">
                                <div className="" style={{ "marginBottom": "20px", "fontWeight": "bold" }}>
                                    <FormattedMessage id="setup_profile.fill_company_data" defaultMessage="Please fill out the following information if you represent a company." />
                                    <br /><br />
                                    <Form.Check inline label={langService.messages['setup_profile.represent']} name="is_company" type="checkbox" checked={this.state.is_company} onChange={this.checkBoxChange} />
                                </div>

                                <div className="">
                                    <div style={{ "marginTop": "20px" }}>
                                        <div>

                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        <FormattedMessage id="forms.labels.company_name" defaultMessage="Company Name" />
                                                    </Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('company_name')} value={this.state.company_name || ''} disabled={!this.state.is_company} required={this.state.is_company} />
                                                    <Form.Control.Feedback type="invalid">
                                                        <FormattedMessage id="validators.required" defaultMessage="This field is required." />
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        <FormattedMessage id="forms.labels.vat" defaultMessage="Vat Number" />
                                                    </Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('vat')} value={this.state.vat || ''} disabled={!this.state.is_company} required={this.state.is_company} />
                                                    <Form.Control.Feedback type="invalid">
                                                        <FormattedMessage id="validators.required" defaultMessage="This field is required." />
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>
                                                        <FormattedMessage id="forms.labels.register_num" defaultMessage="Register Number" />
                                                    </Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('company_reg_no')} value={this.state.company_reg_no || ''} disabled={!this.state.is_company} />
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col}>
                                                    <Form.Label><FormattedMessage id="modalEditProfileInfo.address" defaultMessage="Address" /> </Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('address_line_1')} value={this.state.address_line_1 || ''} disabled={!this.state.is_company} />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label><FormattedMessage id="modalEditProfileInfo.address" defaultMessage="Address" /> 2</Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('address_line_2')} value={this.state.address_line_2 || ''} disabled={!this.state.is_company} />
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formZIP">
                                                    <Form.Label><FormattedMessage id="modalEditProfileInfo.zip" defaultMessage="ZIP" /></Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('post_code')} value={this.state.post_code || ''} disabled={!this.state.is_company} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formCity">
                                                    <Form.Label><FormattedMessage id="modalEditProfileInfo.city" defaultMessage="City" /></Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('company_city')} value={this.state.company_city || ''} disabled={!this.state.is_company} />
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formState">
                                                    <Form.Label><FormattedMessage id="modalEditProfileInfo.state" defaultMessage="State" /></Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('state')} value={this.state.state || ''} disabled={!this.state.is_company} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formCountry">
                                                    <Form.Label><FormattedMessage id="modalEditProfileInfo.country" defaultMessage="Country" /></Form.Label>
                                                    <ReactFlagsSelect className="form-control flag-select-wrap" 
                                                        type="button"
                                                        searchable={true} 
                                                        defaultCountry={this.state.company_country ? this.state.company_country : 'US'} 
                                                        onSelect={(c) => this.onSelectFlag(c)}
                                                        disabled={!this.state.is_company}
                                                    />
                                                </Form.Group>
                                            </Form.Row>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className="setup-step-btns">
                        <div className="btn btn-default btn-developers" onClick={() => history.push('/profile-setup/1')}>
                            <FormattedMessage id="common.back" defaultMessage="Go back" />
                        </div>
                        <Button type="button" className="btn btn-default" onClick={(e) => this.handleSubmit(e)} >{role.type === 'DEV' ? langService.messages['common.next'] : langService.messages['common.finish']}</Button>
                    </Col>
                </Row>

            </Form>
        )
    }

}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateRoleStart,
    GetRoleStart
})(FormCompanyInformation));

