import React from 'react';
import './formPersonalInformation.scss';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import ModalAddCover from './modalAddCover';
import ModalAddAvatar from './modalAddAvatar';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { UpdateRoleStart, UploadRoleCoverPictureStart, UploadRoleProfilePictureStart } from '../../../core/actions/role';
// import Countries from '../../../utils/i18n/countries.js';
import { langService } from './../../../utils/languageService';

class FormProfileInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalCoverVisible: false,
            modalAvatarVisible: false,
            name: this.props.role.name,
            description: this.props.role.description,
            country: this.props.role.country,
            city: this.props.role.city,
            cover_image: this.props.role.cover_image,
            profile_image: this.props.role.profile_image,
            coverPreview: this.props.role.cover_image,
            avatarPreview: this.props.role.profile_image
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            this.setState({
                id: nextProps.role.id,
                name: nextProps.role.name,
                company_name: nextProps.role.company_name,
                vat: nextProps.role.vat,
                first_name: nextProps.role.first_name,
                last_name: nextProps.role.last_name,
                description: nextProps.role.description,
                address_line_1: nextProps.role.address_line_1,
                address_line_2: nextProps.role.address_line_2,
                post_code: nextProps.role.post_code,
                city: nextProps.role.city,
                state: nextProps.role.state,
                country: nextProps.role.country
            });
        }
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    onSelectFlag(countryCode) {
        this.setState({ country: countryCode });
    }

    next() {
        this.props.UpdateRoleStart({
            name: this.state.name,
            description: this.state.description,
            country: this.state.country,
            city: this.state.city
        }, 2);

    }

    hideModalCover = (img) => {
        if (img) {
            var that = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ cover_image: img, coverPreview: e.target.result, modalCoverVisible: false });
            }
            reader.readAsDataURL(img);
            this.props.UploadRoleCoverPictureStart(img);
        } else {
            this.setState({ modalCoverVisible: false });
        }
    }

    hideModalAvatar = (img) => {
        if (img) {
            var that = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ profile_image: img, avatarPreview: e.target.result, modalAvatarVisible: false });
            }
            reader.readAsDataURL(img);
            this.props.UploadRoleProfilePictureStart(img);
        } else {
            this.setState({ modalAvatarVisible: false });
        }
    }

    renderModalCoverImage() {
        if (this.state.modalCoverVisible) {
            return (
                <ModalAddCover
                    visible={this.state.modalCoverVisible}
                    image={null}
                    onClose={(img) => this.hideModalCover(img)}
                />
            )
        }
    }

    renderModalLogoImage() {
        if (this.state.modalAvatarVisible) {
            return (
                <ModalAddAvatar
                    visible={this.state.modalAvatarVisible}
                    image={null}
                    onClose={(img) => this.hideModalAvatar(img)} />
            )
        }
    }

    render() {
        const { role } = this.props;
        return (
            <>
                <Row>
                    <Col>
                        <div className="card p-0">
                            <div className="card-head">
                                {role.type === "DEV" && langService.messages['profile.personal']}
                                {role.type === "INV" && langService.messages['profile.public']}
                            </div>
                            <div className="card-body sp-cardbody">
                                <div className="" style={{ "marginBottom": "20px" }}>
                                        {langService.messages['profile.public.desc']}
                                </div>

                                <div className="sp-header">

                                    <div className="sp-cover">
                                        <Image src={this.state.coverPreview ? this.state.coverPreview : "/assets/img/profile-cover.png"} className="p-cover-img"></Image>
                                        <div className="btn btn-default btn-trans btn-spchangecover" onClick={() => this.setState({ modalCoverVisible: true })}>
                                            <FormattedMessage id="profile.change.cover" defaultMessage="Change Cover" />
                                        </div>
                                    </div>

                                    <div className="sp-avatar-wrapper">

                                        <Image src={this.state.avatarPreview ? this.state.avatarPreview : "/assets/img/default-avatar.png"} className="sp-avatar"></Image>

                                        <div className="sp-avatar-overlay" onClick={() => this.setState({ modalAvatarVisible: true })}>
                                            <Image src="/assets/img/svg/camera.svg" fluid onClick={() => this.setState({ modalAvatarVisible: true })}></Image>
                                            <div className="" onClick={() => this.setState({ modalAvatarVisible: true })}>
                                                <FormattedMessage id="common.upload" defaultMessage="Upload" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {this.renderModalCoverImage()}
                                {this.renderModalLogoImage()}

                                <div className="sp-formwrap">
                                    <div style={{ "padding": "10px" }}>
                                        <div>
                                            <Row>
                                                <Form.Group as={Col} controlId="formName">
                                                    <Form.Label><FormattedMessage id="modalEditProfileInfo.displayname" defaultMessage="Display Name" /></Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('name')} value={this.state.name || ''} />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} controlId="formDescription">
                                                    <Form.Label><FormattedMessage id="forms.labels.description" defaultMessage="Description" /></Form.Label>
                                                    <Form.Control as="textarea" rows="2" onChange={this.handleChange('description')} value={this.state.description || ''} />
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <Form.Group as={Col} controlId="formCountry">
                                                    <Form.Label><FormattedMessage id="forms.labels.country" defaultMessage="Country" /></Form.Label>
                                                    <ReactFlagsSelect className="form-control flag-select-wrap" 
                                                        searchable={true} 
                                                        defaultCountry={this.state.country || ''} 
                                                        placeholder={langService.messages['forms.placeholders.select_country']}
                                                        searchPlaceholder={langService.messages['forms.placeholders.search_country']}
                                                        onSelect={(c) => this.onSelectFlag(c)} />
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="formCity">
                                                    <Form.Label>
                                                        <FormattedMessage id="forms.labels.city" defaultMessage="City" />
                                                    </Form.Label>
                                                    <Form.Control type="text" onChange={this.handleChange('city')} value={this.state.city || ''} />
                                                </Form.Group>
                                            </Row>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className="setup-step-btns">
                        <div></div>
                        {/* {role.type === "DEV" ?
                            <div className="btn btn-default btn-developers" onClick={() => history.push('/profile-setup/1')}>
                                <FormattedMessage id="common.back" defaultMessage="Go Back" />
                            </div>
                            : <div></div>
                        } */}
                        <div className="btn btn-default btn-developers" onClick={() => this.next()}>
                            <FormattedMessage id="common.next" defaultMessage="Next Step" />
                        </div>
                    </Col>
                </Row>
            </>

        )
    }

}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateRoleStart,
    UploadRoleProfilePictureStart,
    UploadRoleCoverPictureStart
})(FormProfileInformation));