//Projects
export const CHANGE_PROJECT_FILTERS = "CHANGE_PROJECT_FILTERS";

export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

export const SELECT_PROJECT_REQUEST = "SELECT_PROJECT_REQUEST";
export const SELECT_PROJECT_SUCCESS = "SELECT_PROJECT_SUCCESS";
export const SELECT_PROJECT_FAILURE = "SELECT_PROJECT_FAILURE";

export const DESELECT_PROJECT = 'DESELECT_PROJECT';

//-------------------
//  Project Image actions
//-------------------
export const UPLOAD_PROJECT_LOGO_PICTURE_REQUEST = "UPLOAD_PROJECT_LOGO_PICTURE_REQUEST";
export const UPLOAD_PROJECT_LOGO_PICTURE_SUCCESS = "UPLOAD_PROJECT_LOGO_PICTURE_SUCCESS";
export const UPLOAD_PROJECT_LOGO_PICTURE_FAILURE = "UPLOAD_PROJECT_LOGO_PICTURE_FAILURE";

export const UPLOAD_PROJECT_COVER_PICTURE_REQUEST = "UPLOAD_PROJECT_COVER_PICTURE_REQUEST";
export const UPLOAD_PROJECT_COVER_PICTURE_SUCCESS = "UPLOAD_PROJECT_COVER_PICTURE_SUCCESS";
export const UPLOAD_PROJECT_COVER_PICTURE_FAILURE = "UPLOAD_PROJECT_COVER_PICTURE_FAILURE";

//MyProjects
export const GET_MY_PROJECTS_REQUEST = "GET_MY_PROJECTS_REQUEST";
export const GET_MY_PROJECTS_SUCCESS = "GET_MY_PROJECTS_SUCCESS";
export const GET_MY_PROJECTS_FAILURE = "GET_MY_PROJECTS_FAILURE";

export const SELECT_MY_PROJECT_REQUEST = "SELECT_MY_PROJECT_REQUEST";
export const SELECT_MY_PROJECT_SUCCESS = "SELECT_MY_PROJECT_SUCCESS";
export const SELECT_MY_PROJECT_FAILURE = "SELECT_MY_PROJECT_FAILURE";

export const DESELECT_MY_PROJECT = 'DESELECT_MY_PROJECT';

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";