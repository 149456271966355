import React from 'react';
import './tabDisputeSettlement.scss';
import { connect } from 'react-redux';
import moment from 'moment';
import { Accordion } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import ModalSettlementAddComment from '../../components/Audit/modalSettlementAddComment';
import { UpdateTaskDisputeSettlementStart } from '../../core/actions/taskDisputeSettlement';
import { UpdateTaskDisputeSettlementTopicStart } from '../../core/actions/taskDisputeSettlementTopic';


class TabDisputeSettlement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalReplyVisible: false,
            eventKey: 0,
            expandedPanels: [],
            showEditPercentage: false,
            proposed_percentage: 100
        };
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({showEditPercentage: false});
    }

    activateToggle(eventKey) {
        let arr = this.state.expandedPanels;
        if (arr.includes(eventKey)) {
            arr.splice(arr.indexOf(eventKey), 1);
        } else {
            arr.push(eventKey);
        }
        this.setState({ expandedPanels: arr });
    }

    isExpanded(key) {
        if (this.state.expandedPanels.includes(key)) {
            return true;
        }
        return false;
    }

    resolve(topic) {
        this.props.UpdateTaskDisputeSettlementTopicStart(topic.id, null, null, true);
    }

    reply(topic) {
        this.setState({ topic: topic, modalReplyVisible: true });
    }

    handleChange = input => event => {
        this.setState({ [input]: parseInt(event.target.value) });
    }

    onCounter(send) {
        if (send) {
            this.props.UpdateTaskDisputeSettlementStart(
                this.props.settlement.task,
                'SEND_IN_NEGOTIATION',
                this.props.settlement.last_task_dispute_settlement_change,
                this.state.proposed_percentage)
            .then(res => {
                this.setState({showEditPercentage: false});    
            });               
        } else {
            this.setState({ proposed_percentage: this.props.settlement.proposed_percentage, showEditPercentage: true});    
        }
                 
    }

    onAccept() {
        this.props.UpdateTaskDisputeSettlementStart(
            this.props.settlement.task,
            'ACCEPT',
            this.props.settlement.last_task_dispute_settlement_change)
        .then(res => {
            this.setState({showEditPercentage: false});    
        });            
    }

    onEscalate() {
        this.props.UpdateTaskDisputeSettlementStart(
            this.props.settlement.task,
            'ESCALATE',
            this.props.settlement.last_task_dispute_settlement_change)
        .then(res => {
            this.setState({showEditPercentage: false});    
        });            
    }

    render() {
        const { selectedTask, settlement, role } = this.props;
        if (!settlement.status) return null;
        return (
            <>
                <div className="proposed-solution">

                    {!this.state.showEditPercentage &&
                        <div className="settlement-info">
                        
                        {(role.type === "INV" && !settlement.available_actions.includes('ACCEPT'))  && <FormattedMessage id="roles.name.INV" defaultMessage="Client " />}
                        {(role.type === "INV" && settlement.available_actions.includes('ACCEPT'))  && <FormattedMessage id="roles.name.DEV" defaultMessage="Freelancer" />}
                        {(role.type === "DEV" && !settlement.available_actions.includes('ACCEPT'))  && <FormattedMessage id="roles.name.DEV" defaultMessage="Freelancer" />}
                        {(role.type === "DEV" && settlement.available_actions.includes('ACCEPT'))  && <FormattedMessage id="roles.name.INV" defaultMessage="Client" />}
                        &nbsp;<FormattedMessage id="dispute.proposed" defaultMessage="proposed a resolution to pay" /> {parseInt(settlement.proposed_percentage)}% <FormattedMessage id="dispute.of_price" defaultMessage=" of the agreed price." />
                    </div>
                    }
                    {this.state.showEditPercentage &&
                    <div className="settlement-info">
                        <FormattedMessage id="dispute.i_propose" defaultMessage="I propose a payment of  " /> 
                        <input type="number" className="input-percentage" min="0" max="100" 
                            value={parseInt(this.state.proposed_percentage)} 
                            onChange={this.handleChange('proposed_percentage')}>    
                        </input>
                        <FormattedMessage id="dispute.of_price_2" defaultMessage="% of the agreed price is payed." /> 
                    </div>
                    }

                    {selectedTask.status !== 'COMPLETED' &&
                        <div className="settlement-actions mt-2">
                        {settlement.available_actions?.map((a, i) => {
                            if (a === "SEND_IN_NEGOTIATION") {
                                if (this.state.showEditPercentage) {
                                    return <div key={i} className="btn btn-default btn-projects mr-2" onClick={()=> this.onCounter(true)}><FormattedMessage id="dispute.send_counter" defaultMessage="SEND COUNTER" /></div>   
                                } else {
                                    return <div key={i} className="btn btn-default btn-projects mr-2" onClick={()=> this.onCounter(false)}><FormattedMessage id="dispute.counter" defaultMessage="COUNTER" /></div>    
                                }
                            } else  if (a === "ACCEPT") {
                                return <div key={i} className="btn btn-default btn-projects mr-2" onClick={()=> this.onAccept()}><FormattedMessage id="dispute.accept" defaultMessage="ACCEPT" /></div>
                            } else if (a === "ESCALATE") {
                                return <div key={i} className="btn btn-default btn-projects mr-2"  onClick={()=> this.onEscalate()}><FormattedMessage id="dispute.send_to-audit" defaultMessage="SEND TO AUDIT" /></div>
                            } 
                            return null;
                        })}
                        </div>
                    }

                { settlement.status === "ESCALATED" && <div className="escalated"><FormattedMessage id="dispute.was_escalated" defaultMessage="Dispute was escalated to auditor." /></div> }
                { settlement.status === "FAILED" && <div className="escalated"><FormattedMessage id="dispute.expired" defaultMessage="Dispute settlement time expired. The case was automatically escalated to auditor." /></div> }
                { (selectedTask.status === 'COMPLETED' && selectedTask.exists_dispute_settlement) && 
                    <div className="escalated"><FormattedMessage id="dispute.resolved" defaultMessage="Dispute was resolved by both parties accepting settlement agreement." /></div>
                }

                </div>

                <div className="keypoints-title">
                    <FormattedMessage id="dispute.keypoints_with_comments" defaultMessage="Dispute keypoints with comments:" /> 
                </div>

                {settlement.topics.map((topic, i) => (
                    <Accordion key={topic.id} defaultActiveKey={i}>
                        <Accordion.Toggle className="topic-header" eventKey={i} onClick={() => this.activateToggle(i)}>
                            <div className={topic.resolved ? 'resolved' : ''}>
                                { !topic.resolved && <span>● </span>}
                                { topic.resolved && <span>&#10004; </span>}
                                {topic.topic} 
                            </div>
                            <i className={this.isExpanded(i) ? "arrow up" : "arrow down"}></i>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i}>
                            
                            <div className="comments-wrap">
                                <div className="orig-comment-title"><FormattedMessage id="dispute.original_comment" defaultMessage="Original comment by the client:" /></div>
                                <div className="orig-comment mt-2 mb-2">"{topic.comment}"</div>

                                {topic.comments.length > 0 && <div className="orig-comment-title"><FormattedMessage id="dispute.replies" defaultMessage="Replies:" /></div> }

                                {topic.comments.map((c, x) => (
                                    <div className="replies mt-2" key={x}>
                                        <span className="c-date">{moment(c.datetime).format('DD.MM.YYYY HH:mm')}</span> -&nbsp;
                                        <span className="">{c.fromRole.type === 'DEV' ? <FormattedMessage id="roles.name.DEV" defaultMessage="Freelancer" /> : <FormattedMessage id="roles.name.INV" defaultMessage="Client " /> }</span> -&nbsp;
                                        <span className="b-reply">"{c.comment}"</span>
                                    </div>
                                ))}
                                {topic.resolved &&
                                <div className="replies mt-2">
                                    <span className="c-date">{moment(settlement.last_task_dispute_settlement_change).format('DD.MM.YYYY HH:mm')}</span> -&nbsp;
                                    <span className=""><FormattedMessage id="dispute.marked_resolved" defaultMessage="Client has marked this keypoint as resolved." /></span>
                                </div>
                                }

                                <div className="topic-actions">
                                { (settlement.status === 'IN_NEGOTIATION' &&  !topic.resolved) &&
                                    <button className="btn btn-default btn-projects mt-2" onClick={() => this.reply(topic)}><FormattedMessage id="dispute.reply" defaultMessage="Reply" /></button>
                                }
                                { (this.props.role.type === "INV" && !topic.resolved ) &&
                                    <button className="btn btn-default btn-projects ml-2 mt-2" onClick={() => this.resolve(topic)}><FormattedMessage id="dispute.mark_as_resolved" defaultMessage="MARK AS RESOLVED" /></button>
                                }
                                </div>

                            </div>

                        </Accordion.Collapse>
                    </Accordion>
                ))}

                <ModalSettlementAddComment visible={this.state.modalReplyVisible} topic={this.state.topic} onConfirm={() => this.onConfirm()} onClose={() => this.setState({ modalReplyVisible: false })} />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        selectedTask: state.myWork.myTasks.selected,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateTaskDisputeSettlementStart,
    UpdateTaskDisputeSettlementTopicStart
})(TabDisputeSettlement));