import * as types from './types';
import { apiCon } from '../../../API';
//----------------------------------------------
// Actions Task Dispute Settlement Topic Comment
//----------------------------------------------

//---------------------------------------------------
// Actions Get Task Dispute Settlement Topic Comments
//---------------------------------------------------
export const GetTaskDisputeSettlementTopicCommentsStart = (dispute_settlement_topic_id, limit = 100, search, ordering) => async dispatch => {
    dispatch({ type: types.GET_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENTS_REQUEST });
    var url = '/me/project/task/dispute_settlement/topic/comments/'
    const params = {
        'taskdisputesettlementtopic_id ': dispute_settlement_topic_id,
        'limit': limit,
        ...(search ? { 'search': search } : {}),
        ...(ordering ? { 'ordering': ordering } : {}),
    }
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetTaskDisputeSettlementTopicCommentsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetTaskDisputeSettlementTopicCommentsFail(err.response));
        })
}
export const GetTaskDisputeSettlementTopicCommentsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENTS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetTaskDisputeSettlementTopicCommentsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetTaskDisputeSettlementTopicCommentsFail(err.response));
        })
}
export const GetTaskDisputeSettlementTopicCommentsSuccess = (data) => {
    const payload = { succ_msg: "Successfully received Task Dispute Settlement Topic Comments info.", list: data }
    return { type: types.GET_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENTS_SUCCESS, payload: payload };
}
export const GetTaskDisputeSettlementTopicCommentsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Task Dispute Settlement Topic Comments failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get Task Dispute Settlement Topic Comments failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENTS_FAILURE, payload: payload }
}

//-----------------------------------------------------
// Actions Create Task Dispute Settlement Topic Comment
//-----------------------------------------------------
export const CreateDisputeSettlementTopicCommentStart = (task_dispute_settlement_topic_id, comment) => async dispatch => {
    dispatch({ type: types.CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENT_REQUEST });
    const values = {
        'taskdisputesettlementtopic': task_dispute_settlement_topic_id,
        'comment': comment
    }
    const address = `/me/project/task/dispute_settlement/topic/comments/`;
    const config = { headers: { 'content-type': 'application/json' } }
    await apiCon.post(address, JSON.stringify(values), config)
        .then(response => {
            dispatch(CreateDisputeSettlementTopicCommentSuccess(response.data, task_dispute_settlement_topic_id));
        })
        .catch(err => {
            dispatch(CreateDisputeSettlementTopicCommentFail(err.response));
        });
}
export const CreateDisputeSettlementTopicCommentSuccess = (data, topic_id) => {
    const succ_msg = "Task Dispute Settlement Topic Comment created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data, topic: topic_id };
    return { type: types.CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENT_SUCCESS, payload: payload };
}
export const CreateDisputeSettlementTopicCommentFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Ooops! Create Task Dispute Settlement Topic Comment failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create Task Dispute Settlement Topic Comment failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENT_FAILURE, payload: payload };
}

