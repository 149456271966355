import React from 'react';
import './taskProposalList.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { UpdateTaskProposalStart } from './../../core/actions/taskProposals/index';
import ModalAcceptTaskProposal from '../../components/Tasks/modalAcceptTaskProposal';
import DateRangeDuration from '../../components/Common/DateRangeDuration';
import { langService } from './../../utils/languageService/index';
import ModalSubmitTaskProposal from '../../components/Tasks/modalSubmitTaskProposal';
import TaskProposalContent from '../Tasks/taskProposalContent';

class TaskProposal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            btnCounter: langService.messages['common.counter'],
            proposalEdit: false,
            focusedInput: null,
            startDate: moment(props.tp.start_date),
            endDate: moment(props.tp.end_date),
            tpPrice: props.tp.price,
            modalVisible: false,
            modalProposalVisible: false
        };
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    reOffer() {
        // this.setState({ btnCounter: langService.messages['my_task.send_offer'], proposalEdit: true });
        this.setState({ modalProposalVisible: true });
    }

    cancelReOffer() {
        this.setState({ btnCounter: langService.messages['common.counter'], proposalEdit: false });
    }

    onDatesChange(startDate, endDate) {
        this.setState({ startDate, endDate });
    }

    hide() {
        this.setState({ modalVisible: false });
    }

    accept() {
        this.setState({ modalVisible: false });
        this.sendAction("ACCEPT");
    }

    renderModal(tp, selectedTask) {
        if (this.state.modalVisible) {
            return (
                <ModalAcceptTaskProposal visible={this.state.modalVisible} tp={tp} selectedTask={selectedTask} onClose={() => this.hide()} onAccept={() => this.accept()} />
            )
        }
    }

    renderModalSubmitTaskProposal() {
        const { role, selectedTask, tp } = this.props;

        if (selectedTask.id) {
            return (
                <ModalSubmitTaskProposal
                    visible={this.state.modalProposalVisible}
                    taskId={selectedTask.id}
                    clientId={selectedTask.project.client.id}
                    start={tp.start_date ? tp.start_date : selectedTask.start_date}
                    end={tp.end_date ? tp.end_date : selectedTask.end_date}
                    price={tp.price ? tp.price : selectedTask.price}
                    invite={false}
                    freelancerId={role.id}
                    competences={tp.competences}
                    action="counter"
                    existingProposal={tp}
                    onClose={() => this.hideModalSubmitProposal()}
                />);
        } else {
            return null;
        }
    }

    hideModalSubmitProposal() {
        this.setState({ modalProposalVisible: false });
    }

    sendAction(a) {
        const { tp } = this.props;
        this.props.UpdateTaskProposalStart({
            id: tp.id,
            action_type: a,
            last_task_proposal_change: tp.last_task_proposal_change,
        });
    }

    render() {
        const { tp, selectedTask } = this.props;
        return (
            <div className="proposal-item">
                <div className="when">
                    <DateRangeDuration start_date={new Date()} end_date={tp.last_task_proposal_change} />
                </div>

                <div className="proposal-item-inner">

                    <TaskProposalContent selectedTask={selectedTask} tp={tp} />

                    <div className="v-divider"></div>

                    <div className="proposal-actions">
                        {tp.available_actions?.map((a, i) => {
                            if (a === "COUNTER") {
                                return <button key={i} onClick={() => this.reOffer()}
                                    className={this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding btn-fullWidth mb-2"}>{this.state.btnCounter}</button>
                            } else if (a === "ACCEPT") {
                                return <button key={i} onClick={() => this.setState({ modalVisible: true })} className={this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding btn-fullWidth mb-2"}>
                                    <FormattedMessage id="common.accept" defaultMessage="Accept" />
                                </button>
                            } else {
                                return <button key={i} onClick={() => this.sendAction(a)}
                                    className={this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding btn-fullWidth mb-2"}>
                                    <FormattedMessage id="common.reject" defaultMessage="Reject" />
                                </button>
                            }

                        })}

                        {tp.available_actions.length === 0 &&
                            <p className={tp.status === "CONFIRMED" ? "tp-accepted" : "tp-rejected"}>
                                <FormattedMessage id={`common.${tp.status.toLowerCase()}`} defaultMessage={tp.status.toLowerCase()} />
                            </p>
                        }

                        {this.renderModal(tp, selectedTask)}
                        <button onClick={() => this.sendAction("COUNTER")}
                            className={!this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding btn-fullWidth mb-2"}>
                            <FormattedMessage id="my_task.send_offer" defaultMessage="Send offer" />
                        </button>
                        <button onClick={() => this.cancelReOffer()}
                            className={!this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding btn-fullWidth mb-2"}>
                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </button>
                    </div>

                </div>

                {this.state.modalProposalVisible && this.renderModalSubmitTaskProposal()}

            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateTaskProposalStart,
})(TaskProposal));