import React from 'react';
import '../../../assets/styles/partials/_auth.scss';
import { connect } from 'react-redux';
import { required } from '../../../utils/forms/validators'
import { Input } from '../../../utils/forms/formElements';
import { ForgotPasswordStart } from '../../../core/actions/auth';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { Form as BSForm, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


class ForgotPasswordForm extends React.Component {
    FORM_LABEL = 'forms.labels';
    FORM_PLC = 'forms.placeholders';

    onSubmit = values => {
        this.props.ForgotPasswordStart(values);
    }

    render() {
        const { formatMessage } = this.props.intl;
        // const prod = process.env.NODE_ENV === "production" ? true : false;
        return (
            <>
                <div id="overlay">
                    <img src="/assets/img/icon/lightbulb.svg" alt="Idea"></img>
                </div>
                <Container className="flex-center pt-65">
                    <Form
                        onSubmit={this.onSubmit}
                        render={props => (
                            <div className="auth">
                                <p><span className="underline"><FormattedMessage id="forms.forgotPasswordForm" defaultMessage="ForgotPasswordForm" /></span></p>
                                <BSForm validated={props.valid} onSubmit={props.handleSubmit}>
                                    <Field
                                        name="email"
                                        label={<FormattedMessage id={`${this.FORM_LABEL}.email`} defaultMessage="email" />}
                                        placeholder={formatMessage({ id: `${this.FORM_PLC}.email`, defaultMessage: "email" })}
                                        component={Input}
                                        validate={required}
                                    />
                                    <button type="submit" className="btn btn-default btn-generic btn-block mt-4"><FormattedMessage id={`${this.FORM_LABEL}.resetPassword`} defaultMessage="sendMail" /></button>
                                    <BSForm.Text className="auth-small">
                                        <FormattedMessage id={`${this.FORM_LABEL}.noAccountYet`} defaultMessage="noAccountYet" />
                                        <Link to="/auth/register" onClick={this.goToRegistration}><FormattedMessage id={`${this.FORM_LABEL}.goToRegistration`} defaultMessage="goToRegistration" /></Link>
                                    </BSForm.Text>
                                    <BSForm.Text className="auth-small">
                                        <FormattedMessage id={`${this.FORM_LABEL}.alreadyHaveAccount`} defaultMessage="alreadyHaveAccount" />
                                        <Link to="/auth/login" onClick={this.goToLogin}><FormattedMessage id={`${this.FORM_LABEL}.goToLogin`} defaultMessage="goToLogin" /></Link>
                                    </BSForm.Text>
                                    {/* { !prod ?  <pre><p ><code><small>{JSON.stringify(props.values, 0, 2)}</small></code></p></pre> : '' } */}
                                </BSForm>
                            </div>
                        )}
                    />
                </Container>
            </>
        )
    }
}

export default injectIntl(connect(null, { ForgotPasswordStart })(ForgotPasswordForm));
