import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import history from '../../utils/routes/history';
import { apiCon } from '../../API';
import { connect } from 'react-redux';
import { Container, Image } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import ModalCreateProgram from './modalCreateProgram';
import ModalCreateDegree from './modalCreateDegree';
import { GetEduDegreesStart, DeleteDegreeStart } from '../../core/actions/institution/degree';
import { GetMyEduProgramsStart, DeleteProgramStart, GetProgramDetailsStart } from '../../core/actions/institution/program';
import ModalConfirmDelete from '../../components/Common/ModalConfirmDelete/modalConfirmDelete';


class MyInstitutionPrograms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteProgramId: null,
            deleteDegreeId: null,
            editDegree: null,
            editProgram: null,
            modalCreateDegreeVisible: false,
            modalCreateProgramVisible: false
        };
    }

    delete() {
        if (this.state.deleteProgramId && !this.state.deleteDegreeId) {
            this.props.DeleteProgramStart(this.state.deleteProgramId).then(
                this.props.GetMyEduProgramsStart(),
                this.setState({ modalConfirmDeleteVisible: false, deleteProgramId: null, deleteDegreeId: null })
            );
            this.props.GetMyEduProgramsStart();
            this.setState({ modalConfirmDeleteVisible: false, deleteProgramId: null, deleteDegreeId: null });
        } else if (this.state.deleteDegreeId && !this.state.deleteProgramId ) {
            this.props.DeleteDegreeStart(this.state.deleteDegreeId).then(
                this.props.GetEduDegreesStart(),
                this.setState({ modalConfirmDeleteVisible: false, deleteProgramId: null, deleteDegreeId: null })
            );
            this.props.GetEduDegreesStart();
            this.setState({ modalConfirmDeleteVisible: false, deleteProgramId: null, deleteDegreeId: null });
        }
    }

    getFormatedCompetencies(data) {
        const arr = [];
        data.competences.map(x => {
            arr.push({
                value: x.id.toString(),
                label: x.name,
                level: x.level
            })
        })
        return arr;
    }

    getProgramDetailsForEdit(id) {
        apiCon.get('edu/program/' + id).then(res => {
            res.data.competences = this.getFormatedCompetencies(res.data);
            this.setState({ editProgram: res.data, modalCreateProgramVisible: true });
        })
        .catch(err => {
            process.env.NODE_ENV !== "production" && console.log('Bad Luck', err);
        })
        // this.props.GetProgramDetailsStart(id).then(res =>
        //     this.setState({ editProgram: res, modalCreateProgramVisible: true })
        // );
    }

    addNewProgram() {
        this.setState({ editProgram: null, modalCreateProgramVisible: true })
    }

    addNewDegree() {
        this.setState({ editDegree: null, modalCreateDegreeVisible: true })
    }

    renderPrograms() {
        const { programs } = this.props;
        return (
            <>
                {programs.map((x, i) =>
                    <div className="employee-wrap clickable" key={i}>
                    <div className="degree-wrap" onClick={() => this.getProgramDetailsForEdit(x.id)}>
                        <div className="degree-name">{x.name}</div>
                        <div className="degree-small-name">{x.degree.name}</div>
                        <div className="degree-desc">{x.description}</div>
                        <div className="card-tags mt-2">
                            {x.competences.map((c, j) =>
                                <span onClick={() => history.push('/competency/'+c.id)} key={j}>{c.name} L{c.level}</span>
                            )}
                        </div>
                    </div>
                    <div className="delete-subrole ml-3">
                        <Image src="/assets/img/svg/delete.svg" className="delete-icon" onClick={() => this.setState({ deleteProgramId: x.id, modalConfirmDeleteVisible: true })}></Image>
                    </div>
                </div>
                )}
            </>
        )
    }

    renderDegrees() {
        const { degrees } = this.props;
        return (
            <>
                {degrees.map((x, i) =>
                    <div className="employee-wrap clickable" key={i}>
                        <div className="degree-wrap" onClick={() => this.setState({ editDegree: x, modalCreateDegreeVisible: true })}>
                            <div className="degree-name">{x.name}</div>
                            <div className="degree-desc">{x.description}</div>
                        </div>
                        <div className="delete-subrole ml-3">
                            <Image src="/assets/img/svg/delete.svg" className="delete-icon" onClick={() => this.setState({ deleteDegreeId: x.id, modalConfirmDeleteVisible: true })}></Image>
                        </div>
                    </div>
                )}
            </>
        )
    }

    render() {
        return (
            <>
                <div className="edu-card-header">
                    <div className="card-head"><FormattedMessage id="edu.edu_programs" defaultMessage="Educational Programs" /></div>
                    <div>
                        <div className="btn btn-default btn-projects-inv btn-in-header" onClick={() => this.addNewProgram()}>+ <FormattedMessage id="edu.program" defaultMessage="Program" /></div>
                        <div className="btn btn-default btn-projects-inv btn-in-header" onClick={() => this.addNewDegree()}>+ <FormattedMessage id="edu.degree" defaultMessage="Degree" /></div>
                    </div>
                </div>

                <Container className="p-0 mt-2 pb-3">

                    <div className="edu-subtitle">
                        <FormattedMessage id="edu.manage_programs" defaultMessage="Manage educational programs and degrees of your institution." />
                    </div>
                    <hr />

                    <div className="mt-4 subrole-category"><FormattedMessage id="edu.programs" defaultMessage="Programs" /></div>
                    {this.renderPrograms()}
                    <hr />

                    <div className="mt-4 subrole-category"><FormattedMessage id="edu.degrees" defaultMessage="Degrees" /></div>
                    {this.renderDegrees()}

                </Container>
                {this.state.modalCreateDegreeVisible && <ModalCreateDegree visible={this.state.modalCreateDegreeVisible} degree={this.state.editDegree} onConfirm={() => this.props.GetEduDegreesStart()} onClose={() => this.setState({ modalCreateDegreeVisible: false })} />}
                {this.state.modalCreateProgramVisible && <ModalCreateProgram visible={this.state.modalCreateProgramVisible} program={this.state.editProgram} onConfirm={() => this.props.GetMyEduProgramsStart()} onClose={() => this.setState({ modalCreateProgramVisible: false })} />}
                {this.state.modalConfirmDeleteVisible && <ModalConfirmDelete visible={this.state.modalConfirmDeleteVisible} onClose={() => this.setState({ modalConfirmDeleteVisible: false })} onConfirm={() => this.delete()} />}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        degrees: state.myInstitution.myDegrees.list.results,
        programs: state.myInstitution.myPrograms.list.results,
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetEduDegreesStart,
    GetMyEduProgramsStart,
    GetProgramDetailsStart,
    DeleteProgramStart,
    DeleteDegreeStart
})(MyInstitutionPrograms));