import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DeleteIssuerStart, GetMyIssuersStart } from '../../core/actions/issuer';
import ModalConfirmDelete from '../../components/Common/ModalConfirmDelete/modalConfirmDelete';
import ModalCreateIssuer from '../../components/Issuer/modalCreateIssuer';
import ReactTooltip from 'react-tooltip';
import { langService } from '../../utils/languageService/index';


class MyInstitutionIssuerDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalCreateIssuerVisible: false,
            modalConfirmDeleteVisible: false
        };
    }

    // delete() {
    //     this.props.DeleteIssuerStart(this.props.role.id).then(
    //         this.setState({ modalConfirmDeleteVisible: false }),
    //         this.props.GetMyIssuersStart()
    //     );
    // }

    toolTipMessage(message, n) {
        return (
            <>
                <div className="info-icon-wrap">
                    <img src="/assets/img/icon/info.png" data-tip data-for={ "competency" + n } alt="info-icon" width="13"></img>
                </div>
                <ReactTooltip id={ "competency" + n } place="right" type="dark" effect="solid">
                    <div className="tooltip-txt">
                        {message}
                    </div>
                </ReactTooltip>
            </>
        )
    }

    render() {
        const { selectedIssuer, role } = this.props;
        return (
            <>
                <div className="edu-card-header">
                    <div className="card-head"><FormattedMessage id="issuers.issuer_details" defaultMessage="Issuer details" /></div>
                    {selectedIssuer.issuer_prefix
                        ?
                        <div>
                            {/* <div className="btn btn-default btn-projects-inv btn-in-header" onClick={() => this.setState({ modalConfirmDeleteVisible: true })}><FormattedMessage id="common.delete" defaultMessage="Delete" /></div> */}
                            <div className="btn btn-default btn-projects-inv btn-in-header" onClick={() => this.setState({ modalCreateIssuerVisible: true })}><FormattedMessage id="common.edit" defaultMessage="Edit" /></div>
                        </div>
                        :
                        <div>
                            <div className="btn btn-default btn-projects-inv btn-in-header" onClick={() => this.setState({ modalCreateIssuerVisible: true })}>+ <FormattedMessage id="common.add" defaultMessage="Add" /></div>
                        </div>
                    }
                </div>

                <Container className="p-0 mt-2 pb-3">

                    <div className="edu-subtitle">
                        <FormattedMessage id="issuers.manage_issuer" defaultMessage="Manage your issuer settings and competencies generating process." />
                    </div>
                    <hr />

                    {selectedIssuer.issuer_prefix
                        ?
                        <>
                            <div className="mt-4 subrole-category">
                                <div className="tooltip-parent">
                                    <div className="tooltip-title">
                                        <div><FormattedMessage id="issuers.issuer_name" defaultMessage="Issuer Id (used to generate competency prefix)" /></div>
                                        {this.toolTipMessage(langService.messages['tooltip.issuer_id'], 1)}
                                    </div>
                                </div>
                            </div>
                            <div className="issuer-detail">{selectedIssuer.name} ({selectedIssuer.issuer_prefix})</div>

                            <div className="mt-4 subrole-category">
                                <div className="tooltip-parent">
                                    <div className="tooltip-title">
                                        <div><FormattedMessage id="issuers.mint_type" defaultMessage="Digital Signature Type" /></div>
                                        {this.toolTipMessage(langService.messages['tooltip.issuer_task_competencies'], 2)}
                                    </div>
                                </div>
                            </div>
                            <div className="issuer-detail">
                                {selectedIssuer.task_mint_type === 1 && <FormattedMessage id="issuers.MINT_DISABLED" defaultMessage="Disabled" />}
                                {selectedIssuer.task_mint_type === 2 && <FormattedMessage id="issuers.MINT_ON_SYS_ISSUER" defaultMessage="System certificate" />}
                                {selectedIssuer.task_mint_type === 3 && <FormattedMessage id="issuers.MINT_ON_SAME_ISSUER" defaultMessage="Own certificate" />}
                            </div>

                            <div className="mt-4 subrole-category">
                                <div className="tooltip-parent">
                                    <div className="tooltip-title">
                                        <div><FormattedMessage id="issuers.allow_public_insert" defaultMessage="allow_public_insert" /></div>
                                        {this.toolTipMessage(langService.messages['tooltip.issuer_public_adding'], 3)}
                                    </div>
                                </div>
                            </div>
                            <div className="issuer-detail">
                                {selectedIssuer.allow_competence_public_insert && <FormattedMessage id="common.enabled" defaultMessage="Enabled" />}
                                {!selectedIssuer.allow_competence_public_insert && <FormattedMessage id="common.disabled" defaultMessage="Disabled" />}
                            </div>

                            <div className="mt-4 subrole-category">
                                <div className="tooltip-parent">
                                    <div className="tooltip-title">
                                        <div><FormattedMessage id="issuers.allow_voting" defaultMessage="allow_voting" /></div>
                                        {this.toolTipMessage(langService.messages['tooltip.issuer_public_voting'], 4)}
                                    </div>
                                </div>
                            </div>
                            <div className="issuer-detail">
                                {selectedIssuer.allow_competence_voting && <FormattedMessage id="common.enabled" defaultMessage="Enabled" />}
                                {!selectedIssuer.allow_competence_voting && <FormattedMessage id="common.disabled" defaultMessage="Disabled" />}
                            </div>

                            <div className="mt-4 subrole-category">
                                <div className="tooltip-parent">
                                    <div className="tooltip-title">
                                        <div><FormattedMessage id="issuers.is_external" defaultMessage="is_external" /></div>
                                        {this.toolTipMessage(langService.messages['tooltip.issuer_external_issuer_details'], 5)}
                                    </div>
                                </div>
                            </div>
                            <div className="issuer-detail">
                                {selectedIssuer.is_external && <FormattedMessage id="common.yes" defaultMessage="Enabled" />}
                                {!selectedIssuer.is_external && <FormattedMessage id="common.no" defaultMessage="Disabled" />}
                            </div>
                        </>
                        :
                        <></>
                    }

                </Container>
                {this.state.modalCreateIssuerVisible && <ModalCreateIssuer owner={role.id} issuer={selectedIssuer} visible={this.state.modalCreateIssuerVisible} onClose={() => this.setState({ modalCreateIssuerVisible: false })} onConfirm={() => this.props.GetMyIssuersStart()}  />}
                {this.state.modalConfirmDeleteVisible && <ModalConfirmDelete visible={this.state.modalConfirmDeleteVisible} onClose={() => this.setState({ modalConfirmDeleteVisible: false })} onConfirm={() => this.delete()} />}

            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        selectedIssuer: state.issuers.selected
    }
}

export default injectIntl(connect(mapStateToProps, {
    DeleteIssuerStart,
    GetMyIssuersStart
})(MyInstitutionIssuerDetails));