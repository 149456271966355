import React from 'react';
import '../../browse-styles.scss';
import '../index.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { ChangeEduFiltersStart } from '../../../../core/actions/role';
import { Select } from '../../../../utils/forms/formElements';

const EducationalInstitutionsFiltersComp = props => {
    return (
        <div className="filters-wrapper">
            <div className="filter-title-edu">
                <h6><FormattedMessage id="browse.common.commingsoon" defaultMessage="Coming soon" /></h6>
            </div>
            {/* <div className="filter-title-edu">
                <h6><FormattedMessage id="common.misc" defaultMessage="Misc" /></h6>
            </div> */}
            <div>
                <button style={{ width: '100%' }} onClick={() => props.ChangeEduFiltersStart()} className="btn btn-default btn-educationals">
                    <FormattedMessage id="forms.reset_filters" defaultMessage="Reset filters" />
                </button>
            </div>
        </div>
    );
}

const EducationalInstitutionsSearchSortComp = props => {
    const { formatMessage } = props.intl;

    const SORT_OPTIONS = [
        { label: formatMessage({ id: 'browse.common.sortby', defaultMessage: 'Sort by' }), value: '' }
    ]

    return (
        <div className="browse-search">
            <Form>
                <Form.Row>
                    <Col md={12} lg={9}>
                        <Form.Control
                            placeholder={formatMessage({ id: 'browse.common.search', defaultMessage: 'Search' })}
                            onChange={e => props.ChangeEduFiltersStart({ search: e.target.value })}
                            value={props.edusFilters.search}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <Select
                            blurinputonselect="true"
                            name="educationalinstitutions-sort-select"
                            style={{ display: 'inline' }}
                            options={SORT_OPTIONS}
                            value={props.edusFilters.ordering}
                            onChange={e => props.ChangeEduFiltersStart({ ordering: e.target.value })}
                        />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    edusFilters: state.browse.edus.list.filters
})

const EducationalInstitutionsFilters = injectIntl(connect(mapStateToProps, {
    ChangeEduFiltersStart
})(EducationalInstitutionsFiltersComp))

const EducationalInstitutionsSearchSort = injectIntl(connect(mapStateToProps, {
    ChangeEduFiltersStart
})(EducationalInstitutionsSearchSortComp));

export { EducationalInstitutionsFilters, EducationalInstitutionsSearchSort };