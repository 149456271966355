import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, Tabs, Tab, Image } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { SelectChatStart, SendMessageStart } from '../../core/actions/chat';
import DateRangeDuration from '../../components/Common/DateRangeDuration';
import { getShortName, shortenText } from './../../utils/common/index';
import { langService } from '../../utils/languageService/index';
import { REACT_APP_BASE_BACKEND_URL_BARE } from '../../utils/common/env-variables';

class MessagesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chatWith: null,
            chatUserId: null,
            chatTaskId: null,
            message: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ message: event.target.value });
    }

    selectChat(c) {
        this.setState({ chatWith: c, chatUserId: c?.user?.id, chatTaskId: c?.task?.id });
        this.props.SelectChatStart(c.user.id, (c.task ? c.task.id : null));
    }

    sendMessage() {
        if (this.state.message === '') { return; }

        this.props.SendMessageStart(
            this.state.chatWith.user.id,
            (this.state.chatWith.task ? this.state.chatWith.task.id : null),
            this.state.message,
            null //files
        );

        this.setState({ message: '' });
    }


    renderPeopleTab() {
        const { chats } = this.props;
        return (
            <div className="message-list">
                {chats.map((c, i) =>
                    c.task === null &&
                    <div className="message-item" key={i} onClick={() => this.selectChat(c)}>

                        <div className="item-thumb">
                            {c.user?.profile_image
                                ? <Image src={REACT_APP_BASE_BACKEND_URL_BARE() + c.user?.profile_image} />
                                : <Image src="/assets/img/default-avatar.png" />
                            }
                        </div>

                        <div className="item-body">
                            <div className="item-top">

                                <div className="msg-user">
                                    {getShortName(c.user?.name)}
                                    <span className="new-msg">●</span>
                                </div>
                                <div className="msg-time">
                                    <DateRangeDuration start_date={new Date()} end_date={c.last_message.datetime} />
                                </div>

                            </div>

                            <div className="item-bottom">
                                {shortenText(c.last_message.message, 20)}
                            </div>
                        </div>

                    </div>
                )}
            </div>
        )
    }

    renderTasksTab() {
        const { chats } = this.props;
        return (
            <div className="message-list">
                {chats.map((c, i) =>
                    c.task !== null &&
                    <div className="message-item" key={i} onClick={() => this.selectChat(c)}>

                        <div className="item-body">

                            <div className="item-top">

                                <div className="msg-user-small">
                                    <div className="small-thumb">
                                        {c.user?.profile_image
                                            ? <Image src={REACT_APP_BASE_BACKEND_URL_BARE() + c.user?.profile_image} />
                                            : <Image src="/assets/img/default-avatar.png" />
                                        }
                                    </div>
                                    {getShortName(c.user?.name)}
                                    <span className="new-msg">●</span>
                                </div>

                                <div className="msg-time">
                                    <DateRangeDuration start_date={new Date()} end_date={c.last_message.datetime} />
                                </div>

                            </div>

                            <div className="item-bottom-task">
                                {c.task?.name}
                            </div>

                        </div>

                    </div>
                )}
            </div>
        )

    }

    renderSidebar() {
        return (
            <div className="mypage-sidebar">

                <div className="sidebar-header">
                    <div>
                        <FormattedMessage id="messages.messages" defaultMessage="Messages" />
                    </div>
                </div>

                <div className="sidebar-filter">
                    <Form>
                        <Form.Control as="select" size="sm">
                            <option>
                                {langService.messages['browse.common.search']}...
                            </option>
                        </Form.Control>
                    </Form>
                </div>

                <Tabs defaultActiveKey="people" className="sidebar-tabs">
                    <Tab eventKey="people" title={langService.messages['messages.people']}>
                        {this.renderPeopleTab()}
                    </Tab>
                    <Tab eventKey="tasks" title={langService.messages['projects.tasks']}>
                        {this.renderTasksTab()}
                    </Tab>
                </Tabs>

            </div>
        )
    }

    renderChatWindow() {
        const { chats, selectedChat, role } = this.props;
        return (
            <div className="card p-0 message-window">

                <div className="message-header">
                    {this.state.chatWith.user.name}
                    <div className="chat-status">
                        {chats.map((c, x) =>
                            c.userId === this.state.chatUserId
                                ? c.is_online
                                    ? <><span className="chat-circle status-color-active">● </span>
                                        <FormattedMessage id="messages.online" defaultMessage="Online" />
                                    </>
                                    : <><span className="chat-circle status-color-draft">● </span>
                                        <FormattedMessage id="messages.offline" defaultMessage="Offline" />
                                    </>
                                : ''
                        )}
                    </div>
                </div>

                <div className="message-body">

                    {selectedChat.map((c, i) =>

                        <div className="message" key={i}>

                            <div className="item-top">
                                <div className="msg-user">
                                    {c.sender === role.id
                                        ? <>
                                            <FormattedMessage id="messages.you" defaultMessage="You" />
                                        </>
                                        : this.state.chatWith.user.name
                                    }
                                </div>
                                <div className="msg-time">
                                    <DateRangeDuration start_date={this.state.now} end_date={c.datetime} />
                                </div>
                            </div>

                            <div className="msg">
                                {c.message}
                            </div>

                        </div>
                    )}
                </div>
                <div className="message-bottom">
                    <div className="message-input">
                        <textarea className="message-textarea" value={this.state.message} onChange={this.handleChange} placeholder={langService.messages['forms.placeholders.messages']}></textarea>
                    </div>
                    <div className="message-controls">
                        <div className="ctrl-left">
                            <Image src="/assets/img/svg/image.svg" />
                            <Image src="/assets/img/svg/attachment.svg" />
                        </div>
                        <div className="ctrl-right">
                            <Image src="/assets/img/svg/send.svg" onClick={() => this.sendMessage()} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Container className="mt-4">
                <Row>

                    <Col xs={12} md={3} className="p-0 pb-3">
                        {this.renderSidebar()}
                    </Col>

                    <Col xs={12} md={9}>
                        {
                            this.state.chatWith
                                ?
                                this.renderChatWindow()
                                :
                                <div className="card message-window">
                                    <FormattedMessage id="forms.placeholders.select_chat" defaultMessage="Select a contact to chat..." />
                                </div>
                        }
                    </Col>

                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        chats: state.chat.list.results,
        selectedChat: state.chat.selected.messages.results,
    }
}

export default injectIntl(connect(mapStateToProps, {
    SelectChatStart,
    SendMessageStart
})(MessagesPage));