import moment from 'moment';
import { langService } from '../../utils/languageService';
import { apiCon } from '../../API';
/* Common functions used throughout the projects

    - Date utils
    - String utils
    - Currency utils

*/
export const getUrlParameterObject = (props, allowedProps = null) => {
    var object = new URLSearchParams();
    Object.keys(props).forEach(key => {
        if ((allowedProps === null || allowedProps.includes(key)) && props[key]) {
            if (Array.isArray(props[key])) {
                props[key].forEach(element => {
                    object.append(key, element)
                });
            } else {
                object.append(key, props[key]);
            }
        }
    })
    return object;
}

Array.prototype.unique = function () {
    var a = this.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i].id === a[j].id)
                a.splice(j--, 1);
        }
    }

    return a;
};

export const getParameterObject = (props, allowedProps) => {
    var object = {};
    Object.keys(props).forEach(key => {
        if (allowedProps.includes(key)) {
            object[key] = props[key];
        }
    })
    return object;
}

export const getShortName = (name) => {
    var res = name;
    var str = name.split(" ");
    if (str.length > 1) {
        res = str[0] + ' ' + str[1].charAt(0) + '.';
    }
    return res;
}

export const shortenText = (txt, length) => {
    var res = txt;
    if (txt.length > length) {
        res = txt.substring(0, length) + '...';
    }
    return res;
}

export const getFileName = (link) => {
    // try {
    return link.split('/').pop();
    // } catch (error) {
    //     return link;   
    // }
}

export const getFileExtension = (link) => {
    // try {
    return getFileName(link).split('.').pop();
    // } catch (error) {
    //     return getFileName(link);
    // }
}

export const contains = (array, value) => {
    array.forEach(element => {
        if (element === value) {
            return true;
        }
    });
    return false;
}

export const addDaysToDate = (date, days) => {
    const date_ = moment(date);
    return date_.add(days, 'days');
}
 
export const durationFromDateRange = (start, end) => {
    const startDate = moment(start);
    const timeEnd = moment(end);
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    return diffDuration.asSeconds()
}

export const formatDateRange = (d1, d2) => {
    const date1 = d1.getYear() === d2.getYear() ?
        new Intl.DateTimeFormat(langService.locale, {
            month: 'short',
            day: '2-digit'
        }).format(d1) :
        new Intl.DateTimeFormat(langService.locale, {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        }).format(d1)
    const date2 = new Intl.DateTimeFormat(langService.locale, {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    }).format(d2)
    return `${date1} - ${date2}`
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    s = s.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1)
}


export const downloadFile = (fullUrl) => {
    apiCon.get(fullUrl, { responseType: 'blob' })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', getFileName(fullUrl));
            document.body.appendChild(link);
            link.click();
        });
}

export const WorkingDays = (week) => {
    var res = '';
    if (week.indexOf("Monday") !== -1) {
        res += langService.messages['common.monday_short'];
    }
    if (week.indexOf("Tuesday") !== -1) {
        if (res) { res += ', '; }
        res += langService.messages['common.tuesday_short'];
    }
    if (week.indexOf("Wednesday") !== -1) {
        if (res) { res += ', '; }
        res += langService.messages['common.wednesday_short'];
    }
    if (week.indexOf("Thursday") !== -1) {
        if (res) { res += ', '; }
        res += langService.messages['common.thursday_short'];
    }
    if (week.indexOf("Friday") !== -1) {
        if (res) { res += ', '; }
        res += langService.messages['common.friday_short'];
    }
    if (week.indexOf("Saturday") !== -1) {
        if (res) { res += ', '; }
        res += langService.messages['common.saturday_short'];
    }
    if (week.indexOf("Sunday") !== -1) {
        if (res) { res += ', '; }
        res += langService.messages['common.sunday_short'];
    }
    return res;
}

export const WorkingDaysArr = (week) => {
    var res = [false, false, false, false, false, false, false];
    if (week.indexOf("Monday") !== -1) { res[0] = true; }
    if (week.indexOf("Tuesday") !== -1) { res[1] = true; }
    if (week.indexOf("Wednesday") !== -1) { res[2] = true; }
    if (week.indexOf("Thursday") !== -1) { res[3] = true; }
    if (week.indexOf("Friday") !== -1) { res[4] = true; }
    if (week.indexOf("Saturday") !== -1) { res[5] = true; }
    if (week.indexOf("Sunday") !== -1) { res[6] = true; }
    return res;
}

export const formatDateRangeDuration = (start, end) => {
    const seconds = durationFromDateRange(start, end)
    const weeks = (seconds / 604800) | 0;
    const months = (weeks / 4) | 0;
    const years = (months / 12) | 0;

    if (years !== 0) {
        return langService.formatRelativeTime(years, 'year', { numeric: 'auto' })
    } else if (months !== 0) {
        return langService.formatRelativeTime(months, 'month', { numeric: 'auto' })
    } else if (weeks !== 0) {
        return langService.formatRelativeTime(weeks, 'week', { numeric: 'auto' })
    } else {
        return langService.formatRelativeTime(seconds)
    }
}


export const getAuditCostString = (price) => {
    const cost = parseFloat(price) * 0.05;
    if (cost <= 5) {
        return "$5"
    } else {
        return "$" + cost.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " (5% " + langService.messages['common.of_task_price'] + ")";
    }
}
