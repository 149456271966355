import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { GET_CLIENTS_SUCCESS, CHANGE_CLIENT_FILTERS } from './../../actions/role/types';

export const INITIAL_STATE_FILTERS = {
    limit: 10,
    search: '',
    ordering: '',
    min_nmb_of_finished_tasks: '',
    min_nmb_of_active_tasks: '',
    min_nmb_of_finished_projects: '',
    min_nmb_of_active_projects: '',
    industry: ''
}

const INITIAL_STATE = {
    list: {
        filters: INITIAL_STATE_FILTERS,
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_CLIENT_FILTERS:
            return {
                ...state, list: {
                    ...state.list,
                    filters: { ...state.list.filters, ...action.payload.filters }
                }
            };
        case GET_CLIENTS_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? [...state.list.results, ...action.payload.list.results] : action.payload.list.results
                }
            };
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}