import React, { useEffect } from 'react';
import '../../browse-styles.scss';
import '../index.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { ChangeClientFiltersStart } from '../../../../core/actions/role';
import { GetProjectCategoriesStart } from '../../../../core/actions/projectCategories';
import { Select } from '../../../../utils/forms/formElements';


const ClientsFiltersComp = props => {
    useEffect(() => {
        props.GetProjectCategoriesStart(100);
    }, [])

    const initializeIndustryCategories = () => {
        const { projectCategories } = props;
        const categories = projectCategories?.map(c => ({ label: c.name, value: c.id }));
        categories.unshift({ label: 'All industries', value: '' });
        return categories;
    }

    const handleMinNumberChange = (type, value) => {
        if (value === '') {
            props.ChangeClientFiltersStart({ [`min_nmb_of_${type}`]: '' });
            return;
        }

        const isNumbersOnly = /^\d+$/.test(value);

        if (!isNumbersOnly)
            return;

        props.ChangeClientFiltersStart({ [`min_nmb_of_${type}`]: value });
    }

    return (
        <div className="filters-wrapper">
            <div className="filter-title-inv">
                <h6><FormattedMessage id="browse.common.industry" defaultMessage="Industry" /></h6>
                <Select
                    blurinputonselect="true"
                    name="clients-industry-select"
                    style={{ marginTop: '-10px' }}
                    options={initializeIndustryCategories()}
                    value={props.clientsFilters.industry}
                    onChange={e => props.ChangeClientFiltersStart({ industry: e.target.value })}
                />
            </div>
            <div className="filter-title-inv">
                <h6><FormattedMessage id="profile.completedtasksupper" defaultMessage="Completed tasks" /></h6>
                <Form.Group controlId="clients-finished-projects">
                    <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>Min</span>
                    <Form.Control type="text" value={props.clientsFilters.min_nmb_of_finished_projects} onChange={e => handleMinNumberChange('finished_projects', e.target.value)} />
                </Form.Group>
            </div>
            <div className="filter-title-inv">
                <h6><FormattedMessage id="browse.common.activeprojects" defaultMessage="Active projects" /></h6>
                <Form.Group controlId="clients-active-projects">
                    <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>Min</span>
                    <Form.Control type="text" value={props.clientsFilters.min_nmb_of_active_projects} onChange={e => handleMinNumberChange('active_projects', e.target.value)} />
                </Form.Group>
            </div>
            {/* <div className="filter-title-inv" style={{ marginBottom: '13px' }}>
                <h6><FormattedMessage id="common.misc" defaultMessage="Misc" /></h6>
            </div> */}
            <div>
                <button style={{ width: '100%' }} onClick={() => props.ChangeClientFiltersStart()} className="btn btn-default btn-employers">
                    <FormattedMessage id="forms.reset_filters" defaultMessage="Reset filters" />
                </button>
            </div>
        </div>
    );
}

const ClientsSearchSortComp = props => {
    const { formatMessage } = props.intl;

    const SORT_OPTIONS = [
        { label: formatMessage({ id: 'browse.common.sortby', defaultMessage: 'Sort by' }), value: '' },
        { label: formatMessage({ id: 'browse.order.highestrating', defaultMessage: 'Highest rating' }), value: '-rating__rating' },
        { label: formatMessage({ id: 'browse.order.lowestrating', defaultMessage: 'Lowest rating' }), value: 'rating__rating' },
        { label: formatMessage({ id: 'browse.order.mostactivetasks', defaultMessage: 'Most active tasks' }), value: '-statistics__active_task_count' },
        { label: formatMessage({ id: 'browse.order.mostfinishedtasks', defaultMessage: 'Most finished tasks' }), value: '-statistics__completed_task_count' }
    ]

    return (
        <div className="browse-search">
            <Form>
                <Form.Row>
                    <Col md={12} lg={9}>
                        <Form.Control
                            placeholder={formatMessage({ id: 'browse.common.search', defaultMessage: 'Search' })}
                            onChange={e => props.ChangeClientFiltersStart({ search: e.target.value })}
                            value={props.clientsFilters.search}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <Select
                            blurinputonselect="true"
                            name="clients-sort-select"
                            style={{ display: 'inline' }}
                            options={SORT_OPTIONS}
                            value={props.clientsFilters.ordering}
                            onChange={e => props.ChangeClientFiltersStart({ ordering: e.target.value })}
                        />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    clientsFilters: state.browse.clients.list.filters,
    projectCategories: state.projectCategory.list.results,
    isAuthenticated: state.user.auth.isAuthenticated
})

const ClientsFilters = injectIntl(connect(mapStateToProps, {
    ChangeClientFiltersStart,
    GetProjectCategoriesStart
})(ClientsFiltersComp))

const ClientsSearchSort = injectIntl(connect(mapStateToProps, {
    ChangeClientFiltersStart
})(ClientsSearchSortComp))

export { ClientsFilters, ClientsSearchSort };