import React from 'react'
import _ from 'lodash';
import './index.scss';
import '../browse-styles.scss';
import history from '../../../utils/routes/history';
import InfoBar from '../../../components/Infobar';
import BrowseNavigationControl from '../../../components/BrowseNavigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createLoadingSelector } from '../../../API/selector';
import { GetAuditorsStart, GetAuditorsChangePageStart } from '../../../core/actions/role';
import { AuditorsFilters, AuditorsSearchSort } from './AuditorsFilters';
import { isEqual } from 'lodash';
import { INITIAL_STATE_FILTERS } from '../../../core/reducers/browse/auditorReducer';
import { debounceFreeInputFields } from '../../../utils/common/browse';

class AuditorsPage extends React.Component {

    componentDidMount() {
        this.init();
    }

    init = () => {
        const { auditors, auditorsFilters } = this.props;

        if (auditors.length !== 0)
            return;

        this.props.GetAuditorsStart(auditorsFilters);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.auditorsFilters === this.props.auditorsFilters)
            return;

        if (isEqual(this.props.auditorsFilters, INITIAL_STATE_FILTERS)) {
            debounceFreeInputFields.cancel();
            this.props.GetAuditorsStart(this.props.auditorsFilters);
            return;
        }

        if (prevProps.auditorsFilters.search !== this.props.auditorsFilters.search) {
            debounceFreeInputFields([this.props.auditorsFilters.search], this.props.GetAuditorsStart, this.props.auditorsFilters);
            return;
        }

        debounceFreeInputFields.cancel();
        this.props.GetAuditorsStart(this.props.auditorsFilters);
    }

    componentWillUnmount() {
        debounceFreeInputFields.cancel();
    }

    selectAuditor(id) {

    }

    openDetails(c) {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;
        if (!isAuthenticated) 
            return history.push("/auth/login");
        if (isAuthenticated && isEmailVerified) 
            return history.push("/user/" + c.id);
        return null;
    }

    renderActions(c) {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;

        if (!isAuthenticated)
            return <button className="btn btn-default btn-developers" onClick={() => history.push('/auth/login')}><FormattedMessage id="projects.logintoview" defaultMessage="Login to view" /></button>

        if (!isEmailVerified)
            return (<button className="btn btn-default btn-developers"><FormattedMessage id="common.verify_email_to_view" defaultMessage="Verify email to view" /></button>)

        return (<button className="btn btn-default btn-developers" onClick={() => history.push("/user/" + c.id)}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>);
    }

    renderAuditors(auditors) {
        if (_.isEmpty(auditors)) {
            return <></>
        }
        return (
            <>
                {auditors.map((c, i) =>
                    <Card className="card-item card-item-auditors" key={i}>
                        <Card.Body>

                            <div className="card-thumb">
                                <img src="/assets/img/project-thumb-1.png" className="p-thumb" alt="Project thumb" />
                            </div>

                            <div className="card-details">
                                <Card.Title className="card-title browse-namelink" onClick={() => this.openDetails(c)}>{c.name}</Card.Title>
                                <div className="card-desc">
                                    <FormattedMessage id="forms.labels.short_description" defaultMessage="Short description" />
                                </div>
                                <div className="card-info">
                                    <span>
                                        <FormattedMessage id="browse.common.activeprojects" defaultMessage="Active Projects" />
                                        <strong>{1}</strong>
                                    </span>
                                    <span>
                                        <FormattedMessage id="browse.common.opentasks" defaultMessage="Open tasks" />
                                        <strong>{1}</strong>
                                    </span>
                                </div>
                            </div>

                            <div className="card-sep"></div>

                            <div className="card-actions">
                                <div className="p-info-name">
                                    {c.rating?.rating
                                        ?
                                        <><span className="p-star" role="img" aria-label="Star rating">⭐</span>
                                            <span><span className="rating-big">{c.rating?.rating}</span><span className="rating-small">/5</span></span></>
                                        : ''
                                    }
                                </div>

                                {this.renderActions(c)}
                                
                            </div>
                        </Card.Body>
                    </Card>
                )}
            </>
        )
    }


    render() {
        const { auditors } = this.props;
        return (
            <>
                <Jumbotron className="jumbo-aud mb-0">
                    <InfoBar />
                    <Container>
                        <h1><FormattedMessage id="auditors.heading" defaultMessage="Most Credible Auditors at one place" /></h1>
                    </Container>
                </Jumbotron>
                <Container className="mt-0">
                    <BrowseNavigationControl page="auditors" />
                    <Row>
                        <Col md={12} lg={3}>
                            <AuditorsFilters />
                        </Col>
                        <Col md={12} lg={9}>
                            <div className="browse-title title-aud">
                                <FormattedMessage id="browse.auditors" defaultMessage="Auditors" />
                            </div>
                            <AuditorsSearchSort />

                            <span className="items-count">
                                <FormattedMessage id="plural.auditors" values={{ count: auditors.length }}
                                    defaultMessage={`${auditors.length} Auditors`} />
                            </span>

                            <InfiniteScroll
                                dataLength={auditors.length}
                                next={() => setTimeout(() => this.props.nextUrl && this.props.GetAuditorsChangePageStart(this.props.nextUrl), 500)}
                                hasMore={auditors.length !== this.props.count}
                                loader={<div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.loading" defaultMessage="Loading" />...</div>}
                                endMessage={auditors.length > 0 && <div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.allrecordsloaded" defaultMessage="All records loaded" /></div>}
                            >
                                <div className="card-list-projects">
                                    {this.renderAuditors(auditors)}
                                </div>
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['GET_AUDITORS']);
const mapStateToProps = state => {
    return {
        isLoading: loadingSelector(state),
        auth: state.user.auth,
        isEmailVerified: state.user.info.isEmailVerified,
        auditors: state.browse.auditors.list.results,
        auditorsFilters: state.browse.auditors.list.filters,
        nextUrl: state.browse.auditors.list.next,
        count: state.browse.auditors.list.count
    }
}
export default injectIntl(connect(mapStateToProps, {
    GetAuditorsStart,
    GetAuditorsChangePageStart
})(AuditorsPage));