import { combineReducers } from "redux";
import myDegreesReducer from './myDegreesReducer';
import myProgramsReducer from './myProgramsReducer';
import myStudentsReducer from './myStudentsReducer';


export default combineReducers({
    myDegrees: myDegreesReducer,
    myPrograms: myProgramsReducer,
    myStudents: myStudentsReducer
})