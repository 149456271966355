export default {
    en: {
        'common.save': 'Save',
        'common.save_and_continue': 'Save and Continue',
        'common.save_and_add': 'Save and add more',
        'common.confirm_and_save': 'Confirm and Save',
        'common.edit': 'Edit',
        'common.remove': 'Remove',
        'common.delete': 'Delete',
        'common.close': 'Close',
        'common.download': 'Download',
        'common.cancel': 'Cancel',
        'common.counter': 'Counter',
        'common.reject': 'Reject',
        'common.accept': 'Accept',
        'common.add': 'Add',
        'common.add_new': '+ Add new',
        'common.upload': 'Upload',
        'common.submit': 'Submit',
        'common.verify': 'Verify',
        'common.verified': 'Verified',
        'common.back': 'Go Back',
        'common.next': 'Next Step',
        'common.finish': 'Finish',
        'common.project': 'Project',
        'common.task': 'Task',
        'common.rec_task': 'Recurring Task',
        'common.misc': 'Misc',
        'common.read_more': 'Read more...',
        'common.contribute': 'CONTRIBUTE',
        'common.level': 'Level',
        'common.logintocontribute': 'Login to contribute',
        'common.verifytocontribute': 'Verify email to contribute',
        'common.history': 'History',
        'common.yes_delete': 'YES, DELETE',
        'common.sure_about_delete': 'Are you sure you want to delete this item?',
        'common.confirmation': 'Confirmation',
        'common.up': 'Up',
        'common.down': 'Down',
        'common.enabled': 'Enabled',
        'common.disabled': 'Disabled',
        'common.yes': 'YES',
        'common.no': 'NO',
        'common.all': 'All',
        'common.from': 'From',
        'common.to': 'To',
        'common.from_years': 'Year from',
        'common.to_years': 'Year to',
        'common.draft': 'Draft',
        'common.published': 'Published',
        'common.in_negotiation': 'In Negotiation',
        'common.active': 'Active',
        'common.in_progress': 'In Progress',
        'common.submitted': 'Submitted',
        'common.pending_audit': 'Pending Audit',
        'common.audit': 'Audit',
        'common.in_audit': 'In Audit',
        'common.in_dispute': 'In dispute',
        'common.auditor_confirmed': 'Auditor Confirmed',
        'common.auditor_failed': 'Auditor Failed',
        'common.completed': 'Completed',
        'common.confirmed': 'Confirmed',
        'common.rejected': 'Rejected',
        'common.pending': 'Pending',
        'common.invited': 'Invited',
        'common.closed': 'Closed',
        'common.open': 'Open/Active',
        'common.confirm': 'CONFIRM',
        'common.failed': 'Failed',
        'common.public': 'Public',
        'common.private': 'Private',
        'common.files.no_preview': 'Cannot preview this file. Please download to view.',
        'common.files.preview': 'File viewer',
        'common.comment': 'Comment',
        'common.custom': 'Custom',
        'common.active_freelancer': 'Active Freelancer',
        'common.recurring': "Recurring",
        'common.reccurrance': "Task Recurrance",
        'common.reccurring_task': "Recurring Task",
        'common.reccurring_type': "Recurring Type",
        'common.reccurring_times': "Repeat X",
        'common.interval': "INTERVAL (start/end date)",
        'common.weekly': "WEEKLY",
        'common.monthly': "MONTHLY",
        'common.create': "CREATE",
        'common.milestone': "Milestone",
        'common.milestones': "Milestones",
        'common.milestones.create': "Create a New Milestone",
        'common.conditions': "Conditions",
        'common.of_task_price': "of task price",
        'common.task_success': "Task success",
        'common.search_freelancers': 'Search freelancers',
        'common.administrator': 'Administrator',
        'common.administrators': 'Administrators',
        'common.monday_short': 'Mon',
        'common.tuesday_short': 'Tue',
        'common.wednesday_short': 'Wed',
        'common.thursday_short': 'Thu',
        'common.friday_short': 'Fri',
        'common.saturday_short': 'Sat',
        'common.sunday_short': 'Sun',
        'common.monday': 'Monday',
        'common.tuesday': 'Tuesday',
        'common.wednesday': 'Wednesday',
        'common.thursday': 'Thursday',
        'common.friday': 'Friday',
        'common.saturday': 'Saturday',
        'common.sunday': 'Sunday',
        'common.year': 'Year',
        'common.days': 'Days',
        'common.hours': 'Hours',
        'common.minutes': 'Minutes',
        'common.seconds': 'Seconds',
        'common.mins': 'Min',
        'common.secs': 'Sec',
        'common.ooops': 'Ooops!',
        'common.warning': 'Warning',
        'common.page_not_found': "The page you requested does not exist or you don't have the permission to view the URL.",
        'common.leave_a_comment': 'Leave a comment...',
        'common.name': 'Name',
        'common.list': 'List',
        'common.national_id': 'National ID number',
        'common.verify_email_to_view': 'Verify email to view',
        'common.did_not_get_verify_email': 'Did not receive activation email?',
        'common.confirmDeleteTitle': 'Confirmation',
        'common.confirmDeleteText': 'Are you sure you want to delete this item?',
        'common.addCompetency': 'Add a competency',
        'common.definition': 'Definition',
        'common.relations': 'Relations',
        'competency.save_button': 'Save competency',
        'competency.publish_button': 'Publish competency',
        'competency.define_common': 'Defines where this competency is commnly used.',
        'common.alertTitle': 'Alert',
        'common.alertTextCompetencies': 'Choose at least 5 competencies.',
        'common.alertTextDisputeCase': 'You must choose percentage betwen 5 and 100.',

        'tooltip.fill_out': 'Fill out these sections:',
        'tooltip.email_verification': 'Email verification',
        'tooltip.personal_info': 'Personal info',
        'tooltip.description': 'Description',
        'tooltip.profile_image': 'Profile image',
        'tooltip.cover_image': 'Cover image',
        'tooltip.availability': 'Availability',
        'tooltip.competencies': 'Competencies',
        'tooltip.experience': 'Experience',
        'tooltip.education': 'Education',
        'tooltip.issuer_id': 'Used to generate competency Id prefix. Example "universename_competencyname".',
        'tooltip.issuer_task_competencies': 'Control if and how do you allow your competencies to be used in tasks created by clients and earned by freelancers.',
        'tooltip.issuer_disabled': 'Do not allow third party usage of our competencies in tasks.',
        'tooltip.issuer_sys_certificate': 'Allow using our competences in tasks by making copies signed with system issuer.',
        'tooltip.issuer_my_certificate': 'Allow using our competences in tasks.',
        'tooltip.issuer_public_adding': 'Allow any third person to create new competencies on behalf of your name.',
        'tooltip.issuer_public_voting': 'Make your competencies definitions and relations subject to public consensus about content of competencies.',
        'tooltip.issuer_external_issuer': 'Select this if you are external issuer our platform will try to sync your competencies. Contact us for more details.',
        'tooltip.issuer_external_issuer_details': 'If you are external issuer our platform will try to sync your competencies. Contact us for more details.',

        'tooltip.earned_competencies': 'Competencies the user was awarded either by completing tasks or through a verified educational program.',
        'tooltip.userdefined_competencies': 'Competencies added to his profile by the user himself or herself.',
        'common.earned': 'EARNED', 
        'common.user_defined': 'USER DEFINED', 

        'setup_profile.company_info': 'Company information',
        'setup_profile.required': 'Required information before you can hire someone',
        'setup_profile.work_info': 'Work information',
        'setup_profile.availability': 'Set up your current availability',
        'setup_profile.full_time': 'Full time',
        'setup_profile.part_time': 'Part time',
        'setup_profile.all': 'All',
        'setup_profile.not_available': 'Not accepting work',
        'setup_profile.hourly_rate': 'Your hourly rate',
        'setup_profile.select_days': 'Select working days',
        'setup_profile.desc': 'With ECTA you can find the perfect projects for you and get to work!',
        'setup_profile.finish': 'Great job!',
        'setup_profile.finish.desc': 'You can keep improving your profile through My Profile page.',
        'setup_profile.projects.desc': "We have already found some projects for you!",
        'setup_profile.projects': 'Browse projects',
        'setup_profile.profile': 'View my profile',
        'setup_profile.fill_company_data': 'Please fill out the following information if you represent a company.',
        'setup_profile.represent': 'I represent a company',
        'setup_profile.projects_duration': 'Projects duration',
        'setup_profile.interest_projects_duration': 'I\'m interested in projects with duration',
        'setup_profile.interest_task_pricing': 'I\'m interested in tasks with remuneration',
        'setup_profile.dev.finished': 'We already found some task for you!',
        'setup_profile.inv.finished': 'Browse through existing projects or create a new project.',
        'setup_profile.edu.finished': 'Browse through educational institutions or go to MyInstitution page.',
        'setup_profile.aud.finished': 'Browse through auditors or go to MyAudits page.',

        'activate_profile.awesome': 'Awesome!',
        'activate_profile.desc': 'Your account has been activated!',
        'activate_profile.desc_long': 'You can now login and continue with setting up your profile.',
        'activate_profile.error': 'Ooops!',
        'activate_profile.error_desc': 'Token is invalid or is expired!',
        'activate_profile.error_desc_long': 'Please login, go to your profile settings and send the activation email again.',

        'my_projects.add_tasks': 'Add Task',
        'my_projects.create_task': 'Create Task',
        'my_projects.not_published': 'Your Project is not published',
        'my_projects.publish': 'Publish Project',
        'my_projects.private': 'Your Project is private. Invite people to open tasks.',
        'my_projects.project_overview': 'Project Overview',
        'my_projects.invoices': 'Invoices',
        'my_projects.is_working': 'Is working on the task',
        'my_projects.finished_work': 'Finished work on task',
        'my_projects.view_task': 'View Task',
        'my_projects.submited_work': 'Submitted work',
        'my_projects.review_work': 'Review Work',
        'my_projects.preview': 'Preview and publish',
        'my_projects.you_can_complete_your_project': 'You can complete your project',
        'my_projects.complete': 'Complete Project',
        'my_projects.view': 'View Project',
        'my_projects.invite_freelancers': 'Invite freelancers',

        'my_projects.warning_info': 'Some parameters of the task your are editing are out of scope of the parameters of the project',
        'my_projects.task_price_exceeds': 'Task price exceeds the project estimated budget by <b>{price}</b>.',
        'my_projects.task_start_date_before': 'Task start date (<b>{task_date}</b>) is before the project start date (<b>{project_date}</b>).',
        'my_projects.task_end_date_after': 'Task end date (<b>{task_date}</b>) is after the project end date (<b>{project_date}</b>).',
        'my_projects.save_will_update': 'You can confirm to continue and the project will be updated the following values',

        'my_task.upload': 'Upload Documents',
        'my_task.complete': 'Complete task',
        'my_task.not_published': 'Your Task is not published',
        'my_task.publish': 'Publish Task',
        'my_task.publish_invite': 'Publish your project and invite people to this private task.',
        'my_task.private': 'Your Task is private. Invite people to open negotiation.',
        'my_task.invite': 'Invite people.',
        'my_task.back_to_all': 'Back To All Tasks',
        'my_task.review_work': 'Review final work',
        'my_task.review_user': 'Review User',
        'my_task.task_overview': 'Task Overview',
        'my_task.task_proposals': 'Task Proposals',
        'my_task.submited_work': 'Submited work',
        'my_task.new_offer': 'New offer',
        'my_task.send_offer': 'Send offer',
        'my_task.final': 'Final submission',
        'my_task.submit': 'Submit work',
        'my_task.completed': 'Task completed?',
        'my_task.completed_desc': 'Submit your final files and request for payment.',

        'my_task_tooltip.negotiation': 'List of tasks that are in negotiation phase.',
        'my_task_tooltip.confirmed': 'List of tasks that have been confirmed.',
        'my_task_tooltip.in_progress': 'List of tasks that are in progress.',
        'my_task_tooltip.submitted': 'List of tasks that have been submitted.',
        'my_task_tooltip.completed': 'List of tasks that have been completed.',
        'my_task_tooltip.in_audit': 'List of tasks that are in audit phase.',
        'my_task.tooltip.rejected': 'List of tasks that have been rejected or failed.',
        'my_task.tooltip.status': 'Task status',

        'my_audits.negotiation': 'Negotiation',
        'my_audits.communication': 'Communication',
        'my_audits.file_submission': 'File submissions',
        'my_audits.complete': 'Complete History',

        //Message Sent
        'history.freelancer_send_message.title': 'Freelancer sent a message',
        'history.freelancer_send_message.text': '"<b>{message}</b>"',
        'history.client_send_message.title': 'Client sent a message',
        'history.client_send_message.text': '"<b>{message}</b>"',
        //Task Status Changed
        'history.task_status_changed.title': 'Task status changed',
        'history.task_is_in_negotiation.text': 'Task went into negotiation.',
        'history.task_is_published.text': 'Task was published.',
        'history.task_is_draft.text': 'Task was was changed to draft.',
        'history.task_is_in_dispute.text': 'Client filed a dispute.',
        'history.task_is_active.text': 'Task went into active status.',
        'history.task_is_in_progress.text': 'Task start date has started.',
        'history.task_is_submitted.text': 'Freelancer marked task as completed.',
        'history.task_is_pending_audit.text': 'Client filed a dispute with a complaint:',
        'history.task_is_in_audit.text': 'Auditor accepted to review the dispute.',
        'history.task_is_auditor_confirmed.text': 'Auditor marked task as confirmed.',
        'history.task_is_auditor_failed.text': 'Auditor marked task as failed.',
        'history.task_is_completed.text': 'Task was successfully completed.',
        //Task Auditor Changed
        'history.task_auditor_changed.title': 'Auditor for task <b>{task_name}</b> changed',
        'history.task_auditor_changed.text': 'Because of time emitted, active auditor changed.',
        //Task Proposal Changed
        //CLIENT_INVITED, FREELANCER_PROPOSED, TASK_EDITED, FILE_EDITED, SENT_FINAL, ACCEPTED, COUNTERED, REJECTED, AUTO_REJECTED
        'history.task_proposal_changed_client_invited.title': 'Client <b>{client_name}</b> has sent invitation',
        'history.task_proposal_changed_client_invited.text': 'Client has invited freelancer to participate in task',
        'history.task_proposal_changed_freelancer_proposed.title': 'New task proposal',
        'history.task_proposal_changed_freelancer_proposed.text': 'Freelancer sent a task proposal',
        'history.task_proposal_changed_task_changed.title': 'Task <b>{task_name}</b> data changed',
        'history.task_proposal_changed_task_changed.text': 'Client <b>{client_name}</b> changed task data, so the proposal reset.',
        'history.task_proposal_changed_file_edited.title': 'Task <b>{task_name}</b> documentation changed',
        'history.task_proposal_changed_file_edited.text': 'Client <b>{client_name}</b> changed task documents, so the proposal reset.',
        'history.task_proposal_changed_sent_final.title': 'Final task proposal',
        'history.task_proposal_changed_sent_final.text': 'Client sent a task proposal',
        'history.task_proposal_changed_accepted.title': 'Task Proposal accepted',
        'history.task_proposal_changed_accepted.text': 'Freelancer accepted the proposal',
        'history.task_proposal_changed_countered.title': 'Task Proposal has been changed',
        'history.task_proposal_changed_countered.text': 'A new proposal has been submitted.',
        'history.task_proposal_changed_rejected.title': 'Task Proposal has been rejected',
        'history.task_proposal_changed_rejected.text': 'Proposal between Freelancer <b>{freelancer_name}</b> and client <b>{client_name}</b> on this task has been <b>Rejected</b>.',
        'history.task_proposal_changed_auto_rejected.title': 'Other proposal has been accepted',
        'history.task_proposal_changed_auto_rejected.text': 'Proposal between Freelancer <b>{freelancer_name}</b> and client <b>{client_name}</b> on this task has been <b>Auto Rejected</b>, because other proposal has been accepted.',
        //Task Result
        'history.task_result_added.title': 'Freelancer sent results',
        'history.task_result_added.text': '"<b>{result_name}</b>"',
        'history.task_result_edited.title': 'Task result modified',
        'history.task_result_edited.text': 'Freelancer changed new result set with comment: "<b>{result_name}</b>"',
        //Task Result Document
        'history.task_result_document_added.title': 'Freelancer added files to results:',
        'history.task_result_document_added.text': '<b>{freelancer_name}</b> added a document named "<b>{document_name}</b>"',
        'history.task_result_document_deleted.title': 'Freelancer <b>{freelancer_name}</b> removed a document',
        'history.task_result_document_deleted.text': '<b>{freelancer_name}</b> removed a document named "<b>{document_name}</b>"',

        //Plural
        "plural.tasks": "{count, plural, =0 {No Tasks} one {# Task} other{# Tasks}}",
        "plural.projects": "{count, plural, =0 {No Projects} one {# Project} other {# Projects}}",
        "plural.auditors": "{count, plural, =0 {No Auditors} one {# Auditor} other {# Auditors}}",
        "plural.clients": "{count, plural, =0 {No Clients} one {# Client} other {# Clients}}",
        "plural.freelancers": "{count, plural, =0 {No Freelancers} one {# Freelancer} other {# Freelancers}}",
        "plural.competencies": "{count, plural, =0 {No Competencies} one {# Competency} other {# Competencies}}",
        "plural.edus": "{count, plural, =0 {No Educational Institutions} one {# Educational Institution} other {# Educational Institutions}}",
        "plural.competences.selected": "{count, plural, =0 {No Competencies Selected} one {# Competence Selected} other {# Competencies Selected}}",
        "plural.files.attached": "{count, plural, =0 {No Files Attached} one {# File Attached} other {# Files Attached}}",
        "plural.task.proposals": "{count, plural, =0 {No Task Proposals}  one {# Task Proposal} other {# Task Proposals}}",
        "plural.profile.reviews": "{count, plural, =0 {No Reviews}  one {# Review} other {# Reviews}}",


        'demo.hello': 'Hello.',
        'forms.loginForm': 'Login',
        'forms.registrationForm': 'Register',
        'forms.registerRoleForm': 'Register Role',
        'forms.forgotPasswordForm': 'Forgot your password? ',
        'forms.resetPasswordForm': 'Reset Password',

        'forms.reset_filters': 'Reset filters',

        'forms.labels.username': 'Username',
        'forms.labels.password': 'Password',
        'forms.labels.newPassword': 'New Password',
        'forms.labels.confirmPassword': 'Repeat password',
        'forms.labels.email': 'Email',
        'forms.labels.name': 'Name',
        'forms.labels.firstName': 'First Name',
        'forms.labels.lastName': 'Last Name',
        'forms.labels.gender': 'Gender',
        'forms.labels.gender.male': 'Male',
        'forms.labels.gender.female': 'Female',
        'forms.labels.login': 'Login',
        'forms.labels.loginWithSocial': 'Login with your social account.',
        'forms.labels.forgotPassword': 'Forgot your password? ',
        'forms.labels.resetHere': 'Reset here.',
        'forms.labels.noAccountYet': 'Don\'t have an account? ',
        'forms.labels.goToRegistration': 'Register here.',
        'forms.labels.sendMail': 'Send Mail',
        'forms.labels.register': 'Register',
        'forms.labels.resetPassword': 'Reset Password',
        'forms.labels.alreadyHaveAccount': 'Already have an account? ',
        'forms.labels.goToLogin': 'Go to login.',
        'forms.labels.update': "Update",
        'forms.labels.first_name': "First name",
        'forms.labels.last_name': "Last name",
        'forms.labels.address_line_1': "Address line 1",
        'forms.labels.address_line_2': "Address line 2",
        'forms.labels.post_code': "Post code",
        'forms.labels.city': "City",
        'forms.labels.state': "State",
        'forms.labels.country': "Country",
        'forms.labels.vat': "VAT number",
        'forms.labels.register_num': "Registration number",
        'forms.labels.company_name': "Company name",
        'forms.labels.howitworks': "How it works",
        'forms.labels.roleSelect': 'Choose your role (you can add more later)',
        'forms.labels.continue': 'Continue',
        'forms.labels.rememberme': 'Remember Me',
        'forms.labels.continueWithGoogle': 'Continue with Google',
        'forms.labels.iagreewiththe': 'I agree with the',
        'forms.labels.termsandconditions': 'Terms and Conditions',
        'forms.labels.and': 'and',
        'forms.labels.more': 'more',
        'forms.labels.privacypolicy': 'Privacy Policy',
        'forms.labels.status': 'Status',
        'forms.labels.project_name': 'Project name',
        'forms.labels.task_name': 'Task name',
        'forms.labels.category': 'Category',
        'forms.labels.short_description': 'Short Description',
        'forms.labels.description': 'Description',
        'forms.labels.documents': 'Documents',
        'forms.labels.add_documents': 'Add Documents',
        'forms.labels.select_documents': 'Select your documents',
        'forms.labels.selected_documents': 'Selected documents',
        'forms.labels.drag_documents': "Drag 'n' drop some documents here or click the button to select",
        'forms.labels.estimated_start_end': 'Estimated start/end date',
        'forms.labels.price': 'Price',
        'forms.labels.required_competences': 'Required competencies',
        'forms.labels.visibility': 'Visibility',
        'forms.labels.accept_proposal': 'Are you sure you want to accept this task proposal?',
        'forms.labels.comment': 'Comment',
        'forms.labels.public_link': 'Public link',
        'forms.labels.min_chars': '(min {min} characters)',
        'forms.labels.invite_freelancers_to_task': 'Invite freelancers to tasks',
        'forms.labels.contributed_by': 'Contributed by',
        'forms.labels.registerWithGoogle': "Register with Google",
        'forms.labels.invited_freelancers': 'Invited freelancers:',

        'forms.placeholders.comment': 'Add a comment to your work...',
        'forms.placeholders.select_chat': 'Select a contact to chat...',
        'forms.placeholders.messages': 'Write a message...',
        'forms.placeholders.competence': 'Add 1-5 competencies',
        'forms.placeholders.addcompetence': 'Add competence',
        'forms.placeholders.select': 'Select...',
        'forms.placeholders.username': 'Input your username...',
        'forms.placeholders.email': 'Input your email...',
        'forms.placeholders.newPassword': 'Input new password...',
        'forms.placeholders.confirmPassword': 'Confirm new password...',
        'forms.placeholders.category': 'Select category...',
        'forms.placeholders.select_country': 'Select a country...',
        'forms.placeholders.search_country': 'Search...',
        'forms.placeholders.select_freelancer': 'Select freelancers...',

        'validators.required': 'This field is required!',
        'validators.minLenIs': 'Should contain at least {num, number} characters.',
        'validators.minLen8': 'Should contain at least 8 characters.',
        'validators.textInputRequired': 'This field is required!',
        'validators.passwordMatch': 'Passwords must match.',
        'validators.maxSize': 'Max allowed size is 20Mb.',
        'validators.minChars': 'Minimum 10 characters ',
        'validators.left': ' left',
        'validators.needsNumber': 'Please include a number.',
        'validators.needsMixedCharacters': 'Please include mixed uppercase and lowercase characters.',
        'validators.needsSpecialCharasters': 'Please include a special character.',

        'nav.labels.logout': 'Logout',
        'nav.labels.profile': 'Profile',
        'nav.labels.profile.select': 'Select Profile',
        'nav.labels.login': 'Login',
        'nav.labels.register': 'Register',
        'nav.labels.browse': 'Browse',
        'nav.labels.dashboard': 'Dashboard',
        'nav.labels.myProjects': 'My Projects',
        'nav.labels.myTasks': 'My Tasks',
        'nav.labels.myWork': 'My Work',
        'nav.labels.myInstitution': 'My Institution',
        'nav.labels.myAudits': 'My Audits',
        'nav.labels.myClients': 'My Clients',
        'nav.labels.howitworks': 'How it works',
        'nav.labels.postaproject': 'Post a project',
        'nav.labels.profile_setup': 'Profile setup',
        'nav.labels.published_projects': 'Published Projects',

        'infobar.get_hired': 'Get hired today!',
        'infobar.complete_profile': 'Complete your profile and increase your chances of getting hired.',
        'infobar.start_hiring': 'Start hiring today!',
        'infobar.complete_profile_2': 'Please complete your profile before you start hiring.',
        'infobar.setup_profile': 'Setup profile',

        'roles.role': 'Role',
        'roles.name.DEV': 'Freelancer',
        'roles.name.INV': 'Client',
        'roles.name.AUD': 'Auditor',
        'roles.name.EDU': 'Education Institution',
        'roles.name.EAP': 'Program administrator',
        'roles.name.EAS': 'Student administrator',
        'roles.name.EAC': 'Competencies administrator',
        'roles.name.NEW': 'New role',
        'roles.add.DEV': 'A freelancer, who wants to get hired.',
        'roles.add.INV': 'A client, looking for freelancers.',
        'roles.add.AUD': 'I am an Auditor',
        'roles.add.EDU': 'We are an educational institution',
        'roles.legalName': 'Legal name*',
        'roles.nameAndSurname': 'Your full name*',
        'roles.no_active_role_yet': 'No active role yet?',
        'roles.please_register_role': 'Please register a role, that you will be using on ECTA.',
        'roles.delete_this_role': 'Delete this role?',
        'roles.add_admin': 'Add administrator',

        'edu.students': 'Students',
        'edu.student_list': 'Student list',
        'edu.student_requests': 'Student Certification Requests',
        'edu.verification_requests': 'Verification Requests',
        'edu.confirm_requests': 'Confirm and aproove requests from your alumni students.',
        'edu.alumni_list': 'A list of your certified alumni students.',
        'edu.no_student_requests': 'No student requests',
        'edu.edu_programs': 'Educational Programs',
        'edu.programs': 'Programs',
        'edu.degrees': 'Degrees',
        'edu.program': 'Program',
        'edu.degree': 'Degree',
        'edu.inst_admins': 'Institution administrators',
        'edu.manage_admins': 'Manage users authorised to administer your institution.',
        'edu.student_admins': 'Student administrators',
        'edu.program_admins': 'Program administrators',
        'edu.competencies_admins': 'Competence administrators',
        'edu.manage_programs': 'Manage educational programs and degrees of your institution',
        'edu.send_request': 'Request verification',
        'edu.student_id': 'Student Number',
        'edu.diploma_id': 'Diploma Id Number',

        'degrees.new': 'New degree',
        'degrees.addnew': 'Add a new degree',
        'degree.create.error': 'Error while creating new degree. ',
        'degree.update.error': 'Error while editing degree. ',
        'degree.delete.error': 'Error while deleting degree. ',

        'program.create.error': 'Error while creating new program. ',
        'program.update.error': 'Error while editing program. ',
        'program.delete.error': 'Error while deleting program. ',
        'programs.addnew': 'Add a New Program',
        'programs.your_competencies': 'Competencies (search or create your own)',

        'rating.satisfied': 'Are you satisfied with the result?',
        'rating.review': 'Review user',
        'rating.review_aud': 'Review audit process',
        'rating.review_client': 'Give feedback on working with this client',
        'rating.review_inv': 'Review client',
        'rating.feedback': 'Leave feedback',
        'rating.feedback_long': 'Leave public review for this user...',
        'rating.review_work': 'Review Submitted Work',
        'rating.open_dispute': 'Open dispute',
        'rating.confirm_and_pay': 'Confirm and Pay',
        'rating.confirm_and_pay_repeat': 'Confirm and Repeat',
        'rating.review_audit_title': 'Are you satisfied with the audit process?',
        'rating.review_audit': 'Review the audit process by these categories',

        'rating.category.communication': 'Communication',
        'rating.category.quality': 'Quality of work',
        'rating.category.price': 'Price satisfaction',
        'rating.category.recommend': 'Would recommend',
        'rating.category.work_again': 'Would you work again',
        'rating.category.documentation': 'Clear work specifications',
        'rating.category.quality_of_explanation': 'Quality of explanation',
        'rating.category.length_of_explanation': 'Length of explanation',
        'rating.category.satisfaction_with_verdict': 'Satisfaction with the verdict',

        'messages.messages': 'Messages',
        'messages.people': 'People',
        'messages.online': 'Online',
        'messages.offline': 'Offline',
        'messages.you': 'You',

        'components.languageSelect': 'Language Select',
        'components.roleSelect': 'Role Select',

        'browse.projects': 'Projects',
        'browse.developers': 'Freelancers',
        'browse.investors': 'Clients',
        'browse.auditors': 'Auditors',
        'browse.edus': 'Schools',
        'browse.browse_projects': 'Browse projects',
        'browse.browse_tasks': 'Browse tasks',
        'browse.browse_auditors': 'Browse auditors',
        'browse.browse_edus': 'Browse educational institutions',
        'browse.goto_myedu': 'Go to My-Institution',
        'browse.goto_myaudits': 'Go to My-Audits',

        'login.error': 'Ooops! Wrong username or password. Please try again.',
        'register.error': 'Ooops! Registration failed. Please try again.',
        'forgotpassword.error': 'Ooops! Forgot Password failed. Please try again.',
        'resetpassword.error': 'Ooops! Reset Password failed. Please try again.',

        'role.change.error': 'Ooops! Change Role failed. Please try again.',
        'role.create.error': 'Ooops! Create Role failed. Please try again.',
        'role.get.error': 'Ooops! Get Role failed. Please try again.',
        'role.update.error': 'Ooops! Update Role failed. Please try again.',
        'role.review.submit.error': 'Ooops! Submit Rating Review failed. Please try again.',
        'role.detail.update.error': 'Ooops! Update Role Detail failed. Please try again.',
        'role.experience.create.error': 'Ooops! Create Role Experience failed. Please try again.',
        'role.experience.update.error': 'Ooops! Update Role Experience failed. Please try again.',
        'role.experience.delete.error': 'Ooops! Delete Role Experience failed. Please try again.',
        'role.education.create.error': 'Ooops! Create Role Education failed. Please try again.',
        'role.education.update.error': 'Ooops! Update Role Education failed. Please try again.',
        'role.education.delete.error': 'Ooops! Delete Role Education failed. Please try again.',
        'role.freelacers.get.error': 'Ooops! Get Freelancers failed. Please try again.',
        'role.clients.get.error': 'Ooops! Get Clients failed. Please try again.',
        'role.auditors.get.error': 'Ooops! Get Auditors failed. Please try again.',
        'role.edus.get.error': 'Ooops! Get Educational Institution failed. Please try again.',
        'role.upload.profile_pic.error': 'Ooops! Update Profile Picture failed. Please try again.',
        'role.upload.cover_pic.error': 'Ooops! Update Cover Picture failed. Please try again.',

        'project.upload.logo_pic.error': 'Ooops! Update Logo Picture failed. Please try again.',
        'project.upload.cover_pic.error': 'Ooops! Update Cover Picture failed. Please try again.',

        'landing.slogan': 'The place where great ideas are born.',
        'landing.forEmployers': 'FOR CLIENTS',
        'landing.forDevelopers': 'FOR FREELANCERS',

        'footer.about': 'About',
        'footer.how': 'How it works',
        'footer.terms': 'Terms & Conditions',
        'footer.privacy': 'Privacy',
        'footer.questions': 'Questions?',
        'footer.settings': 'Manage your account and privacy',
        'footer.contact': 'Contact us',

        'browse.common.industry': 'Industry',
        'browse.common.show': 'Show',
        'browse.common.language': 'Language',
        'browse.common.country': 'Country',
        'browse.common.rating': 'Rating',
        'browse.common.activeprojects': 'Active projects',
        'browse.common.opentasks': 'Open tasks',
        'browse.common.view_my_projects': 'View in My Projects',
        'browse.common.view': 'View',
        "browse.common.viewinmyprojects": 'View in My Projects',
        'browse.common.search': 'Search',
        'browse.common.sortby': 'Sort by',
        'browse.common.loading': 'Loading',
        'browse.common.createProject': 'Create a new project',
        'browse.common.commingsoon': 'Coming soon',
        'browse.common.view_my_work': 'View in My Work',
        'browse.common.allrecordsloaded': 'All records loaded',
        'browse.common.select-issuer': 'Select issuer',


        'browse.order.startingsoon': 'Starting soon',
        'browse.order.highestbudget': 'Highest budget',
        'browse.order.lowestbudget': 'Lowest budget',
        'browse.order.byprojects': 'By projects',
        'browse.order.byclient': 'By client',
        'browse.order.highestrating': 'Highest rating',
        'browse.order.lowestrating': 'Lowest rating',
        'browse.order.mostactivetasks': 'Most active tasks',
        'browse.order.mostfinishedtasks': 'Most finished tasks',
        'browse.order.highesthourlyrate': 'Highest hourly rate',
        'browse.order.lowesthourlyrate': 'Lowest hourly rate',
        'browse.order.nameasc': 'Name ascending',
        'browse.order.namedesc': 'Name descending',

        'browse.project.budget': 'Budget',
        'browse.project.timeframe': 'Timeframe',
        'browse.project.open_tasks': 'Open Tasks',
        'browse.project.participating': 'Participating in project',
        'browse.project.about': 'About',
        'browse.project.join': 'Join the project!',
        'browse.project.explore': 'Explore tasks',
        'browse.project.viewtask': 'View details',
        'browse.project.verify_email_short': 'Verify your email',
        'browse.project.verify_email': 'Please verify your email to continue working with the website',
        'browse.project.re-send_email': 'Re-send activation email',
        'browse.project.company': 'Company',
        'browse.project.location': 'Location',
        'browse.project.about_client': 'About Client',
        'browse.project.project_details': 'Project Details',

        'browse.task.negotiation': 'Negotiations in progress',
        'browse.task.negotiations_completed': 'Negotiations completed',
        'browse.task.post_negotiation': 'Someone is already working on this task.',
        'browse.task.private_invite_only': 'Your task is private. Invite freelancers to this task.',
        'browse.task.invite_only': 'Task is invite only',
        'browse.task.view_my_work': 'View in My Work',
        'browse.task.submit_task_proposal': 'Submit task proposal',
        'browse.task.submit_task_proposal_counter': 'Submit a task proposal counter offer',
        'browse.task.like_task': 'You like this task?',
        'browse.task.invite_free_long': 'Invite freelancers to this task.',
        'browse.task.invite_free': 'Invite freelancers',
        'browse.task.goto_my_projects': 'Go to My Projects',
        'browse.task.switch_to_participate': 'Switch role to participate',
        'browse.task.back': 'Back to All Tasks',
        'browse.task.about': 'About',
        'browse.task.required_competences': 'Required competencies',
        'browse.task.description': 'Long description',
        'browse.task.milestone': 'Milestones',
        'browse.task.remuneration': 'Task remuneration',
        'browse.task.fixed': 'Fixed',
        'browse.task.timeframe': 'Timeframe',
        'browse.task.details': 'Task Details',
        'browse.task.proposal_rejected': 'Proposal rejected',

        'dashboard.welcome': 'Welcome',
        'dashboard.setup': 'Setup',
        'dashboard.browse': 'Browse',
        'dashboard.statistics': 'Statistics',
        'dashboard.clients': 'Clients',
        'dashboard.myactivity': 'My Activity',
        'dashboard.sidebar': 'Sidebar',

        'competency': 'Competency',
        'competency.new': 'New competency',
        'competences.this_person_can': 'This person can ',
        'competences.id': 'Competency Id',
        'competences.name': 'Competency name',
        'competences.create': 'Create a New Competency',
        'competences.edit': 'Edit Competencies',
        'competences.my': 'My Competencies',
        'competences.add_five': 'Add at least five competencies.',
        'competences.add': 'Add competencies',
        'competences.add.desc': 'Add your competences and we will suggest the perfect projects for you!',
        'competences.clients_search': 'What clients search for the most',
        'competences.search': 'Search...',
        'competences.least_one': 'Please select at least one competency',
        'competences.parent': 'Parent competency',
        'competences.no_parent': 'No parent competency',
        'competences.skills': 'Base competencies',
        'competences.related_skills': 'Base competencies',
        'competences.no_related_skills': 'No base competencies',
        'competency.add.step1': 'Name',
        'competency.add.step2': 'Definition',
        'competency.add.step3': 'Relations',
        'competences.parent_description': 'The parent competency positions competency vertically in the registry.',
        'competences.related_description': 'Related or base competencies that this competency includes or consists of.',

        'competences.written_by_you': 'ECTA Competencies are written by you',
        'competences.created_by_you': 'Competencies are created by people like you!',
        'competences.top_definition': 'TOP DEFINITION',

        'competences.add_skill_title': 'Suggest base competency',
        'competences.add_description_title': 'Suggest competence description',
        'competences.add_parent_title': 'Suggest competence parent',
        'competences.add_skill': 'Suggest base competency',
        'competences.add_description': 'Suggest Description',
        'competences.add_parent': 'Suggest Parent',
        'competences.contribute_info': 'Participate in consensus about the competency details by contributing or voting.',
        'competences.commonly_used': 'Commonly used in',
        'competences.categories': 'Categories',
        'competences.commonly_used_in': 'Categories in which this competency is commonly used.',
        'competences.platform_only': 'Platform only',
        'competences.suggested': 'Suggested',
        'competency.blooms_taxonomy': "Blooms's Taxonomy",
        'competency.blooms_taxonomy_description': "Bloom's Taxonomy helps us classify educational learning objectives. Simply finish the sentence starting with:",
        'competency.blooms_taxonomy_info': "Bloom's Taxonomy helps us classify educational learning objectives.",
        'competency.description': "Competency description",

        'blooms.whats_this': 'What\'s this',
        'blooms.L1': 'REMEMBER (define, duplicate, list, memorize, repeat...) is the 1st level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L2': 'UNDERSTAND (classify, discuss, explain, report...) is the 2nd level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L3': 'APPLY (execute, implement, solve, use, interpret...) is the 3rd level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L4': 'ANALYZE (differentiate, organize, compare, distinguish...) is the 4th level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L5': 'EVALUATE (argue, judge, support, critique, weigh...) is the 5th level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',
        'blooms.L6': 'CREATE (design, assemble, construct, develop, author...) is the 6th level in Bloom\'s taxonomy, which is a set of 3 hierarchical models used to classify educational learning objectives into levels of complexity and specificity.',

        'projects.heading': 'Find the best projects and tasks for you',
        'projects.industry': 'Industry',
        'projects.employment': 'Employment',
        'projects.employmentduration': 'Employment duration',
        'projects.tasks': 'Tasks',
        'projects.competences': 'Competencies',
        'projects.duration': 'Duration',
        'projects.logintoview': 'Login to view',
        'projects.image': 'Project image',
        'projects.image_desc': 'Make your project more attractive by adding a cover photo and profile image/logo.',
        'projects.change.image': 'Change Project image',
        'projects.change.cover': 'Change Project cover image',
        'projects.estimated_budget': 'Estimated budget',
        'projects.estimated_start': 'Estimated start date',
        'projects.estimated_end': 'Estimated end date',

        'education.add': 'Add Education',
        'education.add.desc': 'Make your profile more credible by adding your education.',
        'education.school': 'School/University',
        'education.degree': 'Degree/Program',

        'experience.add': 'Add Experience',
        'experience.add.desc': 'Make your profile more credible by adding your experience.',
        'experience.edit': 'Edit Experience',
        'experience.company': 'Company/Project',
        'experience.position': 'Position/Role',

        'freelancers.heading': 'Say Hi to the Best Devs in the World!',
        'freelancers.availability': 'Availability',
        'freelancers.competences': 'Competencies',
        'freelancers.hourlyrate': 'Hourly rate',
        'freelancers.completedprojects': 'Completed projects',
        'freelancers.language': 'Language',
        'freelancers.country': 'Country',
        'freelancers.studiedat': 'Studied at',
        'freelancers.hour': 'hour',
        'freelancers.completedTasks': 'Completed tasks',

        'clients.heading': 'The best lorem ipsum dolor at one place',

        'educationalinstitutions.heading': 'Most Credible Educational Institutions at one place',

        'auditors.heading': 'Most Credible Auditors at one place',
        'auditors.dispute': 'Dispute',
        'auditors.dispute_cases': 'Dispute Cases',
        'auditors.accept_case': 'ACCEPT CASE',
        'auditors.reject_case': 'Reject case',
        'auditors.submit_final_decision': 'SUBMIT FINAL DECISION',

        'auditors.client_filed_dispute': ' Dispute settlement failed',
        'auditors.you_filed_dispute': ' Dispute settlement failed',
        'auditors.dispute_escalated': ' The case was escalated to auditor.',
        'auditors.auditor_accepted': ' Auditor accepted the case',
        'auditors.auditor_accepted_your': ' Auditor accepted your case',
        'auditors.auditor_reviewed': ' Auditor has reviewed the case',
        'auditors.auditor_reviewed_your': ' Auditor has reviewed your case',
        'auditors.auditor_decided_1': 'It is decided that task has been completed ',
        'auditors.auditor_decided_2': 'and a ',
        'auditors.auditor_decided_3': 'compensation has been granted to freelancer.',
        'auditors.audit_compensation': 'Audit compensation',
        'auditors.audit_deadline': 'Audit deadline',
        'auditors.audit_deadline_days': '7 working days',
        'auditors.audit_rules': 'Rules of audit process...',
        'auditors.audit_client_reason': 'Client complaint reason:',
        'auditors.dispute_details': 'Dispute details',
        'auditors.audit_data': 'Audit data',
        'auditors.decided_1': 'Task was executed',
        'auditors.decided_2': 'and',
        'auditors.decided_3': 'will be payed.',
        'auditors.slider_info': '*By moving the slider you decide the proportion of the task that was executed correctly and the amount of compensation the freelancer receives from the original agreed amount. ',
        'auditors.dispute_resolution': 'Dispute resolution:',
        'auditors.auditor_comment': 'Auditor comment:',
        'auditors.keypoint': 'Keypoint',
        'auditors.comment': 'Comment',
        'auditor.dispute_case_details': 'Dispute case details',
        'auditor.agree_to_continue': 'Agree to continue',
        'auditor.file_dispute': 'File dispute',
        'auditor.file_dispute_proposal': 'FILE A DISPUTE PROPOSAL',

        'modalEditProfileImage.changeyourprofileimage': 'Change your profile image',
        'modalEditProfileImage.dragndrop': "Click or drag your picture here",

        'modalEditFreelancerRates.heading': 'Edit Availability and Preferences',
        'modalEditFreelancerRates.availability': 'Availability',
        'modalEditFreelancerRates.acceptingwork': 'Accepting work',
        'modalEditFreelancerRates.notacceptingwork': 'Not accepting work',
        'modalEditFreelancerRates.hourlyrate': 'Hourly Rate (USD/hr)',

        'modalEditFreelancerEducation.heading': 'Edit Education',
        'modalEditFreelancerEducation.studiedat': 'Studied at',

        'modalEditProfileInfo.heading': 'Edit profile information',
        'modalEditProfileInfo.displayname': 'Display Name',
        'modalEditProfileInfo.companyname': 'Company Name',
        'modalEditProfileInfo.vat': 'VAT',
        'modalEditProfileInfo.firstname': 'First name',
        'modalEditProfileInfo.lastname': 'Last name',
        'modalEditProfileInfo.description': 'Description',
        'modalEditProfileInfo.address': 'Address',
        'modalEditProfileInfo.zip': 'ZIP',
        'modalEditProfileInfo.city': 'City',
        'modalEditProfileInfo.state': 'State',
        'modalEditProfileInfo.country': 'Country',

        'profile.hourlyrate': 'Hourly rate',
        'profile.hour': 'hr',
        'profile.availability': 'Availability',
        'profile.completedtasks': 'completed tasks',
        'profile.completedtasksupper': 'Completed tasks',
        'profile.participatedinprojects': 'participated in projects',
        'profile.clients': 'clients',
        'profile.inthepastweek': 'In the past week',
        'profile.clientswholookedfor': 'Clients who looked for',
        'profile.recommendations': 'recommendations',
        'profile.reviews': 'reviews',
        'profile.online': 'Online',
        'profile.nodescription': 'No description',
        'profile.education': 'Education',
        'profile.experience': 'Experience',
        'profile.competences': 'Competencies',
        'profile.reviewsupper': 'Reviews',
        'profile.participatedinprojectsupper': 'Participated in projects',
        'profile.participatedintasks': 'Participated in tasks',
        'profile.nocity': 'No city',
        'profile.nocountry': 'No country',
        'profile.acceptingwork': 'Accepting work',
        'profile.personal': 'Personal information',
        'profile.personal.desc': 'Become more credible by filling in your personal information.',
        'profile.description.add': 'Add description',
        'profile.description.desc': 'Adding a short description increases your chances of getting hired.',
        'profile.public': 'Public information',
        'profile.public.desc': 'Publicly visible profile details',
        'profile.change.cover': 'Change cover',
        'profile.change.profile': 'Change profile image',
        'profile.change.role': 'Quick switch between my profiles',

        'DropdownUserMenu.myprofile': 'My Profile',
        'DropdownUserMenu.addanotherprofile': 'Add another profile',
        'DropdownUserMenu.settings': 'Settings',
        'DropdownUserMenu.balances': 'Balances',
        'DropdownUserMenu.ndas': "NDA's",
        'DropdownUserMenu.contracts': 'Contracts',
        'DropdownUserMenu.help': 'Help',
        'DropdownUserMenu.resolution_center': "Resolution Center",

        'settings.account': 'User account',
        'settings.account_settings': 'User account settings',
        'settings.payment': 'Transactions',
        'settings.notification': 'Notifications',
        'settings.push_notification': 'Push notifications',
        'settings.notification_pref': 'Notification preferences',
        'settings.general': 'General',
        'settings.messaging': 'Messaging',
        'settings.task_related': 'Task related',
        'settings.security': 'Security',
        'settings.current_role': 'Your current account role',

        'resolutionCenter.finalSubmit': 'I accept the terms and conditions of the Dispute Resolution Service.',
        'resolutionCenter.noTaskSelected': 'No task selected. Go to',
        'resolutionCenter.keyPoints': 'Problematic keypoints (add your comments)',
        'resolutionCenter.proposePercentage': 'I propose to resolve this by paying the following percentage (%) of the agreed amount',
        'resolutionCenter.costOfDispute': 'Cost of dispute audit',
        'resolutionCenter.agreedTerms': 'Agreed Terms',
        'resolutionCenter.more_in_the': 'More in the ',
        'resolutionCenter.faq_section': ' FAQ section...',

        'dispute.proposed': 'proposed a resolution to pay ',
        'dispute.of_price': ' of the agreed price.',
        'dispute.i_propose': 'I propose a payment of   ',
        'dispute.of_price_2': ' % of the agreed price is payed.',
        'dispute.send_counter': 'SEND COUNTER',
        'dispute.counter': 'COUNTER',
        'dispute.accept': 'ACCEPT',
        'dispute.send_to-audit': 'SEND TO AUDIT',
        'dispute.was_escalated': 'Dispute was escalated to auditor.',
        'dispute.expired': 'Dispute settlement time expired. The case was automatically escalated to auditor.',
        'dispute.resolved': 'Dispute was resolved by accepting settlement agreement.',
        'dispute.keypoints_with_comments': 'Dispute keypoints with comments:',
        'dispute.original_comment': 'Original comment by the client:',
        'dispute.replies': 'Replies:',
        'dispute.marked_resolved': 'Client has marked this keypoint as resolved.',
        'dispute.reply': 'Reply',
        'dispute.mark_as_resolved': 'MARK AS RESOLVED',



        // Notifications
        'notification.notifications': 'Notifications',
        'notification.markAllAsRead': 'Mark All as Read',
        'notification.nothingNew': 'Nothing new',
        'notification.upToDate': 'You\'re all up to date!',
        'notification.seeAll': 'See all',

        // Notifications...FOR CLIENT & FREELANCER
        'notification.OnReviewRequestSubmitted.title': 'New review',
        'notification.OnReviewRequestSubmitted.text': 'You got a new review from ',

        // Notifications...FOR CLIENT
        'notification.OnFreelancerTaskProposalProposed.title': 'New task proposal',
        'notification.OnFreelancerTaskProposalProposed.text': ' has sent you a task proposal.',
        'notification.OnFreelancerTaskProposalCountered.title': 'New counter offer',
        'notification.OnFreelancerTaskProposalCountered.text': ' has sent you a counter offer.',
        'notification.OnTaskProposalAutoRejected.title': 'Task proposal rejected',
        'notification.OnTaskProposalAutoRejected.text': 'Your task proposal has been rejected.',
        'notification.OnFreelancerTaskProposalRejected.title': 'Task proposal rejected',
        'notification.OnFreelancerTaskProposalRejected.text': ' has rejected your task proposal.',
        'notification.OnFreelancerTaskProposalRejectedLast.title': 'Task proposal rejected',
        'notification.OnFreelancerTaskProposalRejectedLast.text': ' has rejected your last task proposal.',
        'notification.OnFreelancerTaskProposalAccepted.title': 'Task proposal accepted',
        'notification.OnFreelancerTaskProposalAccepted.text': ' has accepted your task proposal.',
        'notification.OnFreelancerTaskRejected.title': 'Task cancelled',
        'notification.OnFreelancerTaskRejected.text': ' has cancelled work for task.',
        'notification.OnFreelancerTaskSubmitted.title': 'Task completed',
        'notification.OnFreelancerTaskSubmitted.text': ' has submitted final work for the task.',
        'notification.OnProjectMayCompleted.title': 'Project completed',
        'notification.OnProjectMayCompleted.text': 'All tasks on the project have been completed.',
        'notification.OnTaskAuditorFailedForClient.title': 'Dispute case reviewed',
        'notification.OnTaskAuditorFailedForClient.text': 'Auditor has made the final decision.',
        'notification.OnTaskAuditorConfirmedForClient.title': 'Dispute case reviewed',
        'notification.OnTaskAuditorConfirmedForClient.text': 'Auditor has made the final decision.',
        'notification.OnTaskInAuditForClient.title': 'Audit in progress',
        'notification.OnTaskInAuditForClient.text': 'Auditor has accepted your dispute case in review.',
        'notification.OnFreelancerTaskResultSubmitted.title': 'New task results',
        'notification.OnFreelancerTaskResultSubmitted.text': ' sent new task results.',
        'notification.OnTaskPublishedStartTimeWarning.title': 'Task start date is nearing',
        'notification.OnTaskPublishedStartTimeWarning.text': 'Task status will go into draft soon.',

        // Notifications...FOR FREELANCER
        'notification.OnClientTaskProposalProposed.title': 'Invite to task',
        'notification.OnClientTaskProposalProposed.text': ' has invited you to a task.',
        'notification.OnClientTaskProposalCountered.title': 'New counter offer',
        'notification.OnClientTaskProposalCountered.text': ' has sent you a counter offer.',
        'notification.OnClientTaskProposalRejected.title': 'Task proposal rejected',
        'notification.OnClientTaskProposalRejected.text': ' has rejected your task proposal.',
        'notification.OnClientTaskProposalSentFinal.title': 'Final counter offer',
        'notification.OnClientTaskProposalSentFinal.text': ' has sent you a final task proposal offer.',
        'notification.OnClientTaskRejected.title': 'Task cancelled',
        'notification.OnClientTaskRejected.text': ' has cancelled a task.',
        'notification.OnClientTaskCompleted.title': 'Task completed',
        'notification.OnClientTaskCompleted.text': ' has confirmed, payed and completed task.',
        'notification.OnTaskInProgress.title': 'Task in progress',
        'notification.OnTaskInProgress.text': ' is due in ',
        'notification.OnClientTaskPendingAudit.title': 'A dispute was escalated to audit',
        'notification.OnClientTaskPendingAudit.text': 'Task name: ',
        'notification.OnClientTaskInAudit.title': 'Audit started',
        'notification.OnClientTaskInAudit.text': 'Auditor has accepted the case.',
        'notification.OnTaskAuditorConfirmedForFreelancer.title': 'Dispute case reviewed',
        'notification.OnTaskAuditorConfirmedForFreelancer.text': 'Auditor has made the final decision.',
        'notification.OnTaskAuditorFailedForFreelancer.title': 'Dispute case reviewed',
        'notification.OnTaskAuditorFailedForFreelancer.text': 'Auditor has made the final decision.',
        'notification.OnTaskInAuditForFreelancer.title': 'Audit in progress',
        'notification.OnTaskInAuditForFreelancer.text': 'Auditor has accepted dispute case in review.',
        // Dispute settlement
        'notification.OnClientTaskInDispute.title': 'Task dispute',
        'notification.OnClientTaskInDispute.text': 'Client has filled a dispute with settlement proposal.',
        'notification.OnClientTaskDisputeSetInNegotiation.title': 'Dispute update',
        'notification.OnClientTaskDisputeSetInNegotiation.text': 'Client has sent a new settlement agreement proposal.',
        'notification.OnFreelancerTaskDisputeSetInNegotiation.title': 'Dispute update',
        'notification.OnFreelancerTaskDisputeSetInNegotiation.text': 'Freelancer has sent a new settlement agreement proposal.',
        'notification.OnFreelancerTaskDisputeSettlementComment.title': 'Dispute update',
        'notification.OnFreelancerTaskDisputeSettlementComment.text': 'Freelancer has sent a new comment on a dispute topic.',
        'notification.OnClientTaskDisputeSettlementComment.title': 'Dispute update',
        'notification.OnClientTaskDisputeSettlementComment.text': 'Client has sent a new comment on a dispute topic.',

        // Notifications...FOR AUDITOR
        'notification.OnNewTaskDisputeCase.title': 'New audit request',
        'notification.OnNewTaskDisputeCase.text': 'You have been invited to review a dispute.',
        'notification.OnTaskDisputeCaseAutoRejected.title': 'New audit request expired',
        'notification.OnTaskDisputeCaseAutoRejected.text': 'Your invite to review a dispute case has been cancelled.',

        // EDU
        'notification.OnNewRoleEducationRequest.title': 'New verification request',
        'notification.OnNewRoleEducationRequest.text': ' has sent you a request to verify his/hers education program.',
        'notification.OnRoleEducationRequestApproved.title': 'Verification request',
        'notification.OnRoleEducationRequestApproved.text': ' has verified your education.',
        'notification.OnRoleEducationRequestRejected.title': 'Verification request',
        'notification.OnRoleEducationRequestRejected.text': ' has rejected your education verification request.',

        // ISSUERS - EN
        'issuers.issuer': 'Issuer',
        'issuers.settings': 'Issuer settings',
        'issuers.add_new': 'Add new issuer',
        'issuers.issuer_name': 'Issuer Id (used to generate competency prefix)',
        'issuers.mint_type': 'Task Competencies usage',
        'issuers.MINT_DISABLED': 'Disabled',
        'issuers.MINT_ON_SYS_ISSUER': 'Use system certificate',
        'issuers.MINT_ON_SAME_ISSUER': 'Use my certificate',
        'issuers.allow_public_insert': 'Allow public competencies adding',
        'issuers.allow_voting': 'Allow public competencies voting',
        'issuers.is_external': 'External issuer',
        'issuers.get_my_issuers_success': 'Successfully recieved my issuers.',
        'issuers.get_my_issuers_error': 'Failed to retrieve a list of issuers. ',
        'issuers.delete_issuer_success': 'Issuer deleted successfully.',
        'issuers.delete_issuer_fail': 'There was an error while trying to delete issuer. ',
        'issuers.get_available_issuers_success' : 'Successfully recieved available issuers. ',
        'issuers.get_available_issuers_fail' : 'Failed to receive available issuers. ',
        'ssuers.get_public_issuers_success' : 'Successfully received public issuers. ',
        'ssuers.get_public_issuers_fail' : 'Failed to receive public issuers. ',
        'issuers.create_iusser_success' : 'Successfully created a new issuer. ',
        'issuers.create_iusser_fail' : 'Failed to create a new issuer. ',
        'issuers.get_iusser_details_success' : 'Successfully recieved subrole details. ',
        'issuers.get_iusser_details_fail' : 'Failed to recieve subrole details. ',
        'issuers.update_issuer_details_success' : 'Successfully updated issuer details. ',
        'issuers.update_issuer_details_fail' : 'Failed to update issuer details. ',
        'issuers.issuer_details': 'Issuer details',
        'issuers.manage_issuer': 'Manage your issuer settings and competencies generating process.',
        'issuers.edit': 'Edit issuer',
        'issuers.issued_by_ecta': 'Issued by ECTA',
        'issuers.issued_by': 'Issued by ',
        'issuers.disabled_voting': 'The issuer has disabled public voting on this competency.',
        'issuers.issuer_missing': 'Issuer missing',
        'issuers.issuer_missing_text': 'Please create an Issuer first, before creating your competences related to your educational programs.',

    },
    sl: {
        'common.save': 'Shrani',
        'common.save_and_continue': 'Shrani in nadaljuj',
        'common.save_and_add': 'Shrani in dodaj več',
        'common.confirm_and_save': 'Potrdi in shrani',
        'common.edit': 'Uredi',
        'common.remove': 'Odstrani',
        'common.delete': 'Izbriši',
        'common.close': 'Zapri',
        'common.download': 'Prenesi',
        'common.cancel': 'Prekliči',
        'common.counter': 'Nova ponudba',
        'common.reject': 'Zavrni',
        'common.accept': 'Sprejmi',
        'common.add': 'Dodaj',
        'common.add_new': '+ Dodaj',
        'common.upload': 'Naloži',
        'common.submit': 'Pošlji',
        'common.verify': 'Verificiraj',
        'common.verified': 'Verificirano',
        'common.back': 'Pojdi nazaj',
        'common.next': 'Naslednji korak',
        'common.finish': 'Zaključi',
        'common.project': 'Projekt',
        'common.task': 'Naloga',
        'common.rec_task': 'Ponavljajoča se naloga',
        'common.all': 'Vsi',
        'common.from': 'Od',
        'common.to': 'Do',
        'common.from_years': 'Leto od',
        'common.to_years': 'Leto do',
        'common.draft': 'Osnutek',
        'common.published': 'Objavljen',
        'common.in_negotiation': 'V pogajanju',
        'common.active': 'Aktiven',
        'common.in_progress': 'V izvajanju',
        'common.submitted': 'Oddano',
        'common.pending_audit': 'Čaka na Revizijo',
        'common.in_audit': 'V Reviziji',
        'common.in_dispute': 'V sporu',
        'common.audit': 'Revizija',
        'common.auditor_confirmed': 'Revizija Potrjena',
        'common.auditor_failed': 'Revizija Zavrnjena',
        'common.completed': 'Dokončano',
        'common.confirmed': 'Sprejeto',
        'common.rejected': 'Zavrnjeno',
        'common.pending': 'Čaka na potrditev',
        'common.invited': 'Povabila',
        'common.closed': 'Zaprto',
        'common.open': 'Odprto/Aktivno',
        'common.confirm': 'POTRDI',
        'common.failed': 'Neuspešno',
        'common.public': 'Javen',
        'common.private': 'Zaseben',
        'common.files.no_preview': 'Te datoteke ni mogoče pripraviti na ogled. Za ogled ga prenesite.',
        'common.files.preview': 'Pregledovalnik datotek',
        'common.comment': 'Komentar',
        'common.misc': 'Razno',
        'common.read_more': 'Preberite več...',
        'common.contribute': 'PRISPEVAJ',
        'common.level': 'Stopnja',
        'common.logintocontribute': 'Prijavi se in prispevaj',
        'common.verifytocontribute': 'Potrdi e-pošto in prispevaj',
        'common.history': 'Zgodovina',
        'common.yes_delete': 'DA, ODSTRANI',
        'common.sure_about_delete': 'Ste prepričani, da želite odstraniti ta podatek?',
        'common.confirmation': 'Potrditveno okno',
        'common.up': 'Za',
        'common.down': 'Proti',
        'common.enabled': 'Omogočeno',
        'common.disabled': 'Onemogočeno',
        'common.yes': 'DA',
        'common.no': 'NE',
        'common.custom': 'Po meri',
        'common.active_freelancer': 'Aktiven izvajalec',
        'common.recurring': "Ponavljajoče",
        'common.reccurrance': "Ponavljanje",
        'common.reccurring_task': "Ponavljajoča naloga",
        'common.recurring_type': "Tip ponavljanja",
        'common.reccurring_times': "Ponovi X",
        'common.interval': "INTERVALNO (start/konec datum)",
        'common.weekly': "TEDENSKO",
        'common.monthly': "MESEČNO",
        'common.create': "USTVARI",
        'common.milestone': "Mejnik",
        'common.milestones': "Mejniki",
        'common.milestones.create': "Ustvari nov Mejnik",
        'common.conditions': "Pogoji",
        'common.of_task_price': "cene naloge",
        'common.task_success': "Naloga opravljena",
        'common.search_freelancers': 'Išči izvajalce',
        'common.administrators': 'Administratorji',
        'common.administrator': 'Administrator',
        'common.monday_short': 'Pon',
        'common.tuesday_short': 'Tor',
        'common.wednesday_short': 'Sre',
        'common.thursday_short': 'Čet',
        'common.friday_short': 'Pet',
        'common.saturday_short': 'Sob',
        'common.sunday_short': 'Ned',
        'common.monday': 'Ponedeljek',
        'common.tuesday': 'Torek',
        'common.wednesday': 'Sreda',
        'common.thursday': 'Četrtek',
        'common.friday': 'Petek',
        'common.saturday': 'Sobota',
        'common.sunday': 'Nedelja',
        'common.year': 'Leto',
        'common.days': 'Dni',
        'common.hours': 'Ur',
        'common.minutes': 'Minut',
        'common.seconds': 'Sekund',
        'common.mins': 'Min',
        'common.secs': 'Sek',
        'common.ooops': 'Uuups!',
        'common.warning': 'Opozorilo',
        'common.page_not_found': "Stran, ki jo iščete ne obstaja ali nimate pravic za dostop do te povezave.",
        'common.leave_a_comment': 'Napišite komentar...',
        'common.name': 'Naziv',
        'common.list': 'Seznam',
        'common.national_id': 'EMŠO',
        'common.verify_email_to_view': 'Potrdite e-pošto za ogled',
        'common.did_not_get_verify_email': 'Niste prejeli potrditvene e-pošte?',
        'common.confirmDeleteTitle': 'Potrditev',
        'common.confirmDeleteText': 'Ali ste prepričani?',
        'common.addCompetency': 'Dodaj kompetenco',
        'common.definition': 'Definicija',
        'common.relations': 'Relacije',
        'common.alertTitle': 'Opozorilno okno',
        'common.alertTextCompetencies': 'Izberite vsaj 5 kompetenc.',
        'common.alertTextDisputeCase': 'Izbrati morate odstotek med 5 in 100.',

        'tooltip.fill_out': 'Izpolnite naslednje sekcije:',
        'tooltip.email_verification': 'Potrdite vašo e-pošto',
        'tooltip.personal_info': 'Osnovni podatki',
        'tooltip.description': 'Opis',
        'tooltip.profile_image': 'Profilna slika',
        'tooltip.cover_image': 'Naslovna slika',
        'tooltip.availability': 'Razpoložljivost',
        'tooltip.competencies': 'Kompetence',
        'tooltip.experience': 'Izkušnje',
        'tooltip.education': 'Izobrazba',
        'tooltip.issuer_id': 'Za generiranje ključa kompetence. Primer "imeuniverze_imekompetence".',
        'tooltip.issuer_task_competencies': 'Upravljajte kako dovoljujete, da se uporabljajo vaše kompetence v nalogah ustvrjenih iz strani naročnikov in prislužene iz strani izvajalcev.',
        'tooltip.issuer_disabled': 'Ne dovolite uporabe vaših kompetenc v nalogah iz strani tretjih uporabnikov.',
        'tooltip.issuer_sys_certificate': 'Dovolite uporabo vaših kompetenc v nalogah z kopijo podpisano iz strani sistemskega izdajatelja.',
        'tooltip.issuer_my_certificate': 'Dovolite uporabo vaših kompetenc v nalogah.',
        'tooltip.issuer_public_adding': 'Dovolite drugim uporabnikom, da ustvarijo nove kompetence v vašem imenu.',
        'tooltip.issuer_public_voting': 'Omogočite javna mnenja o definicijah in povezavah o vsebinah kompetenc.',
        'tooltip.issuer_external_issuer': 'Izberite, če ste zunanji eksterni izdajatelj. Naš program bo poskušal nadgraditi vaše kompetence. Kontaktirajte nas za več informacij.',
        'tooltip.issuer_external_issuer_details': 'Če ste zunanji eksterni izdajatelj, bo naš program poskušal nadgraditi vaše kompetence. Kontaktirajte nas za več informacij.',

        'tooltip.earned_competencies': 'Kompetence, ki jih je uporabnik pridobil ali z uspešno zaključenimi nalogami ali preko potrjene formalne izobrazbe.',
        'tooltip.userdefined_competencies': 'Kompetence, ki si jih je uporabnik dodal na svoj profil sam.',
        'common.earned': 'PRIDOBLJENE', 
        'common.user_defined': 'UPORABNIŠKE', 

        'setup_profile.company_info': 'Podatki o podjetju',
        'setup_profile.required': 'Potrebne informacije, preden lahko nekoga najamete',
        'setup_profile.work_info': 'Podatki o delu',
        'setup_profile.availability': 'Nastavite razpoložljivost',
        'setup_profile.full_time': 'Polni delovni čas',
        'setup_profile.part_time': 'Krajši delovni čas',
        'setup_profile.all': 'Vsi',
        'setup_profile.not_available': 'Ne sprejemam dela',
        'setup_profile.hourly_rate': 'Tvoja urna postavka',
        'setup_profile.select_days': 'Izberite delovne dni',
        'setup_profile.desc': 'Z ECTA lahko najdete popolne projekte za vas in se lotite dela!',
        'setup_profile.finish': 'Odlično opravljeno!',
        'setup_profile.finish.desc': 'Profil lahko nadaljnjo nadgradite na strani Moj profil.',
        'setup_profile.projects.desc': "Za vas smo že našli nekaj projektov!",
        'setup_profile.projects': 'Iščite po projektih',
        'setup_profile.profile': 'Ogled mojega profila',
        'setup_profile.fill_company_data': 'Prosimo izpolnite spodnja polja, če ste zastopnik podjetja.',
        'setup_profile.represent': 'Zastopam podjetje',
        'setup_profile.projects_duration': 'Trajanje projektov',
        'setup_profile.interest_projects_duration': 'Zanimajo me projekti, ki trajajo',
        'setup_profile.interest_task_pricing': 'Zanimajo me naloge z proračunom', // 'I'm interested in tasks with remuneration',
        'setup_profile.dev.finished': 'Za vas smo poiskali že nekaj nalog!',
        'setup_profile.inv.finished': 'Raziščite obstoječe projekte ali ustvarite nov projekt.',
        'setup_profile.edu.finished': 'Raziščite obstoječe Institucije ali pojdite na stran Moja Institucija.',
        'setup_profile.aud.finished': 'Raziščite obstoječe Revizorje ali pojdite na stran Moje Revizije.',

        'activate_profile.awesome': 'Super!',
        'activate_profile.desc': 'Vaš račun je aktiviran!',
        'activate_profile.desc_long': 'Zdaj se lahko prijavite in nadaljujete z nastavitvijo svojega profila.',
        'activate_profile.error': 'Ups!',
        'activate_profile.error_desc': 'Žeton je neveljaven ali pa je potekel!',
        'activate_profile.error_desc_long': 'Prosimo, prijavite se, pojdite na nastavitve svojega profila in ponovno pošljite aktivacijsko e-poštno sporočilo.',

        'my_projects.add_tasks': 'Dodaj nalogo',
        'my_projects.create_task': 'Ustvari Nalogo',
        'my_projects.not_published': 'Tvoj projekt še ni objavljen',
        'my_projects.publish': 'Objavi projekt',
        'my_projects.private': 'Tvoj projekt je privaten. Povabi izvajalce na odprte naloge.',
        'my_projects.project_overview': 'Pregled Projekta',
        'my_projects.invoices': 'Računi',
        'my_projects.is_working': 'Trenutno dela na nalogi',
        'my_projects.finished_work': 'Zaključil delo na nalogi',
        'my_projects.view_task': 'Ogled Naloge',
        'my_projects.submited_work': 'Oddano delo',
        'my_projects.review_work': 'Pregled dela',
        'my_projects.preview': 'Predogled in objava',
        'my_projects.you_can_complete_your_project': 'Projekt je na voljo za zaključek',
        'my_projects.complete': 'Končaj projekt',
        'my_projects.view': 'Ogled Projekta',
        'my_projects.invite_freelancers': 'Povabi razvijalce',

        'my_projects.warning_info': 'Nekateri parametri, ki jih skušate urejati so izven obsega parametrov projekta',
        'my_projects.task_price_exceeds': 'Cena naloge presega predvideni proračun projekta za <b>{price}</b>.',
        'my_projects.task_start_date_before': 'Začetni datum naloge (<b>{task_date}</b>) je pred datumom začetka projekta (<b>{project_date}</b>).',
        'my_projects.task_end_date_after': 'Končni datum naloge (<b>{task_date}</b>) je po končnem datumu projekta (<b>{project_date}</b>).',
        'my_projects.save_will_update': 'Potrdite nadaljevanje in projekt bo posodobljen z naslednjimi vrednostmi',

        'my_task.upload': 'Naložite dokumente',
        'my_task.complete': 'Zaključite nalogo',
        'my_task.not_published': 'Tvoja naloga še ni objavljena',
        'my_task.publish': 'Objavi nalogo',
        'my_task.publish_invite': 'Objavite svoj projekt in povabite ljudi k tej zasebni nalogi.',
        'my_task.private': 'Tvoja naloga je zasebna. Povabi izvajalce in začni pogajanje.',
        'my_task.invite': 'Povabi izvajalce.',
        'my_task.back_to_all': 'Nazaj na seznam nalog',
        'my_task.review_work': 'Preglej dokončano delo',
        'my_task.review_user': 'Oceni izvajalca',
        'my_task.task_overview': 'Pregled naloge',
        'my_task.task_proposals': 'Ponudbe',
        'my_task.submited_work': 'Oddano delo',
        'my_task.new_offer': 'Nova ponudba',
        'my_task.send_offer': 'Pošlji ponudbo',
        'my_task.final': 'Končna oddaja',
        'my_task.submit': 'Oddaj delo',
        'my_task.completed': 'Naloga končana?',
        'my_task.completed_desc': 'Priložite datoteke in zaključite z delom.',

        'my_task_tooltip.negotiation': 'Seznam nalog, ki so v fazi pogajanja.',
        'my_task_tooltip.confirmed': 'Seznam nalog, ki so bile potrjene.',
        'my_task_tooltip.in_progress': 'Seznam nalog, ki so v fazi izvajanja.',
        'my_task_tooltip.submitted': 'Seznam nalog, ki so bile oddane.',
        'my_task_tooltip.completed': 'Seznam nalog, ki so bile končane.',
        'my_task_tooltip.in_audit': 'Seznam nalog, ki so v fazi pregledovanja.',
        'my_task.tooltip.rejected': 'Seznam nalog, ki so bile zavrnjene ali odpovedane.',
        'my_task.tooltip.status': 'Status ponudbe',

        'my_audits.negotiation': 'Pogajanje',
        'my_audits.communication': 'Komunikacija',
        'my_audits.file_submission': 'Predložitev datotek',
        'my_audits.complete': 'Celotna zgodovina',

        // Message Sent
        'history.freelancer_send_message.title': 'Freelancer sent a message',
        'history.freelancer_send_message.text': '"<b>{message}</b>"',
        'history.client_send_message.title': 'Client sent a message',
        'history.client_send_message.text': '"<b>{message}</b>"',
        //Task Status Changed
        'history.task_status_changed.title': 'Task status changed',
        'history.task_is_in_negotiation.text': 'Task went into negotiation.',
        'history.task_is_published.text': 'Task was published.',
        'history.task_is_draft.text': 'Task was was changed to draft.',
        'history.task_is_in_dispute.text': 'Client filed a dispute.',
        'history.task_is_active.text': 'Task went into active status.',
        'history.task_is_in_progress.text': 'Task start date has started.',
        'history.task_is_submitted.text': 'Freelancer marked task as completed.',
        'history.task_is_pending_audit.text': 'Client filed a dispute with a complaint: ',
        'history.task_is_in_audit.text': 'Auditor accepted to review the dispute.',
        'history.task_is_auditor_confirmed.text': 'Auditor marked task as confirmed.',
        'history.task_is_auditor_failed.text': 'Auditor marked task as failed.',
        'history.task_is_completed.text': 'Task was successfully completed.',
        //Task Auditor Changed
        'history.task_auditor_changed.title': 'Auditor for task <b>{task_name}</b> changed',
        'history.task_auditor_changed.text': 'Because of time emitted, active auditor changed.',
        //Task Proposal Changed
        //CLIENT_INVITED, FREELANCER_PROPOSED, TASK_EDITED, FILE_EDITED, SENT_FINAL, ACCEPTED, COUNTERED, REJECTED, AUTO_REJECTED
        'history.task_proposal_changed_client_invited.title': 'Client <b>{client_name}</b> has sent invitation',
        'history.task_proposal_changed_client_invited.text': 'Client has invited freelancer to participate in task',
        'history.task_proposal_changed_freelancer_proposed.title': 'New task proposal',
        'history.task_proposal_changed_freelancer_proposed.text': 'Freelancer sent a task proposal',
        'history.task_proposal_changed_task_changed.title': 'Task <b>{task_name}</b> data changed',
        'history.task_proposal_changed_task_changed.text': 'Client <b>{client_name}</b> changed task data, so the proposal reset.',
        'history.task_proposal_changed_file_edited.title': 'Task <b>{task_name}</b> documentation changed',
        'history.task_proposal_changed_file_edited.text': 'Client <b>{client_name}</b> changed task documents, so the proposal reset.',
        'history.task_proposal_changed_sent_final.title': 'Final task proposal',
        'history.task_proposal_changed_sent_final.text': 'Client sent a task proposal',
        'history.task_proposal_changed_accepted.title': 'Task Proposal accepted',
        'history.task_proposal_changed_accepted.text': 'Freelancer accepted the proposal',
        'history.task_proposal_changed_countered.title': 'Task Proposal has been changed',
        'history.task_proposal_changed_countered.text': 'A new proposal has been submitted.',
        'history.task_proposal_changed_rejected.title': 'Task Proposal has been rejected',
        'history.task_proposal_changed_rejected.text': 'Proposal between Freelancer <b>{freelancer_name}</b> and client <b>{client_name}</b> on this task has been <b>Rejected</b>.',
        'history.task_proposal_changed_auto_rejected.title': 'Other proposal has been accepted',
        'history.task_proposal_changed_auto_rejected.text': 'Proposal between Freelancer <b>{freelancer_name}</b> and client <b>{client_name}</b> on this task has been <b>Auto Rejected</b>, because other proposal has been accepted.',
        //Task Result
        'history.task_result_added.title': 'Freelancer sent results',
        'history.task_result_added.text': '"<b>{result_name}</b>"',
        'history.task_result_edited.title': 'Task result modified:',
        'history.task_result_edited.text': 'Freelancer changed new result set with comment: "<b>{result_name}</b>"',
        //Task Result Document
        'history.task_result_document_added.title': 'Freelancer added files to results:',
        'history.task_result_document_added.text': '<b>{freelancer_name}</b> added a document named "<b>{document_name}</b>"',
        'history.task_result_document_deleted.title': 'Freelancer <b>{freelancer_name}</b> removed a document',
        'history.task_result_document_deleted.text': '<b>{freelancer_name}</b> removed a document named "<b>{document_name}</b>"',

        //Plural
        "plural.tasks": "{count, plural, =0 {Ni Nalog} one {# Naloga} two{# Nalogi} few{# Naloge} =4{# Naloge} other{# Nalog}}",
        "plural.projects": "{count, plural, =0 {Ni Projektov} one {# Projekt} two{# Projekta} few{# Projekti} =4{# Projekta} other{# Projektov}}",
        "plural.auditors": "{count, plural, =0 {Ni Avditorjev} one {# Avditor} two{# Avditorja} few{# Avditorji} =4{# Avditorji} other{# Avditorjev}}",
        "plural.clients": "{count, plural, =0 {Ni Naročnikov} one {# Naročnik} two{# Naročnika} few{# Naročniki} =4{# Naročniki} other{# Naročnikov}}",
        "plural.freelancers": "{count, plural, =0 {Ni Izvajalcev} one {# Izvajalec} two{# Izvajalca} few{# Izvajalci} =4{# Izvajalci} other{# Izvajalcev}}",
        "plural.competencies": "{count, plural, =0 {Ni Kompetenc} one {# Kompetenca} other {# Kompetenc}}",
        "plural.edus": "{count, plural, =0 {Ni Izobraževalnih ustanov} one {# Izobraževalna ustanova} two{# Izobraževalni ustanovi} few{# Izobraževalne ustanove} =4{# Izobraževalne ustanove} other{# Izobraževalnih ustanov}}",
        "plural.competences.selected": "{count, plural, =0 {Ni izbranih kompetenc} one {# izbrana Kompetenca} two{# izbrani Kompetenci} few{# Izbrane kompetence} =4{# Izbrane kompetence} other{# izbranih kompetenc}}",
        "plural.files.attached": "{count, plural, =0 {Ni Priloženih Datotek} one {# Priložena Datoteka} two{# Priloženi Datoteki} few{# Priložene Datoteke} =4{# Priložene Datoteke} other{# Priloženih Datotek}}",
        "plural.task.proposals": "{count, plural, =0 {Ni Ponudb} one {# Ponudba} two{# Ponudbi} few{# Ponudbe} =4{# Ponudbe} other{# Ponudb}}",
        "plural.profile.reviews": "{count, plural, =0 {Ni ocen}  one {# Ocena} two{# Oceni} few{# Ocen} =4{# Ocene} other{# Ocen}}",


        'demo.hello': 'Hej.',
        'forms.loginForm': 'Prijava',
        'forms.registrationForm': 'Registracija',
        'forms.registerRoleForm': 'Registracija Vloge',
        'forms.forgotPasswordForm': 'Pozabljeno geslo',
        'forms.resetPasswordForm': 'Ponastavitev gesla',

        'forms.reset_filters': 'Ponastavite filtre',

        'forms.labels.username': 'Uporabniško ime',
        'forms.labels.password': 'Geslo',
        'forms.labels.newPassword': 'Novo geslo',
        'forms.labels.confirmPassword': 'Potrdi geslo',
        'forms.labels.email': 'E-pošta',
        'forms.labels.name': 'Ime',
        'forms.labels.firstName': 'Ime',
        'forms.labels.lastName': 'Priimek',
        'forms.labels.gender': 'Spol',
        'forms.labels.gender.male': 'Moški',
        'forms.labels.gender.female': 'Ženska',
        'forms.labels.login': 'Prijava',
        'forms.labels.loginWithSocial': 'Prijavite se s socialnim omrežjem.',
        'forms.labels.forgotPassword': 'Ste pozabili geslo? ',
        'forms.labels.resetHere': 'Resetirajte geslo.',
        'forms.labels.noAccountYet': 'Še nimate računa? ',
        'forms.labels.goToRegistration': 'Pojdite na registracijo.',
        'forms.labels.sendMail': 'Pošlji sporočilo',
        'forms.labels.register': 'Ustvari račun',
        'forms.labels.resetPassword': 'Ponastavite geslo',
        'forms.labels.alreadyHaveAccount': 'Že imate račun? ',
        'forms.labels.goToLogin': 'Pojdite na prijavo.',
        'forms.labels.update': "Posodobi",
        'forms.labels.first_name': "Ime",
        'forms.labels.last_name': "Priimek",
        'forms.labels.address_line_1': "Naslov",
        'forms.labels.address_line_2': "Naslov - vrstica 2",
        'forms.labels.post_code': "Poštna številka",
        'forms.labels.city': "Kraj",
        'forms.labels.state': "Pokrajina",
        'forms.labels.country': "Država",
        'forms.labels.vat': "Davčna številka",
        'forms.labels.register_num': "Registracijska številka",
        'forms.labels.company_name': "Naziv podjetja",
        'forms.labels.howitworks': "Kako deluje",
        'forms.labels.roleSelect': 'Izberite svojo vlogo (lahko jih dodate več kasneje)',
        'forms.labels.continue': 'Nadaljuj',
        'forms.labels.rememberme': 'Ostani prijavljen',
        'forms.labels.continueWithGoogle': 'Prijavi se z Google-om',
        'forms.labels.iagreewiththe': 'Strinjam se z',
        'forms.labels.termsandconditions': 'pogoji uporabe',
        'forms.labels.and': 'in',
        'forms.labels.more': 'več',
        'forms.labels.privacypolicy': 'politiko zasebnosti',
        'forms.labels.status': 'Status',
        'forms.labels.project_name': 'Ime projekta',
        'forms.labels.task_name': 'Ime naloge',
        'forms.labels.category': 'Kategorija',
        'forms.labels.short_description': 'Kratek opis',
        'forms.labels.description': 'Opis',
        'forms.labels.documents': 'Dokumenti',
        'forms.labels.add_documents': 'Dodaj dokumente',
        'forms.labels.select_documents': 'Izberite svoje dokumente',
        'forms.labels.selected_documents': 'Izbrani dokumenti',
        'forms.labels.drag_documents': 'Povleci dokumente sem ali klikni na gumb izberi',
        'forms.labels.estimated_start_end': 'Predviden čas začetka/konca',
        'forms.labels.price': 'Cena',
        'forms.labels.required_competences': 'Potrebne kompetence',
        'forms.labels.visibility': 'Vidnost',
        'forms.labels.accept_proposal': 'Ali ste prepričani, da želite sprejeti ta predlog naloge?',
        'forms.labels.comment': 'Komentar',
        'forms.labels.public_link': 'Javna povezava',
        'forms.labels.min_chars': '(min {min} znakov)',
        'forms.labels.invite_freelancers_to_task': 'Povabite izvajalce na naloge',
        'forms.labels.contributed_by': 'Prispeval',
        'forms.labels.registerWithGoogle': "Prijava za Google računom",
        'forms.labels.invited_freelancers': 'Povabljeni razvijalci:',

        'forms.placeholders.comment': 'Dodajte komentar k svojemu delu ...',
        'forms.placeholders.select_chat': 'Izberite stik za klepet ...',
        'forms.placeholders.messages': 'Napišite sporočilo...',
        'forms.placeholders.competence': 'Dodajte 1-5 kompetenc',
        'forms.placeholders.addcompetence': 'Dodaj kompetence',
        'forms.placeholders.select': 'Izberite...',
        'forms.placeholders.username': 'Vnesite svoje uporabniško ime...',
        'forms.placeholders.email': 'Vnesite svoj e-poštni naslov...',
        'forms.placeholders.newPassword': 'Vnesite novo geslo...',
        'forms.placeholders.confirmPassword': 'Potrdite novo geslo...',
        'forms.placeholders.category': 'Izberite kategorijo...',
        'forms.placeholders.select_country': 'Izberite državo...',
        'forms.placeholders.search_country': 'Išči...',
        'forms.placeholders.select_freelancer': 'Izberi razvijalce...',

        'validators.required': 'To polje je obvezno!',
        'validators.minLenIs': 'Geslo mora vsebovati najmanj {num, number} znakov.',
        'validators.minLen8': 'Geslo mora vsebovati najmanj 8 znakov.',
        'validators.textInputRequired': 'To polje je obvezno.',
        'validators.passwordMatch': 'Geslo se mora ujemati.',
        'validators.maxSize': 'Največja dovoljena velikost je 20Mb.',
        'validators.minChars': 'Minimalno 10 znakov ',
        'validators.left': ' preostane',
        'validators.needsNumber': 'Geslo mora vsebovati vsaj eno številko.',
        'validators.needsMixedCharacters': 'Geslo mora vsebovati mešane velik in male črke.',
        'validators.needsSpecialCharasters': 'Geslo mora vsebovati vsaj en poseben znak (!"#$).',

        'nav.labels.logout': 'Odjava',
        'nav.labels.profile': 'Profil',
        'nav.labels.profile.select': 'Izberi Profil',
        'nav.labels.login': 'Prijava',
        'nav.labels.register': 'Registracija',
        'nav.labels.browse': 'Išči',
        'nav.labels.myProjects': 'Moji projekti',
        'nav.labels.myTasks': 'Moje naloge',
        'nav.labels.dashboard': 'Nadzorna plošča',
        'nav.labels.myWork': 'Moje delo',
        'nav.labels.myInstitution': 'Moja Ustanova',
        'nav.labels.myAudits': 'Moje delo',
        'nav.labels.myClients': 'Moje stranke',
        'nav.labels.howitworks': 'Kako deluje',
        'nav.labels.postaproject': 'Objavi projekt',
        'nav.labels.profile_setup': 'Nastavitev profila',
        'nav.labels.published_projects': 'Objavljeni projekti',

        'infobar.get_hired': 'Pridobite zaposlitev danes!',
        'infobar.complete_profile': 'Dopolnite svoj profil in povečajte možnosti za zaposlitev.',
        'infobar.start_hiring': 'Začnite zaposlevati ljudi danes!',
        'infobar.complete_profile_2': 'Preden začnete zaposlevati, dopolnite svoj profil.',
        'infobar.setup_profile': 'Dopolni profil',

        'roles.role': 'Vloga',
        'roles.name.DEV': 'Izvajalec',
        'roles.name.INV': 'Naročnik',
        'roles.name.AUD': 'Avditor',
        'roles.name.EDU': 'Izobraževalna ustanova',
        'roles.name.EAP': 'Administrator programov',
        'roles.name.EAS': 'Administrator študentov',
        'roles.name.EAC': 'Administrator kompetenc',
        'roles.name.NEW': 'Nova vloga',
        'roles.add.DEV': 'Razvijalec, ki išče delo.',
        'roles.add.INV': 'Delodajalec, ki išče izvajalce/razvijalce.',
        'roles.add.AUD': 'Avditor',
        'roles.add.EDU': 'Izobraževalna ustanova',
        'roles.legalName': 'Pravno ime*',
        'roles.nameAndSurname': 'Ime in priimek*',
        'roles.no_active_role_yet': 'Brez aktivne vloge?',
        'roles.please_register_role': 'Prosimo, ustvarite si vlogo s katero boste uporabljali ECTO.',
        'roles.delete_this_role': 'Pobriši to vlogo?',
        'roles.add_admin': 'Dodajanje administratorja',

        'edu.students': 'Študenti',
        'edu.student_list': 'Seznam študentov',
        'edu.student_requests': 'Potrjevanje študentov',
        'edu.no_student_requests': 'Brez podatkov',
        'edu.verification_requests': 'Prošnje za verifikacijo',
        'edu.confirm_requests': 'Preverjanje in potrjevanje prošenj za verifikacijo vaših alumni.',
        'edu.alumni_list': 'Seznam vaših verificiranih študentov alumni',
        'edu.edu_programs': 'Izobraževalni programi',
        'edu.programs': 'Programi',
        'edu.degrees': 'Stopnje',
        'edu.program': 'Program',
        'edu.degree': 'Stopnja',
        'edu.inst_admins': 'Administratorji institucije',
        'edu.manage_admins': 'Urejanje uporabnikov, pooblaščenih za administracijo institucije.',
        'edu.student_admins': 'Administratorji študentov',
        'edu.program_admins': 'Administratorji izobraževalnih programov',
        'edu.competencies_admins': 'Administratorji kompetenc',
        'edu.manage_programs': 'Urejanje izobraževalnih programov in stopenj vaše institucije',
        'edu.send_request': 'Prošnja za verifikacijo',
        'edu.student_id': 'Št. študenta',
        'edu.diploma_id': 'Št. diplome',

        'degrees.new': 'Nova stopnja',
        'degrees.addnew': 'Dodajanje nove stopnje',
        'degree.create.error': 'Napaka pri kreiranju nove stopnje. ',
        'degree.update.error': 'Napaka pri urejanju stopnje. ',
        'degree.delete.error': 'Napaka pri brisanju stopnje. ',

        'program.create.error': 'Napaka pri kreiranju novega programa. ',
        'program.update.error': 'Napaka pri urejanju programa. ',
        'program.delete.error': 'Napaka pri brisanju programa. ',
        'programs.addnew': 'Dodajanje novega programa',
        'programs.your_competencies': 'Kompetence (poiščite ali kreirajte svoje)',

        'rating.satisfied': 'Ste zadovoljni z rezultatom?',
        'rating.review': 'Ocenite uporabnika',
        'rating.review_aud': 'Ocenite postopek revizije',
        'rating.review_client': 'Oddajte povratno informacijo o delu z naročnikom',
        'rating.review_inv': 'Ocenite naročnika',
        'rating.feedback': 'Pustite povratne informacije',
        'rating.feedback_long': 'Zapiši oceno za tega uporabnika ...',
        'rating.review_work': 'Pregled oddanega dela',
        'rating.open_dispute': 'Zavrni in pošlji v revizijo',
        'rating.confirm_and_pay': 'Potrdi in plačaj',
        'rating.confirm_and_pay_repeat': 'Potrdi in ponovi',
        'rating.review_audit_title': 'Ste zadovoljni z postopkom revizije?',
        'rating.review_audit': 'Ocenite postopek glede na spodnje kategorije',

        'rating.category.communication': 'Komunikacija',
        'rating.category.quality': 'Kvaliteta dela',
        'rating.category.price': 'Primerna cena',
        'rating.category.recommend': 'Bi priporočil',
        'rating.category.work_again': 'Bi še sodeloval',
        'rating.category.documentation': 'Kvalitetna dokumentacija',
        'rating.category.quality_of_explanation': 'Kakovostna obrazložitev',
        'rating.category.length_of_explanation': 'Primerno podrobna obrazložitev',
        'rating.category.satisfaction_with_verdict': 'Zadovoljstvo z odločitvijo',

        'messages.messages': 'Sporočila',
        'messages.people': 'Stiki',
        'messages.online': 'Na voljo',
        'messages.offline': 'Ni na voljo',
        'messages.you': 'Ti',

        'components.languageSelect': 'Izbor jezika',
        'components.roleSelect': 'Izbor vloge',

        'browse.projects': 'Projekti',
        'browse.developers': 'Izvajalci',
        'browse.investors': 'Naročniki',
        'browse.auditors': 'Avditorji',
        'browse.edus': 'Izobraževalne ustanove',
        'browse.browse_projects': 'Razišči projekte',
        'browse.browse_tasks': 'Razišči naloge',
        'browse.browse_auditors': 'Razišči avditorje',
        'browse.browse_edus': 'Razišči izobraževalne ustanove',
        'browse.goto_myedu': 'Pojdi na stran Moja Institucija',
        'browse.goto_myaudits': 'Pojdi na stran Moje Revizije',


        'login.error': 'Ups! Napačno uporabniško ime ali geslo. Prosimo poskusite ponovno.',
        'register.error': 'Ups! Registracija ni uspela. Prosimo poskusite ponovno.',
        'forgotpassword.error': 'Ups! Ponastavitev gesla ni uspela. Prosimo poskusite ponovno.',
        'resetpassword.error': 'Ups! Ponastavitev gesla ni uspela. Prosimo poskusite ponovno.',

        'role.change.error': 'Ups! Sprememba profila ni uspela. Prosimo poskusite ponovno.',
        'role.create.error': 'Ups! Kreiranje profila ni uspelo. Prosimo poskusite ponovno.',
        'role.get.error': 'Ups! Pridobivanje profila ni uspelo. Prosimo poskusite ponovno.',
        'role.update.error': 'Ups! Sprememba profila ni uspela. Prosimo poskusite ponovno.',
        'role.review.submit.error': 'Ups! Pošiljanje ocene ni uspelo. Prosimo poskusite ponovno.',
        'role.detail.update.error': 'Ups! Sprememba podatkov profila ni uspela. Prosimo poskusite ponovno.',
        'role.experience.create.error': 'Ups! Kreiranje izkušenj ni uspela. Prosimo poskusite ponovno.',
        'role.experience.update.error': 'Ups! Sprememba izkušenj ni uspela. Prosimo poskusite ponovno.',
        'role.experience.delete.error': 'Ups! Odstranitev izkušenj ni uspela. Prosimo poskusite ponovno.',
        'role.education.create.error': 'Ups! Kreiranje šolanja ni uspela. Prosimo poskusite ponovno.',
        'role.education.update.error': 'Ups! Sprememba šolanja ni uspela. Prosimo poskusite ponovno.',
        'role.education.delete.error': 'Ups! Odstranitev šolanja ni uspela. Prosimo poskusite ponovno.',
        'role.freelacers.get.error': 'Ups! Pridobivanje izvajalcev ni uspela. Prosimo poskusite ponovno.',
        'role.clients.get.error': 'Ups! Pridobivanje naročnikov ni uspela. Prosimo poskusite ponovno.',
        'role.auditors.get.error': 'Ups! Pridobivanje avditorjev ni uspela. Prosimo poskusite ponovno.',
        'role.edus.get.error': 'Ups! Pridobivanje izobraževalnih ustanov ni uspela. Prosimo poskusite ponovno.',
        'role.upload.profile_pic.error': 'Ups! Sprememba profilne slike ni uspela. Prosimo poskusite ponovno.',
        'role.upload.cover_pic.error': 'Ups! Sprememba naslovne slike ni uspela. Prosimo poskusite ponovno.',

        'project.upload.logo_pic.error': 'Ups! Sprememba logotipa ni uspela. Prosimo poskusite ponovno.',
        'project.upload.cover_pic.error': 'Ups! Sprememba naslovne slike ni uspela. Prosimo poskusite ponovno.',

        'landing.slogan': "Kjer se velike ideje rodijo.",
        'landing.forEmployers': "ZA NAROČNIKE",
        'landing.forDevelopers': "ZA IZVAJALCE",

        'footer.about': 'O projektu',
        'footer.how': 'Kako deluje',
        'footer.terms': 'Pogoji uporabe',
        'footer.privacy': 'Pravilnik o zasebnosti',
        'footer.questions': 'Imate vprašanja?',
        'footer.settings': 'Nastavitve računa in zasebnosti',
        'footer.contact': 'Kontaktirajte nas',

        'browse.common.industry': 'Industrija',
        'browse.common.show': 'Pokaži',
        'browse.common.language': 'Jezik',
        'browse.common.country': 'Država',
        'browse.common.rating': 'Ocena',
        'browse.common.activeprojects': 'Aktivni projekti',
        'browse.common.opentasks': 'Odprte naloge',
        'browse.common.view_my_projects': 'Poglej v Moji Projekti',
        'browse.common.view': 'Podrobnosti',
        "browse.common.viewinmyprojects": 'Poglej v Mojih Projektih',
        'browse.common.search': 'Iskanje',
        'browse.common.sortby': 'Razvrsti po',
        'browse.common.loading': 'Nalaganje',
        'browse.common.createProject': 'Ustvari projekt',
        'browse.common.commingsoon': 'Prihaja kmalu',
        'browse.common.view_my_work': 'Poglej v \'Mojem Delu\'',
        'browse.common.allrecordsloaded': 'Vsi podatki prikazani',
        'browse.common.select-issuer': 'Izberi izdajatelja',

        'browse.order.startingsoon': 'Se začne kmalu',
        'browse.order.highestbudget': 'Najvišji proračun',
        'browse.order.lowestbudget': 'Najnižji proračun',
        'browse.order.byprojects': 'Po projektih',
        'browse.order.byclient': 'Po izvajalcu',
        'browse.order.highestrating': 'Najvišja ocena',
        'browse.order.lowestrating': 'Najnižja ocena',
        'browse.order.mostactivetasks': 'Največ aktivnih nalog',
        'browse.order.mostfinishedtasks': 'Največ dokončanih nalog',
        'browse.order.highesthourlyrate': 'Najvišjna urna postavka',
        'browse.order.lowesthourlyrate': 'Najnižja urna postavka',
        'browse.order.nameasc': 'Nazivu naraščujoče',
        'browse.order.namedesc': 'Nazivu padajoče',

        'browse.project.budget': 'Proračun',
        'browse.project.timeframe': 'Časovni okvir',
        'browse.project.open_tasks': 'Odprte naloge',
        'browse.project.participating': 'Pri projektu sodelujejo',
        'browse.project.about': 'O projektu',
        'browse.project.join': 'Pridruži se projektu!',
        'browse.project.explore': 'Razišči naloge',
        'browse.project.viewtask': 'Poglej podrobnosti',
        'browse.project.verify_email_short': 'Preverite svojo e-pošto',
        'browse.project.verify_email': 'Prosimo potrdite vaš e-poštni naslov pred uporabljanjem platforme',
        'browse.project.re-send_email': 'Ponovno pošlji aktivacijsko e-pošto',
        'browse.project.company': 'Podjetje',
        'browse.project.location': 'Lokacija',
        'browse.project.about_client': 'O podjetju',
        'browse.project.project_details': 'Podrobnosti Projekta',

        'browse.task.negotiation': 'Pogajanje je v teku',
        'browse.task.negotiations_completed': 'Pogajanje je končano',
        'browse.task.post_negotiation': 'Nekdo že opravlja trenutno nalogo.',
        'browse.task.private_invite_only': 'Tvoja naloga je zasebna. Povabite izvajalce k tej nalogi.',
        'browse.task.invite_only': 'Naloga je zasebna',
        'browse.task.view_my_work': 'Poglej v Moje delo',
        'browse.task.submit_task_proposal': 'Oddaj ponudbo',
        'browse.task.submit_task_proposal_counter': 'Oddaj kontra ponudbo',
        'browse.task.like_task': 'Vam je naloga všeč?',
        'browse.task.invite_free_long': 'Povabite izvajalce k tej nalogi.',
        'browse.task.invite_free': 'Povabite izvajalce',
        'browse.task.goto_my_projects': 'Pojdite na moje projekte',
        'browse.task.switch_to_participate': 'Zamenjajte vlogo, če želite sodelovati',
        'browse.task.back': 'Nazaj na Seznam Nalog',
        'browse.task.about': 'O nalogi',
        'browse.task.required_competences': 'Potrebne kompetence',
        'browse.task.description': 'Podroben opis',
        'browse.task.milestone': 'Mejniki',
        'browse.task.remuneration': 'Plačilo naloge',
        'browse.task.fixed': 'Fiksno',
        'browse.task.timeframe': 'Časovni okvir',
        'browse.task.details': 'Podrobnosti Naloge',
        'browse.task.proposal_rejected': 'Ponudba zavrnjena',

        'dashboard.welcome': 'Dobrodošel',
        'dashboard.setup': 'Nastavitev profila',
        'dashboard.browse': 'Išči',
        'dashboard.statistics': 'Statistika',
        'dashboard.clients': 'Stranke',
        'dashboard.myactivity': 'Moja aktivnost',
        'dashboard.sidebar': 'Stranska vrstica',

        'competency': 'Kompetenca',
        'competency.new': 'Nova kompetenca',
        'competences.this_person_can': 'Ta oseba je sposobna ',
        'competences.id': 'Id kompetence',
        'competences.name': 'Ime kompetence',
        'competences.create': 'Ustvari novo kompetenco',
        'competences.edit': 'Uredi kompetence',
        'competences.my': 'Moje kompetence',
        'competences.add_five': 'Dodajte vsaj pet kompetenc.',
        'competences.add': 'Dodajte kompetence',
        'competences.add.desc': 'Dodajte svoje kompetence in za vas bomo predlagali popolne projekte!',
        'competences.clients_search': 'Kaj stranke najbolj iščejo',
        'competences.search': 'Išči med kompetencami',
        'competences.least_one': 'Izberite vsaj eno kompetenco',
        'competences.parent': 'Nadrejena kompetenca',
        'competences.no_parent': 'Brez nadrejene kompetence',
        'competences.skills': 'Temeljne kompetence',
        'competences.related_skills': 'Temeljne kompetence',
        'competences.no_related_skills': 'Brez temeljnih kompetenc',
        'competency.add.step1': 'Ime',
        'competency.add.step2': 'Definicija',
        'competency.add.step3': 'Relacije',

        'competences.add_skill_title': 'Predlagaj temeljno kompetenco',
        'competences.add_description_title': 'Predlagaj opis kompetence',
        'competences.add_parent_title': 'Predlagaj nadrejeno kompetenco',
        'competences.add_skill': 'Predlagaj temeljno kompetenco',
        'competences.add_description': 'Predlagaj opis',
        'competences.add_parent': 'Predlagaj nadrejeno kompetenco',
        'competences.contribute_info': 'Sodelujte pri konsenzu o podrobnostih kompetence z prispevanjem ali glasovanjem.',
        'competences.commonly_used': 'Običajno uporabljena v',
        'competences.categories': 'Kategorija (neobvezno)',
        'competences.commonly_used_in': 'Kategorije v katerih se ta kompetenca pogosto upojavlja.',
        'competences.platform_only': 'Samo platforma',
        'competences.parent_description': 'Matična kompetenca postavlja kompetenco na vrh registra.',
        'competences.related_description': 'Povezane ali temeljne kompetence, ki jih ta kompetenca vsebuje ali pa je iz njih sestavljena.',

        'competences.written_by_you': 'ECTA kompetence ustvarja skupnost',
        'competences.created_by_you': 'Kompetence lahko prispevate tudi vi!',
        'competences.top_definition': 'TOP DEFINICIJA',

        'competences.suggested': 'Predlogi',
        'competency.save_button': 'Shrani kompetenco',
        'competency.publish_button': 'Objavi kompetenco',
        'competency.define_common': 'Določa kje je kompetenca najbolj pogosto uporabljena.',
        'competency.blooms_taxonomy': "Bloomova taksonomija",
        'competency.blooms_taxonomy_description': "Bloomova taksonomija nam pomaga klasificirati izobraževalne izraze. Zaključite stavek, ki se začne z:",
        'competency.blooms_taxonomy_info': "Bloomova taksonomija nam pomaga klasificirati izobraževalne izraze.",
        'competency.description': "Opis kompetence",

        'blooms.whats_this': 'Kaj je to?',
        'blooms.L1': 'POMNENJE (zapomniti, definirati, duplicirati, navesti, ponoviti...) je 1. Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L2': 'RAZUMEVANJE (klasificirati, razpravljati, pojasnjevati, prepoznati...) je 2. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L3': 'APLICIRANJE (implementirari, izvesti, uporabljati, demonstrirati...) je 3. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L4': 'ANALIZA (razločevati, povezovati, razlikovati, testirati...) je 4. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L5': 'EVALVACIJA (oceniti, debatirati, zagovarjati, presojati kritizirati...) je 5. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',
        'blooms.L6': 'KREACIJA (zgraditi, sestaviti, oblikovati, razviti, formulirati, raziskati...) je 6. stopnja Bloomove taksonomije, ki je skupek treh hierarhičnih modelov, ki se uporabljajo za razvrščanje izobraževalnih učnih ciljev na stopnje kompleksnosti in specifičnosti.',

        'projects.heading': 'Poišči najboljše projekte in naloge za sebe',
        'projects.industry': 'Industrija',
        'projects.employment': 'Zaposlitev',
        'projects.employmentduration': 'Čas zaposlitve',
        'projects.tasks': 'Naloge',
        'projects.competences': 'Kompetence',
        'projects.duration': 'Trajanje',
        'projects.logintoview': 'Prijavi se za pogled',
        'projects.image': 'Slika projekta',
        'projects.image_desc': 'Naredite svoj projekt bolj privlačen, tako da dodate naslovno sliko in sliko / logotip projekta.',
        'projects.change.image': 'Spremeni sliko projekta',
        'projects.change.cover': 'Spremeni naslovno sliko projekta',
        'projects.estimated_budget': 'Predviden proračun',
        'projects.estimated_start': 'Predvideni datum začetka',
        'projects.estimated_end': 'Predvideni datum konca',

        'education.add': 'Dodaj izobraževanje',
        'education.add.desc': 'Z dodanim izobraževanjem naredite svoj profil bolj verodostojen.',
        'education.school': 'Šola/Univerza',
        'education.degree': 'Stopnja/Program',

        'experience.add': 'Dodaj izkušnjo',
        'experience.add.desc': 'Z dodajanjem izkušenj naredite svoj profil bolj verodostojen.',
        'experience.edit': 'Uredi Izkušnjo',
        'experience.company': 'Podjetje/Projekt',
        'experience.position': 'Položaj/Vloga',

        'freelancers.heading': 'Najboljši razvijalci na svetu!',
        'freelancers.availability': 'Razpoložljivost',
        'freelancers.competences': 'Kompetence',
        'freelancers.hourlyrate': 'Urna postavka',
        'freelancers.completedprojects': 'Končani projekti',
        'freelancers.language': 'Jezik',
        'freelancers.country': 'Država',
        'freelancers.studiedat': 'Študiral na',
        'freelancers.hour': 'uro',
        'freelancers.completedTasks': 'Končane naloge',

        'clients.heading': 'Najbolši lorem ipsum dolor na enem mestu',

        'educationalinstitutions.heading': 'Najbol kredibilne izobraževalne ustanove na enem mestu',

        'auditors.heading': 'Najbolj kredibilni avditorji na enem mestu',
        'auditors.dispute': 'Spor',
        'auditors.dispute_cases': 'Moji Primeri',
        'auditors.accept_case': 'SPREJMI PRIMER',
        'auditors.reject_case': 'Zavrni primer',
        'auditors.submit_final_decision': 'POŠLJI KONČNO ODLOČITEV',

        'auditors.client_filed_dispute': ' Sporazumna razrešitev spora ni uspela',
        'auditors.you_filed_dispute': '  Sporazumna razrešitev spora ni uspela ',
        'auditors.dispute_escalated': ' Primer je bil predan v revizijski postopek.',
        'auditors.auditor_accepted': ' Revizor je primer sprejel. Rok je 7 delovnih dni.',
        'auditors.auditor_accepted_your': ' Revizor je vaš primer sprejel. Rok je 7 delovnih dni.',
        'auditors.auditor_reviewed': ' Revizor je primer pregledal. Podrobnosti:',
        'auditors.auditor_reviewed_your': ' Revizor je vaš primer pregledal. Podrobnosti:',
        'auditors.auditor_decided_1': 'Odločeno je, da je naloga končana ',
        'auditors.auditor_decided_2': 'in plačilo v znesku ',
        'auditors.auditor_decided_3': 'je bilo dodeljeno izvajalcu.',
        'auditors.audit_compensation': 'Plačilo za revizijo',
        'auditors.audit_deadline': 'Rok za revizijo',
        'auditors.audit_deadline_days': '7 delovnih dni',
        'auditors.audit_rules': 'Pravila revizijskega postopka ...',
        'auditors.audit_client_reason': 'Razlog pritožbe stranke:',
        'auditors.dispute_details': 'O sporu',
        'auditors.audit_data': 'Podatki za revizijo',
        'auditors.decided_1': 'Naloga je opravljena',
        'auditors.decided_2': 'in',
        'auditors.decided_3': 'bo izplačilo.',
        'auditors.slider_info': '*S premikanjem drsnika določite delež pravilno opravljene naloge in višino nadomestila, ki ga freelancer prejme od prvotno dogovorjenega zneska. ',
        'auditors.dispute_resolution': 'Odločitev revizije:',
        'auditors.auditor_comment': 'Auditorjev komentar:',
        'auditors.keypoint': 'Ključna točka',
        'auditors.comment': 'Komentar',
        'auditor.dispute_case_details': 'Podatki o primeru spora',
        'auditor.agree_to_continue': 'Potrdite za nadaljevanje',
        'auditor.file_dispute': 'Sproži spor',
        'auditor.file_dispute_proposal': 'Vloži predlog za rešitev spora',

        'modalEditProfileImage.changeyourprofileimage': 'Spremeni svojo profilno sliko',
        'modalEditProfileImage.dragndrop': 'Klikni ali povleci sliko sem',

        'modalEditFreelancerRates.heading': 'Uredi Razpoložljivost in Preference',
        'modalEditFreelancerRates.availability': 'Razpoložljivost',
        'modalEditFreelancerRates.acceptingwork': 'Sprejemam delo',
        'modalEditFreelancerRates.notacceptingwork': 'Ne sprejemam dela',
        'modalEditFreelancerRates.hourlyrate': 'Urna postavka (USD/ura)',

        'modalEditFreelancerEducation.heading': 'Uredi Izobrazbo',
        'modalEditFreelancerEducation.studiedat': 'Študiral na',

        'modalEditProfileInfo.heading': 'Uredi podatke o profilu',
        'modalEditProfileInfo.displayname': 'Prikazno ime',
        'modalEditProfileInfo.companyname': 'Ime organizacije',
        'modalEditProfileInfo.vat': 'DDV',
        'modalEditProfileInfo.firstname': 'Ime',
        'modalEditProfileInfo.lastname': 'Priimek',
        'modalEditProfileInfo.description': 'Opis',
        'modalEditProfileInfo.address': 'Naslov',
        'modalEditProfileInfo.zip': 'Poštna št.',
        'modalEditProfileInfo.city': 'Mesto',
        'modalEditProfileInfo.state': 'Regija',
        'modalEditProfileInfo.country': 'Država',

        'profile.hourlyrate': 'Urna postavka',
        'profile.hour': 'ura',
        'profile.availability': 'Razpoložljivost',
        'profile.completedtasks': 'dokončane naloge',
        'profile.completedtasksupper': 'Dokončane naloge',
        'profile.participatedinprojects': 'sodeloval pri projektih',
        'profile.clients': 'strank',
        'profile.inthepastweek': 'V preteklem tednu',
        'profile.clientswholookedfor': 'Strani ki so iskale',
        'profile.recommendations': 'priporočil',
        'profile.reviews': 'ocen',
        'profile.online': 'Aktiven',
        'profile.nodescription': 'Ni opisa',
        'profile.education': 'Izobrazba',
        'profile.experience': 'Izkušnje',
        'profile.competences': 'Kompetence',
        'profile.reviewsupper': 'Ocene',
        'profile.participatedinprojectsupper': 'Sodeloval pri projektih',
        'profile.participatedintasks': 'Sodeloval pri nalogah',
        'profile.nocity': 'Ni mesta',
        'profile.nocountry': 'Ni države',
        'profile.acceptingwork': 'Sprejema delo',
        'profile.personal': 'Osebni podatki',
        'profile.personal.desc': 'Če izpolnite svoje osebne podatke, postanete bolj verodostojni.',
        'profile.description.add': 'Dodaj opis',
        'profile.description.desc': 'Če dodate kratek opis, povečate možnosti, da se zaposlite.',
        'profile.public': 'Javne informacije',
        'profile.public.desc': 'Javno vidne podrobnosti profila',
        'profile.change.cover': 'Spremeni sliko',
        'profile.change.profile': 'Spremeni sliko profila',
        'profile.change.role': 'Hitro preklapljanje med mojimi profili',

        'DropdownUserMenu.myprofile': 'Moj profil',
        'DropdownUserMenu.addanotherprofile': 'Dodaj novo vlogo',
        'DropdownUserMenu.settings': 'Nastavitve',
        'DropdownUserMenu.balances': 'Denarnice',
        'DropdownUserMenu.ndas': "NDA's",
        'DropdownUserMenu.contracts': 'Pogodbe',
        'DropdownUserMenu.help': 'Pomoč',
        'DropdownUserMenu.resolution_center': "Reševanje sporov",

        'settings.account': 'Uporabniški račun',
        'settings.account_settings': 'Nastavitve uporabniškega računa',
        'settings.payment': 'Transakcije',
        'settings.notification': 'Obvestila',
        'settings.push_notification': 'Potisna obvestila',
        'settings.notification_pref': 'Nastavitve obveščanja',
        'settings.general': 'Splošno',
        'settings.messaging': 'Sporočila',
        'settings.task_related': 'Nalogam odvisna spročila',
        'settings.security': 'Varnost',
        'settings.current_role': 'Vaša trenutna aktivna vloga',

        'resolutionCenter.finalSubmit': 'Strinjam se s pogoji storitve za reševanje sporov.',
        'resolutionCenter.noTaskSelected': 'Nobena naloga ni izbrana. Pojdi na',
        'resolutionCenter.keyPoints': 'Problematične ključne točke (dodajte svoje komentarje)',
        'resolutionCenter.proposePercentage': 'Predlagam, da spor rešimo s plačilom naslednjega odstotka (%) od dogovorjenega zneska',
        'resolutionCenter.costOfDispute': 'Stroški revizije spora',
        'resolutionCenter.agreedTerms': 'Dogovorjeni pogoji',
        'resolutionCenter.more_in_the': 'Več informacij v ',
        'resolutionCenter.faq_section': ' sekciji Pogosta vprašanja...',

        'dispute.proposed': 'je predlagal plačilo ',
        'dispute.of_price': ' od dogovorjene cene.',
        'dispute.i_propose': 'Predlagam plačilo  ',
        'dispute.of_price_2': ' % od dogovorjene cene.',
        'dispute.send_counter': 'POŠLJI PREDLOG',
        'dispute.counter': 'NOV PREDLOG',
        'dispute.accept': 'SPREJMI',
        'dispute.send_to-audit': 'POŠLJI V REVIZIJO',
        'dispute.was_escalated': 'Spor je bil predan v revizijo avditorju.',
        'dispute.expired': 'Čas za sporazumno razrešitev je potekel. Primer je bil avtomatično poslan v revizijo avditorju.',
        'dispute.resolved': 'Spor je bil uspešno rešen sporazumno. Naloga je uspešno zaključena.',
        'dispute.keypoints_with_comments': 'Oporne točne (z komentarji):',
        'dispute.original_comment': 'Prvotni komentar naročnika:',
        'dispute.replies': 'Odgovori:',
        'dispute.marked_resolved': 'Naročnik je označil točko kot rešeno.',
        'dispute.reply': 'Odgovori',
        'dispute.mark_as_resolved': 'OZNAČI KOT REŠENO',


        // Notifications
        'notification.notifications': 'Obvestila',
        'notification.markAllAsRead': 'Označi vse kot prebrano',
        'notification.nothingNew': 'Nič novega',
        'notification.upToDate': 'Z vsem si na tekočem!',
        'notification.seeAll': 'Poglej vse',

        // Notifications...FOR CLIENT & FREELANCER
        'notification.OnReviewRequestSubmitted.title': 'Nova ocena',
        'notification.OnReviewRequestSubmitted.text': 'Dobili ste novo mnenje in oceno od ',

        // Notifications...FOR CLIENT
        'notification.OnFreelancerTaskProposalProposed.title': 'Nova ponudba',
        'notification.OnFreelancerTaskProposalProposed.text': ' ti je poslal/-a ponudbo za nalogo.',
        'notification.OnFreelancerTaskProposalCountered.title': 'Odgovor na ponudbo',
        'notification.OnFreelancerTaskProposalCountered.text': ' ti je poslal/-a nove pogoje ponudbe.',
        'notification.OnTaskProposalAutoRejected.title': 'Ponudba zavrnjena',
        'notification.OnTaskProposalAutoRejected.text': 'Tvoja ponudba je bila zavrnjena.',
        'notification.OnFreelancerTaskProposalRejected.title': 'Ponudba zavrnjena',
        'notification.OnFreelancerTaskProposalRejected.text': ' je zavrnil/-a tvojo ponudbo za nalogo.',
        'notification.OnFreelancerTaskProposalRejectedLast.title': 'Ponudba zavrnjena',
        'notification.OnFreelancerTaskProposalRejectedLast.text': ' je zavrnil/-a tvojo zadnjo ponudbo za nalogo.',
        'notification.OnFreelancerTaskProposalAccepted.title': 'Ponudba sprejeta',
        'notification.OnFreelancerTaskProposalAccepted.text': ' je sprejel/-a tvojo ponudbo za nalogo.',
        'notification.OnFreelancerTaskRejected.title': 'Naloga preklicana',
        'notification.OnFreelancerTaskRejected.text': ' je prekinil delo na nalogi.',
        'notification.OnFreelancerTaskSubmitted.title': 'Naloga opravljena',
        'notification.OnFreelancerTaskSubmitted.text': ' je poslal/-a rezultate dela na nalogi.',
        'notification.OnProjectMayCompleted.title': 'Projekt zaključen',
        'notification.OnProjectMayCompleted.text': 'Vse naloge projekta so zaključene.',
        'notification.OnTaskRecurringCreated.title': 'Ponavljajoče se naloga ustvarjena',
        'notification.OnTaskRecurringCreated.text': 'je bila ustvarjena.',
        'notification.OnTaskAuditorFailedForClient.title': 'Revizija zaključena',
        'notification.OnTaskAuditorFailedForClient.text': 'Avditor je razsodil o vašem primeru.',
        'notification.OnTaskAuditorConfirmedForClient.title': 'Revizija zaključena',
        'notification.OnTaskAuditorConfirmedForClient.text': 'Avditor je razsodil o vašem primeru.',
        'notification.OnTaskInAuditForClient.title': 'Revizija v teku',
        'notification.OnTaskInAuditForClient.text': 'Avditor je sprejel vaš primer v razsojo.',
        'notification.OnFreelancerTaskResultSubmitted.title': 'Novi rezultati',
        'notification.OnFreelancerTaskResultSubmitted.text': ' vam je poslal nove rezultate naloge.',
        'notification.OnTaskPublishedStartTimeWarning.title': 'Pričetek naloge se bliža',
        'notification.OnTaskPublishedStartTimeWarning.text': 'Status naloge se bo spremenil v osnutek.',

        // Notifications...FOR FREELANCER
        'notification.OnClientTaskProposalProposed.title': 'Povabilo na nalogo',
        'notification.OnClientTaskProposalProposed.text': ' te je povabil/-a na sodelovanje pri nalogi.',
        'notification.OnClientTaskProposalCountered.title': 'Nova ponudba',
        'notification.OnClientTaskProposalCountered.text': ' ti je poslal/-a novo ponudbo za nalogo.',
        'notification.OnClientTaskProposalRejected.title': 'Ponudba zavrnjena',
        'notification.OnClientTaskProposalRejected.text': ' je zavrnil/-a tvojo ponudbo za nalogo.',
        'notification.OnClientTaskProposalSentFinal.title': 'Končna ponudba',
        'notification.OnClientTaskProposalSentFinal.text': ' ti je poslal/-a končno ponudbo za nalogo.',
        'notification.OnClientTaskRejected.title': 'Naloga preklicana',
        'notification.OnClientTaskRejected.text': ' je preklical izvajanje naloge.',
        'notification.OnClientTaskCompleted.title': 'Naloga zaključena',
        'notification.OnClientTaskCompleted.text': ' je potrdil/-a, plačal/-a in zaključil/-a nalogo.',
        'notification.OnTaskInProgress.title': 'Naloga aktivna',
        'notification.OnTaskInProgress.text': ' ima rok za dostavo ',
        'notification.OnClientTaskPendingAudit.title': 'Spor o nalogi je bil predan avditorju.',
        'notification.OnClientTaskPendingAudit.text': 'Naloga: ',
        'notification.OnClientTaskInAudit.title': 'Revizija v teku',
        'notification.OnClientTaskInAudit.text': 'Avditor je sprejel primer v revizijo.',
        'notification.OnTaskAuditorConfirmedForFreelancer.title': 'Revizija zaključena',
        'notification.OnTaskAuditorConfirmedForFreelancer.text': 'Avditor je razsodil o primeru.',
        'notification.OnTaskAuditorFailedForFreelancer.title': 'Revizija zaključena',
        'notification.OnTaskAuditorFailedForFreelancer.text': 'Avditor je razsodil o primeru.',
        'notification.OnTaskInAuditForFreelancer.title': 'Revizija v teku',
        'notification.OnTaskInAuditForFreelancer.text': 'Avditor je sprejel primer v revizijo.',
        // Dispute settlement
        'notification.OnClientTaskInDispute.title': 'Spor pri nalogi',
        'notification.OnClientTaskInDispute.text': 'Naročnik je odprl spor za nalogo z predlogom za razrešitev.',
        'notification.OnClientTaskDisputeSetInNegotiation.title': 'Novosti o sporu',
        'notification.OnClientTaskDisputeSetInNegotiation.text': 'Naročnik je posodobil podatke v sporu o nalogi.',
        'notification.OnFreelancerTaskDisputeSetInNegotiation.title': 'Novosti o sporu',
        'notification.OnFreelancerTaskDisputeSetInNegotiation.text': 'Izvajalec je posodobil podatke v sporu o nalogi.',
        'notification.OnFreelancerTaskDisputeSettlementComment.title': 'Novosti o sporu',
        'notification.OnFreelancerTaskDisputeSettlementComment.text': 'Izvajalec je odgovoril na problematično točko spora.',
        'notification.OnClientTaskDisputeSettlementComment.title': 'Novosti o sporu',
        'notification.OnClientTaskDisputeSettlementComment.text': 'Naročnik je odgovoril na problematično točko spora.',

        // Notifications...FOR AUDITOR
        'notification.OnNewTaskDisputeCase.title': 'Povabilo za revizijo',
        'notification.OnNewTaskDisputeCase.text': 'Povabljeni ste bili za presojo v sporu na nalogi.',
        'notification.OnTaskDisputeCaseAutoRejected.title': 'Povabilo poteklo',
        'notification.OnTaskDisputeCaseAutoRejected.text': 'Rok za sodelovanje v presoji o sporu je potekel.',

        // EDU
        'notification.OnNewRoleEducationRequest.title': 'Prošnja za verifikacijo',
        'notification.OnNewRoleEducationRequest.text': ' vam je poslal/-la prošnjo za potrditev izobrazbe.',
        'notification.OnRoleEducationRequestApproved.title': 'Prošnja za verifikacijo',
        'notification.OnRoleEducationRequestApproved.text': ' vam je potrdil/-a izobrazbo.',
        'notification.OnRoleEducationRequestRejected.title': 'Prošnja za verifikacijo',
        'notification.OnRoleEducationRequestRejected.text': ' vam je zavrnil/-a prošnjo za verifikacijo izobrazbe.',

        // ISSUERS - SI
        'issuers.issuer': 'Izdajatelj',
        'issuers.settings': 'Nastavitve izdajatelja',
        'issuers.add_new': 'Dodajanje novega izdajatelja',
        'issuers.issuer_name': 'Oznaka izdajatelja (uporaba v imenu izdanih kompetenc)',
        'issuers.mint_type': 'Uporaba kompetenc za naloge',
        'issuers.MINT_DISABLED': 'Onemogočeno',
        'issuers.MINT_ON_SYS_ISSUER': 'Uporabi sistemsko digitalno potrdilom',
        'issuers.MINT_ON_SAME_ISSUER': 'Uporabi moje digitalno potrdilo',
        'issuers.allow_public_insert': 'Dovoljeno dodajanje kompetenc vsem',
        'issuers.allow_voting': 'Omogoči sistem glasovanja za kompetence',
        'issuers.is_external': 'Zunanji izdajatelj',
        'issuers.get_my_issuers_success': 'Seznam mojih izdajateljev je bil uspešno pridobljen.',
        'issuers.get_my_issuers_error': 'Napaka pri pridobivanju mojih izdajateljev. ',
        'issuers.delete_issuer_success': 'Izdajatelj pobrisan uspešno.',
        'issuers.delete_issuer_fail': 'Napaka pri brisanju izbranega izdajatelja. ',
        'issuers.get_available_issuers_success' : 'Uspešno prejeti razpoložljivi izdajatelji.',
        'issuers.get_available_issuers_fail' : 'Ni bilo mogoče sprejeti razpoložljivih izdajateljev. ',
        'ssuers.get_public_issuers_success' : 'Uspešno sprejeti javni izdajatelji. ',
        'ssuers.get_public_issuers_fail' : 'Javnih izdajateljev ni bilo mogoče sprejeti. ',
        'issuers.create_iusser_success' : 'Uspešno ustvarjen nov izdajatelj.',
        'issuers.create_iusser_fail' : 'Ni uspelo ustvariti novega izdajatelja. ',
        'issuers.get_iusser_details_success' : 'Podrobnosti o vlogi so bila uspešno prejeta. ',
        'issuers.get_iusser_details_fail' : 'Podrobnosti o vlogi ni bilo mogoče dobiti. ',
        'issuers.update_issuer_details_success' : 'Podrobnosti izdajatelja so bile uspešno posodobljene. ',
        'issuers.update_issuer_details_fail' : 'Podrobnosti izdajatelja ni bilo mogoče posodobiti. ',
        'issuers.issuer_details': 'Podrobnosti izdajatelja',
        'issuers.manage_issuer': 'Urejanje nastavitev vašega izdajatelja ter procesa kreiranja kompetenc.',
        'issuers.edit': 'Urejanje izdajatelja',
        'issuers.issued_by_ecta': 'Izdajatelj ECTA',
        'issuers.issued_by': 'Izdajatelj ',
        'issuers.disabled_voting': 'Izdajatelj je onemogočil javno glasovanje za to kompetenco.',
        'issuers.issuer_missing': 'Manjka izdajatelj',
        'issuers.issuer_missing_text': 'Prosimo, da najprej ustvarite izdajatelja, s katerim boste potem lahko kreirali kompetence in jih dodajali na vaše izobraževalne programe.',
    }
}