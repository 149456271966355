import React from 'react';
import './taskProposalList.scss';
import TaskProposal from './taskProposal';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';


class TaskProposalList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { myTaskProposals } = this.props;
        return (
            <div className="proposal-list">
                {
                    myTaskProposals.map((tp, i) =>
                        <TaskProposal selectedTask={this.props.selectedTask} tp={tp} key={i} />
                    )
                }
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        myTaskProposals: state.myWork.myTaskProposals.list.results,
    }
}

export default injectIntl(connect(mapStateToProps, { })(TaskProposalList));