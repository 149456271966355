import { setToken, removeToken } from '../../API';
import { LOGOUT_SUCCESS, LOGIN_SUCCESS, GET_USER_FROM_LOCALE, REGISTER_SUCCESS } from './../actions/auth/types';
// import { GOOGLE_REGISTER_SUCCESS } from '../actions/googleAuth/types';
import { connect, disconnect } from './../../API/webSocket';
import { CREATE_ROLE_SUCCESS, CHANGE_ACTIVE_ROLE_SUCCESS } from './../actions/role/types';

const authTokenHandler = store => next => action => {
  if (action.type === LOGIN_SUCCESS || action.type === GET_USER_FROM_LOCALE || action.type === REGISTER_SUCCESS) {
    if (action.payload.token) {
      const token = action.payload.token;
      setToken(token.access_token, token.refresh_token);
      connect();
    }
  }
  else if (action.type === CREATE_ROLE_SUCCESS) {
    if (action.payload.role) {
      connect();
    }
  }
  else if (action.type === CHANGE_ACTIVE_ROLE_SUCCESS) {
    if (action.payload.settings?.active_role_obj) {
      connect();
    }
  }
  else if (action.type === LOGOUT_SUCCESS) {
    removeToken();
    disconnect();
  }
  return next(action);
}

export default authTokenHandler;