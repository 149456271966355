import React from 'react';
import ReactDOM from 'react-dom';
import '../src/assets/styles/main.scss';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import App from './App';
import store from './core/store';
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
// import { GetLocationStart } from './core/actions/location'
import { FetchUserFromLocalStorage } from './core/actions/auth'

// store.dispatch(GetLocationStart());
store.dispatch(FetchUserFromLocalStorage());

ReactDOM.render(
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <App />
        </DndProvider>
    </Provider>
    ,
    document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
