import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';


class NotificationHistoryPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Container className="mt-4">
                <Row>

                    <Col xs={12} md={3} className="p-0 pb-3">
                        
                    </Col>

                    <Col xs={12} md={9}>
                        <p>Notification history page</p>
                    </Col>

                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

export default injectIntl(connect(mapStateToProps, {
})(NotificationHistoryPage));