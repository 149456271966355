import React, { useState, useEffect } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col, Form as BSForm } from 'react-bootstrap';
import { getTasksFromMilestones } from '../../utils/common/misc';
import { useSelector, useDispatch } from 'react-redux';
import './modalInviteFreelancers.scss';
import { formatDateRange, getUrlParameterObject } from '../../utils/common';
// import { Select } from '../../utils/forms/formElements';
// import { GetFreelancersStart } from '../../core/actions/role';
import { apiCon } from '../../API';
import { WithOutContext as ReactTags } from 'react-tag-input';
// import Freelancers from '../Browse/Freelancers';
import { langService } from '../../utils/languageService';
import history from '../../utils/routes/history';
import useReactSelect from '../../utils/hooks/useReactSelect';
import ReactSelect from 'react-select';
import { GetMyTaskProposalsStart } from '../../core/actions/taskProposals';

const customStyles = {
    width: 'inherit',
    height: 'inherit',
    maxWidth: '75%',
    maxHeight: '75%',
    bottom: 'auto',
    top: '8%'
};

const ALLOWED_FREELANCER_FILTERS = ['limit', 'search'];
const DISABLED_TASK_STATUSES = ['COMPLETED', 'DRAFT'];

const useDebounce = (value, timeout) => {
    const [state, setState] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setState(value), timeout);
        return () => clearTimeout(handler);
    }, [value, timeout]);

    return state;
}

// Props
// visible: boolean;
// closeFn: () => void;
// submitFn: () => void;
const ModalInviteFreelancersTask = ({ task, checked, changeChecked }) => {
    const isDisabled = DISABLED_TASK_STATUSES.includes(task.status);

    const [taskProposals, setTaskProposals] = useState([]);

    const selectedProject = useSelector(state => state.myWork.myProjects.selected);

    useEffect(() => {
        if (!selectedProject.id)
            return;

        if (!task.id)
            return;

        fetchTaskProposals();
    }, [selectedProject, task])

    const fetchTaskProposals = async () => {
        var url = '/me/project/task/proposals/';

        const params = {
            'limit': 10,
            'search': '',
            'task': task.id,
            'project': selectedProject.id    
        }

        await apiCon.get(url, { params })
        .then(response => {
            setTaskProposals(response.data.results);
        })
        .catch(err => {
        })
    }

    return (
        <Container className="modal-invite-freelancers-task-container" onClick={() => !isDisabled && changeChecked(task.id)}>
            <Row>
                <Col className="modal-invite-freelancers-task-checkbox" md={2}>
                    <BSForm.Check
                        type="checkbox"
                        id={`task-checkbox-${task.id}`}
                        checked={checked}
                        onChange={() => changeChecked(task.id)}
                        disabled={isDisabled}
                    />
                </Col>
                <Col className="modal-invite-freelancers-task-content" md={6}>
                    <div className="modal-invite-freelancers-task-name">{task.name}</div>
                    <div className="card-tags">
                        {task.competences.map(c => <span onClick={() => history.push('/competency/'+c.id)} key={c.id}>{c.name} L{c.level}</span>)}
                    </div>
                    <div>
                        <span>{langService.messages['forms.labels.invited_freelancers']}</span>
                        <ul>
                            {taskProposals.map(p => <li>{p.freelancer.name}</li>)}
                        </ul>
                    </div>
                </Col>
                <Col className="modal-invite-freelancers-task-info" md={3}>
                    <div className="modal-invite-freelancers-budget">${Math.round(task.price)}</div>
                    <div className="modal-invite-freelancers-task">{formatDateRange(new Date(task.start_date), new Date(task.end_date))}</div>
                </Col>
                <Col md={1} className="modal-invite-freelancers-task-more">
                    <span className="icon-email"></span>
                </Col>
            </Row>
            <hr className="modal-invite-freelancers-hr" />
        </Container>
    );
}

const ModalInviteFreelancers = ({ visible, closeFn, submitFn }) => {
    // State
    const [tasks, setTasks] = useState([]);
    const [checkedTasks, setCheckedTasks] = useState([]);
    const [selectedFreelancers, setSelectedFreelancers] = useState([]);
    const freelancersReactSelect = useReactSelect({ apiRoute: '/freelancers/', valueKey: 'id', labelKey: 'name' });

    // Get state from redux store
    const selectedProject = useSelector(state => state.myWork.myProjects.selected);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!selectedProject)
            return;

        setTasks(getTasksFromMilestones(selectedProject?.milestones?.list))
    }, [selectedProject]);

    useEffect(() => {
        const tempCheckedTasks = [];
        tasks.forEach(t => tempCheckedTasks.push({ id: t.id, checked: false }));
        setCheckedTasks(tempCheckedTasks);
    }, [tasks]);

    const taskChangeCheck = taskId => {
        const tempCheckedTasks = [];

        checkedTasks.forEach(t => {
            if (t.id !== taskId)
                tempCheckedTasks.push(t);
            else
                tempCheckedTasks.push({ id: t.id, checked: !t.checked });
        });

        setCheckedTasks(tempCheckedTasks);
    }

    const getSelectedTasks = () => {
        const tempSelectedTasks = [];

        checkedTasks.forEach(ct => {
            if (!ct.checked)
                return;

            tasks.forEach(t => {
                if (t.id === ct.id)
                    tempSelectedTasks.push(t);
            });
        });

        return tempSelectedTasks;
    }

    return (
        <Rodal
            visible={visible}
            onClose={() => closeFn()}
            customStyles={customStyles}
            closeMaskOnClick={false}>
            <div className="modal-guts" style={{ padding: '10px' }}>

                <div className="modal-title">
                    <FormattedMessage id="forms.labels.invite_freelancers_to_task" defaultMessage="Invite freelancers to tasks" />
                </div>

                <div className="modal-body p-2 pt-4">
                    
                        <Row>
                            <Col className="modal-invite-freelancers-tasks-col" md={8}>
                                {tasks.length === checkedTasks.length && tasks.map(t => <ModalInviteFreelancersTask key={t.id} task={t} checked={checkedTasks.find(ct => ct.id === t.id).checked} changeChecked={taskChangeCheck} />)}
                            </Col>
                            <Col md={4}>
                                <ReactSelect
                                    name="grouped-proposals-selected-freelancer"
                                    classNamePrefix="select"
                                    placeholder={langService.messages['forms.placeholders.select_freelancer']}
                                    options={freelancersReactSelect.options}
                                    onChange={e => setSelectedFreelancers(e)}
                                    inputValue={freelancersReactSelect.search}
                                    onInputChange={e => freelancersReactSelect.setSearchFn(e)}
                                    isLoading={freelancersReactSelect.loading}
                                    isClearable={true}
                                    isMulti={true}
                                />
                            </Col>
                        </Row>
                    
                </div>

                <div className="mp-img-footer">
                    <button className="btn btn-default btn-projects btn-smallpadding mr-2" onClick={() => closeFn()}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </button>
                    <button className="btn btn-default btn-projects btn-create-project btn-smallpadding mr-2" onClick={() => submitFn(getSelectedTasks(), selectedFreelancers)}>
                        <FormattedMessage id="common.submit" defaultMessage="Submit" />
                    </button>
                </div>
            </div>
        </Rodal>
    );
}

export default ModalInviteFreelancers;