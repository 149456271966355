import React from 'react';
import './index.scss';
import moment from 'moment';
import history from '../../utils/routes/history';
import ModalFileViewer from '../../components/Common/ModalFileViewer/modalFileViewer';
import CountdownTimer from '../../components/Common/CountDownTimer';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { formatDateRange } from '../../utils/common';
import { Tabs, Tab } from 'react-bootstrap';
import { getFileExtension, getFileName, contains, getAuditCostString } from '../../utils/common';
import { langService } from './../../utils/languageService/index';
import { Link } from 'react-router-dom';
import { UpdateTaskDisputeCaseStart } from '../../core/actions/taskDisputeCases';
import { GetMyTaskHistoryStart } from '../../core/actions/tasks';
import ModalSubmitFinalDecision from '../../components/Audit/modalSubmitFinalDecision';
import { createErrorMessageSelector, createLoadingSelector } from '../../API/selector';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { SelectTaskDisputeSettlement } from '../../core/actions/taskDisputeSettlement';
import 'react-vertical-timeline-component/style.min.css';
import TabDisputeSettlement from '../../components/Audit/tabDisputeSettlement';

const cardStyles = [
    { background: 'rgba(251, 116, 0, 0.8)', color: '#fff' }, // NEGOTIATIONS
    { background: 'rgba(255, 197, 0, 0.8)', color: '#fff' }, // COMMUNICATION
    { background: 'rgba(0, 141, 206, 0.8)', color: '#fff' }, // STATUS CHANGES / OTHER
    { background: 'rgba(31, 58, 134, 0.8)', color: '#fff' }, // FILE SUBMISSIONS
    { background: 'rgba(200, 0, 0,   0.7)', color: '#fff' }, // WARNING
    { background: 'rgba(0, 200, 0,   0.7)', color: '#fff' }, // SUCCESS
];
const arrowStyles = [
    { borderRight: "7px solid  rgba(251, 116, 0, 0.8)" }, // NEGOTIATIONS
    { borderRight: "7px solid  rgba(255, 197, 0, 0.8)" }, // COMMUNICATION
    { borderRight: "7px solid  rgba(0, 141, 206, 0.8)" }, // STATUS CHANGES / OTHER
    { borderRight: "7px solid  rgba(31, 58, 134, 0.8)" }, // FILE SUBMISSIONS
    { borderRight: "7px solid  rgba(200, 0, 0,   0.7)" }, // WARNING
    { borderRight: "7px solid  rgba(0, 200, 0,   0.7)" }, // SUCCESS
];



class MyAuditsSelectedCase extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            btnCounter: langService.messages['common.counter'],
            focusedInput: null,
            activeKey: props.activeKey ?? 1,
            startDate: props.selectedTaskProposal ? moment(props.selectedTaskProposal.start_date) : null,
            endDate: props.selectedTaskProposal ? moment(props.selectedTaskProposal.end_date) : null,
            tpPrice: props.selectedTaskProposal ? props.selectedTaskProposal.price : 0,
            modalSubmitFinalDecisionVisible: false,
            modalFileViewerVisible: false,
            selectedDocument: null,
            historyFilter: 4
        };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidMount() {
        this.props.SelectTaskDisputeSettlement(this.props.selectedCase.task.id, false);

    //     if (this.props.selectedCase.task.id) {
    //         console.log('selectedCase.task.id', this.props.selectedCase.task.id);
    //         this.props.GetMyReviewRequestsForTaskStart(null, null, this.props.selectedCase.id);
    //     }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "") {
            if (this.state.activeKey === '3') {
                this.filterHistory(this.state.historyFilter)
            }
        }
    }

    handleTabChange(selectedKey) {
        if (selectedKey === '3') {
            this.filterHistory(this.state.historyFilter)
        }
        this.setState({ activeKey: selectedKey });
    };

    // RENDERs
    hideModalSubmitFinalDecision() {
        this.setState({ modalSubmitFinalDecisionVisible: false });
    }

    // Modal File Viewer
    openFileViewer(file) {
        this.setState({ selectedDocument: file, modalFileViewerVisible: true });
    }
    renderModalFileViewer() {
        if (this.state.modalFileViewerVisible) {
            return (
                <ModalFileViewer visible={this.state.modalFileViewerVisible} file={this.state.selectedDocument} onClose={() => this.hideModalFileViewer()} />
            )
        }
    }
    hideModalFileViewer() {
        this.setState({ modalFileViewerVisible: false });
    }

    switchTabs = (key) => {
        this.setState({ activeKey: key });
    }

    filterHistory = (f) => {
        this.setState({ historyFilter: f }, () => {
            switch (f) {
                case 1:
                    this.props.GetMyTaskHistoryStart(this.props.selectedCase.task.id, ['NEGOTIATION']);
                    break;
                case 2:
                    this.props.GetMyTaskHistoryStart(this.props.selectedCase.task.id, ['MESSAGING']);
                    break;
                case 3:
                    this.props.GetMyTaskHistoryStart(this.props.selectedCase.task.id, ['FILE_SUBMISSION']);
                    break;
                case 4:
                    this.props.GetMyTaskHistoryStart(this.props.selectedCase.task.id);
                    break;
                default:
                    this.props.GetMyTaskHistoryStart(this.props.selectedCase.task.id);
                    break;
            }
        });

    }

    sendAction(a) {
        const { selectedCase } = this.props;
        switch (a) {
            case 'ACCEPT':
                this.props.UpdateTaskDisputeCaseStart({
                    id: selectedCase.id,
                    action_type: a,
                    last_task_proposal_change: selectedCase.last_task_proposal_change,
                });
                this.handleTabChange(1)
                break;
            default: {
                this.props.UpdateTaskDisputeCaseStart({
                    id: selectedCase.id,
                    action_type: a,
                    last_task_proposal_change: selectedCase.last_task_proposal_change,
                });
                break;
            }
        }
    }

    renderTaskOverview() {
        const { selectedCase } = this.props;
        return (

            <div className="task-overview-body p-3">

                <div className="form-labels">
                    <FormattedMessage id="browse.task.about" defaultMessage="About" />
                </div>

                <div className="mt-2 mb-2">
                    <FormattedMessage id="common.project" defaultMessage="Project" />: &nbsp;
                    <Link className="name-link" to={"/projects/" + selectedCase.task.project.id} onClick={() => history.push("/projects/" + selectedCase.task.project.id)}>{selectedCase.task.project.name}</Link>
                </div>

                <div className="mt-2 mb-2">
                    <FormattedMessage id="roles.name.INV" defaultMessage="Client" />: &nbsp;
                    <Link className="name-link" to={"/user/" + selectedCase.client.id} onClick={() => history.push("/user/" + selectedCase.client.id)}>{selectedCase.client.name}</Link>
                </div>

                <div className="mt-2 mb-3">
                    <FormattedMessage id="roles.name.DEV" defaultMessage="Freelancer" />: &nbsp;
                    <Link className="name-link" to={"/user/" + selectedCase.freelancer.id} onClick={() => history.push("/user/" + selectedCase.freelancer.id)}>{selectedCase.freelancer.name}</Link>
                </div>

                <div className="form-labels">
                    <FormattedMessage id="forms.labels.short_description" defaultMessage="Short description" />
                </div>
                <p>{selectedCase.task.short_description}</p>

                <div className="form-labels">
                    <FormattedMessage id="forms.labels.description" defaultMessage="Description" />
                </div>
                <p>{selectedCase.task.description}</p>

                <div className="form-labels">
                    <FormattedMessage id="plural.files.attached" values={{ count: selectedCase.task?.documents?.length }}
                        defaultMessage={`${selectedCase.task?.documents?.length} Files Attached`} />
                </div>
                {selectedCase.task?.documents?.map((doc, i) =>
                    <div className="attachment-box" key={i}>
                        <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? getFileExtension(doc.document) : 'generic'}`}></div>
                        <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                    </div>
                )}
                {selectedCase.task?.documents?.length === 0 && <div className="mt-3 mb-3"></div>}


                <div className="form-labels mt-4">
                    <FormattedMessage id="common.conditions" defaultMessage="Terms" />
                </div>
                <hr />

                <div className="form-labels">
                    <FormattedMessage id="projects.competences" defaultMessage="Competences" />
                </div>

                <div className="card-tags mb-2">
                    {selectedCase.task.competences.map((c, j) =>
                        <span onClick={() => history.push('/competency/' + c.id)} key={j}>{c.name} L{c.level}</span>
                    )}
                </div>

                <div className="proposal-content mb-4">
                    <div style={{ "marginRight": "20px" }}>
                        <div className="form-labels">
                            <FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" />
                        </div>
                        <div className="proposal-current">{`$` + parseInt(selectedCase.task?.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                    </div>
                    <div>
                        <div className="form-labels">
                            <FormattedMessage id="browse.task.timeframe" defaultMessage="Timeframe" />
                        </div>
                        <div className="proposal-current">{formatDateRange(new Date(selectedCase.task?.start_date), new Date(selectedCase.task?.end_date))}</div>
                    </div>
                </div>

                {
                    selectedCase.task.recurring &&
                    <div className="mb-4">
                        <div className="form-labels">
                            <FormattedMessage id="common.reccurrance" defaultMessage="Task recurrance" />
                        </div>
                        <div className="proposal-current">
                            {selectedCase.task.recurring_type === 0 && langService.messages['common.interval']}
                            {selectedCase.task.recurring_type === 1 && langService.messages['common.weekly'] + " X " + selectedCase.task.recurring_amount}
                            {selectedCase.task.recurring_type === 2 && langService.messages['common.monthly'] + " X " + selectedCase.task.recurring_amount}
                        </div>
                    </div>
                }

                <div className="card-actions">

                </div>

            </div >
        )
    }

    renderTabDisputeDetails() {
        const { selectedCase } = this.props;
        return (
            <>
                <div className="proposal-content mb-4">
                    <div style={{ "marginRight": "36px" }}>
                        <div className="form-labels">
                            <FormattedMessage id="auditors.audit_compensation" defaultMessage="Audit compensation" />
                        </div>
                        <div className="proposal-current">{getAuditCostString(selectedCase.task.price)}</div>
                    </div>
                    <div>
                        <div className="form-labels">
                            <FormattedMessage id="auditors.audit_deadline" defaultMessage="Audit deadline" />
                        </div>
                        <div className="proposal-current"><FormattedMessage id="auditors.audit_deadline_days" defaultMessage="7 working days" /></div>
                    </div>
                </div>
                <Link className="" to="/help"><FormattedMessage id="auditors.audit_rules" defaultMessage="Rules of audit process..." /></Link>

                <hr />

                <div className="form-labels">
                    Parties could not come to a settlement agreement and task was escalated to audit.
                </div>
                {/* <div className="complaint-text mt-3">"{selectedCase.task.dispute_reason}"</div> */}

                { (selectedCase.status === "TASK_CONFIRMED" || selectedCase.status === "TASK_FAILED") &&
                    <>
                        <hr />
                        <div className="form-labels"><FormattedMessage id="auditors.dispute_resolution" defaultMessage="Dispute resolution:" /></div>
                        <div className="proposal-current"><FormattedMessage id="common.task_success" defaultMessage="Task success:" />: {parseInt(selectedCase.confirmed_percentage)}%</div>
                        <div className="form-labels mt-3"><FormattedMessage id="auditors.auditor_comment" defaultMessage="Auditor comment:" /></div>
                        <div className="complaint-text mt-3">"{selectedCase.decision_comment}"</div>
                    </>
                }

            </>

        )
    }

    renderNegotiations(data, index, title, text, position) {
        return (
            <VerticalTimelineElement key={index}
                className="vertical-timeline-element--work"
                contentStyle={cardStyles[0]}
                contentArrowStyle={arrowStyles[0]}
                date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                iconStyle={cardStyles[0]}
                position={position}
            >
                <div className="vertical-timeline-element-title">{text}</div>
                <p>
                    Price: {`$` + parseInt(data.object_data.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                    <br />
                    Timeframe: {moment(data.object_data.start_date).format('DD.MM.YYYY')} - {moment(data.object_data.end_date).format('DD.MM.YYYY')}
                </p>
            </VerticalTimelineElement>
        )
    }

    renderMessages(data, index, title, text, position) {
        return (
            <VerticalTimelineElement key={index}
                className="vertical-timeline-element--work"
                contentStyle={cardStyles[1]}
                contentArrowStyle={arrowStyles[1]}
                date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                iconStyle={cardStyles[1]}
                position={position}
            >
                <div className="vertical-timeline-element-title">{title}</div>
                {/* <div className="vertical-timeline-element-subtitle">Miami, FL</div> */}
                <p style={{ "fontStyle": "Italic" }}>{text}</p>
            </VerticalTimelineElement>
        )
    }

    renderTaskResults(data, index, title, text, position) {
        return (
            <VerticalTimelineElement key={index}
                className="vertical-timeline-element--work"
                contentStyle={cardStyles[3]}
                contentArrowStyle={arrowStyles[3]}
                date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                iconStyle={cardStyles[3]}
                position={position}
            >
                <div className="vertical-timeline-element-title">{title}</div>
                <p>Comment:</p>
                <p style={{ "fontStyle": "Italic" }}>{text}</p>

                {data.object_data.documents.map((doc, c) =>
                    <div className="audit-attachment-box" key={c}>
                        <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? getFileExtension(doc.document) : 'generic'}`}></div>
                        <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                    </div>
                )
                }

            </VerticalTimelineElement>
        )
    }

    renderOtherHistory(data, index, title, text) {
        const { selectedCase } = this.props;
        var color = 2;

        switch (data.object_data.status) {

            case "PENDING_AUDIT":
                color = 4;
                // text = text + ' ' + data.object_data.dispute_reason;
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">{title}</div>
                        {/* <div className="vertical-timeline-element-subtitle">Miami, FL</div> */}
                        {/* <p>{text}</p> */}
                        <p>Parties could not come to a settlement agreement and task was escalated to audit.</p>
                        {/* <p style={{ "fontWeigth": "bold", "fontStyle": "italic" }}>"{data.object_data.dispute_reason}"</p> */}
                    </VerticalTimelineElement>
                )

            case "IN_AUDIT":
                color = 4;
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">{title}</div>
                        {/* <div className="vertical-timeline-element-subtitle">Miami, FL</div> */}
                        <p>{text}</p>
                    </VerticalTimelineElement>
                )

            case "AUDITOR_CONFIRMED":
                color = 5;
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">{title}</div>
                        {/* <div className="vertical-timeline-element-subtitle">Miami, FL</div> */}
                        <p>{text}</p>
                        <p><FormattedMessage id="common.task_success" defaultMessage="Task success" />: {parseInt(selectedCase.confirmed_percentage)}%</p>
                        <p><FormattedMessage id="common.comment" defaultMessage="Comment" />:</p>
                        <p style={{ "fontWeigth": "bold", "fontStyle": "italic" }}>"{selectedCase.decision_comment}"</p>
                        {/* <p style={{"fontWeigth": "bold", "fontStyle": "italic"}}>"{data.object_data.dispute_reason}"</p> */}
                    </VerticalTimelineElement>
                )

            case "AUDITOR_FAILED":
                color = 4;
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">{title}</div>
                        {/* <div className="vertical-timeline-element-subtitle">Miami, FL</div> */}
                        <p>{text}</p>
                        <p><FormattedMessage id="common.comment" defaultMessage="Comment" />:</p>
                        <p style={{ "fontWeigth": "bold", "fontStyle": "italic" }}>"{selectedCase.decision_comment}"</p>
                    </VerticalTimelineElement>
                )

            default:
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">{title}</div>
                        {/* <div className="vertical-timeline-element-subtitle">Miami, FL</div> */}
                        <p>{text}</p>
                    </VerticalTimelineElement>
                )
        }
    }

    renderDataHistory(history, index) {
        const { selectedCase } = this.props;
        var title = `history.${history.object_type}.title`;
        var text = `history.${history.object_type}.text`;
        var position = ''; // left=client, right=freelancer

        switch (history.object_type.toLowerCase()) {

            case 'messages': // COMMUNICATION
                if (history.object_data.sender === selectedCase.freelancer.id) {
                    title = <FormattedMessage id="history.freelancer_send_message.title" values={{ name: selectedCase.freelancer.name, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Freelancer ${<strong>{selectedCase.freelancer.name}</strong>} has send you an message:`} />
                    text = <FormattedMessage id="history.freelancer_send_message.text" values={{ name: selectedCase.freelancer.name, message: history.object_data.message, b: chunks => (<strong>{chunks}</strong>) }} defaultMessage={`${selectedCase.freelancer.name}: "${<strong>{history.object_data.message}</strong>}"`} />
                    position = 'right';
                } else {
                    title = <FormattedMessage id="history.client_send_message.title" values={{ name: selectedCase.client.name, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Client "${selectedCase.client.name}" has send you an message:`} />
                    text = <FormattedMessage id="history.client_send_message.text" values={{ name: selectedCase.client.name, message: history.object_data.message, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`${selectedCase.client.name}: "${<strong>{history.object_data.message}</strong>}"`} />
                    position = 'left';
                }
                return (this.renderMessages(history, index, title, text, position));

            case 'historicaltaskresult': // FILE SUBMISSIONS
                position = 'right';
                if (history.change_type === '+') {
                    title = <FormattedMessage id="history.task_result_added.title" values={{ b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Task result has been added:`} />
                    text = <FormattedMessage id="history.task_result_added.text" values={{ freelancer_name: selectedCase.freelancer.name, result_name: history.object_data.name, b: chunks => (<strong>{chunks}</strong>) }} defaultMessage={`Freelancer ${<strong>{selectedCase.freelancer.name}</strong>} added a new result set with comment: ${<strong>{history.object_data.name}</strong>}`} />
                }
                else if (history.change_type === '~') {
                    title = <FormattedMessage id="history.task_result_edited.title" values={{ b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Task result has been modified:`} />
                    text = <FormattedMessage id="history.task_result_edited.text" values={{ freelancer_name: selectedCase.freelancer.name, result_name: history.object_data.name, b: chunks => (<strong>{chunks}</strong>) }} defaultMessage={`Freelancer ${<strong>{selectedCase.freelancer.name}</strong>} changed result set with comment: ${<strong>{history.object_data.name}</strong>}`} />
                }
                return (this.renderTaskResults(history, index, title, text, position));

            // case 'historicaltaskresultdocument': // FILE SUBMISSIONS
            //     if (history.change_type === '+') {
            //         title = <FormattedMessage id="history.task_result_document_added.title" values={{ freelancer_name: selectedCase.freelancer.name, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Freelancer ${<strong>{selectedCase.freelancer.name}</strong>} submitted work`} />
            //         text = <FormattedMessage id="history.task_result_document_added.text" values={{ freelancer_name: selectedCase.freelancer.name, document_name: history.object_data.document, b: chunks => (<strong>{chunks}</strong>) }} defaultMessage={`${<strong>{selectedCase.freelancer.name}</strong>} added a document named "${<strong>{history.object_data.document}</strong>}"`} />
            //     }
            //     else if (history.change_type === '-') {
            //         title = <FormattedMessage id="history.task_result_document_deleted.title" values={{ freelancer_name: selectedCase.freelancer.name, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Freelancer ${<strong>{selectedCase.freelancer.name}</strong>} removed a document`} />
            //         text = <FormattedMessage id="history.task_result_document_deleted.text" values={{ freelancer_name: selectedCase.freelancer.name, document_name: history.object_data.document, b: chunks => (<strong>{chunks}</strong>) }} defaultMessage={`${<strong>{selectedCase.freelancer.name}</strong>} removed a document named "${<strong>{history.object_data.document}</strong>}"`} />
            //     }
            //     return (this.renderFileSubmissions(history, index, title, text));

            case 'historicaltaskproposal': // NEGOTIATIONS
                title = <FormattedMessage id={`history.task_proposal_changed_${history.object_data.last_action_type.toLowerCase()}.title`} values={{ freelancer_name: selectedCase.freelancer.name, task_name: selectedCase.task.name, client_name: selectedCase.client.name, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Task Proposal changed translation "${history.object_data.last_action_type}" title not defined.`} />
                text = <FormattedMessage id={`history.task_proposal_changed_${history.object_data.last_action_type.toLowerCase()}.text`} values={{
                    task_name: selectedCase.task.name, auditor_name: selectedCase.auditor.name,
                    client_name: selectedCase.client.name, freelancer_name: selectedCase.freelancer.name, b: chunks => <strong>{chunks}</strong>
                }} defaultMessage={`Task Proposal changed translation "${history.object_data.last_action_type}" title not defined.`} />
                if (history.object_data.last_action_type === 'FREELANCER_PROPOSED' || history.object_data.last_action_type === 'ACCEPTED') {
                    position = 'right';
                } else {
                    position = 'left';
                }
                return (this.renderNegotiations(history, index, title, text, position));

            case 'historicaltask': // STATUS CHANGES (OTHER)
                if (history.object_data.changed_fields.indexOf('status') !== -1) {
                    title = <FormattedMessage id="history.task_status_changed.title" defaultMessage={`Task ${<strong>{selectedCase.task.name}</strong>} status changed.`} />
                    text = <FormattedMessage id={`history.task_is_${history.object_data.status.toLowerCase()}.text`} defaultMessage={`Task ${history.object_data.name} translation for ${history.object_data.status} is not defined`} />
                }
                else {
                    title = <FormattedMessage id="history.task_auditor_changed.title" values={{ task_name: selectedCase.freelancer.name, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Auditor for task ${<strong>{selectedCase.task.name}</strong>} changed`} />
                    text = <FormattedMessage id="history.task_auditor_changed.text" defaultMessage={`Because of time emitted, active auditor changed.`} />
                }
                return (this.renderOtherHistory(history, index, title, text));

            default:
                return null;
        }
        // return (this.renderHistoryEntry(history, history.history_date, index, title, text));
    }

    renderTabAuditData() {
        const { task_history } = this.props;
        return (
            <Tab eventKey={3} title={langService.messages['auditors.audit_data']}>

                <div className="audit-data-filters">
                    <div className={"audit-data-filter f1" + (this.state.historyFilter === 1 ? " active" : "")} onClick={() => this.filterHistory(1)}>
                        <FormattedMessage id="my_audits.negotiation" defaultMessage="Negotiation" />
                    </div>
                    <div className={"audit-data-filter f2" + (this.state.historyFilter === 2 ? " active" : "")} onClick={() => this.filterHistory(2)}>
                        <FormattedMessage id="my_audits.communication" defaultMessage="Communication" />
                    </div>
                    <div className={"audit-data-filter f3" + (this.state.historyFilter === 3 ? " active" : "")} onClick={() => this.filterHistory(3)}>
                        <FormattedMessage id="my_audits.file_submission" defaultMessage="File submissions" />
                    </div>
                    <div className={"audit-data-filter f4" + (this.state.historyFilter === 4 ? " active" : "")} onClick={() => this.filterHistory(4)}>
                        <FormattedMessage id="my_audits.complete" defaultMessage="Complete History" />
                    </div>
                </div>

                <VerticalTimeline>
                    {task_history.map((h, i) => this.renderDataHistory(h, i))}
                </VerticalTimeline>

            </Tab>
        )
    }

    // renderDeadline() {
    //     const { selectedCase } = this.props;
    //     var startDate = new Date(selectedCase.date_last_modified)
    //     var endDate
    //     if (selectedCase.status === 'PENDING_AUDIT') {
    //         endDate = addDaysToDate(startDate, 2);
    //     } else if (selectedCase.status === 'IN_AUDIT') {
    //         endDate = addDaysToDate(startDate, 7);
    //     }
    //     else return '';
    //     return <CountdownTimer startDate={moment(startDate)} endDate={endDate} />
    // }

    render() {
        const { selectedCase } = this.props;
        if (!selectedCase.id) { return null };
        return (

            <div className="project-overview">

                <div className="project-overview-cover">
                    <Image src={selectedCase.task.project.cover_image ? selectedCase.task.project.cover_image : "/assets/img/floriane-vita.png"} height="120" className="project-overview-coverimg"></Image>
                    <div className="btn btn-default btn-trans btn-changecover" style={{ "right": "18px" }} onClick={() => history.push("/projects/" + selectedCase.task.project.id)}>
                        <FormattedMessage id="my_projects.view" defaultMessage="View Project" />
                    </div>
                </div>

                <div className="project-overview-header">

                    <div className="t-overview-titles">

                        <div className="">

                            <div className="t-overview-title">
                                <span>{selectedCase.task.name}</span>
                                <span>{selectedCase.task.category?.name}</span>
                                <div>
                                    <span className={"status-circle status-color-" + selectedCase.task.status.toLowerCase()}>●</span>
                                    <FormattedMessage id={`common.${selectedCase.task.status.toLowerCase()}`} defaultMessage={selectedCase.task.status.toLowerCase()} />
                                </div>
                            </div>

                            {selectedCase.end_date &&
                                <div className="cd-timer">
                                    <CountdownTimer endDate={moment(selectedCase.end_date)} />
                                </div>
                            }

                        </div>

                        <div className="submitwork-btns">
                            {
                                (selectedCase.status === "PENDING_AUDIT" && selectedCase) &&
                                selectedCase.available_actions.map((a, i) => {
                                    if (a === "ACCEPT") {
                                        return (
                                            <button key={i} className="btn btn-default btn-projects btn-create-project" onClick={() => this.sendAction(a)} >
                                                <FormattedMessage id="auditors.accept_case" defaultMessage="ACCEPT CASE" />
                                            </button>
                                        )
                                    } else if (a === "REJECT") {
                                        return (
                                            <button key={i} className="btn btn-default btn-projects mt-2" onClick={() => this.sendAction(a)}>
                                                <FormattedMessage id="auditors.reject_case" defaultMessage="Reject case" />
                                            </button>
                                        )
                                    } else {
                                        return null;
                                    }
                                })
                            }

                            {selectedCase.status === "IN_AUDIT" &&
                                <button className="btn btn-default btn-projects btn-create-project" onClick={() => this.setState({ modalSubmitFinalDecisionVisible: true })}>
                                    <FormattedMessage id="auditors.submit_final_decision" defaultMessage="SUBMIT FINAL DECISION" />
                                </button>
                            }

                        </div>

                    </div>

                </div>

                <div className="task-overview-body">

                    <Tabs defaultActiveKey={1} activeKey={this.state.activeKey} className="p-overview-tabs" onSelect={this.handleTabChange}>

                        <Tab eventKey={1} title={langService.messages['my_task.task_overview']}>
                            <div className="tab-content">
                                {this.renderTaskOverview()}
                            </div>
                        </Tab>

                        {(selectedCase.task.status === "PENDING_AUDIT" || selectedCase.task.status === "AUDITOR_FAILED" || selectedCase.task.status === "AUDITOR_CONFIRMED") &&

                            <Tab eventKey={2} title={langService.messages['auditors.dispute_details']}>
                                <div className="p-3">
                                    {this.renderTabDisputeDetails()}
                                </div>
                            </Tab>
                        }

                        <Tab eventKey={4} title="Dispute">
                            <TabDisputeSettlement settlement={this.props.settlement}/>
                        </Tab>

                        {(selectedCase.task.status === "IN_AUDIT" || selectedCase.task.status === "AUDITOR_FAILED" || selectedCase.task.status === "AUDITOR_CONFIRMED") && this.renderTabAuditData()}

                    </Tabs>
                </div>

                {selectedCase.task.status === "IN_AUDIT" && this.renderModalFileViewer()}

                {this.state.modalSubmitFinalDecisionVisible && <ModalSubmitFinalDecision disputeCase={selectedCase} visible={this.state.modalSubmitFinalDecisionVisible} onClose={() => this.hideModalSubmitFinalDecision()} />}

            </div>
        )
    }
}

const loadingSelector = createLoadingSelector(['SELECT_MY_TASK_DISPUTE_CASE']);
const errorSelector = createErrorMessageSelector(['SELECT_MY_TASK_DISPUTE_CASE']);
const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        selectedCase: state.myWork.myDisputeCases.selected,
        task_history: state.myWork.myTasks.selected.history,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state),
        settlement: state.myWork.myDisputeSettlements.selected
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateTaskDisputeCaseStart,
    GetMyTaskHistoryStart,
    SelectTaskDisputeSettlement
})(MyAuditsSelectedCase));