import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalCreateNewCompetence.scss';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { createErrorMessageSelector, createLoadingSelector } from './../../API/selector';
import { CreateCompetenceDescriptionStart } from '../../core/actions/competenceDescription';
import Levels from '../../assets/json/blooms-taxonomy.js';
import AutosizeTextarea from '../Common/AutosizeTextarea';


class ModalAddCompetenceDescription extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            description: '',
            level: '',
            sub_level: '',
            levels: [],
            levelInvalid: false,
        };
        this.selectRef = React.createRef();
    }

    componentDidMount() {
        this.generateLevelOptions(Levels);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "") {
            this.hide();
        }
        if (prevProps.lang !== this.props.lang) {
            this.generateLevelOptions(Levels);
        }
    }

    generateLevelOptions(data) {
        if (!data.levels) { return; };
        const { lang } = this.props;
        var options = [];
        // SHOW ONLY SUBLEVEL FROM LEVEL FROM INPUT
        data.levels[this.props.level - 1].verbs.forEach(o => {
            options.push({ label: lang === 'sl' ? o.verb_si : o.verb, value: o.id });
        });
        this.setState({ levels: options });
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        if (input === 'level') {
            const level = event.value.toString().substr(0, 1);
            if (this.state.name) {
                this.props.VerifyCompetenceStart(this.state.name, level);
            }
            this.setState({ level: level, sub_level: event.value, levelInvalid: false });
        }
    }

    textAreaValueChanged(input, event) {
        if (input === 'description' && event.target.value.length > 1000)
            return;
        this.setState({ [input]: event.target.value })
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }
        this.setState({ validated: true });
    }

    save() {
        this.props.CreateCompetenceDescriptionStart(this.props.id, this.state.description, this.state.sub_level);
    }

    render() {
        const { levelInvalid } = this.state;
        const { visible } = this.props;
        return (
            <Rodal className="new-competence-rodal"
                visible={visible}
                width={500}
                // height={500}
                customStyles={{ height: 'auto', bottom: 'auto', top: 'auto' }}
                onClose={() => this.hide()}
                closeMaskOnClick={false}>
                <div>

                    <div className="modal-title">
                        <FormattedMessage id="competences.add_description_title" defaultMessage="Suggest competence description" />
                    </div>

                    <div className="modal-body pt-4">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Form.Group>
                                <div className="desc-wrap">
                                    <div className="this-person">
                                        <FormattedMessage id="competences.this_person_can" defaultMessage="This person can" />
                                    </div>
                                    <Select
                                        required
                                        blurinputonselect="true"
                                        ref={this.selectRef}
                                        className="select-level"
                                        classNamePrefix="select"
                                        name="selLevel"
                                        options={this.state.levels}
                                        onChange={this.handleChange('level')}
                                        styles={{ control: (provided, state) => levelInvalid ? { ...provided, border: "1px solid #dc3545" } : provided }}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group controlId="formCDesc" className="mb-4">
                                <AutosizeTextarea rows={3} input="description" handleChange={(input, event) => this.textAreaValueChanged(input, event)} />
                            </Form.Group>

                            <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2" disabled={!(this.state.level)} >
                                <FormattedMessage id="common.save" defaultMessage={`Save`} />
                            </button>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.cancel" defaultMessage={`Cancel`} />
                            </div>
                        </Form>

                    </div>
                </div>
            </Rodal>
        )
    }

}

const loadingSelector = createLoadingSelector(['CREATE_COMPETENCE_DESCRIPTION']);
const errorSelector = createErrorMessageSelector(['CREATE_COMPETENCE_DESCRIPTION']);
const mapStateToProps = state => {
    return {
        lang: state.lang,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state),
    }
}

export default injectIntl(connect(mapStateToProps, {
    CreateCompetenceDescriptionStart
})(ModalAddCompetenceDescription));