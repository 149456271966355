import React from 'react';
import './index.scss';
import './selectedTask.scss';
import moment from 'moment';
import ContactList from '../../components/Chat/contactList';
import TaskProposalList from '../../components/Tasks/taskProposalList';
import TaskOverviewWithEdit from '../../components/Tasks/taskOverviewWithEdit';
import ModalViewSubmitedWork from '../../components/Tasks/modalViewSubmitedWork';
import ModalSubmitTaskProposal from '../../components/Tasks/modalSubmitTaskProposal';
import ModalReviewUser from '../../components/Tasks/modalReviewUser';
import ModalReviewAuditor from '../../components/Tasks/modalReviewAuditor';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Tabs, Tab, Image } from 'react-bootstrap';
import { SelectMyTaskStart, DeselectMyTask } from '../../core/actions/tasks';
import { getFileExtension, getFileName, contains, downloadFile } from '../../utils/common';
import { UpdateTaskStart } from '../../core/actions/tasks';
import { GetMyReviewRequestsStart, GetMyReviewRequestsForTaskStart } from '../../core/actions/rating';
import { langService } from './../../utils/languageService/index';
import ModalFileViewer from '../../components/Common/ModalFileViewer/modalFileViewer';
import { formatDateRange } from './../../utils/common/index';
import TabAuditInfo from '../../components/Audit/tabAuditInfo';
import TabDisputeSettlement from '../../components/Audit/tabDisputeSettlement';
import { SelectTaskDisputeSettlement } from '../../core/actions/taskDisputeSettlement';
import history from '../../utils/routes/history';


class MyProjectsSelectedTask extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTabProject: "1",
            activeTabKey: "1",
            modalVisible: false,
            modalReviewUserVisible: false,
            modalReviewAuditorVisible: false
        };
    }

    componentDidMount() {
        this.props.GetMyReviewRequestsStart();

        this.props.SelectTaskDisputeSettlement(this.props.selectedTask.id, false);

        if (this.props.selectedTask.status === 'AUDITOR_CONFIRMED' || this.props.selectedTask.status === 'AUDITOR_REJECTED') {
            this.props.GetMyReviewRequestsForTaskStart(null, null, this.props.selectedTask.id);
        }

        if (this.props.tabKey) {
            this.setState({ activeTabKey: this.props.tabKey });
        }
    }

    downloadFile = (url) => {
        downloadFile(url);
    }

    hasHistory(status) {
        const exclStatuses = ['DRAFT', 'PUBLISHED'];
        return exclStatuses.indexOf(status) === -1;
    }

    handleTabChange(selectedkey) {
        this.setState({ activeTabKey: selectedkey });
    };

    // Modal File Viewer
    openFileViewer(file) {
        this.setState({ selectedDocument: file, modalFileViewerVisible: true });
    }
    renderModalFileViewer() {
        if (this.state.modalFileViewerVisible) {
            return (
                <ModalFileViewer visible={this.state.modalFileViewerVisible} file={this.state.selectedDocument} onClose={() => this.hideModalFileViewer()} />
            )
        }
    }
    hideModalFileViewer() {
        this.setState({ modalFileViewerVisible: false });
    }

    hide() {
        this.setState({ modalVisible: false });
    }

    renderModal(user, task) {
        if (this.state.modalVisible) {
            return (
                <ModalViewSubmitedWork visible={this.state.modalVisible} user={user} task={task} onClose={() => this.hide()} />
            )
        }
    }

    renderModalReviewUser(user, task) {
        if (this.state.modalReviewUserVisible) {
            return (
                <ModalReviewUser visible={this.state.modalReviewUserVisible} type={'freelancer'} user={user} task={task} onClose={() => this.setState({ modalReviewUserVisible: false })} />
            )
        }
    }

    renderModalReviewAuditor(task) {
        if (this.state.modalReviewAuditorVisible) {
            return (
                <ModalReviewAuditor visible={this.state.modalReviewAuditorVisible} task={task} onClose={() => this.setState({ modalReviewAuditorVisible: false })} />
            )
        }
    }

    publishTask() {
        this.props.UpdateTaskStart({
            id: this.props.selectedTask.id,
            status: 'PUBLISHED',
        });
    }

    hideModalProposal = () => {
        this.setState({ modalProposalVisible: false });
    }

    renderModalInviteFreelancers() {
        if (!this.state.modalProposalVisible) return;
        const { selectedTask } = this.props;
        if (selectedTask.id) {
            return (
                <ModalSubmitTaskProposal
                    visible={this.state.modalProposalVisible}
                    taskId={selectedTask.id}
                    clientId={selectedTask.project.client.id}
                    start={selectedTask.start_date}
                    end={selectedTask.end_date}
                    price={selectedTask.price}
                    invite={true}
                    freelancerId={null}
                    onClose={this.hideModalProposal}
                    competences={selectedTask.competences}
                />);
        } else {
            return null;
        }
    }

    renderInfobar() {
        const { selectedTask, projectStatus } = this.props;
        if (selectedTask.status === "DRAFT") {
            return (
                <div className="project-infobar">
                    <div className="project-infobar-text">
                        <FormattedMessage id="my_task.not_published" defaultMessage="Your Task is not published" />
                    </div>
                    <div className="btn btn-default btn-project-infobar btn-smallpadding" onClick={() => this.publishTask()}>
                        <FormattedMessage id="my_task.publish" defaultMessage="Publish Task" />
                    </div>
                </div>
            )
        } else if (projectStatus === "DRAFT" && selectedTask.visibility === "PRIVATE") {
            return (
                <>
                    <div className="project-infobar">
                        <div className="project-infobar-text">
                            <FormattedMessage id="my_task.publish_invite" defaultMessage="Publish your project and invite people to this private task." />
                        </div>
                    </div>
                </>
            )
        } else if ((selectedTask.status === "PUBLISHED" || selectedTask.status === "IN_NEGOTIATION") && selectedTask.visibility === "PRIVATE") {
            return (
                <>
                    <div className="project-infobar">
                        <div className="project-infobar-text">
                            <FormattedMessage id="my_task.private" defaultMessage="Your Task is private. Invite people to open negotiation." />
                        </div>
                        <div className="btn btn-default btn-project-infobar btn-smallpadding" onClick={() => this.setState({ modalProposalVisible: true })}>
                            <FormattedMessage id="my_task.invite" defaultMessage="Invite people." />
                        </div>

                    </div>
                    {this.renderModalInviteFreelancers()}
                </>
            )
        }
    }

    render() {
        const { selectedTask, reviewRequests, auditorReviewRequests, settlement } = this.props;
        if (!selectedTask.id) { return null; }
        return (
            <div className="project-overview">

                <div className="project-overview-cover">
                    {this.renderInfobar()}
                    <Image src={selectedTask.project.cover_image ? selectedTask.project.cover_image : "/assets/img/floriane-vita.png"} height="120" className="project-overview-coverimg"></Image>
                </div>

                <div className="header-back">
                    <div style={{'display': 'flex', 'alignItems': 'center'}}>
                        <span className="icon-back_arrow" onClick={() => this.props.onBackToTasks()}></span>
                        <span onClick={() => this.props.onBackToTasks()}>
                            <FormattedMessage id="my_task.back_to_all" defaultMessage="Back To All Tasks" />
                        </span>
                    </div>
                </div>

                <div className="project-overview-header pt-0">
                    <div className="p-overview-titles">
                        <div className="p-overview-title">
                            <span>{selectedTask.name}</span>
                            <span>{selectedTask.category?.name}</span>
                            <div>
                                <span className={"status-circle status-color-" + selectedTask.status.toLowerCase()}>●</span> {langService.messages[`common.${selectedTask.status.toLowerCase()}`]}
                            </div>
                        </div>
                        {selectedTask.status && (selectedTask.status === "SUBMITTED") &&
                            <>
                                <div className="p-overview-btns">
                                    <button className="btn btn-default btn-projects-inv" onClick={() => this.setState({ modalVisible: true })}>
                                        <FormattedMessage id="my_task.review_work" defaultMessage="Review final work" />
                                    </button>
                                </div>
                                {this.renderModal(selectedTask.active_freelancer, selectedTask)}
                            </>
                        }
                        {reviewRequests.some(x => x.reviewee.id === selectedTask.active_freelancer?.id)
                            && selectedTask.status && selectedTask.status === "COMPLETED" &&
                            <>
                                <div className="p-overview-btns">
                                    <button className="btn btn-default btn-projects-inv" onClick={() => this.setState({ modalReviewUserVisible: true })}>
                                        <FormattedMessage id="my_task.review_user" defaultMessage="Review User" />
                                    </button>
                                </div>
                                {this.renderModalReviewUser(selectedTask.active_freelancer, selectedTask)}
                            </>
                        }
                        {auditorReviewRequests.length > 0 && selectedTask.status
                            && (selectedTask.status === "AUDITOR_CONFIRMED" || selectedTask.status === "AUDITOR_REJECTED") &&
                            <>
                                <div className="p-overview-btns">
                                    <button className="btn btn-default btn-projects-inv" onClick={() => this.setState({ modalReviewAuditorVisible: true })} >
                                        <FormattedMessage id="rating.review_aud" defaultMessage="Review Auditor" />
                                    </button>
                                </div>
                                {this.renderModalReviewAuditor(selectedTask)}
                            </>
                        }
                    </div>
                </div>

                <div className="task-overview-body">

                    <Tabs defaultActiveKey={1} activeKey={this.state.activeTabKey} className="p-overview-tabs" onSelect={(x) => this.handleTabChange(x)}>

                        <Tab eventKey="1" title={langService.messages['my_task.task_overview']}>
                            <div className="tab-content">
                                <TaskOverviewWithEdit project={this.props.selectedProject} />
                            </div>
                        </Tab>

                        {(selectedTask.number_of_proposals === 0 && selectedTask.active_freelancer) &&
                            <Tab eventKey="2" title={langService.messages['common.active_freelancer']}>
                                <div className="proposal-item">

                                    <div className="proposal-item-inner p-4">

                                        <div className="proposal-item-wrapper">

                                            <div className="proposal-thumb">
                                                {selectedTask.active_freelancer.profile_image
                                                    ? <Image src={selectedTask.active_freelancer.profile_image} className="user-thumb" />
                                                    : <Image src="/assets/img/default-avatar.png" className="user-thumb" />
                                                }
                                            </div>

                                            <div className="proposal-item-body">

                                                <div className="proposal-item-header">

                                                    <div className="proposal-item-titles">
                                                        <div>
                                                            <span className="form-labels">{selectedTask.active_freelancer?.name}</span>
                                                            {selectedTask.active_freelancer?.rating.rating &&
                                                                <div className="p-info-name">
                                                                    <span className="p-star" role="img" aria-label="Star rating">⭐</span>
                                                                    <span>
                                                                        <span className="rating-big">{selectedTask.active_freelancer?.rating.rating}</span><span className="rating-small">/5</span>
                                                                    </span>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div>{selectedTask.active_freelancer?.company_name}</div>
                                                        <div>{selectedTask.active_freelancer?.description}</div>
                                                    </div>
                                                </div>

                                                <div className="tp-labels mt-3 mb-2">
                                                    <FormattedMessage id="forms.labels.required_competences" defaultMessage="Required Competences" />
                                                </div>

                                                <div className="user-competences mb-2">
                                                    <div className="card-tags mb-2">
                                                        {selectedTask.competences.map((c, j) =>
                                                            <span onClick={() => history.push('/competency/' + c.id)} key={j}>{c.name} L{c.level}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="proposal-content">
                                                    <div>
                                                        <div className="proposal-labels">
                                                            <FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" />
                                                        </div>
                                                        <div className="proposal-current">{`$` + parseInt(selectedTask.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                                                    </div>
                                                    <div style={{ 'minWidth': '238px' }}>
                                                        <div className="proposal-labels">
                                                            <FormattedMessage id="browse.task.timeframe" defaultMessage="Timeframe" />
                                                        </div>
                                                        <div className="proposal-current-d">{formatDateRange(new Date(selectedTask.start_date), new Date(selectedTask.end_date))}</div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </Tab>
                        }

                        {selectedTask.number_of_proposals > 0 &&
                            <Tab eventKey="2" title={langService.messages['my_task.task_proposals'] + (selectedTask.number_of_proposals > 0 ? ` (${selectedTask.number_of_proposals})` : "(0)")}>
                                <TaskProposalList selectedTask={selectedTask} />
                            </Tab>
                        }

                        {selectedTask.number_of_results > 0 &&
                            <Tab eventKey="3" title={langService.messages['my_task.submited_work'] + ` (${selectedTask.number_of_results})`}>
                                {selectedTask.results.map((result, i) =>
                                    <div className="result-item" key={i}>
                                        <div className="result-info">
                                            <div className="result-date">{moment(result.date_created).format('DD.MM.YYYY HH:mm')}</div>
                                            <div className="result-comment"> {result.name}</div>
                                        </div>
                                        <div className="result-docs">
                                            <div className="attachments m-0">
                                                {result?.documents?.map((doc, x) =>
                                                    <div className="attachment-box" key={x}>
                                                        <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png', 'JPG', 'PNG'], getFileExtension(doc.document)) ? 'generic' : getFileExtension(doc.document)}`}></div>
                                                        {/* <div className="download-link" onClick={() => this.downloadFile(doc.document)}>{getFileName(doc.document)}</div> */}
                                                        <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                                                    </div>
                                                )}
                                                {this.renderModalFileViewer()}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Tab>
                        }

                        {selectedTask.exists_dispute_settlement &&
                            <Tab eventKey="5" title={langService.messages['auditors.dispute']}>
                                <TabDisputeSettlement settlement={this.props.settlement}/>
                            </Tab>
                        }

                        {this.hasHistory(selectedTask.status) &&
                            <Tab eventKey="4" title={langService.messages['common.history']}>
                                <TabAuditInfo />
                            </Tab>
                        }

                    </Tabs>
                </div>

                <ContactList />

            </div >
        )
    }

}

const mapStateToProps = state => {
    return {
        selectedTask: state.myWork.myTasks.selected,
        reviewRequests: state.myWork.myRatingRequests.list.results,
        auditorReviewRequests: state.myWork.myRatingRequestsForTask.list.results,
        settlement: state.myWork.myDisputeSettlements.selected
    }
}

export default injectIntl(connect(mapStateToProps, {
    SelectMyTaskStart,
    DeselectMyTask,
    UpdateTaskStart,
    GetMyReviewRequestsStart,
    GetMyReviewRequestsForTaskStart,
    SelectTaskDisputeSettlement
})(MyProjectsSelectedTask));