import React from 'react';
import './modalSubmitFinalDecision.scss';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
import { UpdateTaskDisputeCaseStart } from '../../core/actions/taskDisputeCases';
import AutosizeTextarea from './../../components/Common/AutosizeTextarea/index';
import { Link } from 'react-router-dom';

const customStyles = {
    width: '550px',
    height: 'inherit',
    // maxWidth: '50%',
    maxHeight: '65%',
    bottom: 'auto',
    top: '15%'
};

class ModalSubmitFinalDecision extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            btnSaveDisabled: true,
            percentage: 50,
            comment: '',
            isValid: '',
        };
    }

    hide() {
        this.props.onClose();
    }

    // handleChangeStart = () => {
    //     console.log('Change event started')
    // };

    handleChange = value => {
        this.setState({
            percentage: value
        });
    };

    // handleChangeComplete = () => {
    //     console.log('Change event completed')
    // };

    textAreaValueChanged(input, event) {
        if ((this.state.comment.length + 1) > 100) {
            this.setState({ [input]: event.target.value, isValid: '' });
        } else {
            this.setState({ [input]: event.target.value, isValid: 'is-invalid' });
        }
    }

    getPaymentAmount() {
        const { disputeCase } = this.props;
        var res = (this.state.percentage / 100) * disputeCase.task.price;
        return "$" + res.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }

    save() {
        if (this.state.comment.length >= 100) {
            this.setState({ isValid: '' });

            const { disputeCase } = this.props;
            var params = { id: disputeCase.id, decision_comment: this.state.comment }

            if (this.state.percentage >= 5) {
                params.action_type = "TASK_CONFIRM";
                params.confirmed_percentage = this.state.percentage;
            } else {
                params.action_type = "TASK_FAIL";
            }

            this.props.UpdateTaskDisputeCaseStart(params);
            this.hide();

        } else {
            this.setState({ isValid: 'is-invalid' });
        }
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={550}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        <FormattedMessage id="auditors.submit_final_decision" defaultMessage="Submit Final Decision" />
                    </div>

                    <div className="modal-body p-0 pt-3">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>


                            <div className="pt-2 row">

                                <div className="text-right mb-2 circle col">
                                    <div className="dot-c text-center">
                                        <span className="licons icon-investor"></span><br />
                                        <span className="circle-texts"><FormattedMessage id="roles.name.INV" defaultMessage="Client" /></span>
                                    </div>
                                </div>

                                <div className='audit-values'>
                                    <div className='audit-txt'><FormattedMessage id="auditors.decided_1" defaultMessage="Task was executed" /></div>
                                    <div className='audit-value' style={{"paddingLeft": "5px"}}>{this.state.percentage}%</div>
                                    <div className='audit-txt'><FormattedMessage id="auditors.decided_2" defaultMessage="and" /></div>
                                    <div className='audit-value'>{this.getPaymentAmount()}</div>
                                    <div className='audit-txt'><FormattedMessage id="auditors.decided_3" defaultMessage="will be payed." /></div>
                                </div>

                                <div className="text-left circle col">
                                    <div className="dot-f text-center">
                                        <span className="licons icon-developers"></span><br />
                                        <span className="circle-texts"><FormattedMessage id="roles.name.DEV" defaultMessage="Freelancer" /></span>
                                    </div>
                                </div>

                            </div>

                            <div className='slider audit-slider'>
                                <Slider
                                    min={0}
                                    max={100}
                                    tooltip={false}
                                    step={5}
                                    value={this.state.percentage}
                                    onChangeStart={this.handleChangeStart}
                                    onChange={this.handleChange}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            </div>

                            <div className="final-decision-info mt-2 mb-2">
                                <FormattedMessage id="auditors.slider_info" defaultMessage="*By moving the slider you decide the proportion of the task that was executed correctly and the amount of compensation the freelancer receives from the original agreed amount." />&nbsp;
                                <Link to="/help"><FormattedMessage id="common.read_more" defaultMessage="Read more..." /></Link>
                            </div>

                            <hr />

                            <div className="mt-2">
                                <p><strong>
                                    <FormattedMessage id="forms.labels.comment" defaultMessage="Comment" />&nbsp;
                                    <FormattedMessage id="forms.labels.min_chars" values={{min: 100}} defaultMessage="(minimum 100 characters)" />
                                </strong></p>
                                <AutosizeTextarea isValid={this.state.isValid} value={this.state.comment} rows={4} input="comment" edit={false} handleChange={(input, event) => this.textAreaValueChanged(input, event)} />
                            </div>

                            <br />
                            <div className="btn btn-default btn-projects-inv float-right mt-2 mb-2" onClick={() => this.save()}>
                                <FormattedMessage id="common.submit" defaultMessage="Submit" />
                            </div>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>
                        </Form>

                    </div>
                
                </div>
            </Rodal>
        )
    }

}

export default injectIntl(connect(null, {
    UpdateTaskDisputeCaseStart
})(ModalSubmitFinalDecision));