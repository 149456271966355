import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { UploadProjectCoverPictureStart } from '../../core/actions/projects';
import ImageUploader from '../Common/ImageUploader';


class ModalEditProjectCover extends React.Component {
    hide() {
        this.props.onClose();
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={600}
                height={360}
                closeMaskOnClick={false}>
                <ImageUploader
                    headerText={<FormattedMessage id="projects.change.cover" defaultMessage="Change project image" />}
                    footerText={<FormattedMessage id="modalEditProfileImage.dragndrop" defaultMessage="Click or Drag 'n' drop your picture file here" />}
                    initialImage={this.props.image}
                    defaultImage={'/assets/img/floriane-vita.png'}
                    saveFn={this.props.UploadProjectCoverPictureStart}
                    saveFnArgs={[this.props.project.id]}
                    closeFn={this.props.onClose}
                    avatarEditorProps={{
                        width: 545,
                        height: 150,
                        border: 0,
                        borderRadius: 0
                    }}
                />
            </Rodal>
        )
    }

}

const mapStateToProps = state => {
    return {
        project: state.myWork.myProjects.selected,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UploadProjectCoverPictureStart
})(ModalEditProjectCover));