import * as types from './types';
import history from '../../../utils/routes/history'
import  { apiCon, con, conWithoutToken } from '../../../API'
import { langService } from '../../../utils/languageService';
import { GetNotificationsStatusStart } from '../notification';
import { CHANGE_LANG } from '../../actions/lang/types';

//----------------
// Actions Login
//----------------               
export const LoginStart = (credentials) => async dispatch => {
    dispatch({ type: types.LOGIN_REQUEST });
    localStorage.setItem("RememberMe", credentials.rememberMe);
    await conWithoutToken.post(
        '/auth/login/',
        credentials,
    ).then(res => {
        dispatch(LoginSuccess(res.data));
    })
        .catch(err => {
            dispatch(LoginFail(err.response));
        })
}

export const LoginSuccess = (data) => async dispatch => {
    const token = { access_token: data.access_token, refresh_token: data.refresh_token };
    const user = { ...data.user }
    const payload = { token: token, user: user, succ_msg: "Login sucessful!" };
    dispatch({ type: types.LOGIN_SUCCESS, payload: payload });
    if (user.roles && user.roles.length > 0) {
        dispatch(GetProfileCompletionStart());
        dispatch(GetNotificationsStatusStart());
        if (user.settings.active_role_obj.type === 'DEV') {
            history.push("/browse/tasks");
        } else {
            history.push("/browse/projects");
        }
    }
    else {
        history.push("/auth/register-role");
    }
}

export const LoginFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = langService.messages["login.error"];
    if (err.data.error_first_message) { toast_msg = err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.LOGIN_FAILURE, payload: payload };
}

export const FetchUserFromLocalStorage = () => async dispatch => {
    // ...also set language
    const lang = localStorage.getItem('lang');
    lang ? dispatch({ type: CHANGE_LANG, payload: lang }) : dispatch({ type: CHANGE_LANG, payload: navigator.language.includes('sl') ? 'sl' : 'en' });
    const access_token = localStorage.getItem("django_access_token");
    if (access_token) {
        const refresh_token = localStorage.getItem("django_refresh_token");
        const token = { access_token, refresh_token };
        const payload = { token: token, succ_msg: "User fetched from locale successfully!" };
        dispatch({ type: types.GET_USER_FROM_LOCALE, payload: payload });
        dispatch(FetchUserStart());
    }    
}

export const FetchUserStart = () => async dispatch => {
    dispatch({ type: types.GET_USER_REQUEST });
    apiCon.get(
        '/me/'
    ).then(response => {
        dispatch(FetchUserSuccess(response.data));
    }
    )
        .catch(err => {
            dispatch(FetchUserFail(err.response));
        })
}

export const FetchUserSuccess = (data) => async dispatch => {
    const user = { ...data }
    const payload = { user: user, succ_msg: "User fetched successfully!" };
    if (data.settings.active_role) {
        dispatch(GetProfileCompletionStart());
        dispatch(GetNotificationsStatusStart());
    }
    dispatch({ type: types.GET_USER_SUCCESS, payload: payload });
}

export const FetchUserFail = (err) => {
    if (err) {
        const err_msg = { errorType: err.data.error, detail: err.data.detail };
        const status = err.status;
        const payload = { err_msg: err_msg, status: status };
        return { type: types.GET_USER_FAILURE, payload: payload };
    } else {
        history.push("/");
        return { type: types.GET_USER_FAILURE, payload: { err_msg: 'Fetching user data failed.', status: '' } };
    }
}

//----------------------
// Actions Registration
//----------------------
export const RegisterStart = (credentials) => async dispatch => {
    dispatch({ type: types.REGISTER_REQUEST });
    await conWithoutToken.post(
        '/auth/registration/',
        credentials
    ).then(response => {
        dispatch(RegisterSuccess(response.data));
    }).catch(err => {
        dispatch(RegisterFail(err.response));
    })
}

export const RegisterSuccess = (data) => async dispatch => {
    const succ_msg = "Register was successful. An activation link has been send to your email.";
    const token = { access_token: data.access_token, refresh_token: data.refresh_token };
    const user = { ...data.user }
    const payload = { token: token, user: user, succ_msg: succ_msg };
    dispatch({ type: types.REGISTER_SUCCESS, payload: payload });
    if (user.roles && user.roles.length > 0) {
        dispatch(GetProfileCompletionStart());
        dispatch(GetNotificationsStatusStart());
        if (user.settings.active_role_obj.type === 'DEV') {
            history.push("/browse/tasks");
        } else {
            history.push("/browse/projects");
        }
    }
    else
        history.push("/auth/register-role");
}

export const RegisterFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = langService.messages["register.error"];
    if (err.data.error_first_message) { toast_msg = err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.REGISTER_FAILURE, payload: payload };
}

//----------------------
// Actions Logout
//----------------------
export const LogoutStart = () => async dispatch => {
    // localStorage.getItem('django_access_token');
    const refreshToken = localStorage.getItem('django_refresh_token');
    dispatch({ type: types.LOGOUT_REQUEST });
    await con.post('/auth/logout/', { refresh: refreshToken }).then(data => {
        dispatch(LogoutSuccess());
    }).catch(err => {
        dispatch(LogoutFail(err.response));
    });
}

export const LogoutSuccess = () => async dispatch => {
    const payload = { succ_msg: "Logged out successfully." };
    dispatch({ type: types.LOGOUT_SUCCESS, payload: payload });
    history.push("/");
}

export const LogoutFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status };
    return { type: types.LOGOUT_FAILURE, payload: payload };
}

//----------------------
// Actions Reset Password
//----------------------
export const ResetPasswordStart = (data) => async dispatch => {
    dispatch({ type: types.RESET_PASSWORD_REQUEST });
    await conWithoutToken.post(`/auth/password/reset/confirm/`, data)
        .then(
            () => {
                dispatch(ResetPasswordSuccess(data));
            })
        .catch(err => {
            dispatch(ResetPasswordFail(err.response));
        })
}

export const ResetPasswordSuccess = (data) => async dispatch => {
    const succ_msg = "Password reset successfully. Login in with your new password.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg };
    dispatch({ type: types.RESET_PASSWORD_SUCCESS, payload: payload });
    dispatch(LogoutStart(data.token.token_type, data.token.access_token));
    history.push("/auth/login");
}

export const ResetPasswordFail = (err) => {
    var toast_msg = langService.messages["resetpassword.error"];
    if (err.data.error_first_message) { toast_msg = "Reset password failed. " + err.data.error_first_message; };
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.RESET_PASSWORD_FAILURE, payload: payload }
}

//----------------------
// Actions Forgot Password
//----------------------
export const ForgotPasswordStart = (values) => async dispatch => {
    dispatch({ type: types.FORGOT_PASSWORD_REQUEST });
    await conWithoutToken.post(`/auth/password/reset/`, values)
        .then(
            () => {
                dispatch(ForgotPasswordSuccess());
            })
        .catch(err => {
            dispatch(ForgotPasswordFail(err.response));
        })
}

export const ForgotPasswordSuccess = () => async dispatch => {
    var succ_msg = "Mail for password reset sent successfully.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg };
    dispatch({ type: types.FORGOT_PASSWORD_SUCCESS, payload: payload });
    dispatch(LogoutStart());
}

export const ForgotPasswordFail = (err) => {
    var toast_msg = langService.messages["forgotpassword.error"];
    if (err.data.error_first_message) { toast_msg = "Forgot password failed. " + err.data.error_first_message; };
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.FORGOT_PASSWORD_FAILURE, payload: payload };
}

//----------------------
// Actions Resend Activation Email
//----------------------
export const ResendActivationEmailStart = () => async dispatch => {
    dispatch({ type: types.RESEND_ACTIVATION_EMAIL_REQUEST });
    await con.get(`auth/email/resend-confirm-email/`)
        .then(() => {
            dispatch(ResendActivationEmailSuccess());
        })
        .catch(err => {
            dispatch(ResendActivationEmailFail(err.response));
        })
}

export const ResendActivationEmailSuccess = () => async dispatch => {
    var succ_msg = "Activation email was resend successfully, check your email adress.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg };
    dispatch({ type: types.RESEND_ACTIVATION_EMAIL_SUCCESS, payload: payload });
}

export const ResendActivationEmailFail = (err) => {
    var toast_msg = "Resend Activation email failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Resend Activation email failed. " + err.data.error_first_message; };
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.RESEND_ACTIVATION_EMAIL_FAILURE, payload: payload };
}

//----------------------
// Actions Activate Email
//----------------------
export const ActivateEmailStart = (values) => async dispatch => {
    dispatch({ type: types.ACTIVATE_EMAIL_REQUEST });
    await con.post(`auth/registration/verify-email/`, values)
        .then(() => {
            dispatch(ActivateEmailSuccess());
        })
        .catch(err => {
            dispatch(ActivateEmailFail(err.response));
        })
}

export const ActivateEmailSuccess = () => async dispatch => {
    var succ_msg = "Activate email was successfully.";
    const payload = { succ_msg: succ_msg };
    dispatch({ type: types.ACTIVATE_EMAIL_SUCCESS, payload: payload });
}

export const ActivateEmailFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status };
    return { type: types.ACTIVATE_EMAIL_FAILURE, payload: payload };
}

//----------------------
// Actions Get Profile Completion
//----------------------
export const GetProfileCompletionStart = () => async dispatch => {
    dispatch({ type: types.GET_PROFILE_COMPLETION_REQUEST });
    await apiCon.get('/me/completion/')
        .then(response => {
            dispatch(GetProfileCompletionSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetProfileCompletionFail(err.response));
        })
}
export const GetProfileCompletionSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received profile completion info.", completion: data }
    dispatch({ type: types.GET_PROFILE_COMPLETION_SUCCESS, payload: payload });
}
export const GetProfileCompletionFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Profile completion failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get Profile completion failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_PROFILE_COMPLETION_FAILURE, payload: payload };
}

