import React from 'react';
import '../../../assets/styles/partials/_auth.scss';
import history from '../../../utils/routes/history';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Form as BSForm, Container, Col } from 'react-bootstrap';
import { required, strongPassword, passwordConfirmMatch } from '../../../utils/forms/validators';
import { composeRecordLevelValidators } from '../../../utils/forms/validatorUtilities'
import { Input, CheckBoxAgreeWithTerms } from '../../../utils/forms/formElements';
import { RegisterStart } from '../../../core/actions/auth';
import { RegisterGoogleStart } from '../../../core/actions/googleAuth';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import {GoogleLogin} from 'react-google-login';
import { REACT_APP_GOOGLE_CLIENT_ID } from '../../../utils/common/env-variables';

class RegisterPage extends React.Component {
    FORM_LABEL = 'forms.labels';
    FORM_PLC = 'forms.placeholders';

    onSubmit = credentials => {
        this.props.RegisterStart(credentials);
    }

    onRegisterGoogle = async(google_response)=> {
        this.props.RegisterGoogleStart(google_response.accessToken)
    }

    goToLogin = e => {
        e.preventDefault();
        history.push("/auth/login");
    }

    render() {
        return (
            <>
                <div id="overlay">
                    <img src="/assets/img/icon/lightbulb.svg" alt="Idea"></img>
                </div>
                <Container className="flex-center pt-65">
                    <Form
                        onSubmit={this.onSubmit}
                        initialValues={this.props.values}
                        validate={composeRecordLevelValidators(passwordConfirmMatch)}
                        render={({ handleSubmit, valid, values }) => (
                            <div className="auth">
                                <p><span className="underline"><FormattedMessage id="forms.registrationForm" defaultMessage="Register" /></span></p>
                                <BSForm validated={valid} onSubmit={handleSubmit}>
                                    <BSForm.Row>
                                        <Col>
                                            <Field
                                                name="email"
                                                label={<FormattedMessage id={`${this.FORM_LABEL}.email`} defaultMessage="email" />}
                                                type="email"
                                                component={Input}
                                                validate={required}
                                            />
                                            <Field
                                                name="password1"
                                                type="password"
                                                label={<FormattedMessage id={`${this.FORM_LABEL}.password`} defaultMessage="password" />}
                                                component={Input}
                                                validate={strongPassword}
                                            />
                                            <Field
                                                name="password2"
                                                type="password"
                                                label={<FormattedMessage id={`${this.FORM_LABEL}.confirmPassword`} defaultMessage="confirmPassword" />}
                                                component={Input}
                                                // validate={minLen8}
                                            />
                                        </Col>
                                    </BSForm.Row>
                                    <Field
                                        name="agreeWithTerms"
                                        type="checkbox"
                                        component={CheckBoxAgreeWithTerms}
                                        defaultValue={false}
                                        validate={required}
                                    />
                                    <button type="submit" className="btn btn-default btn-generic btn-block"><FormattedMessage id="forms.labels.register" defaultMessage="Register" /></button>
                                    <BSForm.Text className="auth-small">
                                        <FormattedMessage id={`${this.FORM_LABEL}.alreadyHaveAccount`} defaultMessage="alreadyHaveAccount" />
                                        <Link to="/auth/login"><FormattedMessage id={`${this.FORM_LABEL}.goToLogin`} defaultMessage="goToLogin" /></Link>
                                    </BSForm.Text>
                                    {/* {!prod ? <pre><p ><code><small>{JSON.stringify(values, 0, 2)}</small></code></p></pre> : ''} */}
                                </BSForm>
                                <div className="separator">or</div>
                                {/* <Button variant="outline-secondary" className="btn-block">
                                    <img src="/assets/img/icon/Google__G__Logo.svg" width="20" alt="Continue with Google"></img>
                                    <FormattedMessage id={`${this.FORM_LABEL}.continueWithGoogle`} defaultMessage="Continue with Google" />
                                </Button> */}
                                <GoogleLogin
                                    clientId={REACT_APP_GOOGLE_CLIENT_ID()}
                                    buttonText={<FormattedMessage id={`${this.FORM_LABEL}.registerWithGoogle`} defaultMessage="Register with Google" />}
                                    onSuccess={this.onRegisterGoogle}
                                    onFailure={this.onRegisterGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    className="btn-block"
                                />
                            </div>
                        )}
                    />
                </Container>
            </>
        )
    }

}

export default injectIntl(connect(null, { RegisterStart,RegisterGoogleStart })(RegisterPage));