import React from 'react';
import { connect } from 'react-redux';
import history from '../../utils/routes/history';
import { injectIntl, FormattedMessage } from 'react-intl';
import { langService } from '../../utils/languageService';
import { getBloomName, getBloomVerb } from '../../utils/common/misc';

class VotingItem extends React.Component {

    render() {
        const { item } = this.props;
        return (
            <>
                <div className="mb-1">
                    {item.parent
                        ? <small><span className="link-hover" onClick={() => history.push("/competency/" + item.parent.id)}>{item.parent.name + " L" + item.parent.level}</span></small>
                        : <small><FormattedMessage id="competences.no_parent" defaultMessage="No parent competency" /></small>
                    }
                </div>
                <p><strong><span className="link-hover" onClick={() => history.push("/competency/" + item.id)}>{item.name?.toUpperCase() + " L" + item.level}</span> <small>({item.id})</small></strong></p>
                <div className=""><small>{getBloomName(item.level)} ({getBloomVerb(1, item.level, langService.locale)})</small> </div>
                <p>
                    <FormattedMessage id="competences.this_person_can" defaultMessage="This person can " />
                    <strong>{getBloomVerb(2, item.sub_level, langService.locale)}</strong>&nbsp;
                                        {item.description ? item.description : "..."}
                </p>
                <div><small>{item.skills?.length > 0 ? langService.messages['competences.related_skills'] : langService.messages['competences.no_related_skills']}</small></div>
                <div>
                    {item.skills?.map((s, index) =>
                        <span key={index}><span className="link-hover" onClick={() => history.push("/competency/" + s.id)} >{s.name + " L" + s.level}</span><span>{index < item.skills?.length - 1 ? ',\u00A0' : ''}</span></span>
                    )}
                </div>
            </>
        )
    }
}

export default injectIntl(connect(null, {})(VotingItem));
