import * as types from './types';
import { apiCon } from '../../../API';
import { SelectMyTaskStart, CompleteSubmitTaskStart } from '../tasks';
import { CreateTaskResultDocumentStart } from '../taskResultDocuments';

//----------------------
// Actions Task Results
//----------------------

//----------------------
// Actions Get My Task Results
//----------------------
export const GetMyTaskResultsStart = (taskId, limit = 100, search) => async dispatch => {
    dispatch({ type: types.GET_MY_TASK_RESULTS_REQUEST });
    var url = '/me/project/task/results/'
    const params = {
        'limit' : limit,
        'task': taskId,
        ...(search ? { 'search': search } : {}),
    }
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetMyTaskResultsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyTaskResultsFail(err.response));
        })
}
export const GetMyTaskResultsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_MY_TASK_RESULTS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyTaskResultsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyTaskResultsFail(err.response));
        })
}
export const GetMyTaskResultsSuccess = (data) => {
    const payload = { succ_msg: "Successfully received tasks results info.", list: data }
    return { type: types.GET_MY_TASK_RESULTS_SUCCESS, payload: payload };
}
export const GetMyTaskResultsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Task Results failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get My Task Results failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_TASK_RESULTS_FAILURE, payload: payload }
}

//----------------------
// Actions Select My Task Result
//----------------------
export const SelectMyTaskResult = (id) => async dispatch => {
    dispatch({ type: types.SELECT_MY_TASK_RESULT_REQUEST });
    await apiCon.get('/project/task/result/' + id)
        .then(response => {
            dispatch(SelectMyTaskResultSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectMyTaskResultFail(err.response));
        })
}
export const SelectMyTaskResultSuccess = (data) => {
    const payload = { succ_msg: "Successfully received task result info.", selected: data }
    return { type: types.SELECT_MY_TASK_RESULT_SUCCESS, payload: payload };
}
export const SelectMyTaskResultFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Task result failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get task result failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_MY_TASK_RESULT_FAILURE, payload: payload }
}

export const DeselectMyTaskResult = () => {
    const payload = { succ_msg: "Successfully deselected task result info." }
    return { type: types.DESELECT_MY_TASK_RESULT, payload: payload };
}

//----------------------
// Actions Update Task Result
//----------------------
export const UpdateTaskResultStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_TASK_RESULT_REQUEST });
    const address = `/me/project/task/result/` + values.id;
    delete values['id'];
    const json = JSON.stringify(values);
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateTaskResultSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateTaskResultFail(err.response));
        });

}
export const UpdateTaskResultSuccess = (data) => {
    const succ_msg = "Task Result updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.UPDATE_TASK_RESULT_SUCCESS, payload: payload };
}
export const UpdateTaskResultFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Update Task Result failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Update Task Result failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.UPDATE_TASK_RESULT_FAILURE, payload: payload };
}

//----------------------
// Actions Create Task Result
//----------------------
export const CreateTaskResultStart = (values, files, finishTask) => async dispatch => {
    dispatch({ type: types.CREATE_TASK_RESULT_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/project/task/results/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(CreateTaskResultSuccess(response.data));
            if (files) {
                Promise.all(files.map((file, index) => {
                    return dispatch(CreateTaskResultDocumentStart(response.data['id'], file))
                })).then(array => {
                    if (finishTask && finishTask === true) {
                        dispatch(CompleteSubmitTaskStart(values['task'], 'SUBMIT'))
                    }
                });
            }
            else {
                if (finishTask && finishTask === true) {
                    dispatch(CompleteSubmitTaskStart(values['task'], 'SUBMIT'))
                }
            }
        })
        .catch(err => {
            dispatch(CreateTaskResultFail(err.response));
        });

}
export const CreateTaskResultSuccess = (data) => {
    const succ_msg = "Task Result created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.CREATE_TASK_RESULT_SUCCESS, payload: payload };
}
export const CreateTaskResultFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Ooops! Create Task Result failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create Task Result failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_TASK_RESULT_FAILURE, payload: payload };
}

