import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { UpdateEducationVerificationRequestStart } from '../../core/actions/education';

class MyInstitutionStudents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() { }

    reject(r) {
        this.props.UpdateEducationVerificationRequestStart({ id: r.id, status: 4 });
    }

    confirm(r) {
        this.props.UpdateEducationVerificationRequestStart({ id: r.id, status: 3 });
    }

    renderRequests() {
        const { requests } = this.props;

        return (
            <>
                {requests.results.map((r, x) => {
                    return (
                        <div key={x}>
                            <div className="request-list pr-3 pl-3">
                                <div>
                                    <div className="pb-2">
                                        <span className="fs-14"><FormattedMessage id="forms.labels.firstName" defaultMessage="Firstname" />: </span>
                                        <span className="">{r.applicant_first_name}</span>
                                    </div>
                                    <div className="pb-2">
                                        <span className="fs-14"><FormattedMessage id="forms.labels.lastName" defaultMessage="Lastname" />: </span>
                                        <span className="">{r.applicant_last_name}</span>
                                    </div>
                                    <div className="pb-2">
                                        <span className="fs-14"><FormattedMessage id="common.national_id" defaultMessage="National ID number" />: </span>
                                        <span className="">{r.applicant_national_id}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="pb-2">
                                        <span className="fs-14"><FormattedMessage id="edu.student_id" defaultMessage="Student Number" />: </span>
                                        <span className="">{r.applicant_edu_id}</span>
                                    </div>
                                    <div className="pb-2">
                                        <span className="fs-14"><FormattedMessage id="edu.diploma_id" defaultMessage="Diploma Id Number" />: </span>
                                        <span className="">{r.certificate_edu_id}</span>
                                    </div>
                                    <div className="pb-2">
                                        <span className="fs-14">
                                        <FormattedMessage id="common.from" defaultMessage="From" />
                                        &nbsp;-&nbsp; 
                                        <FormattedMessage id="common.to" defaultMessage="To" />
                                        : </span>
                                        <span className="">{moment(r.date_from).format('YYYY')} - {moment(r.date_to).format('YYYY')}</span>
                                    </div>
                                </div>
                                <div className="action-btns-right">
                                    <div className="btn btn-default btn-projects btn-sm btn-smallpadding mb-3" onClick={() => this.reject(r)}>
                                        <FormattedMessage id="common.reject" defaultMessage="Reject" />
                                    </div>
                                    <div className="btn btn-default btn-projects-inv btn-sm btn-smallpadding" onClick={() => this.confirm(r)}>
                                        <FormattedMessage id="common.confirm" defaultMessage="CONFIRM" />
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    )
                })}
            </>
        )

    }

    renderStudentList() {
        const { students } = this.props;

        return (
            <table className="s-table mr-3 ml-3">
                <thead>
                    <tr>
                        <th><FormattedMessage id="common.name" defaultMessage="Name" /></th>
                        <th><FormattedMessage id="edu.student_id" defaultMessage="Student Number" /></th>
                        <th><FormattedMessage id="edu.program" defaultMessage="Program" /></th>
                        <th><FormattedMessage id="edu.diploma_id" defaultMessage="Diploma Id Number" /></th>
                        <th><FormattedMessage id="common.year" defaultMessage="Year" /></th>
                    </tr>
                </thead>
                <tbody>
                    {students.results.map((r, x) => {
                        return (
                            <tr key={x}>
                                <td><Link className="link-blue" to={"/user/" + r.applicant.id}>{r.applicant_first_name} {r.applicant_last_name}</Link></td>
                                <td>{r.applicant_edu_id}</td>
                                <td>{r.edu_program.name} - {r.edu_program.degree.name}</td>
                                <td>{r.certificate_edu_id}</td>
                                <td>{moment(r.date_from).format('YYYY')} - {moment(r.date_to).format('YYYY')}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    render() {
        const { showRequests, showStudentList } = this.props;
        return (
            <>
                <div className="edu-card-header">
                    <div className="card-head">
                        <FormattedMessage id="edu.students" defaultMessage="Students" /> -&nbsp;
                        {showRequests && <FormattedMessage id="edu.verification_requests" defaultMessage="Verification requests" />}
                        {showStudentList && <FormattedMessage id="common.list" defaultMessage="List" />}
                    </div>
                </div>
                <Container className="p-0 mt-2 pb-3">

                    <div className="edu-subtitle">
                        {showRequests && <FormattedMessage id="edu.confirm_requests" defaultMessage="Confirm and aproove requests from your alumni students." />}
                        {showStudentList && <FormattedMessage id="edu.alumni_list" defaultMessage="A list of your certified alumni students." />}
                    </div>
                    <hr />
                    {showRequests && this.renderRequests()}
                    {showStudentList && this.renderStudentList()}
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        // programs: state.user.subroles.list.results,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateEducationVerificationRequestStart
})(MyInstitutionStudents));