import { GET_EDUCATION_VERIFICATION_REQUESTS_SUCCESS, GET_VERIFIED_STUDENTS_SUCCESS } from "../../actions/education/types";


const INITIAL_STATE = {
    selected: {
        id: null,
        is_locked: '',
        name: '',
        description: ''
    },
    requests: {
        count: 0,
        next: null,
        previous: null,
        results: [ ]
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: [ ]
    }    
}


export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case GET_EDUCATION_VERIFICATION_REQUESTS_SUCCESS:
            var req = {
                count: 0,
                next: null,
                previous: null,
                results: [ ]
            }
            var lst = {
                count: 0,
                next: null,
                previous: null,
                results: [ ]
            }
            action.payload.data.results.forEach(e => {
                if (e.status === 1) { // pending
                    req.count++;
                    req.results.push(e);
                } else if (e.status === 3) { // aprooved
                    lst.count++;
                    lst.results.push(e);
                }
            });
            return{ ...state, requests: req, list: lst };
    
        // case CREATE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };
        // case UPDATE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };
        // case DELETE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };            
        default:
            return state;
    }
}