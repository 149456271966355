import { GET_PROJECTS_SUCCESS, SELECT_PROJECT_SUCCESS, DESELECT_PROJECT } from '../../../actions/projects/types'
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../../actions/auth/types'
import { CHANGE_PROJECT_FILTERS } from '../../../actions/projects/types';
import milestoneReducer from './milestoneReducer';
import { GET_MILESTONES_SUCCESS, CREATE_MILESTONE_SUCCESS, UPDATE_MILESTONE_SUCCESS, SELECT_MY_MILESTONE_SUCCESS, DELETE_MILESTONE_SUCCESS } from './../../../actions/milestone/types';

export const INITIAL_STATE_FILTERS = {
    limit: 10,
    search: '',
    ordering: '',
    category: '',
    budget_max: '',
    budget_min: '',
    date_from: '',
    date_to: '',
    status: ['ACTIVE', 'PUBLISHED'],
    my_projects: false
}

const INITIAL_STATE = {
    selected: {
        id: null,
        category: {
            id: null,
            name: '',
            description: ''
        },
        client: {
            id: null,
            competences: [],
            type: '',
            name: '',
            first_name: '',
            last_name: '',
            company_name: '',
            address_line_1: '',
            address_line_2: '',
            post_code: '',
            city: '',
            state: '',
            country: '',
            vat: '',
            status: '',
        },
        logo_image: '',
        cover_image: '',
        estimated_budget: '',
        estimated_start: '',
        estimated_end: '',
        number_of_tasks: null,
        milestones: milestoneReducer,
        name: '',
        short_description: '',
        description: '',
        visibility: '',
        status: ''
    },
    list: {
        filters: INITIAL_STATE_FILTERS,
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_PROJECT_FILTERS:
            return {
                ...state, list: {
                    ...state.list,
                    filters: { ...state.list.filters, ...action.payload.filters }
                }
            };
        case GET_PROJECTS_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? [...state.list.results, ...action.payload.list.results] : action.payload.list.results
                }
            };
        case SELECT_PROJECT_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case DESELECT_PROJECT:
            return { ...state, selected: INITIAL_STATE.selected };
        case GET_MILESTONES_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: milestoneReducer(state.selected.milestones, action),
                }
            }
        case CREATE_MILESTONE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: milestoneReducer(state.selected.milestones, action),
                }
            }
        case UPDATE_MILESTONE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: milestoneReducer(state.selected.milestones, action),
                }
            }
        case DELETE_MILESTONE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: milestoneReducer(state.selected.milestones, action),
                }
            }
        case SELECT_MY_MILESTONE_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    milestones: milestoneReducer(state.selected.milestones, action),
                }
            }
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}