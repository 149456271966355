//-------------------
//  Get SubRoles 
//-------------------
export const GET_SUBROLES_REQUEST = "GET_SUBROLES_REQUEST";
export const GET_SUBROLES_SUCCESS = "GET_SUBROLES_SUCCESS";
export const GET_SUBROLES_FAILURE = "GET_SUBROLES_FAILURE";

//-------------------
//  Create SubRole 
//-------------------
export const CREATE_SUBROLE_REQUEST = "CREATE_SUBROLE_REQUEST";
export const CREATE_SUBROLE_SUCCESS = "CREATE_SUBROLE_SUCCESS";
export const CREATE_SUBROLE_FAILURE = "CREATE_SUBROLE_FAILURE";

//-------------------
//  Get SubRole Details
//-------------------
export const GET_SUBROLE_DETAIL_REQUEST = "GET_SUBROLE_DETAIL_REQUEST";
export const GET_SUBROLE_DETAIL_SUCCESS = "GET_SUBROLE_DETAIL_SUCCESS";
export const GET_SUBROLE_DETAIL_FAILURE = "GET_SUBROLE_DETAIL_FAILURE";

//-------------------
//  Update SubRole 
//-------------------
export const UPDATE_SUBROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_SUBROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_SUBROLE_FAILURE = "UPDATE_ROLE_FAILURE";

//-------------------
//  Delete SubRole actions
//-------------------
export const DELETE_SUBROLE_REQUEST = "DELETE_SUBROLE_REQUEST";
export const DELETE_SUBROLE_SUCCESS = "DELETE_SUBROLE_SUCCESS";
export const DELETE_SUBROLE_FAILURE = "DELETE_SUBROLE_FAILURE";