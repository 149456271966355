import React from 'react';
import '../browse-styles.scss';
import './index.scss';
import _ from 'lodash';
import history from '../../../utils/routes/history';
import InfoBar from '../../../components/Infobar';
import BrowseNavigationControl from '../../../components/BrowseNavigation';
import FormatedDuration from '../../../components/Common/FormatedDuration';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { GetTasksStart, SelectTaskStart, DeselectTask, SelectMyTaskStart, GetTasksChangePageStart } from '../../../core/actions/tasks';
import { createLoadingSelector } from '../../../API/selector';
import { TasksFilters, TasksSearchSort } from './TasksFilters';
import { isEqual } from 'lodash';
import { INITIAL_STATE_FILTERS } from '../../../core/reducers/browse/tasksReducer';
import { debounceFreeInputFields } from '../../../utils/common/browse';

class TasksList extends React.Component {

    componentDidMount() {
        this.init();
    }

    init = () => {
        const { tasks, tasksFilters } = this.props;

        if (tasks.length !== 0)
            return;

        this.props.GetTasksStart(tasksFilters);
    }

    componentDidUpdate(prevProps) {
        // Makes sure tasksFilters prop changed
        if (prevProps.tasksFilters === this.props.tasksFilters)
            return;

        // If reset button is clicked cancel the input debouncer & fetch immediately
        if (isEqual(this.props.tasksFilters, INITIAL_STATE_FILTERS)) {
            debounceFreeInputFields.cancel();
            this.props.GetTasksStart(this.props.tasksFilters);
            return;
        }

        if (prevProps.tasksFilters.search !== this.props.tasksFilters.search
            || prevProps.tasksFilters.price_max !== this.props.tasksFilters.price_max
            || prevProps.tasksFilters.price_min !== this.props.tasksFilters.price_min) {
            debounceFreeInputFields([this.props.tasksFilters.search, this.props.tasksFilters.price_max, this.props.tasksFilters.price_min], this.props.GetTasksStart, this.props.tasksFilters);
            return;
        }


        debounceFreeInputFields.cancel();
        this.props.GetTasksStart(this.props.tasksFilters);
    }

    componentWillUnmount() {
        debounceFreeInputFields.cancel();
    }

    openTaskDetails = (projectId, taskId) => {
        this.props.DeselectTask()
        history.push("/project/" + projectId + "/task/" + taskId);
    }

    viewInMyWork = taskId => {
        this.props.SelectMyTaskStart(taskId);
        history.push('/my-work')
    }

    removeHours(d) {
        var res = new Date(new Date(d).getFullYear(), new Date(d).getMonth(), new Date(d).getDate());
        return res.toISOString();
    }

    renderActions(t) {
        const { auth: { isAuthenticated }, isEmailVerified, active_role, active_role_type } = this.props;

        if (!isAuthenticated)
            return <button className="btn btn-default btn-projects" onClick={() => history.push('/auth/login')}><FormattedMessage id="projects.logintoview" defaultMessage="Login to view" /></button>

        if (isAuthenticated && !isEmailVerified)
            return (<button className="btn btn-default btn-projects"><FormattedMessage id="common.verify_email_to_view" defaultMessage="Verify email to view" /></button>)

        if (active_role_type !== 'DEV')
            return <button className="btn btn-default btn-projects" onClick={() => this.openTaskDetails(t.project.id, t.id)}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>

        if (active_role?.id !== t.active_freelancer)
            return <button className="btn btn-default btn-projects" onClick={() => this.openTaskDetails(t.project.id, t.id)}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>

        return (
            <>
                <button className="btn btn-default btn-projects" onClick={() => this.openTaskDetails(t.project.id, t.id)}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>
                <button className="btn btn-default btn-projects btn-smallpadding mt-2" onClick={() => this.viewInMyWork(t.id)}><FormattedMessage id="browse.common.view_my_work" defaultMessage="View in My Work" /></button>
            </>
        );
    }


    renderTasks(tasks) {
        if (_.isEmpty(tasks)) {
            return <></>
        }
        return (
            <>
                {tasks.map((t, i) =>
                    <Card className="card-item" key={i}>
                        <Card.Body>
                            <div className="card-thumb">
                                {
                                    t.project.logo_image ?
                                        <img src={t.project.logo_image} className="p-thumb" alt="Project thumb" />
                                        :
                                        <img src="/assets/img/default-task.png" className="p-thumb" alt="Project thumb" />
                                }
                            </div>
                            <div className="card-details">
                                <Card.Title onClick={() => this.openTaskDetails(t.project.id, t.id)} className="card-title browse-namelink">{t.name}</Card.Title>
                                <div className="card-desc">
                                    {t.short_description}
                                </div>
                                <div className="card-info">
                                    <span> <FormattedMessage id="projects.duration" defaultMessage="Duration" /> <strong>{t.visibility && t.visibility === "PRIVATE" ? 'N/A' :
                                        <FormatedDuration start_date={this.removeHours(t.start_date)} end_date={this.removeHours(t.end_date)} />}
                                    </strong></span>
                                </div>
                                <div className="card-tags">
                                    {t.competences.map((c, x) =>
                                        <span onClick={() => history.push('/competency/'+c.id)} key={x}>{c.name} L{c.level}</span>
                                    )}
                                </div>
                            </div>
                            <div className="card-sep"></div>
                            <div className="card-actions">
                                <span>{t.price ? `$` + parseInt(t.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : t.price} </span>
                                {this.renderActions(t)}
                            </div>
                        </Card.Body>
                    </Card>
                )}
            </>
        )
    }

    render() {
        const { tasks } = this.props;
        const { auth: { isAuthenticated } } = this.props;
        return (
            <>
                <Jumbotron className="jumbo-projects mb-0">
                    <InfoBar />
                    <Container>
                        <h1><FormattedMessage id="projects.heading" defaultMessage="Find the best projects and tasks for you" /></h1>
                    </Container>
                </Jumbotron>
                <Container className="mt-0">
                    <BrowseNavigationControl page="tasks" />
                    <Row>
                        <Col md={12} lg={3}>
                            <TasksFilters />
                        </Col>
                        <Col md={12} lg={9}>

                            <div className="browse-title">
                                <FormattedMessage id="projects.tasks" defaultMessage="Tasks" />
                                {isAuthenticated && this.props.active_role_type === "INV" &&
                                    <button className="btn btn-default btn-projects btn-create-project" onClick={() => history.push("/project/new")}><FormattedMessage id="browse.common.createProject" defaultMessage="Create project" /></button>
                                }
                            </div>

                            <TasksSearchSort />

                            <span className="items-count">
                                <FormattedMessage id="plural.tasks" values={{ count: tasks?.length }}
                                    defaultMessage={`${tasks?.length} Tasks`} />
                            </span>

                            <InfiniteScroll
                                dataLength={tasks.length}
                                next={() => setTimeout(() => this.props.nextUrl && this.props.GetTasksChangePageStart(this.props.nextUrl), 500)}
                                hasMore={tasks.length !== this.props.count}
                                loader={<div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.loading" defaultMessage="Loading" />...</div>}
                                endMessage={tasks.length > 0 && <div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.allrecordsloaded" defaultMessage="All records loaded" /></div>}
                            >
                                <div className="card-list-projects">
                                    {this.renderTasks(tasks)}
                                </div>
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['GET_TASKS']);
const mapStateToProps = state => {
    return {
        isLoading: loadingSelector(state),
        tasks: state.browse.tasks?.list?.results,
        auth: state.user.auth,
        isEmailVerified: state.user.info.isEmailVerified,
        active_role: state.user.info.settings.active_role_obj,
        active_role_type: state.user.info.settings.active_role_obj?.type,
        tasksFilters: state.browse.tasks.list.filters,
        nextUrl: state.browse.tasks.list.next,
        count: state.browse.tasks.list.count
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetTasksStart,
    SelectTaskStart,
    DeselectTask,
    SelectMyTaskStart,
    GetTasksChangePageStart
})(TasksList));