import * as types from './types';
import history from '../../../utils/routes/history'
import { conWithoutToken } from '../../../API'
import { LoginSuccess, LoginFail, RegisterSuccess, GetProfileCompletionStart } from '../auth'
import { GetNotificationsStatusStart } from '../notification';
import { langService } from '../../../utils/languageService';
import { REGISTER_SUCCESS } from '../auth/types';

/**
 * 
 * @param {*} accesstoken This is the accesstoken of the user obtained from Google
 */
export const LoginGoogleStart = (accesstoken) => async dispatch => {
  if (!accesstoken) { return }

  dispatch({ type: types.GOOGLE_LOGIN_REQUEST });
  await conWithoutToken.post(
    '/auth/google/', { access_token: accesstoken, },
  ).then(res => {
    dispatch(LoginSuccess(res.data));
  })
    .catch(err => {
      dispatch(LoginGoogleFail(err.response));
    })
}

// export const LoginGoogleSuccess = (data) => async dispatch => {
//   const token = { access_token: data.access_token, refresh_token: data.refresh_token };
//   const user = { ...data.user }
//   const payload = { token: token, user: user, succ_msg: "Login sucessful!" };
//   dispatch({ type: types.GOOGLE_LOGIN_SUCCESS, payload: payload });
//   if (user.roles && user.roles.length > 0) {
//       dispatch(GetProfileCompletionStart());
//       dispatch(GetNotificationsStatusStart());
//       if (user.settings.active_role_obj.type === 'DEV') {
//           history.push("/browse/tasks");
//       } else {
//           history.push("/browse/projects");
//       }
//   }
//   else
//       history.push("/auth/register-role");
// }


export const LoginGoogleFail = (err) => {
  const err_msg = { errorType: err.data.error, detail: err.data.detail };
  var toast_msg = langService.messages["login.error"];
  if (err.data.error_first_message) { toast_msg = err.data.error_first_message; };
  const status = err.status;
  const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
  return { type: types.GOOGLE_LOGIN_FAILURE, payload: payload };
}

/**
 * 
 * @param {*} accesstoken This is the accesstoken of the user obtained from Google
 */
export const RegisterGoogleStart = (accesstoken) => async dispatch => {
  if (!accesstoken) {
    return
  }

  dispatch({ type: types.GOOGLE_REGISTER_REQUEST });
  await conWithoutToken.post(
    '/auth/google/',
    {
      access_token: accesstoken,
    },
  ).then(res => {
    // dispatch(RegisterGoogleSuccess(res.data));
    dispatch(RegisterSuccess(res.data));
  })
    .catch(err => {
      dispatch(RegisterGoogleFail(err.response));
    })
}

export const RegisterGoogleSuccess = (data) => async dispatch => {
  const token = { access_token: data.access_token, refresh_token: data.refresh_token };
  const user = { ...data.user }
  const payload = { token: token, user: user, succ_msg: "Register was successful." };
  dispatch({ type: REGISTER_SUCCESS, payload: payload }); // REGISTER_SUCCESS
  if (user.roles && user.roles.length > 0) {
    dispatch(GetProfileCompletionStart());
    dispatch(GetNotificationsStatusStart());
    if (user.settings.active_role_obj.type === 'DEV') {
      history.push("/browse/tasks");
    } else {
      history.push("/browse/projects");
    }
  } else {
    history.push("/auth/register-role");
  }
}

export const RegisterGoogleFail = (err) => {
  const err_msg = { errorType: err.data.error, detail: err.data.detail };
  var toast_msg = langService.messages["register.error"];
  if (err.data.error_first_message) { toast_msg = err.data.error_first_message; };
  const status = err.status;
  const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
  return { type: types.GOOGLE_REGISTER_FAILURE, payload: payload };
}
