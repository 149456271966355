import React from 'react';
import '../../browse-styles.scss';
import './index.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Container, Card, Col, Jumbotron, Image } from 'react-bootstrap';
import { DateRangePicker } from 'react-dates';
import { CreateProjectStart } from '../../../../core/actions/projects';
import { GetProjectCategoriesStart } from '../../../../core/actions/projectCategories';
import { UploadDocuments } from '../../../../components/Projects/uploadDocuments';
import ModalCreateProjectImages from '../../../../components/Projects/Create/modalCreateProjectImages';
import ModalCreateProjectCover from '../../../../components/Projects/Create/modalCreateProjectCover';
import { langService } from './../../../../utils/languageService/index';
import AutosizeTextarea from './../../../../components/Common/AutosizeTextarea';

class ProjectCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            short_description: '',
            description: '',
            estimated_start: null,
            estimated_end: null,
            estimated_budget: 0,
            visibility: 'PUBLIC',
            category: '',
            focusedInput: null,
            startDate: null,
            endDate: null,
            validated: false,
            dateInvalid: false,
            projectCover: null,
            projectCoverPreview: null,
            projectLogo: null,
            projectLogoPreview: null,
            projectFiles: null,
            modalProjectCoverVisible: false,
            modalProjectLogoVisible: false
        };
    }

    componentDidMount() {
        this.props.GetProjectCategoriesStart();
      }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.projectCategories.results.length > 0) {
            this.setState({ category: nextProps.projectCategories.results[0].id.toString() });
        }
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value })
    }

    textAreaValueChanged(input, event) {
        if (input === 'short_description' && event.target.value.length > 500) 
            return;
        
        if (input === 'description' && event.target.value.length > 5000)
            return;

        this.setState({ [input]: event.target.value })
    }

    onFilesChanged = (f) => {
        this.setState({ projectFiles: f })
    }

    save = () => {
        if (this.state.modalProjectLogoVisible || this.state.modalProjectCoverVisible ) { return; }
        this.props.CreateProjectStart({
            name: this.state.name,
            short_description: this.state.short_description,
            description: this.state.description,
            estimated_start: moment(this.state.startDate).startOf('day').toISOString(),
            estimated_end: moment(this.state.endDate).startOf('day').toISOString(),
            estimated_budget: this.state.estimated_budget,
            visibility: this.state.visibility,
            category: this.state.category
        }, this.state.projectLogo, this.state.projectCover, this.state.projectFiles);
    }

    handleSubmit = event => {
        if (this.state.modalProjectLogoVisible || this.state.modalProjectCoverVisible ) { return; }

        const form = event.currentTarget;

        // check date range
        if (!this.state.startDate || !this.state.endDate) {
            this.setState({ dateInvalid: true });
            event.preventDefault();
            event.stopPropagation();
        }

        // form validation
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
        }

        this.setState({ validated: true });
    };

    hideModalProjectCover = (img) => {
        if (img) {
            var that = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ projectCover: img, projectCoverPreview: e.target.result, modalProjectCoverVisible: false });
            }
            reader.readAsDataURL(img);
        } else {
            this.setState({ modalProjectCoverVisible: false });
        }
    }

    hideModalProjectLogo = (img) => {
        if (img) {
            var that = this;
            var reader = new FileReader();
            reader.onload = function (e) {
                that.setState({ projectLogo: img, projectLogoPreview: e.target.result, modalProjectLogoVisible: false });
            }
            reader.readAsDataURL(img);
        } else {
            this.setState({ modalProjectLogoVisible: false });
        }
    }


    /* RENDERS */
    renderModalCoverImage() {
        if (this.state.modalProjectCoverVisible) {
            return (
                <ModalCreateProjectCover
                    visible={this.state.modalProjectCoverVisible}
                    image={null}
                    onClose={(img) => this.hideModalProjectCover(img)}
                />
            )
        }
    }

    renderModalLogoImage() {
        if (this.state.modalProjectLogoVisible) {
            return (
                <ModalCreateProjectImages
                    visible={this.state.modalProjectLogoVisible}
                    image={null}
                    onClose={(img) => this.hideModalProjectLogo(img)} />
            )
        }
    }

    renderCoverImage() {
        return (
            <>
                <div className="p-cover-wrapper">
                    <Image src={this.state.projectCoverPreview ? this.state.projectCoverPreview : "/assets/img/floriane-vita.png"} className="p-cover-img"></Image>
                    <div className="btn btn-default btn-trans btn-changecover" onClick={() => this.setState({ modalProjectCoverVisible: true })}>
                        <FormattedMessage id="projects.change.cover" defaultMessage="Change Project cover image" />
                    </div>
                </div>
                {this.renderModalCoverImage()}
            </>
        )
    }

    renderProjectLogoImage() {
        return (
            <>
                <div className="p-logo-wrapper">
                    <Image src={this.state.projectLogoPreview ? this.state.projectLogoPreview : "/assets/img/default-project.png"} className="p-logo-img"></Image>
                    <div className="p-logo-overlay" onClick={() => this.setState({ modalProjectLogoVisible: true })}>
                        <Image src="/assets/img/svg/camera.svg" fluid onClick={() => this.setState({ modalProjectLogoVisible: true })}></Image>
                        <div className="" onClick={() => this.setState({ modalProjectLogoVisible: true })}>
                            <FormattedMessage id="common.upload" defaultMessage="Upload" />
                        </div>
                    </div>
                </div>
                {this.renderModalLogoImage()}
            </>
        )
    }

    render() {
        return (
            <>
                <Jumbotron className="jumbo-project-create">
                    <div>
                        <div className="create-title">
                            <FormattedMessage id="browse.common.createProject" defaultMessage="Create Project" />
                        </div>
                        <div className="create-steps">
                            <div className="connecting-line"></div>
                            <div className="step-item">
                                <div className="step-step step-active">1</div>
                                <div className="step-info">
                                    <FormattedMessage id="browse.project.project_details" defaultMessage="Project Details" />
                                </div>
                            </div>
                            <div className="step-item">
                                <div className="step-step">2</div>
                                <div className="step-info">
                                    <FormattedMessage id="my_projects.add_tasks" defaultMessage="Add Tasks" />
                                </div>
                            </div>
                            <div className="step-item">
                                <div className="step-step">3</div>
                                <div className="step-info">
                                    <FormattedMessage id="my_projects.preview" defaultMessage="Preview and publish" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Jumbotron>

                <Container className="create-content-container mt-4">

                    <Card className="project-create-container">

                        <div className="project-create-header">
                            <div>
                                <FormattedMessage id="browse.project.project_details" defaultMessage="Project Details" />
                            </div>
                        </div>

                        <div className="project-create-body">

                            {this.renderCoverImage()}

                            {this.renderProjectLogoImage()}

                            <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                                <Form.Group>
                                    <Form.Label>
                                        <FormattedMessage id="projects.image" defaultMessage="Project Image" />
                                    </Form.Label><br />
                                    <small>
                                        <FormattedMessage id="projects.image_desc" defaultMessage="Make your project more attractive by adding a cover photo and profile image/logo." />
                                    </small>
                                </Form.Group>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="formProjectName">
                                        <Form.Label>
                                            <FormattedMessage id="forms.labels.project_name" defaultMessage="Project name" />
                                        </Form.Label>
                                        <Form.Control type="text" onChange={this.handleChange('name')} required />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formProjectCategory">
                                        <Form.Label>
                                            <FormattedMessage id="forms.labels.category" defaultMessage="Category" />
                                        </Form.Label>
                                        <Form.Control as="select" onChange={this.handleChange('category')} value={this.state.category} required >
                                            {this.props.projectCategories ? this.props.projectCategories.results.map((cat) => <option key={cat.id} value={cat.id}>{cat.name}</option>) : ''}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Group controlId="formProjectShortDescription">
                                    <Form.Label>
                                        <FormattedMessage id="forms.labels.short_description" defaultMessage="Short Description" /> ({500 - this.state.short_description.length})
                                    </Form.Label>
                                    {/* <Form.Control as="textarea" rows="2" value={this.state.short_description} onChange={this.handleChange('short_description')} ref={ref => this.textareaShortDescription = ref} required /> */}
                                    <AutosizeTextarea rows={2} value={this.state.short_description} input="short_description" handleChange={(input, event) => this.textAreaValueChanged(input, event)} />
                                </Form.Group>

                                <Form.Group controlId="formProjectDescription">
                                    <Form.Label>
                                        <FormattedMessage id="forms.labels.description" defaultMessage="Description" /> ({5000 - this.state.description.length})
                                    </Form.Label>
                                    {/* <Form.Control as="textarea" rows="5" value={this.state.description} onChange={this.handleChange('description')} ref={ref => this.textareaDescription = ref} required /> */}
                                    <AutosizeTextarea rows={5} value={this.state.description} input="description" handleChange={(input, event) => this.textAreaValueChanged(input, event)} />
                                </Form.Group>

                                <Form.Group style={{ width: '100%' }}>
                                    <Form.Label>
                                        <FormattedMessage id="forms.labels.documents" defaultMessage="Documents" />
                                    </Form.Label>
                                    <UploadDocuments onFilesChanged={this.onFilesChanged} />
                                </Form.Group>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="formProjectDuration">
                                        <Form.Label>
                                            <FormattedMessage id="forms.labels.estimated_start_end" defaultMessage="Estimated start/end date" />
                                        </Form.Label><br />
                                        <DateRangePicker
                                            startDate={this.state.startDate}
                                            startDateId="projectStartDate"
                                            endDate={this.state.endDate}
                                            endDateId="projectEndDate"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            endDatePlaceholderText={"End date"}
                                            startDatePlaceholderText={"Start date"}
                                            displayFormat={"DD-MM-YYYY"}
                                            required
                                        />
                                        {this.state.dateInvalid && <div><small className="text-danger">Please select the date range for this project.</small></div>}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formProjectBudget">
                                        <Form.Label>
                                            <FormattedMessage id="projects.estimated_budget" defaultMessage="Estimated budget" /> ($)
                                        </Form.Label>
                                        <Form.Control type="number" min="1" onChange={this.handleChange('estimated_budget')} required />
                                    </Form.Group>

                                </Form.Row>

                                <Form.Group>
                                    <Form.Label as="legend">
                                        <FormattedMessage id="forms.labels.visibility" defaultMessage="Visibility" />
                                    </Form.Label>
                                    <div className="visibility-wrapper">
                                        <Form.Check inline type="radio" value="PUBLIC" onChange={this.handleChange('visibility')} label={langService.messages['common.public']} name="formProjectRadios" id="formProjectRadio1" defaultChecked />
                                        <Form.Check inline type="radio" value="PRIVATE" onChange={this.handleChange('visibility')} label={langService.messages['common.private']} name="formProjectRadios" id="formProjectRadio2" />
                                    </div>
                                </Form.Group>

                                <p></p>
                                <p></p>

                                <button type="submit" className="btn btn-default btn-generic float-right">
                                    <FormattedMessage id="common.save_and_continue" defaultMessage="Save and Continue" />
                                </button>
                            </Form>

                        </div>

                    </Card>

                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        projectCategories: state.projectCategory.list,
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetProjectCategoriesStart,
    CreateProjectStart
})(ProjectCreate));