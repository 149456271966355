import React from 'react';
import './modalViewSubmitedWork.scss';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Image } from 'react-bootstrap';
import { CompleteSubmitTaskStart } from '../../core/actions/tasks';
import { getFileExtension, getFileName, contains, downloadFile } from '../../utils/common';
import { SelectTaskDisputeSettlement } from '../../core/actions/taskDisputeSettlement';

const customStyles = {
    height: 'fit-content',
    bottom: 'auto',
    top: '15%'
};

class ModalViewSubmitedWork extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            arrayOfCategoryRatings: [],
            review: '',
            btnSaveDisabled: true,
        };
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    downloadFile = (url) => {
        downloadFile(url);
    }

    dispute() {
        this.props.SelectTaskDisputeSettlement(this.props.task.id, false).then(() => history.push('/resolution-center'));
    }

    confirm(repeat) {
        this.props.CompleteSubmitTaskStart(this.props.task.id, repeat ? 'COMPLETE_CONTINUE' : 'COMPLETE');
    }

    render() {
        const { visible, user } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={690}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        <FormattedMessage id="rating.review_work" defaultMessage="Review Submitted Work" />
                    </div>

                    <div className="modal-body p-2 pt-4">

                        <div className="form-label mb-1">
                            <FormattedMessage id="common.comment" defaultMessage="Comment" />
                        </div>

                        <div className="submited-user">
                            <div className="action-avatar-wrapper">
                                <Image src={user.profile_image ? user.profile_image : '/assets/img/default-avatar.png'} className="action-avatar" fluid></Image>
                                <div className="name-is-working">{user.name}</div>
                            </div>
                        </div>
                        <div className="user-work-comment">
                            {this.props.task?.results[0]?.name}
                        </div>

                        <div className="form-label">
                            <FormattedMessage id="forms.labels.documents" defaultMessage="Documents" />
                        </div>
                        <div className="attachments mb-4">
                            {this.props.task?.results[0]?.documents?.map((doc, i) =>
                                <div className="attachment-box" key={i}>
                                    <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? 'generic' : getFileExtension(doc.document)}`}></div>
                                    <div className="download-link" onClick={() => this.downloadFile(doc.document)}>{getFileName(doc.document)}</div>
                                </div>
                            )}
                        </div>

                        <div className="modal-submit-buttons">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.hide()}>
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.dispute()}>
                                <FormattedMessage id="rating.open_dispute" defaultMessage='Open dispute' />
                            </div>
                            <div className="btn btn-default btn-projects-inv mr-2" onClick={() => this.confirm(false)}>
                                <FormattedMessage id="rating.confirm_and_pay" defaultMessage='Confirm and Pay' />
                            </div>
                            {this.props.task.recurring &&
                                <div className="btn btn-default btn-projects-inv mr-2" onClick={() => this.confirm(true)}>
                                    <FormattedMessage id="rating.confirm_and_pay_repeat" defaultMessage='Pay and Repeat' />
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </Rodal>
        )
    }

}


export default injectIntl(connect(null, {
    CompleteSubmitTaskStart,
    SelectTaskDisputeSettlement
})(ModalViewSubmitedWork));