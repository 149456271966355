import { GET_TASK_CATEGORIES_SUCCESS } from '../../actions/taskCategories/types'

const INITIAL_STATE = {
    list: {
        count: 0,
        next: null,
        previous: null,
        results: [ ]
    }    
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case GET_TASK_CATEGORIES_SUCCESS:
            return{ ...state, list: action.payload.list };
        default:
            return state;
    }
}