import React from 'react'
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import './index.scss';


const ThemePage = () => (
    <>
        <Container className="pt-5">
            <h2>ECTA Theme</h2>
            <p></p>

            <h3>Colors</h3>
            <div className="flex-container">
                <div className="div1">$color-projects</div>
                <div className="div2">$color-developers</div>
                <div className="div3">$color-employers</div>
                <div className="div4">$color-educationals</div>
                <div className="div5">$color-auditors</div>
                <div className="div6">$color-headlines</div>
                <div className="div7">$color-text</div>
                <div className="div8">$color-placeholder</div>
                <div className="div9">$color-borders</div>
                <div className="div10">$color-lighthover</div>
                <div className="div11">$color-background</div>
                <div className="div12">$color-danger</div>
                <div className="div13">$color-success</div>
            </div>

            <h3>Buttons</h3>
            <div className="flex-container">
                <button className="btn btn-default btn-projects">Projects</button>
                <button className="btn btn-default btn-developers">Freelancers</button>
                <button className="btn btn-default btn-employers">Clients</button>
                <button className="btn btn-default btn-auditors">Auditors</button>
                <button className="btn btn-default btn-educationals">Schools</button>
                <button className="btn btn-default btn-disabled">Disabled</button>
            </div>
            
            <p></p>

            <h3>Typography</h3>
            <div>
                <p></p>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6>
                
                <p></p>

                <h4>Paragraph</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

                <p></p>

                <h4>Small text</h4>
                <small>Small text. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</small>
                
                <p></p>
                <p></p>
            </div>

            <h3>Font icons (icomoon.io, /assets/fonts/ecta)</h3>
            <div style={{"fontSize": "32px", "display": "flex", "flexWrap": "wrap"}}>
                <div className="tico">
                    <span className="icon-audit"></span>
                    <span> icon-audit</span>
                </div>
                <div className="tico">
                    <span className="icon-dashboard"></span>
                    <span> icon-dashboard</span>
                </div>
                <div className="tico">
                    <span className="icon-developers"></span>
                    <span> icon-developers</span>
                </div>
                <div className="tico">
                    <span className="icon-educational_institutions"></span>
                    <span> icon-educational_institutions</span>
                </div>
                <div className="tico">
                    <span className="icon-email"></span>
                    <span> icon-email</span>
                </div>
                <div className="tico">
                    <span className="icon-investor"></span>
                    <span> icon-investor</span>
                </div>
                <div className="tico">
                    <span className="icon-my_clients"></span>
                    <span> icon-my_clients</span>
                </div>
                <div className="tico">
                    <span className="icon-projects"></span>
                    <span> icon-projects</span>
                </div>
                <div className="tico">
                    <span className="icon-search"></span>
                    <span> icon-search</span>
                </div>
                <div className="tico">
                    <span className="icon-settings"></span>
                    <span> icon-settings</span>
                </div>
                <div className="tico">
                    <span className="icon-back_arrow"></span>
                    <span> icon-back_arrow</span>
                </div>
                <div className="tico">
                    <span className="icon-experience"></span>
                    <span> icon-experience</span>
                </div>
                <div className="tico">
                    <span className="icon-arrow-up"></span>
                    <span> icon-arrow-up</span>
                </div>
                <div className="tico">
                    <span className="icon-arrow-down"></span>
                    <span> icon-arrow-down</span>
                </div>
                <div className="tico">
                    <span className="icon-xls"></span>
                    <span> icon-xls</span>
                </div>
                <div className="tico">
                    <span className="icon-ppt"></span>
                    <span> icon-ppt</span>
                </div>
                <div className="tico">
                    <span className="icon-png"></span>
                    <span> icon-png</span>
                </div>
                <div className="tico">
                    <span className="icon-pdf"></span>
                    <span> icon-pdf</span>
                </div>
                <div className="tico">
                    <span className="icon-jpg"></span>
                    <span> icon-jpg</span>
                </div>
                <div className="tico">
                    <span className="icon-generic_attachment"></span>
                    <span> icon-generic_attachment</span>
                </div>
                <div className="tico">
                    <span className="icon-docx"></span>
                    <span> icon-docx</span>
                </div>
                <div className="tico">
                    <span className="icon-send"></span>
                    <span> icon-send</span>
                </div>
                <div className="tico">
                    <span className="icon-profile-info"></span>
                    <span> icon-profile-info</span>
                </div>
                <div className="tico">
                    <span className="icon-image"></span>
                    <span> icon-image</span>
                </div>
                <div className="tico">
                    <span className="icon-history"></span>
                    <span> icon-history</span>
                </div>
                <div className="tico">
                    <span className="icon-high_five"></span>
                    <span> icon-high_five</span>
                </div>
                <div className="tico">
                    <span className="icon-good-job"></span>
                    <span> icon-good-job</span>
                </div>
                <div className="tico">
                    <span className="icon-delete"></span>
                    <span> icon-delete</span>
                </div>
                <div className="tico">
                    <span className="icon-camera"></span>
                    <span> icon-camera</span>
                </div>
                <div className="tico">
                    <span className="icon-attachment"></span>
                    <span> icon-attachment</span>
                </div>
                <div className="tico">
                    <span className="icon-blockchain"></span>
                    <span> icon-blockchain</span>
                </div>
                <div className="tico">
                    <span className="icon-brain"></span>
                    <span> icon-brain</span>
                </div>
                <div className="tico">
                    <span className="icon-budget"></span>
                    <span> icon-budget</span>
                </div>
                <div className="tico">
                    <span className="icon-calendar_timeframe"></span>
                    <span> icon-calendar_timeframe</span>
                </div>
                <div className="tico">
                    <span className="icon-contact_us"></span>
                    <span> icon-contact_us</span>
                </div>
                <div className="tico">
                    <span className="icon-facebook-logo"></span>
                    <span> icon-facebook-logo</span>
                </div>
                <div className="tico">
                    <span className="icon-information"></span>
                    <span> icon-information</span>
                </div>
                <div className="tico">
                    <span className="icon-manage_account"></span>
                    <span> icon-manage_account</span>
                </div>
                <div className="tico">
                    <span className="icon-network"></span>
                    <span> icon-network</span>
                </div>
                <div className="tico">
                    <span className="icon-open_tasks"></span>
                    <span> icon-open_tasks</span>
                </div>
                <div className="tico">
                    <span className="icon-questions"></span>
                    <span> icon-questions</span>
                </div>
                <div className="tico">
                    <span className="icon-social-care"></span>
                    <span> icon-social-care</span>
                </div>
                <div className="tico">
                    <span className="icon-star"></span>
                    <span> icon-star</span>
                </div>
                <div className="tico">
                    <span className="icon-stopwatch"></span>
                    <span> icon-stopwatch</span>
                </div>
                <div className="tico">
                    <span className="icon-nitifications"></span>
                    <span> icon-nitifications</span>
                </div>
                <div className="tico">
                    <span className="icon-edit"></span>
                    <span> icon-edit</span>
                </div>
            </div>

            <p></p>
            <p></p>

            <h3>Components</h3>
            {/* <ProfileSwitcherControl /> */}
            <>
                <div className="switch-header">
                    <span>Quick switch between my profiles</span>
                    <span className="icon-settings"></span>
                </div>
                <div className="switch-container">
                    <div className="switch-item item-dev item-dev-active">
                        <span className="dev-usercon">JB</span>
                        <div className="item-content">
                            <span>Johnny Bravo</span>
                            <span>Developer</span>
                        </div>
                    </div>
                    <div className="switch-item item-inv item-inv-active">
                        <div className="inv-usercon">CN</div>
                        <div className="item-content">
                            <span>Chuck Norris</span>
                            <span>Investor</span>
                        </div>
                    </div>
                    <div className="switch-item item-aud item-aud-active">
                        <span className="aud-usercon">DD</span>
                        <div className="item-content">
                            <span>Dick Dickson</span>
                            <span>Auditor</span>
                        </div>
                    </div>
                    <div className="switch-item item-edu item-edu-active">
                        <span className="edu-usercon">SE</span>
                        <div className="item-content">
                            <span>Scientology Edu</span>
                            <span>Educational institution</span>
                        </div>
                    </div>
                </div>
            </>
        </Container>
    </>
)

export default connect(null, {})(ThemePage);