import * as types from './types';
import { apiCon } from '../../../API';

//----------------------
// Actions Create Document
//----------------------
export const CreateDocumentsStart = (files = [], project, task) => async dispatch => {
    dispatch({ type: types.CREATE_DOCUMENTS_REQUEST });
    const promises = files.map(file => {
        const formData = new FormData();
        formData.append('document', file);
        if (project)
            formData.append('project', project);
        else if (task)
            formData.append('task', task);
        const address = `/documents/`;
        const config = { headers: { 'content-type': 'multipart/form-data' } }
        return apiCon.post(address, formData, config).then(response => response.data)
    })
    Promise.all(promises).then(array => {
        dispatch(CreateDocumentsSuccess(array))
    }).catch(err => {
        dispatch(CreateDocumentsFail(err.response));
    });
}
export const CreateDocumentsSuccess = (data) => {
    const succ_msg = "Documents created successfully!";
    const payload = { succ_msg: succ_msg, selected: data };
    return { type: types.CREATE_DOCUMENTS_SUCCESS, payload: payload };
}
export const CreateDocumentsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: err_msg, status: status };
    return { type: types.CREATE_DOCUMENTS_FAILURE, payload: payload };
}

//----------------------
// Actions Delete Document
//----------------------
export const DeleteDocumentStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_DOCUMENT_REQUEST });
    const address = '/document/' + id;
    await apiCon.delete(address)
        .then(response => {
            dispatch(DeleteDocumentSuccess(id));
        })
        .catch(err => {
            dispatch(DeleteDocumentFail(err.response));
        });
}
export const DeleteDocumentSuccess = (id) => {
    const succ_msg = "Document deleted successfully!";
    const payload = { succ_msg: succ_msg, deleted: id };
    return { type: types.DELETE_DOCUMENT_SUCCESS, payload: payload };
}
export const DeleteDocumentFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status };
    return { type: types.DELETE_DOCUMENT_FAILURE, payload: payload };
}
