import userRoleReducer from './userRoleReducer';
import {
    GET_USER_REQUEST, GET_USER_FAILURE, GET_USER_SUCCESS, LOGIN_REQUEST, LOGIN_SUCCESS,
    LOGIN_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE,
    LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE, GET_PROFILE_COMPLETION_SUCCESS, ACTIVATE_EMAIL_SUCCESS
} from '../../../actions/auth/types'
import {
    UPDATE_ROLE_SUCCESS, CREATE_ROLE_SUCCESS, UPLOAD_ROLE_PROFILE_PICTURE_SUCCESS,
    UPLOAD_ROLE_COVER_PICTURE_SUCCESS, CHANGE_ACTIVE_ROLE_SUCCESS,
    GET_MY_ROLE_DETAIL_SUCCESS, GET_ROLE_SUCCESS, UPDATE_ROLE_DETAILS_SUCCESS, DELETE_ROLE_SUCCESS
} from '../../../actions/role/types';
import { DELETE_EDUCATION_SUCCESS, UPDATE_EDUCATION_SUCCESS, CREATE_EDUCATION_SUCCESS, SELECT_EDUCATION_SUCCESS, GET_MYVERIFICATIONREQUESTS_SUCCESS } from '../../../actions/education/types'
import { DELETE_EXPERIENCE_SUCCESS, UPDATE_EXPERIENCE_SUCCESS, CREATE_EXPERIENCE_SUCCESS, SELECT_EXPERIENCE_SUCCESS } from '../../../actions/experience/types'
import { GET_MY_RATING_SUCCESS, GET_MY_REVIEWS_SUCCESS } from '../../../actions/rating/types';


const INITIAL_STATE = {
    isInitialized: false,
    email: '',
    isEmailVerified: null,
    settings: userRoleReducer,
    roles: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return { ...state, isInitialized: false }
        case LOGIN_SUCCESS:
            return {
                ...state, ...action.payload.user, isInitialized: true,
                settings: userRoleReducer(state.settings, action)
            }
        case LOGIN_FAILURE:
            return { ...state, isInitialized: true }
        case GET_USER_REQUEST:
            return { ...state, isInitialized: false }
        case GET_USER_SUCCESS:
            return {
                ...state, ...action.payload.user, isInitialized: true,
                settings: userRoleReducer(state.settings, action)
            }
        case GET_USER_FAILURE:
            return { ...state, isInitialized: true }
        case REGISTER_REQUEST:
            return { ...state, isInitialized: false }
        case REGISTER_SUCCESS:
            return {
                ...state, ...action.payload.user, isInitialized: true,
                settings: userRoleReducer(state.settings, action)
            }
        case REGISTER_FAILURE:
            return { ...state, isInitialized: true }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case ACTIVATE_EMAIL_SUCCESS:
            return { ...state, isEmailVerified: true }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        //Role
        case GET_ROLE_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case CREATE_ROLE_SUCCESS:
            return {
                ...state, roles: [...state.roles, action.payload.role],
                settings: userRoleReducer(state.settings, action)
            }
        case DELETE_ROLE_SUCCESS:
            return {
                ...state, roles: state.roles.filter(x => x.id !== action.payload.deleted)
            }
        case UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                roles: state?.roles?.map(role =>
                    role.id === action.payload.role.id ?
                        action.payload.role : role),
                settings: userRoleReducer(state.settings, action)
            };
        case UPLOAD_ROLE_PROFILE_PICTURE_SUCCESS:
            return {
                ...state,
                roles: state?.roles?.map(role =>
                    role.id === action.payload.role.id ?
                        action.payload.role : role),
                settings: userRoleReducer(state.settings, action)
            };
        case UPLOAD_ROLE_COVER_PICTURE_SUCCESS:
            return {
                ...state,
                roles: state?.roles?.map(role =>
                    role.id === action.payload.role.id ?
                        action.payload.role : role),
                settings: userRoleReducer(state.settings, action)
            }
        case UPDATE_ROLE_DETAILS_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case GET_MY_ROLE_DETAIL_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case GET_PROFILE_COMPLETION_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        //Rating
        case GET_MY_RATING_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case GET_MY_REVIEWS_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        //Experience
        case CREATE_EXPERIENCE_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case UPDATE_EXPERIENCE_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case DELETE_EXPERIENCE_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case SELECT_EXPERIENCE_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        //Education
        case CREATE_EDUCATION_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case UPDATE_EDUCATION_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case DELETE_EDUCATION_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case SELECT_EDUCATION_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        case GET_MYVERIFICATIONREQUESTS_SUCCESS:
            return {
                ...state,
                settings: userRoleReducer(state.settings, action)
            }
        default:
            return state;
    }
}

