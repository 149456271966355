import React from 'react';
import '../../containers/Profile/index.scss';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalEditFreelancerCompetences from './modalEditFreelancerCompetences';
import groupBy from 'lodash/groupBy'
import history from '../../utils/routes/history';
import ReactTooltip from 'react-tooltip';


class ProfileCompetenciesSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalFreelancerCompetencesVisible: false
        };
    }

    renderEarned() {
        const { role } = this.props;
        const group = groupBy(role.user_competences, 'issuer.name');
        return (
            <>
                <div className="c-section-title">
                    <FormattedMessage id="common.earned" defaultMessage="EARNED" /> &nbsp;
                    <div className="info-icon-wrap">
                        <img src="/assets/img/icon/info.png" data-tip data-for='earned' alt="info-icon" width="13"></img>
                    </div>
                </div>
                <ReactTooltip id="earned" place="right" type="dark" effect="solid">
                    <div className="tooltip-txt">
                        <FormattedMessage id="tooltip.earned_competencies" defaultMessage="Competencies the user was awarded either by completing tasks or through a verified educational program." />
                    </div>
                </ReactTooltip>
                {Object.keys(group).map((c, i) =>
                    <div key={i} className="mb-2">
                        <div className="c-group-title">
                            <FormattedMessage id="issuers.issued_by" defaultMessage="Issued by" />
                            <span className="clink" onClick={() => history.push('/user/' + c.owner.id)}>{c.toUpperCase()}</span>
                        </div>
                        <div className="card-tags">
                            {group[c].map((grp, x) => <span onClick={() => history.push('/competency/' + grp.competence.id)} key={x}>{grp.issuer_competence_name} L{grp.competence?.level}</span>)}
                        </div>
                    </div>
                )}
                <hr />
            </>
        )
    }

    render() {
        const { role } = this.props;
        return (
            <>
                <div className="card p-0">
                    <span className="icon-edit" onClick={() => this.setState({ modalFreelancerCompetencesVisible: true })}></span>
                    <div className="card-head"><FormattedMessage id="profile.competences" defaultMessage="Competences" />{(role.user_competences?.length || role.competences?.length) > 0 && <span className=""> ({role.user_competences?.length + role.competences?.length})</span>}</div>
                    <div className="card-body">

                        {/* EARNED from completed tasks and confirmed educations */}
                        {role.user_competences?.length > 0 && this.renderEarned()}

                        {/* USE DEFINED */}
                        {role.competences?.length > 0
                            ?
                            <>
                                <div className="c-section-title">
                                    <FormattedMessage id="common.user_defined" defaultMessage="USER DEFINED" /> &nbsp;
                                    <div className="info-icon-wrap">
                                        <img src="/assets/img/icon/info.png" data-tip data-for='user-defined' alt="info-icon" width="13"></img>
                                    </div>
                                </div>
                                <ReactTooltip id="user-defined" place="right" type="dark" effect="solid">
                                    <div className="tooltip-txt">
                                        <FormattedMessage id="tooltip.userdefined_competencies" defaultMessage="Competencies added to his profile by the user himself or herself." />
                                    </div>
                                </ReactTooltip>
                                <div className="card-tags">
                                    {role.competences.map((c, i) => <span onClick={() => history.push('/competency/' + c.id)} key={i}>{c.name} L{c.level}</span>)}
                                </div>
                            </>
                            :
                            <div className="p-section empty-section">
                                <div className="">
                                    <Image src="/assets/img/svg/competences.svg" className="section-img"></Image>
                                </div>
                                <div className="">
                                    <div className=""><FormattedMessage id="competences.add.desc" defaultMessage="Add your competences and we will suggest the perfect projects for you!" /></div>
                                    <div className="add-link" onClick={() => this.setState({ modalFreelancerCompetencesVisible: true })}><FormattedMessage id="competences.add" defaultMessage="Add Competences" /></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.state.modalFreelancerCompetencesVisible &&
                    <ModalEditFreelancerCompetences
                        obj_competences={role.competences}
                        visible={this.state.modalFreelancerCompetencesVisible}
                        onClose={() => this.setState({ modalFreelancerCompetencesVisible: false })} />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        // role: state.user.info.settings.active_role_obj,
    }
}

export default injectIntl(connect(mapStateToProps, {
})(ProfileCompetenciesSection));