import * as types from './types';
import { apiCon } from '../../../API'
import { langService } from '../../../utils/languageService';
import { GetProfileCompletionStart } from '../auth';

//----------------------
// Actions Select Education
//----------------------
export const SelectEducationStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_EDUCATION_REQUEST });
    await apiCon.get('/me/role/detail/education/' + id)
        .then(response => {
            dispatch(SelectEducationSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectEducationFail(err.response));
        })
}
export const SelectEducationSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received profile Education info.", selected: data }
    dispatch({ type: types.SELECT_EDUCATION_SUCCESS, payload: payload });
}
export const SelectEducationFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select Education failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Select Education failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_EDUCATION_FAILURE, payload: payload };
}

//----------------------
// Actions Create Education
//----------------------
export const CreateEducationStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_EDUCATION_REQUEST });
    await apiCon.post(`/me/role/detail/educations/`, values)
        .then(response => {
            dispatch(CreateEducationSuccess(response.data));
            dispatch(GetProfileCompletionStart());
        })
        .catch(err => {
            dispatch(CreateEducationFail(err.response));
        })
}

export const CreateEducationSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully created Education.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, education: data };
    dispatch({ type: types.CREATE_EDUCATION_SUCCESS, payload: payload });
}

export const CreateEducationFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Adding experience failed. Please check your data and try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.CREATE_EDUCATION_FAILURE, payload: payload };
}

//----------------------
// Actions Update Education
//----------------------
export const UpdateEducationStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_EDUCATION_REQUEST });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `/me/role/detail/education/` + values.id;
    delete values['id']
    const json = JSON.stringify(values);
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateEducationSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateEducationFail(err.response));
        })
}

export const UpdateEducationSuccess = (data) => {
    const succ_msg = "Successfully updated Education.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, education: data };
    return { type: types.UPDATE_EDUCATION_SUCCESS, payload: payload };
}

export const UpdateEducationFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.education.update.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_EDUCATION_FAILURE, payload: payload };
}

//----------------------
// Actions Delete Education
//----------------------
export const DeleteEducationStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_EDUCATION_REQUEST });
    const address = `/me/role/detail/education/` + id;
    await apiCon.delete(address)
        .then((response) => {
            dispatch(DeleteEducationSuccess(id));
            dispatch(GetProfileCompletionStart());
        })
        .catch(err => {
            dispatch(DeleteEducationFail(err.response));
        })
}

export const DeleteEducationSuccess = (id) => {
    const succ_msg = "Successfully deleted Education.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_EDUCATION_SUCCESS, payload: payload };
}

export const DeleteEducationFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.education.delete.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_EDUCATION_FAILURE, payload: payload };
}


//----------------------
// Actions Get Education Verification Requests
//-----------------------
export const GetEduVerificationRequestsStart = (status, limit = 100, search, ordering, starts_with, applicant) => async dispatch => {
    dispatch({ type: types.GET_EDUCATION_VERIFICATION_REQUESTS });
    const params = {
        'limit': limit,
        ...(search ? { 'search': search } : {}),
        ...(ordering ? { 'ordering': ordering } : {}),
        ...(starts_with ? { 'starts_with': starts_with } : {}),
        ...(status ? { 'status': status } : {}), // PENDING = 1 CANCEL = 2 APPROVED = 3 REJECTED = 4
        ...(applicant ? { 'applicant': applicant } : {})
    }
    await apiCon.get('/edu/education/requests/', { params })
        .then(response => {
            dispatch(GetEduVerificationRequestsSuccess(response.data, status));
        }
        )
        .catch(err => {
            dispatch(GetEduVerificationRequestsFail(err.response));
        })
}
export const GetEduVerificationRequestsSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received verification requests.", data }
        dispatch({ type: types.GET_EDUCATION_VERIFICATION_REQUESTS_SUCCESS, payload: payload });
}
export const GetEduVerificationRequestsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Get verification requests failed."
    if (err.data.error_first_message) { toast_msg = "Get verification requests failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_EDUCATION_VERIFICATION_REQUESTS_FAILURE, payload: payload };
}

//----------------------
// Actions Get Education Verification Request Details
//-----------------------
export const GetEduVerificationRequestDetailsStart = (id) => async dispatch => {
    dispatch({ type: types.GET_EDUCATION_VERIFICATION_REQUEST_DETAILS });
    await apiCon.get('/edu/education/request/' + id)
        .then(response => {
            dispatch(GetEduVerificationRequestDetailsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetEduVerificationRequestDetailsFail(err.response));
        })
}
export const GetEduVerificationRequestDetailsSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received verification request details.", selected: data }
    dispatch({ type: types.GET_EDUCATION_VERIFICATION_REQUEST_DETAILS_SUCCESS, payload: payload });
}
export const GetEduVerificationRequestDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Get verification request details failed.";
    if (err.data.error_first_message) { toast_msg = "Get verification request details failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_EDUCATION_VERIFICATION_REQUEST_DETAILS_FAILURE, payload: payload };
}


//----------------------
// Actions Create Education Verification Request
//-----------------------
export const CreateEducationVerificationRequestStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_EDUCATION_VERIFICATION_REQUEST });
    await apiCon.post(`/me/role/detail/educations/requests/`, values)
        .then(response => {
            dispatch(CreateEducationVerificationRequestSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateEducationVerificationRequestFail(err.response));
        })
}

export const CreateEducationVerificationRequestSuccess = (data) => async dispatch => {
    const succ_msg = "Your request for education verification has been submited.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, education: data };
    dispatch({ type: types.CREATE_EDUCATION_VERIFICATION_SUCCESS, payload: payload });
    dispatch(GetMyEduVerificationRequestsStart());
}

export const CreateEducationVerificationRequestFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Submittin verification request failed. Please check your data and try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.CREATE_EDUCATION_VERIFICATION_FAILURE, payload: payload };
}

//----------------------
// Actions Update Education
//----------------------
export const UpdateEducationVerificationRequestStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = '/edu/education/request/' + values.id;
    delete values['id']
    const json = JSON.stringify(values);
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateEducationVerificationRequestSuccess(response.data));
            dispatch(GetEduVerificationRequestsStart());
        })
        .catch(err => {
            dispatch(UpdateEducationVerificationRequestFail(err.response));
        })
}

export const UpdateEducationVerificationRequestSuccess = (data) => {
    const succ_msg = "Successfully updated request.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, education: data };
    return { type: types.UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS_SUCCESS, payload: payload };
}

export const UpdateEducationVerificationRequestFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Update verification request failed.";
    if (err.data.error_first_message) { toast_msg = "Update verification request failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS_FAILURE, payload: payload };
}


//----------------------
// Actions Get Education Verification Request Details
//-----------------------
export const GetMyEduVerificationRequestsStart = (values) => async dispatch => {
    dispatch({ type: types.GET_MYVERIFICATIONREQUESTS_REQUESTS });
    await apiCon.get('/me/role/detail/educations/requests/', values)
        .then(response => {
            dispatch(GetMyEduVerificationRequestsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyEduVerificationRequestsFail(err.response));
        })
}
export const GetMyEduVerificationRequestsSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my verification requests.", requests: data }
    dispatch ({ type: types.GET_MYVERIFICATIONREQUESTS_SUCCESS, payload: payload });
}
export const GetMyEduVerificationRequestsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Get my verification requests failed.";
    if (err.data.error_first_message) { toast_msg = "Get my verification requests failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MYVERIFICATIONREQUESTS_FAILURE, payload: payload };
}