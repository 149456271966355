import * as types from './types';
import { apiCon } from '../../../API'
import { langService } from '../../../utils/languageService';


// GET /v1/api/eduinstitutions/programs/?edu_id=[edu_id] (Public pregled izobraževalnih programov za neko izobraževalno ustanovo)
// GET /v1/api/edu/programs/ (Pregled izobraževalnih programov, samo za izobraževalne institucije in programskega admina)
// POST /v1/api/edu/programs/ (Dodajanje izobraževalnega programa, samo za izobraževalne institucije in programskega admina)
// GET /v1/api/edu/program/{0} (Pregled posameznega izobraževalnega programa, samo za izobraževalne institucije in programskega admina)
// PATCH /v1/api/edu/program/{0} (Urejanje posameznega izobraževalnega programa, samo za izobraževalne institucije in programskega admina)
// DELETE /v1/api/edu/program/{0} (Brisanje oz. deaktiviranje posameznega izobraževalnega programa, samo za izobraževalne institucije in programskega admina)


//-------------------
//  Get Institution programs (public) 
//-------------------
// GET /v1/api/eduinstitutions/programs/?edu_id=[edu_id] (Public pregled izobraževalnih programov za neko izobraževalno ustanovo)
export const GetEduProgramsStart = () => async dispatch => {
    dispatch({ type: types.GET_INSTITUTION_PROGRAMS_REQUEST });
    await apiCon.get(`eduinstitutions/programs/`)
        .then(response => {
            dispatch(GetEduProgramsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetEduProgramsFail(err.response));
        })
}

export const GetEduProgramsSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved institution programs.";
    const payload = { succ_msg: succ_msg, role: data };
    dispatch({ type: types.GET_INSTITUTION_PROGRAMS_SUCCESS, payload: payload });
}

export const GetEduProgramsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_INSTITUTION_PROGRAMS_FAILURE, payload: payload };
}

//-------------------
//  Get My Institution programs
//-------------------
export const GetMyEduProgramsStart = () => async dispatch => {
    dispatch({ type: types.GET_MY_INSTITUTION_PROGRAMS_REQUEST });
    await apiCon.get(`edu/programs/`)
        .then(response => {
            dispatch(GetMyEduProgramsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetMyEduProgramsFail(err.response));
        })
}

export const GetMyEduProgramsSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved my institution programs.";
    const payload = { succ_msg: succ_msg, list: data };
    dispatch({ type: types.GET_MY_INSTITUTION_PROGRAMS_SUCCESS, payload: payload });
}

export const GetMyEduProgramsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_MY_INSTITUTION_PROGRAMS_FAILURE, payload: payload };
}


//----------------------
// Create Program
//----------------------
export const CreateProgramStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_PROGRAM_REQUEST });
    await apiCon.post(`edu/programs/`, values)
        .then(response => {
            dispatch(CreateProgramSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateProgramFail(err.response));
        })
}

export const CreateProgramSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully created a new program.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, role: data };
    dispatch({ type: types.CREATE_PROGRAM_SUCCESS, payload: payload });
}

export const CreateProgramFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = langService.messages["program.create.error"];
    if (err.data.error_first_message) { toast_msg += err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.CREATE_PROGRAM_FAILURE, payload: payload };
}

//----------------------
// Get Program details
//----------------------
export const GetProgramDetailsStart = (id) => async dispatch => {
    dispatch({ type: types.GET_MY_PROGRAM_REQUEST });
    await apiCon.get(`edu/program/` + id)
        .then(response => {
            dispatch(GetProgramDetailsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetProgramDetailsFail(err.response));
        })
}

export const GetProgramDetailsSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved program details.";
    const payload = { succ_msg: succ_msg, data: data };
    dispatch({ type: types.GET_MY_PROGRAM_SUCCESS, payload: payload });
}

export const GetProgramDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_MY_PROGRAM_FAILURE, payload: payload };
}

//----------------------
// Update Program details
//----------------------
export const UpdateProgramDetailsStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_MY_PROGRAM_REQUEST });
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `edu/program/` + values.id;
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateProgramDetailsSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateProgramDetailsFail(err.response));
        })
}

export const UpdateProgramDetailsSuccess = (data) => {
    const succ_msg = "Successfully updated program details.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, details: data };
    return { type: types.UPDATE_MY_PROGRAM_SUCCESS, payload: payload };
}

export const UpdateProgramDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = langService.messages["program.update.error"];
    if (err.data.error_first_message) { toast_msg += err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_MY_PROGRAM_FAILURE, payload: payload };
}

//----------------------
// Delete Program
//----------------------
export const DeleteProgramStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_MY_PROGRAM_REQUEST });
    const address = 'edu/program/' + id;
    await apiCon.delete(address)
        .then(response => {
            dispatch(DeleteProgramSuccess(id));
        })
        .catch(err => {
            dispatch(DeleteProgramFail(err.response));
        });
}

export const DeleteProgramSuccess = (id) => {
    const succ_msg = "Program deleted successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_MY_PROGRAM_SUCCESS, payload: payload };
}

export const DeleteProgramFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = langService.messages["program.delete.error"];
    if (err.data.error_first_message) { toast_msg += err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_MY_PROGRAM_FAILURE, payload: payload };
}