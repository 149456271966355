import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalFileViewer.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Image } from 'react-bootstrap';
import { downloadFile } from '../../../utils/common';

const customStyles = {
    width: 'inherit',
    height: 'inherit',
    maxWidth: '50%',
    maxHeight: '85%',
    bottom: 'auto',
    top: '8%'
};

class ModalFileViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: this.props.file,
            downloadedFile: null
        };
    }

    componentDidMount() {
        var conf = { method: "GET", mode: 'cors', headers: { 'Authorization': 'Bearer ' + localStorage.getItem('django_access_token'), } };
        fetch(this.props.file, conf).then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                this.setState({ downloadedFile: url });
            });
        });
    }

    hide() {
        this.props.onClose();
    }

    download() {
        downloadFile(this.state.file);
    }

    renderDisplayObject(file) {
        if (!file || !this.state.downloadedFile) return null;

        if (file.includes('.pdf')) {
            return (
                <object className="pdf-embeded" data={this.state.downloadedFile} type="application/pdf">
                    <embed src={this.state.downloadedFile} type="application/pdf" />
                </object>
            )             
        } else if (file.includes('.png') || file.includes('.PNG') || file.includes('.jpg') || file.includes('.jpeg') 
                || file.includes('.bmp') || file.includes('.jfif')) {
            return (
                <Image src={this.state.downloadedFile} fluid></Image>
            )
            // } else if (file.includes('.docx')) {
            //     // const embedUrl = "https://docs.google.com/gview?url=" + this.state.downloadedFile + "&embedded=true";
            //     // const embedUrl = "https://view.officeapps.live.com/op/embed.aspx?src=" + this.state.downloadedFile;
            //     const extUrl = this.state.downloadedFile.substring(5, this.state.downloadedFile.length);
            //     const embedUrl = "https://docs.google.com/gview?url=" + extUrl;
            //     return (
            //         // <iframe className="docx-embeded" style={{"frameborder": "0"}} title="docxPreview" src={embedUrl}></iframe>
            //         // <iframe className="docx-embeded" style={{"frameborder": "0"}} title="docxPreview" src={embedUrl}></iframe>
            //         <a href={embedUrl} target="_blank" rel="noopener noreferrer">Open this file with Google Docs</a>
            //     )
        } else {
            return (
                <p className="mt-4">
                    <FormattedMessage id="common.files.no_preview" defaultMessage={`Cannot preview this file. Please download to view.`} />
                </p>
            )
        }
    }

    render() {
        const { visible, file } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                    
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        <FormattedMessage id="common.files.preview" defaultMessage={`File Viewer`} />
                    </div>

                    <div className="modal-body p-2 pt-4" style={{ "textAlign": "center", "height": "85%" }}>

                        {this.renderDisplayObject(file)}

                        <div className="modal-submit-buttons">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.close" defaultMessage={`Close`} />
                            </div>
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.download()}>
                                <FormattedMessage id="common.download" defaultMessage={`Download`} />
                            </button>
                        </div>

                    </div>

                </div>
            </Rodal>
        )
    }

}

export default injectIntl(connect(null, {})(ModalFileViewer));