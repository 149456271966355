import { GET_LOCATION_SUCCESS } from '../../actions/location/types'

const INITIAL_STATE = {
    city: '',
    country:'',
    countryCode:'',
    region:'',
    zip:''
}

export default (state = INITIAL_STATE, action) =>{
    switch(action.type){
        // case types.GET_LOCATION_REQUEST:
        //     return {...state}
        case GET_LOCATION_SUCCESS:
            return {...state, ...action.payload.data}
        // case types.GET_LOCATION_FAILURE:
        //     return {...INITIAL_STATE}
        default:
            return state;
    }
}