import React, { useState } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';



export function ModalAddDocuments(props) {

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps, open } = useDropzone({
        multiple: true,
        noClick: true,
        noDrag: false,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
            ));
            props.onFilesChanged(acceptedFiles);
        }
    });

    return (
        <Rodal
            visible={props.visible}
            onClose={() => props.onCancel()}
            width={600}
            height={210}
            closeMaskOnClick={false}>
            <div style={{ "padding": "10px" }}>
                <p><FormattedMessage id="forms.labels.add_documents" defaultMessage="Add Documents" /></p>
                <section className="p-uploadfiles-container">
                    <div {...getRootProps({ className: 'dropzone p-files-upload' })}>
                        <input {...getInputProps()} />
                        <div className="drop-container">
                            <button type="button" className="btn-selectfile" onClick={open}>
                                <FormattedMessage id="forms.labels.select_documents" defaultMessage="Select your Documents" />
                            </button>
                            <div>
                                <FormattedMessage id="forms.labels.drag_documents" defaultMessage="Drag 'n' drop some files here or click the button to select" />
                            </div>
                        </div>
                    </div>
                </section>
                <p></p>

                <div className="mp-img-footer">
                    <button className="btn btn-default btn-projects btn-smallpadding mr-2" onClick={() => props.onCancel()}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </button>
                </div>
            </div>
        </Rodal>
    );
}