import React from 'react';
import './index.scss';
import './finished.scss';
import history from '../../../utils/routes/history';
import { connect } from 'react-redux';
import { Container, Image } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';


class ProfileSetupFinishedPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    gotoProfile() {
        history.push("/profile");
    }

    browse() {
        history.push("/browse/projects");
    }

    render() {
        const { completed, role } = this.props;
        return (
            <>
                <Container className="mt-5">

                    <div className="finished-wrapper">

                        <div className="great-job">
                            <FormattedMessage id="setup_profile.finish" defaultMessage="Great job!" />
                        </div>

                        <div className="keep-improving">
                            <FormattedMessage id="setup_profile.finish.desc" defaultMessage="You can keep improving your profile through My Profile page." />
                        </div>

                        <Image src="/assets/img/svg/high_five.svg" className="highfive"></Image>

                        <div className="progress-wrap">
                            <div>
                                <FormattedMessage id="nav.labels.profile_setup" defaultMessage="Profile setup" />
                            </div>
                            <div>{completed}%</div>
                        </div>

                        <div className="profile-setup-progress" style={{ "width": "70%" }}>
                            <div className="progress-wrapper">
                                <div className="setup-progress" style={{ width: completed + '%' }}></div>
                            </div>
                        </div>

                        {role.type === "DEV" &&
                            <div className="btn btn-default btn-developers-inv" onClick={() => this.gotoProfile()}>
                                <FormattedMessage id="setup_profile.profile" defaultMessage="View my profile" />
                            </div>
                        }
                        {role.type === "INV" &&
                            <div className="btn btn-default btn-employers-inv" onClick={() => this.gotoProfile()}>
                                <FormattedMessage id="setup_profile.profile" defaultMessage="View my profile" />
                            </div>
                        }
                        {(role.type === "EDU" || role.type === "EAS" || role.type === "EAP" || role.type === "EAC") &&
                            <div className="btn btn-default btn-educationals-inv" onClick={() => this.gotoProfile()}>
                                <FormattedMessage id="setup_profile.profile" defaultMessage="View my profile" />
                            </div>
                        }
                        {role.type === "AUD" &&
                            <div className="btn btn-default btn-auditors-inv" onClick={() => this.gotoProfile()}>
                                <FormattedMessage id="setup_profile.profile" defaultMessage="View my profile" />
                            </div>
                        }

                    </div>

                    <hr />

                    <div className="ps-content-wrap">

                        {role.type === "DEV" &&
                            <>
                                <div className="ps-title24">
                                    <FormattedMessage id="setup_profile.dev.finished" defaultMessage="We already found some tasks for you!" />
                                </div>
                                <div className="btn btn-default btn-projects-inv" onClick={() => history.push("/browse/tasks")}>
                                    <FormattedMessage id="browse.browse_tasks" defaultMessage="Browse tasks" />
                                </div>
                            </>
                        }

                        {role.type === "INV" &&
                            <>
                                <div className="ps-title24">
                                    <FormattedMessage id="setup_profile.inv.finished" defaultMessage="Browse through existing projects or create a new project." />
                                </div>
                                <div>
                                    <div className="btn btn-default btn-projects-inv mr-2" onClick={() => history.push("/browse/projects")}>
                                        <FormattedMessage id="browse.browse_projects" defaultMessage="Browse projects" />
                                    </div>
                                    <div className="btn btn-default btn-projects-inv" onClick={() => history.push("/project/new")}>
                                        <FormattedMessage id="browse.common.createProject" defaultMessage="Create a new project" />
                                    </div>
                                </div>
                            </>
                        }

                        {role.type === "EDU" &&
                            <>
                                <div className="ps-title24">
                                    <FormattedMessage id="setup_profile.edu.finished" defaultMessage="Browse through educational institutions or go to MyInstitution page." />
                                </div>
                                <div>
                                    <div className="btn btn-default btn-projects-inv mr-2" onClick={() => history.push("/browse/educational-institutions")}>
                                        <FormattedMessage id="browse.browse_edus" defaultMessage="Browse educational institutions" />
                                    </div>
                                    <div className="btn btn-default btn-projects-inv" onClick={() => history.push("/my-institution")}>
                                        <FormattedMessage id="browse.goto_myedu" defaultMessage="Go to My-Institution" />
                                    </div>
                                </div>
                            </>
                        }

                        {role.type === "AUD" &&
                            <>
                                <div className="ps-title24">
                                    <FormattedMessage id="setup_profile.aud.finished" defaultMessage="Browse through auditors or go to MyAudits page." />
                                </div>
                                <div>
                                    <div className="btn btn-default btn-projects-inv mr-2" onClick={() => history.push("/browse/auditors")}>
                                        <FormattedMessage id="browse.browse_auditors" defaultMessage="Browse auditors" />
                                    </div>
                                    <div className="btn btn-default btn-projects-inv" onClick={() => history.push("/my-audits")}>
                                        <FormattedMessage id="browse.goto_myaudits" defaultMessage="Go to My-Audits" />
                                    </div>
                                </div>
                            </>
                        }


                    </div>

                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        completed: state.user.info.settings.active_role_obj?.profile_completion?.quality,
        role: state.user.info.settings.active_role_obj
    }
}

export default injectIntl(connect(mapStateToProps, {

})(ProfileSetupFinishedPage));