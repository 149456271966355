import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalMilestones.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Col } from 'react-bootstrap';
import MilestoneColorPicker from '../Common/MilestoneColorPicker';
import { CreateMilestoneStart, UpdateMilestoneStart, DeleteMilestoneStart } from '../../core/actions/milestone';


const customStyles = {
    width: 'inherit',
    height: 'inherit',
    maxWidth: '75%',
    maxHeight: '70%',
    bottom: 'auto',
    top: '8%'
};


class ModalMilestones extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            milestones: this.props.milestones,
            selectedMilestone: {
                id: 0,
                name: '',
                short_description: '',
                description: '',
                color: 0,
                project: this.props.projectId
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.projectId) {
            this.setState({ ...this.state, selectedMilestone: { ...this.state.selectedMilestone, project: nextProps.projectId}});
        }
        if (nextProps.milestones) {
            this.setState({ ...this.state, milestones: nextProps.milestones});
        }
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        this.setState({ ...this.state, selectedMilestone: { ...this.state.selectedMilestone, [input]: event.target.value } });
    }

    onColorChange = (c) => {
        this.setState({ ...this.state, selectedMilestone: { ...this.state.selectedMilestone, color: c.colorId } });
    }

    select(m) {
        this.setState({ selectedMilestone: m });
    }

    add() {
        this.setState({
            selectedMilestone: {
                project: this.props.projectId,
                id: 0,
                name: '',
                short_description: '',
                description: '',
                color: 0,
                validated: true
            }
        });
    }

    delete(id) {
        this.props.DeleteMilestoneStart(id);
        this.add();
    }

    handleSubmit = event => {
        const form = event.currentTarget;
        // form validation
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }
        this.setState({ validated: true });
    };

    save() {
        if (this.state.selectedMilestone.id === 0) {
            this.props.CreateMilestoneStart(this.state.selectedMilestone);
        } else {
            this.props.UpdateMilestoneStart(this.state.selectedMilestone);
        }
        // this.add();
    }

    renderMilestones(m) {
        if (!m) return null;

        var milestones = m.filter(function (milestone) {
            return milestone.id !== null;
        });

        if (milestones) {
            return (
                <>
                    {milestones.map((m, i) =>
                        <div className="mile-item" key={i} onClick={() => this.select(m)}>
                            <div className="ml-item-body">
                                <div style={{ "width": "10px" }} className={"m" + m.color}></div>
                                <div className="ml-body-content">
                                    <div>{m.name}</div>
                                    <div>{m.short_description}</div>
                                    <div>{m.description}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )
        } else {
            return null;
        }
    }

    render() {
        const { visible } = this.props;
        const { selectedMilestone, milestones } = this.state;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        Project milestones
                    </div>

                    <div className="modal-body p-2 pt-4" style={{ "height": "85%" }}>

                        <div className="btn btn-default btn-projects-inv mb-3" onClick={() => this.add()}>+ Add new</div>

                        <div className="card-h-container">

                            <div className="card p-0 mr-2" style={{ "width": "50%" }}>

                                {this.renderMilestones(milestones?.list)}

                            </div>

                            <div className="card ml-2 pt-3" style={{ "width": "50%" }}>

                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                    <Form.Row>
                                        <Form.Group style={{ "width": "100%" }}>
                                            <Form.Label>
                                                Name
                                                </Form.Label>
                                            <Form.Control type="text" value={this.state.selectedMilestone.name} onChange={this.handleChange('name')} required />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group style={{ "width": "100%" }}>
                                            <Form.Label>
                                                Short description
                                                </Form.Label>
                                            <Form.Control type="text" value={this.state.selectedMilestone.short_description} onChange={this.handleChange('short_description')} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group style={{ "width": "100%" }}>
                                            <Form.Label>
                                                Description
                                                </Form.Label>
                                            <Form.Control as="textarea" rows="2" value={this.state.selectedMilestone.description} onChange={this.handleChange('description')} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group style={{ "width": "100%" }}>
                                            <Form.Label>
                                                Color
                                                </Form.Label>
                                            <MilestoneColorPicker colorPicker={true} selected={selectedMilestone} onColorChange={(c) => this.onColorChange(c)} />
                                        </Form.Group>
                                    </Form.Row>

                                    {this.state.selectedMilestone.id !== 0
                                        ?
                                        <>
                                            <Form.Row>
                                                <div className="delete-milestone" onClick={() => this.delete(this.state.selectedMilestone.id)}>Delete this milestone?</div>
                                            </Form.Row>
                                            <Form.Row className="mt-3">
                                                <Col className="text-right">
                                                    <button type="submit" className="btn btn-default btn-projects-inv">
                                                        <FormattedMessage id="common.update" defaultMessage="Update" />
                                                    </button>
                                                </Col>
                                            </Form.Row>
                                        </>
                                        :
                                        <Form.Row className="mt-3">
                                            <Col className="text-right">
                                                <button type="submit" className="btn btn-default btn-projects-inv">
                                                    <FormattedMessage id="common.create" defaultMessage="Create" />
                                                </button>
                                            </Col>
                                        </Form.Row>
                                    }
                                </Form>
                            </div>

                        </div>


                        <div className="modal-submit-buttons">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.close" defaultMessage="Close" />
                            </div>
                        </div>

                    </div>

                </div>
            </Rodal>
        )
    }

}

export default injectIntl(connect(null, {
    CreateMilestoneStart,
    UpdateMilestoneStart,
    DeleteMilestoneStart
})(ModalMilestones));