import React from 'react';
import './index.scss';
import './selectedProject.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Tabs, Tab } from 'react-bootstrap';
import ProjectOverviewWithEdit from '../../components/Projects/projectOverviewWithEdit';
import Image from 'react-bootstrap/Image'
import ModalEditProjectImage from '../../components/Projects/modalEditProjectImage';
import ModalEditProjectCover from '../../components/Projects/modalEditProjectCover';
import { UpdateProjectStart } from '../../core/actions/projects';
import { langService } from './../../utils/languageService/index';
import Task from './task';
import NoMilestoneArea from './noMilestoneArea';
import MilestoneDropdown from './milestoneDropdown';
import ModalInviteFreelancers from './modalInviteFreelancers';
import { CreateTaskProposalStart } from '../../core/actions/taskProposals';

class MyProjectsSelectedProject extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTabProject: "1",
            modalProjectImageVisible: false,
            modalProjectCoverVisible: false,
            modalMilestonesVisible: false,
            modalInviteFreelancersVisible: false,
            tempTasks: []
        };
    }

    openModalMilestones() {
        this.setState({ modalMilestonesVisible: true });
    }

    hideModalMilestones = () => {
        this.setState({ modalMilestonesVisible: false });
    }

    // Testing DnD
    handleDrop = (taskId, milestoneId) => {
        const tempTasks = [];

        [...this.state.tempTasks].forEach(task => {
            if (task.id === taskId) {
                task.milestone = milestoneId;
                tempTasks.push(task);
            } else {
                tempTasks.push(task);
            }
        });

        this.setState({ tempTasks: tempTasks });
    }

    getTasksForMilestone = milestone => {
        const tempTaskElements = [];

        this.state.tempTasks.forEach(task => {
            if (task.milestone !== milestone)
                return;

            tempTaskElements.push(
                <Task key={task.id} task={task} />
            );
        });

        return tempTaskElements;
    }

    renderMilestoneDropdowns = milestoneList => {
        const milestoneDropdownElements = [];

        if (milestoneList?.length === 1) {
            return milestoneList[0].tasks.map(task => <Task key={task.id} task={task} readOnly={true} />)
        }

        milestoneList.forEach((milestone, y) => {
            if (milestone.id === null) {
                milestoneDropdownElements.unshift(
                    <div key={y}>
                        <NoMilestoneArea
                            milestoneId={null}
                        // dropFn={this.handleDrop}
                        >
                            {milestone?.tasks.map(task => <Task key={task.id} task={task} />)}
                        </NoMilestoneArea>
                    </div>
                );
                return;
            }

            milestoneDropdownElements.push(
                <div key={y}>
                    <MilestoneDropdown
                        milestoneId={milestone.id}
                        milestoneName={milestone.name}
                        milestoneColor={milestone.color}
                    // bgColor="#ff6363"
                    // dropFn={this.handleDrop}
                    >
                        {milestone?.tasks.map(task => <Task key={task.id} task={task} />)}
                    </MilestoneDropdown>
                </div>
            );
        });

        return milestoneDropdownElements;
    }

    renderTestTab() {
        const { selectedProject } = this.props;

        return (
            <div>
                {this.renderMilestoneDropdowns(selectedProject?.milestones?.list)}
            </div>
        );
    }
    // End testing DnD

    hideModalProjectImage = () => {
        this.setState({ modalProjectImageVisible: false });
    }

    hideModalProjectCover = () => {
        this.setState({ modalProjectCoverVisible: false });
    }

    publishProject() {
        this.props.UpdateProjectStart({
            id: this.props.selectedProject.id,
            status: 'PUBLISHED',
        });
    }

    completeProject() {
        this.props.UpdateProjectStart({
            id: this.props.selectedProject.id,
            status: 'COMPLETED',
        });
    }

    isProjectEditable = () => {
        const { selectedProject } = this.props;
        const disabledEdits = ['COMPLETED', 'REJECTED'];

        if (disabledEdits.includes(selectedProject.status))
            return false;

        return true;
    }

    handleInviteFreelancersSubmit = (checkedTasks, selectedFreelancers) => {
        selectedFreelancers.forEach(f => {
            checkedTasks.forEach(t => {
                this.props.CreateTaskProposalStart({
                    freelancer: f.value,
                    task:  t.id,
                    competences: t.competences.map(c => c.id),
                    price: t.price,
                    start_date: t.start_date,
                    end_date: t.end_date
                });
            });
        });
    }

    renderModal() {
        if (this.state.modalProjectImageVisible) {
            return (
                <ModalEditProjectImage visible={this.state.modalProjectImageVisible} image={this.props.selectedProject.logo_image} onClose={() => this.hideModalProjectImage()} />
            )
        }
    }

    renderChangeCoverModal() {
        if (this.state.modalProjectCoverVisible) {
            return (
                <ModalEditProjectCover visible={this.state.modalProjectCoverVisible} image={this.props.selectedProject.cover_image} onClose={() => this.hideModalProjectCover()} />
            )
        }
    }

    renderInfobar() {
        const { selectedProject } = this.props;
        if (selectedProject.status === "DRAFT") {
            return (
                <div className="project-infobar">
                    <div className="project-infobar-text">
                        <FormattedMessage id="my_projects.not_published" defaultMessage="Your Project is not published" />
                    </div>
                    <div className="btn btn-default btn-project-infobar btn-smallpadding" onClick={() => this.publishProject()}>
                        <FormattedMessage id="my_projects.publish" defaultMessage="Publish Project" />
                    </div>
                </div>
            )
        }
        else if (selectedProject.may_be_completed) {
            return (
                <div className="project-infobar">
                    <div className="project-infobar-text">
                        <FormattedMessage id="my_projects.you_can_complete_your_project" defaultMessage="You can complete your project" />
                    </div>
                    <div className="btn btn-default btn-project-infobar btn-smallpadding" onClick={() => this.completeProject()}>
                        <FormattedMessage id="my_projects.complete" defaultMessage="Complete Project" />
                    </div>
                </div>
            )
        }
        else if (selectedProject.visibility === "PRIVATE") {
            return (
                <div className="project-infobar">
                    <div className="project-infobar-text">
                        <FormattedMessage id="my_projects.private" defaultMessage="You Project is private. Invite people to open tasks." />
                    </div>
                </div>
            )
        }
    }

    render() {
        const { selectedProject } = this.props;
        if (!selectedProject.id || !selectedProject.milestones?.list) { return (<></>) };
        return (
            <div className="project-overview">

                <div className="project-overview-cover">
                    {this.renderInfobar()}
                    <Image src={selectedProject.cover_image ? selectedProject.cover_image : "/assets/img/floriane-vita.png"} height="120" className="project-overview-coverimg"></Image>
                    {!selectedProject.may_be_completed && this.isProjectEditable() &&
                        <div className="btn btn-default btn-trans btn-spchangecover" onClick={() => this.setState({ modalProjectCoverVisible: true })}>
                            <FormattedMessage id="profile.change.cover" defaultMessage="Change cover" />
                        </div>
                    }
                </div>

                <div className="project-overview-header">

                    <div className="project-thumb project-img-container" onClick={() => this.isProjectEditable() && this.setState({ modalProjectImageVisible: true })}>
                        {selectedProject.logo_image
                            ?
                            <Image src={selectedProject.logo_image} className="p-thumb" fluid />
                            :
                            <Image src="/assets/img/default-project.png" className="p-thumb" fluid />
                        }
                        {this.isProjectEditable() &&
                            <div className="project-img-overlay">
                                <span className="icon-edit"></span>
                            </div>
                        }
                    </div>

                    {this.renderModal()}
                    {this.renderChangeCoverModal()}
                    { this.state.modalInviteFreelancersVisible && <ModalInviteFreelancers visible={this.state.modalInviteFreelancersVisible} closeFn={() => this.setState({ modalInviteFreelancersVisible: false })} submitFn={this.handleInviteFreelancersSubmit} />}

                    <div className="p-overview-titles">

                        <div className="p-overview-title">
                            <span>{selectedProject.name}</span>
                            <span>{selectedProject.category?.name}</span>
                            <div>
                                <span className={"status-circle status-color-" + selectedProject.status.toLowerCase()}>● </span>
                                <FormattedMessage id={`common.${selectedProject.status.toLowerCase()}`} defaultMessage={selectedProject.status} />
                            </div>
                        </div>

                        <div className="p-overview-btns">
                            {this.isProjectEditable() &&
                                <>
                                <button className="btn btn-default btn-projects" onClick={() => this.props.onAddNewTask()} >
                                    + <FormattedMessage id="my_projects.create_task" defaultMessage="Create Task" />
                                </button>
                                <button className="btn btn-default btn-projects mt-2" onClick={() => this.setState({ modalInviteFreelancersVisible: true })} >
                                    <FormattedMessage id="my_projects.invite_freelancers" defaultMessage="Invite freelancers" />
                                </button>
                                </>
                            }
                        </div>

                    </div>
                </div>

                <div className="project-overview-body">
                    <Tabs defaultActiveKey={this.state.selectedTabProject} className="p-overview-tabs">
                        <Tab eventKey="1" title={langService.messages['my_projects.project_overview']}>
                            <div className="tab-content">
                                <ProjectOverviewWithEdit project={selectedProject} />
                            </div>
                        </Tab>
                        <Tab eventKey="2" title={langService.messages['projects.tasks'] + " (" + selectedProject.number_of_tasks + ")"}>
                            {this.renderTestTab()}
                        </Tab>
                        <Tab eventKey="3" title={langService.messages['browse.developers']}>
                            <div className="tab-content">
                            </div>
                        </Tab>
                        <Tab eventKey="4" title={langService.messages['my_projects.invoices']}>
                            <div className="tab-content">
                            </div>
                        </Tab>
                    </Tabs>
                </div>

                {/* <ModalMilestones projectId={selectedProject.id} milestones={milestones} visible={this.state.modalMilestonesVisible} onClose={this.hideModalMilestones} /> */}

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedProject: state.myWork.myProjects.selected,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateProjectStart,
    CreateTaskProposalStart
})(MyProjectsSelectedProject));