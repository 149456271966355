import { GET_COMPETENCE_PARENTS_SUCCESS, CREATE_COMPETENCE_PARENT_SUCCESS, VOTE_FOR_COMPETENCE_PARENT_SUCCESS, DELETE_VOTE_FOR_COMPETENCE_PARENT_SUCCESS } from "../../actions/competenceParent/types";

const INITIAL_STATE = {
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_COMPETENCE_PARENTS_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? [...state.list.results, ...action.payload.list.results] : action.payload.list.results
                }
            };
        case CREATE_COMPETENCE_PARENT_SUCCESS:
            return {
                ...state,
                list: { ...state.list, results: state?.list?.results?.concat(action.payload.selected) }
            };
        case VOTE_FOR_COMPETENCE_PARENT_SUCCESS:
            return {
                ...state, list: {
                    ...state.list,
                    results: state.list.results.map(x => {
                        return x.id === action.payload.selected.id ? action.payload.selected : x
                    }).sort((a, b) => b.vote_score - a.vote_score)
                }
            };
        case DELETE_VOTE_FOR_COMPETENCE_PARENT_SUCCESS:
            return {
                ...state, list: {
                    ...state.list,
                    results: state.list.results.map(x => {
                        return x.id === action.payload.deleted.id ? action.payload.deleted : x
                    }).sort((a, b) => b.vote_score - a.vote_score)
                }
            };
        default:
            return state;
    }
}