import React from 'react';
import { FormattedRelativeTime } from 'react-intl';
import { durationFromDateRange } from '../../../utils/common/index';

class DateRangeDuration extends React.Component {
    render() {
        const seconds = durationFromDateRange(this.props.start_date, this.props.end_date)
        const weeks = (seconds / 604800) | 0;
        const months = (weeks / 4) | 0;
        const years = (months / 12) | 0;

        if (years !== 0) {
            return <FormattedRelativeTime value={years} unit='year' numeric="auto" />
        } else if (months !== 0) {
            return <FormattedRelativeTime value={months} unit='month' numeric="auto" />
        } else if (weeks !== 0) {
            return < FormattedRelativeTime value={weeks} unit='week' numeric="auto" />
        } else {
            return <FormattedRelativeTime value={seconds} numeric="auto" updateIntervalInSeconds={10} />
        }
    }
}
export default DateRangeDuration;