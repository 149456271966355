import * as types from './types';
import { apiCon } from '../../../API';
import history from '../../../utils/routes/history'
import { GetMyTaskProposalsStart, DeselectMyTaskProposal } from './../taskProposals/index';
import { CreateDocumentsStart } from './../documents/index';
import { GetMyReviewRequestsStart } from './../rating/index';
import { getParameterObject, getUrlParameterObject } from './../../../utils/common/index';
import { INITIAL_STATE_FILTERS } from '../../reducers/browse/tasksReducer';
import { DeselectMyTaskDisputeCase } from '../taskDisputeCases';
import { DeselectTaskDisputeSettlement, SelectTaskDisputeSettlement } from '../taskDisputeSettlement/index';

//----------------------
// Actions Tasks
//----------------------
const ALLOWED_FILTERS = ['limit', 'search', 'ordering', 'price_max', 'price_min', 'date_from', 'date_to', 'status', 'competences', 'project', 'my_tasks']
//----------------------
// Actions Change Tasks List Filters 
//----------------------
export const ChangeTaskFiltersStart = (params = INITIAL_STATE_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_FILTERS);
    const payload = { succ_msg: "Successfully change tasks filters.", filters: filters }
    return { type: types.CHANGE_TASK_FILTERS, payload: payload };
}

//----------------------
// Actions Get Tasks List
//----------------------
export const GetTasksStart = (params = INITIAL_STATE_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_TASKS_REQUEST });
    const url = '/project/tasks/'
    const filters = getUrlParameterObject(params, ALLOWED_FILTERS)
    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetTasksSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetTasksFail(err.response));
        })
}
export const GetTasksChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_TASKS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetTasksSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetTasksFail(err.response));
        })
}
export const GetTasksSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received tasks info.", list: data, add: add }
    return { type: types.GET_TASKS_SUCCESS, payload: payload };
}
export const GetTasksFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get tasks failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get tasks failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_TASKS_FAILURE, payload: payload }
}

//----------------------
// Actions Select Task
//----------------------
export const SelectTaskStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_TASK_REQUEST });
    await apiCon.get('/project/task/' + id)
        .then(response => {
            dispatch(SelectTaskSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectTaskFail(err.response));
        })
}
export const SelectTaskSuccess = (data) => {
    const payload = { succ_msg: "Successfully received task info.", selected: data }
    return { type: types.SELECT_TASK_SUCCESS, payload: payload };
}
export const SelectTaskFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Task failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get Task failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_TASK_FAILURE, payload: payload }
}

export const DeselectTask = () => {
    const payload = { succ_msg: "Successfully deselected task info." }
    return { type: types.DESELECT_TASK, payload: payload };
}

//----------------------
// Actions My Tasks
//----------------------

//----------------------
// Actions Get My Tasks List
//----------------------
export const GetMyTasksStart = (limit = 10, search, ordering) => async dispatch => {
    dispatch({ type: types.GET_MY_TASKS_REQUEST });
    const url = '/me/project/tasks/'
    const params = {
        'limit': limit,
        'search': search,
        'ordering': ordering,
    }
    const filters = getUrlParameterObject(params)
    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetMyTasksSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyTasksFail(err.response));
        })
}
export const GetMyTasksChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_MY_TASKS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyTasksSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetMyTasksFail(err.response));
        })
}
export const GetMyTasksSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received tasks info.", list: data, add: add }
    return { type: types.GET_MY_TASKS_SUCCESS, payload: payload };
}
export const GetMyTasksFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Tasks failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get My Tasks failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_TASKS_FAILURE, payload: payload }
}

//----------------------
// Actions Select My Task
//----------------------
export const SelectMyTaskStart = (id, selectProposals = true) => async dispatch => {
    dispatch({ type: types.SELECT_MY_TASK_REQUEST });
    await apiCon.get('me/project/task/' + id)
        .then(response => {
            dispatch(SelectMyTaskSuccess(response.data, selectProposals));
        }
        )
        .catch(err => {
            dispatch(SelectMyTaskFail(err.response));
        })
}
export const SelectMyTaskSuccess = (data, selectProposals) => async dispatch => {
    const payload = { succ_msg: "Successfully received my task info.", selected: data }
    if (selectProposals) {
        dispatch(DeselectMyTaskProposal());
        dispatch(DeselectTaskDisputeSettlement());
        dispatch(GetMyTaskProposalsStart(data.id));
    }
    if (data.exists_dispute_settlement) {
        dispatch(SelectTaskDisputeSettlement(data.id, false));
    }
    dispatch({ type: types.SELECT_MY_TASK_SUCCESS, payload: payload });

}
export const SelectMyTaskFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select My Task failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Select My Task failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_MY_TASK_FAILURE, payload: payload }
}

export const DeselectMyTask = () => async dispatch => {
    const payload = { succ_msg: "Successfully deselected my task info." }
    dispatch(DeselectMyTaskProposal());
    dispatch(DeselectMyTaskDisputeCase());
    dispatch({ type: types.DESELECT_MY_TASK, payload: payload });
}

//----------------------
// Actions Get My Task History
//----------------------
// HistoryType can be an mix of these statuses [ 'MESSAGING', 'FILE_SUBMISSION', 'AUDIT', 'TASK', 'NEGOTIATION' ]
export const GetMyTaskHistoryStart = (id, history_type = ['MESSAGING', 'FILE_SUBMISSION', 'TASK', 'NEGOTIATION']) => async dispatch => {
    dispatch({ type: types.GET_MY_TASK_HISTORY_REQUEST });
    const filters = getUrlParameterObject({ 'history_type': history_type })
    await apiCon.get('me/project/task/history/' + id, { params: filters })
        .then(response => {
            dispatch(GetMyTaskHistorySuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyTaskHistoryFail(err.response));
        })
}
export const GetMyTaskHistorySuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my task history info.", selected: data }
    dispatch({ type: types.GET_MY_TASK_HISTORY_SUCCESS, payload: payload });

}
export const GetMyTaskHistoryFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Task History failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get My Task History failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_TASK_HISTORY_FAILURE, payload: payload }
}

//----------------------
// Actions Update Task
//----------------------
export const UpdateTaskStart = (values, selectTask = true) => async dispatch => {
    dispatch({ type: types.UPDATE_TASK_REQUEST });
    const address = `/me/project/task/` + values.id;
    delete values['id'];
    const json = JSON.stringify(values);
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateTaskSuccess(response.data, selectTask));
        })
        .catch(err => {
            dispatch(UpdateTaskFail(err.response));
        });

}
export const UpdateTaskSuccess = (data, selectTask) => async dispatch => {
    const succ_msg = "Task updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data, selectTask: selectTask };
    dispatch(DeselectMyTaskProposal());
    dispatch({ type: types.UPDATE_TASK_SUCCESS, payload: payload });
}
export const UpdateTaskFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    let toast_msg = "Ooops! Update Task failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Update Task failed. " + err.data.error_first_message; }
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.UPDATE_TASK_FAILURE, payload: payload };
}

//----------------------
// Actions Create Task
//----------------------
export const CreateTaskStart = (values, files, selectTask = true, redirect = false) => async dispatch => {
    dispatch({ type: types.CREATE_TASK_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/project/tasks/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            return new Promise((resolve) => {
                dispatch(CreateTaskSuccess(response.data, selectTask, redirect))
                resolve();
            }).then(array => {
                if (files) {
                    dispatch(CreateDocumentsStart(files, null, response.data.id))

                }
            });
        })
        .catch(err => {
            dispatch(CreateTaskFail(err.response));
        });
}
export const CreateTaskSuccess = (data, selectTask, redirect) => async dispatch => {
    const succ_msg = "Task created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data, selectTask: selectTask, redirect: redirect };
    if (selectTask) { dispatch(DeselectMyTaskProposal()); }
    dispatch({ type: types.CREATE_TASK_SUCCESS, payload: payload });
    if (redirect) {
        history.push('/my-projects/' + data.project.id + '/' + data.id)
    }
}
export const CreateTaskFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    let toast_msg = "Ooops! Create Task failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_TASK_FAILURE, payload: payload };
}

//----------------------
// Actions Submit/Complete Task
//----------------------
export const CompleteSubmitTaskStart = (id, action_type, dispute_reason) => async dispatch => {
    dispatch({ type: types.COMPLETE_SUBMIT_TASK_REQUEST });
    const address = '/me/project/task/completion/' + id;
    const params = {
        'action_type': action_type,
        ...(action_type === 'SEND_IN_AUDIT' ? { 'dispute_reason': dispute_reason } : {}),
    }
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, params, settings)
        .then(response => {
            dispatch(CompleteSubmitTaskSuccess(response.data));
            if (action_type === 'COMPLETE') {
                dispatch(GetMyReviewRequestsStart(1, null, response.data.active_freelancer.id))
            }
        })
        .catch(err => {
            dispatch(CompleteSubmitTaskFail(err.response));
        });
}
export const CompleteSubmitTaskSuccess = (data, showToast = true) => async dispatch => {
    const succ_msg = "Task submited/completed successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: showToast ? succ_msg : null, selected: data };
    dispatch({ type: types.COMPLETE_SUBMIT_TASK_SUCCESS, payload: payload });
}
export const CompleteSubmitTaskFail = (err, showToast = true) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    let toast_msg = "Ooops! Complete/Submit Task failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Complete/Submit failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: showToast ? toast_msg : null };
    return { type: types.COMPLETE_SUBMIT_TASK_FAILURE, payload: payload };
}


