import * as types from './types';
import { apiCon } from '../../../API';
//--------------------------------------
// Actions Task Dispute Settlement Topic
//--------------------------------------

//---------------------------------------------
// Actions Select Task Dispute Settlement Topic
//---------------------------------------------
export const SelectTaskDisputeSettlementTopic = (id) => async dispatch => {
    dispatch({ type: types.SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST });
    await apiCon.get('/me/project/task/dispute_settlement/topic/' + id)
        .then(response => {
            dispatch(SelectTaskDisputeSettlementTopicSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectTaskDisputeSettlementTopicFail(err.response));
        })
}
export const SelectTaskDisputeSettlementTopicSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received Task Dispute Settlement Topic info.", selected: data }
    dispatch({ type: types.SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS, payload: payload });
}
export const SelectTaskDisputeSettlementTopicFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Task Dispute Settlement Topic failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get Task Dispute Settlement Topic failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE, payload: payload }
}

export const DeselectTaskDisputeSettlementTopic = () => {
    const payload = { succ_msg: "Successfully deselected Task Dispute Settlement Topic info." }
    return { type: types.DESELECT_TASK_DISPUTE_SETTLEMENT_TOPIC, payload: payload };
}

//-------------------------------------------
// Actions Get Task Dispute Settlement Topics
//-------------------------------------------
export const GetTaskDisputeSettlementTopicsStart = (dispute_settlement_id, limit = 100, search, ordering) => async dispatch => {
    dispatch({ type: types.GET_TASK_DISPUTE_SETTLEMENT_TOPICS_REQUEST });
    var url = '/me/project/task/dispute_settlement/topics/'
    const params = {
        'taskdisputesettlement_id': dispute_settlement_id,
        'limit': limit,
        ...(search ? { 'search': search } : {}),
        ...(ordering ? { 'ordering': ordering } : {}),
    }
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetTaskDisputeSettlementTopicsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetTaskDisputeSettlementTopicsFail(err.response));
        })
}
export const GetTaskDisputeSettlementTopicsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_TASK_DISPUTE_SETTLEMENT_TOPICS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetTaskDisputeSettlementTopicsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetTaskDisputeSettlementTopicsFail(err.response));
        })
}
export const GetTaskDisputeSettlementTopicsSuccess = (data) => {
    const payload = { succ_msg: "Successfully received Task Dispute Settlement Topic info.", list: data }
    return { type: types.GET_TASK_DISPUTE_SETTLEMENT_TOPICS_SUCCESS, payload: payload };
}
export const GetTaskDisputeSettlementTopicsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Task Dispute Settlement Topics failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get Task Dispute Settlement Topics failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_TASK_DISPUTE_SETTLEMENT_TOPICS_FAILURE, payload: payload }
}

//---------------------------------------------
// Actions Update Task Dispute Settlement Topic
//---------------------------------------------
export const UpdateTaskDisputeSettlementTopicStart = (id, topic, comment, resolved) => async dispatch => {
    dispatch({ type: types.UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST });
    const address = `/me/project/task/dispute_settlement/topic/` + id;
    const values = {
        ...(topic ? { 'topic': topic } : {}),
        ...(comment ? { 'comment': comment } : {}),
        ...(resolved ? { 'resolved': resolved } : {})
    }
    const json = JSON.stringify(values);
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateTaskDisputeSettlementTopicSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateTaskDisputeSettlementTopicFail(err.response));
        });
}
export const UpdateTaskDisputeSettlementTopicSuccess = (data, showToast = true) => async dispatch => {
    const succ_msg = "Task Dispute Settlement Topic updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: showToast ? succ_msg : null, selected: data };
    dispatch({ type: types.UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS, payload: payload });
}
export const UpdateTaskDisputeSettlementTopicFail = (err, showToast = true) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Update Task Dispute Settlement Topic failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Update Task Dispute Settlement Topic failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: showToast ? toast_msg : null, status: status };
    return { type: types.UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS, payload: payload };
}

//---------------------------------------------
// Actions Create Task Dispute Settlement Topic
//---------------------------------------------
export const CreateDisputeSettlementTopicStart = (dispute_settlement_id, topic, comment) => async dispatch => {
    dispatch({ type: types.CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST });
    const values = {
        'taskdisputesettlement': dispute_settlement_id,
        'topic': topic,
        'comment': comment
    }
    const address = `/me/project/task/dispute_settlement/topics/`;
    const config = { headers: { 'content-type': 'application/json' } }
    await apiCon.post(address, JSON.stringify(values), config)
        .then(response => {
            dispatch(CreateDisputeSettlementTopicSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateDisputeSettlementTopicFail(err.response));
        });
}
export const CreateDisputeSettlementTopicSuccess = (data) => {
    const succ_msg = "Task Dispute Settlement Topic created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS, payload: payload };
}
export const CreateDisputeSettlementTopicFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Ooops! Create Task Dispute Settlement Topic failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create Task Dispute Settlement Topic failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE, payload: payload };
}

//---------------------------------------------
// Actions Delete Task Dispute Settlement Topic
//---------------------------------------------
export const DeleteDisputeSettlementTopicStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST });
    const address = `/me/project/task/dispute_settlement/topic/` + id;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.delete(address, settings)
        .then(response => {
            dispatch(DeleteDisputeSettlementTopicSuccess(id));
        })
        .catch(err => {
            dispatch(DeleteDisputeSettlementTopicFail(err.response));
        });

}
export const DeleteDisputeSettlementTopicSuccess = (id) => {
    const succ_msg = "Task Dispute Settlement Topic deleted successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS, payload: payload };
}
export const DeleteDisputeSettlementTopicFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Delete Task Dispute Settlement Topic failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Delete Task Dispute Settlement Topic failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE, payload: payload };
}

