import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './Setup/formWorkInformation.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Col, Row } from 'react-bootstrap';
import { WorkingDaysArr } from '../../utils/common';
import { UpdateRoleDetailsStart } from '../../core/actions/role';
import { langService } from './../../utils/languageService/index';
import { createErrorMessageSelector, createLoadingSelector } from './../../API/selector';
import DualRangeSlider from '../Common/DualRangeSlider/index';
const arrDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const customStyles = {
    height: 'inherit',
    maxHeight: '85%',
    bottom: 'auto',
    top: '10%'
};

class ModalEditFreelancerAvailability extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            selectedAvailabilityOption: this.props.role.availability,
            selectedProjectsOption: this.areAllDurationsFilled() ? 'custom' : 'all',
            showCheckRow: "none",
            showDurationRow: this.areAllDurationsFilled() ? 'flex' : 'none',
            days: [true, true, true, true, true, false, false],
            from: this.props.role.project_duration_from,
            fromTime: this.props.role.project_duration_from_choice ? this.props.role.project_duration_from_choice : 'Days',
            to: this.props.role.project_duration_to,
            toTime: this.props.role.project_duration_to_choice ? this.props.role.project_duration_to_choice : 'Days',
            task_remuneration_from: this.props.role.task_remuneration_from ? parseInt(this.props.role.task_remuneration_from) : '1000',
            task_remuneration_to: this.props.role.task_remuneration_to ? parseInt(this.props.role.task_remuneration_to) : '10000',
            hourly_rate: this.props.role.hourly_rate,
            currentMin: 0,
            currentMax: 0,
        };
    }



    areAllDurationsFilled = () => {
        if (this.props.role.project_duration_from
            && this.props.role.project_duration_from_choice
            && this.props.role.project_duration_to
            && this.props.role.project_duration_to_choice)
            return true;

        return false;
    }

    componentDidMount() {
        if (this.props.role.availability === "F") {
            this.setState({ days: WorkingDaysArr(this.props.role.availability_days), showCheckRow: "block" });
        } else {
            this.setState({ showCheckRow: "none" });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === "") {
            this.hide()
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            this.setState({
                id: nextProps.role.id,
                days: WorkingDaysArr(nextProps.role.availability_days)
            });
        }
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    handleAvailabilityOptionChange = (e) => {
        if (e.target.value === "F") {
            this.setState({
                showCheckRow: "block",
                selectedAvailabilityOption: e.target.value
            });
        } else {
            this.setState({
                showCheckRow: "none",
                selectedAvailabilityOption: e.target.value
            });
        }
    }

    handleProjectsOptionChange = (e) => {
        if (e.target.value === "custom") {
            this.setState({
                showDurationRow: "flex",
                selectedProjectsOption: e.target.value
            });
        } else {
            this.setState({
                showDurationRow: "none",
                selectedProjectsOption: e.target.value
            });
        }
    }

    checkBoxChange = (e) => {
        var idx = parseInt(e.target.id.substring(1));
        var arr = this.state.days;
        arr[idx] = !arr[idx];
        this.setState({ days: arr });
    }

    save() {
        // Availability
        var days = [];
        if (this.state.selectedAvailabilityOption === "F") {
            // add days
            for (let i = 0; i < this.state.days.length; i++) {
                if (this.state.days[i]) {
                    days.push(arrDays[i]);
                }
            }
        }

        this.props.UpdateRoleDetailsStart({
            availability: this.state.selectedAvailabilityOption,
            availability_days: days,
            hourly_rate: this.state.hourly_rate,
            task_remuneration_from: this.currentMin,
            task_remuneration_to: this.currentMax,
            project_duration_from_choice: this.state.fromTime,
            project_duration_to_choice: this.state.toTime,
            project_duration_from: this.state.from,
            project_duration_to: this.state.to,
        });
    }

    parentFunctionMin = (minValue) => {
        if (minValue) {
            this.currentMin = minValue
        }
    }

    parentFunctionMax = (maxValue) => {
        if (maxValue) {
            this.currentMax = maxValue
        }
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={725}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title"><FormattedMessage id="modalEditFreelancerRates.heading" defaultMessage="Edit Availability and Preferences" /></div>

                    <div className="modal-body p-2 pt-4">

                        <Row>
                            <Col>
                                <div className="card p-0">
                                    <div className="card-head">
                                        <FormattedMessage id="setup_profile.work_info" defaultMessage="Work information" />
                                    </div>
                                    <div className="card-body">
                                        <div>

                                            {/* AVAILABILITY */}
                                            <div className="card-body-title">
                                                <FormattedMessage id="setup_profile.availability" defaultMessage="Set up your current availability" />
                                            </div>
                                            <div className="sp-subtitle"></div>
                                            <div className="form-group">
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" value="F" className="sp-radio"
                                                            checked={this.state.selectedAvailabilityOption === 'F'}
                                                            onChange={this.handleAvailabilityOptionChange}
                                                        />
                                                        <FormattedMessage id="setup_profile.full_time" defaultMessage="Full time" />
                                                    </label>
                                                </div>
                                                <div className="sp-checkrow" style={{ display: this.state.showCheckRow }}>
                                                    <div className="">
                                                        <FormattedMessage id="setup_profile.select_days" defaultMessage="Select working days" /> :
                                                    </div>
                                                    <div className="">
                                                        <Form.Check inline label={langService.messages['common.monday']} id="d0" type="checkbox" checked={this.state.days[0]} onChange={this.checkBoxChange} />
                                                        <Form.Check inline label={langService.messages['common.tuesday']} id="d1" type="checkbox" checked={this.state.days[1]} onChange={this.checkBoxChange} />
                                                        <Form.Check inline label={langService.messages['common.wednesday']} id="d2" type="checkbox" checked={this.state.days[2]} onChange={this.checkBoxChange} />
                                                        <Form.Check inline label={langService.messages['common.thursday']} id="d3" type="checkbox" checked={this.state.days[3]} onChange={this.checkBoxChange} />
                                                        <Form.Check inline label={langService.messages['common.friday']} id="d4" type="checkbox" checked={this.state.days[4]} onChange={this.checkBoxChange} />
                                                        <Form.Check inline label={langService.messages['common.saturday']} id="d5" type="checkbox" checked={this.state.days[5]} onChange={this.checkBoxChange} />
                                                        <Form.Check inline label={langService.messages['common.sunday']} id="d6" type="checkbox" checked={this.state.days[6]} onChange={this.checkBoxChange} />
                                                    </div>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" value="P" className="sp-radio"
                                                            checked={this.state.selectedAvailabilityOption === 'P'}
                                                            onChange={this.handleAvailabilityOptionChange}
                                                        />
                                                        <FormattedMessage id="setup_profile.part_time" defaultMessage="Part time" />
                                                    </label>
                                                </div>
                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" value="N" className="sp-radio"
                                                            checked={this.state.selectedAvailabilityOption === 'N'}
                                                            onChange={this.handleAvailabilityOptionChange}
                                                        />
                                                        <FormattedMessage id="setup_profile.not_available" defaultMessage="Not accepting work" />
                                                    </label>
                                                </div>
                                            </div>

                                            {/* HOURLY RATE */}
                                            <div className="card-body-title">
                                                <FormattedMessage id="setup_profile.hourly_rate" defaultMessage="Your hourly rate" />
                                            </div>
                                            <div className="sp-subtitle"></div>
                                            <div className="form-group">
                                                <Form.Control type="number" className="sp-input-number mr-2" onChange={this.handleChange('hourly_rate')} min={1} max={1000} value={this.state.hourly_rate} />
                                            </div>

                                            {/* DURATION */}
                                            <div className="card-body-title"><FormattedMessage id="setup_profile.projects_duration" defaultMessage="Projects duration" /></div>
                                            <div className="sp-subtitle"><FormattedMessage id="setup_profile.interest_projects_duration" defaultMessage="I'm interested in projects with duration" /></div>
                                            <div className="form-group">

                                                <div className="radio">
                                                    <label>
                                                        <input type="radio" value="all" className="sp-radio"
                                                            checked={this.state.selectedProjectsOption === 'all'}
                                                            onChange={this.handleProjectsOptionChange}
                                                        />
                                                        <FormattedMessage id="common.all" defaultMessage="All" />
                                                    </label>
                                                </div>

                                                <div className="radio">

                                                    <label>
                                                        <input type="radio" value="custom" className="sp-radio"
                                                            checked={this.state.selectedProjectsOption === 'custom'}
                                                            onChange={this.handleProjectsOptionChange}
                                                        />
                                                        <FormattedMessage id="common.custom" defaultMessage="Custom" />
                                                    </label>

                                                    <div className="sp-duration-row" style={{ display: this.state.showDurationRow }}>
                                                        <div className="mr-2"><FormattedMessage id="common.from" defaultMessage="From" /></div>
                                                        <Form.Control type="number" className="sp-input-number mr-2" onChange={this.handleChange('from')} value={this.state.from} />
                                                        <select className="form-control" style={{ width: "120px" }} onChange={this.handleChange('fromTime')} value={this.state.fromTime}>
                                                            <option value="Days">Days</option>
                                                            <option value="Weeks">Weeks</option>
                                                            <option value="Months">Months</option>
                                                        </select>
                                                        <div className="ml-2 mr-2"><FormattedMessage id="common.to" defaultMessage="To" /></div>
                                                        <Form.Control type="number" className="sp-input-number  mr-2" onChange={this.handleChange('to')} value={this.state.to} />
                                                        <select className="form-control" style={{ width: "120px" }} onChange={this.handleChange('toTime')} value={this.state.toTime}>
                                                            <option value="Days">Days</option>
                                                            <option value="Weeks">Weeks</option>
                                                            <option value="Months">Months</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            {/* RENUMERATION */}
                                            <div className="card-body-title">Task remuneration</div>
                                            <div className="sp-subtitle">I'm interested in tasks with remuneration</div>
                                            <DualRangeSlider
                                                min={200}
                                                max={10000}
                                                currentMin={this.state.task_remuneration_from}
                                                currentMax={this.state.task_remuneration_to}
                                                inputMin={this.state.task_remuneration_from}
                                                inputMax={this.state.task_remuneration_to}
                                                minValueBetween={300}
                                                sliderWidth={300}
                                                onCallBackMin={(x) => this.parentFunctionMin(x)}
                                                onCallBackMax={(z) => this.parentFunctionMax(z)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col className="modal-btns-right">
                                <div className="btn btn-default btn-generic btn-smallpadding mr-2" onClick={() => this.hide()}>
                                    <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                                </div>
                                <div className="btn btn-default btn-developers" onClick={() => this.save()}>
                                    <FormattedMessage id="common.save" defaultMessage="Save" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Rodal>
        )
    }

}
const loadingSelector = createLoadingSelector(['UPDATE_ROLE_DETAILS']);
const errorSelector = createErrorMessageSelector(['UPDATE_ROLE_DETAILS']);
const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateRoleDetailsStart
})(ModalEditFreelancerAvailability));