import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';


class VotingButtons extends React.Component {

    render() {
        const { isAuth, item } = this.props;
        return (
            <div className="voting-controls">
                <div className={`vote-btn vote-up ${item.user_voted === 'UP' && 'up-selected'}`} onClick={() => { isAuth && this.props.onVote(item.id, item.user_voted, 'UP') }}>
                    <span className="icon-arrow-up-filled"></span>
                    <div>{item.num_vote_up}</div>
                </div>
                <div className="vote-score">{parseFloat(item.vote_score).toFixed(2)}</div>
                <div className={`vote-btn vote-down ${item.user_voted === 'DOWN' && 'down-selected'}`} onClick={() => { isAuth && this.props.onVote(item.id, item.user_voted, 'DOWN') }} >
                    <div>{item.num_vote_down}</div>
                    <span className="icon-arrow-down-filled"></span>
                </div>
            </div>
        )
    }
}

export default injectIntl(connect(null, {})(VotingButtons));
