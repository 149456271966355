import React from 'react';
import { createLoadingSelector } from '../../API/selector';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// usage: 
// const submitForm = useReduxLoadingState('ACTION_NAME', { loading: false });
// button element => <Button disabled={submitForm.loading}>{submitForm.loading ? 'Loading' : 'Submit'}</Button>
export const useReduxLoadingState = (action, initialState, additionalLoadTime = 0) => {
    const [isLoading, setIsLoading] = useState(initialState.loading);
    const [returnState, setReturnState] = useState({
        loading: isLoading,
        success: true
    });

    const loadingSelector = createLoadingSelector([action]);
    const state = useSelector(state => state);

    useEffect(() => {
        if (isLoading && !loadingSelector(state)) {
            if (additionalLoadTime > 0)
                setTimeout(() => setIsLoading(loadingSelector(state)), additionalLoadTime);
            else
                setIsLoading(loadingSelector(state));
        } else {
            setIsLoading(loadingSelector(state));
        }
    }, [state.loading])

    useEffect(() => {
        const errors = state.errors[action];

        setReturnState({
            loading: isLoading,
            success: !errors
        })
    }, [isLoading])

    return returnState;
}

// For class components
export const withReduxLoadingState = (WrappedComponent, action, initialState, additionalLoadTime = 0) => {
    return props => {
        const reduxLoadingState = useReduxLoadingState(action, initialState, additionalLoadTime);
        return <WrappedComponent reduxLoadingState={reduxLoadingState} {...props} />
    }
}