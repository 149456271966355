//Notification Settings
export const GET_NOTIFICATION_SETTINGS_REQUEST = "GET_NOTIFICATION_SETTINGS_REQUEST";
export const GET_NOTIFICATION_SETTINGS_SUCCESS = "GET_NOTIFICATION_SETTINGS_SUCCESS";
export const GET_NOTIFICATION_SETTINGS_FAILURE = "GET_NOTIFICATION_SETTINGS_FAILURE";

export const UPDATE_NOTIFICATION_SETTING_REQUEST = "UPDATE_NOTIFICATION_SETTING_REQUEST";
export const UPDATE_NOTIFICATION_SETTING_SUCCESS = "UPDATE_NOTIFICATION_SETTING_SUCCESS";
export const UPDATE_NOTIFICATION_SETTING_FAILURE = "UPDATE_NOTIFICATION_SETTING_FAILURE";

export const SELECT_NOTIFICATION_SETTING_REQUEST = "SELECT_NOTIFICATION_SETTING_REQUEST";
export const SELECT_NOTIFICATION_SETTING_SUCCESS = "SELECT_NOTIFICATION_SETTING_SUCCESS";
export const SELECT_NOTIFICATION_SETTING_FAILURE = "SELECT_NOTIFICATION_SETTING_FAILURE";

//Notifications
export const GET_NOTIFICATION_STATUS_REQUEST = "GET_NOTIFICATION_STATUS_REQUEST";
export const GET_NOTIFICATION_STATUS_SUCCESS = "GET_NOTIFICATION_STATUS_SUCCESS";
export const GET_NOTIFICATION_STATUS_FAILURE = "GET_NOTIFICATION_STATUS_FAILURE";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const MARK_NOTIFICATION_AS_READ_REQUEST = "MARK_NOTIFICATION_AS_READ_REQUEST";
export const MARK_NOTIFICATION_AS_READ_SUCCESS = "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_FAILURE = "MARK_NOTIFICATION_AS_READ_FAILURE";

//Web Socket 
export const WEB_SOCKET_ROLE_CHANGED = "WEB_SOCKET_ROLE_CHANGED";
export const WEB_SOCKET_ADD_NOTIFICATION = "WEB_SOCKET_ADD_NOTIFICATION";
export const WEB_SOCKET_TASK_PROPOSAL_CHANGED = "WEB_SOCKET_TASK_PROPOSAL_CHANGED";
export const WEB_SOCKET_TASK_PROPOSAL_CREATED = "WEB_SOCKET_TASK_PROPOSAL_CREATED";
export const WEB_SOCKET_TASK_DISPUTE_CHANGED = "WEB_SOCKET_TASK_DISPUTE_CHANGED";
export const WEB_SOCKET_TASK_DISPUTE_CREATED = "WEB_SOCKET_TASK_DISPUTE_CREATED";
export const WEB_SOCKET_TASK_CREATED = "WEB_SOCKET_TASK_CREATED";
export const WEB_SOCKET_TASK_CHANGED = "WEB_SOCKET_TASK_CHANGED";
export const WEB_SOCKET_PROJECT_MAY_COMPLETE = "WEB_SOCKET_PROJECT_MAY_COMPLETE";