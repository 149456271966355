//--------------------------
//  Authentication actions
//--------------------------
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const GET_USER_FROM_LOCALE = "GET_USER_FROM_LOCALE";

export const TOKEN_REFRESH_REQUEST = "TOKEN_REFRESH_REQUEST";
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAILURE = "TOKEN_REFRESH_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESEND_ACTIVATION_EMAIL_REQUEST = "RESEND_ACTIVATION_EMAIL_REQUEST";
export const RESEND_ACTIVATION_EMAIL_SUCCESS = "RESEND_ACTIVATION_EMAIL_SUCCESS";
export const RESEND_ACTIVATION_EMAIL_FAILURE = "RESEND_ACTIVATION_EMAIL_FAILURE";

export const ACTIVATE_EMAIL_REQUEST = "ACTIVATE_EMAIL_REQUEST";
export const ACTIVATE_EMAIL_SUCCESS = "ACTIVATE_EMAIL_SUCCESS";
export const ACTIVATE_EMAIL_FAILURE = "ACTIVATE_EMAIL_FAILURE";

//-------------------
//  Get Profile Completion
//-------------------
export const GET_PROFILE_COMPLETION_REQUEST = "GET_PROFILE_COMPLETION_REQUEST";
export const GET_PROFILE_COMPLETION_SUCCESS = "GET_PROFILE_COMPLETION_SUCCESS";
export const GET_PROFILE_COMPLETION_FAILURE = "GET_PROFILE_COMPLETION_FAILURE";