import { UPDATE_ROLE_DETAILS_SUCCESS, GET_MY_ROLE_DETAIL_SUCCESS } from '../../../../actions/role/types';
import {
    CREATE_EDUCATION_SUCCESS,
    UPDATE_EDUCATION_SUCCESS,
    SELECT_EDUCATION_SUCCESS,
    DELETE_EDUCATION_SUCCESS,
    GET_MYVERIFICATIONREQUESTS_SUCCESS
} from '../../../../actions/education/types'


const INITIAL_STATE = {
    list: [],
    selected: {
        id: null,
        degree: '',
        school: '',
        field_of_study: '',
        date_from: '',
        date_to: '',
        owner: null
    },
    requests: {
        count: 0,
        next: null,
        previous: null,
        results: []
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_ROLE_DETAILS_SUCCESS:
            return { ...state, list: action.payload.details.educations }
        case GET_MY_ROLE_DETAIL_SUCCESS:
            return { ...state, list: action.payload.details.educations }
        case CREATE_EDUCATION_SUCCESS:
            const edu = action.payload.education
            return {
                ...state, selected: INITIAL_STATE.selected,
                list: state.list.concat(edu)
            }
        case UPDATE_EDUCATION_SUCCESS:
            const edu2 = action.payload.education
            return {
                ...state, selected: INITIAL_STATE.selected,
                list: state.list.map(education =>
                    education.id === edu2.id ?
                        edu2 : education)
            }
        case DELETE_EDUCATION_SUCCESS:
            const edu3 = action.payload.deleted
            return {
                ...state, selected: INITIAL_STATE.selected,
                list: state.list.filter((education) => education.id !== edu3)
            }
        case SELECT_EDUCATION_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case GET_MYVERIFICATIONREQUESTS_SUCCESS:
            return { ...state, requests: action.payload.requests }
        default:
            return state;
    }
}