import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import ProfileSwitcher from '../../components/ProfileSwitcher';
import Infobar from '../../components/Infobar';
import { Link } from 'react-router-dom';

class DashboardPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { role, isEmailVerified } = this.props;
        return (
            <>

                <Jumbotron className="jumbo-dash">
                    <Infobar />
                    <Container>
                        <h1><FormattedMessage id="dashboard.welcome" defaultMessage="Welcome" />, {role.name}</h1>
                        <p>With Ecta you can lorem ipsum...</p>
                    </Container>
                </Jumbotron>
                <Container className="mt-4">
                    <Row>
                        <Col>
                            <ProfileSwitcher />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={9}>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span> */}
                                <div className="card-head"><FormattedMessage id="dashboard.welcome" defaultMessage="Welcome" /></div>
                                <div className="card-body"></div>
                            </div>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span> */}
                                <div className="card-head"><FormattedMessage id="dashboard.setup" defaultMessage="Setup" /></div>
                                <div className="card-body"></div>
                            </div>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span> */}
                                <div className="card-head"><FormattedMessage id="dashboard.browse" defaultMessage="Browse" /></div>
                                <div className="card-body"></div>
                            </div>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span> */}
                                <div className="card-head"><FormattedMessage id="dashboard.statistics" defaultMessage="Statistics" /></div>
                                <div className="card-body"></div>
                            </div>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span> */}
                                <div className="card-head"><FormattedMessage id="dashboard.clients" defaultMessage="Clients" /></div>
                                <div className="card-body"></div>
                            </div>
                            <div className="card p-0">
                                {/* <span className="icon-edit"></span> */}
                                <div className="card-head"><FormattedMessage id="dashboard.myactivity" defaultMessage="My Activity" /></div>
                                <div className="card-body"></div>
                            </div>
                        </Col>
                        <Col md={12} lg={3}>
                            <div className="card">
                                { isEmailVerified && <Link to="/auth/register-role"><FormattedMessage id="common.register_role" defaultMessage="Register role" /></Link> }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        isEmailVerified: state.user.info.isEmailVerified,
        role: state.user.info.settings.active_role_obj,
    }
}

export default injectIntl(connect(mapStateToProps, {

})(DashboardPage));