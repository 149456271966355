import React from 'react';
import '../browse-styles.scss';
import './index.scss';
import _ from 'lodash';
import history from '../../../utils/routes/history';
import InfoBar from '../../../components/Infobar';
import BrowseNavigationControl from '../../../components/BrowseNavigation';
import FormatedDuration from '../../../components/Common/FormatedDuration';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { GetProjectsStart, SelectProjectStart } from '../../../core/actions/projects';
import { createLoadingSelector } from '../../../API/selector';
import { SelectMyProjectStart, ChangeProjectFiltersStart, GetProjectsChangePageStart } from '../../../core/actions/projects';
import { ProjectsFilters, ProjectsSearchSort } from './ProjectsFilters';
import { isEqual } from 'lodash';
import { INITIAL_STATE_FILTERS } from '../../../core/reducers/browse/projectsReducer';
import { debounceFreeInputFields } from '../../../utils/common/browse';
import ModalResendActivationEmail from '../../../components/Common/ModalResendActivationEmail/modalResendActivationEmail';
// import BrowseItem from '../../../components/Common/BrowseItem';

class ProjectsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVerifyEmailVisible: false
        };
    }
    
    componentDidMount() {
        this.init();
    }

    init = () => {
        const { projects, projectsFilters } = this.props;

        if (projects.length !== 0)
            return;

        this.props.GetProjectsStart(projectsFilters);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.projectsFilters === this.props.projectsFilters)
            return;

        if (isEqual(this.props.projectsFilters, INITIAL_STATE_FILTERS)) {
            debounceFreeInputFields.cancel();
            this.props.GetProjectsStart(this.props.projectsFilters);
            return;
        }

        if (prevProps.projectsFilters.search !== this.props.projectsFilters.search
            || prevProps.projectsFilters.budget_max !== this.props.projectsFilters.budget_max
            || prevProps.projectsFilters.budget_min !== this.props.projectsFilters.budget_min) {
            debounceFreeInputFields([this.props.projectsFilters.search, this.props.projectsFilters.budget_max, this.props.projectsFilters.budget_min], this.props.GetProjectsStart, this.props.projectsFilters);
            return;
        }

        debounceFreeInputFields.cancel();
        this.props.GetProjectsStart(this.props.projectsFilters);
    }

    componentWillUnmount() {
        debounceFreeInputFields.cancel();
    }

    viewInMyProjects(id) {
        history.push("/my-projects/" + id);
    }

    renderCreateProjectAction() {
        const { isEmailVerified } = this.props;
        if (isEmailVerified) {
            return (<button className="btn btn-default btn-projects btn-create-project" onClick={() => history.push("/project/new")}><FormattedMessage id="browse.common.createProject" defaultMessage="Create project" /></button>)
        }
        return (<button className="btn btn-default btn-projects btn-create-project" onClick={() => this.setState({modalVerifyEmailVisible: true})}><FormattedMessage id="browse.common.createProject" defaultMessage="Create project" /></button>)
    }

    renderActions(p) {
        const { auth: { isAuthenticated }, active_role_id } = this.props;
        if (isAuthenticated) {
            if (p.client.id === active_role_id) {
                return (
                    <>
                        <button className="btn btn-default btn-projects" onClick={() => { this.props.history.push("/projects/" + p.id) }}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>
                        <button className="btn btn-default btn-projects btn-smallpadding mt-2" onClick={() => this.viewInMyProjects(p.id)}><FormattedMessage id="browse.common.view_my_projects" defaultMessage="View in My Projects" /></button>
                    </>
                )
            } else {
                return (<button className="btn btn-default btn-projects" onClick={() => { this.props.history.push("/projects/" + p.id) }}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>)
            }
        }
        return (
            <button className="btn btn-default btn-projects" onClick={() => history.push('/auth/login')}><FormattedMessage id="projects.logintoview" defaultMessage="Login to view" /></button>
        )
    }

    renderProjects(projects) {
        if (_.isEmpty(projects)) {
            return <></>
        }
        return (
            <>
                {projects.map((p, i) =>
                    <Card className="card-item" key={i}>
                        <Card.Body>
                            <div className="card-thumb">
                                {
                                    p.logo_image ?
                                        <img src={p.logo_image} className="p-thumb" alt="Project thumb" />
                                        :
                                        <img src="/assets/img/default-project.png" className="p-thumb" alt="Project thumb" />
                                }
                            </div>
                            <div className="card-details">
                                <Card.Title className="card-title browse-namelink" onClick={() => { this.props.history.push("/projects/" + p.id) }}>{p.name}</Card.Title>
                                <div className="card-desc">
                                    {p.short_description}
                                </div>
                                <div className="card-info">
                                    <span><FormattedMessage id="projects.tasks" defaultMessage="Tasks" /> <strong>{p.number_of_tasks}</strong></span>
                                    <span> <FormattedMessage id="projects.duration" defaultMessage="Duration" /> <strong>{p.visibility && p.visibility === "PRIVATE" ? 'N/A' :
                                        <FormatedDuration start_date={p.estimated_start} end_date={p.estimated_end} />}
                                    </strong></span>
                                </div>
                                <div className="card-tags">
                                    <span className="no-hover">{p.category.name}</span>
                                </div>
                            </div>
                            <div className="card-sep"></div>
                            <div className="card-actions">
                                <span>{p.estimated_budget ? `$` + parseInt(p.estimated_budget).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : p.estimated_budget} </span>
                                {this.renderActions(p)}
                            </div>
                        </Card.Body>
                    </Card>
                )}
            </>
        )
    }

    render() {
        const { projects } = this.props;
        const { auth: { isAuthenticated }, active_role_type } = this.props;
        return (
            <>
                <Jumbotron className="jumbo-projects mb-0">
                    <InfoBar />
                    <Container>
                        <h1><FormattedMessage id="projects.heading" defaultMessage="Find the best projects and tasks for you" /></h1>
                    </Container>
                </Jumbotron>
                <Container className="mt-0">
                    <BrowseNavigationControl page="projects" />
                    <Row>
                        <Col md={12} lg={3}>
                            <ProjectsFilters />
                        </Col>
                        <Col md={12} lg={9}>

                            <div className="browse-title">
                                <FormattedMessage id="browse.projects" defaultMessage="Projects" />
                                {isAuthenticated && active_role_type === "INV" && this.renderCreateProjectAction()}
                            </div>

                            <ProjectsSearchSort />

                            <span className="items-count">
                                <FormattedMessage id="plural.projects" values={{ count: projects.length }}
                                    defaultMessage={`${projects.length} Projects`} />
                            </span>

                            <InfiniteScroll
                                dataLength={projects.length}
                                next={() => setTimeout(() => this.props.nextUrl && this.props.GetProjectsChangePageStart(this.props.nextUrl), 500)}
                                hasMore={projects.length !== this.props.count}
                                loader={<div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.loading" defaultMessage="Loading" />...</div>}
                                endMessage={projects.length > 0 && <div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.allrecordsloaded" defaultMessage="All records loaded" /></div>}
                            >
                                <div className="card-list-projects">
                                    {this.renderProjects(projects)}
                                </div>
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </Container>
                <ModalResendActivationEmail visible={this.state.modalVerifyEmailVisible} onClose={() => this.setState({ modalVerifyEmailVisible: false })} />
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['GET_PROJECTS']);
const mapStateToProps = state => {
    return {
        isLoading: loadingSelector(state),
        projects: state.browse.projects.list.results,
        auth: state.user.auth,
        isEmailVerified: state.user.info.isEmailVerified,
        active_role_id: state.user.info.settings.active_role,
        active_role_type: state.user.info.settings.active_role_obj?.type,
        projectsFilters: state.browse.projects.list.filters,
        nextUrl: state.browse.projects.list.next,
        count: state.browse.projects.list.count
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetProjectsStart,
    SelectProjectStart,
    SelectMyProjectStart,
    ChangeProjectFiltersStart,
    GetProjectsChangePageStart
})(ProjectsList));