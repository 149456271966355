import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './uploadDocuments.scss';
import { FormattedMessage } from 'react-intl';
import { langService } from './../../utils/languageService/index';
import { Image } from 'react-bootstrap';


export function UploadDocuments(props) {
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps, open } = useDropzone({
        multiple: true,
        noClick: true,
        noDrag: false,
        maxFilesize: 20,
        onDrop: acceptedFiles => {
            const addedFiles = (acceptedFiles.map(file =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
            ))
            setFiles(files.concat(addedFiles));
            props.onFilesChanged(acceptedFiles);
        }
    });

    function deleteFile(file) {
        setFiles(files.filter(x => x !== file))
    }

    const filez = files.map(file => (
        <li key={file.name}>
            {
                file.name} - {(file.size / 1048576).toFixed(2)} Mb {(file.size / 1048576) > 20 && <span className="size-exceeds"> - {langService.messages['validators.maxSize']}</span>

            }
            <Image src="/assets/img/svg/delete.svg" className="delete-file" onClick={() => deleteFile(file)}></Image>
        </li>
    ));

    return (
        <>
            <section className="p-uploadfiles-container">

                <div {...getRootProps({ className: 'dropzone p-files-upload' })}>
                    <input {...getInputProps()} />
                    <div className="drop-container">
                        <button type="button" className="btn-selectfile" onClick={open}>
                            <FormattedMessage id="forms.labels.select_documents" defaultMessage="Select your Files" />
                        </button>
                        <div>
                            <FormattedMessage id="forms.labels.drag_documents" defaultMessage="Drag 'n' drop some files here or click the button to select" />
                        </div>
                    </div>
                </div>

                <div>
                    <span><small>
                        <FormattedMessage id="forms.labels.selected_documents" defaultMessage="Selected Documents" /> :
                    </small></span>
                    <ul>{filez}</ul>
                </div>

            </section>
        </>
    );
}