import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../actions/role/types';
import {
    CREATE_TASK_DISPUTE_SETTLEMENT_SUCCESS, DELETE_TASK_DISPUTE_SETTLEMENT_SUCCESS, DESELECT_TASK_DISPUTE_SETTLEMENT,
    SELECT_TASK_DISPUTE_SETTLEMENT_SUCCESS, UPDATE_TASK_DISPUTE_SETTLEMENT_SUCCESS
} from '../../actions/taskDisputeSettlement/types';
import { CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS, DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS, UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS } from '../../actions/taskDisputeSettlementTopic/types';
import { CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENT_SUCCESS } from '../../actions/taskDisputeSettlementTopicComment/types';

//Topics Model:
// {
//     "id": 0,
//     "is_locked": "string",
//     "comments": [],
//     "topic": "string",
//     "comment": "string",
//     "resolved": true
// }

//Comments Model: 
// {
//     "id": 0,
//     "is_locked": "string",
//     "datetime": "2020-10-15T10:40:36.426Z",
//     "comment": "string",
//     "fromRole": 0
// }

const INITIAL_STATE = {
    selected: {
        task: null,
        topics: [],
        available_actions: '',
        last_task_dispute_settlement_change: null,
        failed_time: null,
        status: null,
        proposed_percentage: ''
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //Task Dispute Settlement
        case SELECT_TASK_DISPUTE_SETTLEMENT_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case DESELECT_TASK_DISPUTE_SETTLEMENT:
            return { ...state, selected: INITIAL_STATE.selected };
        case UPDATE_TASK_DISPUTE_SETTLEMENT_SUCCESS:
            return {
                ...state, selected: action.payload.selected,
            };
        case CREATE_TASK_DISPUTE_SETTLEMENT_SUCCESS:
            return {
                ...state, selected: action.payload.selected,
            };
        case DELETE_TASK_DISPUTE_SETTLEMENT_SUCCESS:
            return {
                ...state, selected: INITIAL_STATE.selected,
            };
        //Task Dispute Settlement Topic
        case CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected, topics: state.selected.topics.concat(action.payload.selected)
                },
            };
        case UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS:
            const topicUpdate = action.payload.selected;
            return {
                ...state, selected: {
                    ...state.selected, topics:
                        state.selected.topics.map(topic => topic.id === topicUpdate.id ? topicUpdate : topic)
                },
            };
        case DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS:
            const topicId = action.payload.deleted;
            return {
                ...state, selected: {
                    ...state.selected, topics: state.selected.topics.filter(topic => topic.id !== topicId)
                },
            };
        //Task Dispute Settlement Topic Comment
        case CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_COMMENT_SUCCESS:
            const tid = action.payload.topic;
            return {
                ...state, selected: {
                    ...state.selected, topics:
                        state.selected.topics.map(topic => topic.id === tid ?
                            { ...topic, comments: topic.comments.concat(action.payload.selected) } : topic)
                },
            };
        //Basic Reset 
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE };
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE };
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE };
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
}