import React from 'react';
import './index.scss';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { GetMyProjectsStart } from '../../core/actions/projects';
import { Container, Row, Col, Accordion, Form } from 'react-bootstrap';
import { SelectMyProjectStart } from '../../core/actions/projects';
import { SelectMyTaskStart, DeselectMyTask, GetMyTaskHistoryStart } from '../../core/actions/tasks';
import MyProjectsSelectedProject from './selectedProject';
import MyProjectsSelectedTask from './selectedTask';
import TaskCreate from '../../components/Tasks/taskCreate';
import Image from 'react-bootstrap/Image'
import { langService } from './../../utils/languageService/index';

class MyProjectsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addingNewTask: false,
            filterValue: "all",
            visibleCollapseList: {},
            selectedTaskId: parseInt(props.match.params.taskid) ?? '',
        };
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidMount() {
        this.props.GetMyProjectsStart();
        const param = this.props.match.params;
        if (param && param.uid && !param.taskid) {
            if (this.props.selectedProject?.id !== param.uid) {
                this.props.SelectMyProjectStart(param.uid);
            }
        } else if (param && param.uid && param.taskid) {
            if (this.props.selectedProject?.id !== param.uid) {
                this.props.SelectMyProjectStart(param.uid);
            }
            if (this.props.selectTask?.id !== param.taskid) {
                this.props.SelectMyTaskStart(param.taskid);
            }
        }
    }

    selectProject(id) {
        this.setState({ addingNewTask: false, selectedTaskId: '' });
        this.props.SelectMyProjectStart(id);
    }

    selectTask(id) {
        this.setState({ addingNewTask: false, selectedTaskId: id });
        this.props.SelectMyTaskStart(id);
        // this.props.SelectTaskDisputeSettlement(id, false);
        // this.props.GetMyTaskHistoryStart(id, ['AUDIT']);
    }

    backToTasks() {
        this.setState({ selectedTabProject: '2', selectedTaskId: '' });
        this.props.DeselectMyTask();
    }

    onAddNewTask() {
        this.setState({ addingNewTask: true, selectedTaskId: '' });
    }

    onFilterChange(e) {
        const filter = e.target.value;
        this.setState({ filterValue: filter, selectedTaskId: '' });
        if (filter === "all") {
            this.props.GetMyProjectsStart(100, null, ['DRAFT', 'PUBLISHED', 'ACTIVE', 'COMPLETED', 'REJECTED']);
        } else {
            this.props.GetMyProjectsStart(100, null, [filter.toUpperCase()]);
        }
    }

    renderProjectSidebarTasks() {
        // old way
        const { selectedProject } = this.props;
        return selectedProject.milestones?.list &&
            selectedProject.milestones?.list.map((milestone, x) => {
                if (milestone.tasks.length > 0) {
                    return (
                        <div key={x}>
                            {selectedProject.milestones.list.length > 1 &&
                                <div className="sidebar-milestone">{milestone.name ? milestone.name : "Default"}</div>
                            }
                            {milestone?.tasks.map((t, y) =>
                                <div style={{ "marginBottom": "5px" }} key={y}>
                                    <span className={"status-circle status-color-" + t.status.toLowerCase()}>●</span>
                                    <span className={this.state.selectedTaskId === t.id ? "sidebar-item-task strong" : "sidebar-item-task"} onClick={() => this.selectTask(t.id)} >{t.name} {t.recurring && " (" + langService.messages["common.recurring"] + ")"}</span>
                                </div>
                            )}
                        </div>
                    )
                }
            })
    }

    renderSidebar() {
        const { myProjects, selectedProject } = this.props;
        return (
            <div className="mypage-sidebar">

                <div className="sidebar-header">
                    <div>
                        <FormattedMessage id="nav.labels.myProjects" defaultMessage="My Projects" />
                        <span className="no-of-projects"> ({myProjects.length})</span>
                    </div>
                    <div className="add-project-icon" onClick={() => history.push("/project/new")}>+</div>
                </div>

                <div className="sidebar-filter">
                    <Form>
                        <Form.Control as="select" onChange={this.onFilterChange} value={this.state.filterValue} size="sm">
                            <option value="all">
                                {langService.messages['common.all']}
                            </option>
                            <option value="draft">
                                {langService.messages['common.draft']}
                            </option>
                            <option value="published">
                                {langService.messages['common.published']}
                            </option>
                            <option value="active">
                                {langService.messages['common.active']}
                            </option>
                            <option value="completed">
                                {langService.messages['common.completed']}
                            </option>
                            <option value="rejected">
                                {langService.messages['common.rejected']}
                            </option>
                        </Form.Control>
                    </Form>
                </div>

                <Accordion defaultActiveKey={selectedProject.id}>

                    {myProjects.map((p, i) =>
                        <div className="sidebar-item" key={i} >
                            <Accordion.Toggle className={p.id === this.props.selectedProject.id ? 'sidebar-item-header sidebar-item-header-active' : 'sidebar-item-header'} onClick={() => { this.selectProject(p.id) }} eventKey={p.id}>
                                <div className="sidebar-thumb">
                                    {p.logo_image
                                        ?
                                        <Image src={p.logo_image} className="p-thumb" fluid />
                                        :
                                        <Image src="/assets/img/default-project.png" className="p-thumb" fluid />
                                    }
                                </div>
                                <div>{p.name}</div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={p.id} in={selectedProject.id === p.id}>
                                <div className="sidebar-item-body">
                                    <div>
                                        <FormattedMessage id="projects.tasks" defaultMessage="Tasks" /> ({p.number_of_tasks})
                                    </div>
                                    {this.renderProjectSidebarTasks()}
                                    <p></p>
                                    {p.status !== "COMPLETED" && p.status !== "REJECTED" &&
                                        <div className="addNewTask" onClick={() => this.setState({ addingNewTask: true })}>
                                            + <FormattedMessage id="my_projects.create_task" defaultMessage="Create Tasks" />
                                        </div>
                                    }
                                </div>
                            </Accordion.Collapse>
                        </div>
                    )}
                </Accordion>

            </div>
        )
    }

    render() {
        const { selectedProject, selectedTask } = this.props;
        return (
            <Container className="mt-4">
                <Row>
                    <Col xs={12} md={3} className="my-sidebar pb-3">
                        {this.renderSidebar()}
                    </Col>
                    <Col xs={12} md={9}>
                        <div className="card my-content">
                            {this.state.addingNewTask
                                ?
                                <TaskCreate onTaskAdded={() => this.setState({ addingNewTask: false })} />
                                :
                                selectedTask.id !== null
                                    ? <MyProjectsSelectedTask projectStatus={selectedProject.status} tabKey={this.props?.match?.params?.tabKey} onBackToTasks={() => this.backToTasks()} />
                                    : <MyProjectsSelectedProject onAddNewTask={() => this.setState({ addingNewTask: true })} />
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        myProjects: state.myWork.myProjects.list.results,
        selectedProject: state.myWork.myProjects.selected,
        selectedTask: state.myWork.myTasks.selected,
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyProjectsStart,
    SelectMyProjectStart,
    SelectMyTaskStart,
    DeselectMyTask,
    GetMyTaskHistoryStart
})(MyProjectsPage));