/* Multilanguage

    Using React-Intl outside components
    https://github.com/formatjs/react-intl/blob/master/docs/API.md#createintl

    Usage
    import { langService } from '...';
    ...
    const msg = langService.messages["demo.hello"];

    To change language:
    import { changeLanguage } from '...';
    ...
    changeLanguage("en");

*/

import { createIntl, createIntlCache } from 'react-intl'
import translations from '../i18n'


const cache = createIntlCache(); // This is optional but highly recommended since it prevents memory leak

export const langService = createIntl({
    locale: "en",
    messages: translations.en
}, cache);

export const changeLanguage = (lang) => {
    langService.locale = lang;
    lang === "sl" ? langService.messages = translations.sl : langService.messages = translations.en;
}