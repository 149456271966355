import React from 'react'
import './index.scss'
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';


class BrowseNavigationControl extends React.Component {
    componentDidUpdate = prevProps => {
        if (prevProps.active_role_type === this.props.active_role_type)
            return;

        if (this.props.active_role_type === 'DEV' && history.location.pathname.includes('/projects'))
            history.push('/browse/tasks');

        if (this.props.active_role_type !== 'DEV' && history.location.pathname.includes('/tasks'))
            history.push('/browse/projects');
    }


    render() {
        return (
            <>
                <div className="nav-control mb-4">
                    {this.props.active_role_type === 'DEV'
                        ?
                        <div className={`nav-tab tab-projects text-center ${this.props.page === 'tasks' ? 'active' : ""}`} onClick={() => history.push("/browse/tasks")}>
                            <span className="icon-projects"></span>
                            <span><FormattedMessage id="projects.tasks" defaultMessage="Tasks" /></span>
                        </div>
                        :
                        <div className={`nav-tab tab-projects text-center ${this.props.page === 'projects' ? 'active' : ""}`} onClick={() => history.push("/browse/projects")}>
                            <span className="icon-projects"></span>
                            <span><FormattedMessage id="browse.projects" defaultMessage="Projects" /></span>
                        </div>
                    }
                    <div className={`nav-tab tab-dev text-center ${this.props.page === "developers" ? "active" : ""}`} onClick={() => history.push("/browse/developers")}>
                        <span className="icon-developers"></span>
                        <span><FormattedMessage id="browse.developers" defaultMessage="Freelancers" /></span>
                    </div>
                    <div className={`nav-tab tab-inv text-center ${this.props.page === "investors" ? "active" : ""}`} onClick={() => history.push("/browse/investors")}>
                        <span className="icon-investor"></span>
                        <span><FormattedMessage id="browse.investors" defaultMessage="Clients" /></span>
                    </div>
                    <div className={`nav-tab tab-edu text-center ${this.props.page === "edu" ? "active" : ""}`} onClick={() => history.push("/browse/educational-institutions")}>
                        <span className="icon-educational_institutions"></span>
                        <span><FormattedMessage id="browse.edus" defaultMessage="Schools" /></span>
                    </div>
                    {/* <div className={`nav-tab tab-aud text-center ${this.props.page === "auditors" ? "active" : ""}`} onClick={() => history.push("/browse/auditors")}>
                        <span className="icon-audit"></span>
                        <span><FormattedMessage id="browse.auditors" defaultMessage="Auditors" /></span>
                    </div> */}
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    active_role_type: state.user.info.settings.active_role_obj?.type
})

export default injectIntl(connect(mapStateToProps, {})(BrowseNavigationControl));