import React from 'react';
import './index.scss';
import history from '../../utils/routes/history';
import LanguageSelect from './LanguageSelect';
import DropdownNotifications from './DropdownNotifications';
import DropdownUserMenu from './DropdownUserMenu';
import { connect } from 'react-redux';
import { Nav, Navbar, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Container } from 'react-bootstrap';
import ModalResendActivationEmail from '../Common/ModalResendActivationEmail/modalResendActivationEmail';


class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            landing: false,
            transparent: false,
            menuOpened: false,
            modalVerifyEmailVisible: false
        };
        if (history.location.pathname === "/") {
            this.state.landing = true;
            this.state.transparent = true;
        } else {
            this.state.transparent = false;
        }
    }

    componentDidMount() {
        history.listen((location, action) => { location.pathname === "/" ? this.isLanding(true) : this.isLanding(false); });
        window.addEventListener('scroll', (e) => { this.changeHeaderOnScroll(); }, false);
        document.getElementById('hamburger').addEventListener('click', (e) => { this.changeHeaderOnHamburgerClick(); });
    }

    isLanding(val) {
        this.setState({ landing: val, transparent: val }, () => { });
    }

    changeHeaderOnScroll(e) {

        if (!this.state.landing) return;

        const distanceY = window.pageYOffset || document.documentElement.scrollTop, changeOn = 100;

        if (distanceY < changeOn) {
            this.setState({ transparent: true }, () => { });
        } else {
            this.setState({ transparent: false }, () => { });
        }
    }

    changeHeaderOnHamburgerClick() {
        if (!this.state.landing) return;
        if (this.state.menuOpened) {
            this.setState({ transparent: true, menuOpened: false }, () => { });
        } else {
            this.setState({ transparent: false, menuOpened: true }, () => { });
        }
    }

    openMyPage(url) {
        const { isEmailVerified } = this.props;

        if (isEmailVerified) {
            history.push(url);
        } else {
            this.setState({modalVerifyEmailVisible: true});
        }
    }

    isSubrole(t) {
        return ['EAP', 'EAS', 'EAC'].includes(t);
    }

    renderPublicItems() {
        const hidden = false;
        return (
            <>
            <Nav className={hidden ? "hidden" : "mr-auto"}>
                <Nav.Link onClick={() => history.push("/browse/projects")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                    <span className="icon-search"></span>
                    <FormattedMessage id="nav.labels.browse" defaultMessage="Browse" />
                </Nav.Link>
                <Nav.Link onClick={() => history.push("/competencies")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                    <span className="icon-competence"></span>
                    <FormattedMessage id="projects.competences" defaultMessage="Competencies" />
                </Nav.Link>
                <Nav.Link onClick={() => history.push("/help")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                    {/* <span className="icon-information"></span> */}
                    <FormattedMessage id="nav.labels.howitworks" defaultMessage="How it works" />
                </Nav.Link>
            </Nav>

            <Nav className={hidden ? "hidden" : "ml-auto"}>
                <Nav.Link onClick={() => history.push("/auth/login")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                    <FormattedMessage id="nav.labels.login" defaultMessage="Login" />
                </Nav.Link>
                <div className="separator-wrapper d-none d-lg-block">
                    <div className="header-separator"></div>
                </div>
                <Nav.Link onClick={() => history.push("/auth/register")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                    <FormattedMessage id="nav.labels.register" defaultMessage="Register" />
                </Nav.Link>
                <div className="btn-wrapper">
                    <div className="btn btn-header" onClick={() => history.push("/auth/login")} >
                        <FormattedMessage id="nav.labels.postaproject" defaultMessage="Post a project" />
                    </div>
                </div>
            </Nav>
        </>
        )
    }

    renderAuthItems() {
        const hidden = false;
        const { hasRole } = this.props;
        const type = this.props.role ? this.props.role.type : undefined;
        return (
            <>
            <Nav className={hidden ? "hidden" : "mr-auto"}>

                <Nav.Link onClick={() => history.push(`/browse/${type === 'DEV' ? 'tasks' : 'projects'}`)} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                    <span className="icon-search"></span>
                    <FormattedMessage id="nav.labels.browse" defaultMessage="Browse" />
                </Nav.Link>

                <Nav.Link onClick={() => history.push("/competencies")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                    <span className="icon-competence"></span>
                    <FormattedMessage id="projects.competences" defaultMessage="Competencies" />
                </Nav.Link>

                {(hasRole && !this.isSubrole(type)) &&
                    <Nav.Link onClick={() => history.push("/dashboard")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                        <span className="icon-dashboard"></span>
                        <FormattedMessage id="nav.labels.dashboard" defaultMessage="Dashboard" />
                    </Nav.Link>
                }

                {type && type === "DEV" &&
                    <>
                        <Nav.Link onClick={() => this.openMyPage("/my-work")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                            <span className="icon-projects"></span>
                            <FormattedMessage id="nav.labels.myWork" defaultMessage="My work" />
                        </Nav.Link>
                    </>
                }

                {type && type === "INV" &&
                    <>
                        <Nav.Link onClick={() => this.openMyPage("/my-projects")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                            <span className="icon-projects"></span>
                            <FormattedMessage id="nav.labels.myProjects" defaultMessage="My projects" />
                        </Nav.Link>
                    </>
                }

                {type && type === "AUD" &&
                    <>
                        <Nav.Link onClick={() => this.openMyPage("/my-audits")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                            <span className="icon-projects"></span>
                            <FormattedMessage id="nav.labels.myAudits" defaultMessage="My Audits" />
                        </Nav.Link>
                    </>
                }

                {(type && (type === "EDU" || type === "EAS" || type === "EAP"))  && 
                    <>
                        <Nav.Link onClick={() => this.openMyPage("/my-institution")} className={this.state.transparent ? "nav-link-landing" : "nav-link"}>
                            <span className="icon-projects"></span>
                            <FormattedMessage id="nav.labels.myInstitution" defaultMessage="My Institution" />
                        </Nav.Link>
                    </>
                }

            </Nav>

            <Nav className={hidden ? "hidden" : "ml-auto"}>

                {/* Notifications */}
                {hasRole && <DropdownNotifications transparent={this.state.transparent} />}

                {/* User menu */}
                <DropdownUserMenu transparent={this.state.transparent} />

            </Nav>
            <ModalResendActivationEmail visible={this.state.modalVerifyEmailVisible} onClose={() => this.setState({ modalVerifyEmailVisible: false })} />
        </>
        )
    }

    render() {
        const hidden = false;
        const { auth: { isAuthenticated } } = this.props;
        return (
            <Navbar id="ecta-header" sticky="top" expand="lg" className={this.state.transparent ? "navbar-landing sticky" : "sticky"}>
                <Container>

                    <Navbar.Brand onClick={() => history.push("/")}>
                        <img src={`/assets/img/${this.state.transparent ? "ecta_logo_white.png" : "ecta_logo.png"}`} height="30" className="d-inline-block align-top" alt="ECTA" />
                    </Navbar.Brand>

                    <Navbar.Toggle id="hamburger" aria-controls="responsive-navbar-nav" aria-expanded="false" aria-label="Toggle navigation" className={hidden ? "hidden" : "navbar-light"} />

                    <Navbar.Collapse id="responsive-navbar-nav">

                        { !isAuthenticated ? this.renderPublicItems() : this.renderAuthItems() }
                                                    
                        <Form inline>
                            <LanguageSelect transparent={this.state.transparent} />
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.user.auth,
        user: state.user.info,
        isEmailVerified: state.user.info.isEmailVerified,
        role: state.user.info.settings.active_role_obj,
        hasRole: state.user.info.settings.active_role
    }
}

export default connect(mapStateToProps, {})(Header);