import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import './index.scss';

class ContactPage extends React.Component {

    render() {
        return (
            <>
                <Container className="mt-4">
                    <h1><FormattedMessage id="contact" defaultMessage="Contact" /></h1>
                </Container>
            </>
        )
    }
}

export default injectIntl(connect(null, { })(ContactPage));