import React from 'react';
import './index.scss';
import Flag from "react-flags";
import Countries from '../../utils/i18n/countries.js'; // TO DISPLAY COUNTRY NAME... USE Countries[countryCode]
import { connect } from 'react-redux';
import { WorkingDays } from '../../utils/common';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectProfileStart } from '../../core/actions/role';
import { SelectProjectStart } from '../../core/actions/projects';
import ProfileReviews from './profileReviews';
import ParticipatedInProjects from './participatedInProjects';
import groupBy from 'lodash/groupBy';
import history from '../../utils/routes/history';
import ReactTooltip from 'react-tooltip';


class PublicProfilePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cover_image: this.props.role.cover_image,
            profile_image: this.props.role.profile_image,
            coverPreview: this.props.role.cover_image,
            avatarPreview: this.props.role.profile_image
        };
    }

    componentDidMount() {
        const param = this.props.match.params;
        if (param.id) {
            this.props.SelectProfileStart(param.id);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            this.setState({
                profile_image: nextProps.role.profile_image,
                avatarPreview: nextProps.role.profile_image,
                cover_image: nextProps.role.cover_image,
                coverPreview: nextProps.role.cover_image
            });
        }
    }

    getDurationNiceTextFromValue = value => {
        switch (value) {
            case 'Days':
                return 'd';
            case 'Weeks':
                return 'w';
            case 'Months':
                return ' mos'
            default:
                return 'N/A';
        }
    }

    renderAvailability() {
        const { role } = this.props;
        switch (role.availability) {
            case "F":
                return (<div className="val"><FormattedMessage id="setup_profile.full_time" defaultMessage="Full time" /><div className="val-muted">({WorkingDays(role.availability_days)})</div></div>);
            case "P":
                return (<div className="val"><FormattedMessage id="setup_profile.part_time" defaultMessage="Part time" /></div>);
            case "N":
                return (<div className="val"><FormattedMessage id="setup_profile.not_available" defaultMessage="Not accepting work" /></div>);
            default:
                return null;
        }
    }

    renderSidebar() {
        const { role } = this.props;

        switch (this.props.role.type) {

            case "DEV":
                return (
                    <>
                        <div className="card card-availability">
                            <div className="p-rates">

                                <div className="caption">
                                    <FormattedMessage id="profile.hourlyrate" defaultMessage="Hourly Rate" />
                                </div>
                                <div className="val">${role.hourly_rate}/h</div>

                                {role.availability && (<div className="caption"><FormattedMessage id="profile.availability" defaultMessage="Avaliability" /></div>)}
                                {this.renderAvailability()}

                                {role.project_duration_from && role.project_duration_to > 0 ?
                                    <>
                                        <div className="caption"><FormattedMessage id="setup_profile.projects_duration" defaultMessage="Projects duration" /></div>
                                        <div className="val">
                                            {console.log('role => ', role)}
                                            {role.project_duration_from_choice && role.project_duration_from ? `${role.project_duration_from}${this.getDurationNiceTextFromValue(role.project_duration_from_choice)}` : 'N/A'}
                                            <span>&nbsp;-&nbsp;</span>
                                            {role.project_duration_to_choice && role.project_duration_to ? `${role.project_duration_to}${this.getDurationNiceTextFromValue(role.project_duration_to_choice)}` : 'N/A'}
                                        </div>
                                    </>
                                    :
                                    <div></div>
                                }

                                {role.task_remuneration_from && role.task_remuneration_to > 0 ?
                                    <>
                                        <div className="caption"><FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" /></div>
                                        <div className="val">{role.task_remuneration_from ? parseInt(role.task_remuneration_from) : 'N/A'} - {role.task_remuneration_to ? parseInt(role.task_remuneration_to) : 'N/A'} USD</div>
                                    </>
                                    :
                                    <div></div>
                                }

                            </div>

                            <div className="p-stats">
                                <div className="p-stats-item">
                                    <span>{role?.completed_tasks_count ?? 0}</span>
                                    <span><FormattedMessage id="profile.completedtasks" defaultMessage="completed tasks" /></span>
                                </div>
                                <div className="p-stats-item">
                                    <span>{role?.participated_in_projects?.length ?? 0}</span>
                                    <span><FormattedMessage id="profile.participatedinprojects" defaultMessage="participated in projects" /></span>
                                </div>
                                <div className="p-stats-item">
                                    <span>{role?.rating?.reviewer_count ?? 0}</span>
                                    <span><FormattedMessage id="profile.reviews" defaultMessage="reviews" /></span>
                                </div>
                            </div>
                        </div>
                        {/* <span className="inbetween"><FormattedMessage id="profile.inthepastweek" defaultMessage="In the past week" /></span>
                        <div className="card" style={{ height: "308px" }}>
                            <FormattedMessage id="profile.clientswholookedfor" defaultMessage="Clients who looked for" />...
                        </div> */}
                    </>
                );

            case "INV":
                return (
                    <>
                        <div className="card card-availability">
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                        </div>
                    </>
                );

            case "EDU":
            case "AUD":
                break;
            default:
                break;
        }
    }

    renderEarned() {
        const { role } = this.props;
        const group = groupBy(role.user_competences, 'issuer.name');
        return (
            <>
                <div className="c-section-title">
                    <FormattedMessage id="common.earned" defaultMessage="EARNED" /> &nbsp;
                    <div className="info-icon-wrap">
                        <img src="/assets/img/icon/info.png" data-tip data-for='earned' alt="info-icon" width="13"></img>
                    </div>
                </div>
                <ReactTooltip id="earned" place="right" type="dark" effect="solid">
                    <div className="tooltip-txt">
                        <FormattedMessage id="tooltip.earned_competencies" defaultMessage="Competencies the user was awarded either by completing tasks or through a verified educational program." />
                    </div>
                </ReactTooltip>
                {Object.keys(group).map((c, i) =>
                    <div key={i} className="mb-2">
                        <div className="c-group-title">
                            <FormattedMessage id="issuers.issued_by" defaultMessage="Issued by" />:
                            <span className="clink" onClick={() => history.push('/user/' + c.owner.id)}>{c.toUpperCase()}</span>
                        </div>
                        <div className="card-tags">
                            {group[c].map((grp, x) => <span onClick={() => history.push('/competency/' + grp.competence.id)} key={x}>{grp.issuer_competence_name} L{grp.competence?.level}</span>)}
                        </div>
                    </div>
                )}
                <hr />
            </>
        )
    }

    render() {
        const { role } = this.props;
        const roleType = role.type ? "roles.name." + role.type : "roles.name.DEV";
        return (
            <>
                <Container className="mt-4">
                    <Row>
                        <Col md={12} lg={9}>

                            <div className="card card-profile">

                                <div className="sp-header">

                                    <div className="sp-cover">
                                        <Image src={this.state.coverPreview ? this.state.coverPreview : "/assets/img/profile-cover.png"} className="p-cover-img"></Image>
                                    </div>

                                    <div className="sp-avatar-wrapper">
                                        <Image src={this.state.avatarPreview ? this.state.avatarPreview : "/assets/img/default-avatar.png"} className="sp-avatar"></Image>
                                    </div>

                                </div>

                                <div className="p-details">
                                    <div className="p-rating">
                                        <div className="p-location">
                                            <Flag
                                                className="p-flag"
                                                basePath="/assets/img"
                                                name={role.country ? role.country : 'AA'}
                                                format="png"
                                                pngSize={32}
                                                shiny={true}
                                                alt={Countries[role.country]}
                                            />
                                            {role.city ? role.city : <FormattedMessage id="profile.nocity" defaultMessage="No city" />}
                                        </div>
                                        <span>3 <FormattedMessage id="profile.recommendations" defaultMessage="recommendations" /></span>
                                        <span>
                                            <FormattedMessage id="plural.profile.reviews" values={{ count: role?.rating?.reviewer_count ?? 0 }}
                                                defaultMessage={`${role?.rating?.reviewer_count ?? 0} Reviews`} />
                                        </span>
                                    </div>

                                    <div className="p-info">
                                        <div className="p-info-name">
                                            <span>{role.name}</span>
                                            {role.rating.rating &&
                                                <>
                                                    <span className="p-star" role="img" aria-label="Star rating">⭐</span>
                                                    <span>{role.rating.rating}/<small>5</small></span>
                                                </>
                                            }
                                        </div>
                                        <div className="p-info-tagline">
                                            <FormattedMessage id={roleType} defaultMessage="" />
                                        </div>
                                        <div className="p-info-status">● <FormattedMessage id="profile.online" defaultMessage="Online" /></div>
                                        {role.description
                                            ?
                                            <div className="p-description">
                                                <span>{role.description}</span>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>

                                </div>

                            </div>

                            {/* COMPETENCES */}

                            {role.type === "DEV" &&
                                <>
                                    <div className="card p-0">
                                        <div className="card-head"><FormattedMessage id="profile.competences" defaultMessage="Competences" />{role.user_competences?.length > 0 && <span className=""> ({role.user_competences?.length})</span>}</div>
                                        <div className="card-body">

                                            {/* EARNED from completed tasks and confirmed educations */}
                                            {role.user_competences?.length > 0 && this.renderEarned()}

                                            {/* USE DEFINED */}
                                            {role.competences?.length > 0
                                                ?
                                                <>
                                                    <div className="c-section-title">
                                                        <FormattedMessage id="common.user_defined" defaultMessage="USER DEFINED" /> &nbsp;
                                                        <div className="info-icon-wrap">
                                                            <img src="/assets/img/icon/info.png" data-tip data-for='user-defined' alt="info-icon" width="13"></img>
                                                        </div>
                                                    </div>
                                                    <ReactTooltip id="user-defined" place="right" type="dark" effect="solid">
                                                        <div className="tooltip-txt">
                                                            <FormattedMessage id="tooltip.userdefined_competencies" defaultMessage="Competencies added to his profile by the user himself or herself." />
                                                        </div>
                                                    </ReactTooltip>
                                                    <div className="card-tags">
                                                        {role.competences.map((c, i) => <span onClick={() => history.push('/competency/' + c.id)} key={i}>{c.name} L{c.level}</span>)}
                                                    </div>
                                                </>
                                                :
                                                <div className="p-section empty-section">
                                                    <div className="">
                                                        <Image src="/assets/img/svg/competences.svg" className="section-img"></Image>
                                                    </div>
                                                    <div className="">
                                                        <div className=""><FormattedMessage id="competences.add.desc" defaultMessage="Add your competences and we will suggest the perfect projects for you!" /></div>
                                                        <div className="add-link" onClick={() => this.setState({ modalFreelancerCompetencesVisible: true })}><FormattedMessage id="competences.add" defaultMessage="Add Competences" /></div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </>
                            }

                            {/* EXPERIENCE */}
                            {role.type === "DEV" &&
                                <>
                                    <div className="card p-0">
                                        <div className="card-head"><FormattedMessage id="profile.experience" defaultMessage="Experience" /></div>
                                        <div className="card-body">
                                            {role.experiences.length > 0
                                                ?
                                                <div className="section-rows">
                                                    {role.experiences.map((e, i) =>
                                                        <div className="section-row" key={i}>
                                                            {/* <Image src="/assets/img/default-ex.png" className="sec-img"></Image> */}
                                                            <div className="sec-info">
                                                                <div className="">{e.company_name}</div>
                                                                <div className="">{e.title}</div>
                                                                <div className="">{e.date_from && e.date_from.substring(0, 4)} - {e.date_to && e.date_to.substring(0, 4)}</div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                :
                                                ''
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            {/* EDUCATION */}
                            {role.type === "DEV" &&
                                <>
                                    <div className="card p-0">
                                        <div className="card-head"><FormattedMessage id="profile.education" defaultMessage="Education" /></div>
                                        <div className="card-body">
                                            {role.educations.length > 0
                                                ?
                                                <div className="section-rows">
                                                    {role.educations.map((edu, i) =>
                                                        <div className="section-row" key={i}>
                                                            {/* <Image src="/assets/img/default-e.png" className="sec-img"></Image> */}
                                                            <div className="sec-info">
                                                                <div className="">{edu.school}</div>
                                                                <div className="">{edu.program}</div>
                                                                <div className="">{edu.date_from && edu.date_from.substring(0, 4)} - {edu.date_to && edu.date_to.substring(0, 4)}</div>
                                                            </div>
                                                            {(edu.verified) && <div className="is-verified"><div className="icon-educational_institutions"></div><small><FormattedMessage id="common.verified" defaultMessage="Verified" /></small></div>}
                                                            <div className="request-verification"></div>
                                                        </div>
                                                    )}
                                                </div>
                                                :
                                                ''
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            {/* REVIEWS */}
                            {role.type !== "EDU" && <ProfileReviews reviews={this.props.reviews} />}

                            {/* PARTICIPATED & PUBLISHED PROJECTS */}
                            <ParticipatedInProjects role={role} />

                        </Col>

                        <Col md={12} lg={3}>
                            {this.renderSidebar()}
                        </Col>

                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.browse.profile.selected,
        reviews: state.browse.profile.selected.reviews
    }
}

export default injectIntl(connect(mapStateToProps, {
    SelectProfileStart,
    SelectProjectStart
})(PublicProfilePage));