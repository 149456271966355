import * as types from './types';
import { apiCon } from '../../../API'
import { langService } from '../../../utils/languageService';
import { GetProfileCompletionStart } from '../auth';

//----------------------
// Actions Select Experience
//----------------------
export const SelectExperienceStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_EXPERIENCE_REQUEST });
    await apiCon.get('/me/role/detail/experience/' + id)
        .then(response => {
            dispatch(SelectExperienceSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectExperienceFail(err.response));
        })
}
export const SelectExperienceSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received profile Experience info.", selected: data }
    dispatch({ type: types.SELECT_EXPERIENCE_SUCCESS, payload: payload });
}
export const SelectExperienceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select Experience failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Select Experience failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_EXPERIENCE_FAILURE, payload: payload };
}

//----------------------
// Actions Create Experience
//----------------------
export const CreateExperienceStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_EXPERIENCE_REQUEST });
    await apiCon.post(`/me/role/detail/experiences/`, values)
        .then(response => {
            dispatch(CreateExperienceSuccess(response.data));
            dispatch(GetProfileCompletionStart());
        })
        .catch(err => {
            dispatch(CreateExperienceFail(err.response));
        })
}

export const CreateExperienceSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully created Experience.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, experience: data };
    dispatch({ type: types.CREATE_EXPERIENCE_SUCCESS, payload: payload });
}

export const CreateExperienceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.experience.create.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.CREATE_EXPERIENCE_FAILURE, payload: payload };
}

//----------------------
// Actions Update Experience
//----------------------
export const UpdateExperienceStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_EXPERIENCE_REQUEST });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `/me/role/detail/experience/` + values.id;
    delete values['id']
    const json = JSON.stringify(values);
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateExperienceSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateExperienceFail(err.response));
        })
}

export const UpdateExperienceSuccess = (data) => {
    const succ_msg = "Successfully updated Experience.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, experience: data };
    return { type: types.UPDATE_EXPERIENCE_SUCCESS, payload: payload };
}

export const UpdateExperienceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.experience.update.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_EXPERIENCE_FAILURE, payload: payload };
}

//----------------------
// Actions Delete Experience
//----------------------
export const DeleteExperienceStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_EXPERIENCE_REQUEST });
    const address = `/me/role/detail/experience/` + id;
    await apiCon.delete(address)
        .then((response) => {
            dispatch(DeleteExperienceSuccess(id));
            dispatch(GetProfileCompletionStart());
        })
        .catch(err => {
            dispatch(DeleteExperienceFail(err.response));
        })
}

export const DeleteExperienceSuccess = (id) => {
    const succ_msg = "Successfully deleted Experience.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_EXPERIENCE_SUCCESS, payload: payload };
}

export const DeleteExperienceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.experience.delete.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_EXPERIENCE_FAILURE, payload: payload };
}