import React from 'react';
import './modalAcceptTaskProposal.scss';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import TaskProposalContent from '../Tasks/taskProposalContent';

const customStyles = {
    height: 'auto',
    bottom: 'auto',
    top: '15%'
};

class ModalAcceptTaskProposal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // btnSaveDisabled: true,
        };
    }

    hide() {
        this.props.onClose();
    }

    accept() {
        this.props.onAccept();
    }

    render() {
        const { visible, tp, selectedTask } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={666}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        <FormattedMessage id="forms.labels.accept_proposal" defaultMessage="Are you sure you want to accept this task proposal?" />
                    </div>

                    <div className="modal-body p-0 pt-4">

                        <div className="proposal-box">
                            <div className="">
                                <FormattedMessage id="common.task" defaultMessage="Task" />
                            </div>
                            <div className="headline">{tp.task.name}</div>
                            <hr></hr>

                            <div className="proposal-terms">
                                <TaskProposalContent selectedTask={selectedTask} tp={tp} />
                            </div>

                        </div>

                        <div className="modal-submit-buttons">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.accept()}>
                                <FormattedMessage id="common.accept" defaultMessage="Accept" />
                            </button>
                        </div>

                    </div>

                </div>
            </Rodal>
        )
    }

}

const mapStateToProps = state => {
    return {
        // tasks: state.myWork.myProjects.selected.tasks,
    }
}

export default injectIntl(connect(mapStateToProps, {

})(ModalAcceptTaskProposal));