import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import './CountDownTimer.scss';
import { injectIntl, FormattedMessage } from 'react-intl';


class CountdownTimer extends React.Component {
    state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            const { endDate } = this.props;
            const now = moment();

            //Rerender after seconds
            const countdown = moment.duration(endDate.diff(now));
            const days = countdown.days();
            const hours = countdown.hours();
            const minutes = countdown.minutes();
            const seconds = countdown.seconds();

            //Does not rerender after seconds
            // const countdown = moment(endDate - now);
            // const days = countdown.format('D');
            // const hours = countdown.format('HH');
            // const minutes = countdown.format('mm');
            // const seconds = countdown.format('ss');

            this.setState({ days, hours, minutes, seconds });
        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { days, hours, minutes, seconds } = this.state;
        const daysRadius = mapNumber(days, 30, 0, 0, 360);
        const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
        const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
        const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

        return (
            <div>
                <div className='countdown-wrapper'>
                    <div className='countdown-item'>
                        <SVGCircle radius={daysRadius} />
                        {days}
                        <span><FormattedMessage id="common.days" defaultMessage="days" /></span>
                    </div>
                    <div className='countdown-item'>
                        <SVGCircle radius={hoursRadius} />
                        {hours}
                        <span><FormattedMessage id="common.hours" defaultMessage="hours" /></span>
                    </div>
                    <div className='countdown-item'>
                        <SVGCircle radius={minutesRadius} />
                        {minutes}
                        <span><FormattedMessage id="common.mins" defaultMessage="min" /></span>
                    </div>
                    <div className='countdown-item'>
                        <SVGCircle radius={secondsRadius} />
                        {seconds}
                        <span><FormattedMessage id="common.secs" defaultMessage="sec" /></span>
                    </div>
                </div>
            </div>
        );
    }
}

const SVGCircle = ({ radius }) => (
    <svg className='countdown-svg'>
        {/* <path fill="none" stroke="#333" strokeWidth="3" d={describeArc(50, 50, 48, 0, radius)} /> */}
        <path fill="none" stroke="#333" strokeWidth="3" d={describeArc(30, 30, 24, 0, radius)} />
    </svg>
);


// From stackoverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

function describeArc(x, y, radius, startAngle, endAngle) {

    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
}

// Stackoverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
    return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

export default injectIntl(connect(null, {})(CountdownTimer));