import * as types from './types';
import { apiCon } from '../../../API'
import { getUrlParameterObject } from './../../../utils/common/index';

//----------------------
// Actions Milestones
//----------------------

//----------------------
// Actions Get My Milestone List
//----------------------
export const GetMyMilestonesStart = (project, search, ordering, limit = 100) => async dispatch => {
    dispatch({ type: types.GET_MY_MILESTONES_REQUEST });
    var url = '/me/project/milestones/'
    const params = {
        'limit': limit,
        'search': search,
        'project': project,
        'ordering': ordering,
    }
    const filters = getUrlParameterObject(params)
    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetMyMilestonesSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyMilestonesFail(err.response));
        })
}
export const GetMyMilestonesSuccess = (data) => {
    const payload = { succ_msg: "Successfully received My Milestones info.", list: data }
    return { type: types.GET_MY_MILESTONES_SUCCESS, payload: payload };
}
export const GetMyMilestonesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Milestones failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get My Milestones failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_MILESTONES_FAILURE, payload: payload }
}


//----------------------
// Actions Select My Milestone
//----------------------
export const SelectMyMilestoneStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_MY_MILESTONE_REQUEST });
    await apiCon.get('/me/project/milestone/' + id)
        .then(response => {
            dispatch(SelectMyMilestoneSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectMyMilestoneFail(err.response));
        })
}
export const SelectMyMilestoneSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received My Milestone info.", selected: data }
    dispatch({ type: types.SELECT_MY_MILESTONE_SUCCESS, payload: payload });
}
export const SelectMyMilestoneFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select My Milestone failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Select My Milestone failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_MY_MILESTONE_FAILURE, payload: payload };
}

//----------------------
// Actions Get Milestone List
//----------------------
export const GetMilestonesStart = (project, search, ordering, limit = 100) => async dispatch => {
    dispatch({ type: types.GET_MILESTONES_REQUEST });
    var url = '/project/milestones/'
    const params = {
        'limit': limit,
        'search': search,
        'project': project,
        'ordering': ordering,
    }
    const filters = getUrlParameterObject(params)
    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetMilestonesSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMilestonesFail(err.response));
        })
}
export const GetMilestonesSuccess = (data) => {
    const payload = { succ_msg: "Successfully received Milestones info.", list: data }
    return { type: types.GET_MILESTONES_SUCCESS, payload: payload };
}
export const GetMilestonesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Milestones failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get Milestones failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MILESTONES_FAILURE, payload: payload }
}


//----------------------
// Actions Select Milestone
//----------------------
export const SelectMilestoneStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_MY_MILESTONE_REQUEST });
    await apiCon.get('/project/milestone/' + id)
        .then(response => {
            dispatch(SelectMilestoneSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectMilestoneFail(err.response));
        })
}
export const SelectMilestoneSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received Milestone info.", selected: data }
    dispatch({ type: types.SELECT_MILESTONE_SUCCESS, payload: payload });
}
export const SelectMilestoneFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select Milestone failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Select Milestone failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_MILESTONE_FAILURE, payload: payload };
}

//----------------------
// Actions Create Milestone
//----------------------
export const CreateMilestoneStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_MILESTONE_REQUEST });
    await apiCon.post(`/me/project/milestones/`, values)
        .then(response => {
            dispatch(CreateMilestoneSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateMilestoneFail(err.response));
        })
}

export const CreateMilestoneSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully created Milestone.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, milestone: data };
    dispatch({ type: types.CREATE_MILESTONE_SUCCESS, payload: payload });
}

export const CreateMilestoneFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Create Milestone failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create Milestone failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.CREATE_MILESTONE_FAILURE, payload: payload };
}

//----------------------
// Actions Update Milestone
//----------------------
export const UpdateMilestoneStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_MILESTONE_REQUEST });
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `/me/project/milestone/` + values.id;
    delete values['id']
    const json = JSON.stringify(values);
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateMilestoneSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateMilestoneFail(err.response));
        })
}

export const UpdateMilestoneSuccess = (data) => {
    const succ_msg = "Successfully updated Milestone.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, milestone: data };
    return { type: types.UPDATE_MILESTONE_SUCCESS, payload: payload };
}

export const UpdateMilestoneFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Update Milestone failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Update Milestone failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_MILESTONE_FAILURE, payload: payload };
}

//----------------------
// Actions Delete Milestone
//----------------------
export const DeleteMilestoneStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_MILESTONE_REQUEST });
    const address = `/me/project/milestone/` + id;
    await apiCon.delete(address)
        .then((response) => {
            dispatch(DeleteMilestoneSuccess(id));
        })
        .catch(err => {
            dispatch(DeleteMilestoneFail(err.response));
        })
}

export const DeleteMilestoneSuccess = (id) => {
    const succ_msg = "Successfully deleted Milestone.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_MILESTONE_SUCCESS, payload: payload };
}

export const DeleteMilestoneFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Delete Milestone failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Delete Milestone failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_MILESTONE_FAILURE, payload: payload };
}
