import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './index.scss';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CreateDegreeStart, UpdateDegreeDetailsStart } from '../../core/actions/institution/degree';

const customStyles = {
    width: 'inherit',
    height: '315px', //'inherit',
    maxWidth: '500px',
    maxHeight: '85%',
    bottom: 'auto',
    top: '15%'
};


class ModalCreateDegree extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.degree ? this.props.degree.name : '',
            description: this.props.degree ? this.props.degree.description : '',
            validated: false,
            edit: this.props.degree?.id ? true : false
        };
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        // form validation
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }

        this.setState({ validated: true });
    };

    save() {   
        if (this.state.edit) {
            this.props.UpdateDegreeDetailsStart({
                id: this.props.degree.id,
                name: this.state.name,
                description: this.state.description,
            }).then(
                this.setState({ name: '', description: '', validated: false, edit: false }),
                this.props.onConfirm(),
                this.props.onClose()
            );            
        } else {
            this.props.CreateDegreeStart({
                name: this.state.name,
                description: this.state.description,
            }).then(
                this.setState({ name: '', description: '', validated: false, edit: false }),
                this.props.onConfirm(),
                this.props.onClose()
            );
        }
    }

    cancel() {
        this.setState({ name: '', description: '', validated: false, edit: false });
        this.props.onClose();
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.cancel()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        { this.state.edit
                            ? <FormattedMessage id="degrees.edit" defaultMessage="Edit degree" />
                            : <FormattedMessage id="degrees.addnew" defaultMessage="Add a New Degree" />
                        }
                    </div>

                    <div className="modal-body p-2 pt-3">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formName">
                                    <Form.Label><FormattedMessage id="forms.labels.name" defaultMessage="Name" /></Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('name')} value={this.state.name || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formEmail">
                                    <Form.Label><FormattedMessage id="forms.labels.description" defaultMessage="Description" /></Form.Label>
                                    <Form.Control as="textarea" rows="3" onChange={this.handleChange('description')} value={this.state.description || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2">
                                <FormattedMessage id="common.save" defaultMessage="Save" />
                            </button>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.cancel()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>

                        </Form>

                    </div>

                </div>
            </Rodal >
        )
    }

}

export default injectIntl(connect(null, {
    CreateDegreeStart,
    UpdateDegreeDetailsStart
})(ModalCreateDegree));