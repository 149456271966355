//Competence SKILLs
export const GET_COMPETENCE_SKILLS_REQUEST = "GET_COMPETENCE_SKILLS_REQUEST";
export const GET_COMPETENCE_SKILLS_SUCCESS = "GET_COMPETENCE_SKILLS_SUCCESS";
export const GET_COMPETENCE_SKILLS_FAILURE = "GET_COMPETENCE_SKILLS_FAILURE";

export const CREATE_COMPETENCE_SKILL_REQUEST = "CREATE_COMPETENCE_SKILL_REQUEST";
export const CREATE_COMPETENCE_SKILL_SUCCESS = "CREATE_COMPETENCE_SKILL_SUCCESS";
export const CREATE_COMPETENCE_SKILL_FAILURE = "CREATE_COMPETENCE_SKILL_FAILURE";

export const VOTE_FOR_COMPETENCE_SKILL_REQUEST = "VOTE_FOR_COMPETENCE_SKILL_REQUEST";
export const VOTE_FOR_COMPETENCE_SKILL_SUCCESS = "VOTE_FOR_COMPETENCE_SKILL_SUCCESS";
export const VOTE_FOR_COMPETENCE_SKILL_FAILURE = "VOTE_FOR_COMPETENCE_SKILL_FAILURE";

export const DELETE_VOTE_FOR_COMPETENCE_SKILL_REQUEST = "DELETE_VOTE_FOR_COMPETENCE_SKILL_REQUEST";
export const DELETE_VOTE_FOR_COMPETENCE_SKILL_SUCCESS = "DELETE_VOTE_FOR_COMPETENCE_SKILL_SUCCESS";
export const DELETE_VOTE_FOR_COMPETENCE_SKILL_FAILURE = "DELETE_VOTE_FOR_COMPETENCE_SKILL_FAILURE";