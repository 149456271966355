import React from 'react'
import './contactList.scss';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectChatStart, SendMessageStart } from '../../core/actions/chat';
import { Image } from 'react-bootstrap';
import { langService } from './../../utils/languageService/index';

export class ContactList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chatWith: null,
      selectedUser: null,
      selectedTask: null,
      message: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ message: e.target.value });
  }

  openForm() {
    document.getElementById("myForm").style.display = "block";
  }

  closeForm() {
    document.getElementById("myForm").style.display = "none";
  }

  openChat(p) {
    if (this.props.role.type === "INV") {
      this.setState({ chatWith: p.freelancer, chatUserId: p.freelancer?.id, chatTaskId: p?.task.id });
      this.props.SelectChatStart(p.freelancer?.id, (p.task ? p.task.id : null));
    } else {
      this.setState({ chatWith: p.client, chatUserId: p.client?.id, chatTaskId: p?.task.id });
      this.props.SelectChatStart(p.client?.id, (p.task ? p.task.id : null));
    }
    document.getElementById("myChat").style.display = "block";
  }

  closeChat() {
    document.getElementById("myChat").style.display = "none";
  }

  sendMessage() {
    if (this.state.message === '') { return; }

    this.props.SendMessageStart(
      this.state.chatUserId,
      this.state.chatTaskId,
      this.state.message,
      null //files
    );

    this.setState({ message: '' });
  }

  renderChat() {
    const { selectedChat } = this.props;
    return (
      <div className="chat-box" id="myChat">

        <div className="chatbox-header">
          <div>
            <span>{this.state?.chatWith?.name} </span>
            <span className="chat-circle status-color-active"> ●</span>
            <span className="close chat-close" onClick={() => this.closeChat()}></span>
          </div>
          <div>
            <FormattedMessage id="roles.name.DEV" defaultMessage="Freelancer" />
          </div>
        </div>

        <div className="chat-log">

          {selectedChat.map((c, i) =>
            <div className="message" key={i}>
              {c.message}
            </div>
          )}

        </div>

        <div className="chatbox-bottom">
          <textarea className="chatbox-textarea" value={this.state.message} onChange={this.handleChange} placeholder={langService.messages['forms.placeholders.messages']}></textarea>
          <Image src="/assets/img/svg/send.svg" onClick={() => this.sendMessage()} />
        </div>

      </div>
    )
  }

  render() {
    const { proposals, role } = this.props;
    return (
      <>
        <button className="open-button" onClick={() => this.openForm()}>
          <span className="chat-circle status-color-active">●</span>
          <span style={{ marginLeft: "6px" }}>Messaging</span>
        </button>

        <div className="chat-popup" id="myForm">

          <div className="chat-popup-header" onClick={() => this.closeForm()}>
            <span className="chat-circle status-color-active">●</span>
            <span style={{ marginLeft: "6px" }}>Messaging</span>
          </div>

          <div className="form-container">

            {proposals.length > 0
              ?
              <div className="messaging-task">
                Task: {proposals[0].task.name}
              </div>
              : ''
            }

            {/* CONTACT LIST */}
            {proposals.map((p, i) =>

              <div className="user-row" key={i} onClick={() => this.openChat(p)}>

                {role.type === "INV"
                  ?
                  <>
                    {p.freelancer.profile_image
                      ? <Image src={p.freelancer.profile_image} className="chat-thumb" />
                      : <Image src="/assets/img/default-avatar.png" className="chat-thumb" />
                    }
                    <div className="user-name">
                      <div key={i}>{p.freelancer.name}</div>
                    </div>
                  </>
                  :
                  <>
                    {p.client.profile_image
                      ? <Image src={p.client.profile_image} className="chat-thumb" />
                      : <Image src="/assets/img/default-avatar.png" className="chat-thumb" />
                    }
                    <div className="user-name">
                      <div key={i}>{p.client.name}</div>
                    </div>
                  </>
                }
              </div>
            )}

          </div>
        </div>

        {this.renderChat()}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    role: state.user.info.settings.active_role_obj,
    chats: state.chat.list.results,
    selectedChat: state.chat.selected.messages.results,
    proposals: state.myWork.myTaskProposals.list.results,
  }
}

export default injectIntl(connect(mapStateToProps, {
  SelectChatStart,
  SendMessageStart
})(ContactList));

