import React from 'react';
import { connect } from 'react-redux';
import '../../containers/Competencies/Competency/index.scss';
import { langService } from '../../utils/languageService/index';
import { injectIntl, FormattedMessage } from 'react-intl';
import { VoteForCompetenceDescriptionStart, DeleteVoteForCompetenceDescriptionStart } from '../../core/actions/competenceDescription';
import { getBloomVerb } from '../../utils/common/misc';
import { Card } from 'react-bootstrap';
import VotingButtons from './votingButtonsComponent';
import ContributedBy from './contributedByComponent';

class DescriptionVoting extends React.Component {

    callActionBasedOnUserVote = (id, user_voted, vote_for) => {
        if (user_voted && user_voted === vote_for) {
            this.props.DeleteVoteForCompetenceDescriptionStart(id);
        } else {
            this.props.VoteForCompetenceDescriptionStart(id, vote_for);
        }
    }

    render() {
        const { data, isAuth } = this.props;
        if (!data) return 'loading';
        return (
            <div className="card-list-projects">
                {data?.map((item, i) =>
                    <Card className="card-item card-item-developers" key={i}>
                        <Card.Body>
                            <div className="vote-item" key={item.id}>
                                <VotingButtons isAuth={isAuth} item={item} onVote={(id, user_voted, vote_for) => this.callActionBasedOnUserVote(id, user_voted, vote_for)} />
                                <div className="voting-content">
                                    <div className="form-label"><FormattedMessage id="forms.labels.description" defaultMessage="Description" /></div>
                                    <div className="cedesc">
                                        <FormattedMessage id="competences.this_person_can" defaultMessage="This person can " />
                                        <strong>{getBloomVerb(2, parseInt(item.sub_level), langService.locale)}</strong>&nbsp;
                                        {item.description ? item.description : "..."}
                                    </div>
                                    <ContributedBy item={item} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                )}
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        data: state.competence.selected.competence_descriptions?.list?.results,
        isAuth: state.user.auth.isAuthenticated
    }
}

export default injectIntl(connect(mapStateToProps, {
    VoteForCompetenceDescriptionStart,
    DeleteVoteForCompetenceDescriptionStart
})(DescriptionVoting));