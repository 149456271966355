import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './index.scss';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CreateProgramStart, UpdateProgramDetailsStart } from '../../core/actions/institution/program';
import { GetEduDegreesStart } from '../../core/actions/institution/degree';
import ModalCreateDegree from './modalCreateDegree';
import CustomSelect from '../../components/Common/CustomSelect';
import ModalCreateANewCompetence from '../../components/Competence/Create/modalCreateANewCompetence';


class ModalCreateProgram extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.program ? this.props.program.name : '',
            description: this.props.program ? this.props.program.description : '',
            degree: this.props.program ? this.props.program.degree : '',
            skills: this.getSlugs(),
            validated: false,
            modalCreateDegreeVisible: false,
            modalCreateCompetenceVisible: false,
            edit: this.props.program?.id ? true : false,
            competencyValid: true,
            issuers: [this.props.selectedIssuer?.owner]
        };
    }

    componentDidMount() {
        if (!this.props.program && this.props.degrees.length > 0) {
            this.setState({degree: this.props.degrees[0].id});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.degrees) {
            this.setState({degree: nextProps.degrees[0]?.id});
        }
        if (nextProps.selectedIssuer?.owner) {
            this.setState({issuers: [nextProps.selectedIssuer.owner]});
        }
    }

    getSlugs() {
        const arr = [];
        if (this.props.program?.competences) {
            this.props.program.competences.map(x => 
                arr.push(x.value.toString())
            )
        }
        return arr;
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    onCompetenciesChanged = (values) => {
        this.setState({skills: values?.map(x => x.value), competencyValid: true},
        () => {
            // process.env.NODE_ENV !== "production" && console.log(this.state.skills)
        })
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        if (this.state.skills.length === 0) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ competencyValid: false, validated: true });
            return;
        } else {
            this.setState({ competencyValid: true });
        }

        // form validation
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }

        this.setState({ validated: true });
    };

    save() {   
        if (this.state.edit) {
            this.props.UpdateProgramDetailsStart({
                id: this.props.program.id,
                name: this.state.name,
                description: this.state.description,
                degree: this.state.degree.id,
                competences: this.state.skills
            }).then(
                this.setState({ name: '', description: '', skills: [], degree: '', validated: false, edit: false }),
                this.props.onConfirm(),
                this.props.onClose()
            );
        } else {
            this.props.CreateProgramStart({
                name: this.state.name,
                description: this.state.description,
                degree: this.state.degree,
                competences: this.state.skills
            }).then(
                this.setState({ name: '', description: '', skills: [], degree: '', validated: false, edit: false }),
                this.props.onConfirm(),
                this.props.onClose()
            );
        }
    }

    cancel() {
        this.setState({ name: '', description: '', validated: false, edit: false });
        this.props.onClose();
    }

    onNewDegreeAdded() {
        this.props.GetEduDegreesStart();
    }

    onNewCompetencyAdded() {
        // refresh select...change issuer to null and back
        const temp = this.state.issuers;
        this.setState({ issuers: [] });
        setTimeout(() => {
            this.setState({ issuers: temp, modalCreateCompetenceVisible: false });
        }, 300);
    }

    render() {
        const { visible, degrees } = this.props;
        return (
            <>
            <Rodal className="new-competence-rodal"
                visible={visible}
                onClose={() => this.cancel()}
                customStyles={{ height: 'auto', bottom: 'auto', top: '15%', width: '450px' }}
                closeMaskOnClick={false}>
                <div>

                    <div className="modal-title">
                        {this.state.edit
                            ? <FormattedMessage id="programs.edit" defaultMessage="Edit program" />
                            : <FormattedMessage id="programs.addnew" defaultMessage="Add new program" />
                        }
                    </div>

                    <div className="modal-body">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formName">
                                    <Form.Label><FormattedMessage id="common.name" defaultMessage="Name" /></Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('name')} value={this.state.name || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formEmail">
                                    <Form.Label><FormattedMessage id="forms.labels.description" defaultMessage="Description" /></Form.Label>
                                    <Form.Control as="textarea" rows="2" onChange={this.handleChange('description')} value={this.state.description || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formDegree">
                                    <Form.Label><FormattedMessage id="edu.degree" defaultMessage="Degree" /></Form.Label>
                                    <Form.Control as="select" onChange={this.handleChange('degree')} value={this.state.degree} required >
                                        <option disabled key={-1} value={-1}>Select or create a new degree...</option>
                                        {degrees ? degrees.map((d) => <option key={d.id} value={d.id}>{d.name}</option>) : ''}
                                    </Form.Control>
                                    <div className="form-sublabel create-competence" onClick={() => this.setState({modalCreateDegreeVisible: true})}>
                                        + <FormattedMessage id="degrees.new" defaultMessage="New degree" />
                                    </div>
                                </Form.Group>
                            </Form.Row>

                            <Form.Group>
                                <Form.Label>
                                    <FormattedMessage id="programs.your_competencies" defaultMessage="Competencies" />
                                </Form.Label>
                                <CustomSelect 
                                    // issuers={selectedIssuer.owner ? [selectedIssuer.owner] : []}
                                    issuers={this.state.issuers}
                                    isMulti={true}
                                    selected={this.props.program?.competences}
                                    onSelectedChange={this.onCompetenciesChanged}
                                />
                                <div className="form-sublabel create-competence" onClick={() => this.setState({modalCreateCompetenceVisible: true})}>
                                    + <FormattedMessage id="competency.new" defaultMessage="New Competency" /> 
                                </div>
                                {!this.state.competencyValid && <small className="text-danger">Please select or create at least one competency</small>}
                            </Form.Group>                               

                            <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2">
                                <FormattedMessage id="common.save" defaultMessage="Save" />
                            </button>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.cancel()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>

                        </Form>

                    </div>

                </div>
            </Rodal >
            {this.state.modalCreateDegreeVisible && <ModalCreateDegree visible={this.state.modalCreateDegreeVisible} onConfirm={() => this.onNewDegreeAdded()} onClose={() => this.setState({ modalCreateDegreeVisible: false })} />}
            {this.state.modalCreateCompetenceVisible && <ModalCreateANewCompetence disableIssuerSelect={true} myIssuers={true} visible={this.state.modalCreateCompetenceVisible} onClose={() => this.onNewCompetencyAdded()} />}
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        degrees: state.myInstitution.myDegrees.list.results,
        role: state.user.info.settings.active_role_obj,
        selectedIssuer: state.issuers.selected
    }
}

export default injectIntl(connect(mapStateToProps, {
    CreateProgramStart,
    GetEduDegreesStart,
    UpdateProgramDetailsStart
})(ModalCreateProgram));