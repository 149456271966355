import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import MilestoneColorPicker from '../../components/Common/MilestoneColorPicker';
import ModalCreateANewCompetence from '../../components/Competence/Create/modalCreateANewCompetence';
import ModalMilestones from '../../components/Milestones/modalMilestones';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Form } from 'react-bootstrap';
import { DateRangePicker } from 'react-dates';
import EasyEdit, { Types } from 'react-easy-edit';
import { WithOutContext as ReactTags } from 'react-tag-input';
import { UpdateTaskStart } from '../../core/actions/tasks';
import { GetTaskCategoriesStart } from './../../core/actions/taskCategories';
import { getFileExtension, getFileName, contains } from '../../utils/common';
import { CreateDocumentsStart } from '../../core/actions/documents';
import { ModalAddDocuments } from '../Projects/modalAddDocuments';
import ModalFileViewer from '../../components/Common/ModalFileViewer/modalFileViewer';
import { langService } from './../../utils/languageService/index';
import AutosizeTextarea from './../Common/AutosizeTextarea/index';
import CompetencesComponent from '../Competence/competencesComponent';
import { getBloomVerb, getBloomName } from '../../utils/common/misc';
import { createLoadingSelector, createErrorMessageSelector } from '../../API/selector';
import ModalValidationWarnings from '../Common/ModalValidationWarnings';
import { apiCon } from '../../API';

class TaskOverviewWithEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focusedInput: null,
            startDate: moment(props.task.start_date),
            endDate: moment(props.task.end_date),
            price: null,
            status: '',
            tags: props.task.competences,
            categoryList: [],
            category: props.task.category.id,
            selectedCategory: { label: '', value: '' },
            inEdit: false,
            inEditRecurrance: false,
            inEditMilestone: false,
            inEditCompetences: false,
            milestoneId: null,
            milestones: null,
            recurring: false,
            recurring_type: 0,
            recurring_amount: 1,
            recurring_repeats_visible: false,
            modalAddDocumentsVisible: false,
            modalFileViewerVisible: false,
            modalMilestonesVisible: false,
            selectedDocument: null,
            warnings: {
                messages: [],
            },
            modalWarningsVisible: false,
        };
        this.selectMilestoneRef = React.createRef();
    }

    componentDidMount() {
        this.props.GetTaskCategoriesStart();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === '') {
            this.setState({ inEditRecurrance: false, inEdit: false, price: null, status: '' })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.task) {
            this.setState({
                milestoneId: nextProps.task.milestone?.id ? nextProps.task.milestone?.id : null,
                recurring: nextProps.task.recurring,
                recurring_type: nextProps.task.recurring_type,
                recurring_amount: nextProps.task.recurring_amount,
                recurring_repeats_visible: nextProps.task.recurring_type !== 0 ? true : false
            });
        }

        if (nextProps.categories) {
            this.generateCategoryOptions(nextProps.categories, nextProps.task);
        }

        if (nextProps.selectedProject) {
            this.setState({
                visibility: nextProps.selectedProject.visibility,
                milestones: nextProps.selectedProject.milestones
            });
        }
    }

    generateCategoryOptions(opts, task) {
        if (opts.length === 0) { return; };
        var options = [];
        var selectedOption = { label: '', value: '' };

        opts.forEach(e => {
            var item = { label: e.name, options: [] };
            e.child_categories.forEach(o => {
                if (o.id === task.category.id) {
                    selectedOption = { label: o.name, value: o.id };
                }
                item.options.push({ label: o.name, value: o.id });
            });
            options.push(item);
        });
        this.setState({ categoryList: options });
        this.setState({ selectedCategory: selectedOption });
    }

    openModalAddDocuments() {
        this.setState({ modalAddDocumentsVisible: true });
    }

    onModalCancel() {
        this.setState({ modalAddDocumentsVisible: false });
    }

    onModalSubmit(files) {
        this.props.CreateDocumentsStart(files, null, this.props.task.id);
        this.setState({ modalAddDocumentsVisible: false });
    }

    handleChange = input => event => {
        if (input === 'category') {
            this.setState({ category: event.value, selectedCategory: event }, () => this.save());
        } else if (input === "recurring_type") {
            this.setState({ recurring_type: event.target.value, recurring_repeats_visible: event.target.value !== '0' ? true : false });
        } else {
            this.setState({ [input]: event.target.value });
        }
    }

    checkBoxChange = (e) => {
        e.target.checked
            ? this.setState({ recurring: true })
            : this.setState({ recurring: false, recurring_repeats_visible: false, recurring_type: 0 }, () => this.verify());
    }

    checkBoxEnableRecurringEdit = (e) => {
        this.setState({ inEditRecurrance: true, recurring: true });
    }

    onSelectBlur() {
        this.setState({ inEdit: false });
    }

    onSelectMilestoneBlur() {
        this.setState({ inEditMilestone: false });
    }

    onDatesChangeTask(start, end) {
        this.setState({ startDate: moment(start).startOf('day').add(23, 'hours'), endDate: moment(end).startOf('day').add(23, 'hours') }, () => {
            if (start !== null && end !== null) {
                this.verify();
            }
        });
    }

    onCompetenceChange = (c) => {
        if (c.length >= 1) {
            this.setState({
                tags: c,
                inEditCompetences: false
            }, () => this.save());
        } else {
            this.setState({
                inEditCompetences: false
            });
        }
    }

    openModalMilestones() {
        this.setState({ modalMilestonesVisible: true });
    }

    hideModalMilestones = () => {
        this.setState({ modalMilestonesVisible: false });
    }

    // milestone...
    onColorChange = (c) => {
        this.setState({ milestoneId: c.value !== 0 ? c.value : null, inEditMilestone: false }, () => this.save());
        // this.save(null, null, c.value !== 0 ? c.value : null);
    }

    cancelRecurranceChanges() {
        this.setState({
            inEditRecurrance: false,
            recurring: this.props.task.recurring,
            recurring_type: this.props.task.recurring_type,
            recurring_amount: this.props.task.recurring_amount,
            recurring_repeats_visible: this.props.task.recurring_type !== '0' ? true : false
        });
    }

    getParams() {
        const { task } = this.props;
        const { startDate, endDate, milestoneId, category, tags, price, status } = this.state;

        var params = {
            id: task.id,
            name: task.name,
            short_description: task.short_description,
            description: task.description,
            // start_date: start ? moment(start).startOf('day').toISOString() : moment(this.state.startDate).startOf('day').toISOString(),
            start_date: moment(startDate).toISOString(),
            end_date: moment(endDate).toISOString(),
            ...(status ? { status: status } : {}),
            ...(price ? { price: price } : {}),
            visibility: task.visibility,
            competences: tags.map(x => x.id),
            project: this.props.selectedProject.id,
            ...(category ? { category: category } : {}),
            ...(milestoneId ? { milestone: milestoneId } : {}),
        };

        if (this.state.recurring) {
            params.recurring = true;
            params.recurring_type = this.state.recurring_type;
            params.recurring_amount = this.state.recurring_amount;
        } else {
            params.recurring = false;
        }

        return params;
    }

    verify() {
        const { task } = this.props;
        const params = this.getParams()
        const settings = { headers: { 'Content-Type': 'application/json' } };
        apiCon.patch(`/me/project/task/${task.id}/validate/`, JSON.stringify(params), settings).then(response => {
            this.save(params)
        }).catch(error => {
            if (error.response.data) {
                this.setState({
                    modalWarningsVisible: true, warnings: error.response.data
                });
            }
        })
    }

    onValidationWarningsConfirm() {
        this.setState({ modalWarningsVisible: false }, () => this.save());
    }


    save(params) {
        if (!params)
            params = this.getParams()
        this.props.UpdateTaskStart(params);
    }

    publishTask() {
        this.setState({ status: 'PUBLISHED' }, () => {
            this.save()
        });
    }

    onPriceChange(p) {
        const newPrice = parseFloat(p).toFixed(2);
        this.setState({ price: newPrice }, () => {
            this.verify()
        });
    }

    // Modal File Viewer
    openFileViewer(file) {
        this.setState({ selectedDocument: file, modalFileViewerVisible: true });
    }
    renderModalFileViewer() {
        if (this.state.modalFileViewerVisible) {
            return (
                <ModalFileViewer visible={this.state.modalFileViewerVisible} file={this.state.selectedDocument} onClose={() => this.hideModalFileViewer()} />
            )
        }
    }
    hideModalFileViewer() {
        this.setState({ modalFileViewerVisible: false });
    }

    isTaskEditable = () => {
        const { task } = this.props;
        const disabledEdits = ['ACTIVE', 'IN_PROGRESS', 'SUBMITTED', 'COMPLETED', 'REJECTED', 'FAILED', 'IN_AUDIT', 'AUDITOR_FAILED', 'AUDITOR_CONFIRMED'];

        if (disabledEdits.includes(task.status))
            return false;

        return true;
    }

    renderStatus(task) {
        if (task.status === "DRAFT") {
            return (
                <Col>
                    <button className="btn btn-default btn-projects btn-create-project" onClick={() => this.publishTask()}>
                        <FormattedMessage id="my_task.publish" defaultMessage="Publish Task" />
                    </button>
                </Col>
            )
        } else if (task.status === "PUBLISHED") {
            return (
                <Col>
                    <div className="form-labels">
                        <FormattedMessage id="forms.labels.status" defaultMessage="Status" />
                    </div>
                    <EasyEdit
                        type={Types.RADIO}
                        value={task.status}
                        onSave={(value) => { this.setState({ status: value }, () => this.save()); }}
                        onCancel={this.cancel}
                        options={[
                            { label: langService.messages['common.draft'], value: "DRAFT" },
                            { label: langService.messages['common.published'], value: "PUBLISHED" }
                        ]}
                    />
                </Col>
            )
        } else {
            return (
                <Col>
                    <div className="form-labels">
                        <FormattedMessage id="forms.labels.status" defaultMessage="Status" />
                    </div>
                    <label>{langService.messages[`common.${task.status.toLowerCase()}`]}</label>
                </Col>
            )
        }
    }

    render() {
        const { milestones, tags } = this.state;
        const { task, selectedProject } = this.props;
        return (
            <div className="p-3">
                <Row>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.task_name" defaultMessage="Task Name" />
                        </div>
                        <EasyEdit
                            type={Types.TEXT}
                            onSave={(value) => { task.name = value; this.save() }}
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            value={task.name}
                            allowEdit={this.isTaskEditable()}
                        />
                    </Col>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.category" defaultMessage="Category" />
                        </div>
                        {this.state.inEdit
                            ?
                            <Select
                                name="category"
                                blurinputonselect="true"
                                options={this.state.categoryList || ''}
                                value={this.state.selectedCategory || ''}
                                onChange={this.handleChange('category')}
                                onBlur={() => this.onSelectBlur()}
                                isDisabled={!this.isTaskEditable()}
                            />
                            :
                            <div className={`field-value ${this.isTaskEditable() ? 'label-to-edit' : 'label-to-edit-disabled'}`} onClick={() => this.isTaskEditable() && this.setState({ inEdit: true })}>
                                {this.state.selectedCategory.label ? this.state.selectedCategory.label : langService.messages['forms.placeholders.category']}
                            </div>
                        }
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.short_description" defaultMessage="Short description" /> (500)</div>
                        <EasyEdit
                            type={Types.TEXTAREA}
                            onSave={(value) => { task.short_description = value; this.save() }}
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            attributes={{ rows: "2" }}
                            value={task.short_description}
                            allowEdit={this.isTaskEditable()}
                            editComponent={<AutosizeTextarea
                                rows={3}
                                input="short_description"
                                edit={true}
                            />}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div className="form-labels"><FormattedMessage id="forms.labels.description" defaultMessage="Description" /> (5000)</div>
                        <EasyEdit
                            type={Types.TEXTAREA}
                            onSave={(value) => { task.description = value; this.save() }}
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            attributes={{ rows: "5" }}
                            value={task.description}
                            allowEdit={this.isTaskEditable()}
                            editComponent={<AutosizeTextarea
                                rows={5}
                                input="description"
                                edit={true}
                            />}
                        />
                    </Col>
                </Row>
                <br />
                <br />

                {task?.documents.length > 0
                    ?
                    <>
                        <Row>
                            <Col>
                                <div className="form-labels">
                                    <FormattedMessage id="forms.labels.documents" defaultMessage="Documents" />
                                </div>
                                <div className="p-documents">
                                    {
                                        task.documents.map((doc, c) =>
                                            <div className="attachment-box" key={c}>
                                                <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? 'generic' : getFileExtension(doc.document)}`}></div>
                                                {/* <a href={doc.document} download={getFileName(doc.document)} alt="project attachement">{getFileName(doc.document)}</a> */}
                                                {/* <div className="download-link" onClick={() => this.downloadFile(doc.document)}>{getFileName(doc.document)}</div> */}
                                                <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                                            </div>
                                        )
                                    }
                                </div>
                                {this.renderModalFileViewer()}

                                {this.isTaskEditable() &&
                                    <div className="p-adddocs" onClick={() => this.openModalAddDocuments()} >
                                        + <FormattedMessage id="forms.labels.add_documents" defaultMessage="Add Documents" />
                                    </div>
                                }
                                <ModalAddDocuments onCancel={() => this.onModalCancel()} onFilesChanged={(f) => this.onModalSubmit(f)} visible={this.state.modalAddDocumentsVisible} />
                            </Col>
                        </Row>
                        <br />
                    </>
                    :
                    <Row className="mb-4">
                        <Col>
                            <div className="form-labels">
                                <FormattedMessage id="forms.labels.documents" defaultMessage="Documents" />
                            </div>
                            {this.isTaskEditable()
                                ?
                                <div className="p-adddocs" onClick={() => this.openModalAddDocuments()}>
                                    + <FormattedMessage id="forms.labels.add_documents" defaultMessage="Add Documents" />
                                </div>
                                :
                                <div>
                                    No documents
                                </div>
                            }
                            <ModalAddDocuments onCancel={() => this.onModalCancel()} onFilesChanged={(f) => this.onModalSubmit(f)} visible={this.state.modalAddDocumentsVisible} />
                        </Col>
                    </Row>
                }

                <Row className="mb-4">
                    <Col md="6">
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.estimated_start_end" defaultMessage="Estimated start/end time" />
                        </div>
                        <DateRangePicker
                            startDate={this.state.startDate}
                            startDateId="taskStartDate"
                            endDate={this.state.endDate}
                            endDateId="taskEndDate"
                            onDatesChange={({ startDate, endDate }) => this.onDatesChangeTask(startDate, endDate)}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            endDatePlaceholderText={"End date"}
                            startDatePlaceholderText={"Start date"}
                            displayFormat={"DD-MM-YYYY"}
                            disabled={!this.isTaskEditable()}
                        />
                    </Col>
                    <Col md="6">
                        <div className="form-labels" style={{ marginBottom: "10px" }}><FormattedMessage id="forms.labels.price" defaultMessage="Price" /> ($)</div>
                        <EasyEdit
                            type={Types.NUMBER}
                            onSave={(value) => this.onPriceChange(value)} //{(value) => { task.price = value; this.save() }}
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            value={parseFloat(task.price).toFixed(2)}
                            allowEdit={this.isTaskEditable()}
                        />
                    </Col>
                </Row>

                {this.state.recurring
                    ?
                    <Row>
                        <Col>
                            <div className="form-labels">
                                <FormattedMessage id="common.reccurrance" defaultMessage="Task recurrance" />
                            </div>
                            {this.state.inEditRecurrance
                                ? <>
                                    <Form.Row>
                                        <div className="" style={{ "marginBottom": "5px", "fontWeight": "bold", "marginLeft": "5px" }}>
                                            <Form.Check inline label={langService.messages['common.reccurring_task']} name="recurring" type="checkbox" checked={this.state.recurring} onChange={this.checkBoxChange} />
                                        </div>
                                    </Form.Row>

                                    <Form.Row className={!this.state.recurring ? "hide-section" : ""}>
                                        <Form.Group as={Col} controlId="recurring_type">
                                            <Form.Label>
                                                <FormattedMessage id="common.recurring_type" defaultMessage="Recurring Type" />
                                            </Form.Label>
                                            <Form.Control as="select" onChange={this.handleChange('recurring_type')} value={this.state.recurring_type} style={{ "maxWidth": "238px" }}  >
                                                <option value={0}>{langService.messages['common.interval']}</option>
                                                <option value={1}>{langService.messages['common.weekly']}</option>
                                                <option value={2}>{langService.messages['common.monthly']}</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row className={!this.state.recurring_repeats_visible ? "hide-section" : ""}>
                                        <Form.Group as={Col} controlId="recurring_params">
                                            <Form.Label>
                                                <FormattedMessage id="common.reccurring_times" defaultMessage="Repeat X" />
                                            </Form.Label>
                                            <Form.Control type="number" min="1" value={this.state.recurring_amount} onChange={this.handleChange('recurring_amount')} style={{ "maxWidth": "238px" }} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                        </Form.Group>
                                    </Form.Row>

                                    <div className="easy-edit-button-wrapper">
                                        <button className="easy-edit-button" name="save" onClick={() => this.verify()}>Save</button>
                                        <button className="easy-edit-button" name="cancel" onClick={() => this.cancelRecurranceChanges()}>Cancel</button>
                                    </div>
                                </>
                                :
                                <div className="proposal-current" style={{ "fontSize": "20px" }} onClick={() => this.isTaskEditable() && this.setState({ inEditRecurrance: true })}>
                                    {task.recurring_type === 0 && langService.messages['common.interval']}
                                    {task.recurring_type === 1 && langService.messages['common.weekly'] + " X " + task.recurring_amount}
                                    {task.recurring_type === 2 && langService.messages['common.monthly'] + " X " + task.recurring_amount}
                                </div>

                            }
                        </Col>
                    </Row>
                    :
                    <Form.Row>
                        <div className="" style={{ "marginBottom": "5px", "fontWeight": "bold", "marginLeft": "5px" }}>
                            <Form.Check inline label={langService.messages['common.reccurring_task']} name="recurring" type="checkbox" onChange={this.checkBoxEnableRecurringEdit} disabled={!this.isTaskEditable()} />
                        </div>
                    </Form.Row>
                }

                <Row className="mt-4 mb-4">
                    <Col>
                        <Form.Label>
                            <FormattedMessage id="forms.labels.required_competences" defaultMessage="Required Competences" />
                        </Form.Label>
                        {this.state.inEditCompetences
                            ?
                            <Form autoComplete="off">

                                <CompetencesComponent onCompetencesChange={this.onCompetenceChange} obj_competences={task.competences} issuer={selectedProject?.client?.id}/>

                            </Form>
                            :
                            <ReactTags
                                tags={task?.competences.map(x => {
                                    return {
                                        id: x.id.toString(),
                                        text: `${x.name} (${getBloomName(x.level)} - ${getBloomVerb(1, x.level, langService.locale)})`
                                    }
                                })}
                                handleTagClick={() => this.isTaskEditable() && this.setState({ inEditCompetences: true })}
                                autofocus={false}
                                readOnly={true}
                                classNames={{
                                    tags: 'cctags',
                                    tagInput: 'cctagInput',
                                    tagInputField: 'cctagInputField',
                                    selected: 'ccselected',
                                    tag: 'cctag',
                                    remove: 'ccremove',
                                    suggestions: 'ccsuggestions',
                                    activeSuggestion: 'ccactiveSuggestion'
                                }} />
                        }
                    </Col>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="common.milestone" defaultMessage="Milestone" />
                        </div>
                        <div className={this.state.inEditMilestone ? "" : "hidden"}>
                            <MilestoneColorPicker
                                colorPicker={false}
                                milestones={milestones}
                                selected={task.milestone} //{milestones.selected} 
                                onColorChange={(c) => this.onColorChange(c)}
                            />
                            <div className="form-sublabel create-competence" style={{ "marginTop": "13px" }} onClick={() => this.openModalMilestones()} >
                                + <FormattedMessage id="common.milestones.create" defaultMessage="Create a new Milestone" />
                            </div>
                        </div>
                        <div className={this.state.inEditMilestone ? "hidden" : ""}>
                            <div className={`field-value ${this.isTaskEditable() ? 'label-to-edit' : 'label-to-edit-disabled'}`} onClick={() => this.isTaskEditable() && this.setState({ inEditMilestone: true })}>
                                {task?.milestone?.name ? task.milestone.name : 'Default'}
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.visibility" defaultMessage="Visibility" />
                        </div>
                        <EasyEdit
                            type={Types.RADIO}
                            value={task.visibility}
                            onSave={(value) => { task.visibility = value; this.save() }}
                            onCancel={this.cancel}
                            options={[
                                { label: langService.messages['common.public'], value: "PUBLIC" },
                                { label: langService.messages['common.private'], value: "PRIVATE" }
                            ]}
                            allowEdit={this.isTaskEditable()}
                        />
                    </Col>
                    {this.renderStatus(task)}
                </Row>

                {this.state.modalCreateCompetenceVisible && <ModalCreateANewCompetence visible={this.state.modalCreateCompetenceVisible} />}
                <ModalMilestones projectId={selectedProject.id} milestones={milestones} visible={this.state.modalMilestonesVisible} onClose={this.hideModalMilestones} />
                <ModalValidationWarnings visible={this.state.modalWarningsVisible} warnings={this.state.warnings} onConfirm={() => this.onValidationWarningsConfirm()} onClose={() => this.setState({ modalWarningsVisible: false })} />
            </div >
        )
    }
}

const loadingSelector = createLoadingSelector(['UPDATE_TASK']);
const errorSelector = createErrorMessageSelector(['UPDATE_TASK']);
const mapStateToProps = state => {
    return {
        task: state.myWork.myTasks.selected,
        selectedProject: state.myWork.myProjects.selected,
        competences: state.competence.list.results,
        categories: state.taskCategory.list.results,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetTaskCategoriesStart,
    UpdateTaskStart,
    CreateDocumentsStart
})(TaskOverviewWithEdit));