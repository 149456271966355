export const REACT_APP_BASE_WS_URL_BARE = () => {
    if (window?._env_?.REACT_APP_BASE_WS_URL_BARE) { return window._env_.REACT_APP_BASE_WS_URL_BARE; }
    return process.env.REACT_APP_BASE_WS_URL_BARE;
}
export const REACT_APP_BASE_BACKEND_URL_BARE = () => {
    if (window?._env_?.REACT_APP_BASE_BACKEND_URL_BARE) { return window._env_.REACT_APP_BASE_BACKEND_URL_BARE; }
    return process.env.REACT_APP_BASE_BACKEND_URL_BARE;
}
export const REACT_APP_BASE_BACKEND_URL = () => {
    if (window?._env_?.REACT_APP_BASE_BACKEND_URL) { return window._env_.REACT_APP_BASE_BACKEND_URL; }
    return process.env.REACT_APP_BASE_BACKEND_URL;
}
export const REACT_APP_API_URL = () => {
    if (window?._env_?.REACT_APP_API_URL) { return window._env_.REACT_APP_API_URL; }
    return process.env.REACT_APP_API_URL;
}
export const REACT_APP_LOCATION_API_URL = () => {
    if (window?._env_?.REACT_APP_LOCATION_API_URL) { return window._env_.REACT_APP_LOCATION_API_URL; }
    return process.env.REACT_APP_LOCATION_API_URL;
}
export const REACT_APP_GOOGLE_CLIENT_ID = () => {
    if (window?._env_?.REACT_APP_GOOGLE_CLIENT_ID) { return window._env_.REACT_APP_GOOGLE_CLIENT_ID; }
    return process.env.REACT_APP_GOOGLE_CLIENT_ID;
}
