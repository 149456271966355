import React, { useEffect, useState } from 'react';
import '../../browse-styles.scss';
import './index.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { GetCompetencesStart } from '../../../../core/actions/competences';
import { WithOutContext as ReactTags } from 'react-tag-input';
import { ChangeFreelancerFiltersStart } from '../../../../core/actions/role';
import { Select } from '../../../../utils/forms/formElements';

const FreelancersFiltersComp = props => {
    const { formatMessage } = props.intl;

    const [competenceTags, setCompetenceTags] = useState([]);
    const [competenceSuggestions, setCompetenceSuggestions] = useState([]);

    useEffect(() => {
        if (props.competences.length === 0)
            props.GetCompetencesStart({ limit: 100 });
    }, [])

    useEffect(() => {
        if (props.competences.length !== 0)
            setCompetenceSuggestions(props.competences.map(c => ({ id: c.id, text: `${c.name} L${c.level}` })))
    }, [props.competences])

    useEffect(() => {
        const tempCompetenceTags = [];

        competenceSuggestions.forEach(cs => {
            if (props.freelancersFilters.competences.includes(cs.id))
                tempCompetenceTags.push(cs);
        });

        setCompetenceTags(tempCompetenceTags);
    }, [props.freelancersFilters.competences])

    const handleAvailabilityChange = value => {
        // const { freelancersFilters } = props;
        // if (freelancersFilters.availability.includes(value))
        //     props.ChangeFreelancerFiltersStart({ availability: freelancersFilters.availability.filter(a => a !== value) });
        // else
        //     props.ChangeFreelancerFiltersStart({ availability: [...freelancersFilters.availability, value] });
        props.ChangeFreelancerFiltersStart({ availability: value });
    }

    const handleNumberWorkChange = value => {
        if (value === '') {
            props.ChangeFreelancerFiltersStart({ min_nmb_of_finished_tasks: '' });
            return;
        }

        const isNumbersOnly = /^\d+$/.test(value);

        if (!isNumbersOnly)
            return;

        props.ChangeFreelancerFiltersStart({ min_nmb_of_finished_tasks: value });
    }

    const handleCompetenceAddition = e => {
        const found = competenceSuggestions.find(t => t === e);

        if (found) {
            setCompetenceTags([...competenceTags, e]);
            props.ChangeFreelancerFiltersStart({ competences: [...props.freelancersFilters.competences, e.id] });
        }
    }

    const handleCompetencesDelete = i => {
        setCompetenceTags(competenceTags.filter((tag, index) => index !== i));

        const filteredTagIds = [];
        competenceTags.forEach((tag, index) => {
            if (index !== i)
                filteredTagIds.push(tag.id);
        });

        // This prevents settings state of competences to empty array if competences are already empty array & user is holding backspace
        if (props.freelancersFilters.competences.length === 0 && filteredTagIds.length === 0)
            return;

        props.ChangeFreelancerFiltersStart({ competences: filteredTagIds });
    }

    return (
        <div className="filters-wrapper">

            <div className="filter-title-dev">
                <h6><FormattedMessage id="profile.availability" defaultMessage="Availability" /></h6>
                <Form.Group>
                    <div className="radio">
                        <label className="caption">
                            <input type="radio" value="F" className="sp-radio"
                                checked={props.freelancersFilters.availability === 'F' ? true : false}
                                onChange={() => handleAvailabilityChange('F')}
                            />
                            <FormattedMessage id="setup_profile.full_time" defaultMessage="Full time" />
                        </label>
                    </div>
                    <div className="radio">
                        <label className="caption">
                            <input type="radio" value="P" className="sp-radio"
                                checked={props.freelancersFilters.availability === 'P' ? true : false}
                                onChange={() => handleAvailabilityChange('P')}
                            />
                            <FormattedMessage id="setup_profile.part_time" defaultMessage="Part time" />
                        </label>
                    </div>
                    <div className="radio">
                        <label className="caption">
                            <input type="radio" value="N" className="sp-radio"
                                checked={props.freelancersFilters.availability === '' ? true : false}
                                onChange={() => handleAvailabilityChange('')}
                            />
                            <FormattedMessage id="setup_profile.all" defaultMessage="All" />
                        </label>
                    </div>
                </Form.Group>
            </div>

            <div className="filter-title-dev">
                <h6><FormattedMessage id="profile.completedtasksupper" defaultMessage="Completed tasks" /></h6>
                <Form.Group controlId="freelancers-finished-work">
                    <span className="caption" style={{ marginLeft: '4px' }}>Min</span>
                    <Form.Control type="text" value={props.freelancersFilters.min_nmb_of_finished_tasks} onChange={e => handleNumberWorkChange(e.target.value)} />
                </Form.Group>
            </div>

            <div className="filter-title-dev" style={{ marginBottom: '13px' }}>
                <h6><FormattedMessage id="projects.competences" defaultMessage="Competences" /></h6>
                <ReactTags
                    style={{ width: '100%' }}
                    className="form-control"
                    classNames={{
                        // tags: 'filterTags',
                        tagInput: 'filterTagInput',
                        tagInputField: 'filterTagInputField',
                        selected: 'filterSelected',
                        tag: 'filterTag',
                        remove: 'filterRemove',
                        // suggestions: 'filterSuggestions',
                        // activeSuggestion: 'filterActiveSuggestion'
                    }}
                    autofocus={false}
                    inputFieldPosition="top"
                    tags={competenceTags}
                    suggestions={competenceSuggestions}
                    placeholder={formatMessage({ id: 'forms.placeholders.addcompetence', defaultMessage: 'Add competence' })}
                    handleAddition={e => handleCompetenceAddition(e)}
                    handleDelete={i => handleCompetencesDelete(i)}
                />
            </div>

            <div>
                <button style={{ width: '100%' }} onClick={() => props.ChangeFreelancerFiltersStart()} className="btn btn-default btn-developers">
                    <FormattedMessage id="forms.reset_filters" defaultMessage="Reset filters" />
                </button>
            </div>

        </div>
    );
}

const FreelancersSearchSortComp = props => {
    const { formatMessage } = props.intl;

    const SORT_OPTIONS = [
        { label: formatMessage({ id: 'browse.common.sortby', defaultMessage: 'Sort by' }), value: '' },
        { label: formatMessage({ id: 'browse.order.highestrating', defaultMessage: 'Highest rating' }), value: '-rating__rating' },
        { label: formatMessage({ id: 'browse.order.lowestrating', defaultMessage: 'Lowest rating' }), value: 'rating__rating' },
        { label: formatMessage({ id: 'browse.order.highesthourlyrate', defaultMessage: 'Highest hourly rate' }), value: '-details__hourly_rate' },
        { label: formatMessage({ id: 'browse.order.lowesthourlyrate', defaultMessage: 'Lowest hourly rate' }), value: 'details__hourly_rate' },
        { label: formatMessage({ id: 'browse.order.mostfinishedtasks', defaultMessage: 'Most finished tasks' }), value: '-statistics__completed_task_count' }
    ]

    return (
        <div className="browse-search">
            <Form>
                <Form.Row>
                    <Col md={12} lg={9}>
                        <Form.Control
                            placeholder={formatMessage({ id: 'browse.common.search', defaultMessage: 'Search' })}
                            onChange={e => props.ChangeFreelancerFiltersStart({ search: e.target.value })}
                            value={props.freelancersFilters.search}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <Select
                            blurinputonselect="true"
                            name="freelancers-sort-select"
                            style={{ display: 'inline' }}
                            options={SORT_OPTIONS}
                            value={props.freelancersFilters.ordering}
                            onChange={e => props.ChangeFreelancerFiltersStart({ ordering: e.target.value })}
                        />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    freelancersFilters: state.browse.freelancers.list.filters,
    competences: state.competence.list.results,
    isAuthenticated: state.user.auth.isAuthenticated
})

const FreelancersFilters = injectIntl(connect(mapStateToProps, {
    GetCompetencesStart,
    ChangeFreelancerFiltersStart
})(FreelancersFiltersComp))

const FreelancersSearchSort = injectIntl(connect(mapStateToProps, {
    ChangeFreelancerFiltersStart
})(FreelancersSearchSortComp))

export { FreelancersFilters, FreelancersSearchSort };