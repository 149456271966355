import React from 'react'
import _ from 'lodash';
import './index.scss';
import '../browse-styles.scss';
import history from '../../../utils/routes/history';
import InfoBar from '../../../components/Infobar';
import BrowseNavigationControl from '../../../components/BrowseNavigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createLoadingSelector } from '../../../API/selector';
import { GetEdusStart, GetEdusChangePageStart } from '../../../core/actions/role';
import { EducationalInstitutionsFilters, EducationalInstitutionsSearchSort } from './Educational-InstitutionsFilters';
import { isEqual } from 'lodash';
import { INITIAL_STATE_FILTERS } from '../../../core/reducers/browse/eduReducer';
import { debounceFreeInputFields } from '../../../utils/common/browse';

class EducationalInstitutionsPage extends React.Component {

    componentDidMount() {
        this.init();
    }

    init = () => {
        const { edus, edusFilters } = this.props;

        if (edus.length !== 0)
            return;

        this.props.GetEdusStart(edusFilters);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.edusFilters === this.props.edusFilters)
            return;

        if (isEqual(this.props.edusFilters, INITIAL_STATE_FILTERS)) {
            debounceFreeInputFields.cancel();
            this.props.GetEdusStart(this.props.edusFilters);
            return;
        }

        if (prevProps.edusFilters.search !== this.props.edusFilters.search) {
            debounceFreeInputFields([this.props.edusFilters.search], this.props.GetEdusStart, this.props.edusFilters);
            return;
        }

        debounceFreeInputFields.cancel();
        this.props.GetEdusStart(this.props.edusFilters);
    }

    componentWillUnmount() {
        debounceFreeInputFields.cancel();
    }

    selectEdu() {

    }

    openDetails(c) {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;
        if (!isAuthenticated) 
            return history.push("/auth/login");
        if (isAuthenticated && isEmailVerified) 
            return history.push("/user/" + c.id);
        return null;
    }

    renderActions(c) {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;

        if (!isAuthenticated)
            return <button className="btn btn-default btn-employers" onClick={() => history.push('/auth/login')}><FormattedMessage id="projects.logintoview" defaultMessage="Login to view" /></button>

        if (!isEmailVerified)
            return (<button className="btn btn-default btn-employers"><FormattedMessage id="common.verify_email_to_view" defaultMessage="Verify email to view" /></button>)

        return (<button className="btn btn-default btn-employers" onClick={() => history.push("/user/" + c.id)}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>);
    }


    renderEdus(edus) {
        if (_.isEmpty(edus)) {
            return <></>
        }
        return (
            <>
                {edus.map((c, i) =>
                    <Card className="card-item card-item-edus" key={i}>
                        <Card.Body>

                            <div className="card-thumb">
                                <img src="/assets/img/project-thumb-1.png" className="p-thumb" alt="Project thumb" />
                            </div>

                            <div className="card-details">
                                <Card.Title className="card-title browse-namelink" onClick={() => this.openDetails(c)}>{c.name}</Card.Title>
                                <div className="card-desc">
                                    <FormattedMessage id="forms.labels.short_description" defaultMessage="Short description" />
                                </div>
                                <div className="card-info">
                                    <span>
                                        <FormattedMessage id="browse.common.activeprojects" defaultMessage="Active Projects" />
                                        <strong>{1}</strong></span>
                                    <span>
                                        <FormattedMessage id="browse.common.opentasks" defaultMessage="Open tasks" />
                                        <strong>{1}</strong></span>
                                </div>
                            </div>

                            <div className="card-sep"></div>

                            <div className="card-actions">
                                <div className="p-info-name">
                                    {c.rating.rating
                                        ?
                                        <><span className="p-star" role="img" aria-label="Star rating">⭐</span>
                                            <span><span className="rating-big">{c.rating.rating}</span><span className="rating-small">/5</span></span></>
                                        : ''
                                    }
                                </div>

                                {this.renderActions(c)}

                            </div>
                        </Card.Body>
                    </Card>
                )}
            </>
        )
    }

    render() {
        const { edus } = this.props;
        // const { auth: { isAuthenticated } } = this.props;
        return (
            <>
                <Jumbotron className="jumbo-edu mb-0">
                    <InfoBar />
                    <Container>
                        <h1><FormattedMessage id="educationalinstitutions.heading" defaultMessage="Most Credible Educational Institutions at one place" /></h1>
                    </Container>
                </Jumbotron>
                <Container className="mt-0">
                    <BrowseNavigationControl page="edu" />
                    <Row>
                        <Col md={12} lg={3}>
                            <EducationalInstitutionsFilters />
                        </Col>
                        <Col md={12} lg={9}>
                            <div className="browse-title title-edu">
                                <FormattedMessage id="browse.edus" defaultMessage="Educational Institutions" />
                            </div>

                            <EducationalInstitutionsSearchSort />

                            <span className="items-count">
                                <FormattedMessage id="plural.edus" values={{ count: edus.length }}
                                    defaultMessage={`${edus.length} Educational Institutions`} />
                            </span>

                            <InfiniteScroll
                                dataLength={edus.length}
                                next={() => setTimeout(() => this.props.nextUrl && this.props.GetEdusChangePageStart(this.props.nextUrl), 500)}
                                hasMore={edus.length !== this.props.count}
                                loader={<div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.loading" defaultMessage="Loading" />...</div>}
                                endMessage={edus.length > 0 && <div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.allrecordsloaded" defaultMessage="All records loaded" /></div>}
                            >
                                <div className="card-list-projects">
                                    {this.renderEdus(edus)}
                                </div>
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['GET_EDUS']);
const mapStateToProps = state => {
    return {
        isLoading: loadingSelector(state),
        auth: state.user.auth,
        isEmailVerified: state.user.info.isEmailVerified,
        edus: state.browse.edus.list.results,
        edusFilters: state.browse.edus.list.filters,
        nextUrl: state.browse.edus.list.next,
        count: state.browse.edus.list.count
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetEdusStart,
    GetEdusChangePageStart
})(EducationalInstitutionsPage));