//Competences
export const CHANGE_COMPETENCES_FILTERS = "CHANGE_COMPETENCES_FILTERS";

export const GET_COMPETENCES_REQUEST = "GET_COMPETENCES_REQUEST";
export const GET_COMPETENCES_SUCCESS = "GET_COMPETENCES_SUCCESS";
export const GET_COMPETENCES_FAILURE = "GET_COMPETENCES_FAILURE";

export const VERIFY_COMPETENCE_REQUEST = "VERIFY_COMPETENCE_REQUEST";
export const VERIFY_COMPETENCE_SUCCESS = "VERIFY_COMPETENCE_SUCCESS";
export const VERIFY_COMPETENCE_FAILURE = "VERIFY_COMPETENCE_FAILURE";

export const VALIDATE_COMPETENCE_REQUEST = "VALIDATE_COMPETENCE_REQUEST";
export const VALIDATE_COMPETENCE_SUCCESS = "VALIDATE_COMPETENCE_SUCCESS";
export const VALIDATE_COMPETENCE_FAILURE = "VALIDATE_COMPETENCE_FAILURE";

export const SELECT_COMPETENCE_REQUEST = "SELECT_COMPETENCE_REQUEST";
export const SELECT_COMPETENCE_SUCCESS = "SELECT_COMPETENCE_SUCCESS";
export const SELECT_COMPETENCE_FAILURE = "SELECT_COMPETENCE_FAILURE";

export const DESELECT_COMPETENCE = 'DESELECT_COMPETENCE';

export const CREATE_COMPETENCE_REQUEST = "CREATE_COMPETENCE_REQUEST";
export const CREATE_COMPETENCE_SUCCESS = "CREATE_COMPETENCE_SUCCESS";
export const CREATE_COMPETENCE_FAILURE = "CREATE_COMPETENCE_FAILURE";

export const UPDATE_COMPETENCE_REQUEST = "UPDATE_COMPETENCE_REQUEST";
export const UPDATE_COMPETENCE_SUCCESS = "UPDATE_COMPETENCE_SUCCESS";
export const UPDATE_COMPETENCE_FAILURE = "UPDATE_COMPETENCE_FAILURE";
