//-------------------
//  RATING actions
//-------------------
export const GET_MY_RATING_REQUEST = "GET_MY_RATING_REQUEST";
export const GET_MY_RATING_SUCCESS = "GET_MY_RATING_SUCCESS";
export const GET_MY_RATING_FAILURE = "GET_MY_RATING_FAILURE";

export const GET_RATING_REQUEST = "GET_RATING_REQUEST";
export const GET_RATING_SUCCESS = "GET_RATING_SUCCESS";
export const GET_RATING_FAILURE = "GET_RATING_FAILURE";

//-------------------
//  REVIEWS actions
//-------------------
export const GET_MY_REVIEWS_REQUEST = "GET_MY_REVIEWS_REQUEST";
export const GET_MY_REVIEWS_SUCCESS = "GET_MY_REVIEWS_SUCCESS";
export const GET_MY_REVIEWS_FAILURE = "GET_MY_REVIEWS_FAILURE";

export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILURE = "GET_REVIEWS_FAILURE";

//-------------------
//  REVIEW REQUEST actions
//-------------------
export const GET_REVIEW_TODOS_REQUEST = "GET_REVIEW_TODOS_REQUEST";
export const GET_REVIEW_TODOS_SUCCESS = "GET_REVIEW_TODOS_SUCCESS";
export const GET_REVIEW_TODOS_FAILURE = "GET_REVIEW_TODOS_FAILURE";

export const GET_REVIEW_TODO_REQUEST = "GET_REVIEW_TODO_REQUEST";
export const GET_REVIEW_TODO_SUCCESS = "GET_REVIEW_TODO_SUCCESS";
export const GET_REVIEW_TODO_FAILURE = "GET_REVIEW_TODO_FAILURE";

export const SUBMIT_REVIEW_REQUEST = "SUBMIT_REVIEW_REQUEST";
export const SUBMIT_REVIEW_SUCCESS = "SUBMIT_REVIEW_SUCCESS";
export const SUBMIT_REVIEW_FAILURE = "SUBMIT_REVIEW_FAILURE";

//-------------------
//  REVIEW REQUEST FOR TASK actions
//-------------------
export const GET_REVIEW_TODOS_FOR_TASK_REQUEST = "GET_REVIEW_TODOS_FOR_TASK_REQUEST";
export const GET_REVIEW_TODOS_FOR_TASK_SUCCESS = "GET_REVIEW_TODOS_FOR_TASK_SUCCESS";
export const GET_REVIEW_TODOS_FOR_TASK_FAILURE = "GET_REVIEW_TODOS_FOR_TASK_FAILURE";

export const GET_REVIEW_TODO_FOR_TASK_REQUEST = "GET_REVIEW_TODO_FOR_TASK_REQUEST";
export const GET_REVIEW_TODO_FOR_TASK_SUCCESS = "GET_REVIEW_TODO_FOR_TASK_SUCCESS";
export const GET_REVIEW_TODO_FOR_TASK_FAILURE = "GET_REVIEW_TODO_FOR_TASK_FAILURE";

export const SUBMIT_REVIEW_FOR_TASK_REQUEST = "SUBMIT_REVIEW_FOR_TASK_REQUEST";
export const SUBMIT_REVIEW_FOR_TASK_SUCCESS = "SUBMIT_REVIEW_FOR_TASK_SUCCESS";
export const SUBMIT_REVIEW_FOR_TASK_FAILURE = "SUBMIT_REVIEW_FOR_TASK_FAILURE";
