import * as types from './types';
import { apiCon } from '../../../API';

//-------------------------------
// Actions Competence Parent
//-------------------------------

//------------------------------------
// Actions Get Competence Parents
//------------------------------------
export const GetCompetenceParentsStart = (limit = 100, competence_id, search) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCE_PARENTS_REQUEST });
    var url = '/competence/parents/'
    const params = {
        'limit': limit,
        ...(competence_id ? { 'competence': competence_id } : {}),
        ...(search ? { 'search': search } : {}),
    }
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetCompetenceParentsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetCompetenceParentsFail(err.response));
        })
}
export const GetCompetenceParentsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCE_PARENTS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetCompetenceParentsSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetCompetenceParentsFail(err.response));
        })
}
export const GetCompetenceParentsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received Competence Parents info.", list: data, add: add }
    return { type: types.GET_COMPETENCE_PARENTS_SUCCESS, payload: payload };
}
export const GetCompetenceParentsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Competence Parents failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_COMPETENCE_PARENTS_FAILURE, payload: payload }
}

//--------------------------------------
// Actions Create Competence Parent
//--------------------------------------
export const CreateCompetenceParentStart = (competenceId, parentId) => async dispatch => {
    dispatch({ type: types.CREATE_COMPETENCE_PARENT_REQUEST });
    const json = JSON.stringify({
        'competence': competenceId,
        'parent': parentId,
    });
    const address = `/competence/parents/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(CreateCompetenceParentSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateCompetenceParentFail(err.response));
        });

}
export const CreateCompetenceParentSuccess = (data) => {
    const succ_msg = "Competence Parent created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.CREATE_COMPETENCE_PARENT_SUCCESS, payload: payload };
}
export const CreateCompetenceParentFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Create Competence Parent failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_COMPETENCE_PARENT_FAILURE, payload: payload };
}

//----------------------------------------
// Actions Vote for Competence Parent
//----------------------------------------
export const VoteForCompetenceParentStart = (competenceParentId, vote_option) => async dispatch => {
    dispatch({ type: types.VOTE_FOR_COMPETENCE_PARENT_REQUEST });
    const json = JSON.stringify({
        'action': vote_option ?? 'up',
    });
    const address = `/competence/parent/${competenceParentId}/votes/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(VoteForCompetenceParentSuccess(response.data));
        })
        .catch(err => {
            dispatch(VoteForCompetenceParentFail(err.response));
        });

}
export const VoteForCompetenceParentSuccess = (data) => {
    const succ_msg = "Successfully voted for Competence Parent!";
    const payload = { succ_msg: succ_msg, selected: data };
    return { type: types.VOTE_FOR_COMPETENCE_PARENT_SUCCESS, payload: payload };
}
export const VoteForCompetenceParentFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Vote for Competence Parent failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.VOTE_FOR_COMPETENCE_PARENT_FAILURE, payload: payload };
}

//------------------------------------------
// Actions Delete Vote for Competence Parent
//------------------------------------------
export const DeleteVoteForCompetenceParentStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_VOTE_FOR_COMPETENCE_PARENT_REQUEST });
    const address = `/competence/parent/${id}/votes/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.delete(address, settings)
        .then(response => {
            dispatch(DeleteForCompetenceParentSuccess(response.data));
        })
        .catch(err => {
            dispatch(DeleteForCompetenceParentFail(err.response));
        });

}
export const DeleteForCompetenceParentSuccess = (data) => {
    const succ_msg = "Successfully deleted vote for Competence Parent!";
    const payload = { succ_msg: succ_msg, deleted: data };
    return { type: types.DELETE_VOTE_FOR_COMPETENCE_PARENT_SUCCESS, payload: payload };
}
export const DeleteForCompetenceParentFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Delete Vote for Competence Parent failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_VOTE_FOR_COMPETENCE_PARENT_FAILURE, payload: payload };
}