import React from 'react';
import './tabAuditInfo.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { GetMyTaskHistoryStart } from '../../core/actions/tasks';
import { createLoadingSelector, createErrorMessageSelector } from '../../API/selector';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { contains, getFileExtension, getFileName } from '../../utils/common';

const cardStyles = [
    { background: 'rgba(251, 116, 0, 0.8)', color: '#fff' }, // NEGOTIATIONS
    { background: 'rgba(255, 197, 0, 0.8)', color: '#fff' }, // COMMUNICATION
    { background: 'rgba(0, 141, 206, 0.8)', color: '#fff' }, // STATUS CHANGES / OTHER
    { background: 'rgba(31, 58, 134, 0.8)', color: '#fff' }, // FILE SUBMISSIONS
    { background: 'rgba(200, 0, 0,   0.7)', color: '#fff' }, // WARNING
    { background: 'rgba(0, 200, 0,   0.7)', color: '#fff' }, // SUCCESS
];
const arrowStyles = [
    { borderRight: "7px solid  rgba(251, 116, 0, 0.8)" }, // NEGOTIATIONS
    { borderRight: "7px solid  rgba(255, 197, 0, 0.8)" }, // COMMUNICATION
    { borderRight: "7px solid  rgba(0, 141, 206, 0.8)" }, // STATUS CHANGES / OTHER
    { borderRight: "7px solid  rgba(31, 58, 134, 0.8)" }, // FILE SUBMISSIONS
    { borderRight: "7px solid  rgba(200, 0, 0,   0.7)" }, // WARNING
    { borderRight: "7px solid  rgba(0, 200, 0,   0.7)" }, // SUCCESS
];


class TabAuditInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            historyFilter: this.props.historyFiler ?? 5
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading && this.props.isInvalid === '') {
            this.filterHistory(this.state.historyFilter);
        }
    }

    componentDidMount() {
        this.filterHistory(this.state.historyFilter);
    }

    filterHistory = (f) => {
        this.setState({ historyFilter: f }, () => {
            switch (f) {
                case 1:
                    this.props.GetMyTaskHistoryStart(this.props.selectedTask.id, ['NEGOTIATION']);
                    break;
                case 2:
                    this.props.GetMyTaskHistoryStart(this.props.selectedTask.id, ['MESSAGING']);
                    break;
                case 3:
                    this.props.GetMyTaskHistoryStart(this.props.selectedTask.id, ['FILE_SUBMISSION']);
                    break;
                case 4:
                    this.props.GetMyTaskHistoryStart(this.props.selectedTask.id, ['AUDIT']);
                    break;
                case 5:
                    this.props.GetMyTaskHistoryStart(this.props.selectedTask.id,);
                    break;
                default:
                    this.props.GetMyTaskHistoryStart(this.props.selectedTask.id,);
                    break;
            }
        });

    }

    renderNegotiations(data, index, title, text, position) {
        return (
            <VerticalTimelineElement key={index}
                className="vertical-timeline-element--work"
                contentStyle={cardStyles[0]}
                contentArrowStyle={arrowStyles[0]}
                date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                iconStyle={cardStyles[0]}
                position={position}
            >
                <div className="vertical-timeline-element-title">{text}</div>
                <p>
                    Price: {`$` + parseInt(data.object_data.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                    <br />
                    Timeframe: {moment(data.object_data.start_date).format('DD.MM.YYYY')} - {moment(data.object_data.end_date).format('DD.MM.YYYY')}
                </p>
            </VerticalTimelineElement>
        )
    }

    renderMessages(data, index, title, text, position) {
        return (
            <VerticalTimelineElement key={index}
                className="vertical-timeline-element--work"
                contentStyle={cardStyles[1]}
                contentArrowStyle={arrowStyles[1]}
                date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                iconStyle={cardStyles[1]}
                position={position}
            >
                <div className="vertical-timeline-element-title">{title}</div>
                {/* <div className="vertical-timeline-element-subtitle">Miami, FL</div> */}
                <p style={{ "fontStyle": "Italic" }}>{text}</p>
            </VerticalTimelineElement>
        )
    }

    renderTaskResults(data, index, title, text, position) {
        return (
            <VerticalTimelineElement key={index}
                className="vertical-timeline-element--work"
                contentStyle={cardStyles[3]}
                contentArrowStyle={arrowStyles[3]}
                date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                iconStyle={cardStyles[3]}
                position={position}
            >
                <div className="vertical-timeline-element-title">{title}</div>
                <p>Comment:</p>
                <p style={{ "fontStyle": "Italic" }}>{text}</p>

                {data.object_data.documents.map((doc, c) =>
                    <div className="audit-attachment-box" key={c}>
                        <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? getFileExtension(doc.document) : 'generic'}`}></div>
                        <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                    </div>
                )
                }

            </VerticalTimelineElement>
        )
    }

    renderOtherHistory(data, index, title, text) {
        const { role } = this.props;
        var color = 2;

        switch (data.object_data.status) {

            case "PENDING_AUDIT":
                color = 4;
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">
                            {role.type === "DEV" && <FormattedMessage id="auditors.client_filed_dispute" defaultMessage=" Client filled a dispute with a complaint" />}
                            {role.type === "INV" && <FormattedMessage id="auditors.you_filed_dispute" defaultMessage=" You filled a dispute with a complaint" />}
                        </div>
                        {/* <p style={{ "fontWeigth": "bold", "fontStyle": "italic" }}>"{data.object_data.dispute_reason}"</p> */}
                        <p><FormattedMessage id="auditors.dispute_escalated" defaultMessage=" The case was escalated to auditor." /></p>
                    </VerticalTimelineElement>
                )

            case "IN_AUDIT":
                color = 4;
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">
                            {role.type === "DEV" && <FormattedMessage id="auditors.auditor_accepted" defaultMessage=" Auditor accepted the case" />}
                            {role.type === "INV" && <FormattedMessage id="auditors.auditor_accepted_your" defaultMessage=" Auditor accepted your case" />}
                        </div>
                        <p>
                            <FormattedMessage id="auditors.audit_deadline" defaultMessage="Audit deadline" />:
                        <FormattedMessage id="auditors.audit_deadline_days" defaultMessage=" 7 work days" />
                        </p>
                    </VerticalTimelineElement>
                )

            case "AUDITOR_CONFIRMED":
                color = 5;
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">
                            {role.type === "DEV" && <FormattedMessage id="auditors.auditor_reviewed" defaultMessage=" Auditor has reviewed the case" />}
                            {role.type === "INV" && <FormattedMessage id="auditors.auditor_reviewed_your" defaultMessage=" Auditor has reviewed your case" />}
                        </div>
                        <p><FormattedMessage id="history.task_is_auditor_confirmed.text" defaultMessage="Auditor marked task as confirmed." /></p>
                        <p><FormattedMessage id="common.task_success" defaultMessage="Task success" />:
                                {parseInt(data.object_data.dispute_case.confirmed_percentage)}%
                            </p>
                        <p><FormattedMessage id="common.comment" defaultMessage="Comment" />:</p>
                        <p style={{ "fontWeigth": "bold", "fontStyle": "italic" }}>"{data.object_data.dispute_case.decision_comment}"</p>
                    </VerticalTimelineElement>
                )

            case "AUDITOR_FAILED":
                color = 4;
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">
                            {role.type === "DEV" && <FormattedMessage id="auditors.auditor_reviewed" defaultMessage=" Auditor has reviewed the case. Details:" />}
                            {role.type === "INV" && <FormattedMessage id="auditors.auditor_reviewed_your" defaultMessage=" Auditor has reviewed your case. Details:" />}
                        </div>
                        <p><FormattedMessage id="history.task_is_auditor_failed.text" defaultMessage="Auditor marked task as failed." /></p>
                        <p><FormattedMessage id="common.comment" defaultMessage="Comment" />:</p>
                        <p style={{ "fontWeigth": "bold", "fontStyle": "italic" }}>"{data.object_data.dispute_case.decision_comment}"</p>
                    </VerticalTimelineElement>
                )

            default:
                return (
                    <VerticalTimelineElement key={index}
                        className="vertical-timeline-element--work"
                        contentStyle={cardStyles[color]}
                        contentArrowStyle={arrowStyles[color]}
                        date={moment(data.history_date).format('DD.MM.YYYY HH:mm')}
                        iconStyle={cardStyles[color]}
                    >
                        <div className="vertical-timeline-element-title">{title}</div>
                        {/* <div className="vertical-timeline-element-subtitle">Miami, FL</div> */}
                        <p>{text}</p>
                    </VerticalTimelineElement>
                )
        }
    }

    renderDataHistory(history, index) {
        const { selectedTask } = this.props;
        var title = `history.${history.object_type}.title`;
        var text = `history.${history.object_type}.text`;
        var position = ''; // left=client, right=freelancer

        switch (history.object_type.toLowerCase()) {

            case 'messages': // COMMUNICATION
                if (history.object_data.sender === selectedTask.active_freelancer.id) {
                    title = <FormattedMessage id="history.freelancer_send_message.title" defaultMessage={`Freelancer ${<strong>{selectedTask.active_freelancer.name}</strong>} has send you an message:`} />
                    text = <FormattedMessage id="history.freelancer_send_message.text" values={{ message: history.object_data.message, b: chunks => (<strong>{chunks}</strong>) }} defaultMessage={` "${<strong>{history.object_data.message}</strong>}"`} />
                    position = 'right';
                } else {
                    title = <FormattedMessage id="history.client_send_message.title" defaultMessage={`Client has send you an message:`} />
                    text = <FormattedMessage id="history.client_send_message.text" values={{ message: history.object_data.message, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`"${<strong>{history.object_data.message}</strong>}"`} />
                    position = 'left';
                }
                return (this.renderMessages(history, index, title, text, position));

            case 'historicaltaskresult': // FILE SUBMISSIONS
                position = 'right';
                if (history.change_type === '+') {
                    title = <FormattedMessage id="history.task_result_added.title" defaultMessage={`Task result has been added:`} />
                    text = <FormattedMessage id="history.task_result_added.text" values={{ result_name: history.object_data.name, b: chunks => (<strong>{chunks}</strong>) }} defaultMessage={`Freelancer added a new result set with comment: ${<strong>{history.object_data.name}</strong>}`} />
                }
                else if (history.change_type === '~') {
                    title = <FormattedMessage id="history.task_result_edited.title" defaultMessage={`Task result has been modified:`} />
                    text = <FormattedMessage id="history.task_result_edited.text" values={{ result_name: history.object_data.name, b: chunks => (<strong>{chunks}</strong>) }} defaultMessage={`Freelancer changed result set with comment: ${<strong>{history.object_data.name}</strong>}`} />
                }
                return (this.renderTaskResults(history, index, title, text, position));

            case 'historicaltaskproposal': // NEGOTIATIONS
                title = <FormattedMessage id={`history.task_proposal_changed_${history.object_data.last_action_type.toLowerCase()}.title`} defaultMessage={`Task Proposal changed translation "${history.object_data.last_action_type}" title not defined.`} />
                text = <FormattedMessage id={`history.task_proposal_changed_${history.object_data.last_action_type.toLowerCase()}.text`} defaultMessage={`Task Proposal changed translation "${history.object_data.last_action_type}" title not defined.`} />
                if (history.object_data.last_action_type === 'FREELANCER_PROPOSED' || history.object_data.last_action_type === 'ACCEPTED') {
                    position = 'right';
                } else {
                    position = 'left';
                }
                return (this.renderNegotiations(history, index, title, text, position));

            case 'historicaltask': // STATUS CHANGES (OTHER)
                if (history.object_data.changed_fields.indexOf('status') !== -1) {
                    title = <FormattedMessage id="history.task_status_changed.title" defaultMessage={`Task ${<strong>{selectedTask.name}</strong>} status changed.`} />
                    text = <FormattedMessage id={`history.task_is_${history.object_data.status.toLowerCase()}.text`} defaultMessage={`Task ${history.object_data.name} translation for ${history.object_data.status} is not defined`} />
                }
                else {
                    title = <FormattedMessage id="history.task_auditor_changed.title" values={{ task_name: selectedTask.name, b: chunks => <strong>{chunks}</strong> }} defaultMessage={`Auditor for task ${<strong>{selectedTask.name}</strong>} changed`} />
                    text = <FormattedMessage id="history.task_auditor_changed.text" defaultMessage={`Because of time emitted, active auditor changed.`} />
                }
                return (this.renderOtherHistory(history, index, title, text));

            default:
                return null;
        }
        // return (this.renderHistoryEntry(history, history.history_date, index, title, text));
    }

    render() {
        const { selectedTask } = this.props;
        if (!selectedTask.history) return null;
        return (
            <>
                <div className="audit-data-filters">
                    {['DRAFT', 'PUBLISHED'].indexOf(selectedTask.status) === -1 && <div className={"audit-data-filter f1" + (this.state.historyFilter === 1 ? " active" : "")} onClick={() => this.filterHistory(1)}>
                        <FormattedMessage id="my_audits.negotiation" defaultMessage="Negotiation" />
                    </div>}
                    {['DRAFT', 'PUBLISHED'].indexOf(selectedTask.status) === -1 && <div className={"audit-data-filter f2" + (this.state.historyFilter === 2 ? " active" : "")} onClick={() => this.filterHistory(2)}>
                        <FormattedMessage id="my_audits.communication" defaultMessage="Communication" />
                    </div>}
                    {['DRAFT', 'PUBLISHED', 'IN_NEGOTIATION'].indexOf(selectedTask.status) === -1 && <div className={"audit-data-filter f3" + (this.state.historyFilter === 3 ? " active" : "")} onClick={() => this.filterHistory(3)}>
                        <FormattedMessage id="my_audits.file_submission" defaultMessage="File submissions" />
                    </div>}
                    {['PENDING_AUDIT', 'IN_AUDIT', 'AUDITOR_CONFIRMED', 'AUDITOR_FAILED'].indexOf(selectedTask.status) !== -1 && <div className={"audit-data-filter f3" + (this.state.historyFilter === 4 ? " active" : "")} onClick={() => this.filterHistory(4)}>
                        <FormattedMessage id="common.audit" defaultMessage="Audit" />
                    </div>}
                    <div className={"audit-data-filter f4" + (this.state.historyFilter === 5 ? " active" : "")} onClick={() => this.filterHistory(5)}>
                        <FormattedMessage id="my_audits.complete" defaultMessage="Complete History" />
                    </div>
                </div>
                <VerticalTimeline>
                    {selectedTask.history.map((h, x) => this.renderDataHistory(h, x))}
                </VerticalTimeline>
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['SELECT_MY_TASK']);
const errorSelector = createErrorMessageSelector(['SELECT_MY_TASK']);
const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        selectedTask: state.myWork.myTasks.selected,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyTaskHistoryStart
})(TabAuditInfo));