import * as types from './types';
import { apiCon } from '../../../API'
import { langService } from '../../../utils/languageService';

//----------------------
// Actions Get My Rating
//----------------------
export const GetMyRatingStart = () => async dispatch => {
    dispatch({ type: types.GET_MY_RATING_REQUEST });
    await apiCon.get('/me/role/rating/')
        .then(response => {
            dispatch(GetMyRatingSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyRatingFail(err.response));
        })
}
export const GetMyRatingSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my rating info.", selected: data }
    dispatch({ type: types.GET_MY_RATING_SUCCESS, payload: payload });
}
export const GetMyRatingFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get my Rating failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get my Rating failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_RATING_FAILURE, payload: payload };
}

//----------------------
// Actions Get Rating
//----------------------
export const GetRatingStart = (roleId) => async dispatch => {
    dispatch({ type: types.GET_RATING_REQUEST });
    await apiCon.get('/role/rating/' + roleId)
        .then(response => {
            dispatch(GetRatingSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetRatingFail(err.response));
        })
}
export const GetRatingSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received rating info.", selected: data }
    dispatch({ type: types.GET_RATING_SUCCESS, payload: payload });
}
export const GetRatingFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Rating failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get Rating failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_RATING_FAILURE, payload: payload };
}

//-----------------------
// Actions Get My Reviews
//-----------------------
export const GetMyReviewsStart = (limit = 10, search, reviewerId) => async dispatch => {
    dispatch({ type: types.GET_MY_REVIEWS_REQUEST });
    const url = '/me/role/reviews/';
    var params = new URLSearchParams();
    params.append('limit', limit);
    (search) && (params.append('search', search))
        (reviewerId) && (params.append('reviewer', reviewerId))
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetMyReviewsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyReviewsFail(err.response));
        })
}
export const GetMyReviewsPageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_MY_REVIEWS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyReviewsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyReviewsFail(err.response));
        })
}
export const GetMyReviewsSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my Reviews info.", list: data }
    dispatch({ type: types.GET_MY_REVIEWS_SUCCESS, payload: payload });
}
export const GetMyReviewsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get my Reviews failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get my Reviews failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_REVIEWS_FAILURE, payload: payload };
}

//----------------------
// Actions Get Reviews
//----------------------
export const GetReviewsStart = (revieweeId = null, limit = 10, search, reviewerId) => async dispatch => {
    dispatch({ type: types.GET_REVIEWS_REQUEST });
    const url = '/role/reviews/';
    var params = new URLSearchParams();
    //Required
    params.append('reviewee', revieweeId);
    params.append('limit', limit);
    (search) && (params.append('search', search))
        (reviewerId) && (params.append('reviewer', reviewerId))
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetReviewsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetReviewsFail(err.response));
        })
}
export const GetReviewsPageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_REVIEWS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetReviewsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetReviewsFail(err.response));
        })
}
export const GetReviewsSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received Reviews info.", list: data }
    dispatch({ type: types.GET_REVIEWS_SUCCESS, payload: payload });
}
export const GetReviewsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Reviews failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get Reviews failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_REVIEWS_SUCCESS, payload: payload };
}


//-------------------------------
// Actions Get My Review Requests
//-------------------------------
export const GetMyReviewRequestsStart = (limit = 10, search, revieweeId) => async dispatch => {
    dispatch({ type: types.GET_REVIEW_TODOS_REQUEST });
    const url = '/me/review/requests/';
    var params = new URLSearchParams();
    params.append('limit', limit);
    (search) && (params.append('search', search))
        (revieweeId) && (params.append('reviewee', revieweeId))
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetMyReviewRequestsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyReviewRequestsFail(err.response));
        })
}
export const GetMyReviewRequestsPageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_REVIEW_TODOS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyReviewRequestsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyReviewRequestsFail(err.response));
        })
}
export const GetMyReviewRequestsSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my Review Requests info.", list: data }
    dispatch({ type: types.GET_REVIEW_TODOS_SUCCESS, payload: payload });
}
export const GetMyReviewRequestsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get my Review Requests failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get my Review Requests failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_REVIEW_TODOS_FAILURE, payload: payload };
}

//---------------------------------
// Actions Select My Review Request
//---------------------------------
export const SelectMyReviewRequestStart = (reviewRequestId) => async dispatch => {
    dispatch({ type: types.GET_REVIEW_TODO_REQUEST });
    await apiCon.get('/me/review/request/' + reviewRequestId)
        .then(response => {
            dispatch(SelectMyReviewRequestSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectMyReviewRequestFail(err.response));
        })
}
export const SelectMyReviewRequestSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received My Review Request info.", selected: data }
    dispatch({ type: types.GET_REVIEW_TODO_SUCCESS, payload: payload });
}
export const SelectMyReviewRequestFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select My Review Request failed. Please try again."
    if (err.data.error_first_message) { toast_msg = " Select My Review Request failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_REVIEW_TODO_FAILURE, payload: payload };
}

//------------------------------
// Actions Submit Review Request
//------------------------------
export const SubmitReviewRequestStart = (reviewRequestId, arrayOfCategoryRatings = [], comment) => async dispatch => {
    dispatch({ type: types.SUBMIT_REVIEW_REQUEST });
    const values = {
        "ratings_to_fullfill": arrayOfCategoryRatings,
        "comment": comment
    }
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `/me/review/request/` + reviewRequestId;
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(SubmitReviewRequestSuccess(response.data));
        })
        .catch(err => {
            dispatch(SubmitReviewRequestFail(err.response));
        })
}

export const SubmitReviewRequestSuccess = (data) => {
    const succ_msg = "Successfully Submitted Rating Review.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.SUBMIT_REVIEW_SUCCESS, payload: payload };
}

export const SubmitReviewRequestFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.review.submit.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SUBMIT_REVIEW_FAILURE, payload: payload };
}


//------------------------------------------
// Actions Get My Review Requests for Tasks
//------------------------------------------
export const GetMyReviewRequestsForTaskStart = (limit = 10, search, taskId) => async dispatch => {
    dispatch({ type: types.GET_REVIEW_TODOS_FOR_TASK_REQUEST });
    const url = '/me/review/requests/task/' + taskId;
    var params = new URLSearchParams();
    params.append('limit', limit);
    (search) && (params.append('search', search))
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetMyReviewRequestsForTaskSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyReviewRequestsForTaskFail(err.response));
        })
}
export const GetMyReviewRequestsForTaskPageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_REVIEW_TODOS_FOR_TASK_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyReviewRequestsForTaskSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyReviewRequestsForTaskFail(err.response));
        })
}
export const GetMyReviewRequestsForTaskSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received my Review Requests for task info.", list: data }
    dispatch({ type: types.GET_REVIEW_TODOS_FOR_TASK_SUCCESS, payload: payload });
}
export const GetMyReviewRequestsForTaskFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get my Review Requests for task failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Get my Review Requests for task failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_REVIEW_TODOS_FOR_TASK_FAILURE, payload: payload };
}

//------------------------------------------
// Actions Select My Review Request For Task
//------------------------------------------
export const SelectMyReviewRequestForTaskStart = (taskId, reviewRequestId) => async dispatch => {
    dispatch({ type: types.GET_REVIEW_TODO_FOR_TASK_REQUEST });
    await apiCon.get(`/me/review/request/task/${taskId}/${reviewRequestId}`)
        .then(response => {
            dispatch(SelectMyReviewRequestForTaskSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectMyReviewRequestForTaskFail(err.response));
        })
}
export const SelectMyReviewRequestForTaskSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received My Review Request for Task info.", selected: data }
    dispatch({ type: types.GET_REVIEW_TODO_FOR_TASK_SUCCESS, payload: payload });
}
export const SelectMyReviewRequestForTaskFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Select My Review Request for Task failed. Please try again."
    if (err.data.error_first_message) { toast_msg = " Select My Review Request for Task failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_REVIEW_TODO_FOR_TASK_FAILURE, payload: payload };
}

//---------------------------------------
// Actions Submit Review Request for Task
//---------------------------------------
export const SubmitReviewRequestForTaskStart = (reviewRequestId, taskId, arrayOfCategoryRatings = [], comment) => async dispatch => {
    dispatch({ type: types.SUBMIT_REVIEW_FOR_TASK_REQUEST });
    const values = {
        "ratings_to_fullfill": arrayOfCategoryRatings,
        "comment": comment
    }
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `/me/review/request/task/${taskId}/${reviewRequestId}`;
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(SubmitReviewRequestForTaskSuccess(response.data));
        })
        .catch(err => {
            dispatch(SubmitReviewRequestForTaskFail(err.response));
        })
}

export const SubmitReviewRequestForTaskSuccess = (data) => {
    const succ_msg = "Successfully Submitted Rating Review for Task.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.SUBMIT_REVIEW_FOR_TASK_SUCCESS, payload: payload };
}

export const SubmitReviewRequestForTaskFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.review.submit.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SUBMIT_REVIEW_FOR_TASK_FAILURE, payload: payload };
}