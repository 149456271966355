// import googleAuthRducer from './googleAuthReducer'
import { 
    LOGIN_SUCCESS, 
    GET_USER_SUCCESS,
    REGISTER_SUCCESS,
    GET_USER_FROM_LOCALE, 
    LOGOUT_SUCCESS,
    TOKEN_REFRESH_SUCCESS,
    TOKEN_REFRESH_FAILURE
} from '../../actions/auth/types'
import { GOOGLE_REGISTER_SUCCESS } from '../../actions/googleAuth/types';


const INITIAL_STATE = {
    // isAuthenticating: null,
    isAuthenticated: null,
    token: { 
        access_token : null,
        refresh_token : null
     },
    google_token: {},
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case LOGIN_SUCCESS:
            return{ ...state, isAuthenticated: true, token: action.payload.token}
        case REGISTER_SUCCESS:
        case GOOGLE_REGISTER_SUCCESS:
            return{ ...state, isAuthenticated: true, token: action.payload.token }
        case GET_USER_SUCCESS:
            return{ ...state, isAuthenticated: true }
        case GET_USER_FROM_LOCALE:
            return{ ...state, token: action.payload.token }
        case TOKEN_REFRESH_SUCCESS:
            return{ ...state, isAuthenticated: true, token: action.payload.token }
        case TOKEN_REFRESH_FAILURE:
            return{ ...INITIAL_STATE } 
        case LOGOUT_SUCCESS:
            return{ ...INITIAL_STATE }
         default:
            return state;
    }
}