import React from 'react';
import './formCompetences.scss';
import history from '../../../utils/routes/history';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Form } from 'react-bootstrap';
import { WithOutContext as ReactTags } from 'react-tag-input';
import { UpdateRoleDetailsStart } from '../../../core/actions/role';
import { GetCompetencesStart } from '../../../core/actions/competences';
import { GetMyRoleDetailsStart } from '../../../core/actions/role'
import { langService } from './../../../utils/languageService/index';
import ModalCreateANewCompetence from '../../../components/Competence/Create/modalCreateANewCompetence';
import { apiCon } from '../../../API';
import { getBloomName, getBloomVerb } from '../../../utils/common/misc';
import ModalAlert from '../../Common/ModalAlert/modalAlert'

const KeyCodes = { comma: 188, enter: 13, };
const delimiters = [KeyCodes.comma, KeyCodes.enter];

class FormCompetencesInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            obj_competences: this.props.role.competences?.map(x => {
                return {
                    id: x.id.toString(),
                    text: this.getText(x.name, x.level)
                }
            }) ?? [],
            competences: [],
            inputValue: '',
            modalCreateCompetenceVisible: false,
            modalAlertVisible: false,
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleTagClick = this.handleTagClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        // this.props.GetCompetencesStart(10, null, null, null, true);
        this.props.GetCompetencesStart({ limit: 10, platform_only: true });
        this.props.GetMyRoleDetailsStart();
        this.loadData();
    }

    // init = () => {
    //     this.props.GetCompetencesStart();
    // }

    getText = (label, level) => {
        return (
            `${label} (${getBloomName(level)} - ${getBloomVerb(1, level, langService.locale)})`)
    }

    loadData = () => {
        const { inputValue, obj_competences } = this.state;
        const url = '/competences/'
        const params = {
            'limit': 10,
            'platform_only': true,
            ...(inputValue ? { 'search': inputValue } : {}),
        }
        apiCon.get(url, { params })
            .then(response => response.data.results.filter(x => !obj_competences.some(y => y.id === x.id)).map(x => {
                return {
                    id: x.id.toString(),
                    text: this.getText(x.name, x.level)
                }
            }))
            .catch(err => {
                process.env.NODE_ENV !== "production" && console.log('Bad Luck', err);
            }).then(x => this.setState({ competences: x }))
    };

    handleDelete(i) {
        const { obj_competences, competences } = this.state;
        if (obj_competences.length !== 0) {
            const deleted = obj_competences.find((_, index) => index === i);
            if (deleted) {
                const t = obj_competences.filter((c) => c.id !== deleted.id);
                const all = competences.concat(deleted)
                this.setState({ obj_competences: t, competences: all });
            }
        }
    }

    handleAddition(tag) {
        const { obj_competences, competences } = this.state;
        if (obj_competences.length >= 5) { return; };
        const found = competences.find(el => el.id === tag.id);
        const exists = obj_competences.find(el => el.id === tag.id);
        if (found && !exists) {
            const t = obj_competences.concat(tag);
            const all = competences.filter(x => x.id !== tag.id)
            this.setState({ obj_competences: t, competences: all });
        }
    }

    handleInputChange(input) {
        if (this.state.inputValue !== input) {
            this.setState({ inputValue: input },
                () => this.loadData())
        }
    }

    openModalCreateCompetence = () => {
        this.setState({ modalCreateCompetenceVisible: true });
    }

    hideModalCreateCompetence = (competence) => {
        this.setState({ modalCreateCompetenceVisible: false });
        if (competence) {
            competence = {
                id: competence.id,
                text: this.getText(competence.name, competence.level)
            }
            this.setState({
                obj_competences: [...this.state.obj_competences, competence]
            })
        }
    }

    handleTagClick(comp) {
        const { obj_competences, competences } = this.state;
        const obj = {
            id: comp.id.toString(),
            text: this.getText(comp.name, comp.level)
        }
        if (!obj_competences.some(x => x.id === comp.id))
            this.setState({ obj_competences: obj_competences.concat(obj) })
        if (competences.some(x => x.id === comp.id)) {
            this.setState({ competences: competences.filter(x => x.id !== comp.id) })
        }
    }

    isSelected(id) {
        const { obj_competences } = this.state;
        return obj_competences.some(x => x.id === id)
    }

    next() {
        const res = [];
        this.state.obj_competences.forEach(t => {
            res.push(t.id);
        });
        res.length >= 5 ?
        this.props.UpdateRoleDetailsStart({
            competences: res
        }, 4)
        :
        this.setState({modalAlertVisible: true});
    }


    render() {
        const { base_competences } = this.props;
        const { obj_competences, competences, modalCreateCompetenceVisible } = this.state;
        return (
            <div style={{ "padding": "10px" }}>

                <Row>
                    <Col md={12} lg={9}>
                        <div className="card p-0">
                            <div className="card-head">
                                <FormattedMessage id="competences.my" defaultMessage="My Competences" />
                            </div>
                            <div className="card-body">

                                <div className="card-body-title">
                                    <FormattedMessage id="competences.add_five" defaultMessage="Add at least five competences." />
                                </div>

                                <Form autoComplete="off">
                                    <ReactTags name="tagsInput"
                                        inputFieldPosition="bottom"
                                        tags={obj_competences}
                                        suggestions={competences}
                                        handleDelete={this.handleDelete}
                                        handleAddition={this.handleAddition}
                                        allowDragDrop={false}
                                        // handleDrag={this.handleDrag}
                                        delimiters={delimiters}
                                        autofocus={false}
                                        minQueryLength={0}
                                        handleInputChange={this.handleInputChange}
                                        // renderSuggestion={this.renderSuggestion}
                                        placeholder={langService.messages['competences.search'] + "..."}
                                        classNames={{
                                            tags: 'cctags',
                                            tagInput: 'cctagInput',
                                            tagInputField: 'cctagInputField',
                                            selected: 'ccselected',
                                            tag: 'cctag',
                                            remove: 'ccremove',
                                            suggestions: 'ccsuggestions',
                                            activeSuggestion: 'ccactiveSuggestion'
                                        }}
                                    />
                                </Form>
                                <div className="form-sublabel create-competence" onClick={() => this.openModalCreateCompetence()} >
                                    + <FormattedMessage id="competences.create" defaultMessage="Create new Competence" />
                                </div>

                                <div className="card-body-subtitle">
                                    <FormattedMessage id="competences.clients_search" defaultMessage="What clients search for the most" /> :
                                </div>
                                <div className="tag-listwrap">
                                    {base_competences?.map((c, i) =>
                                        <div className={this.isSelected(c.id) ? "tagtag ctagselected" : "tagtag"}
                                            key={c.id}
                                            onClick={() => this.handleTagClick(c)}>
                                            {this.getText(c.name, c.level)}
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>

                    </Col>
                    <Col md={12} lg={3}>
                        <div className="card p-0">
                            <div className="card-head">
                                <FormattedMessage id="plural.competences.selected" values={{ count: obj_competences.length }}
                                    defaultMessage={`${obj_competences.length} Competences Selected`} />
                            </div>
                            <div className="card-body">
                                <div className="selected-listwrap">
                                    {obj_competences.map((t, x) =>
                                        <div className="sel-tag" key={x}>
                                            <span className="sel-tag-label">{t.text}</span>
                                            <span className="sel-tag-remove" onClick={() => this.handleDelete(x)}>X</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                

                <Row className="mt-3">
                    <Col className="setup-step-btns">
                        <div className="btn btn-default btn-developers" onClick={() => history.push('/profile-setup/2')}>
                            <FormattedMessage id="common.back" defaultMessage="Go Back" />
                        </div>
                        <div className="btn btn-default btn-developers" onClick={() => this.next()}>
                            <FormattedMessage id="common.next" defaultMessage="Next Step" />
                        </div>
                    </Col>
                </Row>

                {modalCreateCompetenceVisible && <ModalCreateANewCompetence visible={modalCreateCompetenceVisible} onClose={this.hideModalCreateCompetence} />}
                {this.state.modalAlertVisible && 
                    <ModalAlert
                        title="common.alertTitle"
                        text='common.alertTextCompetencies'
                        visible={this.state.modalAlertVisible}
                        onClose={() => this.setState({ modalAlertVisible: false })}
                    />
                }
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        base_competences: state.competence.list.results,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateRoleDetailsStart,
    GetCompetencesStart,
    GetMyRoleDetailsStart
})(FormCompetencesInformation));