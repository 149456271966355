import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { GET_NOTIFICATION_SETTINGS_SUCCESS, UPDATE_NOTIFICATION_SETTING_SUCCESS, SELECT_NOTIFICATION_SETTING_SUCCESS } from './../../actions/notification/types';
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../actions/role/types';

const INITIAL_STATE = {
    general: {
        id: null,
        notification_active: false,
        push_notification_active: false,
        email_active: false
    },
    messaging: {
        id: null,
        notification_active: false,
        push_notification_active: false,
        email_active: false
    },
    related: {
        id: null,
        notification_active: false,
        push_notification_active: false,
        email_active: false
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case SELECT_NOTIFICATION_SETTING_SUCCESS:
            var notSett = action.payload.selected
            return {
                ...state,
                general: notSett.notification_type === 0 ? notSett : state.general,
                messaging: notSett.notification_type === 1 ? notSett : state.messaging,
                related: notSett.notification_type === 2 ? notSett : state.related,
            };
        case GET_NOTIFICATION_SETTINGS_SUCCESS:
            var notSett = action.payload.list?.results
            return {
                ...state,
                general: notSett.filter(x => x.notification_type === 0)[0],
                messaging: notSett.filter(x => x.notification_type === 1)[0],
                related: notSett.filter(x => x.notification_type === 2)[0],
            };
        case UPDATE_NOTIFICATION_SETTING_SUCCESS:
            var notSett = action.payload.selected
            return {
                ...state,
                general: notSett.notification_type === 0 ? notSett : state.general,
                messaging: notSett.notification_type === 1 ? notSett : state.messaging,
                related: notSett.notification_type === 2 ? notSett : state.related,
            };
        default:
            return state;
    }
}