import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { langService } from '../../../utils/languageService';

const customStyles = {
    width: 'inherit',
    height: '180px',
    maxWidth: '410px',
    maxHeight: '85%',
    bottom: 'auto',
    top: '15%'
};

class ModalConfirmDelete extends React.Component {


    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.props.onClose()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        {langService.messages['common.confirmDeleteTitle']}
                        
                    </div>

                    <div className="modal-body p-2 pt-4">

                        <h6>{langService.messages['common.confirmDeleteText']}</h6>
                        
                        <br/><br/>

                        <div className="mt-4 modal-submit-buttons modal-abs-bottom">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.props.onClose()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.props.onConfirm()}>
                                <FormattedMessage id="common.yes_delete" defaultMessage="YES, DELETE" />
                            </button>
                        </div>

                    </div>
                </div>
            </Rodal>
        )
    }

}

export default injectIntl(connect(null, {})(ModalConfirmDelete));