import React from 'react'
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';


const NoMatch = () =>(
    <Container className="mt-5">
        <h1><FormattedMessage id="common.ooops" defaultMessage="Ooops!" /></h1>
        <p><FormattedMessage id="common.page_not_found" defaultMessage="The page you requested does not exist or you don't have the permission to view the URL." /></p>
    </Container>
)

export default injectIntl(connect(null, {})(NoMatch));