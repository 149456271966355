//MyTasks
export const GET_MY_TASK_RESULTS_REQUEST = "GET_MY_TASK_RESULTS_REQUEST";
export const GET_MY_TASK_RESULTS_SUCCESS = "GET_MY_TASK_RESULTS_SUCCESS";
export const GET_MY_TASK_RESULTS_FAILURE = "GET_MY_TASK_RESULTS_FAILURE";

export const SELECT_MY_TASK_RESULT_REQUEST = "SELECT_MY_TASK_RESULT_REQUEST";
export const SELECT_MY_TASK_RESULT_SUCCESS = "SELECT_MY_TASK_RESULTL_SUCCESS";
export const SELECT_MY_TASK_RESULT_FAILURE = "SELECT_MY_TASK_RESULT_FAILURE";

export const DESELECT_MY_TASK_RESULT = 'DESELECT_MY_TASK_RESULT';

export const CREATE_TASK_RESULT_REQUEST = "CREATE_TASK_RESULT_REQUEST";
export const CREATE_TASK_RESULT_SUCCESS = "CREATE_TASK_RESULT_SUCCESS";
export const CREATE_TASK_RESULT_FAILURE = "CREATE_TASK_RESULT_FAILURE";

export const UPDATE_TASK_RESULT_REQUEST = "UPDATE_TASK_RESULT_REQUEST";
export const UPDATE_TASK_RESULT_SUCCESS = "UPDATE_TASK_RESULT_SUCCESS";
export const UPDATE_TASK_RESULT_FAILURE = "UPDATE_TASK_RESULT_FAILURE";