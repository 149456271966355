import { combineReducers } from "redux";
import myProjectsReducer from './myProjectsReducer';
import myTasksReducer from './myTasksReducer';
import myTaskProposalReducer from './myTaskProposalReducer'
import myRatingRequestsForTaskReducer from "./myRatingRequestForTaskReducer";
import myRatingRequestsReducer from "./myRatingRequestsReducer";
import myTaskDisputeCaseReducer from "./myTaskDisputeCaseReducer";
import myTaskDisputeSettlementReducer from "./myTaskDisputeSettlementReducer";


export default combineReducers({
    myProjects: myProjectsReducer,
    myTasks: myTasksReducer,
    myDisputeCases: myTaskDisputeCaseReducer,
    myRatingRequestsForTask: myRatingRequestsForTaskReducer,
    // myTaskResults: myTaskResultsReducer,
    myRatingRequests: myRatingRequestsReducer,
    myDisputeSettlements: myTaskDisputeSettlementReducer,
    myTaskProposals: myTaskProposalReducer
})