import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import { connect } from 'react-redux';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import MyAuditsSelectedCase from './selectedCase';
import { GetMyTaskDisputeCasesStart, SelectMyTaskDisputeCase } from './../../core/actions/taskDisputeCases/index';

class MyAuditsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedKey: "0"
        };
    }

    componentDidMount() {
        this.init();
        const param = this.props.match.params;
        if (param && param.uid) {
            this.props.SelectMyTaskDisputeCase(param.uid, true);
        }
    }

    init = () => {
        this.props.GetMyTaskDisputeCasesStart();
    }

    selectTaskDispute(c) {
        this.props.SelectMyTaskDisputeCase(c.id);
    }

    activateToggle(eventKey) {
        this.setState({ selectedKey: eventKey });
    }

    getCasesCount(s1, s2, s3) {
        const { disputeCases } = this.props;
        var count = 0;
        disputeCases.forEach(t => {
            if (t.status === s1 || t.status === s2 || t.status === s3) {
                count++;
            }
        });
        return count;
    }

    renderSidebar() {
        const { disputeCases, selectedCase } = this.props;
        return (
            <div className="mypage-sidebar">

                <div className="sidebar-header">
                    <FormattedMessage id="auditors.dispute_cases" defaultMessage="Dispute cases" />
                </div>

                <Accordion defaultActiveKey="1">

                    <Accordion.Toggle eventKey="0" className={this.state.selectedKey === "0" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("0")}>
                        <div>
                            <FormattedMessage id="common.invited" defaultMessage="Invited" />
                            <span className="t-counts"> ({this.getCasesCount("PENDING_AUDIT")})</span>
                        </div>
                        <i className={this.state.selectedKey === "0" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <div>
                            {disputeCases.map((c, i) =>
                                (c.status === "PENDING_AUDIT") &&
                                <div className={c.id === selectedCase.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTaskDispute(c)} key={i}>
                                    {c.task.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="1" className={this.state.selectedKey === "1" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("1")}>
                        <div>
                            <FormattedMessage id="common.open" defaultMessage="Open/Active" />
                            <span className="t-counts"> ({this.getCasesCount("IN_AUDIT")})</span>
                        </div>
                        <i className={this.state.selectedKey === "1" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <div>
                            {disputeCases.map((c, i) =>
                                (c.status === "IN_AUDIT") &&
                                <div className={c.id === selectedCase.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTaskDispute(c)} key={i}>
                                    {c.task.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="2" className={this.state.selectedKey === "2" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("2")}>
                        <div>
                            <FormattedMessage id="common.closed" defaultMessage="Closed" />
                            <span className="t-counts"> ({this.getCasesCount("TASK_FAILED", "TASK_CONFIRMED")})</span>
                        </div>
                        <i className={this.state.selectedKey === "2" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <div>
                            {disputeCases.map((c, i) =>
                                (c.status === "TASK_FAILED" || c.status === "TASK_CONFIRMED") &&
                                <div className={c.id === selectedCase.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTaskDispute(c)} key={i}>
                                    {c.task.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="3" className={this.state.selectedKey === "3" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("3")}>
                        <div>
                            <FormattedMessage id="common.failed" defaultMessage="Failed" />
                            <span className="t-counts"> ({this.getCasesCount("FAILED")})</span>
                        </div>
                        <i className={this.state.selectedKey === "3" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <div>
                            {disputeCases.map((c, i) =>
                                (c.status === "FAILED") &&
                                <div className={c.id === selectedCase.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTaskDispute(c)} key={i}>
                                    {c.task.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                </Accordion>

            </div>
        )
    }

    render() {
        return (
            <Container className="mt-4">
                <Row>

                    <Col xs={12} md={3} className="my-sidebar pb-3">
                        {this.renderSidebar()}
                    </Col>

                    <Col xs={12} md={9}>
                        <div className="card my-content">
                            {this.props.selectedCase.task?.id && <MyAuditsSelectedCase /> }
                        </div>
                    </Col>

                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        disputeCases: state.myWork.myDisputeCases.list.results,
        selectedCase: state.myWork.myDisputeCases.selected
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyTaskDisputeCasesStart,
    SelectMyTaskDisputeCase,
})(MyAuditsPage));