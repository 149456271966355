//MyTaskDisputeSettlement
export const SELECT_TASK_DISPUTE_SETTLEMENT_REQUEST = "SELECT_TASK_DISPUTE_SETTLEMENT_REQUEST";
export const SELECT_TASK_DISPUTE_SETTLEMENT_SUCCESS = "SELECT_TASK_DISPUTE_SETTLEMENT_SUCCESS";
export const SELECT_TASK_DISPUTE_SETTLEMENT_FAILURE = "SELECT_TASK_DISPUTE_SETTLEMENT_FAILURE";

export const DESELECT_TASK_DISPUTE_SETTLEMENT = 'DESELECT_TASK_DISPUTE_SETTLEMENT';

export const CREATE_TASK_DISPUTE_SETTLEMENT_REQUEST = "CREATE_TASK_DISPUTE_SETTLEMENT_REQUEST";
export const CREATE_TASK_DISPUTE_SETTLEMENT_SUCCESS = "CREATE_TASK_DISPUTE_SETTLEMENT_SUCCESS";
export const CREATE_TASK_DISPUTE_SETTLEMENT_FAILURE = "CREATE_TASK_DISPUTE_SETTLEMENT_FAILURE";

export const UPDATE_TASK_DISPUTE_SETTLEMENT_REQUEST = "UPDATE_TASK_DISPUTE_SETTLEMENT_REQUEST";
export const UPDATE_TASK_DISPUTE_SETTLEMENT_SUCCESS = "UPDATE_TASK_DISPUTE_SETTLEMENT_SUCCESS";
export const UPDATE_TASK_DISPUTE_SETTLEMENT_FAILURE = "UPDATE_TASK_DISPUTE_SETTLEMENT_FAILURE";

export const DELETE_TASK_DISPUTE_SETTLEMENT_REQUEST = "DELETE_TASK_DISPUTE_SETTLEMENT_REQUEST";
export const DELETE_TASK_DISPUTE_SETTLEMENT_SUCCESS = "DELETE_TASK_DISPUTE_SETTLEMENT_SUCCESS";
export const DELETE_TASK_DISPUTE_SETTLEMENT_FAILURE = "DELETE_TASK_DISPUTE_SETTLEMENT_FAILURE";