//-------------------
//  EXPERIENCE Experience actions
//-------------------
export const GET_EXPERIENCES_REQUEST = "GET_EXPERIENCES_REQUEST";
export const GET_EXPERIENCES_SUCCESS = "GET_EXPERIENCES_SUCCESS";
export const GET_EXPERIENCES_FAILURE = "GET_EXPERIENCES_FAILURE";

export const SELECT_EXPERIENCE_REQUEST = "SELECT_EXPERIENCE_REQUEST";
export const SELECT_EXPERIENCE_SUCCESS = "SELECT_EXPERIENCE_SUCCESS";
export const SELECT_EXPERIENCE_FAILURE = "SELECT_EXPERIENCE_FAILURE";

export const UPDATE_EXPERIENCE_REQUEST = "UPDATE_EXPERIENCE_REQUEST";
export const UPDATE_EXPERIENCE_SUCCESS = "UPDATE_EXPERIENCE_SUCCESS";
export const UPDATE_EXPERIENCE_FAILURE = "UPDATE_EXPERIENCE_FAILURE";

export const DELETE_EXPERIENCE_REQUEST = "DELETE_EXPERIENCE_REQUEST";
export const DELETE_EXPERIENCE_SUCCESS = "DELETE_EXPERIENCE_SUCCESS";
export const DELETE_EXPERIENCE_FAILURE = "DELETE_EXPERIENCE_FAILURE";

export const CREATE_EXPERIENCE_REQUEST = "CREATE_EXPERIENCE_REQUEST";
export const CREATE_EXPERIENCE_SUCCESS = "CREATE_EXPERIENCE_SUCCESS";
export const CREATE_EXPERIENCE_FAILURE = "CREATE_EXPERIENCE_FAILURE";
