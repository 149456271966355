import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { GET_NOTIFICATION_STATUS_SUCCESS, GET_NOTIFICATIONS_SUCCESS, MARK_NOTIFICATION_AS_READ_SUCCESS, WEB_SOCKET_ADD_NOTIFICATION } from '../../actions/notification/types'
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../actions/role/types'

const INITIAL_STATE = {
    unseen_notifications: 0,
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case GET_NOTIFICATION_STATUS_SUCCESS:
            return {
                ...state, unseen_notifications: action.payload.counter,
            };
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state, unseen_notifications: 0,
                list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? action.payload.list.results.concat(state.list.results) : action.payload.list.results
                }
            };
        case MARK_NOTIFICATION_AS_READ_SUCCESS:
            var selected = action.payload.selected
            return {
                ...state, list: {
                    ...state.list, results: state.list?.results.map(not => {
                        return not.id === selected.id ? selected : not
                    })
                }
            };
        case WEB_SOCKET_ADD_NOTIFICATION:
            return {
                ...state, unseen_notifications: state.unseen_notifications + 1,
            };
        default:
            return state;
    }
}