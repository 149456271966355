//--------------------------
//  Authentication actions
//--------------------------
export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILURE = "GOOGLE_LOGIN_FAILURE";

export const GOOGLE_REGISTER_REQUEST = "GOOGLE_REGISTER_REQUEST";
export const GOOGLE_REGISTER_SUCCESS = "GOOGLE_REGISTER_SUCCESS";
export const GOOGLE_REGISTER_FAILURE = "GOOGLE_REGISTER_FAILURE";
