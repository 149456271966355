import React from 'react';
import '../browse-styles.scss';
import './projectDetails.scss';
import history from '../../../utils/routes/history';
import ModalFileViewer from '../../../components/Common/ModalFileViewer/modalFileViewer';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Tabs, Tab, Jumbotron, Image } from 'react-bootstrap';
import { formatDateRange, getFileExtension, getFileName, contains } from '../../../utils/common';
import { SelectProjectStart, SelectMyProjectStart } from './../../../core/actions/projects';
import { GetMyTaskProposalsStart } from './../../../core/actions/taskProposals';
import { DeselectTask } from './../../../core/actions/tasks/index';
import { langService } from './../../../utils/languageService/index';
import Infobar from '../../../components/Infobar';

class ProjectDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalFileViewerVisible: false,
            selectedDocument: null
        };
    }

    componentDidMount() {

        const param = this.props.match.params;

        if (param) {
            this.props.SelectProjectStart(param.uid);
            if (param.tab) {
                this.setState({ activeKey: parseInt(param.tab) });
            }
        } else {
            history.push("/browse/projects"); // no data + no id...redirect
        }

        if (param && param.tab) {
            this.setState({ activeKey: parseInt(param.tab) });
        }
    }

    handleChange(selectedkey) {
        this.setState({ activeKey: selectedkey });
    };

    showTasks() {
        this.setState({ activeKey: 2 });
    }

    openTaskDetails(id) {
        this.props.DeselectTask()
        history.push("/project/" + this.props.project.id + "/task/" + id);
    }

    renderParticipants() {
        const { project } = this.props;

        if (project.participants?.length === 1) {
            return (
                <>
                    <Image className="participant-image" src={project.participants[0].profile_image}></Image>
                    <div className="">{project.participants[0].name}</div>
                </>
            )
        } else if (project.participants?.length > 1) {
            return (
                <>
                    <Image className="participant-image" src={project.participants[0].profile_image}></Image>
                    <div className="">{project.participants[0].name} {langService.messages['forms.labels.and']} {project.participants.length} {langService.messages['forms.labels.more']}</div>
                </>
            )
        }
        return (<></>)
    }

    openFileViewer(file) {
        this.setState({ selectedDocument: file, modalFileViewerVisible: true });
    }

    // Modal File Viewer
    renderModalFileViewer() {
        if (this.state.modalFileViewerVisible) {
            return (
                <ModalFileViewer visible={this.state.modalFileViewerVisible} file={this.state.selectedDocument} onClose={() => this.hideModalFileViewer()} />
            )
        }
    }
    hideModalFileViewer() {
        this.setState({ modalFileViewerVisible: false });
    }

    render() {
        const { project, role } = this.props;
        return (
            <>
                <Jumbotron
                    className="jumbo-project-details"
                    style={project?.cover_image ? { backgroundImage: `url(${project?.cover_image})`, backgroundSize: 'cover' } : {}}
                >
                    <Infobar />
                    <div className="container p-img-container">
                        {
                            project?.logo_image ?
                                <img className="p-img" src={project?.logo_image} alt="Project details" />
                                :
                                <img className="p-img" src="/assets/img/default-project.png" alt="Project details" />
                        }
                        <div className="p-img-overlay"></div>
                    </div>
                </Jumbotron>

                <Container>

                    {/* HEADER */}
                    <div className="p-header">

                        <div className="p-first-row">
                            <span className="p-name">
                                {project.name}
                            </span>
                            <div className="c-header">
                                {project?.client?.profile_image
                                    ?
                                    <img className="c-img" width="34" src={project?.client?.profile_image} alt="Client logo" />
                                    :
                                    <img className="c-img" width="34" src="/assets/img/default-avatar.png" alt="Client logo" />
                                }
                                <span className="c-name browse-namelink-freelancer" onClick={() => history.push("/user/" + project?.client?.id)} >{project?.client?.name}</span>
                                {project?.client?.rating?.rating &&
                                    <>
                                        <span className="p-star" role="img" aria-label="Star rating">⭐</span>
                                        <span className="c-rating">{project?.client?.rating?.rating}/<small>5</small></span>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="p-overview">
                            <div>
                                <div className="pr1">
                                    <img src="/assets/img/icon/budget.svg" alt="budget"></img>
                                    <span className="p-overview-title">
                                        <FormattedMessage id="browse.project.budget" defaultMessage="Budget" />
                                    </span>
                                </div>
                                <div className="p-overview-val">${project.estimated_budget ? parseInt(project.estimated_budget).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : project.estimated_budget}</div>
                            </div>
                            <div>
                                <div className="pr1">
                                    <img src="/assets/img/icon/calendar_timeframe.svg" alt="calendar timeframe"></img>
                                    <span className="p-overview-title">
                                        <FormattedMessage id="browse.project.timeframe" defaultMessage="Timeframe" />
                                    </span>
                                </div>
                                <div className="p-overview-val">{project.visibility && project.visibility === "PUBLIC" ? formatDateRange(new Date(project.estimated_start), new Date(project.estimated_end)) : 'N/A'}</div>
                            </div>
                            <div>
                                <div className="pr1">
                                    <img src="/assets/img/icon/open_tasks.svg" alt="open tasks"></img>
                                    <span className="p-overview-title">
                                        <FormattedMessage id="browse.project.open_tasks" defaultMessage="Open Tasks" />
                                    </span>
                                </div>
                                <div className="p-overview-val">{project.number_of_tasks}</div>
                            </div>
                            <div>
                                <div className="pr1">
                                    <img src="/assets/img/icon/participants.svg" alt="participants"></img>
                                    <span className="p-overview-title">
                                        <FormattedMessage id="browse.project.participating" defaultMessage="Participating in project" />
                                    </span>
                                </div>
                                <div className="p-overview-participants">
                                    {this.renderParticipants()}
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* TABS */}
                    <div className="p-tabs">

                        <Tabs defaultActiveKey={1} activeKey={this.state.activeKey} className="p-tabs" onSelect={() => this.handleChange()}>

                            {/* PROJECT INFO */}
                            <Tab eventKey={1} title={langService.messages['browse.project.project_details']}>
                                <div className="p-tab-content">
                                    <div className="content-title">
                                        <FormattedMessage id="browse.project.about" defaultMessage="About" />
                                    </div>
                                    <span>{project.visibility === 'PRIVATE' ? project.short_description : project.description}</span>
                                    <div className="content-title">
                                        <FormattedMessage id="forms.labels.category" defaultMessage="Category" />
                                    </div>
                                    <div className="card-tags">
                                        <span className="no-hover">{project?.category?.name}</span>
                                    </div>
                                    {
                                        project.visibility !== 'PRIVATE' &&
                                        <>
                                            <div className="content-title">
                                                <FormattedMessage id="forms.labels.description" defaultMessage="Description" />
                                            </div>
                                            <span>{project.description}</span>
                                        </>
                                    }
                                    <div className="content-title">
                                        <FormattedMessage id="plural.files.attached" values={{ count: project?.documents?.length }}
                                            defaultMessage={`${project?.documents?.length} Files Attached`} />
                                    </div>
                                    <div className="attachments">
                                        {project?.documents?.map((doc, i) =>
                                            <div className="attachment-box" key={i}>
                                                <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? 'generic' : getFileExtension(doc.document)}`}></div>
                                                {/* <div className="download-link" onClick={() => this.downloadFile(doc.document)} alt="project attachment">{getFileName(doc.document)}</div> */}
                                                <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                                            </div>
                                        )}
                                    </div>
                                    {this.renderModalFileViewer()}
                                </div>
                                <div className="p-content-footer">
                                    <span>
                                        <FormattedMessage id="browse.project.join" defaultMessage="Join the project!" />
                                    </span>
                                    <div className="btn btn-p-explore" onClick={() => this.showTasks()} >
                                        <FormattedMessage id="browse.project.explore" defaultMessage="Explore tasks" />
                                    </div>
                                </div>
                            </Tab>

                            {/* OPEN TASKS */}
                            <Tab className="pt-3" eventKey={2} title={langService.messages['browse.project.open_tasks'] + " (" + project.number_of_tasks + ")"}>

                                {project.milestones?.list?.map((milestone, v) =>
                                    <div key={v}>
                                        {project.milestones.list.length > 1 &&
                                            <>
                                                <div className={"browse-milestone mc" + milestone.color}>{milestone.id !== null ? milestone.name : "Default"}</div>
                                                <div style={{ "height": "1px" }} className={"m" + milestone.color}></div>
                                            </>
                                        }

                                        <div className="task-list">

                                            {milestone.tasks.map((task, x) =>
                                                // (task.status === "PUBLISHED" || task.status === "IN_NEGOTIATION") &&
                                                <div className="task-card" key={x}>
                                                    <span className="task-card-title">{task.name}</span>
                                                    <div></div>
                                                    <div className="task-card-body">
                                                        {task.short_description}
                                                    </div>
                                                    <div className="card-tags">
                                                        {task.competences.map((c, x) =>
                                                            <span onClick={() => history.push('/competency/' + c.id)} key={x}>{c.name} L{c.level}</span>
                                                        )}
                                                    </div>
                                                    <div className="card-params">
                                                        <span>${task.price ? parseInt(task.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : task.price}</span>
                                                        <span>{task.visibility && task.visibility === "PUBLIC" ? formatDateRange(new Date(task.start_date), new Date(task.end_date)) : 'N/A'}</span>
                                                    </div>
                                                    <div>
                                                        {role?.profile_completion?.email_verification
                                                            ? <div className="btn btn-projects" onClick={() => this.openTaskDetails(task.id)}>
                                                                <FormattedMessage id="browse.project.viewtask" defaultMessage="View details" />
                                                            </div>
                                                            : <div className="">
                                                                <strong>
                                                                    <FormattedMessage id="browse.project.verify_email" defaultMessage="Please verify your email to view task details" />
                                                                </strong>
                                                            </div>
                                                        }
                                                    </div>
                                                    {role.id === task.active_freelancer &&
                                                        <div className="btn btn-projects mt-2" onClick={() => history.push('/my-work/' + task.id)}>
                                                            <FormattedMessage id="browse.common.view_my_work" defaultMessage="View in My Work" />
                                                        </div>
                                                    }


                                                </div>
                                            )}
                                        </div>

                                    </div>
                                )}

                            </Tab>

                            {/* ABOUT CLIENT */}
                            <Tab eventKey={3} title={langService.messages['browse.project.about_client']}>
                                <div className="p-tab-content">

                                    <div className="content-title">
                                        <FormattedMessage id="browse.project.company" defaultMessage="Company" />
                                    </div>
                                    <span className="browse-namelink-freelancer" onClick={() => history.push("/user/" + project?.client?.id)} >{project?.client?.name}</span><br />
                                    <span>{project.client.description}</span>

                                    {/* <div className="content-title">Name</div>
                                    <span>{project.client.name}</span> */}

                                    {/* <div className="content-title">
                                        <FormattedMessage id="browse.project.location" defaultMessage="Location" />
                                    </div>
                                    <span>{project.client?.city}, {project.client?.country}</span> */}

                                </div>
                            </Tab>

                        </Tabs>

                    </div>

                </Container>


            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        project: state.browse.projects.selected,
        selectedTask: state.browse.projects.selected_task,
        taskProposals: state.myWork.myTaskProposals.list.results
    }
}

export default injectIntl(connect(mapStateToProps, {
    SelectProjectStart,
    DeselectTask,
    SelectMyProjectStart,
    GetMyTaskProposalsStart
})(ProjectDetails));