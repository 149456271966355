import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './index.scss';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { langService } from './../../utils/languageService/index';
import { CreateIssuerStart, UpdateIssuerDetailsStart } from '../../core/actions/issuer';
import ReactTooltip from 'react-tooltip';

const customStyles = {
    width: 'inherit',
    height: '405px', //'inherit',
    maxWidth: '450px',
    maxHeight: '85%',
    bottom: 'auto',
    top: '15%'
};


class ModalCreateIssuer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.issuer?.name ? this.props.issuer.name : '',
            allow_competence_public_insert: this.props.issuer?.allow_competence_public_insert ? this.props.issuer.allow_competence_public_insert : false,
            allow_competence_voting: this.props.issuer?.allow_competence_voting ? this.props.issuer.allow_competence_voting : false,
            task_mint_type: this.props.issuer?.task_mint_type ? this.props.issuer.task_mint_type : 1,
            is_external: this.props.issuer?.is_external ? this.props.issuer.is_external : false,
            edit: this.props.issuer?.issuer_prefix ? true : false
        };
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    checkBoxChange = (e) => {
        switch (e.target.id) {
            case 'c1':
                this.setState({ allow_competence_public_insert: !this.state.allow_competence_public_insert });       
                break;
            case 'c2':
                this.setState({ allow_competence_voting: !this.state.allow_competence_voting });       
                break;
            case 'c3':
                this.setState({ is_external: !this.state.is_external });       
                break;                
            default:
                break;
        }
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        // form validation
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }

        this.setState({ validated: true });
    };

    save() {   
        const obj = {
            name: this.state.name,
            task_mint_type: this.state.task_mint_type,
            allow_competence_public_insert: this.state.allow_competence_public_insert,
            allow_competence_voting: this.state.allow_competence_voting,
            is_external: this.state.is_external
        }
        if (this.state.edit) {
            this.props.UpdateIssuerDetailsStart(this.props.owner, obj).then(
                // this.setState({ name: '', task_mint_type: 1, allow_competence_public_insert: true, allow_competence_voting: true, is_external: true }),
                this.props.onConfirm(),
                this.props.onClose()
            );
        } else {
            this.props.CreateIssuerStart(obj).then(
                this.setState({ name: '', task_mint_type: 1, allow_competence_public_insert: true, allow_competence_voting: true, is_external: true }),
                this.props.onConfirm(),
                this.props.onClose()
            );
        }
    }

    cancel() {
        this.setState({ 
            name: '', 
            allow_competence_public_insert: true,
            allow_competence_voting: true,
            task_mint_type: 1,
            is_external: true,
            validated: false,
            edit: false
        });
        this.props.onClose();
    }

    toolTipMessage(message, n) {
        return (
            <>
                <div className="info-icon-wrap">
                    <img src="/assets/img/icon/info.png" data-tip data-for={ "competency" + n } alt="info-icon" width="13"></img>
                </div>
                <ReactTooltip id={ "competency" + n } place="right" type="dark" effect="solid">
                    <div className="tooltip-txt">
                        {message}
                    </div>
                </ReactTooltip>
            </>
        )
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.props.onClose()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        { this.state.edit
                            ? <FormattedMessage id="issuers.edit" defaultMessage="Edit issuer" />
                            : <FormattedMessage id="issuers.add_new" defaultMessage="Add a New Issuer" />
                        }
                    </div>

                    <div className="modal-body p-2 pt-3">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formName">
                                    <div className="tooltip-parent">
                                        <div className="tooltip-title">
                                            <div><Form.Label><FormattedMessage id="issuers.issuer_name" defaultMessage="Issuer Id (used to generate competency prefix)" /></Form.Label></div>
                                            {this.toolTipMessage(langService.messages['tooltip.issuer_id'], 1)}
                                        </div>
                                    </div>
                                    <Form.Control type="text" maxLength="6" style={{'width': '50%'}} onChange={this.handleChange('name')} value={this.state.name || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formType">
                                    <div className="tooltip-parent">
                                        <div className="tooltip-title">
                                            <div><Form.Label><FormattedMessage id="issuers.mint_type" defaultMessage="Digital Signature Type" /></Form.Label></div>
                                            {this.toolTipMessage(langService.messages['tooltip.issuer_task_competencies'], 2)}
                                        </div>
                                    </div>
                                    <div className="tooltip-parent">
                                        <div className="tooltip-title">
                                            <Form.Check type="radio" value="1" onChange={this.handleChange('task_mint_type')} label={langService.messages['issuers.MINT_DISABLED']} name="formMintTypeRadios" id="formMintType1" defaultChecked={this.state.task_mint_type === 1} />
                                            {this.toolTipMessage(langService.messages['tooltip.issuer_disabled'], 3)}
                                        </div>
                                    </div>
                                    <div className="tooltip-parent">
                                        <div className="tooltip-title">
                                            <Form.Check type="radio" value="2" onChange={this.handleChange('task_mint_type')} label={langService.messages['issuers.MINT_ON_SYS_ISSUER']} name="formMintTypeRadios" id="formMintType2" defaultChecked={this.state.task_mint_type === 2} />
                                            {this.toolTipMessage(langService.messages['tooltip.issuer_sys_certificate'], 4)}
                                        </div>
                                    </div>
                                    <div className="tooltip-parent">
                                        <div className="tooltip-title">
                                            <Form.Check type="radio" value="3" onChange={this.handleChange('task_mint_type')} label={langService.messages['issuers.MINT_ON_SAME_ISSUER']} name="formMintTypeRadios" id="formMintType3" defaultChecked={this.state.task_mint_type === 3} />
                                            {this.toolTipMessage(langService.messages['tooltip.issuer_my_certificate'], 5)}
                                        </div>
                                    </div>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formCheck1">
                                    <div className="tooltip-parent">
                                        <div className="tooltip-title">
                                            <Form.Check inline label={langService.messages['issuers.allow_public_insert']} id="c1" type="checkbox" checked={this.state.allow_competence_public_insert} onChange={this.checkBoxChange} />
                                            {this.toolTipMessage(langService.messages['tooltip.issuer_public_adding'], 6)}
                                        </div>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formCheck2">
                                    <div className="tooltip-parent">
                                        <div className="tooltip-title">
                                            <Form.Check inline label={langService.messages['issuers.allow_voting']} id="c2" type="checkbox" checked={this.state.allow_competence_voting} onChange={this.checkBoxChange} />
                                            {this.toolTipMessage(langService.messages['tooltip.issuer_public_voting'], 7)}
                                        </div>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formCheck3">
                                    <div className="tooltip-parent">
                                        <div className="tooltip-title">
                                            <Form.Check inline label={langService.messages['issuers.is_external']} id="c3" type="checkbox" checked={this.state.is_external} onChange={this.checkBoxChange} />
                                            {this.toolTipMessage(langService.messages['tooltip.issuer_external_issuer'], 8)}
                                        </div>
                                    </div>
                                </Form.Group>
                            </Form.Row>

                            <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2">
                                <FormattedMessage id="common.save" defaultMessage="Save" />
                            </button>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.cancel()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>

                        </Form>

                    </div>

                </div>
            </Rodal >
        )
    }
}

export default injectIntl(connect(null, {
    CreateIssuerStart,
    UpdateIssuerDetailsStart
})(ModalCreateIssuer));