import React from 'react';
import { connect } from 'react-redux';
import { ChangeLangAction } from '../../core/actions/lang';
import { Select } from '../../utils/forms/formElements';


class LanguageSelect extends React.Component {

    // componentDidMount() {
    //     const lang = localStorage.getItem('lang');
    //     this.props.ChangeLangAction(lang ? lang : 'en');
    // }

    render() {
        return (
            <div className="lang-select">
                <Select
                    name="langSelect"
                    aria-label="Select language"
                    className={this.props.transparent ? "header-lang-landing" : "header-lang"}
                    // label={<FormattedMessage id="components.languageSelect" defaultMessage="languageSelect" />}
                    // defaultValue={localStorage.getItem('lang')}
                    defaultValue={this.props.lang}
                    options={[
                        { label: "SI", value: 'sl' },
                        { label: "EN", value: 'en' }
                    ]}
                    onChange={(e) => { this.props.ChangeLangAction(e.target.value) }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.lang
    }
}

export default connect(mapStateToProps, { ChangeLangAction })(LanguageSelect);