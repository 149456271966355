import React from 'react';
import './account.scss';
import { connect } from 'react-redux';
import { Row, Col, Form, Button, Image } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { UpdateRoleStart, GetRoleStart } from './../../core/actions/role/index';
import ModalDeleteRole from './modalDeleteRole';
import { langService } from '../../utils/languageService';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

class SettingsAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.role.id,
            company_name: this.props.role.company_name,
            vat: this.props.role.vat,
            address_line_1: this.props.role.address_line_1,
            address_line_2: this.props.role.address_line_2,
            post_code: this.props.role.post_code,
            state: this.props.role.state,
            company_city: this.props.role.company_city,
            company_country: this.props.role.company_country,
            company_reg_no: this.props.role.company_reg_no,
            is_company: this.props.role.is_company ? this.props.role.is_company : false,
            validated: false,
            modalVisible: false
        };
        this.checkBoxChange = this.checkBoxChange.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        this.props.GetRoleStart();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            this.setState({
                id: nextProps.role.id,
                company_name: nextProps.role.company_name,
                vat: nextProps.role.vat,
                address_line_1: nextProps.role.address_line_1,
                address_line_2: nextProps.role.address_line_2,
                post_code: nextProps.role.post_code,
                state: nextProps.role.state,
                company_reg_no: nextProps.role.company_reg_no,
                company_city: nextProps.role.company_city,
                company_country: nextProps.role.company_country,
                is_company: nextProps.role.is_company ? nextProps.role.is_company : false
            });
        }
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    checkBoxChange = (e) => {
        const target = e.target;
        if (!target.checked) {
            this.props.UpdateRoleStart({
                is_company: false,
            }, 0);
        }
        this.setState({ is_company: target.checked });
    }

    onSelectFlag(countryCode) {
        this.setState({ company_country: countryCode });
    }

    handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
        }
        this.setState({ validated: true });
    };

    save() {
        if (this.state.is_company) {
            this.props.UpdateRoleStart({
                is_company: true,
                company_name: this.state.company_name,
                vat: this.state.vat,
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                post_code: this.state.post_code,
                company_city: this.state.company_city,
                company_country: this.state.company_country,
                state: this.state.state,
                company_reg_no: this.state.company_reg_no
            }, 0);
        } else {
            this.props.UpdateRoleStart({
                is_company: false,
            }, 0);
        }
    }

    hideModalDeleteAccount() {
        this.setState({ modalVisible: false });
    }

    render() {
        const { role, email } = this.props;
        return (
            <div className="card p-0">
                <div className="card-head">
                    <FormattedMessage id="settings.account_settings" defaultMessage="User account settings" />
                </div>
                <div className="card-body">

                    <Row>
                        <Col>
                            <div className="sett-col-title">
                                <FormattedMessage id="settings.current_role" defaultMessage="Your current account role" />
                            </div>
                            <hr />
                            <div className="delete-role-info">
                                <div className="">
                                    <Image src={role.profile_image} height="64"></Image>
                                </div>
                                <div className="delete-role-details">
                                    {/* <div className="">{role.display_name}</div> */}
                                    <div className=""><FormattedMessage id={"roles.name." + role.type} defaultMessage="" /></div>
                                    <div className="">{email}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {(role.type !== 'EAP' &&  role.type !== 'EAS' && role.type !== 'EAC') &&
                    <>
                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                        <Row>
                            <Col>

                                <div className="" style={{ "marginBottom": "20px", "fontWeight": "bold" }}>
                                    <Form.Check inline label={langService.messages['setup_profile.represent']} name="is_company" type="checkbox" checked={this.state.is_company} onChange={this.checkBoxChange} />
                                </div>

                                <div className={!this.state.is_company ? "hide-section" : ""}>

                                    <div style={{ "marginBottom": "20px", "fontWeight": "bold" }}>
                                        <FormattedMessage id="setup_profile.fill_company_data" defaultMessage="Please fill out the following information if you represent a company." />
                                        <br />
                                    </div>

                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>
                                                <FormattedMessage id="forms.labels.company_name" defaultMessage="Company Name" />
                                            </Form.Label>
                                            <Form.Control type="text" onChange={this.handleChange('company_name')} value={this.state.company_name || ''} disabled={!this.state.is_company} required={this.state.is_company} />
                                            <Form.Control.Feedback type="invalid">
                                                <FormattedMessage id="validators.required" defaultMessage="This field is required." />
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>
                                                <FormattedMessage id="forms.labels.vat" defaultMessage="Vat Number" />
                                            </Form.Label>
                                            <Form.Control type="text" onChange={this.handleChange('vat')} value={this.state.vat || ''} disabled={!this.state.is_company} required={this.state.is_company} />
                                            <Form.Control.Feedback type="invalid">
                                                <FormattedMessage id="validators.required" defaultMessage="This field is required." />
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label>
                                                <FormattedMessage id="forms.labels.register_num" defaultMessage="Register Number" />
                                            </Form.Label>
                                            <Form.Control type="text" onChange={this.handleChange('company_reg_no')} value={this.state.company_reg_no || ''} disabled={!this.state.is_company} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col}>
                                            <Form.Label><FormattedMessage id="modalEditProfileInfo.address" defaultMessage="Address" /> </Form.Label>
                                            <Form.Control type="text" onChange={this.handleChange('address_line_1')} value={this.state.address_line_1 || ''} disabled={!this.state.is_company} />
                                        </Form.Group>
                                        <Form.Group as={Col}>
                                            <Form.Label><FormattedMessage id="modalEditProfileInfo.address" defaultMessage="Address" /> 2</Form.Label>
                                            <Form.Control type="text" onChange={this.handleChange('address_line_2')} value={this.state.address_line_2 || ''} disabled={!this.state.is_company} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formZIP">
                                            <Form.Label><FormattedMessage id="modalEditProfileInfo.zip" defaultMessage="ZIP" /></Form.Label>
                                            <Form.Control type="text" onChange={this.handleChange('post_code')} value={this.state.post_code || ''} disabled={!this.state.is_company} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formCity">
                                            <Form.Label><FormattedMessage id="modalEditProfileInfo.city" defaultMessage="City" /></Form.Label>
                                            <Form.Control type="text" onChange={this.handleChange('company_city')} value={this.state.company_city || ''} />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formState">
                                            <Form.Label><FormattedMessage id="modalEditProfileInfo.state" defaultMessage="State" /></Form.Label>
                                            <Form.Control type="text" onChange={this.handleChange('state')} value={this.state.state || ''} disabled={!this.state.is_company} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formCountry">
                                            <Form.Label><FormattedMessage id="modalEditProfileInfo.country" defaultMessage="Country" /></Form.Label>
                                            <ReactFlagsSelect type="button" className="form-control flag-select-wrap" searchable={true} defaultCountry={this.state.company_country ? this.state.company_country : 'US'} onSelect={(c) => this.onSelectFlag(c)} />
                                        </Form.Group>
                                    </Form.Row>

                                </div>

                            </Col>
                        </Row>

                        <Row className={!this.state.is_company ? "hide-section" : "mt-3"}>
                            <Col className="setup-step-btns mb-4">
                                <Button type="submit" className="btn btn-default">
                                    <FormattedMessage id="forms.labels.update" defaultMessage="Update" />
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                    
                        <div className="">
                            <div className="btn btn-projects-inv" onClick={() => this.setState({ modalVisible: true })}>
                                <FormattedMessage id="roles.delete_this_role" defaultMessage="Delete this role?" />
                            </div>
                            {this.state.modalVisible &&
                                <ModalDeleteRole
                                    visible={this.state.modalVisible}
                                    onClose={() => this.setState({ modalVisible: false })} />
                            }
                        </div>
                    </>
                    }

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        email: state.user.info.email,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateRoleStart,
    GetRoleStart
})(SettingsAccount));