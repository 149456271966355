import React from 'react';
import history from '../../../utils/routes/history';
import './formWorkInformation.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col } from 'react-bootstrap';
import { WorkingDaysArr } from '../../../utils/common';
import { UpdateRoleDetailsStart, GetMyRoleDetailsStart } from '../../../core/actions/role';
import { langService } from '../../../utils/languageService';
const arrDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


class FormWorkInformation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            selectedAvailabilityOption: this.props.role.availability,
            sselectedProjectsOption: this.areAllDurationsFilled() ? 'custom' : 'all',
            showCheckRow: "none",
            showDurationRow: this.areAllDurationsFilled() ? 'flex' : 'none',
            days: [true, true, true, true, true, false, false],
            from: this.props.role.project_duration_from,
            fromTime: this.props.role.project_duration_from_choice ? this.props.role.project_duration_from_choice : 'Days',
            to: this.props.role.project_duration_to,
            toTime: this.props.role.project_duration_to_choice ? this.props.role.project_duration_to_choice : 'Days',
            task_remuneration_from: this.props.role.task_remuneration_from ? parseInt(this.props.role.task_remuneration_from) : '500',
            task_remuneration_to: this.props.role.task_remuneration_to ? parseInt(this.props.role.task_remuneration_to) : '1000',
            hourly_rate: this.props.role.hourly_rate
        };
    }

    areAllDurationsFilled = () => {
        if (this.props.role.project_duration_from
            && this.props.role.project_duration_from_choice
            && this.props.role.project_duration_to
            && this.props.role.project_duration_to_choice)
            return true;

        return false;
    }

    componentDidMount() {
        this.props.GetMyRoleDetailsStart();
        // if (this.props.role.availability === "F") {
        //     this.setState({days: WorkingDaysArr(this.props.role.availability_days), showCheckRow: "block"});
        // } else {
        //     this.setState({showCheckRow: "none"});
        // }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            if (this.props.role.availability === "F") {
                this.setState({
                    days: WorkingDaysArr(this.props.role.availability_days),
                    showCheckRow: "block",
                    hourly_rate: nextProps.role.hourly_rate,
                    selectedAvailabilityOption: nextProps.role.availability
                });
            } else {
                this.setState({
                    showCheckRow: "none",
                    hourly_rate: nextProps.role.hourly_rate ? nextProps.role.hourly_rate : 25,
                    selectedAvailabilityOption: nextProps.role.availability ? nextProps.role.availability : 'P'
                });
            }
        }
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    handleAvailabilityOptionChange = (e) => {
        if (e.target.value === "F") {
            this.setState({
                showCheckRow: "block",
                selectedAvailabilityOption: e.target.value
            });
        } else {
            this.setState({
                showCheckRow: "none",
                selectedAvailabilityOption: e.target.value
            });
        }
    }

    handleProjectsOptionChange = (e) => {
        if (e.target.value === "custom") {
            this.setState({
                showDurationRow: "flex",
                selectedProjectsOption: e.target.value
            });
        } else {
            this.setState({
                showDurationRow: "none",
                selectedProjectsOption: e.target.value
            });
        }
    }

    checkBoxChange = (e) => {
        var idx = parseInt(e.target.id.substring(1));
        var arr = this.state.days;
        arr[idx] = !arr[idx];
        this.setState({ days: arr });
    }

    finish() {
        // Hourly rate
        // this.props.UpdateRoleStart({
        //     hourly_rate: this.state.hourly_rate,
        // }, false);

        // Availability
        var days = [];
        if (this.state.selectedAvailabilityOption === "F") {
            // add days
            for (let i = 0; i < this.state.days.length; i++) {
                if (this.state.days[i]) {
                    days.push(arrDays[i]);
                }
            }
        }

        this.props.UpdateRoleDetailsStart({
            availability: this.state.selectedAvailabilityOption,
            availability_days: days,
            hourly_rate: this.state.hourly_rate,
            task_remuneration_from: this.state.task_remuneration_from,
            task_remuneration_to: this.state.task_remuneration_to,
            project_duration_from_choice: this.state.fromTime,
            project_duration_to_choice: this.state.toTime,
            project_duration_from: this.state.from,
            project_duration_to: this.state.to
        }, 5);
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <div className="card p-0">
                            <div className="card-head">
                                <FormattedMessage id="setup_profile.work_info" defaultMessage="Work information" />
                            </div>
                            <div className="card-body">
                                <div>

                                    {/* AVAILABILITY */}
                                    <div className="card-body-title">
                                        <FormattedMessage id="setup_profile.availability" defaultMessage="Set up your current availability" />
                                    </div>
                                    <div className="sp-subtitle"></div>
                                    <div className="form-group">
                                        <div className="radio">
                                            <label>
                                                <input type="radio" value="F" className="sp-radio"
                                                    checked={this.state.selectedAvailabilityOption === 'F'}
                                                    onChange={this.handleAvailabilityOptionChange}
                                                />
                                                <FormattedMessage id="setup_profile.full_time" defaultMessage="Full time" />
                                            </label>
                                        </div>
                                        <div className="sp-checkrow" style={{ display: this.state.showCheckRow }}>
                                            <div className="">
                                                <FormattedMessage id="setup_profile.select_days" defaultMessage="Select working days" /> :
                                            </div>
                                            <div className="">
                                                <Form.Check inline label={langService.messages['common.monday']} id="d0" type="checkbox" checked={this.state.days[0]} onChange={this.checkBoxChange} />
                                                <Form.Check inline label={langService.messages['common.tuesday']} id="d1" type="checkbox" checked={this.state.days[1]} onChange={this.checkBoxChange} />
                                                <Form.Check inline label={langService.messages['common.wednesday']} id="d2" type="checkbox" checked={this.state.days[2]} onChange={this.checkBoxChange} />
                                                <Form.Check inline label={langService.messages['common.thursday']} id="d3" type="checkbox" checked={this.state.days[3]} onChange={this.checkBoxChange} />
                                                <Form.Check inline label={langService.messages['common.friday']} id="d4" type="checkbox" checked={this.state.days[4]} onChange={this.checkBoxChange} />
                                                <Form.Check inline label={langService.messages['common.saturday']} id="d5" type="checkbox" checked={this.state.days[5]} onChange={this.checkBoxChange} />
                                                <Form.Check inline label={langService.messages['common.sunday']} id="d6" type="checkbox" checked={this.state.days[6]} onChange={this.checkBoxChange} />
                                            </div>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input type="radio" value="P" className="sp-radio"
                                                    checked={this.state.selectedAvailabilityOption === 'P'}
                                                    onChange={this.handleAvailabilityOptionChange}
                                                />
                                                <FormattedMessage id="setup_profile.part_time" defaultMessage="Part time" />
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input type="radio" value="N" className="sp-radio"
                                                    checked={this.state.selectedAvailabilityOption === 'N'}
                                                    onChange={this.handleAvailabilityOptionChange}
                                                />
                                                <FormattedMessage id="setup_profile.not_available" defaultMessage="Not accepting work" />
                                            </label>
                                        </div>
                                    </div>

                                    {/* HOURLY RATE */}
                                    <div className="card-body-title">
                                        <FormattedMessage id="setup_profile.hourly_rate" defaultMessage="Your hourly rate" />
                                    </div>
                                    <div className="sp-subtitle"></div>
                                    <div className="form-group">
                                        <Form.Control type="number" className="sp-input-number mr-2" onChange={this.handleChange('hourly_rate')} min={1} max={1000} value={this.state.hourly_rate || 0} />
                                    </div>

                                    {/* DURATION */}
                                    <div className="card-body-title"><FormattedMessage id="setup_profile.projects_duration" defaultMessage="Projects duration" /></div>
                                    <div className="sp-subtitle"><FormattedMessage id="setup_profile.interest_projects_duration" defaultMessage="I'm interested in projects with duration" /></div>
                                    <div className="form-group">

                                        <div className="radio">
                                            <label>
                                                <input type="radio" value="all" className="sp-radio"
                                                    checked={this.state.selectedProjectsOption === 'all'}
                                                    onChange={this.handleProjectsOptionChange}
                                                />
                                                <FormattedMessage id="common.all" defaultMessage="All" />
                                            </label>
                                        </div>

                                        <div className="radio">

                                            <label>
                                                <input type="radio" value="custom" className="sp-radio"
                                                    checked={this.state.selectedProjectsOption === 'custom'}
                                                    onChange={this.handleProjectsOptionChange}
                                                />
                                                <FormattedMessage id="common.custom" defaultMessage="Custom" />
                                            </label>

                                            <div className="sp-duration-row" style={{ display: this.state.showDurationRow }}>
                                                <div className="mr-2"><FormattedMessage id="common.from" defaultMessage="From" /></div>
                                                <Form.Control type="number" className="sp-input-number mr-2" onChange={this.handleChange('from')} value={this.state.from} />
                                                <select className="form-control" style={{ width: "120px" }} onChange={this.handleChange('fromTime')} value={this.state.fromTime}>
                                                    <option value="Days">Days</option>
                                                    <option value="Weeks">Weeks</option>
                                                    <option value="Months">Months</option>
                                                </select>
                                                <div className="ml-2 mr-2"><FormattedMessage id="common.to" defaultMessage="To" /></div>
                                                <Form.Control type="number" className="sp-input-number  mr-2" onChange={this.handleChange('to')} value={this.state.to} />
                                                <select className="form-control" style={{ width: "120px" }} onChange={this.handleChange('toTime')} value={this.state.toTime}>
                                                    <option value="Days">Days</option>
                                                    <option value="Weeks">Weeks</option>
                                                    <option value="Months">Months</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>


                                    {/* RENUMERATION */}
                                    <div className="card-body-title"><FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" /></div>
                                    <div className="sp-subtitle"><FormattedMessage id="setup_profile.interest_task_pricing" defaultMessage="I'm interested in tasks with remuneration" /></div>
                                    <div className="form-group">
                                        <div className="sp-renumeration">
                                            <div className="mr-2"><FormattedMessage id="common.from" defaultMessage="From" /></div>
                                            <select className="form-control" style={{ width: "120px" }} onChange={this.handleChange('task_remuneration_from')} value={this.state.task_remuneration_from}>
                                                <option value="50">50</option>
                                                <option value="250">250</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                <option value="5000">5000</option>
                                                <option value="10000">10000</option>
                                                <option value="25000">25000</option>
                                                <option value="50000">50000</option>
                                                <option value="100000">100000</option>
                                            </select>
                                            <div className="ml-2 mr-2"><FormattedMessage id="common.to" defaultMessage="To" /></div>
                                            <select className="form-control" style={{ width: "120px" }} onChange={this.handleChange('task_remuneration_to')} value={this.state.task_remuneration_to}>
                                                <option value="250">250</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                <option value="5000">5000</option>
                                                <option value="10000">10000</option>
                                                <option value="25000">25000</option>
                                                <option value="50000">50000</option>
                                                <option value="100000">100000</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className="setup-step-btns">
                        <div className="btn btn-default btn-developers" onClick={() => history.push('/profile-setup/3')}>
                            <FormattedMessage id="common.back" defaultMessage="Go Back" />
                        </div>
                        <div className="btn btn-default btn-developers" onClick={() => this.finish()}>
                            <FormattedMessage id="common.finish" defaultMessage="Finish" />
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateRoleDetailsStart,
    GetMyRoleDetailsStart
})(FormWorkInformation));

