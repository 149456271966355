import React from 'react';
import './taskProposalContent.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { formatDateRange } from '../../utils/common';
import { Image } from 'react-bootstrap';
import history from '../../utils/routes/history';


class TaskProposalContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderCompetenceChanges(tc, tpc) {
        if (!tc || !tpc) return;
        const merged = tc.concat(tpc).unique();
        return (<div className="user-competences mb-2">
            <div className="old-competences">
                {merged?.filter(x => !tpc.some(y => y.id === x.id)).map((c, i) =>
                    <span onClick={() => history.push('/competency/'+c.id)} key={i}>{c.name} L{c.level}</span>
                )}
            </div>
            <div className='new-competences'>
                <div className="card-tags">
                    {merged.filter(y => tc.find(x => x.id === y.id) && tpc.find(x => x.id === y.id)).map((c, i) =>
                        <span onClick={() => history.push('/competency/'+c.id)} key={i}>{c.name} L{c.level}</span>
                    )}
                </div>
                <div className="card-tags added-competences">
                    {tpc.filter(y => !tc.some(x => x.id === y.id)).map((c, i) =>
                        <span onClick={() => history.push('/competency/'+c.id)} key={i}>{c.name} L{c.level}</span>
                    )}
                </div>
            </div>
        </div >)
    }

    render() {
        const { tp, selectedTask } = this.props;
        return (
            <div className="proposal-item-wrapper">

                <div className="proposal-thumb">
                    {tp.freelancer.profile_image
                        ? <Image src={tp.freelancer.profile_image} className="user-thumb" />
                        : <Image src="/assets/img/default-avatar.png" className="user-thumb" />
                    }
                </div>

                <div className="proposal-item-body">

                    <div className="proposal-item-header">

                        <div className="proposal-item-titles">
                            <div>
                                <span className="form-labels">{tp.freelancer?.name}</span>
                                {tp.freelancer?.rating.rating &&
                                    <div className="p-info-name">
                                        <span className="p-star" role="img" aria-label="Star rating">⭐</span>
                                        <span>
                                            <span className="rating-big">{tp.freelancer?.rating.rating}</span><span className="rating-small">/5</span>
                                        </span>
                                    </div>
                                }   
                            </div>
                            <div>{tp.freelancer?.company_name}</div>
                            <div>{tp.freelancer?.description}</div>
                        </div>
                    </div>

                    <div className="tp-labels mt-3 mb-2">
                        <FormattedMessage id="forms.labels.required_competences" defaultMessage="Required Competences" />
                    </div>

                    <div className="user-competences mb-2">
                        {this.renderCompetenceChanges(selectedTask.competences, tp.competences)}
                    </div>

                    <div className="proposal-content">
                        <div>
                            <div className="proposal-labels">
                                <FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" />
                            </div>
                            {
                                tp?.history?.changes?.some(x => x.field === "price") &&
                                <div className="proposal-prev">{`$` + parseInt(tp.history.old_record.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                            }
                            <div className="proposal-current">{`$` + parseInt(tp.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                        </div>
                        <div style={{ 'minWidth': '238px' }}>
                            <div className="proposal-labels">
                                <FormattedMessage id="browse.task.timeframe" defaultMessage="Timeframe" />
                            </div>
                            {
                                tp?.history?.changes?.some(x => x.field === "start_date" || x.field === 'end_date') &&
                                <div className="proposal-prev">{formatDateRange(new Date(tp.history.old_record.start_date), new Date(tp.history.old_record.end_date))}</div>
                            }
                            <div className="proposal-current-d">{formatDateRange(new Date(tp.start_date), new Date(tp.end_date))}</div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}


export default injectIntl(connect(null, {
})(TaskProposalContent));