import React from 'react'
import _ from 'lodash';
import './index.scss';
import '../browse-styles.scss';
import history from '../../../utils/routes/history';
import InfoBar from '../../../components/Infobar';
import BrowseNavigationControl from '../../../components/BrowseNavigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Jumbotron, Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createLoadingSelector } from '../../../API/selector';
import { GetClientsStart, GetClientsChangePageStart } from '../../../core/actions/role';
import { langService } from './../../../utils/languageService/index';
import { ClientsFilters, ClientsSearchSort } from './ClientsFilters';
import { isEqual } from 'lodash';
import { INITIAL_STATE_FILTERS } from '../../../core/reducers/browse/clientReducer';
import { debounceFreeInputFields } from '../../../utils/common/browse';

class ClientsPage extends React.Component {

    componentDidMount() {
        this.init();
    }

    init = () => {
        const { clients, clientsFilters } = this.props;

        if (clients.length !== 0)
            return;

        this.props.GetClientsStart(clientsFilters);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.clientsFilters === this.props.clientsFilters)
            return;

        if (isEqual(this.props.clientsFilters, INITIAL_STATE_FILTERS)) {
            debounceFreeInputFields.cancel();
            this.props.GetClientsStart(this.props.clientsFilters);
            return;
        }

        if (prevProps.clientsFilters.search !== this.props.clientsFilters.search
            || prevProps.clientsFilters.min_nmb_of_finished_projects !== this.props.clientsFilters.min_nmb_of_finished_projects
            || prevProps.clientsFilters.min_nmb_of_active_projects !== this.props.clientsFilters.min_nmb_of_active_projects) {
                debounceFreeInputFields([this.props.clientsFilters.search, this.props.clientsFilters.min_nmb_of_finished_projects, this.props.clientsFilters.min_nmb_of_active_projects], this.props.GetClientsStart, this.props.clientsFilters);
                return;
            }

        debounceFreeInputFields.cancel();
        this.props.GetClientsStart(this.props.clientsFilters);
    }

    componentWillUnmount() {
        debounceFreeInputFields.cancel();
    }

    openDetails(c) {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;
        if (!isAuthenticated) 
            return history.push("/auth/login");
        if (isAuthenticated && isEmailVerified) 
            return history.push("/user/" + c.id);
        return null;
    }

    renderActions(c) {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;

        if (!isAuthenticated)
            return <button className="btn btn-default btn-employers" onClick={() => history.push('/auth/login')}><FormattedMessage id="projects.logintoview" defaultMessage="Login to view" /></button>

        if (!isEmailVerified)
            return (<button className="btn btn-default btn-employers"><FormattedMessage id="common.verify_email_to_view" defaultMessage="Verify email to view" /></button>)

        return (<button className="btn btn-default btn-employers" onClick={() => history.push("/user/" + c.id)}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>);
    }

    renderClients(clients) {
        if (_.isEmpty(clients)) {
            return <></>
        }
        // const { auth: { isAuthenticated } } = this.props;
        return (
            <>
                {clients.map((c, i) =>
                    <Card className="card-item card-item-clients" key={i}>
                        <Card.Body>

                            <div className="card-thumb">
                                {c.profile_image
                                    ? <Image src={c.profile_image} className="p-thumb" />
                                    : <Image src="/assets/img/default-avatar.png" className="p-thumb" />
                                }
                            </div>

                            <div className="card-details">
                                <Card.Title className="card-title browse-namelink" onClick={() => this.openDetails(c)}>{c.company_name}</Card.Title>
                                <div className="card-desc">
                                    {c.description ? c.description : langService.messages['profile.nodescription']}
                                </div>
                                <div className="card-info">
                                    <span><FormattedMessage id="browse.common.activeprojects" defaultMessage="Active projects" /> <strong>{c.number_of_projects}</strong></span>
                                    <span><FormattedMessage id="browse.common.opentasks" defaultMessage="Open tasks" /> <strong>{c.number_of_tasks}</strong></span>
                                </div>
                            </div>

                            <div className="card-sep"></div>

                            <div className="card-actions">
                                <div className="p-info-name">
                                    {c.rating.rating
                                        ?
                                        <><span className="p-star" role="img" aria-label="Star rating">⭐</span>
                                            <span><span className="rating-big">{c.rating.rating}</span><span className="rating-small">/5</span></span></>
                                        : ''
                                    }
                                </div>

                                {this.renderActions(c)}

                            </div>
                        </Card.Body>
                    </Card>
                )}
            </>
        )
    }

    render() {
        const { clients } = this.props;
        return (
            <>
                <Jumbotron className="jumbo-inv mb-0">
                    <InfoBar />
                    <Container>
                        <h1><FormattedMessage id="clients.heading" defaultMessage="The best lorem ipsum dolor at one place" /></h1>
                    </Container>
                </Jumbotron>
                <Container className="mt-0">
                    <BrowseNavigationControl page="investors" />
                    <Row>
                        <Col md={12} lg={3}>
                            <ClientsFilters />
                        </Col>
                        <Col md={12} lg={9}>

                            <div className="browse-title title-inv">
                                <FormattedMessage id="browse.investors" defaultMessage="Clients" />
                            </div>

                            <ClientsSearchSort />

                            <span className="items-count">
                                <FormattedMessage id="plural.clients" values={{ count: clients.length }}
                                    defaultMessage={`${clients.length} Clients`} />
                            </span>

                            <InfiniteScroll
                                dataLength={clients.length}
                                next={() => setTimeout(() => this.props.nextUrl && this.props.GetClientsChangePageStart(this.props.nextUrl), 500)}
                                hasMore={clients.length !== this.props.count}
                                loader={<div style={{ marginTop: '11px'}}><FormattedMessage id="browse.common.loading" defaultMessage="Loading" />...</div>}
                                endMessage={clients.length > 0 && <div style={{ marginTop: '11px'}}><FormattedMessage id="browse.common.allrecordsloaded" defaultMessage="All records loaded" /></div>}
                            >
                                <div className="card-list-projects">
                                    {this.renderClients(clients)}
                                </div>
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['GET_CLIENTS']);
const mapStateToProps = state => {
    return {
        isLoading: loadingSelector(state),
        auth: state.user.auth,
        isEmailVerified: state.user.info.isEmailVerified,
        clients: state.browse.clients.list.results,
        clientsFilters: state.browse.clients.list.filters,
        nextUrl: state.browse.clients.list.next,
        count: state.browse.clients.list.count
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetClientsStart,
    GetClientsChangePageStart
})(ClientsPage));