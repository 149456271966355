//-------------------
//  EDUCATION EDUCATION actions
//-------------------
export const GET_MY_MILESTONES_REQUEST = "GET_MY_MILESTONES_REQUEST";
export const GET_MY_MILESTONES_SUCCESS = "GET_MY_MILESTONES_SUCCESS";
export const GET_MY_MILESTONES_FAILURE = "GET_MY_MILESTONES_FAILURE";

export const GET_MILESTONES_REQUEST = "GET_MILESTONES_REQUEST";
export const GET_MILESTONES_SUCCESS = "GET_MILESTONES_SUCCESS";
export const GET_MILESTONES_FAILURE = "GET_MILESTONES_FAILURE";

export const SELECT_MY_MILESTONE_REQUEST = "SELECT_MY_MILESTONE_REQUEST";
export const SELECT_MY_MILESTONE_SUCCESS = "SELECT_MY_MILESTONE_SUCCESS";
export const SELECT_MY_MILESTONE_FAILURE = "SELECT_MY_MILESTONE_FAILURE";

export const SELECT_MILESTONE_REQUEST = "SELECT_MILESTONE_REQUEST";
export const SELECT_MILESTONE_SUCCESS = "SELECT_MILESTONE_SUCCESS";
export const SELECT_MILESTONE_FAILURE = "SELECT_MILESTONE_FAILURE";

export const UPDATE_MILESTONE_REQUEST = "UPDATE_MILESTONE_REQUEST";
export const UPDATE_MILESTONE_SUCCESS = "UPDATE_MILESTONE_SUCCESS";
export const UPDATE_MILESTONE_FAILURE = "UPDATE_MILESTONE_FAILURE";

export const DELETE_MILESTONE_REQUEST = "DELETE_MILESTONE_REQUEST";
export const DELETE_MILESTONE_SUCCESS = "DELETE_MILESTONE_SUCCESS";
export const DELETE_MILESTONE_FAILURE = "DELETE_MILESTONE_FAILURE";

export const CREATE_MILESTONE_REQUEST = "CREATE_MILESTONE_REQUEST";
export const CREATE_MILESTONE_SUCCESS = "CREATE_MILESTONE_SUCCESS";
export const CREATE_MILESTONE_FAILURE = "CREATE_MILESTONE_FAILURE";
