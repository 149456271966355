import React from 'react';
import './index.scss';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { ChangeCompetencesFiltersStart } from '../../../core/actions/competences';
import { Select } from '../../../utils/forms/formElements';
import ReactSelect from 'react-select';
import useReactSelect from '../../../utils/hooks/useReactSelect';

const CompetenciesSearchSortComp = props => {
    // react-select filters
    const issuedBy = useReactSelect({ apiRoute: 'issuers/', valueKey: 'owner', valueKeyTwo: 'id', labelKey: 'name' });
    const skills = useReactSelect({ apiRoute: '/competences/', valueKey: 'id', labelKey: 'name' });
    const taskCategories = useReactSelect({ apiRoute: '/project/task/categories/', valueKey: 'id', labelKey: 'name' });

    const { formatMessage } = props.intl;

    const SORT_OPTIONS = [
        { label: formatMessage({ id: 'browse.common.sortby', defaultMessage: 'Sort by' }), value: '' },
        { label: formatMessage({ id: 'browse.order.nameasc', defaultMessage: 'Name ascending' }), value: 'name' },
        { label: formatMessage({ id: 'browse.order.namedesc', defaultMessage: 'Name descending' }), value: '-name' }
    ]


    const handleLevelChange = (type, value) => {
        if (value === '') {
            props.ChangeCompetencesFiltersStart({ [`level_${type}`]: '' });
            return;
        }

        const isNumbersOnly = /^\d+$/.test(value);

        if (!isNumbersOnly)
            return;

        props.ChangeCompetencesFiltersStart({ [`level_${type}`]: value });
    }

    return (
        <div className="browse-search mb-3">
            <Form>
                <Form.Row className="mb-3">
                    <Col md={12} lg={6}>
                        <Form.Control
                            placeholder={formatMessage({ id: 'browse.common.search', defaultMessage: 'Search' })}
                            onChange={e => props.ChangeCompetencesFiltersStart({ search: e.target.value, starts_with: '' })}
                            value={props.competencesFilters.search}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <ReactSelect
                            name="competences-issued-by-select"
                            className="select-issued-by"
                            classNamePrefix="select"
                            placeholder={formatMessage({ id: 'browse.common.select-issuer', defaultMessage: 'Select issuer' })}
                            options={issuedBy.options}
                            onChange={e => props.ChangeCompetencesFiltersStart({ issuers: e ? e.value : null })}
                            inputValue={issuedBy.search}
                            onInputChange={e => issuedBy.setSearchFn(e)}
                            isLoading={issuedBy.loading}
                            isClearable={true}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <Select
                            blurinputonselect="true"
                            name="freelancers-sort-select"
                            style={{ display: 'inline' }}
                            options={SORT_OPTIONS}
                            value={props.competencesFilters.ordering}
                            onChange={e => props.ChangeCompetencesFiltersStart({ ordering: e.target.value })}
                        />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col md={12} lg={2} className="competences-platform-only">
                        <Form.Group controlId="competences-platform-only" className="mb-0">
                            <Form.Check
                                label={formatMessage({ id: 'competences.platform_only', defaultMessage: 'Platform only' })}
                                type="checkbox"
                                checked={props.competencesFilters.platform_only}
                                onChange={() => props.ChangeCompetencesFiltersStart({ platform_only: !props.competencesFilters.platform_only })}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={12} lg={3}>
                        <ReactSelect
                            name="competences-category-select"
                            className="select-issued-by"
                            classNamePrefix="select"
                            placeholder={'Select category'}
                            options={taskCategories.options}
                            onChange={e => props.ChangeCompetencesFiltersStart({ categories: e ? e.value : null })}
                            inputValue={taskCategories.search}
                            onInputChange={e => taskCategories.setSearchFn(e)}
                            isLoading={taskCategories.loading}
                            isClearable={true}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <ReactSelect
                            name="competences-issued-by-select"
                            className="select-issued-by"
                            classNamePrefix="select"
                            placeholder={'Select skills'}
                            options={skills.options}
                            onChange={e => props.ChangeCompetencesFiltersStart({ skills: e ? e.map(c => c.value) : [] })}
                            inputValue={skills.search}
                            onInputChange={e => skills.setSearchFn(e)}
                            isLoading={skills.loading}
                            isClearable={true}
                            isMulti={true}
                        />
                    </Col>
                    <Col md={12} lg={2}>
                        <Form.Control placeholder="Level min" value={props.competencesFilters.level_min} onChange={e => handleLevelChange('min', e.target.value)} />
                    </Col>
                    <Col md={12} lg={2}>
                        <Form.Control placeholder="Level max" value={props.competencesFilters.level_max} onChange={e => handleLevelChange('max', e.target.value)} />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    competencesFilters: state.competence.list.filters,
    competences: state.competence.list.results,
})

const CompetenciesSearchSort = injectIntl(connect(mapStateToProps, {
    ChangeCompetencesFiltersStart
})(CompetenciesSearchSortComp))

export { CompetenciesSearchSort };