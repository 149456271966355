import React from 'react';
import '../../containers/Profile/index.scss';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import ModalEditFreelancerEducation from '../../components/Profile/modalEditFreelancerEducation';
import ModalSendEducationVerificationRequest from '../../components/Profile/modalSendEducationVerificationRequest';
import { GetMyRoleDetailsStart, GetRoleStart } from '../../core/actions/role';


class ProfileEducationSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalFreelancerEducationVisible: false,
            modalVerificationRequestVisible: false,
            selectedEdu: null
        };
    }

    exists(eduId, programId) {
        const { requests } = this.props;
        let res = false;
        requests.forEach(req => {
            if (req.edu === eduId && req.edu_program === programId && req.date_from && (req.status === 1 || req.status === 4)) {
                res = true;
            }
        });
        return res;
    }

    isPending(eduId, programId, applicantEduId, dateFrom, dateTo) {
        const { requests } = this.props;
        let res = false;
        requests.forEach(req => {
            if (req.edu === eduId && req.edu_program === programId && req.status === 1 && (req.applicant_edu_id === applicantEduId || req.date_from === dateFrom || req.date_to === dateTo)) {
                res = true;
            }
        });
        return res;
        // PENDING = 1
        // CANCEL = 2
        // APPROVED = 3
        // REJECTED = 4
    }

    isRejected(eduId, programId) {
        const { requests } = this.props;
        let res = false;
        requests.forEach(req => {
            if (req.edu === eduId && req.edu_program === programId && (req.status === 2 || req.status === 4 )) {
                res = true;
            }
        });
        return res;
        // PENDING = 1
        // CANCEL = 2
        // APPROVED = 3
        // REJECTED = 4
    }

    openVerify(edu) {
        if (edu.edu_school) {
            this.setState({ selectedEdu: edu, modalVerificationRequestVisible: true });
        }
    }

    renderEducationIsVerified(edu) {
        const { requests } = this.props;

        if (edu.verified) {
            return (<div className="is-verified"><div className="icon-educational_institutions"></div><small><FormattedMessage id="common.verified" defaultMessage="Verified" /></small></div>)
        } else {
            if (requests) {
                return (
                    <>
                        {this.isPending(edu.edu_school, edu.edu_program, edu.applicant_edu_id, edu.date_from, edu.date_to) && <div className="is-verified"><div className="icon-educational_institutions"></div><small><FormattedMessage id="common.pending" defaultMessage="Pending" /></small></div> }
                        {this.isRejected(edu.edu_school, edu.edu_program) && <div className="is-verified"><div className="icon-educational_institutions"></div><small><FormattedMessage id="common.rejected" defaultMessage="Rejected" /></small></div> }
                        {(!this.exists(edu.edu_school, edu.edu_program) && edu.edu_school) && <div className="btn btn-projects btn-smallpadding btn-sm" onClick={() => this.openVerify(edu)}><small><FormattedMessage id="common.verify" defaultMessage="Verify" /></small></div> }
                    </>
                    )
            } else {
                return (<div><small>uncomfirmed</small></div>)
            }
        }
        //         {/* {(edu.verified) && <div className="is-verified"><div className="icon-educational_institutions"></div><small><FormattedMessage id="common.verified" defaultMessage="Verified" /></small></div> } */}
        //         {(!edu.verified && this.exists(edu.edu_school, edu.edu_program)) && <div className="is-verified"><div className="icon-educational_institutions"></div><small><FormattedMessage id="common.pending" defaultMessage="Pending" /></small></div> }
        //         {(!edu.verified && !this.exists(edu.edu_school, edu.edu_program) && edu.edu_school) && <div className="btn btn-projects btn-smallpadding btn-sm" onClick={() => this.openVerify(edu)}><small><FormattedMessage id="common.verify" defaultMessage="Verify" /></small></div> }
    }

    render() {
        const { role } = this.props;
        return (
            <>
                <div className="card p-0">
                    <span className="icon-edit" onClick={() => this.setState({ modalFreelancerEducationVisible: true })}></span>
                    <div className="card-head"><FormattedMessage id="profile.education" defaultMessage="Education" /></div>
                    <div className="card-body">
                        {role.educations?.list?.length > 0
                            ?
                            <div className="section-rows">
                                {role.educations.list.map((edu, i) =>
                                    <div className="section-row edu-row" key={i}>
                                        {/* <Image src="/assets/img/default-e.png" className="sec-img"></Image> */}
                                        <div className="sec-info">
                                            <div className="">{edu.school}</div>
                                            <div className="">{edu.program}</div>
                                            <div className="">{edu.date_from && edu.date_from.substring(0, 4)} - {edu.date_to && edu.date_to.substring(0, 4)}</div>
                                        </div>
                                        {this.renderEducationIsVerified(edu)}
                                        <div className="request-verification"></div>
                                    </div>
                                )}
                            </div>
                            :
                            <div className="p-section empty-section">
                                <div className="">
                                    <Image src="/assets/img/svg/education.svg" className="section-img"></Image>
                                </div>
                                <div className="">
                                    <div className="">
                                        <FormattedMessage id="education.add.desc" defaultMessage="Make your profile more credible by adding your education." />
                                    </div>
                                    <div className="add-link" onClick={() => this.setState({ modalFreelancerEducationVisible: true })}>
                                        <FormattedMessage id="education.add" defaultMessage="Add Education" />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    this.state.modalFreelancerEducationVisible &&
                    <ModalEditFreelancerEducation
                        educations={role.educations?.list}
                        visible={this.state.modalFreelancerEducationVisible}
                        onClose={() => this.setState({ modalFreelancerEducationVisible: false })} />
                }
                { this.state.modalVerificationRequestVisible &&
                    <ModalSendEducationVerificationRequest edu={this.state.selectedEdu} visible={this.state.modalVerificationRequestVisible} onClose={() => this.setState({ modalVerificationRequestVisible: false })} />
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        requests: state.user.info.settings.active_role_obj.educations?.requests?.results
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyRoleDetailsStart,
    GetRoleStart
})(ProfileEducationSection));