import React from 'react'
import _, { isEqual } from 'lodash';
import './index.scss';
import '../browse-styles.scss';
import history from '../../../utils/routes/history';
import InfoBar from '../../../components/Infobar';
import BrowseNavigationControl from '../../../components/BrowseNavigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Jumbotron, Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createLoadingSelector } from '../../../API/selector';
import { GetFreelancersStart, GetFreelancersChangePageStart } from '../../../core/actions/role';
import { langService } from './../../../utils/languageService/index';
import { FreelancersFilters, FreelancersSearchSort } from './FreelancersFilters';
import { INITIAL_STATE_FILTERS } from '../../../core/reducers/browse/freelancerReducer';
import { debounceFreeInputFields } from '../../../utils/common/browse';

class FreelancersPage extends React.Component {

    componentDidMount() {
        this.init();
    }

    init = () => {
        const { freelancers, freelancersFilters } = this.props;

        if (freelancers.length !== 0)
            return;

        this.props.GetFreelancersStart(freelancersFilters);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.freelancersFilters === this.props.freelancersFilters)
            return;

        // If reset button is clicked cancel the input debouncer & fetch immediately
        if (isEqual(this.props.freelancersFilters, INITIAL_STATE_FILTERS)) {
            debounceFreeInputFields.cancel();
            this.props.GetFreelancersStart(this.props.freelancersFilters);
            return;
        }

        if (prevProps.freelancersFilters.search !== this.props.freelancersFilters.search
            || prevProps.freelancersFilters.min_nmb_of_finished_tasks !== this.props.freelancersFilters.min_nmb_of_finished_tasks) {
            debounceFreeInputFields([this.props.freelancersFilters.search, this.props.freelancersFilters.min_nmb_of_finished_tasks], this.props.GetFreelancersStart, this.props.freelancersFilters);
            return;
        }

        debounceFreeInputFields.cancel();
        this.props.GetFreelancersStart(this.props.freelancersFilters);
    }

    componentWillUnmount() {
        debounceFreeInputFields.cancel();
    }

    openDetails(c) {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;
        if (!isAuthenticated) 
            return history.push("/auth/login");
        if (isAuthenticated && isEmailVerified) 
            return history.push("/user/" + c.id);
        return null;
    }

    renderActions(c) {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;

        if (!isAuthenticated)
            return <button className="btn btn-default btn-developers" onClick={() => history.push('/auth/login')}><FormattedMessage id="projects.logintoview" defaultMessage="Login to view" /></button>

        if (!isEmailVerified)
            return (<button className="btn btn-default btn-developers"><FormattedMessage id="common.verify_email_to_view" defaultMessage="Verify email to view" /></button>)

        return (<button className="btn btn-default btn-developers" onClick={() => history.push("/user/" + c.id)}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>);
    }

    renderFreelancers(freelancers) {
        if (_.isEmpty(freelancers)) {
            return <></>
        }
        const { auth: { isAuthenticated } } = this.props;
        return (
            <>
                {freelancers.map((c, i) =>
                    <Card className="card-item card-item-developers" key={i}>
                        <Card.Body>

                            <div className="card-thumb">
                                {c.profile_image
                                    ? <Image src={c.profile_image} className="p-thumb" />
                                    : <Image src="/assets/img/default-avatar.png" className="p-thumb" />
                                }
                            </div>

                            <div className="card-details">
                                <Card.Title className="card-title browse-namelink" onClick={() => this.openDetails(c)}>
                                    <span>{c.name}</span>
                                    {c.rating.rating &&
                                        <div style={{ 'display': 'flex', 'marginLeft': '4px' }}>
                                            <span className="p-star" role="img" aria-label="Star rating" style={{ 'marginTop': '-1px' }}>⭐</span>
                                            <span>{c.rating?.rating}/<small>5</small></span>
                                        </div>
                                    }
                                </Card.Title>
                                <div className="card-desc">
                                    {c.description ? c.description : langService.messages['profile.nodescription']}
                                </div>
                                <div className="card-info card-info-dev">
                                    {/* <span><FormattedMessage id="freelancers.studiedat" defaultMessage="Studied at" /> <strong>{c.studied_at ? c.studied_at : 'N/A'}</strong></span> */}
                                    <span><FormattedMessage id="freelancers.completedTasks" defaultMessage="Completed tasks" />: <strong>{c.number_of_finished_tasks ? c.number_of_finished_tasks : 'N/A'}</strong></span>
                                </div>
                                <div className="card-tags">
                                    {c.competences.map((c, x) =>
                                        <span onClick={() => history.push('/competency/' + c.id)} key={x}>{c.name} L{c.level}</span>
                                    )}
                                </div>
                            </div>

                            <div className="card-sep"></div>

                            <div className="card-actions">
                                <div className="p-info-name">
                                    <span></span>
                                    {isAuthenticated &&
                                        <span>
                                            <span className="rating-big">
                                                {c.hourly_rate ? <>${c.hourly_rate}</> : 'N/A'}
                                            </span>
                                            <span className="rating-small">/<FormattedMessage id="freelancers.hour" defaultMessage="Hour" /></span>
                                        </span>
                                    }
                                </div>

                                {this.renderActions(c)}

                            </div>
                        </Card.Body>
                    </Card>
                )}
            </>
        )
    }

    render() {
        const { freelancers } = this.props;
        // const { auth: { isAuthenticated } } = this.props;
        return (
            <>
                <Jumbotron className="jumbo-dev mb-0">
                    <InfoBar />
                    <Container>
                        <h1>
                            <FormattedMessage id="freelancers.heading" defaultMessage="Say Hi to the Best Devs in the World!" />
                        </h1>
                    </Container>
                </Jumbotron>
                <Container className="mt-0">
                    <BrowseNavigationControl page="developers" />
                    <Row>
                        <Col md={12} lg={3}>
                            <FreelancersFilters />
                        </Col>
                        <Col md={12} lg={9}>

                            <div className="browse-title title-dev">
                                <FormattedMessage id="browse.developers" defaultMessage="Developers" />
                            </div>

                            <FreelancersSearchSort />

                            <span className="items-count">
                                <FormattedMessage id="plural.freelancers" values={{ count: freelancers.length }}
                                    defaultMessage={`${freelancers.length} Freelancers`} />
                            </span>

                            <InfiniteScroll
                                dataLength={freelancers.length}
                                next={() => setTimeout(() => this.props.nextUrl && this.props.GetFreelancersChangePageStart(this.props.nextUrl), 500)}
                                hasMore={freelancers.length !== this.props.count}
                                loader={<div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.loading" defaultMessage="Loading" />...</div>}
                                endMessage={freelancers.length > 0 && <div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.allrecordsloaded" defaultMessage="All records loaded" /></div>}
                            >
                                <div className="card-list-projects">
                                    {this.renderFreelancers(freelancers)}
                                </div>
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['GET_FREELANCERS']);
const mapStateToProps = state => {
    return {
        isLoading: loadingSelector(state),
        freelancers: state.browse.freelancers.list.results,
        freelancersFilters: state.browse.freelancers.list.filters,
        auth: state.user.auth,
        isEmailVerified: state.user.info.isEmailVerified,
        nextUrl: state.browse.freelancers.list.next,
        count: state.browse.freelancers.list.count
    }
}
export default injectIntl(connect(mapStateToProps, {
    GetFreelancersStart,
    GetFreelancersChangePageStart
})(FreelancersPage));