import React from 'react';
import './index.scss';
import history from './../../utils/routes/history';
import { connect } from 'react-redux';
import { Tabs, Tab, Card } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { langService } from './../../utils/languageService/index';
import FormatedDuration from './../../components/Common/FormatedDuration';

class ParticipatedInProjects extends React.Component {

    taskCompleted(s) {
        const is = ['COMPLETED', 'REJECTED', 'FAILED', 'AUDITOR_FAILED', 'AUDITOR_CONFIRMED'].includes(s);
        return is;
    }

    renderProjectItem(p, i) {
        return (
            <Card className="card-item profile-project-item" key={i}>
                <Card.Body>
                    <div className="card-thumb">
                        {
                            p.logo_image ?
                                <img src={p.logo_image} className="p-thumb" alt="Project thumb" />
                                :
                                <img src="/assets/img/default-project.png" className="p-thumb" alt="Project thumb" />
                        }
                    </div>
                    <div className="card-details">
                        <Card.Title className="card-title">{p.name}</Card.Title>
                        <div className="card-desc">
                            {p.short_description}
                        </div>
                        <div className="card-info">
                            <span><FormattedMessage id="projects.tasks" defaultMessage="Tasks" /> <strong>{p.number_of_tasks}</strong></span>
                            <span><FormattedMessage id="projects.duration" defaultMessage="Duration" /> <strong>{p.visibility && p.visibility === "PRIVATE" ? 'N/A' :
                                <FormatedDuration start_date={p.estimated_start} end_date={p.estimated_end} />}
                            </strong></span>
                        </div>
                        <div className="card-tags">
                            <span className="no-hover">{p.category.name}</span>
                        </div>
                    </div>
                    <div className="card-sep"></div>
                    <div className="card-actions">
                        <span>{p.estimated_budget ? `$` + parseInt(p.estimated_budget).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : p.estimated_budget} </span>
                        <button className="btn btn-default btn-projects" onClick={() => { history.push("/projects/" + p.id) }}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    renderProjects() {
        const { role } = this.props;
        return (
            <div className="card p-0" >
                <div className="card-head">
                    <FormattedMessage id="nav.labels.published_projects" defaultMessage="Published projects" />
                    {/* {role.type === "AUD" && <FormattedMessage id="auditors.dispute_cases" defaultMessage="Dispute Cases" />} */}
                </div>
                <div className="card-body">
                    <Tabs defaultActiveKey="1" className="">
                        <Tab eventKey="1" title={role.participated_in_projects ? `${langService.messages['common.open']} (` + role.participated_in_projects.filter(x => x.status === "PUBLISHED" || x.status === "ACTIVE").length + ")" : langService.messages['common.open']}>
                            <div className="tab-content" style={{ "marginTop": "15px" }}>
                                {role.participated_in_projects &&
                                    role.participated_in_projects.map((p, i) =>
                                        (p.status === "PUBLISHED" || p.status === "ACTIVE") && this.renderProjectItem(p, i)
                                    )
                                }
                            </div>
                        </Tab>
                        <Tab eventKey="2" title={role.participated_in_projects ? `${langService.messages['common.completed']} (` + role.participated_in_projects.filter(x => x.status === "COMPLETED" || x.status === "REJECTED").length + ")" : langService.messages['common.completed']}>
                            <div className="tab-content">
                                {role.participated_in_projects &&
                                    role.participated_in_projects.map((p, x) =>
                                        (p.status === "COMPLETED" || p.status === "REJECTED") && this.renderProjectItem(p, x)
                                    )
                                }
                            </div>
                        </Tab>
                        {/* <Tab eventKey="3" title={role.participated_in_projects ? `${langService.messages['common.rejected']} (` + role.participated_in_projects.filter(x => x.status === "REJECTED").length + ")" : langService.messages['common.rejected']}>
                            <div className="tab-content">
                                {role.participated_in_projects?.map((p, x) =>
                                    p.status === "REJECTED" && this.renderProjectItem(p, x)
                                )}
                            </div>
                        </Tab> */}
                    </Tabs>
                </div>
            </div>
        )
    }

    renderTaskItem(t, i) {
        return (
            <Card className="card-item profile-project-item" key={i}>
                <Card.Body>
                    <div className="card-thumb">
                        {
                            t.logo_image ?
                                <img src={t.logo_image} className="p-thumb" alt="Project thumb" />
                                :
                                <img src="/assets/img/default-task.png" className="p-thumb" alt="Project thumb" />
                        }
                    </div>
                    <div className="card-details">
                        <Card.Title className="card-title">{t.name}</Card.Title>
                        <div className="card-desc">
                            {t.short_description}
                        </div>
                        <div className="card-info">
                            <span><FormattedMessage id="projects.duration" defaultMessage="Duration" /> <strong>{t.visibility && t.visibility === "PRIVATE" ? 'N/A' :
                                <FormatedDuration start_date={t.start_date} end_date={t.end_date} />}
                            </strong>
                            </span>
                        </div>
                        <div className="card-info">
                            <span><FormattedMessage id="forms.labels.category" defaultMessage="Category" /> <strong>{t.category.name}</strong></span>
                        </div>
                        <div className="card-info">
                            <FormattedMessage id="projects.competences" defaultMessage="Competencies" />
                        </div>
                        <div className="card-tags">
                            {t.competences.map((c, x) =>
                                <span onClick={() => history.push('/competency/' + c.id)} key={x}>{c.name} L{c.level}</span>
                            )}
                        </div>
                    </div>
                    <div className="card-sep"></div>
                    <div className="card-actions">
                        <span>{t.price ? `$` + parseInt(t.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 }) : t.price} </span>
                        <button className="btn btn-default btn-projects" onClick={() => { history.push("/project/" + t.project + '/task/' + t.id) }}><FormattedMessage id="browse.common.view" defaultMessage="View" /></button>
                    </div>
                </Card.Body>
            </Card>
        )
    }

    renderTasks() {
        const { role } = this.props;
        return (
            <div className="card p-0" >
                <div className="card-head">
                    <FormattedMessage id="profile.participatedintasks" defaultMessage="Participated in tasks" />
                </div>
                <div className="card-body">
                    <Tabs defaultActiveKey="1" className="">
                        <Tab eventKey="1" title={role.participated_in_tasks ? `${langService.messages['common.open']} (` + role.participated_in_tasks.filter(x => !this.taskCompleted(x.status)).length + ")" : langService.messages['common.open']}>
                            <div className="tab-content" style={{ "marginTop": "15px" }}>
                                {role.participated_in_tasks &&
                                    role.participated_in_tasks.map((t, i) =>
                                        !this.taskCompleted(t.status) && this.renderTaskItem(t, i)
                                    )
                                }
                            </div>
                        </Tab>
                        <Tab eventKey="2" title={role.participated_in_tasks ? `${langService.messages['common.completed']} (` + role.participated_in_tasks.filter(x => this.taskCompleted(x.status)).length + ")" : langService.messages['common.completed']}>
                            <div className="tab-content">
                                {role.participated_in_tasks &&
                                    role.participated_in_tasks.map((t, x) =>
                                        this.taskCompleted(t.status) && this.renderTaskItem(t, x)
                                    )
                                }
                            </div>
                        </Tab>
                        {/* <Tab eventKey="3" title={role.participated_in_tasks ? `${langService.messages['common.rejected']} (` + role.participated_in_tasks.filter(x => x.status === "REJECTED").length + ")" : langService.messages['common.rejected']}>
                            <div className="tab-content">
                                {role.participated_in_tasks?.map((t, x) =>
                                    t.status === "REJECTED" && this.renderTaskItem(t, x)
                                )}
                            </div>
                        </Tab> */}
                    </Tabs>
                </div>
            </div>
        )
    }

    render() {
        const { role } = this.props;
        if (role.type === 'DEV') {
            return this.renderTasks();
        } else if (role.type === 'INV') {
            return this.renderProjects();
        } else {
            return null;
        }
    }
}


export default injectIntl(connect(null, {})(ParticipatedInProjects));