import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import moment from 'moment';
import history from '../../utils/routes/history';
import ContactList from '../../components/Chat/contactList';
import ModalSubmitWork from './modalSubmitWork';
import ModalAcceptTaskProposal from '../../components/Tasks/modalAcceptTaskProposal';
import ModalFileViewer from '../../components/Common/ModalFileViewer/modalFileViewer';
import ModalReviewUser from '../../components/Tasks/modalReviewUser';
import ModalReviewAuditor from '../../components/Tasks/modalReviewAuditor';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { UpdateTaskProposalStart } from '../../core/actions/taskProposals';
import { formatDateRange } from '../../utils/common';
import { Tabs, Tab } from 'react-bootstrap';
import { getFileExtension, getFileName, contains } from '../../utils/common';
import { langService } from './../../utils/languageService/index';
import { GetMyReviewRequestsStart, GetMyReviewRequestsForTaskStart } from '../../core/actions/rating';
import { SelectTaskDisputeSettlement } from '../../core/actions/taskDisputeSettlement';
import ModalSubmitTaskProposal from '../../components/Tasks/modalSubmitTaskProposal';
import TabAuditInfo from '../../components/Audit/tabAuditInfo';
import TabDisputeSettlement from '../../components/Audit/tabDisputeSettlement';
import TabProjectOverview from './tabProjectOverview';
import ReactTooltip from 'react-tooltip';


class MyWorkSelectedTask extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            btnCounter: langService.messages['common.counter'],
            proposalEdit: false,
            focusedInput: null,
            activeTabKey: this.props.tabKey ?? "2",
            startDate: props.selectedTaskProposal ? moment(props.selectedTaskProposal.start_date) : null,
            endDate: props.selectedTaskProposal ? moment(props.selectedTaskProposal.end_date) : null,
            tpPrice: props.selectedTaskProposal ? props.selectedTaskProposal.price : 0,
            modalSubmitWorkVisible: false,
            modalAcceptVisible: false,
            modalFileViewerVisible: false,
            modalProposalVisible: false,
            selectedDocument: null,
            modalReviewUserVisible: false,
            modalReviewAuditorVisible: false
        };
        this.handleTabChange = this.handleTabChange.bind(this);
        this.hideModalSubmitProposal = this.hideModalSubmitProposal.bind(this);
    }

    componentDidMount() {
        this.props.GetMyReviewRequestsStart();

        if (this.props.selectedTask.exists_dispute_settlement) {
            this.props.SelectTaskDisputeSettlement(this.props.selectedTask.id, false);
        }

        if (this.props.selectedTask.status === 'AUDITOR_CONFIRMED' || this.props.selectedTask.status === 'AUDITOR_REJECTED') {
            this.props.GetMyReviewRequestsForTaskStart(null, null, this.props.selectedTask.id);
        }

        if (this.props.tabKey) {
            this.setState({ activeTabKey: this.props.tabKey });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.selectedTaskProposal) {
            this.setState({
                startDate: nextProps.selectedTaskProposal.start_date ? moment(nextProps.selectedTaskProposal.start_date) : null,
                endDate: nextProps.selectedTaskProposal.end_date ? moment(nextProps.selectedTaskProposal.end_date) : null,
                tpPrice: nextProps.selectedTaskProposal.price ? nextProps.selectedTaskProposal.price : 0
            })
        }

        if (nextProps.selectedTask.number_of_results) {
            if (nextProps.selectedTask.number_of_results === 0) {
                this.switchTabs(1);
            }
        } else {
            this.switchTabs(1);
        }
    }

    hasHistory(status) {
        const exclStatuses = ['DRAFT', 'PUBLISHED', 'IN_NEGOTIATION'];
        return exclStatuses.indexOf(status) === -1;
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    handleTabChange(selectedkey) {
        this.setState({ activeTabKey: selectedkey });
    };

    reOffer() {
        this.setState({ modalProposalVisible: true });
    }

    cancelReOffer() {
        this.setState({ btnCounter: langService.messages['common.counter'], proposalEdit: false });
    }

    onDatesChange(startDate, endDate) {
        this.setState({ startDate, endDate });
    }

    sendAction(a) {
        const { selectedTaskProposal } = this.props;
        switch (a) {
            default: {
                this.props.UpdateTaskProposalStart({
                    id: selectedTaskProposal.id,
                    action_type: a,
                    last_task_proposal_change: selectedTaskProposal.last_task_proposal_change,
                });
                break;
            }
        }
    }

    hideModalSubmitWork() {
        this.setState({ modalSubmitWorkVisible: false });
    }

    showModalSubmitWork(final) {
        this.setState({ modalSubmitWorkVisible: true, final: final });
    }

    hideModalSubmitProposal() {
        this.setState({ modalProposalVisible: false });
    }

    // RENDERs

    // Modal Submit Work
    renderModal() {
        if (this.state.modalSubmitWorkVisible) {
            return (
                <ModalSubmitWork visible={this.state.modalSubmitWorkVisible} final={this.state.final} onClose={() => this.hideModalSubmitWork()} />
            )
        }
    }

    hide() {
        this.setState({ modalAcceptVisible: false });
    }

    accept() {
        this.setState({ modalAcceptVisible: false });
        this.sendAction("ACCEPT");
    }

    // Modal File Viewer
    openFileViewer(file) {
        this.setState({ selectedDocument: file, modalFileViewerVisible: true });
    }
    renderModalFileViewer() {
        if (this.state.modalFileViewerVisible) {
            return (
                <ModalFileViewer visible={this.state.modalFileViewerVisible} file={this.state.selectedDocument} onClose={() => this.hideModalFileViewer()} />
            )
        }
    }
    hideModalFileViewer() {
        this.setState({ modalFileViewerVisible: false });
    }

    switchTabs = (key) => {
        this.setState({ activeKey: key });
    }

    renderAcceptModal(tp, selectedTask) {
        if (this.state.modalAcceptVisible) {
            return (
                <ModalAcceptTaskProposal visible={this.state.modalAcceptVisible} tp={tp} selectedTask={selectedTask} onClose={() => this.hide()} onAccept={() => this.accept()} />
            )
        }
    }

    renderModalSubmitTaskProposal() {
        const { role, selectedTask, selectedTaskProposal } = this.props;

        if (selectedTask.id) {
            return (
                <ModalSubmitTaskProposal
                    visible={this.state.modalProposalVisible}
                    taskId={selectedTask.id}
                    clientId={selectedTask.project.client.id}
                    start={selectedTaskProposal.start_date ? selectedTaskProposal.start_date : selectedTask.start_date}
                    end={selectedTaskProposal.end_date ? selectedTaskProposal.end_date : selectedTask.end_date}
                    price={selectedTaskProposal.price ? selectedTaskProposal.price : selectedTask.price}
                    invite={false}
                    freelancerId={role.id}
                    competences={selectedTaskProposal.competences ? selectedTaskProposal.competences : selectedTask.competences}
                    action="counter"
                    existingProposal={selectedTaskProposal}
                    onClose={() => this.hideModalSubmitProposal()}
                />);
        } else {
            return null;
        }
    }

    renderCompetenceChanges(tc, tpc) {
        if (!tc || !tpc) return;
        const merged = tc.concat(tpc).unique();
        return (<div className="user-competences mb-2">
            <div className="old-competences">
                {merged?.filter(x => !tpc.some(y => y.id === x.id)).map((c, i) =>
                    <span onClick={() => history.push('/competency/' + c.id)} key={i}>{c.name} L{c.level}</span>
                )}
            </div>
            <div className='new-competences'>
                <div className="card-tags">
                    {merged.filter(y => tc.find(x => x.id === y.id) && tpc.find(x => x.id === y.id)).map((c, i) =>
                        <span onClick={() => history.push('/competency/' + c.id)} key={i}>{c.name} L{c.level}</span>
                    )}
                </div>
                <div className="card-tags added-competences">
                    {tpc.filter(y => !tc.some(x => x.id === y.id)).map((c, i) =>
                        <span onClick={() => history.push('/competency/' + c.id)} key={i}>{c.name} L{c.level}</span>
                    )}
                </div>
            </div>
        </div >)
    }

    renderTaskOverview() {
        const { selectedTask, selectedTaskProposal } = this.props;
        if (!selectedTask.id) { return };
        return (

            <div className="task-overview-body p-3">

                <div className="form-labels">
                    <FormattedMessage id="browse.task.about" defaultMessage="About" />
                </div>

                <div className="mt-2 mb-2">
                    <FormattedMessage id="roles.name.INV" defaultMessage="Client" />:
                    <div className="name-link" onClick={() => history.push("/user/" + selectedTask.project.client.id)}> {selectedTask.project.client.name}</div>
                </div>

                <div className="mt-2 mb-3">
                    <FormattedMessage id="common.project" defaultMessage="Project" />:
                    <div className="name-link" onClick={() => history.push("/projects/" + selectedTask.project.id)}> {selectedTask.project.name}</div>
                </div>

                <div className="form-labels">
                    <FormattedMessage id="forms.labels.short_description" defaultMessage="Short description" />
                </div>
                <p>{selectedTask.short_description}</p>

                <div className="form-labels">
                    <FormattedMessage id="forms.labels.description" defaultMessage="Description" />
                </div>
                <p>{selectedTask.description}</p>

                <div className="form-labels">
                    <FormattedMessage id="plural.files.attached" values={{ count: selectedTask?.documents?.length }}
                        defaultMessage={`${selectedTask?.documents?.length} Files Attached`} />
                </div>
                {selectedTask?.documents?.map((doc, i) =>
                    <div className="attachment-box" key={i}>
                        <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? getFileExtension(doc.document) : 'generic'}`}></div>
                        {/* <div className="download-link" onClick={() => this.downloadFile(doc.document)} alt="project attachment">{getFileName(doc.document)}</div> */}
                        <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                    </div>
                )}
                {selectedTask?.documents?.length === 0 && <div className="mt-3 mb-3"></div>}


                {selectedTask.status === "IN_NEGOTIATION" &&
                    <>
                        <div className="form-labels mt-4">
                            <FormattedMessage id="browse.task.negotiation" defaultMessage="Negotiations in progress" />
                        </div>
                        <hr />
                    </>
                }

                <div className="form-labels">
                    {selectedTask.status === "IN_NEGOTIATION"
                        ? <FormattedMessage id="forms.labels.required_competences" defaultMessage="Required Competences" />
                        : <FormattedMessage id="projects.competences" defaultMessage="Competences" />
                    }
                </div>
                {selectedTask.status === "IN_NEGOTIATION"
                    ?
                    this.renderCompetenceChanges(selectedTask?.competences, selectedTaskProposal?.competences)
                    :
                    <div className="card-tags mb-2">
                        {selectedTask.competences.map((c, j) =>
                            <span onClick={() => history.push('/competency/' + c.id)} key={j}>{c.name} L{c.level}</span>
                        )}
                    </div>
                }

                <div className="proposal-content mb-4">

                    {selectedTaskProposal
                        ?
                        <>
                            <div style={{ "marginRight": "20px" }}>
                                <div className="form-labels">
                                    <FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" />
                                </div>
                                {
                                    selectedTaskProposal?.history?.changes?.some(x => x.field === "price") &&
                                    <div className="proposal-prev">{`$` + parseInt(selectedTaskProposal?.history?.old_record?.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                                }
                                <div className="proposal-current">{`$` + parseInt(selectedTaskProposal?.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                            </div>
                            <div>
                                <div className="form-labels">
                                    <FormattedMessage id="browse.task.timeframe" defaultMessage="Timeframe" />
                                </div>
                                {
                                    selectedTaskProposal?.history?.changes?.some(x => x.field === "start_date" || x.field === 'end_date') &&
                                    <div className="proposal-prev">{formatDateRange(new Date(selectedTaskProposal?.history?.old_record?.start_date), new Date(selectedTaskProposal?.history?.old_record?.end_date))}</div>
                                }
                                {
                                    selectedTaskProposal?.start_date &&
                                    <div className="proposal-current">{formatDateRange(new Date(selectedTaskProposal?.start_date), new Date(selectedTaskProposal?.end_date))}</div>
                                }
                            </div>
                        </>
                        : <>
                            <div style={{ "marginRight": "20px" }}>
                                <div className="form-labels">
                                    <FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" />
                                </div>
                                <div className="proposal-current">{`$` + parseInt(selectedTask?.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</div>
                            </div>
                            <div>
                                <div className="form-labels">
                                    <FormattedMessage id="browse.task.timeframe" defaultMessage="Timeframe" />
                                </div>
                                <div className="proposal-current">{formatDateRange(new Date(selectedTask?.start_date), new Date(selectedTask?.end_date))}</div>
                            </div>
                        </>
                    }

                </div>

                {
                    selectedTask.recurring &&
                    <div className="mb-4">
                        <div className="form-labels">
                            <FormattedMessage id="common.reccurrance" defaultMessage="Task recurrance" />
                        </div>
                        <div className="proposal-current">
                            {selectedTask.recurring_type === 0 && langService.messages['common.interval']}
                            {selectedTask.recurring_type === 1 && langService.messages['common.weekly'] + " X " + selectedTask.recurring_amount}
                            {selectedTask.recurring_type === 2 && langService.messages['common.monthly'] + " X " + selectedTask.recurring_amount}
                        </div>
                    </div>
                }

                {
                    this.state.proposalEdit &&
                    <>
                        <div className="proposal-content mt-3 mb-0">
                            <div className="form-labels">
                                <FormattedMessage id="my_task.new_offer" defaultMessage="New offer" />
                            </div>
                        </div>

                        <div className="proposal-content">
                            <div style={{ "marginRight": "20px", "width": "135px" }}>
                                <input className="form-control" type="number" name="tpPrice" min="1" value={this.state.tpPrice} onChange={this.handleChange('tpPrice')}></input>
                            </div>
                            <div>
                                <DateRangePicker
                                    startDate={this.state.startDate}
                                    startDateId="tpStartDate"
                                    endDate={this.state.endDate}
                                    endDateId="tpEndDate"
                                    onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    endDatePlaceholderText={"End date"}
                                    startDatePlaceholderText={"Start date"}
                                    displayFormat={"DD-MM-YYYY"}
                                />
                            </div>
                        </div>
                    </>
                }

                <div className="card-actions">
                    {
                        selectedTaskProposal &&
                        selectedTaskProposal.available_actions.map((a, i) => {
                            if (a === "COUNTER") {
                                return <button key={i} onClick={() => this.reOffer()}
                                    className={this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding mr-2"}>{this.state.btnCounter}</button>
                            } else if (a === "ACCEPT") {
                                return <button key={i} onClick={() => this.setState({ modalAcceptVisible: true })} className={this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding"}>{langService.messages['common.accept']}</button>
                            } else {
                                return <button key={i} onClick={() => this.sendAction(a)}
                                    className={this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding mr-2"}>{langService.messages['common.reject']}</button>
                            }
                        })
                    }
                    {this.renderAcceptModal(selectedTaskProposal, selectedTask)}
                    <button onClick={() => this.sendAction("COUNTER")}
                        className={!this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding mr-2"}>
                        <FormattedMessage id="my_task.send_offer" defaultMessage="Send offer" />
                    </button>
                    <button onClick={() => this.cancelReOffer()}
                        className={!this.state.proposalEdit ? "hidden" : "btn btn-default btn-projects btn-smallpadding mr-2"}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </button>
                </div>

            </div >
        )
    }

    renderSubmittedWork() {
        const { selectedTask } = this.props;
        if (selectedTask.number_of_results === 0) { return; }
        return (
            <Tab eventKey="3" title={langService.messages['my_projects.submited_work'] + (selectedTask.number_of_results > 0 ? ` (${selectedTask.number_of_results})` : "")}>
                {selectedTask.results.map((result, i) =>
                    <div className="result-item" key={i}>
                        <div className="result-info">
                            <div className="result-date">{moment(result.date_created).format('DD.MM.YYYY HH:mm')}</div>
                            <div className="result-comment"> {result.name}</div>
                        </div>
                        <div className="result-docs">
                            <div className="attachments">
                                {result?.documents?.map((doc, x) =>
                                    <div className="attachment-box" key={x}>
                                        <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png', 'JPG', 'PNG'], getFileExtension(doc.document)) ? 'generic' : getFileExtension(doc.document)}`}></div>
                                        {/* <div className="download-link" onClick={() => this.downloadFile(doc.document)}>{getFileName(doc.document)}</div> */}
                                        <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Tab>
        )
    }

    renderModalReviewUser(task) {
        if (this.state.modalReviewUserVisible) {
            return (
                <ModalReviewUser visible={this.state.modalReviewUserVisible} type={'client'} user={task.project.client} task={task} onClose={() => this.setState({ modalReviewUserVisible: false })} />
            )
        }
    }

    renderModalReviewAuditor(task) {
        if (this.state.modalReviewAuditorVisible) {
            return (
                <ModalReviewAuditor visible={this.state.modalReviewAuditorVisible} task={task} onClose={() => this.setState({ modalReviewAuditorVisible: false })} />
            )
        }
    }

    toolTipMessage(message, n) {
        return (
            <>
                <div className="info-icon-wrap ml-1">
                    <img src="/assets/img/icon/info.png" data-tip data-for={ "task" + n } alt="info-icon" width="13"></img>
                </div>
                <ReactTooltip id={ "task" + n } place="right" type="dark" effect="solid">
                    <div className="tooltip-txt-my-work">
                        {message}
                    </div>
                </ReactTooltip>
            </>
        )
    }

    render() {
        const { selectedTask, reviewRequests, auditorReviewRequests, settlement } = this.props;
        if (!selectedTask.id) { return null };
        return (

            <div className="project-overview">

                <div className="project-overview-cover">
                    <Image src={selectedTask.project.cover_image ? selectedTask.project.cover_image : "/assets/img/floriane-vita.png"} height="120" className="project-overview-coverimg"></Image>
                    <div className="btn btn-default btn-trans btn-changecover" style={{ "right": "18px" }} onClick={() => history.push("/projects/" + selectedTask.project.id)}>
                        <FormattedMessage id="my_projects.view" defaultMessage="View Project" />
                    </div>
                </div>

                <div className="project-overview-header">

                    <div className="t-overview-titles">
                        <div className="t-overview-title">
                            <span>{selectedTask.name}</span>
                            <span>{selectedTask.category?.name}</span>
                            <div>
                                <div className="tooltip-parent">
                                    <div className="tooltip-title">
                                    <div><span className={"status-circle status-color-" + selectedTask.status.toLowerCase()}>●</span>
                                    <label className="form-label"><FormattedMessage id={`common.${selectedTask.status.toLowerCase()}`} defaultMessage={selectedTask.status.toLowerCase()} /></label></div>
                                        {this.toolTipMessage(langService.messages['my_task.tooltip.status'], 1)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="submitwork-btns">

                            {(selectedTask.status === "ACTIVE" || selectedTask.status === "IN_PROGRESS" || selectedTask.status === 'SUBMITTED') &&
                                <button className="btn btn-default btn-projects" onClick={() => this.showModalSubmitWork(false)} >
                                    <FormattedMessage id="my_task.upload" defaultMessage="Upload documents" />
                                </button>

                            }
                            {(selectedTask.status === "ACTIVE" || selectedTask.status === "IN_PROGRESS") &&
                                <button className="btn btn-default btn-projects btn-create-project mt-2" onClick={() => this.showModalSubmitWork(true)} >
                                    <FormattedMessage id="my_task.complete" defaultMessage="Complete task" />
                                </button>
                            }
                            {reviewRequests.some(x => x.reviewee.id === selectedTask.project.client?.id)
                                && selectedTask.status && selectedTask.status === "COMPLETED" &&
                                <>
                                    <button className="btn btn-default btn-projects btn-create-project mt-2" onClick={() => this.setState({ modalReviewUserVisible: true })} >
                                        <FormattedMessage id="rating.review_inv" defaultMessage="Review Client" />
                                    </button>
                                    {this.renderModalReviewUser(selectedTask)}
                                </>
                            }
                            {auditorReviewRequests.length > 0 && selectedTask.status
                                && (selectedTask.status === "AUDITOR_CONFIRMED" || selectedTask.status === "AUDITOR_REJECTED") &&
                                <>
                                    <button className="btn btn-default btn-projects btn-create-project mt-2" onClick={() => this.setState({ modalReviewAuditorVisible: true })} >
                                        <FormattedMessage id="rating.review_aud" defaultMessage="Review Auditor" />
                                    </button>
                                    {this.renderModalReviewAuditor(selectedTask)}
                                </>
                            }
                        </div>
                    </div>

                    {this.renderModal()}
                </div>

                <div className="task-overview-body">

                    <Tabs defaultActiveKey={1} activeKey={this.state.activeTabKey} className="p-overview-tabs" onSelect={(x) => this.handleTabChange(x)}>

                        <Tab eventKey="1" title={langService.messages['my_projects.project_overview']}>
                            <div className="tab-content">
                                <TabProjectOverview projectId={selectedTask.project.id} />
                            </div>
                        </Tab>

                        <Tab eventKey="2" title={langService.messages['my_task.task_overview']}>
                            <div className="tab-content">
                                {this.renderTaskOverview()}
                                {this.state.modalProposalVisible && this.renderModalSubmitTaskProposal()}
                            </div>
                        </Tab>

                        {this.renderSubmittedWork()}

                        {/* selectedTask.status === 'IN_DISPUTE' && */}
                        {selectedTask.exists_dispute_settlement &&
                            <Tab eventKey="5" title={langService.messages['auditors.dispute']}>
                                <TabDisputeSettlement settlement={this.props.settlement}/>
                            </Tab>
                        }

                        {this.hasHistory(selectedTask.status) &&
                            <Tab eventKey="4" title={langService.messages['common.history']}>
                                <TabAuditInfo />
                            </Tab>
                        }

                    </Tabs>
                </div>

                {this.renderModalFileViewer()}
                <ContactList />

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        selectedTaskProposal: state.myWork.myTaskProposals.list.results[0],
        reviewRequests: state.myWork.myRatingRequests.list.results,
        auditorReviewRequests: state.myWork.myRatingRequestsForTask.list.results,
        settlement: state.myWork.myDisputeSettlements.selected
    }
}

export default injectIntl(connect(mapStateToProps, {
    UpdateTaskProposalStart,
    GetMyReviewRequestsStart,
    GetMyReviewRequestsForTaskStart,
    SelectTaskDisputeSettlement
})(MyWorkSelectedTask));