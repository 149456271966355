import React from 'react';
import './payments.scss';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

class SettingsPayments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            area: ''
        };
    }

    render() {

        return (
            <div className="card p-0">
                <div className="card-head">
                    Payment settings
                </div>
                <div className="card-body">
                    <p>Payment area</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
}

export default injectIntl(connect(mapStateToProps, {

})(SettingsPayments));