import React from 'react';
import { useDrop } from 'react-dnd';
import { UpdateTaskStart } from '../../core/actions/tasks'
import { connect } from 'react-redux';

const NoMilestoneArea = props => {
  // Destruct props
  const { milestoneId, children } = props;

  const [{ isOver, isOverCurrent }, drop] = useDrop({
    accept: 'task',
    drop(item, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop)
        return;

      // dropFn(item.id, milestone)
      props.UpdateTaskStart({
        id: item.id,
        milestone: milestoneId
      }, false);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  // Sets background color based on if the area is hovered while dragging item or not - can be used this later as style,..
  let backgroundColor = 'transparent';
  if (isOverCurrent || isOver) {
    backgroundColor = 'rgba(0, 141, 206, 0.4)';
  }

  return (
    <div
      ref={drop}
      className="tasklist"
      style={{ height: '100%', width: '100%', backgroundColor: backgroundColor }}
    >
      <div>{children.length === 0 
        ? <div className="milestone-empty">Default milestone container. Drag & Drop to rearrange tasks.</div> 
        : children}
      </div>
    </div>
  )
}

export default connect(null, {
  UpdateTaskStart
})(NoMilestoneArea);