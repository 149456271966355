import { GET_COMPETENCES_SUCCESS, SELECT_COMPETENCE_SUCCESS, DESELECT_COMPETENCE, CREATE_COMPETENCE_SUCCESS, UPDATE_COMPETENCE_SUCCESS, VERIFY_COMPETENCE_SUCCESS, VALIDATE_COMPETENCE_SUCCESS } from '../../actions/competences/types'
import { GET_COMPETENCE_DESCRIPTIONS_SUCCESS, VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS, DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS, CREATE_COMPETENCE_DESCRIPTION_SUCCESS } from '../../actions/competenceDescription/types'
import { GET_COMPETENCE_PARENTS_SUCCESS, CREATE_COMPETENCE_PARENT_SUCCESS, VOTE_FOR_COMPETENCE_PARENT_SUCCESS, DELETE_VOTE_FOR_COMPETENCE_PARENT_SUCCESS } from '../../actions/competenceParent/types'
import { GET_COMPETENCE_SKILLS_SUCCESS, CREATE_COMPETENCE_SKILL_SUCCESS, VOTE_FOR_COMPETENCE_SKILL_SUCCESS, DELETE_VOTE_FOR_COMPETENCE_SKILL_SUCCESS } from '../../actions/competenceSkill/types'
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import competenceDescriptionReducer from './competenceDescriptionReducer'
import competenceSkillReducer from './competenceSkillReducer'
import competenceParentReducer from './competenceParentReducer'
import { CHANGE_COMPETENCES_FILTERS } from './../../actions/competences/types';

export const INITIAL_STATE_FILTERS = {
    limit: 10,
    search: '',
    starts_with: '',
    ordering: '',
    issuers: null,
    platform_only: false,
    skills: [],
    level_min: '',
    level_max: '',
    categories: null
}

const INITIAL_STATE = {
    selected: {
        id: null,
        name: '',
        description: '',
        already_exists: false,
        calculated_id: '',
        competence_descriptions: competenceDescriptionReducer,
        competence_skills: competenceSkillReducer,
        competence_parents: competenceParentReducer,
        task_categories: [],
        warnings: []
    },
    list: {
        filters: INITIAL_STATE_FILTERS,
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_COMPETENCES_FILTERS:
            return {
                ...state, list: {
                    ...state.list,
                    filters: { ...state.list.filters, ...action.payload.filters }
                }
            };
        case VERIFY_COMPETENCE_SUCCESS:
            return { ...state, selected: { ...state.selected, ...action.payload.selected } };

        case VALIDATE_COMPETENCE_SUCCESS:
            return { ...state, selected: { ...state.selected, warnings: action.payload.warnings } };            

        case GET_COMPETENCES_SUCCESS:
            // return { ...state, list: action.payload.list };
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? [...state.list.results, ...action.payload.list.results] : action.payload.list.results
                }
            };

        case SELECT_COMPETENCE_SUCCESS:
            return { ...state, selected: { ...state.selected, ...action.payload.selected } };
        case DESELECT_COMPETENCE:
            return { ...state, selected: INITIAL_STATE.selected };
        case CREATE_COMPETENCE_SUCCESS:
            return {
                ...state, selected: { ...state.selected, ...action.payload.selected },
                list: { ...state.list, results: state?.list?.results?.concat(action.payload.selected) }
            };
        case UPDATE_COMPETENCE_SUCCESS:
            return {
                ...state, selected: action.payload.selected.id === state.selected.id ? { ...state.selected, ...action.payload.selected } : state.selected,
                list: {
                    ...state.list, results: state.list.results.map(x =>
                        action.payload.selected.id === x.id ? { ...x, ...action.payload.selected } : x
                    )
                }
            };
        //DESCRIPTIONS
        case GET_COMPETENCE_DESCRIPTIONS_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_descriptions: competenceDescriptionReducer(state.selected.competence_descriptions, action),
                }
            };
        case CREATE_COMPETENCE_DESCRIPTION_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_descriptions: competenceDescriptionReducer(state.selected.competence_descriptions, action),
                }
            };
        case VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_descriptions: competenceDescriptionReducer(state.selected.competence_descriptions, action),
                }
            };
        case DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_descriptions: competenceDescriptionReducer(state.selected.competence_descriptions, action),
                }
            };
        //PARENTS
        case GET_COMPETENCE_PARENTS_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_parents: competenceParentReducer(state.selected.competence_parents, action),
                }
            };
        case CREATE_COMPETENCE_PARENT_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_parents: competenceParentReducer(state.selected.competence_parents, action),
                }
            };
        case VOTE_FOR_COMPETENCE_PARENT_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_parents: competenceParentReducer(state.selected.competence_parents, action),
                }
            };
        case DELETE_VOTE_FOR_COMPETENCE_PARENT_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_parents: competenceParentReducer(state.selected.competence_parents, action),
                }
            };
        //SKILLS
        case GET_COMPETENCE_SKILLS_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_skills: competenceSkillReducer(state.selected.competence_skills, action),
                }
            };
        case CREATE_COMPETENCE_SKILL_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_skills: competenceSkillReducer(state.selected.competence_skills, action),
                }
            };
        case VOTE_FOR_COMPETENCE_SKILL_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_skills: competenceSkillReducer(state.selected.competence_skills, action),
                }
            };
        case DELETE_VOTE_FOR_COMPETENCE_SKILL_SUCCESS:
            return {
                ...state, selected: {
                    ...state.selected,
                    competence_skills: competenceSkillReducer(state.selected.competence_skills, action),
                }
            };
        //RESTART
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}