import { GET_MILESTONES_SUCCESS, CREATE_MILESTONE_SUCCESS, UPDATE_MILESTONE_SUCCESS, SELECT_MILESTONE_SUCCESS, DELETE_MILESTONE_SUCCESS } from './../../../actions/milestone/types';
const INITIAL_STATE = {
    list: [],
    selected: {
        id: null,
        tasks: [],
        name: '',
        short_description: '',
        description: '',
        color: null,
        project: null
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MILESTONES_SUCCESS:
            return { ...state, list: action.payload.list }
        // case CREATE_MILESTONE_SUCCESS:
        //     return {
        //         ...state, selected: INITIAL_STATE.selected,
        //         list: state.list.concat(action.payload.milestone)
        //     }
        // case UPDATE_MILESTONE_SUCCESS:
        //     return {
        //         ...state, selected: INITIAL_STATE.selected,
        //         list: state.list.map(milestone =>
        //             milestone.id === action.payload.milestone ?
        //                 action.payload.milestone : milestone)
        //     }
        // case DELETE_MILESTONE_SUCCESS:
        //     return {
        //         ...state, selected: INITIAL_STATE.selected,
        //         list: state.list.filter((milestone) => milestone.id !== action.payload.deleted)
        //     }
        case SELECT_MILESTONE_SUCCESS:
            return { ...state, selected: action.payload.selected };
        default:
            return state;
    }
}