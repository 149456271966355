import { combineReducers } from "redux";
import projectsReducer from './projectsReducer'
import freelancerReducer from './freelancerReducer';
import clientReducer from './clientReducer';
import auditorReducer from './auditorReducer';
import eduReducer from './eduReducer';
import profileReducer from './profileReducer'
import tasksReducer from "./tasksReducer";

export default combineReducers({
    profile: profileReducer,
    projects: projectsReducer,
    tasks: tasksReducer,
    clients: clientReducer,
    auditors: auditorReducer,
    freelancers: freelancerReducer,
    edus: eduReducer
})