/* SOURCE */
/* https://www.csscodelab.com/react-js-input-double-range-slider */
import React from 'react';
import './DualRangeSlider.scss';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

class DualRangeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderWidth: this.props.sliderWidth,
      min: this.props.min,
      max: this.props.max,
      minValueBetween: this.props.minValueBetween,
      currentMin: this.props.currentMin,
      inputMin: this.props.inputMin,
      currentMax: this.props.currentMax,
      inputMax: this.props.inputMax,
    };
  }

  offset = () => {
    const rect = this.slider.getBoundingClientRect();
    return { left: rect.left }
  }

  componentDidMount() {
    const { currentMin, currentMax, max } = this.state;

    this.minValue.style.width = (currentMin * 100) / max + "%";
    this.maxValue.style.width = (currentMax * 100) / max + "%";
  }

  setMin = (e) => {
    const { min, max, currentMax, minValueBetween } = this.state;
    const inputMin = e.target.value;

    this.setState({
      inputMin: this.currentMin,
    });

    if ((inputMin >= min) && (inputMin <= (currentMax - minValueBetween))) {
      this.setState({
        currentMin: parseInt(inputMin)
      });

      this.minValue.style.width = (inputMin * 100) / max + "%";
    }
  }

  changeMinValue = (e) => {
    e.preventDefault();

    document.addEventListener('mousemove', this.onMouseMoveMin);
    document.addEventListener('mouseup', this.onMouseUpMin);

    document.addEventListener('touchmove', this.onMouseMoveMin);
    document.addEventListener('touchend', this.onMouseUpMin);

    var divOffset = this.offset();
    var levoOffset = divOffset.left;
    this.setState({
      offsetSliderWidht: parseInt(levoOffset),
    })
  }

  onMouseMoveMin = (e) => {
    const { min, max, currentMax, minValueBetween, sliderWidth, offsetSliderWidht } = this.state;

    const dragedWidht = e.clientX - offsetSliderWidht;
    const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
    const currentMin = Math.abs(parseInt((max * dragedWidhtInPercent) / 100));

    if ((currentMin >= min) && (currentMin <= (currentMax - minValueBetween)) && (e.pageX > offsetSliderWidht)) {
      this.minValue.style.width = dragedWidhtInPercent + "%";
      this.minValue.dataset.content = currentMin;

      this.setState({
        currentMin,
        inputMin: currentMin
      })
    }
  }

  onMouseUpMin = () => {
    document.removeEventListener('mouseup', this.onMouseUpMin);
    document.removeEventListener('mousemove', this.onMouseMoveMin);

    document.removeEventListener('touchend', this.onMouseMoveMin);
    document.removeEventListener('touchmove', this.onMouseUpMin);
  }


  setMax = e => {
    const { max, currentMin, minValueBetween } = this.state;

    const inputMax = e.target.value;

    this.setState({
      inputMax: this.currentMax,
    });

    if ((inputMax >= currentMin + minValueBetween) && (inputMax <= max)) {

      this.setState({
        currentMax: parseInt(inputMax)
      });
      this.maxValue.style.width = (inputMax * 100) / max + "%";
    }
  }

  changeMaxValue = (e) => {
    e.preventDefault();

    document.addEventListener('mousemove', this.onMouseMoveMax);
    document.addEventListener('mouseup', this.onMouseUpMax);

    document.addEventListener('touchmove', this.onMouseMoveMax);
    document.addEventListener('touchend', this.onMouseUpMax);

    var divOffset = this.offset();
    var levoOffset = divOffset.left;

    this.setState({
      offsetSliderWidht: parseInt(levoOffset),
      currentMax: e.target.value
    })
  }

  onMouseMoveMax = (e) => {
    const { max, currentMin, minValueBetween, sliderWidth, offsetSliderWidht } = this.state;
    const maxWalueThumb = this.maxValue;
    const dragedWidht = e.clientX - offsetSliderWidht;
    const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
    const currentMax = Math.abs(parseInt((max * dragedWidhtInPercent) / 100));

    if ((currentMax >= (currentMin + minValueBetween)) && (currentMax <= max)) {

      maxWalueThumb.style.width = dragedWidhtInPercent + "%";
      maxWalueThumb.dataset.content = currentMax;
      this.setState({
        currentMax,
        inputMax: currentMax
      })
    }
  }

  onMouseUpMax = () => {
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('mousemove', this.onMouseMoveMax);

    document.removeEventListener('touchend', this.onMouseUp);
    document.removeEventListener('touchmove', this.onMouseMoveMax);
  }

  render() {
    const { currentMin, inputMin, currentMax, inputMax } = this.state;
    return (
      <div className="card-range">
        <div ref={ref => this.slider = ref} id="slider">

          <div ref={ref => this.minValue = ref} id="min" data-content={currentMin}>
            <div ref={ref => this.minValueDrag = ref} id="min-drag" onChange={this.props.onCallBackMin(currentMin)} onMouseDown={this.changeMinValue} onTouchStart={this.changeMinValue}></div>
          </div>

          <div ref={ref => this.maxValue = ref} id="max" data-content={currentMax}>
            <div ref={ref => this.maxValueDrag = ref} id="max-drag" onChange={this.props.onCallBackMax(currentMax)} onMouseDown={this.changeMaxValue} onTouchStart={this.changeMaxValue}></div>
          </div>

        </div>

        <div className="values">
          <div className="mr-2"><FormattedMessage id="common.from" defaultMessage="From" /></div>
          <div className="ml-2 mr-2"><FormattedMessage id="common.to" defaultMessage="To" /></div>
        </div>
        <div className="form-group">
          <div className="sp-renumeration current-value">
            {/* <div className="mr-2"><FormattedMessage id="common.from" defaultMessage="From" /></div> */}
            <div className="form-group bracket">
              <Form.Control
                id="min-input"
                type="number"
                onChange={this.setMin}
                value={inputMin}
              />
            </div>
            {/*  <div className="ml-2 mr-2"><FormattedMessage id="common.to" defaultMessage="To" /></div> */}
            <div className="form-group bracket">
              <Form.Control
                id="max-input"
                type="number"
                onChange={this.setMax}
                value={inputMax}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DualRangeSlider