import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import './modalSettlementAddComment.scss';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import AutosizeTextarea from '../Common/AutosizeTextarea';
import { CreateDisputeSettlementTopicCommentStart } from '../../core/actions/taskDisputeSettlementTopicComment';

const customStyles = {
    width: 'inherit',
    height: 'auto',
    maxWidth: '540px',
    // maxHeight: '200px',
    bottom: 'auto',
    top: '25%'
};

class ModalSettlementAddComment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            isValid: '',
            minLeft: 10
        };
    }

    hide() {
        this.props.onClose();
    }

    confirm() {
        this.props.CreateDisputeSettlementTopicCommentStart(this.props.topic.id, this.state.comment).then(res => {
            this.setState({comment: ''});
            this.props.onClose();
        });
    }

    textAreaValueChanged(input, event) {
        if (event.target.value.length > 9) {
            this.setState({ [input]: event.target.value, isValid: '', minLeft: 10 - event.target.value.length });
        } else {
            this.setState({ [input]: event.target.value, isValid: 'is-invalid', minLeft: 10 - event.target.value.length });
        }
    }

    render() {
        const { visible, topic } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div>
                    <div className="modal-title">
                        {topic?.topic}
                    </div>
                    <div className="modal-body p-2 pt-4" style={{ "textAlign": "center" }}>
                        
                        <div className="mb-5">
                            <AutosizeTextarea 
                                isValid={this.state.isValid}
                                value={this.state.comment}
                                rows={4} input="comment"
                                edit={false}
                                placeholder="Add your comment about this..."
                                handleChange={(input, event) => this.textAreaValueChanged(input, event)}
                            />
                            {this.state.isValid !== '' && <small className="form-field-error mt-1 mb-3">Minimum 10 characters ({this.state.minLeft} left)</small>}
                        </div>

                        <div className="modal-submit-buttons mt-5">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.cancel" defaultMessage={`Cancel`} />
                            </div>
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.confirm()}>
                                <FormattedMessage id="auditor.add_comment" defaultMessage="ADD COMMENT" />
                            </button>
                        </div>
                    </div>
                </div>
            </Rodal>
        )
    }
}

export default injectIntl(connect(null, {
    CreateDisputeSettlementTopicCommentStart
})(ModalSettlementAddComment));