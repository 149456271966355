import experienceReducer from './experienceReducer'
import educationReducer from './educationReducer'
import {
    GET_PROFILE_COMPLETION_SUCCESS, GET_USER_SUCCESS, REGISTER_SUCCESS, LOGIN_SUCCESS
} from '../../../../actions/auth/types'
import {
    CHANGE_ACTIVE_ROLE_SUCCESS, CREATE_ROLE_SUCCESS, UPDATE_ROLE_SUCCESS,
    UPLOAD_ROLE_COVER_PICTURE_SUCCESS, UPLOAD_ROLE_PROFILE_PICTURE_SUCCESS, UPDATE_ROLE_DETAILS_SUCCESS, GET_MY_ROLE_DETAIL_SUCCESS, GET_ROLE_SUCCESS
} from '../../../../actions/role/types'
import { DELETE_EDUCATION_SUCCESS, UPDATE_EDUCATION_SUCCESS, CREATE_EDUCATION_SUCCESS, SELECT_EDUCATION_SUCCESS, GET_MYVERIFICATIONREQUESTS_SUCCESS } from '../../../../actions/education/types'
import { DELETE_EXPERIENCE_SUCCESS, UPDATE_EXPERIENCE_SUCCESS, CREATE_EXPERIENCE_SUCCESS, SELECT_EXPERIENCE_SUCCESS } from '../../../../actions/experience/types'
import { GET_MY_RATING_SUCCESS, GET_MY_REVIEWS_SUCCESS } from '../../../../actions/rating/types'


const INITIAL_STATE = {
    active_role: null,
    active_role_obj: {
        id: null,
        name: '',
        first_name: '',
        last_name: '',
        description: '',
        type: '',
        company_name: '',
        address_line_1: '',
        address_line_2: '',
        post_code: '',
        city: '',
        state: '',
        country: '',
        language: '',
        vat: '',
        company_reg_no: '',
        is_company: null,
        profile_image: null,
        cover_image: null,
        profile_completion: {},
        educations: educationReducer,
        experiences: experienceReducer,
        competences: [],
        details: {},
        rating: {},
        reviews: {
            count: 0,
            next: null,
            previous: null,
            results: []
        }
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_SUCCESS:
            return { ...state, ...action.payload.user.settings }
        case REGISTER_SUCCESS:
            return { ...state, ...action.payload.user.settings }
        case LOGIN_SUCCESS:
            return { ...state, ...action.payload.user.settings }
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...state, ...action.payload.settings }
        case GET_ROLE_SUCCESS:
            return {
                ...state, active_role: action.payload.role.id,
                active_role_obj: {
                    ...state.active_role_obj, ...action.payload.role,
                }
            }
        case UPDATE_ROLE_SUCCESS:
            return {
                ...state, active_role: action.payload.role.id, active_role_obj: {
                    ...state.active_role_obj, ...action.payload.role,
                }
            }
        case CREATE_ROLE_SUCCESS:
            return {
                ...state, active_role: action.payload.role.id, active_role_obj: {
                    ...state.active_role_obj, ...action.payload.role,
                }
            }
        case UPLOAD_ROLE_COVER_PICTURE_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj, ...action.payload.role,
                }
            }
        case UPLOAD_ROLE_PROFILE_PICTURE_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj, ...action.payload.role,
                }
            }
        case UPDATE_ROLE_DETAILS_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj, ...action.payload.details,
                    educations: educationReducer(state.active_role_obj.educations, action),
                    experiences: experienceReducer(state.active_role_obj.experiences, action)
                }
            }
        case GET_MY_ROLE_DETAIL_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj, ...action.payload.details,
                    educations: educationReducer(state.active_role_obj.educations, action),
                    experiences: experienceReducer(state.active_role_obj.experiences, action)
                }
            }
        case GET_PROFILE_COMPLETION_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj, profile_completion: action.payload.completion
                }
            }
        //Rating
        case GET_MY_RATING_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj, rating: action.payload.selected
                }
            }
        case GET_MY_REVIEWS_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj, reviews: action.payload.list
                }
            }
        //Experience
        case CREATE_EXPERIENCE_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    experiences: experienceReducer(state.active_role_obj.experiences, action)
                }
            }
        case UPDATE_EXPERIENCE_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    experiences: experienceReducer(state.active_role_obj.experiences, action)
                }
            }
        case DELETE_EXPERIENCE_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    experiences: experienceReducer(state.active_role_obj.experiences, action)
                }
            }
        case SELECT_EXPERIENCE_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    experiences: experienceReducer(state.active_role_obj.experiences, action)
                }
            }
        //Education
        case CREATE_EDUCATION_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    educations: educationReducer(state.active_role_obj.educations, action),
                }
            }
        case UPDATE_EDUCATION_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    educations: educationReducer(state.active_role_obj.educations, action),
                }
            }
        case DELETE_EDUCATION_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    educations: educationReducer(state.active_role_obj.educations, action),
                }
            }
        case SELECT_EDUCATION_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    educations: educationReducer(state.active_role_obj.educations, action),
                }
            }
        case GET_MYVERIFICATIONREQUESTS_SUCCESS:
            return {
                ...state, active_role_obj: {
                    ...state.active_role_obj,
                    educations: educationReducer(state.active_role_obj.educations, action),
                }
            }
        default:
            return state;
    }
}