import React from 'react';
import autosize from "autosize";
import { Form } from 'react-bootstrap';


class AutosizeTextarea extends React.Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    componentDidMount() {
        if (this.textareaAutosize)
            this.textareaAutosize.style.height = 'auto';
    }

    handleChange = input => event => {    
        const { edit } = this.props;
        this.textareaAutosize.style.height = 'auto';
        this.textareaAutosize.style.height = this.textareaAutosize.scrollHeight + 'px';
        !edit && this.props.handleChange(input, event);
        edit && this.props.setParentValue(event.target.value);
    }
    

    render() {
        const { value, rows, input } = this.props;

        return (
            <Form.Control 
                as="textarea" 
                rows={rows} 
                value={value} 
                onChange={this.handleChange(input)} 
                ref={ref => this.textareaAutosize = ref} 
                className={this.props.isValid}
                placeholder={this.props.placeholder}
                required 
            />
        )
    }
}

export default AutosizeTextarea;