import React from 'react';
import './index.scss';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ResendActivationEmailStart } from './../../core/actions/auth/index';


class InfoBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderActivateEmailBar() {
        return (
            <div className="infobar">
                <div className="container infobar-wrapper">
                    <div className="infobar-icon">
                        <Image src="/assets/img/icon/email-white.png"></Image>
                    </div>

                    <div className="infobar-content">
                        <div>
                            <FormattedMessage id="browse.project.verify_email_short" defaultMessage={`Verify email`} />
                        </div>
                        <div>
                            <FormattedMessage id="browse.project.verify_email" defaultMessage="Please verify your email to start working with the platform." />
                        </div>
                    </div>

                    <div className="infobar-actions">
                        <div className="btn btn-default btn-infobar" onClick={() => this.props.ResendActivationEmailStart()} >
                            <FormattedMessage id="browse.project.re-send_email" defaultMessage={`Re-send activation email`} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSetupProfileBar() {
        if (this.props.completed > 70) return null;
        const { role } = this.props;
        return (
            <div className="infobar">
                <div className="container infobar-wrapper">
                    <div className="infobar-icon2">
                        <Image src="/assets/img/svg/complete_your_profile.svg"></Image>
                    </div>

                    <div className="infobar-content">
                        {role.type === "DEV" &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.get_hired" defaultMessage={`Get hired today!`} />
                                </div>
                                <div>
                                    <FormattedMessage id="infobar.complete_profile" defaultMessage={`Complete your profile and increase your chances of getting hired.`} />
                                </div>
                            </>
                        }
                        {role.type === "INV" &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.start_hiring" defaultMessage={`Start hiring today!`} />
                                </div>
                                <div>
                                    <FormattedMessage id="infobar.complete_profile_2" defaultMessage={`Please complete your profile before you start hiring.`} />
                                </div>
                            </>
                        }
                        {(role.type === "EDU" || role.type === "EAS" || role.type === "EAP" || role.type === "EAC") &&
                            <>
                                <div>
                                    <FormattedMessage id="infobar.start_edu" defaultMessage={`Complete profile!`} />
                                </div>
                                <div>
                                    <FormattedMessage id="infobar.edu_complete" defaultMessage={`Please complete your profile.`} />
                                </div>
                            </>
                        }
                    </div>

                    <div className="infobar-actions">
                        <div className="btn btn-default btn-infobar" onClick={() => history.push('/profile-setup/1')} >
                            <FormattedMessage id="infobar.setup_profile" defaultMessage={`Profile setup.`} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderRegisterRole() {
        return (
            <div className="infobar">
                <div className="container infobar-wrapper">
                    <div className="infobar-icon2">
                        <Image src="/assets/img/svg/complete_your_profile.svg"></Image>
                    </div>

                    <div className="infobar-content">
                        <div>
                            <FormattedMessage id="roles.no_active_role_yet" defaultMessage="No active role yet?" />
                        </div>
                        <div>
                            <FormattedMessage id="roles.please_register_role" defaultMessage="Please register a role, that you will be using on ECTA." />
                        </div>
                    </div>

                    <div className="infobar-actions">
                        <div className="btn btn-default btn-infobar" onClick={() => history.push('/auth/register-role')} >
                            <FormattedMessage id="forms.registerRoleForm" defaultMessage="Register role" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { user, auth: { isAuthenticated }, hasRole } = this.props;
        if (!isAuthenticated) return null;

        if (!user.isEmailVerified) {
            return this.renderActivateEmailBar();
        } else {
            if (hasRole) {
                return this.renderSetupProfileBar();
            } else {
                return this.renderRegisterRole();
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        auth: state.user.auth,
        user: state.user.info,
        role: state.user.info.settings.active_role_obj,
        hasRole: state.user.info.settings.active_role,
        completed: state.user.info?.settings?.active_role_obj?.profile_completion?.quality,
    }
}

export default connect(mapStateToProps, { ResendActivationEmailStart })(InfoBar);