import React from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { Image } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';


class ProfileReviews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            this.props.reviews?.count > 0 ?
                <div className="card p-0">
                    <div className="card-head"><FormattedMessage id="profile.reviewsupper" defaultMessage="Reviews" /><span className=""> ({this.props.reviews?.count})</span></div>
                    <div className="card-body">
                        <div className="section-rows">
                            {this.props.reviews?.results.map((r, i) =>
                                <div className="section-row" key={i}>
                                    <Image src="/assets/img/default-review.png" className="sec-img"></Image>
                                    <div className="sec-info">
                                        <div>{r.reviewer.name}</div>
                                        <div>{r.comment}</div>
                                    </div>
                                    <div className="p-info-name">
                                        <span className="p-star" role="img" aria-label="Star rating">⭐</span>
                                        <span>
                                            <span className="rating-big">{r.rating}</span><span className="rating-small">/5</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div> : <></>
        )
    }
}


export default injectIntl(connect(null, {
})(ProfileReviews));