import React, { useEffect, useState } from 'react';
import '../../browse-styles.scss';
import '../index.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { GetCompetencesStart } from '../../../../core/actions/competences';
import { WithOutContext as ReactTags } from 'react-tag-input';
import { ChangeAuditorFiltersStart } from '../../../../core/actions/role';
import { Select } from '../../../../utils/forms/formElements';

const AuditorsFiltersComp = props => {
    const { formatMessage } = props.intl;

    const [competenceTags, setCompetenceTags] = useState([]);
    const [competenceSuggestions, setCompetenceSuggestions] = useState([]);

    useEffect(() => {
        if (props.competences.length === 0)
            props.GetCompetencesStart({ limit: 100 });
    }, [])

    useEffect(() => {
        if (props.competences.length !== 0)
            setCompetenceSuggestions(props.competences.map(c => ({ id: c.id, text: `${c.name} L${c.level}` })))
    }, [props.competences])

    useEffect(() => {
        const tempCompetenceTags = [];

        competenceSuggestions.forEach(cs => {
            if (props.auditorsFilters.competences.includes(cs.id))
                tempCompetenceTags.push(cs);
        });

        setCompetenceTags(tempCompetenceTags);
    }, [props.auditorsFilters.competences])

    const handleAvailabilityChange = value => {
        const { auditorsFilters } = props;

        if (auditorsFilters.availability.includes(value))
            props.ChangeAuditorFiltersStart({ availability: auditorsFilters.availability.filter(a => a !== value) });
        else
            props.ChangeAuditorFiltersStart({ availability: [...auditorsFilters.availability, value] });
    }

    const handleCompetenceAddition = e => {
        const found = competenceSuggestions.find(t => t === e);

        if (found) {
            setCompetenceTags([...competenceTags, e]);
            props.ChangeAuditorFiltersStart({ competences: [...props.auditorsFilters.competences, e.id] });
        }
    }

    const handleCompetencesDelete = i => {
        setCompetenceTags(competenceTags.filter((tag, index) => index !== i));

        const filteredTagIds = [];
        competenceTags.forEach((tag, index) => {
            if (index !== i)
                filteredTagIds.push(tag.id);
        });

        // This prevents settings state of competences to empty array if competences are already empty array & user is holding backspace
        if (props.auditorsFilters.competences.length === 0 && filteredTagIds.length === 0)
            return;

        props.ChangeAuditorFiltersStart({ competences: filteredTagIds });
    }

    return (
        <div className="filters-wrapper">

            <div className="filter-title-aud">
                <h6><FormattedMessage id="browse.common.commingsoon" defaultMessage="Coming soon" /></h6>
                {/* <h6><FormattedMessage id="profile.availability" defaultMessage="Availability" /></h6>
                <Form.Group controlId="freelancers-availability-full-time">
                    <Form.Check
                        type="checkbox"
                        checked={props.auditorsFilters.availability.includes('F')}
                        onChange={() => handleAvailabilityChange('F')}
                        label={formatMessage({ id: 'setup_profile.full_time', defaultMessage: 'Full-time' })}
                    />
                </Form.Group>
                <Form.Group controlId="freelancers-availability-part-time">
                    <Form.Check
                        type="checkbox"
                        checked={props.auditorsFilters.availability.includes('P')}
                        onChange={() => handleAvailabilityChange('P')}
                        label={formatMessage({ id: 'setup_profile.part_time', defaultMessage: 'Part-time' })}
                    />
                </Form.Group> */}
            </div>

            {/* <div className="filter-title-aud" style={{ marginBottom: '13px' }}>
                <h6><FormattedMessage id="projects.competences" defaultMessage="Competences" /></h6>
                <ReactTags
                    style={{ width: '100%' }}
                    tags={competenceTags}
                    suggestions={competenceSuggestions}
                    placeholder={formatMessage({ id: 'forms.placeholders.addcompetence', defaultMessage: 'Add competence' })}
                    handleAddition={e => handleCompetenceAddition(e)}
                    handleDelete={i => handleCompetencesDelete(i)}
                />
            </div> */}

            <div>
                <button style={{ width: '100%' }} onClick={() => props.ChangeAuditorFiltersStart()} className="btn btn-default btn-auditors">
                    <FormattedMessage id="forms.reset_filters" defaultMessage="Reset filters" />
                </button>
            </div>

        </div>
    );
}

const AuditorsSearchSortComp = props => {
    const { formatMessage } = props.intl;

    const SORT_OPTIONS = [
        { label: formatMessage({ id: 'browse.common.sortby', defaultMessage: 'Sort by' }), value: '' }
    ]

    return (
        <div className="browse-search">
            <Form>
                <Form.Row>
                    <Col md={12} lg={9}>
                        <Form.Control
                            placeholder={formatMessage({ id: 'browse.common.search', defaultMessage: 'Search' })}
                            onChange={e => props.ChangeAuditorFiltersStart({ search: e.target.value })}
                            value={props.auditorsFilters.search}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <Select
                            blurinputonselect="true"
                            name="auditors-sort-select"
                            style={{ display: 'inline' }}
                            options={SORT_OPTIONS}
                            value={props.auditorsFilters.ordering}
                            onChange={e => props.ChangeAuditorFiltersStart({ ordering: e.target.value })}
                        />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    auditorsFilters: state.browse.auditors.list.filters,
    competences: state.competence.list.results,
    isAuthenticated: state.user.auth.isAuthenticated
})

const AuditorsFilters = injectIntl(connect(mapStateToProps, {
    GetCompetencesStart,
    ChangeAuditorFiltersStart
})(AuditorsFiltersComp))

const AuditorsSearchSort = injectIntl(connect(mapStateToProps, {
    ChangeAuditorFiltersStart
})(AuditorsSearchSortComp))

export { AuditorsFilters, AuditorsSearchSort };