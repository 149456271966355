import React from 'react';
import './index.scss';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import SettingsAccount from '../../components/Settings/account';
import SettingsNotifications from '../../components/Settings/notifications';
import SettingsPayments from '../../components/Settings/payments';
import SettingsSecurity from '../../components/Settings/security';
import { LogoutStart } from './../../core/actions/auth/index';


class SettingsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            area: ''
        };
    }

    componentDidMount() {
        const param = this.props.match.params;
        if (param.area) {
            this.setState({ area: param.area })
        } else {
            history.push('/settings/account');
        }
    }

    render() {
        return (
            <>
                <Container className="mt-4">
                    <Row>

                        <Col md={12} lg={3}>

                            <div className="card">
                                <div className="side-sett-item">
                                    <span className="icon-settings side-sett-itemicon"></span>
                                    <span className={this.state.area === 'account' ? "sidelink sk-active" : "sidelink"} onClick={() => history.push('/settings/account')}>
                                        <FormattedMessage id="settings.account" defaultMessage="Account" />
                                    </span>
                                </div>
                                <div className="side-sett-item">
                                    <span className="icon-investor side-sett-itemicon"></span>
                                    <span className={this.state.area === 'payments' ? "sidelink sk-active" : "sidelink"} onClick={() => history.push('/settings/payments')}>
                                        <FormattedMessage id="settings.payment" defaultMessage="Payments" />
                                    </span>
                                </div>
                                <div className="side-sett-item">
                                    <span className="icon-nitifications side-sett-itemicon"></span>
                                    <span className={this.state.area === 'notifications' ? "sidelink sk-active" : "sidelink"} onClick={() => history.push('/settings/notifications')}>
                                        <FormattedMessage id="settings.notification" defaultMessage="Notifications" />
                                    </span>
                                </div>
                                <div className="side-sett-item">
                                    <span className="icon-dashboard side-sett-itemicon"></span>
                                    <span className={this.state.area === 'security' ? "sidelink sk-active" : "sidelink"} onClick={() => history.push('/settings/security')}>
                                        <FormattedMessage id="settings.security" defaultMessage="Security" />
                                    </span>
                                </div>
                                <div className="side-sett-item">
                                    <span className="icon-back_arrow side-sett-itemicon"></span>
                                    <span className="side-sett-itemtext" onClick={() => this.props.LogoutStart()}>
                                        <FormattedMessage id="nav.labels.logout" defaultMessage="Logout" />
                                    </span>
                                </div>
                            </div>

                        </Col>

                        <Col md={12} lg={9}>

                            {this.state.area === 'account' && <SettingsAccount />}

                            {this.state.area === 'payments' && <SettingsPayments />}

                            {this.state.area === 'notifications' && <SettingsNotifications />}

                            {this.state.area === 'security' && <SettingsSecurity />}

                        </Col>

                    </Row>
                </Container>
            </>
        )
    }
}


export default injectIntl(connect(null, {
    LogoutStart
})(SettingsPage));