import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';

class PrivacyPolicyPage extends React.Component {

    render() {
        return (
            <Container className="mt-4">
                <Row>
                    <Col className="text-center">
                        <h1>
                            <FormattedMessage id="forms.labels.privacypolicy" defaultMessage="Privacy Policy" />
                        </h1>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default injectIntl(connect(null, {})(PrivacyPolicyPage));