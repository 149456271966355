import React, { useState, useEffect } from 'react';
import '../../browse-styles.scss';
import '../index.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { WithOutContext as ReactTags } from 'react-tag-input';
import { ChangeTaskFiltersStart } from '../../../../core/actions/tasks';
import { GetCompetencesStart } from '../../../../core/actions/competences';
import { Select } from '../../../../utils/forms/formElements';

const TasksFiltersComp = props => {
    const { formatMessage } = props.intl;

    const [competenceTags, setCompetenceTags] = useState([]);
    const [competenceSuggestions, setCompetenceSuggestions] = useState([]);

    useEffect(() => {
        if (props.competences.length === 0)
            props.GetCompetencesStart({ limit: 100 });
    }, []);

    useEffect(() => {
        if (props.competences.length !== 0)
            setCompetenceSuggestions(props.competences.map(c => ({ id: c.id, text: `${c.name} L${c.level}` })))
    }, [props.competences])

    useEffect(() => {
        const tempCompetenceTags = [];

        competenceSuggestions.forEach(cs => {
            if (props.tasksFilters.competences.includes(cs.id))
                tempCompetenceTags.push(cs);
        });

        setCompetenceTags(tempCompetenceTags);
    }, [props.tasksFilters.competences])

    const handleBudgetChange = (type, value) => {
        if (value === '') {
            props.ChangeTaskFiltersStart({ [`price_${type}`]: '' });
            return;
        }

        const isNumbersOnly = /^\d+$/.test(value);

        if (!isNumbersOnly)
            return;

        props.ChangeTaskFiltersStart({ [`price_${type}`]: value });
    }

    const handleCompetenceAddition = e => {
        const found = competenceSuggestions.find(t => t === e);

        if (found) {
            setCompetenceTags([...competenceTags, e]);
            props.ChangeTaskFiltersStart({ competences: [...props.tasksFilters.competences, e.id] });
        }
    }

    const handleCompetencesDelete = i => {
        setCompetenceTags(competenceTags.filter((tag, index) => index !== i));

        const filteredTagIds = [];
        competenceTags.forEach((tag, index) => {
            if (index !== i)
                filteredTagIds.push(tag.id);
        });

        // This prevents settings state of competences to empty array if competences are already empty array & user is holding backspace
        if (props.tasksFilters.competences.length === 0 && filteredTagIds.length === 0)
            return;

        props.ChangeTaskFiltersStart({ competences: filteredTagIds });
    }

    return (
        <div className="filters-wrapper">

            <div>
                <h6><FormattedMessage id="browse.project.budget" defaultMessage="Budget" /></h6>
                <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>Min</span>
                <Form.Control style={{ marginBottom: '9px' }} value={props.tasksFilters.price_min} onChange={e => handleBudgetChange('min', e.target.value)} />
                <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>Max</span>
                <Form.Control style={{ marginBottom: '13px' }} value={props.tasksFilters.price_max} onChange={e => handleBudgetChange('max', e.target.value)} />
            </div>

            <div>
                <h6><FormattedMessage id="projects.employmentduration" defaultMessage="Employment duration" /></h6>
                <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>
                    <FormattedMessage id="common.from" defaultMessage="from" />
                </span>
                <Form.Control
                    style={{ marginBottom: '13px' }}
                    type="date"
                    value={props.tasksFilters.date_from}
                    onChange={e => props.ChangeTaskFiltersStart({ date_from: e.target.value })}
                />
                <span style={{ marginLeft: '4px', fontSize: '0.82em' }}>
                    <FormattedMessage id="common.to" defaultMessage="To" />
                </span>
                <Form.Control
                    style={{ marginBottom: '13px' }}
                    type="date"
                    value={props.tasksFilters.date_to}
                    onChange={e => props.ChangeTaskFiltersStart({ date_to: e.target.value })}
                />
            </div>

            <div>
                <h6><FormattedMessage id="projects.competences" defaultMessage="Competences" /></h6>
                <ReactTags
                    style={{ width: '100%' }}
                    classNames={{
                        tags: 'filterTags',
                        tagInput: 'filterTagInput',
                        tagInputField: 'filterTagInputField',
                        selected: 'filterSelected',
                        tag: 'filterTag',
                        remove: 'filterRemove',
                        // suggestions: 'filterSuggestions',
                        // activeSuggestion: 'filterActiveSuggestion'
                    }}
                    autofocus={false}
                    inputFieldPosition="top"
                    tags={competenceTags}
                    suggestions={competenceSuggestions}
                    placeholder={formatMessage({ id: 'forms.placeholders.addcompetence', defaultMessage: 'Add competence' })}
                    handleAddition={e => handleCompetenceAddition(e)}
                    handleDelete={i => handleCompetencesDelete(i)}
                />
            </div>

            <div>
                <h6><FormattedMessage id="common.misc" defaultMessage="Misc" /></h6>
                {props.isAuthenticated &&
                    <Form.Group controlId="project-my-projects-filter">
                        <Form.Check
                            type="checkbox"
                            checked={props.tasksFilters.my_tasks}
                            onChange={() => props.ChangeTaskFiltersStart({ my_tasks: !props.tasksFilters.my_tasks })}
                            label={formatMessage({ id: 'nav.labels.myTasks', defaultMessage: 'My tasks' })}
                        />
                    </Form.Group>
                }
            </div>

            <div>
                <button style={{ width: '100%' }} onClick={() => props.ChangeTaskFiltersStart()} className="btn btn-default btn-projects">
                    <FormattedMessage id="forms.reset_filters" defaultMessage="Reset filters" />
                </button>
            </div>

        </div>
    );
}

const TasksSearchSortComp = props => {
    const { formatMessage } = props.intl;

    const SORT_OPTIONS = [
        { label: formatMessage({ id: 'browse.common.sortby', defaultMessage: 'Sort by' }), value: '' },
        { label: formatMessage({ id: 'browse.order.startingsoon', defaultMessage: 'Starting soon' }), value: 'start_date' },
        { label: formatMessage({ id: 'browse.order.highestbudget', defaultMessage: 'Highest budget' }), value: '-price' },
        { label: formatMessage({ id: 'browse.order.lowestbudget', defaultMessage: 'Lowest budget' }), value: 'price' },
        { label: formatMessage({ id: 'browse.order.byprojects', defaultMessage: 'By projects' }), value: 'project__id' }
    ]

    return (
        <div className="browse-search">
            <Form>
                <Form.Row>
                    <Col md={12} lg={9}>
                        <Form.Control
                            placeholder={formatMessage({ id: 'browse.common.search', defaultMessage: 'Search' })}
                            onChange={e => props.ChangeTaskFiltersStart({ search: e.target.value })}
                            value={props.tasksFilters.search}
                        />
                    </Col>
                    <Col md={12} lg={3}>
                        <Select
                            blurinputonselect="true"
                            name="tasks-sort-select"
                            style={{ display: 'inline' }}
                            options={SORT_OPTIONS}
                            value={props.tasksFilters.ordering}
                            onChange={e => props.ChangeTaskFiltersStart({ ordering: e.target.value })}
                        />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    tasksFilters: state.browse.tasks.list.filters,
    competences: state.competence.list.results,
    isAuthenticated: state.user.auth.isAuthenticated
})

const TasksFilters = injectIntl(connect(mapStateToProps, {
    GetCompetencesStart,
    ChangeTaskFiltersStart
})(TasksFiltersComp))

const TasksSearchSort = injectIntl(connect(mapStateToProps, {
    ChangeTaskFiltersStart
})(TasksSearchSortComp));

export { TasksFilters, TasksSearchSort };