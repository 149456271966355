import {
    GET_CHATS_SUCCESS, SELECT_CHAT_SUCCESS, DESELECT_CHAT,
    WEB_SOCKET_USER_STATUS_CHANGED,
    WEB_SOCKET_SEND_MESSAGE,
    WEB_SOCKET_RECEIVE_MESSAGE
} from './../../actions/chat/types';
import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'

const INITIAL_STATE = {
    selected: {
        userId: null,
        taskId: null,
        messages: {
            count: 0,
            next: null,
            previous: null,
            results: []
        }
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CHATS_SUCCESS:
            return {
                ...state, list: {
                    ...state.list, ...action.payload.list,
                    results: action.payload.add ? action.payload.list.results.concat(state.list.results) : action.payload.list.results
                }
            };
        case SELECT_CHAT_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case DESELECT_CHAT:
            return { ...state, selected: INITIAL_STATE.selected };
        case WEB_SOCKET_RECEIVE_MESSAGE:
            var message = action.payload.message
            var last_message = { sender: message.sender, receiver: message.receiver, message: message.message, datetime: message.datetime }
            var chat = {
                ...state, selected: {
                    ...state.selected, messages: {
                        ...state.selected.messages,
                        count: state.selected.messages.count++,
                        results: (message.task ? message.task.id : null) === state.selected.taskId &&
                            (message.sender ? message.sender.id : null) === state.selected.userId ?
                            [{ ...message, sender: message.sender?.id, receiver: message.receiver?.id }].concat(state.selected.messages.results) : []
                    }
                },
                list: {
                    ...state.list, results: state.list.results?.some(chat => (chat.task ? chat.task.id : null) === (message.task ? message.task.id : null)
                        && (chat.user ? chat.user.id : null) === (message.sender ? message.sender.id : null)) ?
                        state.list.results.map(chat =>
                            (chat.task ? chat.task.id : null) === (message.task ? message.task.id : null)
                                && (chat.user ? chat.user.id : null) === (message.sender ? message.sender.id : null) ?
                                { ...chat, last_message: last_message } : chat) :
                        [{
                            task: message.task,
                            user: message.sender,
                            is_online: true,
                            unread_messages: 1,
                            last_message: last_message
                        }].concat(state.list.results)
                }
            };
            process.env.NODE_ENV !== "production" && console.log("ChatState", chat);
            return chat;
        case WEB_SOCKET_SEND_MESSAGE:
            var message = action.payload.message
            var last_message = { sender: message.sender, receiver: message.receiver, message: message.message, datetime: message.datetime }
            var chat = {
                ...state, selected: {
                    ...state.selected, messages: {
                        ...state.selected.messages,
                        count: state.selected.messages.count++,
                        results: (message.task ? message.task.id : null) === state.selected.taskId &&
                            (message.receiver ? message.receiver.id : null) === state.selected.userId ?
                            [{ ...message, sender: message.sender?.id, receiver: message.receiver?.id }].concat(state.selected.messages.results) : []
                    }
                },
                list: {
                    ...state.list, results: state.list.results?.some(chat => (chat.task ? chat.task.id : null) === (message.task ? message.task.id : null)
                        && (chat.user ? chat.user.id : null) === (message.receiver ? message.receiver.id : null)) ?
                        state.list.results.map(chat =>
                            (chat.task ? chat.task.id : null) === (message.task ? message.task.id : null)
                                && (chat.user ? chat.user.id : null) === (message.receiver ? message.receiver.id : null) ?
                                { ...chat, last_message: last_message } : chat) :
                        [{
                            task: message.task,
                            user: message.receiver,
                            is_online: true,
                            unread_messages: 1,
                            last_message: last_message
                        }].concat(state.list.results)
                }
            };
            process.env.NODE_ENV !== "production" && console.log("ChatState", chat);
            return chat;
        case WEB_SOCKET_USER_STATUS_CHANGED:
            var changed = action.payload;
            return {
                ...state, list: {
                    ...state.list, results: state.list.results?.map(chat =>
                        chat.user?.id === changed.user ?
                            { ...chat, is_online: changed.is_online } : chat)
                }
            };
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE }
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE }
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE }
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE }
        default:
            return state;
    }
}