//MyTaskDisputeCase
export const GET_MY_TASK_DISPUTE_CASES_REQUEST = "GET_MY_TASK_DISPUTE_CASES_REQUEST";
export const GET_MY_TASK_DISPUTE_CASES_SUCCESS = "GET_MY_TASK_DISPUTE_CASES_SUCCESS";
export const GET_MY_TASK_DISPUTE_CASES_FAILURE = "GET_MY_TASK_DISPUTE_CASES_FAILURE";

export const SELECT_MY_TASK_DISPUTE_CASE_REQUEST = "SELECT_MY_TASK_DISPUTE_CASE_REQUEST";
export const SELECT_MY_TASK_DISPUTE_CASE_SUCCESS = "SELECT_MY_TASK_DISPUTE_CASE_SUCCESS";
export const SELECT_MY_TASK_DISPUTE_CASE_FAILURE = "SELECT_MY_TASK_DISPUTE_CASE_FAILURE";

export const DESELECT_MY_TASK_DISPUTE_CASE = 'DESELECT_MY_TASK_DISPUTE_CASE';

export const UPDATE_TASK_DISPUTE_CASES_REQUEST = "UPDATE_TASK_DISPUTE_CASES_REQUEST";
export const UPDATE_TASK_DISPUTE_CASES_SUCCESS = "UPDATE_TASK_DISPUTE_CASES_SUCCESS";
export const UPDATE_TASK_DISPUTE_CASES_FAILURE = "UPDATE_TASK_DISPUTE_CASES_FAILURE";