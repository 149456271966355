import { GET_MY_INSTITUTION_PROGRAMS_SUCCESS, GET_MY_PROGRAM_SUCCESS, UPDATE_MY_PROGRAM_SUCCESS } from "../../actions/institution/types";



const INITIAL_STATE = {
    selected: {
        id: null,
        name: '',
        description: '',
        degree: {},
        competences: [],
        is_locked: false
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: [ ]
    }    
}


export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case GET_MY_INSTITUTION_PROGRAMS_SUCCESS:
            return{ ...state, list: action.payload.list };
        case GET_MY_PROGRAM_SUCCESS:
            return{ ...state, selected: action.payload.data };
        case UPDATE_MY_PROGRAM_SUCCESS:
            return {
                ...state, selected: state.selected,
                list: {
                    ...state.list, results: state?.list?.results?.map(item =>
                        item.id === action.payload.details.id ? action.payload.details : item)
                }
            };
        // case CREATE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };
        // case UPDATE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };
        // case DELETE_DEGREE_SUCCESS:
        //     return{ ...state, list: action.payload.list };            
        default:
            return state;
    }
}