import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import chroma from 'chroma-js';


export const colourOptions = [
  { colorId: 0, value: 0, label: 'Blue', color: '#1f3a86' },
  { colorId: 1, value: 1, label: 'Sky', color: '#008dce' },
  { colorId: 2, value: 2, label: 'Yellow', color: '#ffc500' },
  { colorId: 3, value: 3, label: 'Orange', color: '#fb7400' },
  { colorId: 4, value: 4, label: 'Red', color: '#FF0000' },
  { colorId: 5, value: 5, label: 'Green', color: '#23D59B' },
];

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};


const MilestoneColorPicker = props => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    if (props.colorPicker) {
      setOptions(colourOptions);
    } else {
      if (props.milestones?.list) {
        var tempOptions = props.milestones?.list.map(m => ({ colorId: colourOptions[m.color].value, value: m.id ? m.id : 0, label: m.name ? m.name : 'Default', color: colourOptions[m.color].color}));
        setOptions(tempOptions);
        if (props.selected) {
          for (let i = 0; i < tempOptions.length; i++) {
            if (props.colorPicker) {
              if (tempOptions[i].colorId === props.selected.color) {
                setSelected(tempOptions[i]);
              }        
            } else {
              if (tempOptions[i].value === props.selected.id) {
                setSelected(tempOptions[i]);
              }
            }
          }
        }        
      }
    }
  }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

  useEffect(() => {
    if (props.colorPicker) {
      setOptions(colourOptions);
    } else {
      if (props.milestones?.list) {
        var tempOptions = props.milestones?.list.map(m => ({ colorId: colourOptions[m.color].value, value: m.id ? m.id : 0, label: m.name ? m.name : 'Default', color: colourOptions[m.color].color }));
        setOptions(tempOptions);
        if (props.selected) {
          for (let i = 0; i < tempOptions.length; i++) {
            if (props.colorPicker) {
              if (tempOptions[i].colorId === props.selected.color) {
                setSelected(tempOptions[i]);
              }        
            } else {
              if (tempOptions[i].value === props.selected.id) {
                setSelected(tempOptions[i]);
              }
            }
          }
        }
      }
    }
  }, [props.milestones]);

  useEffect(() => {
    if (!props.selected) return;
    for (let i = 0; i < options.length; i++) {
      if (props.colorPicker) {
        if (options[i].colorId === props.selected.color) {
          setSelected(options[i]);
        }        
      } else {
        if (options[i].value === props.selected.id) {
          setSelected(options[i]);
        }
      }
    }
  }, [props.selected]);


  const handleChange = e => {
    setSelected(e);
    props.onColorChange(e);
  }

  return (
    <Select
      defaultValue={options[0]}
      blurinputonselect="true"
      value={selected}
      onChange={e => handleChange(e)}
      menuPlacement="top"
      label="Select milestone color"
      isSearchable={false}
      options={options}
      styles={colourStyles}
    />
  );
}

export default MilestoneColorPicker;