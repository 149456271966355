import React from 'react';
import _ from 'lodash';
import './index.scss';
import history from '../../utils/routes/history';
import AlphabetIndex from './CompetenciesFilters/AlphabetIndex/index';
import ModalCreateANewCompetence from '../../components/Competence/Create/modalCreateANewCompetence';
import InfiniteScroll from 'react-infinite-scroll-component';
import { langService } from './../../utils/languageService/index';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Jumbotron } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { createLoadingSelector } from '../../API/selector';
import { CompetenciesSearchSort } from './CompetenciesFilters';
import { GetCompetencesStart, GetCompetencesChangePageStart, ChangeCompetencesFiltersStart } from '../../core/actions/competences';
import { debounceCompetenceFreeInputFields } from '../../utils/common/browse';
import { getBloomName, getBloomVerb } from '../../utils/common/misc';
import { disconnectFromChannel, connectToChannel } from '../../API/webSocket';
import { GetAvailableIssuersStart } from '../../core/actions/issuer';
import Infobar from '../../components/Infobar';
import { isEqual } from 'lodash';
import { INITIAL_STATE_FILTERS } from '../../core/reducers/competence';
import ReactTooltip from 'react-tooltip';

class CompetenciesMainPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalCreateCompetenceVisible: false,
        };
    }

    componentDidMount() {
        const { competences, competencesFilters, auth: { isAuthenticated }, isInitialized } = this.props;
        connectToChannel("competences")

        if (competences.length !== 0)
            return;

        this.props.GetCompetencesStart(competencesFilters);
        // if (role && (role.type === 'EDU' || role.type === 'EAC')) {
        //     this.props.GetMyIssuersStart();
        // }
        if (isAuthenticated && isInitialized) {
            this.props.GetAvailableIssuersStart();
        }
    }

    componentWillUnmount() {
        const { history } = this.props;

        if (!history.location.pathname.includes('/competency') && history.location.pathname !== '/competencies')
            disconnectFromChannel("competences")

        debounceCompetenceFreeInputFields.cancel();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.competencesFilters === this.props.competencesFilters)
            return;

        if (isEqual(this.props.competencesFilters, INITIAL_STATE_FILTERS)) {
            debounceCompetenceFreeInputFields.cancel();
            this.props.GetCompetencesStart(this.props.competencesFilters);
        }

        if (prevProps.competencesFilters.search !== this.props.competencesFilters.search) {
            debounceCompetenceFreeInputFields([this.props.competencesFilters.limit, this.props.competencesFilters.search, this.props.competencesFilters.ordering, this.props.competencesFilters.starts_with], this.props.GetCompetencesStart, this.props.competencesFilters);
            return;
        }

        debounceCompetenceFreeInputFields.cancel();
        this.props.GetCompetencesStart(this.props.competencesFilters);
    }

    onAlphabetClick = (char) => {
        this.props.ChangeCompetencesFiltersStart({ starts_with: char });
    }

    openModalCreateCompetence() {
        this.setState({ modalCreateCompetenceVisible: true });
    }

    hideModalCreateCompetence = (_) => {
        this.setState({ modalCreateCompetenceVisible: false });
    }

    openDetails(slug) {
        if (!slug) return;
        history.push('/competency/' + slug);
    }

    renderActions() {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;

        if (!isAuthenticated)
            return (<button className="btn btn-default btn-developers-inv" onClick={() => history.push('/auth/login')}><FormattedMessage id="common.logintocontribute" defaultMessage="Login to contribute" /></button>)

        if (!isEmailVerified)
            return (<button className="btn btn-default btn-developers-inv"><FormattedMessage id="common.verifytocontribute" defaultMessage="Verify email to contribute" /></button>)

        return (<button className="btn btn-default btn-developers-inv" onClick={() => this.openModalCreateCompetence()}><FormattedMessage id="common.contribute" defaultMessage="CONTRIBUTE" /></button>);
    }

    renderCompetences(competences) {
        if (_.isEmpty(competences)) {
            return <></>
        }

        return (
            <div className="card-list-projects mt-2">
                {competences.map((c, i) =>
                    <Card className="card-item-edu" key={i}>

                        <div className={`level-badge level-color-` + c.level} data-tip data-for={ "blooms-agenda-" + i }>
                            {getBloomVerb(1, c.level, langService.locale).toUpperCase()}
                            {/* {getBloomVerb(2, c.sub_level, langService.locale).toUpperCase()} */}
                            {/* {getBloomName(c.level)} ({getBloomVerb(1, c.level, langService.locale)}) */}
                        </div>
                        <ReactTooltip className="blooms-agenda" id={"blooms-agenda-" + i} place="right" type="light" effect="solid">
                            <div className="bloom-pyramid">
                                <div className="bp-title">{langService.messages['blooms.whats_this']}</div>
                                <div className="bp-txt">{langService.messages['blooms.L' + c.level]}</div>
                                <img className="bp-img" src="/assets/img/blooms-taxonomy.png" alt=""></img>
                            </div>
                        </ReactTooltip>

                        <Card.Body style={{ 'paddingBottom': '12px' }}>

                            <div style={{ "width": "100%", 'paddingTop': '22px' }}>

                                <div className="fc-row">
                                    <div className="c-itemname link-hover" onClick={() => this.openDetails(c.id)}>{c.name.toUpperCase()} L{c.level}</div>
                                    <div className="c-itemusser">
                                            <FormattedMessage id="issuers.issued_by" defaultMessage="Issuer" />
                                            {(this.props.auth.isAuthenticated && c.issuer)
                                                ? c.issuer_name === "ecta"
                                                    ? <span className="">ECTA</span>
                                                    : <span className="clink" onClick={() => history.push('/user/' + c.issuer)}>{c.issuer_name.toUpperCase()}</span>
                                                : <span className="clink" onClick={() => history.push('/auth/login')}>{c.issuer_name}</span>}
                                            {(!this.props.auth.isAuthenticated && !c.issuer) && <span className="">ECTA</span>}
                                            {(this.props.auth.isAuthenticated && !c.issuer) && <span className="">ECTA</span>}
                                    </div>
                                </div>

                                <div className="top-def"><FormattedMessage id="competences.top_definition" defaultMessage="TOP DEFINITION" /></div>

                                <div className="c-itemdef">
                                    <FormattedMessage id="competences.this_person_can" defaultMessage="This person can " />
                                    {getBloomVerb(2, c.sub_level, langService.locale)}&nbsp;
                                    {c.description ? c.description : "..."}
                                </div>

                                <div className="chips-row">
                                    { c.parent?.name && 
                                        <div className="chips-col mr-5">
                                            <div className="chips-col-title"><FormattedMessage id="competences.parent" defaultMessage="Parent competency" /></div>
                                            <div className="card-tags">
                                                <span className="clink" onClick={() => this.openDetails(c.parent.id)} >{c.parent?.name}</span>
                                            </div>
                                        </div>
                                    }
                                    {c.skills?.length > 0 && 
                                        <div className="chips-col">
                                            <div className="chips-col-title">{langService.messages['competences.related_skills']}</div>
                                            <div className="card-tags">
                                                {c.skills.map((s, index) =>
                                                    <span className="clink" key={index} onClick={() => this.openDetails(s.id)} >{s.name + " L" + s.level}</span>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                            
                            </div>

                            <div className="card-sep"></div>

                            <div className="card-actions">
                                <div></div>
                                <button className="btn btn-default btn-developers" onClick={() => history.push("/competency/" + c.id)}>
                                    <FormattedMessage id="browse.project.viewtask" defaultMessage="View details" />
                                </button>
                            </div>

                        </Card.Body>
                    
                    </Card>
                )}
            </div>
        )
    }

    render() {
        const { competences } = this.props;
        return (
            <>
                <Jumbotron className="jumbo-competencies mb-0">
                    <Infobar />
                    <Container>
                        <h1 className="c-bannertitle"><FormattedMessage id="competences.written_by_you" defaultMessage="ECTA Competencies are written by you" /></h1>
                        {this.renderActions()}
                    </Container>
                </Jumbotron>

                <Container className="mt-3">

                    <Row>
                        <Col className="browse-title-wrapper">
                            <div className="browse-title title-dev">
                                <FormattedMessage id="projects.competences" defaultMessage="Competencies" />
                            </div>
                            {/* {this.renderActions()} */}
                        </Col>
                    </Row>

                    <CompetenciesSearchSort />
                    <AlphabetIndex onAlphabetClick={(c) => this.onAlphabetClick(c)} />

                    <Row>
                        <Col>
                        <span className="items-count mt-3">
                            <FormattedMessage id="plural.competencies" values={{ count: competences.length }} defaultMessage={`${competences.length} Competencies`} />
                        </span>
                        </Col>
                    </Row>

                    <Row>

                        <Col md={9}>

                            <InfiniteScroll
                                dataLength={competences.length}
                                next={() => setTimeout(() => this.props.nextUrl && this.props.GetCompetencesChangePageStart(this.props.nextUrl), 500)}
                                hasMore={competences.length !== this.props.count}
                                loader={<div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.loading" defaultMessage="Loading" />...</div>}
                                endMessage={competences.length > 0 && <div style={{ marginTop: '11px' }}><FormattedMessage id="browse.common.allrecordsloaded" defaultMessage="All records loaded" /></div>}
                            >
                                <div className="card-list-projects">
                                    {this.renderCompetences(competences)}
                                </div>
                            </InfiniteScroll>
                        </Col>

                        <Col md={3}>
                            <div className="c-side-1">
                                <div className="c-side-1-txt">
                                    <FormattedMessage id="competences.created_by_you" defaultMessage="Competencies are created by people like you!" />
                                </div>
                                {this.renderActions()}
                            </div>
                        </Col>

                    </Row>

                </Container>
                {this.state.modalCreateCompetenceVisible && <ModalCreateANewCompetence visible={this.state.modalCreateCompetenceVisible} onClose={(x) => this.hideModalCreateCompetence(x)} />}
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['GET_COMPETENCES']);
const mapStateToProps = state => {
    return {
        auth: state.user.auth,
        role: state.user.info.settings.active_role_obj,
        isEmailVerified: state.user.info.isEmailVerified,
        isLoading: loadingSelector(state),
        competences: state.competence.list.results,
        competencesFilters: state.competence.list.filters,
        nextUrl: state.competence.list.next,
        count: state.competence.list.count
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetCompetencesStart,
    GetCompetencesChangePageStart,
    ChangeCompetencesFiltersStart,
    // GetMyIssuersStart,
    GetAvailableIssuersStart
})(CompetenciesMainPage));