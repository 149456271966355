import { toast } from 'react-toastify';

const ApiNotificationHandler = ({ getState, dispatch }) => next => action => {

    const { type, payload } = action;

    if (type.includes("FAILURE")) {
        if (payload.toast_msg)
            toast.error(payload.toast_msg, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        console.error({
            message: payload ? payload.err_msg.errorType : undefined,
            description: payload ? payload.err_msg.detail : undefined,
        })
    } else if (type.includes("SUCCESS")) {
        if (payload.toast_msg)
            toast.success(payload.toast_msg, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        if (process.env.NODE_ENV !== "production") {
            console.log({
                message: 'API Success',
                description: payload ? payload.succ_msg : undefined,
            })
        };
    }
    next(action);
}
export default ApiNotificationHandler;