import * as types from './types';
import { apiCon } from '../../../API';
import { getParameterObject } from './../../../utils/common/index';
import { INITIAL_STATE_FILTERS as INITIAL_COMPETENCES_FILTERS } from '../../reducers/competence';


//----------------------
// Actions Competences
//----------------------


const ALLOWED_COMPETENCES_FILTERS = ['limit', 'search', 'ordering', 'starts_with', 'issuers', 'platform_only', 'skills', 'level_min', 'level_max', 'categories'];
//----------------------
// Actions Change Competences List Filters 
//----------------------
export const ChangeCompetencesFiltersStart = (params = INITIAL_COMPETENCES_FILTERS) => {
    const filters = getParameterObject(params, ALLOWED_COMPETENCES_FILTERS);
    const payload = { succ_msg: "Successfully change competences filters.", filters: filters }
    return { type: types.CHANGE_COMPETENCES_FILTERS, payload: payload };
}

//----------------------
// Actions Get Competences
//----------------------
// export const GetCompetencesStart = (limit = 100, search, ordering, starts_with, platform_only) => async dispatch => {
//     dispatch({ type: types.GET_COMPETENCES_REQUEST });
//     var url = '/competences/'
//     const params = {
//         'limit': limit,
//         ...(search ? { 'search': search } : {}),
//         ...(ordering ? { 'ordering': ordering } : {}),
//         ...(starts_with ? { 'starts_with': starts_with } : {}),
//         ...(platform_only ? { 'platform_only': platform_only } : {})
//     }
//     await apiCon.get(url, { params })
//         .then(response => {
//             dispatch(GetCompetencesSuccess(response.data));
//         }
//         )
//         .catch(err => {
//             dispatch(GetCompetencesFail(err.response));
//         })
// }

export const GetCompetencesStart = (params = INITIAL_COMPETENCES_FILTERS) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCES_REQUEST });
    var url = '/competences/';
    let filters = getParameterObject(params, ALLOWED_COMPETENCES_FILTERS);
    let skillsParam = '?';

    // TODO: Make general function that converts skills[]=test&skills[]=test2 to skills=test&skills=test2
    if (filters.skills) {
        if (filters.skills.length === 0) {
            delete filters.skills;
        }
        else {
            const oldSkills = [...filters.skills];
            delete filters.skills;
            for (let i = 0; i < oldSkills.length; i++) {
                if (i === oldSkills.length - 1)
                    skillsParam = `${skillsParam}skills=${oldSkills[i]}`;
                else
                    skillsParam = `${skillsParam}skills=${oldSkills[i]}&`;
            }
        }
    }

    if (skillsParam !== '?')
        url = url + skillsParam;

    await apiCon.get(url, { params: filters })
        .then(response => {
            dispatch(GetCompetencesSuccess(response?.data));
        }
        )
        .catch(err => {
            dispatch(GetCompetencesFail(err?.response));
        })
}

export const GetCompetencesChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCES_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetCompetencesSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetCompetencesFail(err.response));
        })
}
export const GetCompetencesSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received Competences info.", list: data, add: add }
    return { type: types.GET_COMPETENCES_SUCCESS, payload: payload };
}
export const GetCompetencesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Competences failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_COMPETENCES_FAILURE, payload: payload }
}

//--------------------------
// Actions Verify Competence - exists or not
//--------------------------
export const VerifyCompetenceStart = (name, level, issuer) => async dispatch => {
    dispatch({ type: types.VERIFY_COMPETENCE_REQUEST });
    await apiCon.get('/competence/verify/', { 
        params: { 
            'name': name,
            'level': level,
            ...(issuer ? { 'issuer_id': issuer } : {}), 
        }})
        .then(response => {
            dispatch(VerifyCompetenceSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(VerifyCompetenceFail(err.response));
        })
}
export const VerifyCompetenceSuccess = (data) => {
    const payload = { succ_msg: "Successfully received verification of Competence.", selected: data }
    return { type: types.VERIFY_COMPETENCE_SUCCESS, payload: payload };
}
export const VerifyCompetenceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Verify Competence failed. Please try again."
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.VERIFY_COMPETENCE_FAILURE, payload: payload };
}

//--------------------------
// Actions Validate Competence - does a base competency the user is trying to add already exist in the n-depth of already selected base competencies
//--------------------------
export const ValidateCompetenceStart = (values) => async dispatch => {
    dispatch({ type: types.VALIDATE_COMPETENCE_REQUEST });
    const json = JSON.stringify(values);
    const address = '/competence/validate/';
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(ValidateCompetenceSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(ValidateCompetenceFail(err.response));
        })
}
export const ValidateCompetenceSuccess = (data) => {
    const payload = { succ_msg: "Competence validation results:", warnings: data.warnings }
    return { type: types.VALIDATE_COMPETENCE_SUCCESS, payload: payload };
}
export const ValidateCompetenceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Competence validation returned warnings."
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.VALIDATE_COMPETENCE_FAILURE, payload: payload };
}

//----------------------
// Actions Select Competence
//----------------------
export const SelectCompetenceStart = (id) => async dispatch => {
    dispatch({ type: types.SELECT_COMPETENCE_REQUEST });
    await apiCon.get('/competence/' + id)
        .then(response => {
            dispatch(SelectCompetenceSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectCompetenceFail(err.response));
        })
}
export const SelectCompetenceSuccess = (data) => {
    const payload = { succ_msg: "Successfully received Competence info.", selected: data }
    return { type: types.SELECT_COMPETENCE_SUCCESS, payload: payload };
}
export const SelectCompetenceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Competence failed. Please try again."
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_COMPETENCE_FAILURE, payload: payload };
}
export const DeselectCompetence = () => {
    const payload = { succ_msg: "Successfully deselected Competence info." }
    return { type: types.DESELECT_COMPETENCE, payload: payload };
}

//----------------------
// Actions Create Competence
//----------------------
export const CreateCompetenceStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_COMPETENCE_REQUEST });
    const json = JSON.stringify(values);
    const address = `/competences/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(CreateCompetenceSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateCompetenceFail(err.response));
        });

}
export const CreateCompetenceSuccess = (data) => {
    const succ_msg = "Competence created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.CREATE_COMPETENCE_SUCCESS, payload: payload };
}
export const CreateCompetenceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Create Competence failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_COMPETENCE_FAILURE, payload: payload };
}

//----------------------
// Actions UPDATE Competence (EDU ONLY)
// /v1/api/competence/{id-slug}
//----------------------
// PATCH
// {
//     "sub_level": 0,
//     "description": "string",
//     "parent": "string"
//     "task_categories": [ 0 ],
//     "skills": [ "string" ]
// }
export const UpdateCompetenceStart = (id, values) => async dispatch => {
    dispatch({ type: types.UPDATE_COMPETENCE_REQUEST });
    const json = JSON.stringify(values);
    const address = `/competence/` + id;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateCompetenceSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateCompetenceFail(err.response));
        });

}
export const UpdateCompetenceSuccess = (data) => {
    const succ_msg = "Competence updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.UPDATE_COMPETENCE_SUCCESS, payload: payload };
}
export const UpdateCompetenceFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Update Competence failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_COMPETENCE_FAILURE, payload: payload };
}
