import React from 'react';
import '../../../assets/styles/partials/_auth.scss';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Form as BSForm, Container } from 'react-bootstrap';
import { minLen8, required } from '../../../utils/forms/validators';
import { Input, CheckBoxCustom } from '../../../utils/forms/formElements';
import { FormattedMessage, injectIntl } from 'react-intl';
import { LoginStart } from '../../../core/actions/auth';
import { Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { LoginGoogleStart } from '../../../core/actions/googleAuth';
import { REACT_APP_GOOGLE_CLIENT_ID } from '../../../utils/common/env-variables';

class LoginForm extends React.Component {
    FORM_LABEL = 'forms.labels';
    FORM_PLC = 'forms.placeholders';

    onSubmit = values => {
        this.props.LoginStart(values);
    }

    onLoginGoogle = async (google_response) => {
        this.props.LoginGoogleStart(google_response.accessToken)
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <>
                <div id="overlay">
                    <img src="/assets/img/icon/lightbulb.svg" alt="Idea"></img>
                </div>
                <Container className="flex-center pt-65">
                    <Form
                        onSubmit={this.onSubmit}
                        render={props => (
                            <>
                                <div className="auth">
                                    <p><span className="underline"><FormattedMessage id="forms.loginForm" defaultMessage="Login Form" /></span></p>
                                    <BSForm validated={props.valid} onSubmit={props.handleSubmit}>
                                        <Field
                                            name="email"
                                            label={<FormattedMessage id={`${this.FORM_LABEL}.email`} defaultMessage="Email" />}
                                            placeholder={formatMessage({ id: `${this.FORM_PLC}.email`, defaultMessage: "Email" })}
                                            component={Input}
                                            validate={required}
                                        />
                                        <Field
                                            name="password"
                                            label={<FormattedMessage id={`${this.FORM_LABEL}.password`} defaultMessage="Password" />}
                                            type="password"
                                            component={Input}
                                            validate={minLen8}
                                        />
                                        <Field
                                            name="rememberMe"
                                            label={<FormattedMessage id={`${this.FORM_LABEL}.rememberme`} defaultMessage="Remember Me" />}
                                            type="checkbox"
                                            component={CheckBoxCustom}
                                            defaultValue={false}
                                        />
                                        <button type="submit" className="btn btn-default btn-generic btn-block"><FormattedMessage id={`${this.FORM_LABEL}.login`} defaultMessage="Login" /></button>
                                        <BSForm.Text className="auth-small">
                                            <FormattedMessage id={`${this.FORM_LABEL}.forgotPassword`} defaultMessage="Forgot password" />
                                            <Link to="/auth/forgot-password"><FormattedMessage id={`${this.FORM_LABEL}.resetHere`} defaultMessage="Reset here." /></Link>
                                        </BSForm.Text>
                                        <BSForm.Text className="auth-small">
                                            <FormattedMessage id={`${this.FORM_LABEL}.noAccountYet`} defaultMessage="noAccountYet" />
                                            <Link to="/auth/register"><FormattedMessage id={`${this.FORM_LABEL}.goToRegistration`} defaultMessage="goToRegistration" /></Link>
                                        </BSForm.Text>
                                        {/* { !prod ?  <pre><p ><code><small>{JSON.stringify(props.values, 0, 2)}</small></code></p></pre> : '' } */}
                                    </BSForm>
                                    <div className="separator">or</div>
                                    <GoogleLogin
                                        clientId={REACT_APP_GOOGLE_CLIENT_ID()}
                                        buttonText={<FormattedMessage id={`${this.FORM_LABEL}.continueWithGoogle`} defaultMessage="Continue with Google" />}
                                        onSuccess={this.onLoginGoogle}
                                        onFailure={this.onLoginGoogle}
                                        cookiePolicy={'single_host_origin'}
                                        isSignedIn={false}
                                        className="btn-block"
                                    />
                                </div>
                            </>
                        )}
                    />
                </Container>
            </>
        )
    }
}

export default injectIntl(connect(null, { LoginStart, LoginGoogleStart })(LoginForm));
