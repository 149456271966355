import React from 'react';
import history from './utils/routes/history';
import translations from './utils/i18n'
import Header from './components/Header/index';
import Routes from './Routes';
import { IntlProvider } from 'react-intl';
import { Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ChangeLangAction } from './core/actions/lang';
import 'react-toastify/dist/ReactToastify.min.css';


class App extends React.Component {

  componentDidMount() {
    console.log(`${process.env.REACT_APP_NAME}`);
    document.getElementById("ecta-spinner").remove();
    process.env.NODE_ENV === "production" ? console.log("Production build " + process.env.REACT_APP_VERSION) : console.log("Development build " + process.env.REACT_APP_VERSION);
  }

  render() {
    const { auth: { isAuthenticated }, info: { isEmailVerified, settings: { active_role_obj }, isInitialized } } = this.props;
    return (
      <>
        <IntlProvider locale={this.props.lang} messages={translations[this.props.lang]}>
          <Router history={history}>
            <Header />
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
            <Routes isAuthenticated={isAuthenticated} isEmailVerified={isEmailVerified} isInitialized={isInitialized} activeRole={active_role_obj} />
          </Router>
        </IntlProvider>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: state.lang,
    auth: state.user.auth,
    info: state.user.info
  }
}

export default connect(mapStateToProps, {
  ChangeLangAction
})(App);
