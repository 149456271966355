//-------------------
//  EDUCATION actions
//-------------------
export const GET_EDUCATIONS_REQUEST = "GET_EDUCATIONS_REQUEST";
export const GET_EDUCATIONS_SUCCESS = "GET_EDUCATIONS_SUCCESS";
export const GET_EDUCATIONS_FAILURE = "GET_EDUCATIONS_FAILURE";

export const SELECT_EDUCATION_REQUEST = "SELECT_EDUCATION_REQUEST";
export const SELECT_EDUCATION_SUCCESS = "SELECT_EDUCATION_SUCCESS";
export const SELECT_EDUCATION_FAILURE = "SELECT_EDUCATION_FAILURE";

export const UPDATE_EDUCATION_REQUEST = "UPDATE_EDUCATION_REQUEST";
export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_FAILURE = "UPDATE_EDUCATION_FAILURE";

export const DELETE_EDUCATION_REQUEST = "DELETE_EDUCATION_REQUEST";
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_FAILURE = "DELETE_EDUCATION_FAILURE";

export const CREATE_EDUCATION_REQUEST = "CREATE_EDUCATION_REQUEST";
export const CREATE_EDUCATION_SUCCESS = "CREATE_EDUCATION_SUCCESS";
export const CREATE_EDUCATION_FAILURE = "CREATE_EDUCATION_FAILURE";

export const GET_EDUCATION_VERIFICATION_REQUESTS = "GET_EDUCATION_VERIFICATION_REQUESTS";
export const GET_EDUCATION_VERIFICATION_REQUESTS_SUCCESS = "GET_EDUCATION_VERIFICATION_REQUESTS_SUCCESS";
export const GET_EDUCATION_VERIFICATION_REQUESTS_FAILURE = "GET_EDUCATION_VERIFICATION_REQUESTS_FAILURE";

export const GET_VERIFIED_STUDENTS = "GET_VERIFIED_STUDENTS";
export const GET_VERIFIED_STUDENTS_SUCCESS = "GET_VERIFIED_STUDENTS_SUCCESS";
export const GET_VERIFIED_STUDENTS_FAILURE = "GET_VERIFIED_STUDENTS_FAILURE";

export const GET_EDUCATION_VERIFICATION_REQUEST_DETAILS = "GET_EDUCATION_VERIFICATION_REQUEST_DETAILS";
export const GET_EDUCATION_VERIFICATION_REQUEST_DETAILS_SUCCESS = "GET_EDUCATION_VERIFICATION_REQUEST_DETAILS_SUCCESS";
export const GET_EDUCATION_VERIFICATION_REQUEST_DETAILS_FAILURE = "GET_EDUCATION_VERIFICATION_REQUEST_DETAILS_FAILURE";

export const GET_MYVERIFICATIONREQUESTS_REQUESTS = "GET_MYVERIFICATIONREQUESTS_REQUESTS";
export const GET_MYVERIFICATIONREQUESTS_SUCCESS = "GET_MYVERIFICATIONREQUESTS_SUCCESS";
export const GET_MYVERIFICATIONREQUESTS_FAILURE = "GET_MYVERIFICATIONREQUESTS_FAILURE";

export const CREATE_EDUCATION_VERIFICATION_REQUEST = "CREATE_EDUCATION_VERIFICATION_REQUEST";
export const CREATE_EDUCATION_VERIFICATION_SUCCESS = "CREATE_EDUCATION_VERIFICATION_SUCCESS";
export const CREATE_EDUCATION_VERIFICATION_FAILURE = "CREATE_EDUCATION_VERIFICATION_FAILURE";

export const UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS = "UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS";
export const UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS_SUCCESS = "UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS_SUCCESS";
export const UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS_FAILURE = "UPDATE_EDUCATION_VERIFICATION_REQUEST_DETAILS_FAILURE";
