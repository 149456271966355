import React from 'react';
import './modalSubmitTaskProposal.scss';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import moment from 'moment';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import { CreateTaskProposalStart, UpdateTaskProposalStart } from '../../core/actions/taskProposals';
import { GetFreelancersStart } from '../../core/actions/role';
import CompetencesComponent from '../Competence/competencesComponent';
import { apiCon } from '../../API';
import { createErrorMessageSelector, createLoadingSelector } from '../../API/selector';
import ModalValidationWarnings from '../Common/ModalValidationWarnings';

const customStyles = {
    height: 'auto',
    bottom: 'auto',
    top: '15%'
};

// This component is used to submit new task proposal or update existing.
// User in views "Public task details", "My Work", "My Projects"

class ModalSubmitTaskProposal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            proposalEdit: false,
            focusedInput: null,
            startDate: moment(this.props.start),
            endDate: moment(this.props.end),
            tpPrice: this.props.price,
            action: 'new', //counter
            tags: this.props.competences ?? [],
            users: [],
            selectedUsers: [],
            warnings: {
                messages: [],
            },
            modalWarningsVisible: false,
        };
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.isCreateLoading && !this.props.isCreateLoading && this.props.isCreateInvalid === '') ||
            (prevProps.isUpdateLoading && !this.props.isUpdateLoading && this.props.isUpdateInvalid === '')) {
            this.hide()
        }
    }

    componentDidMount() {
        if (this.props.invite) {
            this.getFreelancers();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.freelancers && nextProps.proposals && nextProps.roles) {

            var options = [];

            nextProps.freelancers.forEach(f => {
                var exists = false;
                nextProps.proposals.forEach(p => {
                    if (f.id === p.freelancer.id) {
                        exists = true;
                    }
                })
                nextProps.roles.forEach(r => {
                    if (f.id === r.id) {
                        exists = true;
                    }
                })
                if (!exists) {
                    options.push({ label: f.name, value: f.id })
                }
            });

            this.setState({ users: options });
        }
    }

    getFreelancers = () => {
        this.props.GetFreelancersStart();
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        if (input === 'users') {
            this.setState({ selectedUsers: event });
        } else {
            if (parseInt(event.target.value) > -1) {
                this.setState({ [input]: event.target.value });
            }
        }
    }

    onDatesChange(startDate, endDate) {
        this.setState({ startDate, endDate });
    }

    onCompetencesChange = tags => {
        this.setState({ tags: tags });
    }

    onValidationWarningsConfirm() {
        this.setState({ modalWarningsVisible: false }, () => this.submitTaskProposal(false));
    }

    verifyCreate(params) {
        const settings = { headers: { 'Content-Type': 'application/json' } };
        apiCon.post('/me/project/task/proposals/validate/', JSON.stringify(params), settings).then(response => {
            this.props.CreateTaskProposalStart(params);
        }).catch(error => {
            if (error.response.data) {
                this.setState({
                    modalWarningsVisible: true, warnings: error.response.data
                });
            }
        })
    }

    verifyUpdate(params) {
        const settings = { headers: { 'Content-Type': 'application/json' } };
        apiCon.patch(`/me/project/task/proposal/${params.id}/validate/`, JSON.stringify(params), settings).then(response => {
            this.props.UpdateTaskProposalStart(params)
        }).catch(error => {
            if (error.response.data) {
                this.setState({
                    modalWarningsVisible: true, warnings: error.response.data
                });
            }
        })
    }


    submitTaskProposal(verify = true) {
        const { active_role_type } = this.props;

        if (this.state.tpPrice < 1) { return; }

        const competenceList = [];
        this.state.tags.forEach(t => {
            competenceList.push(t.id);
        });

        let post = {
            price: parseInt(this.state.tpPrice),
            start_date: moment(this.state.startDate).startOf('day').add(23, 'hours').toISOString(),
            end_date: moment(this.state.endDate).startOf('day').add(23, 'hours').toISOString(),
            competences: competenceList,
            task: this.props.taskId,
            freelancer: ''
        }


        if (this.props.invite) {
            this.state.selectedUsers.forEach(u => {
                post.freelancer = u.value;
                if (!verify || active_role_type === 'DEV') {
                    this.props.CreateTaskProposalStart(post);
                } else {
                    this.verifyCreate(post)
                }
            });
        } else {
            if (this.props.action === "new") {
                post.freelancer = this.props.freelancerId;
                if (!verify || active_role_type === 'DEV') {
                    this.props.CreateTaskProposalStart(post);
                } else {
                    this.verifyCreate(post)
                }
            } else { // counter
                post.id = this.props.existingProposal.id;
                post.action_type = "COUNTER";
                post.last_task_proposal_change = this.props.existingProposal.last_task_proposal_change;
                if (!verify || active_role_type === 'DEV') {
                    this.props.UpdateTaskProposalStart(post);
                } else {
                    this.verifyUpdate(post)
                }
            }
        }
    }

    render() {
        const { visible, invite, clientId } = this.props;
        return (
            <Rodal
                id="modalSubmitProposal"
                visible={visible}
                onClose={() => this.hide()}
                width={535}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        {this.props.action === "new"
                            ? <FormattedMessage id="browse.task.submit_task_proposal" defaultMessage="Submit a task proposal" />
                            : <FormattedMessage id="browse.task.submit_task_proposal_counter" defaultMessage="Submit a task proposal counter offer" />
                        }
                    </div>

                    <div className="modal-body p-0 pt-4">

                        <div className="proposal-container">

                            <Form autoComplete="off">
                                <div className="proposal-content mb-3">

                                    <div className="">
                                        <div className="proposal-title">
                                            <FormattedMessage id="browse.task.remuneration" defaultMessage="Task remuneration" /> $
                                    </div>
                                        <div style={{ "marginRight": "25px" }}>
                                            <input type="number" className="form-control" name="tpPrice" min="1" value={parseInt(this.state.tpPrice)} onChange={this.handleChange('tpPrice')}></input>
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="proposal-title">
                                            <FormattedMessage id="browse.task.timeframe" defaultMessage="Timeframe" />
                                        </div>
                                        <div className="">
                                            <DateRangePicker
                                                startDate={this.state.startDate}
                                                startDateId="tpStartDate"
                                                endDate={this.state.endDate}
                                                endDateId="tpEndDate"
                                                onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                endDatePlaceholderText={"End date"}
                                                startDatePlaceholderText={"Start date"}
                                                displayFormat={"DD-MM-YYYY"}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="mb-3">
                                    <div className="proposal-title">
                                        <FormattedMessage id="projects.competences" defaultMessage="Timeframe" /> <small>(1-5)</small>
                                    </div>

                                    <CompetencesComponent obj_competences={this.state.tags} onCompetencesChange={this.onCompetencesChange} issuer={clientId} />

                                </div>


                                {invite &&
                                    <div className="proposal-invite">
                                        <div className="proposal-title">
                                            <FormattedMessage id="browse.task.invite_free" defaultMessage="Invite freelancers" />
                                        </div>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isMulti
                                            isSearchable={true}
                                            blurinputonselect="true"
                                            name="users"
                                            options={this.state.users}
                                            onChange={this.handleChange('users')}
                                        />
                                    </div>
                                }

                            </Form>

                        </div>

                        <div className="modal-submit-buttons">
                            <div className="btn btn-default btn-smallpadding btn-projects mr-2" onClick={() => this.hide()}>
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.submitTaskProposal()}>
                                <FormattedMessage id="common.submit" defaultMessage="Submit" />
                            </button>
                        </div>

                    </div>

                </div>
                <ModalValidationWarnings visible={this.state.modalWarningsVisible} warnings={this.state.warnings} onConfirm={() => this.onValidationWarningsConfirm()} onClose={() => this.setState({ modalWarningsVisible: false })} />
            </Rodal>
        )
    }

}

const loadingCreate = createLoadingSelector(['CREATE_TASK_PROPOSAL']);
const errorCreate = createErrorMessageSelector(['CREATE_TASK_PROPOSAL']);
const loadingUpdate = createLoadingSelector(['UPDATE_TASK_PROPOSAL']);
const errorUpdate = createErrorMessageSelector(['UPDATE_TASK_PROPOSAL']);
const mapStateToProps = state => {
    return {
        active_role_type: state.user.info.settings.active_role_obj.type,
        roles: state.user.info.roles,
        proposals: state.myWork.myTaskProposals.list.results,
        freelancers: state.browse.freelancers.list.results,
        isCreateLoading: loadingCreate(state),
        isCreateInvalid: errorCreate(state),
        isUpdateLoading: loadingUpdate(state),
        isUpdateInvalid: errorUpdate(state),
    }
}


export default injectIntl(connect(mapStateToProps, {
    CreateTaskProposalStart,
    UpdateTaskProposalStart,
    GetFreelancersStart
})(ModalSubmitTaskProposal));