import * as types from './types';
import { apiCon } from '../../../API';

//----------------------
// Actions Task Proposal
//----------------------

//----------------------
// Actions Select My Task Proposal
//----------------------
export const SelectMyTaskProposal = (id) => async dispatch => {
    dispatch({ type: types.SELECT_MY_TASK_PROPOSAL_REQUEST });
    await apiCon.get('/project/task/proposal/' + id)
        .then(response => {
            dispatch(SelectMyTaskProposalSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectMyTaskProposalFail(err.response));
        })
}
export const SelectMyTaskProposalSuccess = (data) => {
    const payload = { succ_msg: "Successfully received task proposal info.", selected: data }
    return { type: types.SELECT_MY_TASK_PROPOSAL_SUCCESS, payload: payload };
}
export const SelectMyTaskProposalFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Task proposal failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get task proposal failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.SELECT_MY_TASK_PROPOSAL_FAILURE, payload: payload }
}

export const DeselectMyTaskProposal = () => {
    const payload = { succ_msg: "Successfully deselected task proposal info." }
    return { type: types.DESELECT_MY_TASK_PROPOSAL, payload: payload };
}

//----------------------
// Actions Get My Task Proposal List
//----------------------
export const GetMyTaskProposalsStart = (taskId, projectId, limit = 100, search) => async dispatch => {
    dispatch({ type: types.GET_MY_TASK_PROPOSALS_REQUEST });
    var url = '/me/project/task/proposals/'
    const params = {
        'limit': limit,
        ...(search ? { 'search': search } : {}),
        ...(taskId ? { 'task': taskId } : {}),
        ...(projectId ? { 'project': projectId } : {}),

    }
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetMyTaskProposalsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyTaskProposalsFail(err.response));
        })
}
export const GetMyTaskProposalsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_MY_TASK_PROPOSALS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetMyTaskProposalsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetMyTaskProposalsFail(err.response));
        })
}
export const GetMyTaskProposalsSuccess = (data) => {
    const payload = { succ_msg: "Successfully received tasks proposals info.", list: data }
    return { type: types.GET_MY_TASK_PROPOSALS_SUCCESS, payload: payload };
}
export const GetMyTaskProposalsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get My Task Proposals failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get My Task Proposals failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_TASK_PROPOSALS_FAILURE, payload: payload }
}

//----------------------
// Actions Update Task Proposal
//----------------------
export const UpdateTaskProposalStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_TASK_PROPOSAL_REQUEST });
    const address = `/me/project/task/proposal/` + values.id;
    delete values['id'];
    const json = JSON.stringify(values);
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateTaskProposalSuccess(response.data));
            // dispatch(SelectMyTaskStart(response.data['task']['id']))
        })
        .catch(err => {
            dispatch(UpdateTaskProposalFail(err.response));
        });
}
export const UpdateTaskProposalSuccess = (data, showToast = true, isSelectedTask = true) => async dispatch => {
    const succ_msg = "Task Proposal updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: showToast ? succ_msg : null, selected: data, onMyWork: window.location.pathname === '/my-work' };
    if (isSelectedTask && data.status === 'CONFIRMED') {
        dispatch(GetMyTaskProposalsStart(data.task.id))
    }
    dispatch({ type: types.UPDATE_TASK_PROPOSAL_SUCCESS, payload: payload });
}
export const UpdateTaskProposalFail = (err, showToast = true) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Update Task Proposal failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Update Task Proposal failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: showToast ? toast_msg : null, status: status };
    return { type: types.UPDATE_TASK_PROPOSAL_FAILURE, payload: payload };
}

//----------------------
// Actions Create Task Proposal
//----------------------
export const CreateTaskProposalStart = (values, showToast = true) => async dispatch => {
    dispatch({ type: types.CREATE_TASK_PROPOSAL_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/project/task/proposals/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(CreateTaskProposalSuccess(response.data, showToast));
            // dispatch(SelectMyTaskStart(response.data['task']['id']))
        })
        .catch(err => {
            dispatch(CreateTaskProposalFail(err.response));
        });
}
export const CreateTaskProposalSuccess = (data, showToast = true, addToProposals = true) => {
    const succ_msg = "Task Proposal created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: showToast ? succ_msg : null, selected: data, add: addToProposals };
    return { type: types.CREATE_TASK_PROPOSAL_SUCCESS, payload: payload };
}
export const CreateTaskProposalFail = (err, showToast = true) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Ooops! Create Task Proposal failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create Task Proposal failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: showToast ? toast_msg : null, };
    return { type: types.CREATE_TASK_PROPOSAL_FAILURE, payload: payload };
}

