import React from 'react';
import Rodal from 'rodal';
import './profile-modals.scss';
import 'rodal/lib/rodal.css';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col } from 'react-bootstrap';
import { CreateEducationVerificationRequestStart } from '../../core/actions/education';

const customStyles = {
    // height: 'inherit',
    height: '535px',
    // maxHeight: '85%',
    bottom: 'auto',
    top: '8%'
};

class ModalSendEducationVerificationRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            role_detail_education: this.props.edu.id,
            edu: this.props.edu.edu_school,
            edu_program: this.props.edu.edu_program,
            applicant_program: this.props.edu.program,
            date_from: this.props.edu.date_from,
            date_to: this.props.edu.date_to,
            applicant_first_name: this.props.role.first_name,
            applicant_last_name: this.props.role.last_name,
            applicant_national_id: '',
            applicant_edu_id: '',
            certificate_edu_id: '',
            visible: false,
        };
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        // form validation
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }

        this.setState({ validated: true });
    };
    
    save() {
        this.props.CreateEducationVerificationRequestStart({
            role_detail_education: this.props.edu.id,
            edu: this.props.edu.edu_school,
            edu_program: this.props.edu.edu_program,
            applicant_program: this.props.edu.program,
            date_from: this.props.edu.date_from,
            date_to: this.props.edu.date_to,
            applicant_first_name: this.state.applicant_first_name,
            applicant_last_name: this.state.applicant_last_name,
            applicant_national_id: this.state.applicant_national_id,
            applicant_edu_id: this.state.applicant_edu_id,
            certificate_edu_id: this.state.certificate_edu_id
        }, this.hide());
    }

    render() {
        const { visible, edu } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={420}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>
                    <div className="modal-title">
                        <FormattedMessage id="edu.send_request" defaultMessage="Send verification request" />
                    </div>
                    <div className="modal-body p-2 pt-4 pb-0">

                    
                        <div className="">{edu.school}</div>
                        <div className="">{edu.program}</div>
                        <small>{moment(edu.date_from).format('YYYY')} - {moment(edu.date_to).format('YYYY')}</small>

                        <hr />

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Form.Row className="ml-0 mr-0">
                                <Form.Group as={Col} controlId="firstname">
                                    <Form.Label>
                                        <FormattedMessage id="forms.labels.firstName" defaultMessage="Firstname" />
                                    </Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('applicant_first_name')} value={this.state.applicant_first_name || ''} required/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="lastname">
                                    <Form.Label>
                                        <FormattedMessage id="forms.labels.lastName" defaultMessage="Lastname" />
                                    </Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('applicant_last_name')} value={this.state.applicant_last_name || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row className="ml-0 mr-0">
                                <Form.Group as={Col} controlId="national_id">
                                    <Form.Label>
                                        <FormattedMessage id="common.national_id" defaultMessage="National ID number" />
                                    </Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('applicant_national_id')} value={this.state.applicant_national_id || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row className="ml-0 mr-0">
                                <Form.Group as={Col} controlId="student_id">
                                    <Form.Label>
                                        <FormattedMessage id="edu.student_id" defaultMessage="Student Number" />
                                    </Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('applicant_edu_id')} value={this.state.applicant_edu_id || ''} required/>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row className="ml-0 mr-0">
                                <Form.Group as={Col} controlId="diploma_id">
                                    <Form.Label>
                                        <FormattedMessage id="edu.diploma_id" defaultMessage="Diploma Id Number" />
                                    </Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('certificate_edu_id')} value={this.state.certificate_edu_id || ''} required />
                                </Form.Group>
                            </Form.Row>

                            <Row className="ml-0 mr-0">
                                <Col className="ml-0 mr-0">
                                    <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2 mr-0"> {/* onClick={() => { this.state.editMode ? this.update() : this.save() }} */}
                                        {this.state.editMode ? <FormattedMessage id="common.edit" defaultMessage="Edit" /> : <FormattedMessage id="common.submit" defaultMessage="Submit request" />}
                                    </button>
                                    <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.hide()}>
                                        {this.state.editMode ? <FormattedMessage id="common.cancel" defaultMessage="Cancel" /> : <FormattedMessage id="common.close" defaultMessage="Close" />}
                                    </div>
                                </Col>
                            </Row>

                        </Form>

                    </div>
                </div>
            </Rodal>
        )
    }

}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
    }
}

export default injectIntl(connect(mapStateToProps, {
    CreateEducationVerificationRequestStart
})(ModalSendEducationVerificationRequest));