import React from 'react';
import { connect } from 'react-redux';
import '../../containers/Competencies/Competency/index.scss';
import { injectIntl, FormattedMessage } from 'react-intl';
import history from '../../utils/routes/history';
import { Card } from 'react-bootstrap';
import { VoteForCompetenceSkillStart, DeleteVoteForCompetenceSkillStart } from '../../core/actions/competenceSkill';
import VotingItem from './votingItemComponent';
import VotingButtons from './votingButtonsComponent';
import ContributedBy from './contributedByComponent';

class SkillVoting extends React.Component {

    callActionBasedOnUserVote = (id, user_voted, vote_for) => {
        if (user_voted && user_voted === vote_for) {
            this.props.DeleteVoteForCompetenceSkillStart(id);
        } else {
            this.props.VoteForCompetenceSkillStart(id, vote_for);
        }
    }

    render() {
        const { data, isAuth } = this.props;
        if (!data) return 'loading';
        return (
            <div className="card-list-projects">

                {data?.map((item, i) =>
                    <Card className="card-item card-item-developers" key={i}>
                        <Card.Body>
                            <div className="vote-item" key={item.id}>
                                <VotingButtons isAuth={isAuth} item={item} onVote={(id, user_voted, vote_for) => this.callActionBasedOnUserVote(id, user_voted, vote_for)} />
                                <div className="voting-content">
                                    <VotingItem item={item.skill} />
                                    <ContributedBy item={item} />
                                </div>
                            </div>
                            <div className="card-sep"></div>
                            <div className="card-actions">
                                <div></div>
                                <button className="btn btn-default btn-developers" onClick={() => history.push("/competency/" + item.skill?.id)}>
                                    <FormattedMessage id="browse.project.viewtask" defaultMessage="View details" />
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                )}

            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        data: state.competence.selected.competence_skills?.list?.results,
        isAuth: state.user.auth.isAuthenticated
    }
}

export default injectIntl(connect(mapStateToProps, {
    VoteForCompetenceSkillStart,
    DeleteVoteForCompetenceSkillStart
})(SkillVoting));