import React from 'react';
import './modalViewSubmitedWork.scss';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Rating from 'react-rating';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Image } from 'react-bootstrap';
import { GetMyReviewRequestsForTaskStart, SelectMyReviewRequestForTaskStart, SubmitReviewRequestForTaskStart } from '../../core/actions/rating';
import { langService } from './../../utils/languageService/index';

const customStyles = {
    height: 'fit-content',
    bottom: 'auto',
    top: '15%'
};

class ModalReviewAuditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            arrayOfCategoryRatings: [],
            comment: '',
        };
    }

    componentDidMount() {
        this.props.GetMyReviewRequestsForTaskStart(null, null, this.props.task.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.requests.length > 0 && !nextProps.selected?.id) {
            this.props.SelectMyReviewRequestForTaskStart(this.props.task.id, nextProps.requests[0].id);
        }
        if (nextProps.selected.id) {
            this.setState({ arrayOfCategoryRatings: nextProps.selected.ratings_to_fullfill });
        }
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    rate(idx, rate) {
        var arr = this.state.arrayOfCategoryRatings;
        arr[idx].rating = rate;
        this.setState({ arrayOfCategoryRatings: arr });
    }

    translate(desc) {
        switch (desc) {
            case 'Quality of explanation':
                return langService.messages['rating.category.quality_of_explanation'];
            case 'Length of explanation':
                return langService.messages['rating.category.length_of_explanation'];
            case 'Satisfaction with the verdict':
                return langService.messages['rating.category.satisfaction_with_verdict'];
            default:
                break;
        }
    }

    hide() {
        this.props.onClose();
    }

    save() {
        this.props.SubmitReviewRequestForTaskStart(this.props.selected.id, this.props.task.id, this.state.arrayOfCategoryRatings, this.state.comment);
        this.hide();
    }

    render() {

        var title = langService.messages['rating.review_audit_title'];

        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={750}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        {title}
                    </div>

                    <div className="modal-body p-2 pt-4">

                        <div className="form-label">
                            <FormattedMessage id="rating.review_audit" defaultMessage="Review the audit process by these categories" />
                        </div>

                        <div className="rating-row mt-2 mb-3">

                            <div className="rates">

                                {this.state.arrayOfCategoryRatings.map((r, x) =>
                                    <div className="rate-category" key={x}>
                                        <div className="rate-categoryname">
                                            {/* {r.category.short_description} */}
                                            {this.translate(r.category.short_description)}
                                        </div>
                                        <div className="rating-stars">
                                            <Rating
                                                initialRating={r.rating}
                                                onChange={(rate) => this.rate(x, rate)}
                                                fractions={1}
                                                emptySymbol={<img src="/assets/img/star-gold-empty.png" alt="rating star" width="24" className="icon" />}
                                                fullSymbol={<img src="/assets/img/star-gold-full.png" alt="rating star" width="24" className="icon" />}
                                            />
                                        </div>
                                    </div>
                                )}

                            </div>

                        </div>

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Form.Group controlId="formCDesc">
                                <Form.Label>
                                    <FormattedMessage id="rating.feedback" defaultMessage="Leave feedback" />
                                </Form.Label>
                                <Form.Control as="textarea" rows="3" onChange={this.handleChange('comment')} placeholder={langService.messages['common.leave_a_comment']} />
                            </Form.Group>

                        </Form>

                        <div className="modal-submit-buttons">
                            <div className="btn btn-default btn-projects mr-2" onClick={() => this.hide()}>
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>
                            <div className="btn btn-default btn-projects-inv pl-5 pr-5" onClick={() => this.save()}>
                                <FormattedMessage id="common.save" defaultMessage="Save" />
                            </div>
                        </div>

                    </div>
                </div>
            </Rodal>
        )
    }

}

const mapStateToProps = state => {
    return {
        requests: state.myWork.myRatingRequestsForTask.list.results,
        selected: state.myWork.myRatingRequestsForTask.selected,
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyReviewRequestsForTaskStart,
    SelectMyReviewRequestForTaskStart,
    SubmitReviewRequestForTaskStart
})(ModalReviewAuditor));