import React from 'react';
import './index.scss';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from 'react-bootstrap';
import { UploadDocuments } from '../../components/Projects/uploadDocuments';
import { CreateTaskResultStart } from '../../core/actions/taskResults';
import { langService } from './../../utils/languageService/index';

const customStyles = {
    height: 'auto',
    bottom: 'auto',
    top: '15%'
};

class ModalSubmitWork extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            btnSaveDisabled: true,
            projectFiles: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    hide() {
        this.props.onClose();
    }

    onFilesChanged = (files) => {
        this.setState({ projectFiles: files });
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }


    handleSubmit = event => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.save();
            return;
        }
        this.setState({ validated: true });
    }

    save() {
        this.props.CreateTaskResultStart(
            { task: this.props.task.id, name: this.state.description },
            this.state.projectFiles,
            this.props.final
        );
        this.props.onClose();
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={685}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        {this.props.final ? langService.messages['my_task.final'] : langService.messages['my_task.submit']}
                    </div>

                    <div className="modal-body p-0 pt-4">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            {this.props.final &&
                                <>
                                    <div className="final-submit-title">
                                        <FormattedMessage id="my_task.completed" defaultMessage="Task completed?" />
                                    </div>
                                    <div className="final-submit-txt">
                                        <FormattedMessage id="my_task.completed_desc" defaultMessage="Submit your final files and request for payment." />
                                    </div>
                                </>
                            }

                            <Form.Group controlId="formCDesc">
                                <Form.Label>
                                    <FormattedMessage id="common.comment" defaultMessage="Comment" />
                                </Form.Label>
                                <Form.Control as="textarea" rows="3" onChange={this.handleChange('description')} required placeholder={langService.messages['forms.placeholders.comment']} />
                            </Form.Group>

                            <Form.Group style={{ width: '100%' }}>
                                <Form.Label>
                                    <FormattedMessage id="forms.labels.documents" defaultMessage="Documents" />
                                </Form.Label>
                                <UploadDocuments onFilesChanged={this.onFilesChanged} />
                            </Form.Group>

                            <br />
                            <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2">
                                <FormattedMessage id="common.submit" defaultMessage="Submit" />
                            </button>
                            <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => this.hide()} >
                                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                            </div>
                        </Form>

                    </div>
                </div>
            </Rodal>
        )
    }

}

const mapStateToProps = state => {
    return {
        task: state.myWork.myTasks.selected,
    }
}

export default injectIntl(connect(mapStateToProps, {
    CreateTaskResultStart
})(ModalSubmitWork));