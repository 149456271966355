import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import './milestoneDropdown.scss';
import { UpdateTaskStart } from '../../core/actions/tasks'
import { connect } from 'react-redux';

const MilestoneDropdown = props => {
  // Destruct props
  const { milestoneId, milestoneName, milestoneColor, children } = props;

  // expand state - this handle the collapse/uncollapse
  const [expand, setExpand] = useState(true);

  const [{ isOver, isOverCurrent }, drop] = useDrop({
    accept: 'task',
    drop(item, monitor) {
      const didDrop = monitor.didDrop();

      if (didDrop)
        return;

      // dropFn(item.id, milestoneName)
      props.UpdateTaskStart({
        id: item.id,
        milestone: milestoneId
      }, false);

    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  // Sets background color based on if the dropdown is hovered while dragging item or not - can be used this later as style,..
  let backgroundColor = 'transparent';
  if (isOverCurrent || isOver) {
    backgroundColor = 'rgba(0, 141, 206, 0.4)';
  }

  return (
    <div
      ref={drop}
      className="tasklist"
      style={{ height: '100%', width: '100%', backgroundColor: backgroundColor }}
    >
      <div className={`milestone-header m${milestoneColor}`} onClick={() => setExpand(!expand)}>
        <span className="milestone-title">{milestoneName[0].toUpperCase() + milestoneName.slice(1)}</span>
        {(isOverCurrent || isOver) && <span className="milestone-drophere">(Drop here)</span>}
        {/* <span className="milestone-hright" onClick={() => setExpand(!expand)}>{expand ? 'Collapse' : 'Expand'}</span> */}
        <span className="milestone-hright">
          {expand ? <i className="arrow down marrow"></i> : <i className="arrow up marrow"></i>}
        </span>
      </div>

      {expand &&
        <div>
          {children.length === 0
            ? <div className="milestone-empty">No tasks in this milestone. Drag & Drop to rearrange tasks.</div>
            : children
          }
        </div>
      }

    </div>
  )
}

export default connect(null, {
  UpdateTaskStart
})(MilestoneDropdown);