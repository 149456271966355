import React from 'react';
import Rodal from 'rodal';
import './profile-modals.scss';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form, Row, Col, Image } from 'react-bootstrap';
import { CreateExperienceStart, DeleteExperienceStart, UpdateExperienceStart } from '../../core/actions/experience';


const customStyles = {
    height: 'inherit',
    maxHeight: '85%',
    bottom: 'auto',
    top: '8%'
};

class ModalEditFreelancerExperience extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            experiences: this.props.experiences,
            editMode: false,
            selectedId: null,
            date_from: '',
            date_to: '',
        };
        this.delete = this.delete.bind(this);
    }

    hide() {
        this.props.onClose();
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value });
    }

    handleDateChange = input => event => {
        if (input === 'date_from' || input === 'date_to') {
            let { value, min, max } = event.target;
            value = Math.max(Number(min), Math.min(Number(max), Number(value)));
            this.setState({ [input]: value });
        }
    }

    delete(id) {
        this.props.DeleteExperienceStart(id);
        this.cancel();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.role) {
            this.setState({
                experiences: nextProps.role.experiences.list
            });
        }
    }

    cancel() {
        this.setState({
            selectedId: null,
            title: '',
            company_name: '',
            date_from: '',
            date_to: '',
            owner: '',
            editMode: false
        })
    }

    select(experience) {
        const date_from = new Date(experience.date_from).getFullYear();
        const date_to = new Date(experience.date_to).getFullYear();
        this.setState({
            selectedId: experience.id,
            title: experience.title,
            company_name: experience.company_name,
            date_from: date_from,
            date_to: date_to,
            owner: this.props.role.id,
            editMode: true
        }
        // , () => process.env.NODE_ENV !== "production" && console.log(this.state)
        );
    }

    update() {
        this.props.UpdateExperienceStart({
            id: this.state.selectedId,
            title: this.state.title,
            company_name: this.state.company_name,
            date_from: this.state.date_from + '-01-01',
            date_to: this.state.date_to + '-01-01',
            owner: this.props.role.id
        });
        this.cancel();
    }

    save() {
        this.props.CreateExperienceStart({
            title: this.state.title,
            company_name: this.state.company_name,
            date_from: this.state.date_from + '-01-01',
            date_to: this.state.date_to + '-01-01',
            owner: this.props.role.id
        });
        this.cancel()
    }

    handleSubmit = event => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            this.state.editMode ? this.update() : this.save()
            return;
        }
        this.setState({ validated: true });
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.hide()}
                width={500}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        {this.state.editMode ?
                            <FormattedMessage id="experience.edit" defaultMessage="Edit Experience" /> :
                            <FormattedMessage id="experience.add" defaultMessage="Add Experience" />
                        }
                    </div>

                    <div className="modal-body p-2 pt-4">

                        <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

                            <Form.Row>
                                <Form.Group as={Col} controlId="CompanyProject">
                                    <Form.Label>
                                        <FormattedMessage id="experience.company" defaultMessage="Company/Project" />
                                    </Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('company_name')} value={this.state.company_name || ''} required />
                                </Form.Group>
                                <Form.Group as={Col} controlId="position">
                                    <Form.Label>
                                        <FormattedMessage id="experience.position" defaultMessage="Position/Role" />
                                    </Form.Label>
                                    <Form.Control type="text" onChange={this.handleChange('title')} value={this.state.title || ''} required />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="CompanyProject">
                                    <Form.Label>
                                        <FormattedMessage id="common.from_years" defaultMessage="From" />
                                    </Form.Label>
                                    <Form.Control type="number" onBlur={this.handleDateChange('date_from')} onChange={this.handleChange('date_from')} value={this.state.date_from || ''} min={1930} max={2022} required />
                                </Form.Group>
                                <Form.Group as={Col} controlId="position">
                                    <Form.Label>
                                        <FormattedMessage id="common.to_years" defaultMessage="To" />
                                    </Form.Label>
                                    <Form.Control type="number" onBlur={this.handleDateChange('date_to')} onChange={this.handleChange('date_to')} value={this.state.date_to || ''} min={1930} max={2022} required />
                                </Form.Group>
                            </Form.Row>

                            <Row>
                                <Col>
                                    <button type="submit" className="btn btn-default btn-projects-inv float-right mt-2 mb-2">
                                        {this.state.editMode ? <FormattedMessage id="common.edit" defaultMessage="Edit" /> : <FormattedMessage id="common.add" defaultMessage="Add" />}
                                    </button>
                                    <div className="btn btn-default btn-generic float-right btn-smallpadding mt-2 mb-2 mr-2" onClick={() => { this.state.editMode ? this.cancel() : this.hide() }}>
                                        {this.state.editMode ? <FormattedMessage id="common.cancel" defaultMessage="Cancel" /> : <FormattedMessage id="common.close" defaultMessage="Close" />}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col>
                                    <div className="msection-rows">
                                        {this.state?.experiences.map((e, i) =>
                                            <div className="msection-row" key={i}>
                                                {/* <Image src="/assets/img/default-ex.png" className="sec-img"></Image> */}
                                                <div className="msec-info">
                                                    <div className="">{e.company_name}</div>
                                                    <div className="">{e.title}</div>
                                                    <div className="">{e.date_from && e.date_from.substring(0, 4)} - {e.date_to && e.date_to.substring(0, 4)}</div>
                                                </div>
                                                <div>
                                                    <Image src="/assets/img/svg/delete.svg" className="delete-icon edit-pencil" onClick={() => this.delete(e.id)}></Image>
                                                    <div className="icon-edit edit-icon edit-pencil" onClick={() => this.select(e)}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                        </Form>

                    </div>
                </div>
            </Rodal >
        )
    }

}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
    }
}

export default injectIntl(connect(mapStateToProps, {
    DeleteExperienceStart,
    CreateExperienceStart,
    UpdateExperienceStart
})(ModalEditFreelancerExperience));