//-------------------
//  Get My Issuers 
//-------------------
export const GET_MY_ISSUERS_REQUEST = "GET_MY_ISSUERS_REQUEST";
export const GET_MY_ISSUERS_SUCCESS = "GET_MY_ISSUERS_SUCCESS";
export const GET_MY_ISSUERS_FAILURE = "GET_MY_ISSUERS_FAILURE";

//-------------------
//  Get Available Issuers 
//-------------------
export const GET_AVAILABLE_ISSUERS_REQUEST = "GET_AVAILABLE_ISSUERS_REQUEST";
export const GET_AVAILABLE_ISSUERS_SUCCESS = "GET_AVAILABLE_ISSUERS_SUCCESS";
export const GET_AVAILABLE_ISSUERS_FAILURE = "GET_AVAILABLE_ISSUERS_FAILURE";

//-------------------
//  Get Public Issuers 
//-------------------
export const GET_PUBLIC_ISSUERS_REQUEST = "GET_PUBLIC_ISSUERS_REQUEST";
export const GET_PUBLIC_ISSUERS_SUCCESS = "GET_PUBLIC_ISSUERS_SUCCESS";
export const GET_PUBLIC_ISSUERS_FAILURE = "GET_PUBLIC_ISSUERS_FAILURE";

//-------------------
//  Create Issuer 
//-------------------
export const CREATE_ISSUER_REQUEST = "CREATE_ISSUER_REQUEST";
export const CREATE_ISSUER_SUCCESS = "CREATE_ISSUER_SUCCESS";
export const CREATE_ISSUER_FAILURE = "CREATE_ISSUER_FAILURE";

//-------------------
//  Get Issuer Details
//-------------------
export const GET_ISSUER_DETAIL_REQUEST = "GET_ISSUER_DETAIL_REQUEST";
export const GET_ISSUER_DETAIL_SUCCESS = "GET_ISSUER_DETAIL_SUCCESS";
export const GET_ISSUER_DETAIL_FAILURE = "GET_ISSUER_DETAIL_FAILURE";

//-------------------
//  Update Issuer Details 
//-------------------
export const UPDATE_ISSUER_REQUEST = "UPDATE_ISSUER_REQUEST";
export const UPDATE_ISSUER_SUCCESS = "UPDATE_ISSUER_SUCCESS";
export const UPDATE_ISSUER_FAILURE = "UPDATE_ISSUER_FAILURE";

//-------------------
//  Delete Issuer
//-------------------
export const DELETE_ISSUER_REQUEST = "DELETE_ISSUER_REQUEST";
export const DELETE_ISSUER_SUCCESS = "DELETE_ISSUER_SUCCESS";
export const DELETE_ISSUER_FAILURE = "DELETE_ISSUER_FAILURE";