import { useState, useEffect } from 'react';
import { apiCon } from '../../API';

// Parametners:
// apiRoute: string; - route to fetch data from - REQUIRED
// valueKey: string; - fetched data object key user for value (ex.: id) - REQUIRED
// valueKeyTwo: string; - fetched data objectk key two if fetched data has another object in this one (ex.: id) - OPTIONAL
// labelKey: string; - fetched data object key to show in search - REQUIRED

// See src\containers\Competencies\CompetenciesFilters\index.js for example usages

const INITIAL_PARAMETERS = {
    apiRoute: null,
    valueKey: null,
    valueKeyTwo: null,
    labelKey: null
}

const useReactSelect = (parameters = INITIAL_PARAMETERS) => {
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // TODO: Debounce search
        fetchData(search);
    }, [search])

    const fetchData = async search => {
        setIsLoading(true);
        const params = {
            limit: 10,
            search: search
        }

        try {
            const response = await apiCon.get(parameters.apiRoute, { params });
            setOptions(response.data?.results?.map(obj => ({ value: parameters.valueKeyTwo ? obj[parameters.valueKey][parameters.valueKeyTwo] : obj[parameters.valueKey], label: obj[parameters.labelKey].toUpperCase() })));
            setIsLoading(false);
        } catch (error) {
            setOptions([]);
            setIsLoading(false);
        }
    }

    return {
        setSearchFn: setSearch,
        search: search,
        options: options,
        loading: isLoading
    };
}

export default useReactSelect;