import React from 'react'
import _ from 'lodash';
import '../index.scss';
import './index.scss';
import history from '../../../utils/routes/history';
import { connect } from 'react-redux';
import { langService } from '../../../utils/languageService/index';
import { Container, Row, Col, Jumbotron, Card, Tabs, Tab } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectCompetenceStart } from '../../../core/actions/competences';
import { GetCompetenceDescriptionsStart } from '../../../core/actions/competenceDescription';
import { GetCompetenceParentsStart } from '../../../core/actions/competenceParent';
import { GetCompetenceSkillsStart } from '../../../core/actions/competenceSkill';
import { getBloomName, getBloomVerb } from '../../../utils/common/misc';
import { disconnectFromChannel, connectToChannel } from '../../../API/webSocket';
import DescriptionVoting from '../../../components/Competence/votingDescriptionComponent';
import ParentVoting from '../../../components/Competence/votingParentComponent';
import SkillVoting from '../../../components/Competence/votingSkillsComponent';
import ModalAddCompetenceDescription from '../../../components/Competence/modalAddCompetenceDescription';
import ModalAddCompetenceParent from '../../../components/Competence/modalAddCompetenceParent';
import ModalAddCompetenceSkills from '../../../components/Competence/modalAddCompetenceSkills';
import ModalEditCompetence from '../../../components/Competence/Edit/modalEditCompetence';
import ReactTooltip from 'react-tooltip';
import Infobar from '../../../components/Infobar';
import ModalCreateANewCompetence from '../../../components/Competence/Create/modalCreateANewCompetence';

class CompetenceDetailsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: '',
            modalCreateCompetenceVisible: false,
            modalAddDescriptionVisible: false,
            modalAddParentVisible: false,
            modalAddSkillsVisible: false,
            modalEditCompetenceVisible: false
        };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidMount() {
        const param = this.props.match.params;
        if (param) {
            this.props.SelectCompetenceStart(param.uid, false).then(x => this.handleTabChange(this.props.activeKey ?? '1'));
        } else {
            this.handleTabChange(this.props.activeKey ?? '1')
        }
        connectToChannel("competences")
    }

    componentWillUnmount() {
        if (!history.location.pathname.includes('/competency') && history.location.pathname !== '/competencies')
            disconnectFromChannel("competences")
    }

    handleTabChange(selected_key) {
        const { competency } = this.props;
        if (selected_key === this.state.activeKey) return;
        this.setState({ activeKey: selected_key });
        if (selected_key === "1") {
            this.props.GetCompetenceDescriptionsStart(10, competency?.id);
        } else if (selected_key === "2") {
            this.props.GetCompetenceParentsStart(10, competency?.id);
        } else {
            this.props.GetCompetenceSkillsStart(10, competency?.id);
        }
    };

    toolTipMessage(message, n) {
        return (
            <>
                <div className="info-icon-wrap ml-1">
                    <img src="/assets/img/icon/info.png" data-tip data-for={ "competency" + n } alt="info-icon" width="13"></img>
                </div>
                <ReactTooltip id={ "competency" + n } place="bottom" type="dark" effect="solid">
                    <div className="tooltip-txt">
                        {message}
                    </div>
                </ReactTooltip>
            </>
        )
    }

    openDetails(slug) {
        if (!slug) return;
        history.push('/competency/' + slug);
    }

    openModalCreateCompetence() {
        this.setState({ modalCreateCompetenceVisible: true });
    }

    renderActions() {
        const { auth: { isAuthenticated }, isEmailVerified } = this.props;

        if (!isAuthenticated)
            return (<button className="btn btn-default btn-developers-inv" onClick={() => history.push('/auth/login')}><FormattedMessage id="common.logintocontribute" defaultMessage="Login to contribute" /></button>)

        if (!isEmailVerified)
            return (<button className="btn btn-default btn-developers-inv"><FormattedMessage id="common.verifytocontribute" defaultMessage="Verify email to contribute" /></button>)

        return (<button className="btn btn-default btn-developers-inv" onClick={() => this.openModalCreateCompetence()}><FormattedMessage id="common.contribute" defaultMessage="CONTRIBUTE" /></button>);
    }

    render() {
        const { auth: { isAuthenticated }, competency } = this.props;
        return (
            <>
                <Jumbotron className="jumbo-competencies mb-0">
                    <Infobar />
                    <Container>
                        <h1 className="c-bannertitle"><FormattedMessage id="competences.written_by_you" defaultMessage="ECTA Competencies are written by you" /></h1>
                        {this.renderActions()}
                    </Container>
                </Jumbotron>

                <Container className="mt-3">

                    <div className="header-back p-0 mb-3">
                        <div style={{'display': 'flex', 'alignItems': 'center'}}>
                            <span className="icon-back_arrow" onClick={() => history.goBack()}></span>
                            <span onClick={() => history.goBack()}>
                                <FormattedMessage id="common.back" defaultMessage="Back" />
                            </span>
                        </div>
                        {competency.allow_direct_edit && <button className="btn btn-default btn-developers-inv" onClick={() => this.setState({modalEditCompetenceVisible: true})}><FormattedMessage id="competency.edit" defaultMessage="EDIT COMPETENCY" /></button>}
                    </div>

                    <Card className="card-item-edu competence-details-card">

                        <div className={`level-badge level-color-` + competency.level} data-tip data-for={"blooms-agenda"}>
                            {getBloomVerb(1, competency.level, langService.locale).toUpperCase()}
                            {/* {getBloomVerb(2, c.sub_level, langService.locale).toUpperCase()} */}
                            {/* {getBloomName(c.level)} ({getBloomVerb(1, c.level, langService.locale)}) */}
                        </div>
                        <ReactTooltip className="blooms-agenda" id={"blooms-agenda"} place="right" type="light" effect="solid">
                            <div className="bloom-pyramid">
                                <div className="bp-title">{langService.messages['blooms.whats_this']}</div>
                                <div className="bp-txt">{langService.messages['blooms.L' + competency.level]}</div>
                                <img className="bp-img" src="/assets/img/blooms-taxonomy.png" alt=""></img>
                            </div>
                        </ReactTooltip>

                        <Card.Body style={{ 'paddingBottom': '12px' }}>

                            <div style={{ "width": "100%", 'paddingTop': '22px' }}>

                                <div className="fc-row">
                                    <div className="c-itemname">{competency.name.toUpperCase()} L{competency.level}</div>
                                    <div className="c-itemusser">
                                            <FormattedMessage id="issuers.issued_by" defaultMessage="Issuer" />
                                            {(this.props.auth.isAuthenticated && competency.issuer)
                                                ? competency.issuer_name === "ecta"
                                                    ? <span className="">ECTA</span>
                                                    : <span className="clink" onClick={() => history.push('/user/' + competency.issuer)}>{competency.issuer_name.toUpperCase()}</span>
                                                : <span className="clink" onClick={() => history.push('/auth/login')}>{competency.issuer_name}</span>}
                                            {(!this.props.auth.isAuthenticated && !competency.issuer) && <span className="">ECTA</span>}
                                            {(this.props.auth.isAuthenticated && !competency.issuer) && <span className="">ECTA</span>}
                                    </div>
                                </div>

                                <div className="top-def"><FormattedMessage id="competences.top_definition" defaultMessage="TOP DEFINITION" /></div>

                                <div className="c-itemdef">
                                    <FormattedMessage id="competences.this_person_can" defaultMessage="This person can " />
                                    {getBloomVerb(2, competency.sub_level, langService.locale)}&nbsp;
                                    {competency.description ? competency.description : "..."}
                                </div>

                                <div className="chips-row">
                                    {competency.parent?.name && 
                                        <div className="chips-col mr-5">
                                            <div className="chips-col-title tooltip-parent">
                                                <div className="tooltip-title">
                                                    <FormattedMessage id="competences.parent" defaultMessage="Parent competency" />
                                                    {this.toolTipMessage(langService.messages['competences.parent_description'], 4)}
                                                </div>
                                            </div>
                                            <div className="card-tags">
                                                <span className="clink" onClick={() => this.openDetails(competency.parent?.id)} >{competency.parent?.name}</span>
                                            </div>
                                        </div>
                                    }
                                    {competency.skills?.length > 0 && 
                                        <div className="chips-col mr-5">
                                            <div className="chips-col-title tooltip-parent">
                                                <div className="tooltip-title">
                                                    <FormattedMessage id="competences.related_skills" defaultMessage="Base competencies" />
                                                    {this.toolTipMessage(langService.messages['competences.related_description'], 5)}
                                                </div>
                                            </div>
                                            <div className="card-tags">
                                                {competency.skills.map((s, index) =>
                                                    <span className="clink" key={index} onClick={() => this.openDetails(s.id)} >{s.name + " L" + s.level}</span>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    {competency.task_categories?.length > 0 &&
                                        <div className="chips-col">
                                            <div className="chips-col-title tooltip-parent">
                                                <div className="tooltip-title">
                                                    <FormattedMessage id="competences.categories" defaultMessage="Categories" />
                                                    {this.toolTipMessage(langService.messages['competences.commonly_used_in'], 6)}
                                                </div>
                                            </div>
                                            <div className="card-tags">
                                                {competency.task_categories.map((cc, idx) =>
                                                    <span className="no-hover" key={idx}>{cc.name}</span>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                            
                            </div>

                        </Card.Body>

                    </Card>

                    {competency.allow_voting

                        ?
                        <Tabs defaultActiveKey={1} className="competencies-tabs" activeKey={this.state.activeKey} onSelect={this.handleTabChange}>

                            <Tab eventKey={1} title={langService.messages["forms.labels.description"].toUpperCase()} className="c-tab">
                                <div className="contribute-wrapper">
                                    <div className="">
                                        <FormattedMessage id="competences.contribute_info" defaultMessage="Participate in consensus about the competency details by contributing or voting." />
                                    </div>
                                    {isAuthenticated
                                        ? <button className="btn btn-default btn-developers" onClick={() => this.setState({ modalAddDescriptionVisible: true })} >
                                            + <FormattedMessage id="competences.add_description" defaultMessage="Add Description" />
                                        </button>
                                        : <button className="btn btn-default btn-developers" onClick={() => history.push('/auth/login')}><FormattedMessage id="common.logintocontribute" defaultMessage="Login to contribute" /></button>
                                    }
                                </div>
                                <DescriptionVoting data={competency.competence_descriptions.list} />
                            </Tab>
                            <Tab eventKey={2} title={langService.messages["competences.parent"].toUpperCase()} className="c-tab">
                                <div className="contribute-wrapper">
                                    <div className="">
                                        <FormattedMessage id="competences.contribute_info" defaultMessage="Participate in consensus about the competency details by contributing or voting." />
                                    </div>
                                    {isAuthenticated
                                        ? <button className="btn btn-default btn-developers" onClick={() => this.setState({ modalAddParentVisible: true })}>
                                            + <FormattedMessage id="competences.add_parent" defaultMessage="Add Parent" />
                                        </button>
                                        : <button className="btn btn-default btn-developers" onClick={() => history.push('/auth/login')}><FormattedMessage id="common.logintocontribute" defaultMessage="Login to contribute" /></button>
                                    }
                                </div>
                                <ParentVoting data={competency.competence_parents.list} />
                            </Tab>
                            <Tab eventKey={3} title={langService.messages["competences.skills"].toUpperCase()} className="c-tab">
                                <div className="contribute-wrapper">
                                    <div className="">
                                        <FormattedMessage id="competences.contribute_info" defaultMessage="Participate in consensus about the competency details by contributing or voting." />
                                    </div>
                                    {isAuthenticated
                                        ? <button className="btn btn-default btn-developers" onClick={() => this.setState({ modalAddSkillsVisible: true })}>
                                            + <FormattedMessage id="competences.add_skill" defaultMessage="Add Skill" />
                                        </button>
                                        : <button className="btn btn-default btn-developers" onClick={() => history.push('/auth/login')}><FormattedMessage id="common.logintocontribute" defaultMessage="Login to contribute" /></button>
                                    }
                                </div>
                                <SkillVoting data={competency.competence_skills.list} />
                            </Tab>

                        </Tabs>
                        :
                        <div className="">
                            <FormattedMessage id="issuers.disabled_voting" defaultMessage="The issuer has disabled public voting on this competency." />
                        </div>
                    }

                </Container>
                { this.state.modalCreateCompetenceVisible && <ModalCreateANewCompetence visible={this.state.modalCreateCompetenceVisible} onClose={(x) => this.setState({ modalCreateCompetenceVisible: false })} />}
                { this.state.modalAddDescriptionVisible && <ModalAddCompetenceDescription visible={this.state.modalAddDescriptionVisible} onClose={() => this.setState({ modalAddDescriptionVisible: false })} level={competency.level} id={competency.id} />}
                { this.state.modalAddParentVisible && <ModalAddCompetenceParent visible={this.state.modalAddParentVisible} onClose={() => this.setState({ modalAddParentVisible: false })} id={competency.id} />}
                { this.state.modalAddSkillsVisible && <ModalAddCompetenceSkills visible={this.state.modalAddSkillsVisible} onClose={() => this.setState({ modalAddSkillsVisible: false })} id={competency.id} />}
                { this.state.modalEditCompetenceVisible && <ModalEditCompetence competency={competency} visible={this.state.modalEditCompetenceVisible} onClose={() => this.setState({ modalEditCompetenceVisible: false })} />}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.user.auth,
        role: state.user.info.settings.active_role_obj,
        isEmailVerified: state.user.info.isEmailVerified,
        competency: state.competence.selected,
    }
}

export default injectIntl(connect(mapStateToProps, {
    SelectCompetenceStart,
    GetCompetenceDescriptionsStart,
    GetCompetenceParentsStart,
    GetCompetenceSkillsStart
})(CompetenceDetailsPage));