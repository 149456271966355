import * as types from './types';
import { apiCon } from '../../../API';
// import { SelectMyTaskStart } from '../tasks';

//----------------------
// Actions Task Result Documents
//----------------------

//----------------------
// Actions Delete Task Result Document
//----------------------
export const DeleteTaskResultDocumentStart = (values, id) => async dispatch => {
    dispatch({ type: types.DELETE_TASK_RESULT_DOCUMENT_REQUEST });
    const json = JSON.stringify(values);
    const address = `/me/project/task/result/document/` + values.id;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.delete(address, json, settings)
        .then(response => {
            dispatch(DeleteTaskResultDocumentSuccess(id));
        })
        .catch(err => {
            dispatch(DeleteTaskResultDocumentFail(err.response));
        });

}
export const DeleteTaskResultDocumentSuccess = (id) => {
    const succ_msg = "Task Result Document deleted successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_TASK_RESULT_DOCUMENT_SUCCESS, payload: payload };
}
export const DeleteTaskResultDocumentFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Delete Task Result Document failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Delete Task Result Document failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.DELETE_TASK_RESULT_DOCUMENT_FAILURE, payload: payload };
}

//----------------------
// Actions Create Task Result Document
//----------------------
export const CreateTaskResultDocumentStart = (task_result_id, file) => async dispatch => {
    dispatch({ type: types.CREATE_TASK_RESULT_DOCUMENT_REQUEST });
    const formData = new FormData();
    formData.append('task_result', task_result_id);
    formData.append('document', file);
    const address = `/me/project/task/result/documents/`;
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    await apiCon.post(address, formData, config)
        .then(response => {
            dispatch(CreateTaskResultDocumentSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateTaskResultDocumentFail(err.response));
        });
}
export const CreateTaskResultDocumentSuccess = (data) => {
    const succ_msg = "Task Result Document created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.CREATE_TASK_RESULT_DOCUMENT_SUCCESS, payload: payload };
}
export const CreateTaskResultDocumentFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Ooops! Create Task Result Document failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create Task Result Document failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_TASK_RESULT_DOCUMENT_FAILURE, payload: payload };
}

