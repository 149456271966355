import { GET_MY_ISSUERS_SUCCESS, GET_AVAILABLE_ISSUERS_SUCCESS, GET_PUBLIC_ISSUERS_SUCCESS, GET_ISSUER_DETAIL_SUCCESS, CREATE_ISSUER_SUCCESS } from "../../actions/issuer/types";
import { CHANGE_ACTIVE_ROLE_SUCCESS } from "../../actions/role/types";



const INITIAL_STATE = {
    selected: {
        allow_competence_public_insert: true,
        allow_competence_voting: true,
        is_external: true,
        is_locked: false,
        issuer_prefix: '',
        name: '',
        owner: null,
        task_mint_type: 1
    },
    my: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    available: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    public: {
        count: 0,
        next: null,
        previous: null,
        results: []
    }
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MY_ISSUERS_SUCCESS:
            if (action.payload.list.count === 0) {
                return { ...state, my: INITIAL_STATE.my, selected: INITIAL_STATE.selected };
            } else if (action.payload.list.count === 1) {
                return { ...state, my: action.payload.list, selected: action.payload.list.results[0] };
            } else {
                return { ...state, my: action.payload.list };
            }
        case GET_AVAILABLE_ISSUERS_SUCCESS:
            return { ...state, available: action.payload.list };
        case GET_PUBLIC_ISSUERS_SUCCESS:
            return { ...state, public: action.payload.list };
        case GET_ISSUER_DETAIL_SUCCESS:
            return { ...state, selected: action.payload.list };
        case CREATE_ISSUER_SUCCESS:
            return { ...state, my: { results: [...state.my.results, action.payload.issuer] }, selected: action.payload.issuer };
        // case UPDATE_ISSUER_SUCCESS:
        //     return{ ...state, list: action.payload.list };            
        // case DELETE_ISSUER_SUCCESS:
        //     return{ ...state, list: action.payload.list };            
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            if (action.payload.settings?.active_role_obj?.type !== 'EDU') {
                return INITIAL_STATE;    
            }
            return state;
        default:
            return state;
    }
}