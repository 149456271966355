//Tasks
export const CHANGE_TASK_FILTERS = "CHANGE_TASK_FILTERS";

export const GET_TASKS_REQUEST = "GET_TASKS_REQUEST";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_FAILURE = "GET_TASKS_FAILURE";

export const SELECT_TASK_REQUEST = "SELECT_TASK_REQUEST";
export const SELECT_TASK_SUCCESS = "SELECT_TASK_SUCCESS";
export const SELECT_TASK_FAILURE = "SELECT_TASK_FAILURE";

export const DESELECT_TASK = 'DESELECT_TASK';

//MyTasks
export const GET_MY_TASKS_REQUEST = "GET_MY_TASKS_REQUEST";
export const GET_MY_TASKS_SUCCESS = "GET_MY_TASKS_SUCCESS";
export const GET_MY_TASKS_FAILURE = "GET_MY_TASKS_FAILURE";

export const SELECT_MY_TASK_REQUEST = "SELECT_MY_TASK_REQUEST";
export const SELECT_MY_TASK_SUCCESS = "SELECT_MY_TASK_SUCCESS";
export const SELECT_MY_TASK_FAILURE = "SELECT_MY_TASK_FAILURE";

export const GET_MY_TASK_HISTORY_REQUEST = "GET_MY_TASK_HISTORY_REQUEST";
export const GET_MY_TASK_HISTORY_SUCCESS = "GET_MY_TASK_HISTORY_SUCCESS";
export const GET_MY_TASK_HISTORY_FAILURE = "GET_MY_TASK_HISTORY_FAILURE";

export const DESELECT_MY_TASK = 'DESELECT_MY_TASK';

export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAILURE = "CREATE_TASK_FAILURE";

export const UPDATE_TASK_REQUEST = "UPDATE_TASK_REQUEST";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILURE = "UPDATE_TASK_FAILURE";

export const COMPLETE_SUBMIT_TASK_REQUEST = "COMPLETE_SUBMIT_TASK_REQUEST";
export const COMPLETE_SUBMIT_TASK_SUCCESS = "COMPLETE_SUBMIT_TASK_SUCCESS";
export const COMPLETE_SUBMIT_TASK_FAILURE = "COMPLETE_SUBMIT_TASK_FAILURE";
