import * as types from './types';
import { apiCon } from '../../../API';
//--------------------------------
// Actions Task Dispute Settlement
//--------------------------------

//------------------------------------------
// Actions Select Task Dispute Settlement
//------------------------------------------
export const SelectTaskDisputeSettlement = (task_id, showToast = true) => async dispatch => {
    dispatch({ type: types.SELECT_TASK_DISPUTE_SETTLEMENT_REQUEST });
    await apiCon.get('/me/project/task/dispute_settlement/' + task_id)
        .then(response => {
            dispatch(SelectTaskDisputeSettlementSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(SelectTaskDisputeSettlementFail(err.response, showToast));
        })
}
export const SelectTaskDisputeSettlementSuccess = (data) => async dispatch => {
    const payload = { succ_msg: "Successfully received Task Dispute Settlement info.", selected: data }
    dispatch({ type: types.SELECT_TASK_DISPUTE_SETTLEMENT_SUCCESS, payload: payload });
}
export const SelectTaskDisputeSettlementFail = (err, showToast) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Get Task Dispute Settlement failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Get Task Dispute Settlement failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, ...(showToast ? { toast_msg: toast_msg } : {}) };
    return { type: types.SELECT_TASK_DISPUTE_SETTLEMENT_FAILURE, payload: payload }
}

export const DeselectTaskDisputeSettlement = () => {
    const payload = { succ_msg: "Successfully deselected Task Dispute Settlement info." }
    return { type: types.DESELECT_TASK_DISPUTE_SETTLEMENT, payload: payload };
}

//---------------------------------------
// Actions Update Task Dispute Settlement
//---------------------------------------
// SEND_IN_NEGOTIATION, ACCEPT, ESCALATE
export const UpdateTaskDisputeSettlementStart = (task_id, action_type, last_task_dispute_settlement_change, proposed_percentage) => async dispatch => {
    dispatch({ type: types.UPDATE_TASK_DISPUTE_SETTLEMENT_REQUEST });
    const address = `/me/project/task/dispute_settlement/` + task_id;
    const values = {
        'task': task_id,
        'last_task_dispute_settlement_change': last_task_dispute_settlement_change,
        ...(proposed_percentage ? { 'proposed_percentage': proposed_percentage } : {}),
        ...(action_type ? { 'action_type': action_type } : {}),

    }
    const json = JSON.stringify(values);
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.patch(address, json, settings)
        .then(response => {
            dispatch(UpdateTaskDisputeSettlementSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateTaskDisputeSettlementFail(err.response));
        });
}
export const UpdateTaskDisputeSettlementSuccess = (data, showToast = true) => async dispatch => {
    const succ_msg = "Task Dispute Settlement updated successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: showToast ? succ_msg : null, selected: data };
    dispatch({ type: types.UPDATE_TASK_DISPUTE_SETTLEMENT_SUCCESS, payload: payload });
}
export const UpdateTaskDisputeSettlementFail = (err, showToast = true) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Update Task Dispute Settlement failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Update Task Dispute Settlement failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: showToast ? toast_msg : null, status: status };
    return { type: types.UPDATE_TASK_DISPUTE_SETTLEMENT_FAILURE, payload: payload };
}

//---------------------------------------
// Actions Create Task Dispute Settlement
//---------------------------------------
export const CreateTaskDisputeSettlementStart = (task_id, proposed_percentage) => async dispatch => {
    dispatch({ type: types.CREATE_TASK_DISPUTE_SETTLEMENT_REQUEST });
    const values = {
        'task': task_id,
        'proposed_percentage': proposed_percentage
    }
    const address = `/me/project/task/dispute_settlements/`;
    const config = { headers: { 'content-type': 'application/json' } }
    await apiCon.post(address, JSON.stringify(values), config)
        .then(response => {
            dispatch(CreateTaskDisputeSettlementSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateTaskDisputeSettlementFail(err.response));
        });
}
export const CreateTaskDisputeSettlementSuccess = (data) => {
    const succ_msg = "Task Dispute Settlement created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.CREATE_TASK_DISPUTE_SETTLEMENT_SUCCESS, payload: payload };
}
export const CreateTaskDisputeSettlementFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    var toast_msg = "Ooops! Create Task Dispute Settlement failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Create Task Dispute Settlement failed. " + err.data.error_first_message; };
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_TASK_DISPUTE_SETTLEMENT_FAILURE, payload: payload };
}

//---------------------------------------
// Actions Delete Task Dispute Settlement
//---------------------------------------
export const DeleteTaskDisputeSettlementStart = (task_id) => async dispatch => {
    dispatch({ type: types.DELETE_TASK_DISPUTE_SETTLEMENT_REQUEST });
    const address = `/me/project/task/dispute_settlement/` + task_id;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.delete(address, settings)
        .then(response => {
            dispatch(DeleteTaskDisputeSettlementSuccess(task_id));
        })
        .catch(err => {
            dispatch(DeleteTaskDisputeSettlementFail(err.response));
        });

}
export const DeleteTaskDisputeSettlementSuccess = (id) => {
    const succ_msg = "Task Dispute Settlement deleted successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_TASK_DISPUTE_SETTLEMENT_SUCCESS, payload: payload };
}
export const DeleteTaskDisputeSettlementFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Delete Task Dispute Settlement failed. Please try again.";
    if (err.data.error_first_message) { toast_msg = "Delete Task Dispute Settlement failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.DELETE_TASK_DISPUTE_SETTLEMENT_FAILURE, payload: payload };
}

