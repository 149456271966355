//Chat
export const GET_CHATS_REQUEST = "GET_CHATS_REQUEST";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const GET_CHATS_FAILURE = "GET_CHATS_FAILURE";

export const SELECT_CHAT_REQUEST = "SELECT_CHAT_REQUEST";
export const SELECT_CHAT_SUCCESS = "SELECT_CHAT_SUCCESS";
export const SELECT_CHAT_FAILURE = "SELECT_CHAT_FAILURE";

export const DESELECT_CHAT = 'DESELECT_CHAT';

export const SEND_MESSAGE_REQUEST = "SEND_MESSAGE_REQUEST";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";

//Web Socket 
export const WEB_SOCKET_USER_STATUS_CHANGED = "WEB_SOCKET_USER_STATUS_CHANGED";
export const WEB_SOCKET_SEND_MESSAGE = "WEB_SOCKET_SEND_MESSAGE";
export const WEB_SOCKET_RECEIVE_MESSAGE = "WEB_SOCKET_RECEIVE_MESSAGE";