import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import { connect } from 'react-redux';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { GetMyTasksStart, SelectMyTaskStart } from '../../core/actions/tasks';
import MyWorkSelectedTask from './selectedTask';
import { SelectTaskDisputeSettlement } from '../../core/actions/taskDisputeSettlement';
import ReactTooltip from 'react-tooltip';
import { langService } from '../../utils/languageService/index';


class MyWorkPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedKey: "0"
        };
    }

    componentDidMount() {
        this.props.GetMyTasksStart();
        const param = this.props.match.params;
        if (param && param.uid) {
            this.props.SelectMyTaskStart(param.uid);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // if (nextProps.selectedTask) {
        //     switch (nextProps.selectedTask.status) {

        //         case 'IN_NEGOTIATION':
        //             this.activateToggle("0");
        //             break;

        //         case 'ACTIVE':
        //             this.activateToggle("6");
        //             break;  

        //         case 'IN_PROGRESS':
        //             this.activateToggle("1");
        //             break;  

        //         case 'SUBMITTED':
        //             this.activateToggle("2");
        //             break;  

        //         case 'COMPLETED':
        //         case 'AUDITOR_CONFIRMED':
        //             this.activateToggle("3");
        //             break;                      

        //         case 'IN_AUDIT':
        //             this.activateToggle("4");
        //             break;  

        //         case 'FAILED':
        //         case 'REJECTED':
        //         case 'AUDITOR_FAILED':
        //             this.activateToggle("5");
        //             break;                      

        //         default:
        //             break;
        //     }
        // }
    }

    selectTask(id, project_id) {
        // history.location.replace("/my-work/" + id);
        this.props.SelectMyTaskStart(id);
        // this.props.SelectTaskDisputeSettlement(id, false);
    }

    activateToggle(eventKey) {
        this.setState({ selectedKey: eventKey });
    }

    getTaskCount(s1, s2, s3) {
        const { myTasks } = this.props;
        var count = 0;
        myTasks.forEach(t => {
            if (t.status === s1 || t.status === s2 || t.status === s3) {
                count++;
            }
        });
        return count;
    }

    toolTipMessage(message, n) {
        return (
            <>
                <div className="info-icon-wrap">
                    <img src="/assets/img/icon/info.png" data-tip data-for={ "competency" + n } alt="info-icon" width="13"></img>
                </div>
                <ReactTooltip id={ "competency" + n } place="right" type="dark" effect="solid">
                    <div className="tooltip-txt">
                        {message}
                    </div>
                </ReactTooltip>
            </>
        )
    }

    renderSidebar() {
        const { myTasks, selectedTask } = this.props;
        return (
            <div className="mypage-sidebar">

                <div className="sidebar-header">
                    <FormattedMessage id="projects.tasks" defaultMessage="Tasks" />
                </div>

                <Accordion defaultActiveKey="0">

                    <Accordion.Toggle eventKey="0" className={this.state.selectedKey === "0" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("0")}>
                        <div>
                            <div className="tooltip-parent">
                                <div className="tooltip-title">
                                    <div className="form-label"><FormattedMessage id="common.in_negotiation" defaultMessage="In negotiation" /></div>
                                    <div><span className="t-counts"> ({this.getTaskCount("IN_NEGOTIATION")})</span></div>
                                    {this.toolTipMessage(langService.messages['my_task_tooltip.negotiation'], 1)}
                                </div>
                            </div>
                        </div>
                        <i className={this.state.selectedKey === "0" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <div>
                            {myTasks.map((t, i) =>
                                (t.status === "IN_NEGOTIATION") &&
                                <div className={t.id === selectedTask.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTask(t.id)} key={i}>
                                    {t.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="6" className={this.state.selectedKey === "6" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("6")}>
                        <div>
                            <div className="tooltip-parent">
                                <div className="tooltip-title">
                                    <div className="form-label"><FormattedMessage id="common.confirmed" defaultMessage="Confirmed" /></div>
                                    <div><span className="t-counts"> ({this.getTaskCount("ACTIVE")})</span></div>
                                    {this.toolTipMessage(langService.messages['my_task_tooltip.confirmed'], 2)}
                                </div>
                            </div>
                        </div>
                        <i className={this.state.selectedKey === "6" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="6">
                        <div>
                            {myTasks.map((t, i) =>
                                (t.status === "ACTIVE") &&
                                <div className={t.id === selectedTask.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTask(t.id)} key={i}>
                                    {t.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="1" className={this.state.selectedKey === "1" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("1")}>
                        <div>
                            <div className="tooltip-parent">
                                <div className="tooltip-title">
                                    <div className="form-label"><FormattedMessage id="common.in_progress" defaultMessage="In progress" /></div>
                                    <div><span className="t-counts"> ({this.getTaskCount("IN_PROGRESS")})</span></div>
                                    {this.toolTipMessage(langService.messages['my_task_tooltip.in_progress'], 3)}
                                </div>
                            </div>
                        </div>
                        <i className={this.state.selectedKey === "1" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <div>
                            {myTasks.map((t, i) =>
                                (t.status === "IN_PROGRESS") &&
                                <div className={t.id === selectedTask.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTask(t.id)} key={i}>
                                    {t.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="2" className={this.state.selectedKey === "2" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("2")}>
                        <div>
                            <div className="tooltip-parent">
                                <div className="tooltip-title">
                                    <div className="form-label"><FormattedMessage id="common.submitted" defaultMessage="Submitted" /></div>
                                    <div><span className="t-counts"> ({this.getTaskCount("SUBMITTED")})</span></div>
                                    {this.toolTipMessage(langService.messages['my_task_tooltip.submitted'], 4)}
                                </div>
                            </div>
                        </div>
                        <i className={this.state.selectedKey === "2" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                        <div>
                            {myTasks.map((t, i) =>
                                (t.status === "SUBMITTED") &&
                                <div className={t.id === selectedTask.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTask(t.id)} key={i}>
                                    {t.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="3" className={this.state.selectedKey === "3" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("3")}>
                        <div>
                            <div className="tooltip-parent">
                                <div className="tooltip-title">
                                    <div className="form-label"><FormattedMessage id="common.completed" defaultMessage="Completed" /></div>
                                    <div><span className="t-counts"> ({this.getTaskCount("COMPLETED", "AUDITOR_CONFIRMED")})</span></div>
                                    {this.toolTipMessage(langService.messages['my_task_tooltip.completed'], 5)}
                                </div>
                            </div>
                        </div>
                        <i className={this.state.selectedKey === "3" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                        <div>
                            {myTasks.map((t, i) =>
                                (t.status === "COMPLETED" || t.status === "AUDITOR_CONFIRMED") &&
                                <div className={t.id === selectedTask.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTask(t.id)} key={i}>
                                    {t.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="4" className={this.state.selectedKey === "4" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("4")}>
                        <div>
                            <div className="tooltip-parent">
                                <div className="tooltip-title">
                                    <div className="form-label"><FormattedMessage id="common.in_audit" defaultMessage="In Audit" /></div>
                                    <div><span className="t-counts"> ({this.getTaskCount("IN_AUDIT", "PENDING_AUDIT", "IN_DISPUTE")})</span></div>
                                    {this.toolTipMessage(langService.messages['my_task_tooltip.in_audit'], 6)}
                                </div>
                            </div>
                        </div>
                        <i className={this.state.selectedKey === "4" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                        <div>
                            {myTasks.map((t, i) =>
                                (t.status === "IN_AUDIT" || t.status === "PENDING_AUDIT" || t.status === "IN_DISPUTE") &&
                                <div className={t.id === selectedTask.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTask(t.id)} key={i}>
                                    {t.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                    <Accordion.Toggle eventKey="5" className={this.state.selectedKey === "5" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("5")}>
                        <div>
                            <div className="tooltip-parent">
                                <div className="tooltip-title">
                                    <div className="form-label"><FormattedMessage id="common.rejected" defaultMessage="Rejected" /> / <FormattedMessage id="common.failed" defaultMessage="Failed" /></div>
                                    <div><span className="t-counts"> ({this.getTaskCount("FAILED", "REJECTED", "AUDITOR_FAILED")})</span></div>
                                    {this.toolTipMessage(langService.messages['my_task.tooltip.rejected'], 7)}
                                </div>
                            </div>
                        </div>
                        <i className={this.state.selectedKey === "5" ? "arrow down" : "arrow up"}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                        <div>
                            {myTasks.map((t, i) =>
                                (t.status === "FAILED" || t.status === "REJECTED" || t.status === "AUDITOR_FAILED") &&
                                <div className={t.id === selectedTask.id ? 'sideitem-task sideitem-task-active' : 'sideitem-task'}
                                    onClick={() => this.selectTask(t.id)} key={i}>
                                    {t.name}
                                </div>
                            )}
                        </div>
                    </Accordion.Collapse>

                </Accordion>

            </div>
        )
    }

    render() {
        const { selectedTask, selectedTaskProposal } = this.props;
        return (
            <Container className="mt-4">
                <Row>

                    <Col xs={12} md={3} className="my-sidebar pb-3">
                        {this.renderSidebar()}
                    </Col>

                    <Col xs={12} md={9}>
                        <div className="card my-content">
                            {selectedTask.id !== null && <MyWorkSelectedTask selectedTask={selectedTask} selectedTaskProposal={selectedTaskProposal} tabKey={this.props?.match?.params?.tabKey} />}
                        </div>
                    </Col>

                </Row>
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        myTasks: state.myWork.myTasks.list.results,
        selectedTask: state.myWork.myTasks.selected,
        selectedTaskProposal: state.myWork.myTaskProposals.list.results[0]
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetMyTasksStart,
    SelectMyTaskStart,
    SelectTaskDisputeSettlement
})(MyWorkPage));