import * as types from './types';
import { apiCon } from '../../../API';
import { langService } from '../../../utils/languageService';


//-------------------
//  Get SubRoles 
//-------------------
export const GetSubRolesStart = () => async dispatch => {
    dispatch({ type: types.GET_SUBROLES_REQUEST });
    await apiCon.get(`me/subroles/`)
        .then(response => {
            dispatch(GetSubRolesSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetSubRolesFail(err.response));
        })
}

export const GetSubRolesSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved subroles.";
    const payload = { succ_msg: succ_msg, list: data };
    dispatch({ type: types.GET_SUBROLES_SUCCESS, payload: payload });
}

export const GetSubRolesFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_SUBROLES_FAILURE, payload: payload };
}

//----------------------
// Create SubRole
//----------------------
export const CreateSubRoleStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_SUBROLE_REQUEST });
    await apiCon.post(`me/subroles/`, values)
        .then(response => {
            dispatch(CreateSubRoleSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateSubRoleFail(err.response));
        })
}

export const CreateSubRoleSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully created a new subrole.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, role: data };
    dispatch({ type: types.CREATE_SUBROLE_SUCCESS, payload: payload });
}

export const CreateSubRoleFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.create.error"] + ' ' + err.data.error_first_message;
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.CREATE_SUBROLE_FAILURE, payload: payload };
}

//----------------------
// Get SubRole details
//----------------------
export const GetSubRoleDetailsStart = (id) => async dispatch => {
    dispatch({ type: types.GET_SUBROLE_DETAIL_REQUEST });
    await apiCon.get('me/subrole/' + id)
        .then(response => {
            dispatch(GetSubRoleDetailsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetSubRoleDetailsFail(err.response));
        })
}

export const GetSubRoleDetailsSuccess = (data) => async dispatch => {
    const succ_msg = "Successfully recieved subrole details.";
    const payload = { succ_msg: succ_msg, selected: data };
    dispatch({ type: types.GET_SUBROLE_DETAIL_SUCCESS, payload: payload });
}

export const GetSubRoleDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.get.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, toast_msg: toast_msg, status: status };
    return { type: types.GET_SUBROLE_DETAIL_FAILURE, payload: payload };
}

//----------------------
// Update SubRole details
//----------------------
export const UpdateSubRoleDetailsStart = (values) => async dispatch => {
    dispatch({ type: types.UPDATE_SUBROLE_REQUEST });
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `me/subrole/`;
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateSubRoleDetailsSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateSubRoleDetailsFail(err.response));
        })
}

export const UpdateSubRoleDetailsSuccess = (data) => {
    const succ_msg = "Successfully updated role details.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, details: data };
    return { type: types.UPDATE_SUBROLE_SUCCESS, payload: payload };
}

export const UpdateSubRoleDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["role.detail.update.error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_SUBROLE_FAILURE, payload: payload };
}

//----------------------
// Delete SubRole
//----------------------
export const DeleteSubRoleStart = (roleId) => async dispatch => {
    dispatch({ type: types.DELETE_SUBROLE_REQUEST });
    const address = 'me/subrole/' + roleId;
    await apiCon.delete(address)
        .then(response => {
            dispatch(DeleteSubRoleSuccess(roleId));
        })
        .catch(err => {
            dispatch(DeleteSubRoleFail(err.response));
        });
}

export const DeleteSubRoleSuccess = (id) => {
    const succ_msg = "Role deleted successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_SUBROLE_SUCCESS, payload: payload };
}

export const DeleteSubRoleFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    var toast_msg = "Ooops! Delete Role failed. Please try again."
    if (err.data.error_first_message) { toast_msg = "Delete Role failed. " + err.data.error_first_message; };
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_SUBROLE_FAILURE, payload: payload };
}