import * as types from './types';
import { apiCon } from '../../../API';

//-------------------------------
// Actions Competence Description
//-------------------------------

//------------------------------------
// Actions Get Competence Descriptions
//------------------------------------
export const GetCompetenceDescriptionsStart = (limit = 100, competence_id, search) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCE_DESCRIPTIONS_REQUEST });
    var url = '/competence/descriptions/'
    const params = {
        'limit': limit,
        ...(competence_id ? { 'competence': competence_id } : {}),
        ...(search ? { 'search': search } : {}),
    }
    await apiCon.get(url, { params })
        .then(response => {
            dispatch(GetCompetenceDescriptionsSuccess(response.data));
        }
        )
        .catch(err => {
            dispatch(GetCompetenceDescriptionsFail(err.response));
        })
}
export const GetCompetenceDescriptionsChangePageStart = (url) => async dispatch => {
    dispatch({ type: types.GET_COMPETENCE_DESCRIPTIONS_REQUEST });
    await apiCon.get(url)
        .then(response => {
            dispatch(GetCompetenceDescriptionsSuccess(response.data, true));
        }
        )
        .catch(err => {
            dispatch(GetCompetenceDescriptionsFail(err.response));
        })
}
export const GetCompetenceDescriptionsSuccess = (data, add = false) => {
    const payload = { succ_msg: "Successfully received Competence Descriptions info.", list: data, add: add }
    return { type: types.GET_COMPETENCE_DESCRIPTIONS_SUCCESS, payload: payload };
}
export const GetCompetenceDescriptionsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = "Ooops! Get Competence Descriptions failed. Please try again.";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_COMPETENCE_DESCRIPTIONS_FAILURE, payload: payload }
}

//--------------------------------------
// Actions Create Competence Description
//--------------------------------------
export const CreateCompetenceDescriptionStart = (competenceId, description, sublevel) => async dispatch => {
    dispatch({ type: types.CREATE_COMPETENCE_DESCRIPTION_REQUEST });
    const json = JSON.stringify({
        'competence': competenceId,
        'description': description,
        'sub_level': sublevel
    });
    const address = `/competence/descriptions/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(CreateCompetenceDescriptionSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateCompetenceDescriptionFail(err.response));
        });

}
export const CreateCompetenceDescriptionSuccess = (data) => {
    const succ_msg = "Competence Description created successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, selected: data };
    return { type: types.CREATE_COMPETENCE_DESCRIPTION_SUCCESS, payload: payload };
}
export const CreateCompetenceDescriptionFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Create Competence Description failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_COMPETENCE_DESCRIPTION_FAILURE, payload: payload };
}

//----------------------------------------
// Actions Vote for Competence Description
//----------------------------------------
export const VoteForCompetenceDescriptionStart = (competenceDescriptionId, vote_option) => async dispatch => {
    dispatch({ type: types.VOTE_FOR_COMPETENCE_DESCRIPTION_REQUEST });
    const json = JSON.stringify({
        'action': vote_option ?? 'up',
    });
    const address = `/competence/description/${competenceDescriptionId}/votes/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.post(address, json, settings)
        .then(response => {
            dispatch(VoteForCompetenceDescriptionSuccess(response.data));
        })
        .catch(err => {
            dispatch(VoteForCompetenceDescriptionFail(err.response));
        });

}
export const VoteForCompetenceDescriptionSuccess = (data) => {
    const succ_msg = "Successfully voted for Competence Description!";
    const payload = { succ_msg: succ_msg, selected: data };
    return { type: types.VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS, payload: payload };
}
export const VoteForCompetenceDescriptionFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Vote for Competence Description failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.VOTE_FOR_COMPETENCE_DESCRIPTION_FAILURE, payload: payload };
}

//-----------------------------------------------
// Actions Delete Vote for Competence Description
//-----------------------------------------------
export const DeleteVoteForCompetenceDescriptionStart = (id) => async dispatch => {
    dispatch({ type: types.DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_REQUEST });
    const address = `/competence/description/${id}/votes/`;
    const settings = { headers: { 'Content-Type': 'application/json' } };
    await apiCon.delete(address, settings)
        .then(response => {
            dispatch(DeleteForCompetenceDescriptionSuccess(response.data));
        })
        .catch(err => {
            dispatch(DeleteForCompetenceDescriptionFail(err.response));
        });

}
export const DeleteForCompetenceDescriptionSuccess = (data) => {
    const succ_msg = "Successfully deleted vote for Competence Description!";
    const payload = { succ_msg: succ_msg, deleted: data };
    return { type: types.DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_SUCCESS, payload: payload };
}
export const DeleteForCompetenceDescriptionFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const status = err.status;
    const toast_msg = "Ooops! Delete Vote for Competence Description failed. Please try again."
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_VOTE_FOR_COMPETENCE_DESCRIPTION_FAILURE, payload: payload };
}