import * as types from './types';
import { apiCon } from '../../../API';
import { langService } from '../../../utils/languageService';


// GET /v1/api/me/role/issuers/ (Pregled issuerjev na prijavljeni roli, samo za izobraževalne institucije in kompetenc admina)
// GET /v1/api/me/issuers/ (Pregled issuerjev, ki so na voljo pri izboru vnosa kompetenc, v primeru edu vlog vsaj dva issuerja, sistemski in od edu, za ostale uporabnike zgolj sistemski)
// GET /v1/api/issuers/ (Javni pregled issuerjev, po katerih je možno filtriranje javnih kompetenc)
// POST /v1/api/me/role/issuers/ (Dodajanje issuerja competenc, samo za izobraževalne institucije in kompetenc admina)
// GET /v1/api/me/role/issuer/{0} (Pregled posameznega kompetence issuerja, samo za izobraževalne institucije in kompetenc admina)
// PATCH /v1/api/me/role/issuer/{0} (Urejanje posameznega kompetence issuerja, samo za izobraževalne institucije in kompetenc admina)
// DELETE /v1/api/me/role/issuer/{0} (Brisanje posameznega kompetence issuerja, samo za izobraževalne institucije in kompetenc admina)


//-------------------
//  Get My Issuers 
//-------------------
export const GetMyIssuersStart = () => async dispatch => {
    dispatch({ type: types.GET_MY_ISSUERS_REQUEST });
    await apiCon.get(`me/role/issuers/`)
        .then(response => {
            dispatch(GetMyIssuersSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetMyIssuersFail(err.response));
        })
}

export const GetMyIssuersSuccess = (data) => async dispatch => {
    const succ_msg = langService.messages["issuers.get_my_issuers_success"]; //"Successfully recieved my issuers.";
    const payload = { succ_msg: succ_msg, list: data };
    dispatch({ type: types.GET_MY_ISSUERS_SUCCESS, payload: payload });
}

export const GetMyIssuersFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["issuers.get_my_issuers_error"]; // "Failed to retrieve a list of issuers. "
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_MY_ISSUERS_FAILURE, payload: payload };
}


//-------------------
//  Get Available Issuers 
//-------------------
export const GetAvailableIssuersStart = () => async dispatch => {
    dispatch({ type: types.GET_AVAILABLE_ISSUERS_REQUEST });
    await apiCon.get(`me/issuers/`)
        .then(response => {
            dispatch(GetAvailableIssuersSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetAvailableIssuersFail(err.response));
        })
}

export const GetAvailableIssuersSuccess = (data) => async dispatch => {
    const succ_msg = langService.messages["issuers.get_available_issuers_success"]; //"Successfully recieved available issuers.";
    const payload = { succ_msg: succ_msg, list: data };
    dispatch({ type: types.GET_AVAILABLE_ISSUERS_SUCCESS, payload: payload });
}

export const GetAvailableIssuersFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["issuers.get_available_issuers_error"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_AVAILABLE_ISSUERS_FAILURE, payload: payload };
}

//-------------------
//  Get Public Issuers 
//-------------------
export const GetPublicIssuersStart = () => async dispatch => {
    dispatch({ type: types.GET_PUBLIC_ISSUERS_REQUEST });
    await apiCon.get(`issuers/`)
        .then(response => {
            dispatch(GetPublicIssuersSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetPublicIssuersFail(err.response));
        })
}

export const GetPublicIssuersSuccess = (data) => async dispatch => {
    const succ_msg = langService.messages["issuers.get_public_issuers_success"]; //"Successfully received public issuers.";
    const payload = { succ_msg: succ_msg, list: data };
    dispatch({ type: types.GET_PUBLIC_ISSUERS_SUCCESS, payload: payload });
}

export const GetPublicIssuersFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["issuers.get_public_issuers_fail"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_PUBLIC_ISSUERS_FAILURE, payload: payload };
}


//-------------------
//  Create Issuer 
//-------------------
export const CreateIssuerStart = (values) => async dispatch => {
    dispatch({ type: types.CREATE_ISSUER_REQUEST });
    await apiCon.post(`me/role/issuers/`, values)
        .then(response => {
            dispatch(CreateIssuerSuccess(response.data));
        })
        .catch(err => {
            dispatch(CreateIssuerFail(err.response));
        })
}

export const CreateIssuerSuccess = (data) => async dispatch => {
    const succ_msg = langService.messages["issuers.create_iusser_success"]; //"Successfully created a new issuer.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, issuer: data };
    dispatch({ type: types.CREATE_ISSUER_SUCCESS, payload: payload });
}

export const CreateIssuerFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["issuers.create_iusser_fail"]; //
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.CREATE_ISSUER_FAILURE, payload: payload };
}

//-------------------
//  Get Issuer Details
//-------------------
export const GetIssuerDetailsStart = (id) => async dispatch => {
    dispatch({ type: types.GET_ISSUER_DETAIL_REQUEST });
    await apiCon.get('me/role/issuer/' + id)
        .then(response => {
            dispatch(GetIssuerDetailsSuccess(response.data));
        })
        .catch(err => {
            dispatch(GetIssuerDetailsFail(err.response));
        })
}

export const GetIssuerDetailsSuccess = (data) => async dispatch => {
    const succ_msg = langService.messages["issuers.get_iusser_details_success"]; //"Successfully recieved subrole details.";
    const payload = { succ_msg: succ_msg, selected: data };
    dispatch({ type: types.GET_ISSUER_DETAIL_SUCCESS, payload: payload });
}

export const GetIssuerDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["issuers.get_iusser_details_fail"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.GET_ISSUER_DETAIL_FAILURE, payload: payload };
}

//-------------------
//  Update Issuer Details 
//-------------------
export const UpdateIssuerDetailsStart = (owner, values) => async dispatch => {
    dispatch({ type: types.UPDATE_ISSUER_REQUEST });
    const json = JSON.stringify(values);
    const config = { headers: { 'Content-Type': 'application/json' } };
    const address = `me/role/issuer/`+owner;
    await apiCon.patch(address, json, config)
        .then((response) => {
            dispatch(UpdateIssuerDetailsSuccess(response.data));
        })
        .catch(err => {
            dispatch(UpdateIssuerDetailsFail(err.response));
        })
}

export const UpdateIssuerDetailsSuccess = (data) => {
    const succ_msg = langService.messages["issuers.update_issuer_details_success"]; //"Successfully updated issuer details.";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, details: data };
    return { type: types.UPDATE_ISSUER_SUCCESS, payload: payload };
}

export const UpdateIssuerDetailsFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["issuers.update_issuer_details_fail"];
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.UPDATE_ISSUER_FAILURE, payload: payload };
}

//-------------------
//  Delete Issuer
//-------------------
export const DeleteIssuerStart = (roleId) => async dispatch => {
    dispatch({ type: types.DELETE_ISSUER_REQUEST });
    const address = 'me/role/issuer/' + roleId;
    await apiCon.delete(address)
        .then(response => {
            dispatch(DeleteIssuerSuccess(roleId));
        })
        .catch(err => {
            dispatch(DeleteIssuerFail(err.response));
        });
}

export const DeleteIssuerSuccess = (id) => {
    const succ_msg = langService.messages["issuers.delete_issuer_success"]; // "Issuer deleted successfully!";
    const payload = { succ_msg: succ_msg, toast_msg: succ_msg, deleted: id };
    return { type: types.DELETE_ISSUER_SUCCESS, payload: payload };
}

export const DeleteIssuerFail = (err) => {
    const err_msg = { errorType: err.data.error, detail: err.data.detail };
    const toast_msg = langService.messages["issuers.delete_issuer_fail"]; // "There was an error while trying to delete issuer. ";
    const status = err.status;
    const payload = { err_msg: err_msg, status: status, toast_msg: toast_msg };
    return { type: types.DELETE_ISSUER_FAILURE, payload: payload };
}