//MyTaskDisputeSettlementTopic
export const GET_TASK_DISPUTE_SETTLEMENT_TOPICS_REQUEST = "GET_TASK_DISPUTE_SETTLEMENT_TOPICS_REQUEST";
export const GET_TASK_DISPUTE_SETTLEMENT_TOPICS_SUCCESS = "GET_TASK_DISPUTE_SETTLEMENT_TOPICS_SUCCESS";
export const GET_TASK_DISPUTE_SETTLEMENT_TOPICS_FAILURE = "GET_TASK_DISPUTE_SETTLEMENT_TOPICS_FAILURE";

export const SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST = "SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST";
export const SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS = "SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS";
export const SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE = "SELECT_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE";

export const DESELECT_TASK_DISPUTE_SETTLEMENT_TOPIC = 'DESELECT_TASK_DISPUTE_SETTLEMENT_TOPIC';

export const CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST = "CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST";
export const CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS = "CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS";
export const CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE = "CREATE_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE";

export const UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST = "UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST";
export const UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS = "UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS";
export const UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE = "UPDATE_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE";

export const DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST = "DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_REQUEST";
export const DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS = "DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_SUCCESS";
export const DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE = "DELETE_TASK_DISPUTE_SETTLEMENT_TOPIC_FAILURE";