import React from 'react';
import './index.scss';
import './DropdownUserMenu.scss';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import { LogoutStart } from '../../core/actions/auth';
import { ChangeRoleStart } from '../../core/actions/role';

class DropdownUserMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = { isOpen: false }
    }

    handleOpen = () => {
        this.setState({ isOpen: true })
    }

    handleClose = () => {
        this.setState({ isOpen: false })
    }

    render() {
        let { user: { settings, roles }, hasRole } = this.props;

        return (
            <Dropdown as={Nav.Item}
                onMouseEnter={this.handleOpen}
                onMouseLeave={this.handleClose}
                show={this.state.isOpen}
            >
                <Dropdown.Toggle as={Nav.Link} className={this.props.transparent ? "nav-link-landing" : "nav-link"}>
                    {settings?.active_role_obj?.profile_image
                        ?
                        <img className="nav-user-img" src={settings.active_role_obj.profile_image} alt="Active profile thumb"></img>
                        :
                        <span className={`${settings?.active_role_obj?.type ? 'nav-' + settings.active_role_obj.type.toLowerCase() : 'nav-dev'}`}>{settings?.active_role_obj?.name?.charAt(0)?.toUpperCase()}</span>
                    }
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right">

                    {/* Active role */}
                    {hasRole &&
                        <>
                            <div className="dd-active">
                                {settings?.active_role_obj?.profile_image
                                    ?
                                    <img className="dd-role-withimg-active" src={settings.active_role_obj.profile_image} alt="Active profile thumb"></img>
                                    :
                                    <span className={`dd-${settings?.active_role_obj?.type?.toLowerCase()}-img-active`}>{settings?.active_role_obj?.name?.charAt(0)?.toUpperCase()}</span>
                                }
                                <div style={{ width: "135px" }}>
                                    <span className="title">{settings?.active_role_obj?.name}</span>
                                    <span className="subtitle pb-2"><FormattedMessage id={"roles.name." + settings.active_role_obj?.type} defaultMessage="Developer" /></span>
                                    <button className="btn btn-default btn-developers btn-block pr-0 pl-0 pt-1 pb-1" onClick={() => history.push("/profile")}>
                                        <FormattedMessage id="DropdownUserMenu.myprofile" defaultMessage="My Profile" />
                                    </button>
                                </div>
                            </div>
                        </>
                    }

                    {/* Roles */}
                    {(settings?.active_role_obj?.type !== 'EAS' && settings?.active_role_obj?.type !== 'EAP' && settings?.active_role_obj?.type !== 'EAC') && 
                    <>
                    <Dropdown.Divider />
                    <div className="dd-roles">
                        {
                            roles?.filter(role => role.id !== settings?.active_role)?.map((role) =>
                                <div onClick={() => this.props.ChangeRoleStart(role.id)} className="dd-role" key={role.id}>
                                    {role.profile_image
                                        ?
                                        <img className="dd-role-withimg" src={role.profile_image} alt="Role thumb"></img>
                                        :
                                        <span className={`dd-${role?.type?.toLowerCase()}-img`}>{role.name.charAt(0).toUpperCase()}</span>
                                    }
                                    <div>
                                        <span className="title">{role.name}</span>
                                        <span className="subtitle"><FormattedMessage id={"roles.name." + role?.type} defaultMessage="Developer" /></span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            roles?.length < 4 &&
                            <button className="btn btn-default btn-generic btn-block pt-1 pb-1" onClick={() => history.push("/auth/register-role")}>+ <FormattedMessage id="DropdownUserMenu.addanotherprofile" defaultMessage="Add another profile" /></button>
                        }
                    </div>
                    </>
                    }


                    {hasRole &&
                        <>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => history.push("/settings")}>
                            <FormattedMessage id="DropdownUserMenu.settings" defaultMessage="Settings" />
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={() => history.push("/messages")}>
                            <FormattedMessage id="DropdownUserMenu.balances" defaultMessage="Balances" />
                        </Dropdown.Item> */}
                        {/* <Dropdown.Item onClick={() => history.push("/messages")}>
                            <FormattedMessage id="DropdownUserMenu.ndas" defaultMessage="NDA's" />
                        </Dropdown.Item> */}
                        {/* <Dropdown.Item onClick={() => history.push("/messages")}>
                            <FormattedMessage id="DropdownUserMenu.contracts" defaultMessage="Contracts" />
                        </Dropdown.Item> */}
                        </>
                    }

                    <Dropdown.Divider />

                    <Dropdown.Item onClick={() => history.push("/help")}>
                        <FormattedMessage id="DropdownUserMenu.help" defaultMessage="Help" />
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.props.LogoutStart()}><FormattedMessage id="nav.labels.logout" defaultMessage="logout" /></Dropdown.Item>
                    {/* <Dropdown.Item><pre><code><small>{JSON.stringify(settings, 0, 2)}</small></code></pre></Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown >
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.info,
        hasRole: state.user.info.settings.active_role
    }
}

export default connect(mapStateToProps, { LogoutStart, ChangeRoleStart })(DropdownUserMenu);