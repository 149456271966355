import { combineReducers } from "redux";
import userInfoReducer from './userInfoReducer';
import userLocationReducer from './userLocationReducer';
import authInfoReducer from './authInfoReducer';
import googleAuthReducer from './googleAuthReducer';
import userSubRoleReducer from "./userInfoReducer/userSubRoleReducer";

export default combineReducers({
    auth: authInfoReducer,
    google_auth: googleAuthReducer,
    info: userInfoReducer,
    subroles: userSubRoleReducer,
    location: userLocationReducer,
})
