import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { injectIntl } from 'react-intl';

class HelpPage extends React.Component {

    render() {
        return (
            <Container className="mt-4">
            <Row>
                <Col className="text-center">
                    <h1>How can we help you?</h1>
                </Col>
            </Row>
            </Container>
        )
    }
}

export default injectIntl(connect(null, { })(HelpPage));