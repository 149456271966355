import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import './index.scss';


const AlphabetIndex = props => {
  const [selectedChar, setSelected] = useState('A');

  useEffect( () => {
    setSelected(props.starts_with);
  }, [props.starts_with])

  const onAlphabetClick = (e) => {
    setSelected(e);
    if (selectedChar === e) {
      setSelected('');
      props.onAlphabetClick('');  
    } else  {
      setSelected(e);
      props.onAlphabetClick(e);
    }
  }

  const prepareAlphabets = () => {
    let result = [];
    for (let i = 65; i < 91; i++) {
      result.push(
        <button 
          key={i} 
          className={selectedChar === String.fromCharCode(i) ? "alphabet-index-item active" : "alphabet-index-item" } 
          onClick={e => onAlphabetClick(e.target.value)} 
          value={String.fromCharCode(i)}>{String.fromCharCode(i)}
        </button>
      )
    }
    return result;
  }

  return (
    <div className="alphabet-index-container mt-2">
      {prepareAlphabets()}
    </div>
  );

}

const mapStateToProps = state => {
  return {
    starts_with: state.competence.list.filters.starts_with,
  }
}
// export default AlphabetIndex;
export default injectIntl(connect(mapStateToProps, {})(AlphabetIndex));