//Competence PARENTs
export const GET_COMPETENCE_PARENTS_REQUEST = "GET_COMPETENCE_PARENTS_REQUEST";
export const GET_COMPETENCE_PARENTS_SUCCESS = "GET_COMPETENCE_PARENTS_SUCCESS";
export const GET_COMPETENCE_PARENTS_FAILURE = "GET_COMPETENCE_PARENTS_FAILURE";

export const CREATE_COMPETENCE_PARENT_REQUEST = "CREATE_COMPETENCE_PARENT_REQUEST";
export const CREATE_COMPETENCE_PARENT_SUCCESS = "CREATE_COMPETENCE_PARENT_SUCCESS";
export const CREATE_COMPETENCE_PARENT_FAILURE = "CREATE_COMPETENCE_PARENT_FAILURE";

export const VOTE_FOR_COMPETENCE_PARENT_REQUEST = "VOTE_FOR_COMPETENCE_PARENT_REQUEST";
export const VOTE_FOR_COMPETENCE_PARENT_SUCCESS = "VOTE_FOR_COMPETENCE_PARENT_SUCCESS";
export const VOTE_FOR_COMPETENCE_PARENT_FAILURE = "VOTE_FOR_COMPETENCE_PARENT_FAILURE";

export const DELETE_VOTE_FOR_COMPETENCE_PARENT_REQUEST = "DELETE_VOTE_FOR_COMPETENCE_PARENT_REQUEST";
export const DELETE_VOTE_FOR_COMPETENCE_PARENT_SUCCESS = "DELETE_VOTE_FOR_COMPETENCE_PARENT_SUCCESS";
export const DELETE_VOTE_FOR_COMPETENCE_PARENT_FAILURE = "DELETE_VOTE_FOR_COMPETENCE_PARENT_FAILURE";