import React from 'react';
import '../../../assets/styles/partials/_auth.scss';
import history from '../../../utils/routes/history'
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ActivateEmailStart } from './../../../core/actions/auth/index';
import { createLoadingSelector } from '../../../API/selector';
import { createErrorMessageSelector } from './../../../API/selector';

class ActivateAccountForm extends React.Component {

    state = {
        validToken: false,
        fetched: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoading && !this.props.isLoading) {
            this.setState({ fetched: true })
        }
    }

    componentDidMount() {
        const token = this.props.match.params.token;
        if (!token) return;
        this.props.ActivateEmailStart({ key: token })
    }

    render() {
        return (
            <>
                <div id="overlay">
                    <img src="/assets/img/icon/lightbulb.svg" alt="Idea"></img>
                </div>
                <Container className="flex-center pt-65">
                    <div className="auth">
                        {this.state.fetched ?
                            this.props.user.isEmailVerified
                                ?
                                <>
                                    <p><span className="underline">
                                        <FormattedMessage id="activate_profile.awesome" defaultMessage="Awesome!" />
                                    </span></p><br />
                                    <p>
                                        <FormattedMessage id="activate_profile.desc" defaultMessage="Your account has been activated!" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="activate_profile.desc_long" defaultMessage="You can now login and continue with setting up your profile." />
                                    </p>
                                    <div onClick={() => history.push("/auth/login")} className="btn btn-default btn-generic">
                                        <FormattedMessage id="nav.labels.login" defaultMessage="Login" />
                                    </div>
                                </>
                                :
                                <>
                                    <p><span className="underline">
                                        <FormattedMessage id="activate_profile.error" defaultMessage="Ooops!" />
                                    </span></p><br />
                                    <p>
                                        <FormattedMessage id="activate_profile.error_desc" defaultMessage="Token is invalid or is expired!" />
                                    </p>
                                    <p>
                                        <FormattedMessage id="activate_profile.error_desc_long" defaultMessage="Please login, go to your profile settings and send the activation email again." />
                                    </p>
                                    <div onClick={() => history.push("/auth/login")} className="btn btn-default btn-generic">
                                        <FormattedMessage id="nav.labels.login" defaultMessage="Login" />
                                    </div>
                                </> : <div>Loading...</div>
                        }
                    </div>
                </Container>
            </>
        )
    }
}

const loadingSelector = createLoadingSelector(['ACTIVATE_EMAIL']);
const errorSelector = createErrorMessageSelector(['ACTIVATE_EMAIL']);
const mapStateToProps = state => {
    return {
        user: state.user.info,
        isInvalid: errorSelector(state),
        isLoading: loadingSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps, { ActivateEmailStart })(ActivateAccountForm));
