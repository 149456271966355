import axios from 'axios';
import store from '../core/store';
import {
  TOKEN_REFRESH_REQUEST,
  TOKEN_REFRESH_SUCCESS,
  TOKEN_REFRESH_FAILURE
} from '../core/actions/auth/types';
import history from '../utils/routes/history';
import { LogoutStart } from '../core/actions/auth';
import { 
  REACT_APP_BASE_BACKEND_URL,
  REACT_APP_API_URL,
  REACT_APP_LOCATION_API_URL,
  REACT_APP_GOOGLE_CLIENT_ID
} from '../utils/common/env-variables';

export const baseUrlHttp = REACT_APP_BASE_BACKEND_URL(); // process.env.
export const apiUrlHttp = REACT_APP_API_URL();
export const locationApiURL = REACT_APP_LOCATION_API_URL(); // process.env.
export const google_client_id = REACT_APP_GOOGLE_CLIENT_ID(); // process.env.

export var axiosInterceptor = null;
export var apiConInterceptor = null;

export function setToken(access_token, refresh_token) {
  localStorage.setItem('django_access_token', access_token);
  localStorage.setItem('django_refresh_token', refresh_token);
  con.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
  apiCon.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
  if (!axiosInterceptor)
    axiosInterceptor = con.interceptors.response.use(response => response, error => refreshTokenInterceptor(error));
  if (!apiConInterceptor)
    apiConInterceptor = apiCon.interceptors.response.use(response => response, error => refreshTokenInterceptor(error));
}

export function changeLanguageHeader(lang) {
  con.defaults.headers.common['Accept-Language'] = lang;
  apiCon.defaults.headers.common['Accept-Language'] = lang;
  conWithoutToken.defaults.headers.common['Accept-Language'] = lang;
}

export function removeToken() {
  localStorage.removeItem('django_access_token');
  localStorage.removeItem('django_refresh_token');
  localStorage.removeItem('RememberMe');
  con.defaults.headers.common['Authorization'] = null;
  apiCon.defaults.headers.common['Authorization'] = null;
  if (axiosInterceptor)
    con.interceptors.response.eject(axiosInterceptor);
  if (apiConInterceptor)
    apiCon.interceptors.response.eject(apiConInterceptor);
}

function forceLogout(err_msg) {
  store.dispatch({
    type: TOKEN_REFRESH_FAILURE,
    payload: { err_msg: err_msg }
  });
  removeToken();
  LogoutStart();
  history.push("/auth/login");
}

export function refreshTokenInterceptor(error) {
  if (error.response?.status === 403 && error.response?.data?.error_first_message === 'Please verify email before using this API call.') {
    history.push("/dashboard");
    return Promise.reject(error);
  }
  else if (error.response?.status === 401 && error.response?.data?.error === 'InvalidToken') {
    store.dispatch({ type: TOKEN_REFRESH_REQUEST });
    const rememberMe = localStorage.getItem('RememberMe');
    const refresh_token = localStorage.getItem('django_refresh_token');

    if (rememberMe === 'true' && refresh_token) {
      const originalRequest = error.config;
      const con = axios.create({
        baseURL: baseUrlHttp,
      });
      return con
        .post('/auth/token/refresh/', { refresh: refresh_token })
        .then(({ data }) => {
          const token = { access_token: data.access, refresh_token: refresh_token };
          store.dispatch({
            type: TOKEN_REFRESH_SUCCESS,
            payload: { token, succ_msg: "Fetch refresh token successfull." }
          });
          setToken(token.access_token, token.refresh_token)
          originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
          return axios(originalRequest);
        })
        .catch(err => {
          const err_msg = { errorType: err?.response?.data?.error, detail: err?.response?.data?.detail };
          forceLogout(err_msg);
          return Promise.reject(err);
        });
    }
    else {
      const err_msg = { errorType: 'Fetch refresh token failed.', detail: 'no Detail Provided' };
      forceLogout(err_msg);
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};

export const apiCon = axios.create({
  baseURL: apiUrlHttp,
  timeout: 40000,
})

export const con = axios.create({
  baseURL: baseUrlHttp,
  timeout: 40000,
})

export const conWithoutToken = axios.create({
  baseURL: baseUrlHttp,
  timeout: 40000,
})

export default con;
