import { LOGIN_SUCCESS, REGISTER_SUCCESS, LOGOUT_SUCCESS, TOKEN_REFRESH_FAILURE } from '../../actions/auth/types'
import { CHANGE_ACTIVE_ROLE_SUCCESS } from '../../actions/role/types';
import { SELECT_MY_TASK_DISPUTE_CASE_SUCCESS, DESELECT_MY_TASK_DISPUTE_CASE, GET_MY_TASK_DISPUTE_CASES_SUCCESS, UPDATE_TASK_DISPUTE_CASES_SUCCESS } from '../../actions/taskDisputeCases/types';
import { WEB_SOCKET_TASK_DISPUTE_CREATED } from '../../actions/notification/types';

const INITIAL_STATE = {
    selected: {
        id: null,
        title: '',
        task: {},
        client: {},
        freelancer: {},
        available_actions: [],
        status: '',
        last_action_type: '',
        decision_comment: '',
        confirmed_percentage: '',
        auditor: null
    },
    list: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MY_TASK_DISPUTE_CASES_SUCCESS:
            return { ...state, list: action.payload.list };
        case SELECT_MY_TASK_DISPUTE_CASE_SUCCESS:
            return { ...state, selected: action.payload.selected };
        case DESELECT_MY_TASK_DISPUTE_CASE:
            return { ...state, selected: INITIAL_STATE.selected };
        case UPDATE_TASK_DISPUTE_CASES_SUCCESS:
            return {
                ...state, selected: action.payload.selected,
                list: {
                    ...state.list, results: state?.list?.results?.map(taskDispute =>
                        taskDispute.id === action.payload.selected.id ?
                            action.payload.selected : taskDispute)
                }
            };
        case WEB_SOCKET_TASK_DISPUTE_CREATED:
            return {
                ...state, list: {
                    ...state.list, count: state.list.count + 1,
                    results: [action.payload.selected].concat(state?.list?.results)
                }
            }
        case CHANGE_ACTIVE_ROLE_SUCCESS:
            return { ...INITIAL_STATE }
        case LOGIN_SUCCESS:
            return { ...INITIAL_STATE };
        case REGISTER_SUCCESS:
            return { ...INITIAL_STATE };
        case TOKEN_REFRESH_FAILURE:
            return { ...INITIAL_STATE };
        case LOGOUT_SUCCESS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
}