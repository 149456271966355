import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import history from '../../utils/routes/history';


class ContributedBy extends React.Component {

    render() {
        const { item } = this.props;
        return (
            <div>
                <small>
                    <FormattedMessage id="forms.labels.contributed_by" defaultMessage="Contributed by" />&nbsp;
                    <strong>
                        <span className={item.contributed_by.type !== 'SYS' ? `link-hover` : 'mc4'} onClick={() => item.contributed_by.type !== 'SYS' ? history.push("/user/" + item.contributed_by?.id) : null} >
                            {item.contributed_by?.name}
                        </span>
                    </strong>
                </small>
            </div>
        )
    }
}

export default injectIntl(connect(null, {})(ContributedBy));
