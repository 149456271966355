import React from 'react'
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import './index.scss';

class LandingPage extends React.Component {

    render() {
        return (
            <>
                <Jumbotron fluid className="jumbo text-center">
                    <Container className="landing-content">
                        <h1><FormattedMessage id="landing.slogan" defaultMessage="The place where great ideas are born." /></h1>
                        <Row className="pt-5">
                            <Col className="text-right pr-5 mb-5 circle">
                                <div className="dot-l text-center">
                                    <span className="licon icon-investor"></span><br />
                                    <span className="circle-text"><FormattedMessage id="landing.forEmployers" defaultMessage="FOR CLIENTS" /></span>
                                </div>
                            </Col>
                            <Col className="text-left pl-5 circle">
                                <div className="dot-r text-center">
                                    <span className="licon icon-developers"></span><br />
                                    <span className="circle-text"><FormattedMessage id="landing.forDevelopers" defaultMessage="FOR DEVELOPERS" /></span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
                <Container>
                </Container>
            </>
        )
    }
}

export default injectIntl(connect(null, {})(LandingPage));