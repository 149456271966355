import { CHANGE_LANG } from './types';
import { changeLanguage } from './../../../utils/languageService';
import { changeLanguageHeader } from '../../../API';


const SetLocalStorageLang = lang => localStorage.setItem('lang', lang);

export const ChangeLangAction = lang => {
    SetLocalStorageLang(lang);
    changeLanguage(lang);
    changeLanguageHeader(lang);
    return {
        type: CHANGE_LANG,
        payload: lang
    }
}