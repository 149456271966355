import { debounce } from 'lodash';

// values: any[];
// actionFn: () => void;
// params: object;
export const debounceFreeInputFields = debounce((values, actionFn, params) => {
    actionFn(params);
}, 500)

export const debounceCompetenceFreeInputFields = debounce((values, actionFn, params) => {
    actionFn(params);
}, 500)