import React from 'react';
import moment from 'moment';
import './projectOverviewWithEdit.scss';
import { ModalAddDocuments } from './modalAddDocuments';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { DateRangePicker } from 'react-dates';
import EasyEdit, { Types } from 'react-easy-edit';
import { UpdateProjectStart } from '../../core/actions/projects';
import { GetProjectCategoriesStart } from '../../core/actions/projectCategories';
import { getFileExtension, getFileName, contains } from '../../utils/common';
import { CreateDocumentsStart } from '../../core/actions/documents';
import ModalFileViewer from '../../components/Common/ModalFileViewer/modalFileViewer';
import { langService } from './../../utils/languageService';
import AutosizeTextarea from './../Common/AutosizeTextarea/index';

class ProjectOverviewWithEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focusedInput: null,
            startDate: moment(props.project.estimated_start),
            endDate: moment(props.project.estimated_end),
            modalAddDocumentsVisible: false,
            modalFileViewerVisible: false,
            selectedDocument: null
        };
    }

    componentDidMount() {
        this.props.GetProjectCategoriesStart();
    }

    onDatesChange(startDate, endDate) {
        this.setState({ startDate: moment(this.state.endDate).startOf('day'), endDate: moment(this.state.endDate).startOf('day') }, () => {
            if (startDate !== null && endDate !== null) {
                this.saveProject();
            }
        });
    }

    generateCategoryOptions = () => {
        const res = [];
        if (this.props.categories.results.length > 0) {
            this.props.categories.results.forEach(cat => {
                res.push({ label: cat.name, value: cat.id })
            });
        }
        return res;
    };

    openModalAddDocuments() {
        this.setState({ modalAddDocumentsVisible: true });
    }

    onModalCancel() {
        this.setState({ modalAddDocumentsVisible: false });
    }

    onModalSubmit(files) {
        this.props.CreateDocumentsStart(files, this.props.project.id, null)
        this.setState({ modalAddDocumentsVisible: false });
    }

    saveProject = () => {
        const params = {
            id: this.props.project.id,
            name: this.props.project.name,
            short_description: this.props.project.short_description,
            description: this.props.project.description,
            estimated_start: this.state.startDate.toISOString(),
            estimated_end: this.state.endDate.toISOString(),
            estimated_budget: this.props.project.estimated_budget,
            visibility: this.props.project.visibility,
            status: this.props.project.status,
            category: this.props.project.category.id
        }
        this.props.UpdateProjectStart(params);
    }

    publishProject() {
        this.props.UpdateProjectStart({
            id: this.props.project.id,
            status: 'PUBLISHED',
        });
    }

    // Modal File Viewer
    openFileViewer(file) {
        this.setState({ selectedDocument: file, modalFileViewerVisible: true });
    }

    renderModalFileViewer() {
        if (this.state.modalFileViewerVisible) {
            return (
                <ModalFileViewer visible={this.state.modalFileViewerVisible} file={this.state.selectedDocument} onClose={() => this.hideModalFileViewer()} />
            )
        }
    }
    hideModalFileViewer() {
        this.setState({ modalFileViewerVisible: false });
    }

    isProjectEditable = () => {
        const { project } = this.props;
        const disabledEdits = ['COMPLETED', 'REJECTED'];

        if (disabledEdits.includes(project.status))
            return false;

        return true;
    }

    renderStatus(project) {
        if (project.status === "DRAFT") {
            return (
                <Col>
                    <button className="btn btn-default btn-projects btn-create-project" onClick={() => this.publishProject()}>
                        <FormattedMessage id="my_projects.publish" defaultMessage="Publish Project" />
                    </button>
                </Col>
            )
        } else if (project.status === "PUBLISHED") {
            return (
                <Col>
                    <div className="form-labels">
                        <FormattedMessage id="forms.labels.status" defaultMessage="Status" />
                    </div>
                    <EasyEdit
                        type={Types.RADIO}
                        value={project.status !== null ? langService.messages[`common.${project.status.toLowerCase()}`] : langService.messages['common.draft']}
                        onSave={(value) => { project.status = value; this.saveProject() }}
                        onCancel={this.cancel}
                        allowEdit={this.isProjectEditable() && project.milestones.list.some(m => m.tasks.length > 0)}
                        options={[
                            { label: langService.messages['common.draft'], value: "DRAFT" },
                            { label: langService.messages['common.published'], value: "PUBLISHED" }
                        ]}
                    />
                </Col>
            )
        } else {
            return (
                <Col>
                    <div className="form-labels"><FormattedMessage id="forms.labels.status" defaultMessage="Status" /></div>
                    <label>{langService.messages[`common.${project.status.toLowerCase()}`]}</label>
                </Col>
            )
        }
    }

    render() {
        const { project } = this.props;
        return (
            <div className="p-overview-content">
                <Row>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.project_name" defaultMessage="Project Name" />
                        </div>
                        <EasyEdit
                            type={Types.TEXT}
                            onSave={(value) => { project.name = value; this.saveProject() }}
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            value={project.name}
                            allowEdit={this.isProjectEditable()}
                        />
                    </Col>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.category" defaultMessage="Category" />
                        </div>
                        <EasyEdit
                            type={Types.SELECT}
                            options={this.generateCategoryOptions()}
                            onSave={(value) => {
                                this.props.project.category.id = value;
                                this.saveProject()
                            }
                            }
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            value={project.category.id}
                            allowEdit={this.isProjectEditable()}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.short_description" defaultMessage="Short description" /> (500)
                        </div>
                        <EasyEdit
                            type={Types.TEXTAREA}
                            onSave={(value) => { project.short_description = value; this.saveProject() }}
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            attributes={{ rows: "2" }}
                            value={project.short_description}
                            allowEdit={this.isProjectEditable()}
                            editComponent={<AutosizeTextarea
                                rows={3}
                                input="short_description"
                                edit={true}
                            />}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.description" defaultMessage="Description" /> (5000)
                        </div>
                        <EasyEdit
                            type={Types.TEXTAREA}
                            onSave={(value) => { project.description = value; this.saveProject() }}
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            attributes={{ rows: "5" }}
                            value={project.description}
                            allowEdit={this.isProjectEditable()}
                            editComponent={<AutosizeTextarea
                                rows={5}
                                input="description"
                                edit={true}
                            />}
                        />
                    </Col>
                </Row>
                <br />
                {
                    project?.documents.length > 0
                        ?
                        <>
                            <Row>
                                <Col>
                                    <div className="form-labels">
                                        <FormattedMessage id="forms.labels.documents" defaultMessage="Documents" />
                                    </div>
                                    <div className="p-documents">
                                        {
                                            project.documents.map((doc, c) =>
                                                <div className="attachment-box" key={c}>
                                                    <div className={`file file-${contains(['docx', 'ppt', 'jpg', 'png'], getFileExtension(doc.document)) ? 'generic' : getFileExtension(doc.document)}`}></div>
                                                    {/* <div className="download-link" onClick={() => this.downloadFile(doc.document)}>{getFileName(doc.document)}</div> */}
                                                    <div className="download-link" onClick={() => this.openFileViewer(doc.document)} alt="project attachment">{getFileName(doc.document)}</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {this.renderModalFileViewer()}
                                    {this.isProjectEditable() &&
                                        <div className="p-adddocs" onClick={() => this.openModalAddDocuments()} >
                                            + <FormattedMessage id="forms.labels.add_documents" defaultMessage="Add Documents" />
                                        </div>
                                    }
                                    <ModalAddDocuments onCancel={() => this.onModalCancel()} onFilesChanged={(f) => this.onModalSubmit(f)} visible={this.state.modalAddDocumentsVisible} />
                                </Col>
                            </Row>
                            <br />
                        </>
                        :
                        <Row className="mb-4">
                            <Col>
                                <div className="form-labels">
                                    <FormattedMessage id="forms.labels.documents" defaultMessage="Documents" />
                                </div>
                                {this.isProjectEditable()
                                    ?
                                    <div className="p-adddocs" onClick={() => this.openModalAddDocuments()}>
                                        + <FormattedMessage id="forms.labels.add_documents" defaultMessage="Add Documents" />
                                    </div>
                                    :
                                    <div>
                                        No documents
                                </div>
                                }
                                <ModalAddDocuments onCancel={() => this.onModalCancel()} onFilesChanged={(f) => this.onModalSubmit(f)} visible={this.state.modalAddDocumentsVisible} />
                            </Col>
                        </Row>
                }
                <Row>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.estimated_start_end" defaultMessage="Estimated start/end date" />
                        </div>
                        <DateRangePicker
                            startDate={this.state.startDate}
                            startDateId="projectStartDate"
                            endDate={this.state.endDate}
                            endDateId="projectEndDate"
                            onDatesChange={({ startDate, endDate }) => this.onDatesChange(startDate, endDate)}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            endDatePlaceholderText={"End date"}
                            startDatePlaceholderText={"Start date"}
                            displayFormat={"DD-MM-YYYY"}
                            disabled={!this.isProjectEditable()}
                        />
                    </Col>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="projects.estimated_budget" defaultMessage="Estimated Budget" /> ($)
                        </div>
                        <EasyEdit
                            type={Types.NUMBER}
                            onSave={(value) => { project.estimated_budget = value; this.saveProject() }}
                            saveButtonLabel={langService.messages['common.save']}
                            cancelButtonLabel={langService.messages['common.cancel']}
                            value={parseFloat(project.estimated_budget).toFixed(2)}
                            allowEdit={this.isProjectEditable()}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div className="form-labels">
                            <FormattedMessage id="forms.labels.visibility" defaultMessage="Visibility" />
                        </div>
                        <EasyEdit
                            type={Types.RADIO}
                            value={project.visibility}
                            onSave={(value) => { project.visibility = value; this.saveProject() }}
                            onCancel={this.cancel}
                            options={[
                                { label: langService.messages['common.public'], value: "PUBLIC" },
                                { label: langService.messages['common.private'], value: "PRIVATE" }
                            ]}
                            allowEdit={this.isProjectEditable()}
                        />
                    </Col>
                    {this.renderStatus(project)}
                    {/* {project.status === "DRAFT" || project.status === "PUBLISHED" || !project.status
                    ?
                    <Col>
                        <div className="form-labels">Status</div>
                        <EasyEdit
                            type={Types.RADIO}
                            value={project.status !== null ? project.status : 'DRAFT'}
                            onSave={(value) => { project.status = value; this.saveProject() }}
                            onCancel={this.cancel}
                            allowEdit={project.tasks.length > 0}
                            options={[
                                { label: "DRAFT", value: "DRAFT" },
                                { label: "PUBLISHED", value: "PUBLISHED" }
                            ]}
                        />
                    </Col>
                    :
                    <Col>
                        <div className="form-labels">STATUS</div>
                        <label>{project.status}</label>
                    </Col>
                } */}

                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        categories: state.projectCategory.list
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetProjectCategoriesStart,
    UpdateProjectStart,
    CreateDocumentsStart
})(ProjectOverviewWithEdit));