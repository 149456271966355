import React from 'react'
import { Container } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

export class Footer extends React.Component {

  getYear() {
    return new Date().getFullYear();
  }

  render() {

    return (
      <>
        <footer className="pt-4 pt-md-5 mt-4">
          <Container>
            <div className="row">
              <div className="col-12 col-md">
                <img className="mb-2" src="/assets/img/ecta_logo.png" alt="ECTA logo" height="35" />
                <div className="p-3"></div>
                <ul className="list-unstyled text-small">
                  <li><a className="text-muted" href="/help"><FormattedMessage id="footer.about" defaultMessage="About" /></a></li>
                  <li><a className="text-muted" href="/help"><FormattedMessage id="footer.how" defaultMessage="How it works" /></a></li>
                  <li><a className="text-muted" href="/legal/terms-and-conditions"><FormattedMessage id="footer.terms" defaultMessage="Terms & Conditions" /></a></li>
                  <li><a className="text-muted" href="/legal/privacy-policy"><FormattedMessage id="footer.privacy" defaultMessage="Privacy" /></a></li>
                </ul>
                <div className="p-3"></div>
                <small className="d-block mb-3 text-muted">Copyright &copy; {this.getYear()} ECTA</small>
              </div>
              <div className="col-6 col-md">
                <div className="p-5"></div>
                <ul className="list-unstyled text-small">
                  <li><a className="text-muted" href="/help"><FormattedMessage id="footer.questions" defaultMessage="Questions?" /></a></li>
                  <li><a className="text-muted" href="/profile"><FormattedMessage id="footer.settings" defaultMessage="Manage your account and privacy" /></a></li>
                  <li><a className="text-muted" href="/contact"><FormattedMessage id="footer.contact" defaultMessage="Contact us" /></a></li>
                </ul>
              </div>
            </div>
          </Container>
        </footer>
      </>
    )
  }
}

export default injectIntl(Footer);

