import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import { connect } from 'react-redux';
import { Container, Image } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
    GetSubRolesStart,
    CreateSubRoleStart,
    DeleteSubRoleStart
} from '../../core/actions/subrole';
import ModalCreateSubRole from './modalCreateSubRole';
import ModalConfirmDelete from '../../components/Common/ModalConfirmDelete/modalConfirmDelete';

class MyInstitutionEmployees extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalCreateSubRoleVisible: false,
            modalConfirmDeleteVisible: false,
            deleteId: null
        };
    }

    componentDidMount() { }

    delete() {
        this.props.DeleteSubRoleStart(this.state.deleteId).then(
            this.props.GetSubRolesStart(),
            this.setState({ modalConfirmDeleteVisible: false })
        );
        this.props.GetSubRolesStart();
        this.setState({ modalConfirmDeleteVisible: false });
    }

    newRoleAdded() {
        this.props.GetSubRolesStart();
        this.setState({ modalCreateSubRoleVisible: false });
    }

    renderRoles(type) {
        const { subroles } = this.props;
        return (
            <>
                {subroles.map((x, i) => {
                    if (x.type === type) {
                        return (
                            <div className="employee-wrap" key={i}>
                                <div className="employee-name">{x.name}</div>
                                <div className="delete-subrole">
                                    <Image src="/assets/img/svg/delete.svg" className="delete-icon" onClick={() => this.setState({ deleteId: x.id, modalConfirmDeleteVisible: true })}></Image>
                                </div>
                            </div>
                        )
                    }
                    return null;
                })}
            </>
        )
    }

    render() {
        return (
            <>
                <div className="edu-card-header">
                    <div className="card-head"><FormattedMessage id="edu.inst_admins" defaultMessage="Institution administrators" /></div>
                    <div className="btn btn-default btn-projects-inv btn-in-header" onClick={() => this.setState({ modalCreateSubRoleVisible: true })}>+ <FormattedMessage id="common.administrator" defaultMessage="Administrator" /></div>
                </div>
                <Container className="p-0 mt-2 pb-3">

                    <div className="edu-subtitle">
                        <FormattedMessage id="edu.manage_admins" defaultMessage="Manage users authorised to administer your institution." />
                    </div>
                    <hr />

                    <div className="mt-4 subrole-category"><FormattedMessage id="edu.student_admins" defaultMessage="Student administrators" /></div>
                    {this.renderRoles("EAS")}
                    <hr />

                    <div className="mt-4 subrole-category"><FormattedMessage id="edu.program_admins" defaultMessage="Program administrators" /></div>
                    {this.renderRoles("EAP")}
                    <hr />

                    <div className="mt-4 subrole-category"><FormattedMessage id="edu.competencies_admins" defaultMessage="Competence administrators" /></div>
                    {this.renderRoles("EAC")}

                </Container>
                {this.state.modalCreateSubRoleVisible && <ModalCreateSubRole visible={this.state.modalCreateSubRoleVisible} onClose={() => this.setState({ modalCreateSubRoleVisible: false })} onConfirm={() => this.newRoleAdded()} />}
                {this.state.modalConfirmDeleteVisible && <ModalConfirmDelete visible={this.state.modalConfirmDeleteVisible} onClose={() => this.setState({ modalConfirmDeleteVisible: false })} onConfirm={() => this.delete()} />}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        subroles: state.user.subroles.list.results,
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetSubRolesStart,
    CreateSubRoleStart,
    DeleteSubRoleStart
})(MyInstitutionEmployees));