import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CreateRoleStart } from '../../../../core/actions/role';


class RegisterRolePage extends React.Component {
    FORM_LABEL = 'forms.labels';
    FORM_PLC = 'forms.placeholders';

    radioChange(values) {
        console.log(values);
    }

    onSubmit = values => {
        process.env.NODE_ENV !== "production" && console.log("Form Values", values);
        var obj = { type: values.selectedRole, name: values.name, is_company: false, profile_image: null, cover_image: null };
        switch (values.selectedRole) {
            case "INV":
            case "EDU": // case "AUD":
                obj.company_name = values.name;
                break;
            default:
                break;
        }
        this.props.CreateRoleStart(obj, true);
    }

    render() {
        const { roles } = this.props;
        // const prod = process.env.NODE_ENV === "production" ? true : false;
        return (
            <>
                <div id="overlay">
                    <img src="/assets/img/icon/lightbulb.svg" alt="Idea"></img>
                </div>
                <Container className="flex-center pt-65">
                    <Form
                        onSubmit={this.onSubmit}
                        render={({ handleSubmit, valid, values }) => (
                            <div className="auth">
                                <p><span className="underline"><FormattedMessage id="forms.registerRoleForm" defaultMessage="Register" /></span></p>
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">{<FormattedMessage id={`${this.FORM_LABEL}.roleSelect`} defaultMessage="Choose your role (you can add more later)" />}</label>
                                                {
                                                    !roles?.some(role => role.type === "INV") && [
                                                        /* INV */
                                                        <div key="INV" className="custom-control custom-radio">
                                                            <Field
                                                                name="selectedRole"
                                                                value="INV"
                                                                component="input"
                                                                type="radio"
                                                                className="custom-control-input"
                                                                id="INV"
                                                            />
                                                            <label className="custom-control-label" htmlFor="INV"><FormattedMessage id="roles.add.INV" defaultMessage="An employer/investor, looking for contractors." /></label>
                                                        </div>,
                                                        values.selectedRole === "INV" && [
                                                            <div key="1" className="form-group pt-2">
                                                                <label className="form-label"><FormattedMessage id="roles.legalName" defaultMessage="Company/Legal name*" /></label>
                                                                <Field
                                                                    name="name"
                                                                    component="input"
                                                                    type="text"
                                                                    className="form-control"
                                                                    required={true}
                                                                />
                                                            </div>
                                                        ]
                                                    ]}
                                                {
                                                    !roles?.some(role => role.type === "DEV") && [
                                                        /* DEV */
                                                        <div key="DEV" className="custom-control custom-radio">
                                                            <Field
                                                                name="selectedRole"
                                                                value="DEV"
                                                                component="input"
                                                                type="radio"
                                                                className="custom-control-input"
                                                                id="DEV"
                                                            />
                                                            <label className="custom-control-label" htmlFor="DEV"><FormattedMessage id="roles.add.DEV" defaultMessage="A contractor, who wants to get hired." /></label>
                                                        </div>,
                                                        values.selectedRole === "DEV" && [
                                                            <div key="2" className="form-group pt-2">
                                                                <label className="form-label"><FormattedMessage id="roles.nameAndSurname" defaultMessage="Your full name*" /></label>
                                                                <Field
                                                                    name="name"
                                                                    component="input"
                                                                    type="text"
                                                                    className="form-control"
                                                                    required={true}
                                                                />
                                                            </div>
                                                        ]
                                                    ]}
                                                {
                                                    !roles?.some(role => role.type === "EDU") && [
                                                        /* EDU */
                                                        <div key="EDU" className="custom-control custom-radio">
                                                            <Field
                                                                name="selectedRole"
                                                                value="EDU"
                                                                component="input"
                                                                type="radio"
                                                                className="custom-control-input"
                                                                id="EDU"
                                                            />
                                                            <label className="custom-control-label" htmlFor="EDU"><FormattedMessage id="roles.add.EDU" defaultMessage="We are an educational institution." /></label>
                                                        </div>,
                                                        values.selectedRole === "EDU" && [
                                                            <div key="3" className="form-group pt-2">
                                                                <label className="form-label"><FormattedMessage id="roles.legalName" defaultMessage="Legal name*" /></label>
                                                                <Field
                                                                    name="name"
                                                                    component="input"
                                                                    type="text"
                                                                    className="form-control"
                                                                    required={true}
                                                                />
                                                            </div>
                                                        ]
                                                    ]}
                                                {/* {
                                                    !roles?.some(role => role.type === "AUD") && [
                                                        AUD
                                                        <div key="AUD" className="custom-control custom-radio">
                                                            <Field
                                                                name="selectedRole"
                                                                value="AUD"
                                                                component="input"
                                                                type="radio"
                                                                className="custom-control-input"
                                                                id="AUD"
                                                            />
                                                            <label className="custom-control-label" htmlFor="AUD"><FormattedMessage id="roles.add.AUD" defaultMessage="I am an auditor." /></label>
                                                        </div>,
                                                        values.selectedRole === "AUD" && [
                                                            <div key="4" className="form-group pt-2">
                                                                <label className="form-label"><FormattedMessage id="roles.legalName" defaultMessage="Company/Legal name*" /></label>
                                                                <Field
                                                                    name="name"
                                                                    component="input"
                                                                    type="text"
                                                                    className="form-control"
                                                                    required={true}
                                                                />
                                                            </div>
                                                        ]
                                                    ]} */}

                                            </div>
                                        </Col>
                                    </Row>
                                    <button type="submit" disabled={!valid} className="btn btn-default btn-generic btn-block"><FormattedMessage id={`${this.FORM_LABEL}.continue`} defaultMessage="Continue" /></button>
                                    {/* {!prod ? <pre><p ><code><small>{JSON.stringify(values, 0, 2)}</small></code></p></pre> : ''} */}
                                </form>
                            </div>
                        )}
                    />
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        roles: state.user.info.roles,
    }
}

export default injectIntl(connect(mapStateToProps, { CreateRoleStart })(RegisterRolePage));