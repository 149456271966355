import React from "react";
import Footer from './components/Footer/index';
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { PrivateRoute } from './utils/routes/privateRoute';
import ScrollToTop from './utils/routes/scrollToTop';

// Pages
import NoMatch from './utils/routes/no-match';
import LoginForm from './containers/Auth/Login';
import RegisterPage from './containers/Auth/Register';
import ForgotPasswordForm from './containers/Auth/ForgotPassword';
import ResetPasswordForm from './containers/Auth/ResetPassword';
import ActivateAccountForm from './containers/Auth/Activate';
import RegisterRolePage from './containers/Auth/Register/RegisterRole';
import LandingPage from './containers/Landing';
import ProjectsList from './containers/Browse/Projects';
import ProjectDetails from './containers/Browse/Project/projectDetails';
import TaskDetails from './containers/Browse/Task/taskDetails';
import ProjectCreate from './containers/Browse/Project/Create';
import CreateTask from './containers/Browse/Task/createTask';
import TasksList from './containers/Browse/Tasks';
import FreelancersPage from './containers/Browse/Freelancers';
import ClientsPage from './containers/Browse/Clients';
import AuditorsPage from './containers/Browse/Auditors';
import EducationalInstitutionsPage from './containers/Browse/Educational-Institutions';
import DashboardPage from './containers/Dashboard';
import MyWorkPage from './containers/MyWork';
import MyProjectsPage from './containers/MyProjects';
import MyAuditsPage from './containers/MyAudits';
import MessagesPage from './containers/Messages';
import ProfilePage from './containers/Profile';
import ProfileSetupPage from './containers/Profile/Setup';
import ProfileSetupFinishedPage from './containers/Profile/Setup/finished';
import PublicProfilePage from './containers/Profile/publicProfile';
import HelpPage from './containers/Help';
import TermsPage from './containers/Miscellaneous/Legal/Terms';
import PrivacyPolicyPage from './containers/Miscellaneous/Legal/PrivacyPolicy';
import ContactPage from './containers/Contact';
import ThemePage from './containers/Theme/index';
import SettingsPage from './containers/Settings/index';
import NotificationsHistoryPage from './containers/Notifications';
import ResolutionCenterPage from './containers/Resolution-Center';
import CompetenciesMainPage from './containers/Competencies';
import CompetencesDetailsPage from './containers/Competencies/Competency';
import MyInstitutionPage from "./containers/MyInstitution";


function Routes({ location, isAuthenticated, isInitialized, activeRole }) {
    return (
        <div className="wrapper">
            <TransitionGroup className="transition-group">
                <CSSTransition key={location.key} timeout={{ enter: 200, exit: 200 }} classNames="fade">
                    <section className="route-section">
                        <ScrollToTop />
                        <Switch location={location}>
                            <Route exact path="/" component={LandingPage} />
                            <Route exact path="/auth/login" component={LoginForm} />
                            <Route exact path="/auth/register" component={RegisterPage} />
                            <Route exact path="/auth/forgot-password" component={ForgotPasswordForm} />
                            <Route exact path="/auth/reset-password/:uid/:token" component={ResetPasswordForm} />
                            <Route exact path="/auth/activate-account/:token" component={ActivateAccountForm} />
                            <PrivateRoute exact path="/auth/register-role" component={RegisterRolePage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} />

                            <Route path="/browse/projects" component={ProjectsList} />
                            <Route path="/browse/tasks" component={TasksList} />
                            <Route path="/browse/developers" component={FreelancersPage} />
                            <Route path="/browse/investors" component={ClientsPage} />
                            <Route path="/browse/auditors" component={AuditorsPage} />
                            <Route path="/browse/educational-institutions" component={EducationalInstitutionsPage} />

                            <Route path="/competencies" component={CompetenciesMainPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} />
                            <Route path="/competency/:uid" component={CompetencesDetailsPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} />

                            <PrivateRoute path="/projects/:uid/:tab?" component={ProjectDetails} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/project/:projectId/task/:uid" component={TaskDetails} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />

                            <PrivateRoute path="/project/new" component={ProjectCreate} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="INV" activeRole={activeRole} />
                            <PrivateRoute path="/project-task/new/:projectId" component={CreateTask} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="INV" activeRole={activeRole} />

                            <PrivateRoute path="/dashboard" component={DashboardPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />

                            <PrivateRoute path="/my-work/:uid?/:tabKey?" component={MyWorkPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="DEV" activeRole={activeRole} />

                            <PrivateRoute path="/my-audits/:uid?" component={MyAuditsPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="AUD" activeRole={activeRole} />

                            <PrivateRoute path="/my-projects/:uid?/:taskid?/:tabKey?" component={MyProjectsPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="INV" activeRole={activeRole} />
                            <PrivateRoute path="/my-projects" component={MyProjectsPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="INV" activeRole={activeRole} />

                            <PrivateRoute path="/my-institution" component={MyInstitutionPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} requiredRole="EDU" activeRole={activeRole} />

                            <PrivateRoute path="/messages" component={MessagesPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/notification-history" component={NotificationsHistoryPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/profile" component={ProfilePage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/profile-setup/:step" component={ProfileSetupPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/profile-setup-finished" component={ProfileSetupFinishedPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />
                            <PrivateRoute path="/user/:id" component={PublicProfilePage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />

                            <PrivateRoute path="/settings/:area?" component={SettingsPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />

                            <PrivateRoute path="/resolution-center" component={ResolutionCenterPage} isAuthenticated={isAuthenticated} isInitialized={isInitialized} activeRole={activeRole} />

                            <Route exact path="/help" component={HelpPage} />
                            <Route path="/legal/terms-and-conditions" component={TermsPage} />
                            <Route path="/legal/privacy-policy" component={PrivacyPolicyPage} />
                            <Route path="/contact" component={ContactPage} />
                            <Route path="/theme" component={ThemePage} />
                            <Route component={NoMatch} />
                        </Switch>
                        <Footer />
                    </section>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
}

export default withRouter(Routes);
