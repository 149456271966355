import React from 'react';
import '../MyProjects/index.scss';
import './index.scss';
import { connect } from 'react-redux';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import MyInstitutionStudents from './students';
import MyInstitutionPrograms from './programs';
import MyInstitutionEmployees from './employees';
import MyInstitutionIssuerDetails from './issuerDetails';
import ModalCreateSubRole from './modalCreateSubRole';
import ModalCreateDegree from './modalCreateDegree';
import ModalCreateProgram from './modalCreateProgram';
import ModalCreateIssuer from '../../components/Issuer/modalCreateIssuer';
import ModalAlert from '../../components/Common/ModalAlert/modalAlert'
import { GetSubRolesStart } from '../../core/actions/subrole';
import { GetEduDegreesStart } from '../../core/actions/institution/degree';
import { GetMyEduProgramsStart } from '../../core/actions/institution/program';
import { GetMyIssuersStart } from '../../core/actions/issuer';
import { GetEduVerificationRequestsStart } from '../../core/actions/education';

class MyInstitutionPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedKey: "0",
            showProgramDetails: false,
            modalCreateSubroleVisible: false,
            modalCreateDegreeVisible: false,
            modalCreateProgramVisible: false,
            modalCreateIssuerVisible: false,
            showRequests: true,
            showStudentList: false,
            modalAlertVisible: false,
            alertTitle: '',
            alertText: '',
        };
    }

    componentDidMount() {
        if (this.props.role?.type === 'EDU' || this.props.role?.type === 'EAS') {
            this.activateToggle('0');
        } else if (this.props.role?.type === 'EAP') {
            this.activateToggle('1');
        } else {
            // EAC?
        }
    }

    activateToggle(eventKey) {
        switch (eventKey) {
            case "0": // Student Requests
                this.props.GetEduVerificationRequestsStart();
                break;
            case "1": // Programs
                this.props.GetEduDegreesStart();
                this.props.GetMyEduProgramsStart();
                this.props.GetMyIssuersStart();
                break;
            case "2": // Employees
                this.props.GetSubRolesStart();
                this.setState({ showProgramDetails: false });
                break;
            case "3": // Issuers
                this.props.GetMyIssuersStart();
                this.setState({ showProgramDetails: false });
                break;

            default:
                break;
        }
        this.setState({ selectedKey: eventKey });
    }

    openCreateProgramModal() {
        if (this.props.selectedIssuer.owner) {
            this.setState({ modalCreateProgramVisible: true });
        } else {
            this.setState({ modalAlertVisible: true, alertTitle: 'issuers.issuer_missing', alertText: 'issuers.issuer_missing_text' });
        }
    }

    renderSidebar() {
        const { subroles, degrees, programs, myIssuers, myStudents, role } = this.props;
        return (
            <div className="mypage-sidebar">

                <div className="sidebar-header">
                    <FormattedMessage id="nav.labels.myInstitution" defaultMessage="My Institution" />
                </div>

                <Accordion defaultActiveKey="0">

                    {/* STUDENTS */}
                    {(role.type === 'EDU' || role.type === 'EAS') &&
                        <>
                            <Accordion.Toggle eventKey="0" className={this.state.selectedKey === "0" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("0")}>
                                <div>
                                    <FormattedMessage id="edu.students" defaultMessage="Students" />
                                    {/* <span className="t-counts"> ({myStudents.requests.count})</span> */}
                                </div>
                                <i className={this.state.selectedKey === "0" ? "arrow down" : "arrow up"}></i>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <div className="sidebar-panel-items">
                                    {/* <div className="sidebar-section"><FormattedMessage id="edu.requests" defaultMessage="Verification requests" /></div>
                                {requests.map((r, x) => { return( <div key={x} className="sidebar-edu-item">{r.applicant_first_name} {r.applicant_last_name}</div> ) })} */}
                                    <div className="sidebar-addnew" onClick={() => this.setState({ showRequests: true, showStudentList: false })} >
                                        <FormattedMessage id="edu.verification_requests" defaultMessage="Verification requests" /> ({myStudents.requests.count})
                                </div>
                                    <p></p>
                                    <div className="sidebar-addnew" onClick={() => this.setState({ showRequests: false, showStudentList: true })} >
                                        <FormattedMessage id="edu.student_list" defaultMessage="Student list" /> ({myStudents.list.count})
                                </div>
                                </div>
                            </Accordion.Collapse>
                        </>
                    }

                    {/* PROGRAMS */}
                    {(role.type === 'EDU' || role.type === 'EAP') &&
                        <>
                            <Accordion.Toggle eventKey="1" className={this.state.selectedKey === "1" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("1")}>
                                <div><FormattedMessage id="edu.edu_programs" defaultMessage="Educational Programs" /></div>
                                <i className={this.state.selectedKey === "1" ? "arrow down" : "arrow up"}></i>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <div className="sidebar-panel-items">
                                    <div className="sidebar-section"><FormattedMessage id="edu.degrees" defaultMessage="Degrees" /></div>
                                    {this.state.selectedKey === "1" && degrees.map((x, i) => { return (<div key={i} className="sidebar-edu-item">{x.name}</div>) })}
                                    <div className="sidebar-addnew" onClick={() => this.setState({ modalCreateDegreeVisible: true })} >
                                        <FormattedMessage id="common.add_new" defaultMessage="+ Add new" />
                                    </div>
                                    <p></p>
                                    <div className="sidebar-section"><FormattedMessage id="edu.programs" defaultMessage="Programs" /></div>
                                    {this.state.selectedKey === "1" && programs.map((x, i) => { return (<div key={i} className="sidebar-edu-item">{x.name}</div>) })}
                                    <div className="sidebar-addnew" onClick={() => this.openCreateProgramModal()} >
                                        <FormattedMessage id="common.add_new" defaultMessage="+ Add new" />
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </>
                    }

                    {/* ADMINISTRATORS */}
                    {(role.type === 'EDU') &&
                        <>
                            <Accordion.Toggle eventKey="2" className={this.state.selectedKey === "2" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("2")}>
                                <div><FormattedMessage id="common.administrators" defaultMessage="Administrators" /></div>
                                <i className={this.state.selectedKey === "2" ? "arrow down" : "arrow up"}></i>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <div className="sidebar-panel-items">
                                    {this.state.selectedKey === "2" && subroles.map((x, i) => { return (<div className="sidebar-edu-item" key={i}>{x.name}</div>) })}
                                    <div className="sidebar-addnew" onClick={() => this.setState({ modalCreateSubroleVisible: true })} >
                                        <FormattedMessage id="common.add_new" defaultMessage="+ Add new" />
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </>
                    }

                    {/* ISSUER SETTINGS */}
                    {(role.type === 'EDU') &&
                        <>
                            <Accordion.Toggle eventKey="3" className={this.state.selectedKey === "3" ? "sideitem-header sideitem-header-active" : "sideitem-header"} onClick={() => this.activateToggle("3")}>
                                <div><FormattedMessage id="issuers.settings" defaultMessage="Issuer settings" /></div>
                                <i className={this.state.selectedKey === "3" ? "arrow down" : "arrow up"}></i>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <div className="sidebar-panel-items">
                                    {this.state.selectedKey === "3" && myIssuers.map((x, i) => { return (<div className="sidebar-edu-item" key={i}>{x.name}</div>) })}
                                    {myIssuers.length === 0 &&
                                        <div className="sidebar-addnew" onClick={() => this.setState({ modalCreateIssuerVisible: true })} >
                                            <FormattedMessage id="common.add_new" defaultMessage="+ Add new" />
                                        </div>
                                    }
                                </div>
                            </Accordion.Collapse>
                        </>
                    }

                </Accordion>

            </div>
        )
    }

    render() {
        return (
            <Container className="mt-4">
                <Row>
                    <Col xs={12} md={3} className="my-sidebar pb-3">
                        {this.renderSidebar()}
                    </Col>
                    <Col xs={12} md={9}>
                        <div className="card my-content">
                            {(this.state.selectedKey === "0" && !this.state.showProgramDetails) && <MyInstitutionStudents
                                showRequests={this.state.showRequests}
                                showStudentList={this.state.showStudentList}
                                requests={this.props.myStudents.requests}
                                students={this.props.myStudents.list}
                            />}
                            {(this.state.selectedKey === "1" && !this.state.showProgramDetails) && <MyInstitutionPrograms />}
                            {(this.state.selectedKey === "2" && !this.state.showProgramDetails) && <MyInstitutionEmployees />}
                            {(this.state.selectedKey === "3" && !this.state.showProgramDetails) && <MyInstitutionIssuerDetails />}
                        </div>
                    </Col>
                </Row>
                {this.state.modalCreateSubroleVisible && <ModalCreateSubRole visible={this.state.modalCreateSubroleVisible} onConfirm={() => this.props.GetSubRolesStart()} onClose={() => this.setState({ modalCreateSubroleVisible: false })} />}
                {this.state.modalCreateDegreeVisible && <ModalCreateDegree visible={this.state.modalCreateDegreeVisible} onConfirm={() => this.props.GetEduDegreesStart()} onClose={() => this.setState({ modalCreateDegreeVisible: false })} />}
                {this.state.modalCreateProgramVisible && <ModalCreateProgram visible={this.state.modalCreateProgramVisible} onConfirm={() => this.props.GetMyEduProgramsStart()} onClose={() => this.setState({ modalCreateProgramVisible: false })} />}
                {this.state.modalCreateIssuerVisible && <ModalCreateIssuer visible={this.state.modalCreateIssuerVisible} onConfirm={() => this.props.GetMyIssuersStart()} onClose={() => this.setState({ modalCreateIssuerVisible: false })} />}
                {this.state.modalAlertVisible &&
                    <ModalAlert
                        title={this.state.alertTitle}
                        text={this.state.alertText}
                        visible={this.state.modalAlertVisible}
                        onClose={() => this.setState({ modalAlertVisible: false })}
                    />
                }

            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.user.info.settings.active_role_obj,
        subroles: state.user.subroles.list.results,
        degrees: state.myInstitution.myDegrees.list.results,
        programs: state.myInstitution.myPrograms.list.results,
        selectedIssuer: state.issuers.selected,
        myIssuers: state.issuers.my.results,
        myStudents: state.myInstitution.myStudents
    }
}

export default injectIntl(connect(mapStateToProps, {
    GetSubRolesStart,
    GetEduDegreesStart,
    GetMyEduProgramsStart,
    GetMyIssuersStart,
    GetEduVerificationRequestsStart
})(MyInstitutionPage));