import React from 'react';
import './index.scss';
import './DropdownNotifications.scss';
import history from '../../utils/routes/history';
import { connect } from 'react-redux';
import { Nav, Dropdown } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { GetNotificationsStart, MarkNotificationAsReadStart } from './../../core/actions/notification/index';
import DropdownNotification from './DropdownNotification';

class DropdownNotifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showNotifications: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.notifications) {

        }
    }

    toggleDropdown = () => {
        if (this.state.showNotifications) {
            this.setState({ showNotifications: false });
        } else {
            this.props.GetNotificationsStart();
            this.setState({ showNotifications: true });
        }
    }

    viewAll = () => {
        history.push('/notification-history');
    }

    MarkAllAsRead = () => {
        this.props.notifications.forEach(not => {
            !not.read && this.props.MarkNotificationAsReadStart(not.id);
        });
    }

    handleClose = () => {
        this.setState({ showNotifications: false });
    }

    render() {
        const { notifications, counter } = this.props;
        return (
            <>
                <Nav.Link onClick={() => history.push("/messages")} className={this.props.transparent ? "nav-link-landing pr-0" : "nav-link  pr-0"} style={{"width": "55px"}} >
                    <span className="icon-email"></span>
                    {/* <span className="badge badge-pill badge-danger nav-badge">0</span> */}
                </Nav.Link>

                <Dropdown as={Nav.Item} show={this.state.showNotifications} onMouseLeave = {this.handleClose}>
                    <Dropdown.Toggle as={Nav.Link} className={this.props.transparent ? "nav-link-landing pr-0" : "nav-link pr-0"} onClick={this.toggleDropdown} style={{"width": "55px"}} >
                        <span className="icon-nitifications"></span>
                        {counter > 0 && <span className="badge badge-pill badge-danger nav-badge2">{counter}</span>}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right n-menu">
                        <div className="n-menu-header">
                            <div className="n-menu-header-l"><FormattedMessage id="notification.notifications" defaultMessage="Notifications" /></div>
                            <div className="n-menu-header-r">
                                <div onClick={this.MarkAllAsRead}><FormattedMessage id="notification.markAllAsRead" defaultMessage="Mark All as Read" /></div>
                                <div> · </div>
                                <div onClick={() => history.push("/settings")}><FormattedMessage id="DropdownUserMenu.settings" defaultMessage="Settings" /></div>
                            </div>
                        </div>
                        <div className="n-items">
                        {
                            notifications.length > 0 ?                                
                                notifications.slice(0, 50).map((not, x) =>
                                    <DropdownNotification not={not} key={x} />
                                ) :
                                <div className="n-item n-item-green">
                                    <Row className="n-row">
                                        <Col xs={2} className="p-0 text-center"></Col>
                                        <Col xs={8} className="p-0">
                                            <span className="n-title"><FormattedMessage id="notification.nothingNew" defaultMessage="Nothing new" /></span>
                                            <span className="n-text"><FormattedMessage id="notification.upToDate" defaultMessage="You're all up to date!" /></span>
                                        </Col>
                                        <Col xs={2} className="p-0 text-right">
                                        </Col>
                                    </Row>
                                </div>
                        }
                        </div>
                        <div className="n-menu-footer" onClick={this.viewAll}>
                            <span><FormattedMessage id="notification.seeAll" defaultMessage="See all" /></span>
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notification.notifications.list.results,
        counter: state.notification.notifications.unseen_notifications
    }
}

export default connect(mapStateToProps, { 
    GetNotificationsStart,
    MarkNotificationAsReadStart
})(DropdownNotifications);