import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { injectIntl, FormattedMessage } from 'react-intl';
import { apiCon } from '../../API';
import { getUrlParameterObject } from '../../utils/common/index';

// https://react-select.com/creatable
class SelectEduWithCreate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.selected ? this.props.selected : []
        };
    }

    loadData = (inputValue) => {
        const url = '/eduinstitutions/'
        const params = {
            'limit': 10,
            ...(inputValue ? { 'search': inputValue } : {}),
        }
        const filters = getUrlParameterObject(params);
        return apiCon.get(url, { params: filters }).then(response =>
            response.data.results.map(x => {
                return {
                    value: x.id.toString(),
                    label: x.name,
                }
            }))
            .catch(err => {
                process.env.NODE_ENV !== "production" && console.log('Bad Luck', err);
            })
    };

    getOptionLabel = option => {
        return (
            <div>{option.label}</div>
            // <div><strong>{option.label}</strong> <small>({getBloomName(option.level)} - {getBloomVerb(1, option.level, langService.locale)})</small></div>
        );
    }

    handleInputChange = (newValue) => {
        // const inputValue = newValue.replace(/\W/g, '');
        // return inputValue;
        return newValue;
    };

    handleCreate = (inputValue) => {
        this.setState({ selected: { value: null, label: inputValue}});
        this.props.onSchoolCreate(inputValue);
    };

    handleChange = (values) => {
        this.setState({ selected: values });
        this.props.onSelectedChange(values);
    }

    render() {
        const { getOptionLabel, loadData, placeholder, required } = this.props;
        return (
            <div>
                <AsyncCreatableSelect
                    key={JSON.stringify(this.props.preload)}
                    value={this.state.selected}
                    cacheOptions={false}
                    placeholder={<FormattedMessage id={placeholder ?? 'competences.search'} defaultMessage={`${placeholder} message not defined`} />}
                    loadOptions={loadData ?? this.loadData}
                    defaultOptions={true}
                    onInputChange={this.handleInputChange}
                    onCreateOption={this.handleCreate}
                    getOptionLabel={getOptionLabel ?? this.getOptionLabel}
                    onChange={this.handleChange}
                    required={required}
                />
            </div>
        );
    }
}

export default injectIntl(SelectEduWithCreate);