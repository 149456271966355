import React from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ResendActivationEmailStart } from '../../../core/actions/auth/index';

const customStyles = {
    width: 'inherit',
    height: '280px',
    maxWidth: '515px',
    maxHeight: '85%',
    bottom: 'auto',
    top: '15%'
};

class ModalResendActivationEmail extends React.Component {

    resend() {
        this.props.ResendActivationEmailStart().then(res => this.props.onClose());
    }

    render() {
        const { visible } = this.props;
        return (
            <Rodal
                visible={visible}
                onClose={() => this.props.onClose()}
                customStyles={customStyles}
                closeMaskOnClick={false}>
                <div className="modal-guts" style={{ "padding": "10px" }}>

                    <div className="modal-title">
                        <FormattedMessage id="browse.project.verify_email_short" defaultMessage={`Verify email`} />
                    </div>

                    <div className="modal-body p-2 pt-4">

                        <div className="">
                            <FormattedMessage id="browse.project.verify_email" defaultMessage="Please verify your email to start working with the platform." />
                        </div>

                        <br />

                        <div className="">
                            <div className="mb-2"><FormattedMessage id="common.did_not_get_verify_email" defaultMessage="Did not receive activation email?" /></div>
                            <div className="btn btn-default btn-projects btn-smallpadding" onClick={() => this.resend()} >
                                <FormattedMessage id="browse.project.re-send_email" defaultMessage={`Re-send activation email`} />
                            </div>
                        </div>

                        <br /><br /><br />

                        <div className="mt-4 modal-submit-buttons modal-abs-bottom">
                            <button type="submit" className="btn btn-default btn-projects-inv" onClick={() => this.props.onClose()}>
                                <FormattedMessage id="common.close" defaultMessage="CLOSE" />
                            </button>
                        </div>

                    </div>

                </div>
            </Rodal >
        )
    }

}

export default injectIntl(connect(null, {
    ResendActivationEmailStart
})(ModalResendActivationEmail));